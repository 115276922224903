import React, { ElementType } from 'react';
import styles from './card.module.css';
import BtnDefault from '../button';
import { MdMotionPhotosAuto, MdOutlineLocalCarWash, MdOutlineShoppingCart } from 'react-icons/md';
import { AiFillInfoCircle } from 'react-icons/ai';
import { GiCarSeat } from 'react-icons/gi';
import { SiGodotengine } from 'react-icons/si';
import { NavLink } from 'react-router-dom';

function Card(props: { icono: string; title: string; description: string; }){
  const {icono, title, description} = props
  let icon = <AiFillInfoCircle />
  switch(icono){
    case "MdMotionPhotosAuto":
      icon = <MdMotionPhotosAuto className={styles.ICON}/>
      break;
    case "MdOutlineLocalCarWash":
      icon = <MdOutlineLocalCarWash className={styles.ICON}/>
      break;
    case "GiCarSeat":
      icon = <GiCarSeat className={styles.ICON}/>
      break;
    case "SiGodotengine":
      icon = <SiGodotengine className={styles.ICON}/>
      break;
    case "MdOutlineShoppingCart":
      icon = <MdOutlineShoppingCart className={styles.ICON}/>
      break;
  }
  return (
    <div className={styles.CONTAINER}>
      <div className={styles.BACK} >
        <div className={styles.FRONT}>
          <div className={styles.TITLE}>
            <h1>{title}</h1>
            <hr></hr>
          </div>
          {icon}
          <span>{description}</span>
          <NavLink to={"/turn"}><BtnDefault /></NavLink>
      </div></div>
    </div>
  )
}

export default Card