import React, { useEffect, useState } from "react";
import styles from "./select.module.css";
import axios from "axios";
const hours: any = [
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
];

function Select(props: {
  selected: Date | string | undefined;
  hour: string;
  setHour: Function;
}) {
  const { selected, hour, setHour } = props; //devuelve la fecha que se esta seleccionando en el calendar. COn esta fecha se hace la busqueda de horarios disponibles
  const [isActive, setIsActive] = useState<boolean>(false);
  const [hoursDB, setHoursDB] = useState<Array<string>>([]);
  const { REACT_APP_DB_HOST } = process.env

  useEffect(() => {
    axios
      .get(`${REACT_APP_DB_HOST}/turns/available?date=${selected}`)
      // .get(`http://localhost:3001/turns/available?date=${selected}`)
      .then((res) => res.data)
      .then((res) => {
        console.log(res)
        //2023-07-13 03:00:00: [10, 13, 12]
        if(res){
          for (const key in res) {
            setHoursDB(res[key]);
          }
        }else{
          setHoursDB(hours)
        }
      });
      setHour("");
  }, [selected]);

  return (
    <div
      className={`${styles.DROPDOWN} ${isActive ? styles.ACTIVE : ""}`}
      onClick={() => setIsActive(!isActive)}
    >
      <input
        type="text"
        id="text"
        className={styles.TEXTBOX}
        placeholder="SELECCIONAR HORARIO"
        value={hour}
        onBlur={() => setTimeout(() => setIsActive(false), 300)}
        readOnly
      />
      <div className={styles.OPTION}>
        {hoursDB.length > 0
          ? hoursDB.map((item, index) => {
              if (item){
                return (
                  <div key={index} className={styles.UNSELECT}>{`${
                    index + 8
                  }:00`}</div>
                );
              }else{
                return (
                <div key={index} className={styles.INSELECT} onClick={() => setHour(`${index + 8}:00`)}>
                  {`${index + 8}:00`}
                </div>
              );
              }
            })
          : hours.map((item: any, index: number) => (
              <div key={index} onClick={() => setHour(item)}>
                {item}
              </div>
            ))}
      </div>
    </div>
  );
}

export default Select;
