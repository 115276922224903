import styles from './contact.module.css';

export default function Contact (){
    return (
        <div id="CONTACTO" className={styles.Contact}> 
        <div className={styles.WRAPPER}>
            <div className={styles.LEFT}>
                <h1>COMO ENCONTRARNOS</h1>
                <hr /><p/>
                <span>Estamos ubicados en la ciudad de Centenario, provincia de Neuquén. En el denominado "casco viejo" a unos 350mts de la Ruta provincial 7.</span>
                <span>Accediendo desde la primer rotonda "monumento a Los Pioneros" hacia el este.</span>
            </div>
            <div className={styles.RIGHT}>
            <h1>CONTACTO</h1>
            <hr /><p/>
            <form action="https://formsubmit.co/cdwenz@gmail.com" method="POST">
            
                    <input type="text" className={styles.input} name="name" placeholder='Nombre' required />
                
            
                    <input type="email" className={styles.input} name="email" placeholder='email' required />
                
            
                    <textarea name="text" className={styles.input} placeholder='Mensaje' required />
                
                <button type="submit" className={styles.inputBtn}>ENVIAR</button>
            </form>
            </div>
        </div>
    </div>
    )
}