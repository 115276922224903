import React from 'react';
import img from '../../img/Services.png'
import styles from './services.module.css'
import Card from '../../components/card';
import { IconBaseProps } from 'react-icons/lib';

import { services } from './const_services';

function Services() {
  return (
    <div className={styles.CONTAINER}>
      <div className={styles.PANEL}>
        {services.map(service => {
          return(
            <Card icono={service.icon} title={service.title} description={service.description}/>  
          )
        })}
        
      </div>
    </div>
  )
}

export default Services