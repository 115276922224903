import { IService } from "../../interfaces";

export const services: IService[] = [
    {
        title: "Autolavado",
        icon: "MdMotionPhotosAuto",
        description: "Contamos con terminales de autolavado, donde podes realizar el trabajo como lo harías en casa",
    },
    {
        title: "Manual",
        icon: "MdOutlineLocalCarWash",
        description: "Contamos con terminales de autolavado, donde podes realizar el trabajo como lo harías en casa",
    },
    {
        title: "Tapizados",
        icon: "GiCarSeat",
        description: "Contamos con terminales de autolavado, donde podes realizar el trabajo como lo harías en casa",
    },
    {
        title: "Motor - Chasis",
        icon: "SiGodotengine",
        description: "Contamos con terminales de autolavado, donde podes realizar el trabajo como lo harías en casa",
    }
    // {
    //     title: "Productos",
    //     icon: "MdOutlineShoppingCart",
    //     description: "Contamos con terminales de autolavado, donde podes realizar el trabajo como lo harías en casa",
    // }
]


// Servicios:
//  autolavado
// Lavado manual
// Limpieza de tapizados: paneles, butacas, alfombra; techo
// Limpieza de motor chasis
// Venta de productos para el cuidado Vehicular