import  { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { ClassNames, DayPicker } from 'react-day-picker';
import styles, { root } from 'react-day-picker/dist/style.module.css';
import axios from 'axios';
import { color_primary } from '../default';
import { IDatePickerProps, IDatesReserved } from '../../interfaces';

export default function DatePicker(props:IDatePickerProps) {
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1 )
  const {state, setState} = props;
  const [reserved, setReserved] = useState<Array<IDatesReserved>>([{date: new Date("2023-05-19T20:57:36.253Z"), hours: [8,9]}]);

  const classnames:ClassNames = {
    ...styles,
    root: 'my-root',
    head: 'custom-head',
    head_cell: 'custom-head_cell',
    day: 'custom-day'
  }
  
useEffect(() => {
  axios.get(process.env.REACT_APP_DB_HOST + '/turns/available')
  .then(res => res.data)
  .then(res => {
    let result = [];
    for (const key in res) {
      result.push({date: new Date(key), hours: [...res[key]]})
    }
    setReserved(result)
  })
},[state])

const css = `
  .my-root {
    margin: auto;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .my-selected:not([disabled]) { 
    font-weight: bold; 
    border: 2px solid gray;
    background-color: ${color_primary};
    color: white
  }
  .my-selected:hover { 
    border-color: gray;
    color: gray;
  }
  .my-today { 
    font-weight: bold;
    font-size: 120%; 
    color: ${color_primary};
  }

  .custom-day {
    width: 90px;
    max-width: 120px;
    height: 60px;
    border-radius: 10%
  }
  .custom-head_cell { 
    font-size: 1em
  }

  @media screen and (max-width: 760px) {
    .my-today {
      font-weight: 700;
    }
    .custom-day {
      width: 40px;
      height: 40px;
    }
}
`;

  const reservered = () => {
    let dates: Date[] = [];
    reserved.map((element)=>{
      if(element.hours.length >= 11){
        dates.push(element.date)
      }
    })
    return {...dates}
  }
  const disabledDays = [
    reservered(),
    { from: new Date(1901, 1, 1), to: yesterday }
  ];

  const update = async (e:Date | undefined) => {
    if(e) setState(format(e, 'PP'));
  }

  return (
      <>
        <style>{css}</style>
        <DayPicker
          classNames={classnames}
          mode="single"
          selected={new Date(state)}
          onSelect={(e) => update(e)}
          modifiersClassNames={{
            selected: 'my-selected',
            today: 'my-today',
          }}
          disabled={disabledDays}
          />
      </>
  );
}

