import { useEffect, useState } from "react";
import styles from "./select.module.css";
import { brands } from "../../copys";

function Select_cars(props:{setBrander: Function, brander: string}) {
  const { brander, setBrander } = props;
  const [brand, setBrand] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [marcas, setMarcas] = useState(brands);
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    if (!brands.includes(brander)) {
      setValidation(true);
    } else {
      setValidation(false);
    }
    setBrander(brander);
  }, [brander]);

  const handleChange = (e: any) => {
    const text = e.target.value.toUpperCase();
    setBrander(text)
    setIsActive(true);
    setMarcas(brands.filter((item) => item.includes(text)));
  };

  return (
    <div
      className={`${styles.DROPDOWN} ${isActive ? styles.ACTIVE : ""}`}
      onClick={() => setIsActive(!isActive)}
    >
      <input
        type="text"
        name="brand"
        id="text"
        className={styles.TEXTBOX}
        placeholder="MARCA"
        value={brander}
        onChange={(e) => handleChange(e)}
        onBlur={() => setTimeout(() => setIsActive(false), 300)}
        style={{ borderColor: `${validation ? "red" : "gray"}` }}
      />
      <div className={styles.OPTION}>
        {marcas.map((item: any, index: number) => (
          <div key={index} onClick={() => setBrander(item)}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Select_cars;
