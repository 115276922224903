import Carousel from '../../components/carousel';
import styles from './home.module.css';
import Textra from 'react-textra';
import Services from '../services';
import Clients from '../client';

function Home() {
  return (
    <div className={styles.container}>
      <div className={styles.contain}>
        <div className={styles.landingLeft}>
          <Carousel />
        </div>
        <div className={styles.landingRight}>
          <span>
          <Textra 
            effect='leftRight'
            duration={500}
            data={['EXCELENCIA EN EL SERVICIO', 'LAVADO INTEGRAL', 'INTERIOR - EXTERIOR']}
          />
          </span>
        </div>
      </div>
      <div className={styles.contain2}><Services /></div>
      <div className={styles.contain2}><Clients /></div>
    </div>
  )
}

export default Home