import { useEffect, useState } from "react";
import DatePicker from "../../components/date_picker";
import styles from "./turn.module.css";
import { format } from "date-fns";
import axios from "axios";
import { color_primary } from "../../components/default/index";
import Select from "../../components/select";
import Select_cars from "../../components/select/select_cars";
import Select_model from "../../components/select/select_model";
import { brands, modelsByBrand } from "../../copys";
import { IFormTurn } from "../../interfaces";
import toast, { Toaster } from 'react-hot-toast';

function validate(input: IFormTurn) {
  const valo = brands.find((item) => item === input.brand);
  const mood = modelsByBrand[input.brand]?.find(
    (item: string) => item === input.model
  );
  const lengthPhone = input.phone?.toLocaleString().length;
  let error: any = {};
  if (!input.name) error.name = "Name is required";
  if (!input.patent || input.patent.length < 6 || input.patent.length > 7)
    error.patent = "Patent is required";
  if (!input.model || mood !== input.model) error.model = "Model is required";
  if (!input.phone || lengthPhone === undefined || lengthPhone < 9 || lengthPhone > 12)
    error.phone = "Phone is required whithout '0' or '15'";
  if (!input.email) error.email = "Email is required";
  if (!input.brand || valo !== input.brand) error.brand = "Brand not exist";
  console.log("VALIDATE", error);
  return error;
}

function Turn() {
  const [state, setState] = useState(format(Date.now(), "PP"));

  const object: IFormTurn = {
    name: "",
    patent: "",
    brand: "",
    model: "",
    phone: null,
    email: "",
    hour: "",
    date: format(Date.now(), "PP"),
  };
  const err: any = { name: "initial" };
  const [list, setList] = useState(object);
  const [errors, setErrors] = useState(err);
  const [brander, setBrander] = useState<string>("");
  const [model, setModel] = useState("");
  const [hour, setHour] = useState<string>("");

  const hourNumber = (hour: string) => {
    const hourNum =
      hour.length === 5 
      ? Number(hour.slice(0, 2)) 
      : Number(hour.slice(0, 1));
    return hourNum;
  };

  useEffect(() => {
    setList({
      ...list,
      brand: brander,
      model,
      hour,
    });
  }, [brander, model, hour]);

  function handleChange(e: any) {
    setList({
      ...list,
      brand: brander,
      model,
      hour,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validate({
        ...list,
        brand: brander,
        model,
        hour,
        [e.target.name]: e.target.value,
      })
      );
  }

  const notify = (message:string, icon:string) => toast(message,
    {
      icon: icon,
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    }
  );

// const App = () => {
//   return (
//     <div>
//       <button onClick={notify}>Make me a toast</button>
//       <Toaster />
//     </div>
//   );
// };

  const handleClick = async (e: any) => {
    e.preventDefault();
    if (
      errors.name ||
      errors.patent ||
      errors.model ||
      errors.phone ||
      errors.email ||
      errors.brand
    ) {
      console.log({ errors });
    } else {
      let newHour = hourNumber(hour);
      const newList = {
        ...list,
        hour: newHour,
        date: state
      };
      await axios.post(
        `${process.env.REACT_APP_DB_HOST}/turns`,
        // `http://localhost:3001/turns`,
        newList
      )
      .then(res => {
        notify(res.data.message,'👏');
        setList(object);
        setBrander("")
        setModel("")
        setHour("")
        setState(format(Date.now(), "PP"))
      })
      .catch(res => notify(res.response.data,'🔥'))
    }
  };

  return (
    <div className={styles.CONTAINER}>
      <div className={styles.BANNER}>RESERVA TU TURNO</div>
      <form
        action=""
        className={styles.PANEL}
        onChange={(e) => handleChange(e)}
      >
        <div className={styles.DATE}>
          <div className={styles.CALENDAR}>
            <DatePicker state={state} setState={setState} />
          </div>
        </div>
        <div className={styles.RESUME}>
          <h3 className={styles.TITLE}>DATOS</h3>
          <input
            className={styles.input}
            style={{ borderColor: errors.name ? "red" : "gray" }}
            type="text"
            name="name"
            placeholder={"Nombre"}
            value={list.name}
          />
          <input
            className={styles.input}
            style={{ borderColor: errors.patent ? "red" : "gray" }}
            type="text"
            name="patent"
            placeholder={"Patente"}
            value={list.patent}
          />
          <div className={styles.select}>
            <Select_cars setBrander={setBrander} brander={brander} />
          </div>
          <div className={styles.select}>
            <Select_model brand={brander} model={model} setModel={setModel} />
          </div>
          <input
            className={styles.input}
            style={{ borderColor: errors.phone ? "red" : "gray" }}
            type="number"
            name="phone"
            placeholder={"Telefono Movil sin '0'  '15'"}
            value={list.phone || ""}
          />
          <input
            className={styles.input}
            style={{ borderColor: errors.email ? "red" : "gray" }}
            type="email"
            name="email"
            placeholder={"Email"}
            value={list.email}
          />
          <div className={styles.select}>
            <Select selected={state} hour={hour} setHour={setHour} />
          </div>
          <input
            className={styles.inputSubmit}
            type="submit"
            value={"Solicitar"}
            style={{
              backgroundColor: color_primary,
            }}
            onClick={(e) => handleClick(e)}
          />
        </div>
      </form>
      <Toaster />
    </div>
  );
}

export default Turn;
