import { useEffect, useState } from "react";
import styles from "./select.module.css";
import { modelsByBrand } from "../../copys";

function Select_model(props: { brand: string, model: string, setModel: Function }) {
    const { brand, model, setModel } = props;
    const [isActive, setIsActive] = useState<boolean>(false);
    const [models, setModels] = useState<any>(modelsByBrand);
    const [validation, setValidation] = useState(false);
    
    useEffect(()=>{
      if(modelsByBrand.hasOwnProperty(brand)){
        setModels(modelsByBrand[brand])
        setModel(modelsByBrand[brand][0])
      }
    },[brand]);

    const handleChange = (e: any) => {
      const text = e.target.value.toUpperCase();
      setModel(text);
      setIsActive(true);
      setModels(modelsByBrand[brand].filter((item:any) => item.includes(text)));
      setValidation(modelsByBrand[brand].find((item: string) => {
        return item === text ? false : true;
      }));
    };

  return (
    <div
      className={`${styles.DROPDOWN} ${isActive ? styles.ACTIVE : ""}`}
      onClick={() => setIsActive(!isActive)}
    >
      <input
        type="text"
        name="model"
        id="text"
        className={styles.TEXTBOX}
        placeholder="MODELO"
        value={model}
        onChange={(e) => handleChange(e)}
        onBlur={() => setTimeout(() => setIsActive(false), 100)}
        style={{ borderColor: `${validation ? "red" : "gray"}` }}
      />
      <div className={styles.OPTION}>
        {Array.isArray(models) ? models.map((item: any, index: number) => (
          <div key={index} onClick={() => { setModel(item); setValidation(false)}}>
            {item}
          </div>
        )): <div>MODELO</div>}
      </div>
    </div>
  );
}

export default Select_model;
