import styles from './us.module.css';
import { us, vision, mission, env_policy, compromise } from '../../copys';

function Us() {
  return (
    <div className={styles.CONTAINER}>
       <div className={styles.BANNER}> PORQUE NOSOTROS?</div>
      <div className={styles.PANEL}>
        <div className={styles.COLUMN}>
          <h1> HISTORIA </h1>
          <hr />
          <span>{us}</span>
        </div>
        <div className={styles.COLUMN}>
          <h1> VISION </h1>
          <hr />
          <span>{vision}</span>
        </div>
        <div className={styles.COLUMN}>
          <h1> MISION </h1>
          <hr />
          <span>{mission}</span>
        </div>
      </div>
      <div className={styles.ENV_POLICY}>
        <div className={styles.COLUMN1}>
          <h1> POLITICA AMBIENTAL </h1>
          <hr />
          <span>{env_policy}</span>
        </div>
        <div className={styles.COLUMN}>
          <h1> COMPROMISO </h1>
          <hr />
          <span>{compromise}</span>
        </div>
      </div>
    </div>
  )
}

export default Us