export const us ='Desde 2018, Lanín Car Wash brinda un servicio modalidad self sevice (autoservicio) y lavado vehicular. A Particulares y Empresas. Además de la venta de insumos para la estética y cuidado de tu vehículo.'

export const mission = 'Ofrecer un servicio de atención y venta de alta performace, atentos y dispuestos a la mejora continua, enfocados en lograr un servicio sostenible empático con el cliente y el medio ambiente.'

export const vision = 'Posicionarnos y destacarnos en ofrecer un servicio que satisfaga el deseo y la necesidad del cliente.'

export const env_policy = 'Como empresa, buscamos minimizar todos los impactos ambientales significativos provenientes de la actividad, de los productos y los procesos realizados por la organización. Con el fin de lograr la sutentabilidad. Manteniendo nuestro enfoque y visión estratégica en armonía con el Ambiente.'

export const compromise = 'Nuestro compromiso es un equilibrio ideal entre servicios de excelencia, y el menor impacto al ambiente. Haciendo de nuestro trabajo, un desafío sustentable en el tiempo. '

export const brands = [
    "3-STAR",
    "ACURA",
    "AGRALE",
    "AIXAM",
    "ALFA ROMEO",
    "APPIA",
    "APRILIA",
    "ARCTIC CAT",
    "ARO",
    "ASIA",
    "AUDI",
    "BAIC",
    "BAJAJ",
    "BELAVTOMAZ",
    "BENELLI",
    "BETA",
    "BETAMOTOR",
    "BLAC",
    "BLACKSTONE",
    "BMW",
    "BRAVA",
    "CADILLAC",
    "CAN-AM",
    "CERRO",
    "CF MOTO",
    "CFMOTO",
    "CHANGAN",
    "CHERY",
    "CHEVROLET",
    "CHRYSLER",
    "CITROEN",
    "CORADIR",
    "CORVEN",
    "DA DALT",
    "DACIA",
    "DAELIM",
    "DAEWOO",
    "DAIHATSU",
    "DAIMLER BENZ",
    "DAYAMA",
    "DEUTZ",
    "DEUTZ AGRALE",
    "DEUTZ-AGRALE",
    "DFM",
    "DFSK",
    "DIMEX",
    "DINA",
    "DODGE",
    "DONGFENG",
    "DS",
    "DUCATI",
    "ELITE",
    "ELPRA ELECTRIC",
    "EUROMOT",
    "FAW",
    "FERESA",
    "FERRARI",
    "FIAT",
    "FIAT IVECO",
    "FORD",
    "FOTON",
    "GAC GONOW",
    "GAF",
    "GALLOPER",
    "GAMMA",
    "GARELLI SAHEL",
    "GAZ",
    "GEELY",
    "GEO",
    "GHIGGERI",
    "GILERA",
    "GMC",
    "GREAT WALL",
    "GUERRERO",
    "HAOJUE",
    "HARLEY DAVIDSON",
    "HAVAL",
    "HEI BAO",
    "HERO",
    "HINO",
    "HONDA",
    "HUMMER",
    "HUSQVARNA",
    "HYUNDAI",
    "IMSA",
    "INDIAN",
    "INFINITI",
    "INNOCENTI",
    "INTERNACIONAL",
    "INTERNATIONAL",
    "ISUZU",
    "IVECO",
    "JAC",
    "JAGUAR",
    "JAWA",
    "JEEP",
    "JETOUR",
    "JIANSHE",
    "JINCHENG",
    "JMC",
    "JMSTAR",
    "KAWASAKI",
    "KEEWAY",
    "KELLER",
    "KIA",
    "KIDEN",
    "KIKAI",
    "KONISA",
    "KTM",
    "KYC",
    "KYMCO",
    "LADA",
    "LANCER",
    "LAND ROVER",
    "LEGNANO",
    "LEXUS",
    "LIFAN",
    "LOTUS",
    "MACK",
    "MARUTI",
    "MARUTI SUZUKI",
    "MASERATI",
    "MAVERICK",
    "MAXUS",
    "MAZDA",
    "MCLAREN",
    "MERCEDES BENZ",
    "MERCEDES",
    "METRO",
    "MG",
    "MINI",
    "MITSUBISHI",
    "MONDIAL",
    "MOTO GUZZI",
    "MOTOMEL",
    "MV AGUSTA",
    "NAKAI",
    "NISSAN",
    "NISSAN DIESEL",
    "NORTON",
    "NUUV",
    "OKINOI",
    "OLMO",
    "OPEL",
    "PANTHER",
    "PANTHER QUADS",
    "PEUGEOT",
    "PIAGGIO",
    "PLYMOUTH",
    "POLARIS",
    "PONTIAC",
    "PORSCHE",
    "PROTON",
    "QINGQI",
    "RAM",
    "RANGE ROVER",
    "RENAULT",
    "ROVER",
    "ROYAL ENFIELD",
    "RVM",
    "SAAB",
    "SAAB SCANIA",
    "SANTANA",
    "SANXING",
    "SATURN",
    "SCANIA",
    "SCANIA/MARCOPOL",
    "SEAT",
    "SERO",
    "SHERCO",
    "SHINERAY",
    "SIAM",
    "SKODA",
    "SMART",
    "SOUEAST",
    "SPACE",
    "SSANGYONG",
    "SUBARU",
    "SUMO",
    "SUNEQUIP",
    "SUNRA",
    "SUZUKI",
    "SUZUKI - SANTANA",
    "SYM",
    "TATA",
    "TIBO",
    "TOYOTA",
    "TRIUMPH",
    "TVS",
    "VOGE",
    "VOLKSWAGEN",
    "VOLVO",
    "WULING",
    "YAMAHA",
    "YUEJIN",
    "ZANELLA",
  ];
  
export const modelsByBrand:any = {
  "3-STAR": [
      "SXZ1045B",
      "SXZ1045BDW",
      "SXZ1047",
      "SXZ1047W",
      "SXZ3043"
  ],
  "ACURA": [
      "MDX"
  ],
  "AGRALE": [
      "10000",
      "13000",
      "14000",
      "6000",
      "6000D",
      "7500",
      "8500",
      "8500CD",
      "8700",
      "9200",
      "A 10000",
      "A 8700",
      "A10000",
      "A7500",
      "A8700",
      "CHASIS",
      "FURGOVAN",
      "MA",
      "MT"
  ],
  "AIXAM": [
      "CROSSLINE"
  ],
  "ALFA ROMEO": [
      "145",
      "146",
      "147",
      "156",
      "159",
      "166",
      "2000",
      "2600",
      "ALFA",
      "BRERA",
      "GIULIA",
      "GIULIETTA",
      "GT",
      "GTV",
      "GTV6",
      "MITO",
      "SPIDER",
      "STELVIO",
      "T.SPARK",
      "T_SPARK",
      "VELOCE"
  ],
  "APPIA": [
      "ANDINA",
      "BERAKA",
      "BLESS",
      "BREZZA",
      "CITIPLUS",
      "CITIPLUS110",
      "DCN",
      "GEREMY",
      "HARDWIND",
      "LEYENDA",
      "MONTERO",
      "REGIA",
      "STRONGER",
      "VECTRA"
  ],
  "APRILIA": [
      "APRILIA",
      "DORSODURO",
      "RSV4",
      "SHIVER",
      "STX150",
      "TUONO"
  ],
  "ARCTIC CAT": [
      "300",
      "ALTERRA",
      "DVX300",
      "WILDCAT",
      "XR"
  ],
  "ARO": [
      "10",
      "10.0",
      "10.1",
      "10.2",
      "10.3",
      "10.4",
      "10.8",
      "10.9"
  ],
  "ASIA": [
      "AM",
      "BUS",
      "COMBI",
      "R2",
      "TOPIC",
      "TOWER",
      "TOWNER"
  ],
  "AUDI": [
      "Q2",
      "A1",
      "A3",
      "A4",
      "A5",
      "A6",
      "A7",
      "A8",
      "ALLROAD",
      "AVANT",
      "E-TRON",
      "Q3",
      "Q5",
      "Q7",
      "Q8",
      "R8",
      "RS3",
      "RS4",
      "RS5",
      "RS6",
      "RS7",
      "S3",
      "S4",
      "S5",
      "S6",
      "S8",
      "SQ5",
      "TT",
      "TTS"
  ],
  "BAIC": [
      "D20",
      "X25",
      "X35",
      "X55",
      "X65"
  ],
  "BAJAJ": [
      "AVENGER",
      "BOXER",
      "DISCOVER",
      "DOMINAR",
      "PULSAR",
      "ROUSER",
      "ROUSER220F",
      "V 15",
      "XCD125"
  ],
  "BELAVTOMAZ": [
      "BTM"
  ],
  "BENELLI": [
      "180",
      "302",
      "502C",
      "752S",
      "CAFFENERO",
      "IMPERIALE",
      "LEONCINO",
      "SETA",
      "TNT",
      "TNT15",
      "TNT25",
      "TNT300",
      "TNT600GT",
      "TRK",
      "TRK251",
      "ZAFFERANO"
  ],
  "BETA": [
      "ARK",
      "ARROW",
      "BK150",
      "BS110-1",
      "EIKON",
      "ENDURO",
      "EURO",
      "FINDER",
      "FOUR-AE/90",
      "GRINDER",
      "LX250-8",
      "MINICROSS",
      "PANDA",
      "QM",
      "QM200GY",
      "QUADRA",
      "RR450",
      "RT250",
      "TEMPO",
      "ZT310R",
      "ZT310T",
      "ZT310V",
      "ZT310X"
  ],
  "BETAMOTOR": [
      "ADVENTURE",
      "AKVO",
      "ARROW",
      "BK",
      "BOY",
      "BS",
      "CAYMAN",
      "CHRONO",
      "ENDURO",
      "FOUR",
      "FOXTER",
      "GRINDER",
      "MASTER",
      "MINICROSS",
      "MOTARD",
      "NEOS",
      "PANDA",
      "RK6",
      "RR",
      "SCOOBY",
      "TEMPO",
      "TR",
      "URBA",
      "X-TRAINER",
      "ZONTES",
      "ZT310R",
      "ZT310T",
      "ZT310V",
      "ZT310X"
  ],
  "BLAC": [
      "BJ1041",
      "BJ1041C4DG",
      "BJ1041C4SG",
      "BJ1050",
      "BJ1061"
  ],
  "BLACKSTONE": [
      "BKS110",
      "BKS200",
      "BKS200S",
      "BKS250",
      "BKS250S",
      "BKS300",
      "BKS300S",
      "BKS50",
      "JX200ST-3",
      "JX250ST",
      "JX250ST-2",
      "JX250ST-4A",
      "JX50ST-4"
  ],
  "BMW": [
      "116",
      "116I",
      "118",
      "118D",
      "118I",
      "120D",
      "120I",
      "125",
      "130I",
      "135",
      "1M",
      "218I",
      "220",
      "220I",
      "300",
      "316",
      "316I",
      "318",
      "318I",
      "318TDS",
      "320",
      "320I",
      "323",
      "323I",
      "323TI",
      "324",
      "325",
      "325I",
      "328",
      "328CI",
      "328I",
      "330",
      "330I",
      "335I",
      "428I",
      "430I",
      "435I",
      "440I",
      "523",
      "525",
      "525I",
      "528",
      "530",
      "530D",
      "530I",
      "530IA",
      "535",
      "535I",
      "540",
      "540I",
      "540IA",
      "545I",
      "550I",
      "635",
      "645CI",
      "650",
      "650CI",
      "650I",
      "740",
      "740I",
      "745",
      "745IA",
      "750",
      "750LI",
      "C 600",
      "C650",
      "C650GT",
      "F 650",
      "F 700",
      "F 750",
      "F 800",
      "F 850",
      "F650",
      "F800ST",
      "G 310",
      "G 450",
      "G 650",
      "G310R",
      "G650",
      "G650GS",
      "GR",
      "K1200",
      "K1200GT",
      "K1200LT",
      "K1200S",
      "K1300GT",
      "K1300R",
      "K1600B",
      "K1600GTL",
      "M 140",
      "M 240",
      "M 3",
      "M 5",
      "M135",
      "M135I",
      "M2",
      "M235I",
      "M240I",
      "M3/M5",
      "M340I",
      "M4",
      "M440I",
      "M5",
      "M6",
      "M850I",
      "R 1100",
      "R 1150",
      "R 1250",
      "R 18",
      "R NINE",
      "R1100S",
      "R1150",
      "R1150GS",
      "R1150R",
      "R1200",
      "R1200C",
      "R1200CL",
      "R1200GS",
      "R1200R",
      "R1200RS",
      "R1200RT",
      "S1000R",
      "S1000RR",
      "S1000XR",
      "X1",
      "X2",
      "X3",
      "X4",
      "X5",
      "X6",
      "Z3",
      "Z4",
      "Z8",
      "640",
      "640I",
      "K1600GT"
  ],
  "BRAVA": [
      "ALPINA",
      "ALTINO",
      "APOLO",
      "AQUILA",
      "ATHENE",
      "BR200",
      "BR250",
      "DAYSTAR",
      "ELEKTRA",
      "LAZER",
      "NEPAL",
      "NEVADA",
      "TEXANA",
      "WINSTAR"
  ],
  "CADILLAC": [
      "ESCALADE"
  ],
  "CAN-AM": [
      "COMMANDER",
      "DEFENDER",
      "DS",
      "DS450",
      "MAVERICK",
      "OUTLANDER",
      "R SPYDER",
      "RENEGADE",
      "SPYDER"
  ],
  "CERRO": [
      "BIX",
      "CE",
      "CE125-12",
      "CE150-13",
      "CERRO",
      "CHAMICAL",
      "COLT",
      "COW",
      "DOT",
      "FIRE",
      "FLY",
      "FORMER",
      "LINK",
      "LINKCE-110",
      "MOV",
      "PRINCE",
      "R9",
      "ROUTER",
      "SCRATCH",
      "SPEED",
      "SUNNY",
      "XTREME"
  ],
  "CF MOTO": [
      "250NK",
      "CF250-A"
  ],
  "CFMOTO": [
      "300NK",
      "400NK",
      "650GT",
      "650MT",
      "650NK",
      "700CL-X",
      "800MT",
      "ATV",
      "CF500",
      "CF500-2",
      "CF500-2A",
      "CF500-5",
      "CF500-5A",
      "CF500-5B",
      "CF500-5C",
      "CF500-A",
      "CF500AU",
      "CFORCE",
      "UFORCE",
      "ZFORCE"
  ],
  "CHANGAN": [
      "CS15",
      "CS75",
      "M201",
      "MD201"
  ],
  "CHERY": [
      "ARRIZO",
      "BEAT",
      "FACE",
      "FULWIN",
      "IQ",
      "M11",
      "M12",
      "NEW",
      "QQ",
      "T11",
      "TIGGO",
      "SKIN"
  ],
  "CHEVROLET": [
      "14000",
      "1500",
      "15-190",
      "16-220",
      "2500",
      "6000",
      "6-150",
      "AGILE",
      "ASTRA",
      "ASTRO",
      "AVALANCHE",
      "AVEO",
      "BLAZER",
      "C 1500",
      "C.60",
      "C10",
      "CAMARO",
      "CAPTIVA",
      "CAVALIER",
      "CELTA",
      "CHEVROLET",
      "CHEVY",
      "CLASSIC",
      "COBALT",
      "CORSA",
      "CORVETTE",
      "CRUZE",
      "CS",
      "D-14000",
      "EQUINOX",
      "G.",
      "GEO",
      "GMT",
      "GRAND",
      "IMPALA",
      "K 1500",
      "K1500",
      "KALOS",
      "KODIAC",
      "KODIAK",
      "LUV",
      "LUV.TRF/TFS/16",
      "LUV-TFS52H",
      "MALIBU",
      "MATIZ",
      "MERIVA",
      "MONTANA",
      "N 300",
      "NKR",
      "NPR",
      "NPR66PL-5LXY",
      "NUBIRA",
      "ONIX",
      "PICK-UP",
      "PRISMA",
      "S 10",
      "S-10",
      "S10",
      "SILVERADO",
      "SONIC",
      "SPARK",
      "SPIN",
      "SUBURBAN",
      "TAHOE",
      "TIGRA",
      "TRACKER",
      "TRAIL",
      "TRAILBLAZER",
      "TRAVERSE",
      "TROOPER",
      "UPLANDER",
      "VECTRA",
      "VENTURE",
      "VITARA",
      "ZAFIRA"
  ],
  "CHRYSLER": [
      "300",
      "300C",
      "CARAVAN",
      "CHEROKEE",
      "CHEROKEE/LAREDO/SPORT/T",
      "CROSSFIRE",
      "DAKOTA",
      "G.CHEROKEE/LAREDO/LIMITE",
      "GRAND",
      "JEEP",
      "NEON",
      "PACIFICA",
      "PT",
      "RAM",
      "SEBRING",
      "STRATUS",
      "TOWN",
      "VOYAGER",
      "WRANGLER"
  ],
  "CITROEN": [
      "BERFUD6",
      "BERFUD6PL",
      "BERFUD6PLDA",
      "BERFUD6PLDAA",
      "BERFUD8PLDA",
      "BERFUD8PLDAA",
      "BERLINGO",
      "BERLINGO2.0",
      "BERMUDPLDAA",
      "BERMUDPLDAB",
      "BERMUL2D",
      "BERMUL2DAB",
      "BERMUL2N",
      "BERMUL2NAB",
      "BERMUNPLDAA",
      "BERMUNPLDAB",
      "C 15",
      "C-",
      "C.15",
      "C.15D/E/RD",
      "C1",
      "C15",
      "C2",
      "C3",
      "C4",
      "C5",
      "C6",
      "C-ELYSEE",
      "C-ELYS",
      "DS",
      "DS3",
      "DS4",
      "GRAND",
      "JUMPER",
      "JUMPY",
      "NUEVO",
      "SAXO",
      "SPACETOURER",
      "XANTIA",
      "XANTIA/TD/I/16V",
      "XSARA",
      "ZX"
  ],
  "CORADIR": [
      "S2-100",
      "S2-100AA"
  ],
  "CORVEN": [
      "DX",
      "ENERGY",
      "EXPERT",
      "HUNTER",
      "INDIANA",
      "LX",
      "MIRAGE",
      "TERRAIN",
      "TRIAX",
      "TXR"
  ],
  "DA DALT": [
      "CHAT",
      "CICLOCARGA",
      "DS",
      "SIGNIA"
  ],
  "DACIA": [
      "BERLINA",
      "BREAK",
      "DOKKER",
      "DUSTER",
      "LOGAN",
      "R1.6",
      "R524",
      "SANDERO"
  ],
  "DAELIM": [
      "BZ1",
      "DAYSTAR",
      "QL",
      "SZ2"
  ],
  "DAEWOO": [
      "CIELO",
      "CIELO/GLE/GL",
      "DAMAS",
      "ESPERO",
      "ISTANA",
      "LABO",
      "LANOS",
      "LEGANZA",
      "MATIZ",
      "MUSSO",
      "NUBIRA",
      "TICO",
      "KALOS"
  ],
  "DAIHATSU": [
      "APPLAUSE",
      "CUORE",
      "DELTA",
      "ECODELTA",
      "F300",
      "FEROZA",
      "GRAND",
      "HIJET",
      "M.CUORE",
      "MIRA",
      "MOVE",
      "ROCKY",
      "SIRION",
      "TERIOS"
  ],
  "DAIMLER BENZ": [
      "LP"
  ],
  "DAYAMA": [
      "DA-250ST"
  ],
  "DEUTZ": [
      "STALLION",
      "STALLION-3",
      "STALLION-4"
  ],
  "DEUTZ AGRALE": [
      "DINAMIC-13",
      "DYNAMIC-01",
      "DYNAMIC-02",
      "DYNAMIC-03",
      "DYNAMIC-04",
      "DYNAMIC-06",
      "DYNAMIC-10",
      "DYNAMIC-11",
      "DYNAMIC-12",
      "DYNAMIC-13",
      "DYNAMIC-14",
      "STARK-",
      "STARK-15",
      "STARK-16",
      "STARK-17",
      "STARK-18"
  ],
  "DEUTZ-AGRALE": [
      "STARK"
  ],
  "DFM": [
      "1064",
      "1216",
      "1216-E5",
      "DUOLICA",
      "T01"
  ],
  "DFSK": [
      "C31",
      "C32",
      "C35",
      "K01H"
  ],
  "DIMEX": [
      "2625",
      "433-160",
      "451-160",
      "551-175",
      "551-190",
      "551-250",
      "55125002",
      "7400-370",
      "74-310",
      "91-370",
      "D 1416",
      "D 1422",
      "D 1621",
      "D 1721",
      "D 1725",
      "D 1730",
      "D 2730"
  ],
  "DINA": [
      "433-160"
  ],
  "DODGE": [
      "B2500",
      "CALIBER",
      "CARAVAN",
      "CARAVAN/SE/ES/LE",
      "CHALLENGER",
      "DAKOTA",
      "DURANGO",
      "FORZA",
      "GRAND",
      "JOURNEY",
      "NEON",
      "NEON/SPORT",
      "NITRO",
      "RAM",
      "RAM2500",
      "STRATUS",
      "STRATUS/LX",
      "VIPER"
  ],
  "DONGFENG": [
      "MINI"
  ],
  "DS": [
      "DS",
      "DS3"
  ],
  "DUCATI": [
      "1199",
      "1299",
      "748",
      "748R",
      "749",
      "899",
      "999",
      "DIAVEL",
      "HYPERMOTARD",
      "M750",
      "MONSTER",
      "MULTISTRADA",
      "PANIGALE",
      "PAUL",
      "SCRAMBLER",
      "SPORT",
      "ST3",
      "STREETFIGHTER",
      "SUPER",
      "SUPERSPORT",
      "XDIAVEL"
  ],
  "ELITE": [
      "ELITE"
  ],
  "ELPRA ELECTRIC": [
      "CITY-M8-CITY",
      "FOLK/ZMAJ05",
      "INDIE",
      "MASTER",
      "TRES",
      "TWIST/LX05/TW"
  ],
  "EUROMOT": [
      "GTX250B",
      "HJ110-2",
      "HJ125-7",
      "HJ125T-11A"
  ],
  "FAW": [
      "ACTIS",
      "T33",
      "X40"
  ],
  "FERESA": [
      "AV-120",
      "TITA"
  ],
  "FERRARI": [
      "330",
      "360",
      "430",
      "456",
      "458",
      "488",
      "550",
      "599",
      "CALIFORNIA",
      "F12",
      "F355",
      "F430",
      "F599",
      "PORTOFINO"
  ],
  "FIAT": [
      "16V",
      "185",
      "330",
      "330.26",
      "500",
      "500L",
      "500X",
      "ARGO",
      "BARCHETTA",
      "BRAVA",
      "BRAVO",
      "CROMA",
      "CRONOS",
      "DOBLO",
      "DUCATO",
      "DUNA",
      "ELBA",
      "EUROCARGO",
      "FIORINO",
      "GRAND",
      "IDEA",
      "IVECO",
      "LINEA",
      "MAREA",
      "MOBI",
      "MULTIPLA",
      "NUEVO",
      "PALIO",
      "PANDA",
      "PREMIO",
      "PREMIO/CS",
      "PULSE",
      "PUNTO",
      "QUBO",
      "SCUDO",
      "SIENA",
      "STILE",
      "STILO",
      "STRADA",
      "TIPO",
      "TORO",
      "UNO",
      "VETRATO",
      "WEEKEND",
      "WORKING"
  ],
  "FIAT IVECO": [
      "170",
      "330-26",
      "330-30",
      "330-35"
  ],
  "FORD": [
      "150",
      "8000",
      "BRONCO",
      "C-1517",
      "CARGO",
      "CF8000",
      "COURIER",
      "D 900-88",
      "D-1614",
      "ECOSPORT",
      "EDGE",
      "ESCAPE",
      "ESCORT",
      "EXCURSION",
      "EXPEDITION",
      "EXPLORER",
      "EXPLORER/XLT",
      "F 150",
      "F 250",
      "F 350",
      "F-100",
      "F100",
      "F-100/88",
      "F100/F101",
      "F-1000",
      "F-14.000",
      "F-14000/HD",
      "F150",
      "F-150",
      "F-250",
      "F250",
      "F350",
      "F-350",
      "F350LARIAT",
      "F400",
      "F-4000",
      "F-4000/D",
      "F-450",
      "F600",
      "F-800",
      "FESTIVA",
      "FIESTA",
      "FOCUS",
      "FREESTAR",
      "F-SUPER",
      "FUSION",
      "GHIA",
      "KA",
      "KUGA",
      "L 8000",
      "L8501",
      "L8513",
      "LN",
      "LN/LNT",
      "LNT",
      "LT",
      "LTS",
      "MAVERICK",
      "MONDEO",
      "MUSTANG",
      "NEW",
      "NUEVA",
      "PUMA",
      "RANGER",
      "RANGER/XL",
      "RANGER2",
      "S-",
      "S-MAX",
      "TAURUS",
      "TERRITORY",
      "TOURNEO",
      "TRANSIT",
      "TRANSIT2.2L"
  ],
  "FOTON": [
      "AUMARK",
      "GRATOUR",
      "TM1",
      "TUNLAND",
      "VIEW"
  ],
  "GAC GONOW": [
      "GA6420CL"
  ],
  "GAF": [
      "GX"
  ],
  "GALLOPER": [
      "DSL",
      "EXCEED",
      "INNOVATION",
      "SANTAMO",
      "TURBO",
      "XL"
  ],
  "GAMMA": [
      "CFORCE",
      "EAGLE",
      "MOUNTAINEER",
      "UFORCE",
      "VANGUARD",
      "ZFORCE"
  ],
  "GARELLI SAHEL": [
      "SAHEL"
  ],
  "GAZ": [
      "22171",
      "2705-24",
      "2705-44",
      "2752",
      "322132",
      "3302",
      "33023",
      "33027",
      "3307",
      "3309",
      "33097",
      "66-40"
  ],
  "GEELY": [
      "515",
      "EMGRAND",
      "LC"
  ],
  "GEO": [
      "TRACKER"
  ],
  "GHIGGERI": [
      "VITA"
  ],
  "GILERA": [
      "AC4",
      "AG50",
      "AX100",
      "C 110",
      "C100",
      "C110",
      "C50",
      "C70",
      "CD",
      "EG2",
      "FR",
      "FR110",
      "FR-110",
      "FR-125",
      "FR150",
      "FR-150",
      "FR200",
      "FR-200",
      "FR-200R",
      "FR-250",
      "FR250",
      "FREE",
      "FR-X",
      "FR-X250",
      "FRX300",
      "FU",
      "FUTURA",
      "FX-125",
      "G1",
      "GA",
      "GA125",
      "GA-125",
      "GA-50",
      "GLA",
      "GLA100",
      "GLA-110",
      "GX1",
      "JL150",
      "LC",
      "LM-50",
      "LX100-3A",
      "LX100-3B",
      "QAX100",
      "QAX110-4T",
      "QAX-125",
      "QM",
      "QM100-12",
      "QM100-7B",
      "QM100-9",
      "QM110-7B",
      "QM110-9",
      "QM125-10",
      "QM125-11A",
      "QM125-7B",
      "QM125T-10A",
      "QM125T-10D",
      "QS90-2",
      "SAHEL",
      "SG",
      "SMASH",
      "SMX",
      "SMX400",
      "SPEEDY",
      "SUPER",
      "VC",
      "VC125",
      "VC150",
      "VC250",
      "VC650",
      "VC-R",
      "VOGE",
      "XKODIAC",
      "YL",
      "YL125T-3",
      "YL200"
  ],
  "GMC": [
      "ACADIA",
      "JIMMY",
      "KODIAK",
      "SAVANA",
      "SIERRA",
      "SUBURBAN",
      "YUKON"
  ],
  "GREAT WALL": [
      "H6",
      "HAVAL",
      "POER",
      "WINGLE"
  ],
  "GUERRERO": [
      "ARGEN-CARGO",
      "G 50",
      "G100",
      "G110",
      "G110DB",
      "G110DL",
      "G125",
      "G3R200",
      "G50",
      "G50AE",
      "G90",
      "GC",
      "GC125",
      "GC125QUEEN",
      "GC150",
      "GC200",
      "GE",
      "GFT110",
      "GFT150",
      "GFT200",
      "GFT250",
      "GFT300",
      "GFT350X",
      "GK110",
      "GMX",
      "GMX250",
      "GN110",
      "GPR",
      "GPR250",
      "GR1",
      "GR5",
      "GR6",
      "GRF250",
      "GRF250X",
      "GRF70",
      "GRF90",
      "GRM",
      "GSL",
      "GSL150",
      "GT",
      "GT110",
      "GT70",
      "GVL400",
      "GXL",
      "GXL150",
      "GXM200",
      "GXR",
      "GXR200",
      "GXR250",
      "GXR300",
      "WG"
  ],
  "HAOJUE": [
      "NK"
  ],
  "HARLEY DAVIDSON": [
      "1200",
      "DYNA",
      "E.CLIDE",
      "ELECTRA",
      "FAT",
      "FL",
      "FLFB",
      "FLFBS",
      "FLH",
      "FLHP",
      "FLHR",
      "FLHRC",
      "FLHRCI",
      "FLHRI",
      "FLHRS",
      "FLHRSI",
      "FLHT",
      "FLHTC",
      "FLHTCI",
      "FLHTCU",
      "FLHTCUI",
      "FLHTK",
      "FLHX",
      "FLHXS",
      "FLS",
      "FLSL",
      "FLSTC",
      "FLSTF",
      "FLSTFB",
      "FLSTFBS",
      "FLSTFI",
      "FLSTN",
      "FLSTNI",
      "FLSTS",
      "FLSTSE",
      "FLTRU",
      "FLTRXS",
      "FXBB",
      "FXBRS",
      "FXCWC",
      "FXD",
      "FXDB",
      "FXDBI",
      "FXDC",
      "FXDCI",
      "FXDF",
      "FXDL",
      "FXDR",
      "FXDSCN",
      "FXDWG",
      "FXDX",
      "FXDXI",
      "FXSB",
      "FXST",
      "FXSTB",
      "FXSTBI",
      "FXSTC",
      "FXSTD",
      "FXSTI",
      "FXSTS",
      "NIGTH",
      "ROAD",
      "SOFTAIL",
      "SPORSTER",
      "SPORTER",
      "SPORTSTER",
      "V-ROD",
      "VRSCA",
      "VRSCAW",
      "VRSCB",
      "VRSCDX",
      "VRSCR",
      "XG",
      "XL",
      "XL1200C",
      "XL1200N",
      "XL1200NS",
      "XL1200R",
      "XL1200T",
      "XL1200V",
      "XL1200X",
      "XL883C",
      "XL883L",
      "XL883N",
      "XL883R",
      "XLH"
  ],
  "HAVAL": [
      "H1",
      "H2",
      "H6",
      "JOLION"
  ],
  "HEI BAO": [
      "HB1605",
      "HB1605-6"
  ],
  "HERO": [
      "DASH",
      "HERO",
      "HUNK",
      "IGNITOR",
      "XPULSE"
  ],
  "HINO": [
      "300",
      "300/",
      "500",
      "XZU720L"
  ],
  "HONDA": [
      "301CBR600F3",
      "ACCORD",
      "AFRICA",
      "BIZ",
      "C100",
      "C100P",
      "C105",
      "C70",
      "CB",
      "CB1",
      "CB1000",
      "CB125F",
      "CB500F",
      "CB500X",
      "CB600F",
      "CB919",
      "CBR",
      "CBR1100",
      "CBR1100X",
      "CBR1100XV",
      "CBR1100XX",
      "CBR300RA",
      "CBR600",
      "CBR600F2",
      "CBR600F2N",
      "CBR600F3",
      "CBR600F4",
      "CBR600FM",
      "CBR600FV",
      "CBR600FV-ED",
      "CBR600RR",
      "CBR600RR3",
      "CBR900",
      "CBR900RE",
      "CBR900RR",
      "CBR900RRL",
      "CBR900RRN",
      "CBR900RRV",
      "CBR900RRW-ED",
      "CBR929RR",
      "CBR954RR",
      "CBX250",
      "CG",
      "CG125",
      "CG-125",
      "CG150",
      "CG150ESD",
      "CG150KS",
      "CGL125",
      "CH",
      "CITY",
      "CIVIC",
      "CR",
      "CR125",
      "CR-125",
      "CR125R",
      "CR125RM",
      "CR125RT",
      "CR-250",
      "CR250R",
      "CR250RP",
      "CR250RT",
      "CR250RV",
      "CR500",
      "CR500R",
      "CR500RM",
      "CR80R",
      "CR80RB",
      "CR80RP",
      "CR80RR",
      "CR80RS",
      "CR80RT",
      "CR80S",
      "CR85R",
      "CRF",
      "CRF1000L",
      "CRF100F",
      "CRF150R",
      "CRF250L",
      "CRF450X",
      "CRF50F",
      "CRF50FE",
      "CRF50FG",
      "CRF70F",
      "CRF80F",
      "CRV",
      "CR-V",
      "CRV-EX",
      "CRVI",
      "ELITE",
      "FALCON",
      "FIT",
      "FOURTRAX",
      "GLH",
      "HR-V",
      "HURRICANE",
      "INVICTA",
      "LEAD",
      "LEGEND",
      "NC700X",
      "NC750X",
      "NC750XA",
      "NC750XD",
      "NEW",
      "NF",
      "NF100",
      "NT700VA",
      "NX4",
      "NX400",
      "NXR125",
      "ODYSSEY",
      "PCX",
      "PILOT",
      "POP100",
      "PRELUDE",
      "RINCON",
      "SDH",
      "SDH125",
      "SDH125-46",
      "SDH125T",
      "SHADOW",
      "STORM",
      "STREAM",
      "TRANSA",
      "TRANSALP",
      "TRX",
      "TRX250D",
      "TRX250ES",
      "TRX250EX",
      "TRX250TE",
      "TRX250TM",
      "TRX250-X",
      "TRX350",
      "TRX350FE",
      "TRX350TE",
      "TRX350TM",
      "TRX400EX",
      "TRX400FGA",
      "TRX400X",
      "TRX420FA",
      "TRX420FA6",
      "TRX420FE",
      "TRX420FM",
      "TRX420FM1",
      "TRX420FPA",
      "TRX420FPE",
      "TRX420FPM",
      "TRX420PFA",
      "TRX420TE",
      "TRX420TE7",
      "TRX420TM1",
      "TRX450",
      "TRX450ER",
      "TRX450FE",
      "TRX450FM",
      "TRX450R",
      "TRX500",
      "TRX500FA",
      "TRX500FE",
      "TRX500FGA",
      "TRX500FPA",
      "TRX500FPE",
      "TRX500FPM",
      "TRX500FW",
      "TRX500TM6",
      "TRX520FM1",
      "TRX650FA",
      "TRX680FA",
      "TRX680FG",
      "TRX680FGA",
      "V MEN",
      "VARADERO",
      "VFR800X",
      "VLX",
      "VT",
      "VT1100",
      "VT1100C",
      "VT750",
      "VT750C2",
      "VT750C2W-ED",
      "VT750CD2",
      "VT750CDA",
      "VT750CDB",
      "VT750CDC",
      "VT750DC",
      "VT750DCB",
      "VTX1300",
      "WAVE",
      "WR-V",
      "XL1000V",
      "XL200",
      "XL200R",
      "XL650V",
      "XL700V",
      "XL700VA",
      "XLR",
      "XR",
      "XR125L",
      "XR250",
      "XR250L",
      "XR250LR",
      "XR250R",
      "XR250RR",
      "XR250RT",
      "XR250RV",
      "XR650L",
      "XRE",
      "XRE300",
      "XRV750",
      "XRV750V",
      "XRV750V-ED",
      "TRX680FAC"
  ],
  "HUMMER": [
      "H2"
  ],
  "HUSQVARNA": [
      "701",
      "FC",
      "FC250",
      "FE",
      "FX",
      "HQV",
      "NORDEN",
      "SVARTPILEN",
      "TC",
      "TE",
      "TE250",
      "TX",
      "TXC250R",
      "VITPILEN",
      "VITPLEN"
  ],
  "HYUNDAI": [
      "ACCENT",
      "AERO",
      "ATOS",
      "ATOZ",
      "AVANTE",
      "CHORUS",
      "COUNTY",
      "COUPE",
      "CRETA",
      "ELANTRA",
      "EURO",
      "FX",
      "GALLOPER",
      "GENESIS",
      "GETZ",
      "GRACE",
      "GRAND",
      "H 100",
      "H 250",
      "H1",
      "H-1",
      "H100",
      "H350",
      "HD",
      "HD35",
      "HD65",
      "HD72",
      "HD78",
      "I 10",
      "I 30",
      "I10",
      "I30",
      "IONIQ",
      "KONA",
      "LANTRA",
      "MATRIX",
      "MIGHTY",
      "NEW",
      "PORTER",
      "PRO",
      "SANTA",
      "SANTAMO",
      "SONATA",
      "STAREX",
      "STARIA",
      "TERRACAN",
      "TIBURON",
      "TRAJET",
      "TUCSON",
      "VELOSTER",
      "VERACRUZ",
      "VERNA"
  ],
  "IMSA": [
      "EVO",
      "IM",
      "IM110",
      "IM110E",
      "IM110T",
      "IM125",
      "IM125R.4",
      "IM125T",
      "IM150",
      "ROAD"
  ],
  "INDIAN": [
      "CHIEF",
      "SCOUT"
  ],
  "INFINITI": [
      "FX"
  ],
  "INNOCENTI": [
      "ELBA"
  ],
  "INTERNACIONAL": [
      "4700",
      "9670"
  ],
  "INTERNATIONAL": [
      "2500",
      "2554",
      "2654",
      "2674",
      "4300",
      "4600",
      "4700",
      "4800",
      "4900",
      "4954",
      "8100",
      "8200",
      "8300",
      "9200",
      "9300",
      "9600",
      "9670",
      "9700",
      "9800",
      "9900",
      "CO9600",
      "COF",
      "MSC",
      "S1800",
      "S1900",
      "S2500",
      "TRANSTAR"
  ],
  "ISUZU": [
      "2.5",
      "2.8",
      "4X2",
      "AMIGO",
      "AXIOM",
      "B241",
      "CHR660",
      "DMAX",
      "D-MAX",
      "DOBLE",
      "NHR",
      "NKR",
      "NPR",
      "NPR65LL3.9D",
      "NPR65PL3.9D",
      "NPR70",
      "NPR75",
      "NQR90",
      "RODEO",
      "SPACE",
      "TFR16H-00",
      "TFR54H",
      "TFR54H-00",
      "TFR54H-10",
      "TFR54H-20",
      "TFR54H-20/00",
      "TFR54HDAL",
      "TFR54HPAL",
      "TFR54HSAL",
      "TFR55H-00",
      "TFR55H-10",
      "TFR55H-20",
      "TFR55HDAL",
      "TFR55HPAL",
      "TFR55HSAL",
      "TFR69HDEI",
      "TFR69HDI",
      "TFR69HPEI",
      "TFR69HSEI",
      "TFR69HSI",
      "TFS",
      "TFS54F-00",
      "TFS54H-10",
      "TFS54H-20",
      "TFS55H-00",
      "TFS55H-10",
      "TFS55H10",
      "TFS55H-20",
      "TFS55H20",
      "TFS55HDAL",
      "TFS55HSAL",
      "TFS69-HD",
      "TFS69HDE",
      "TFS69HDEI",
      "TFS69HPEI",
      "TFS69HSEI",
      "TROOPER",
      "TROPPER",
      "TURBO",
      "VEHICROSS"
  ],
  "IVECO": [
      "120",
      "120E15",
      "140E18",
      "150E20N/150E20T",
      "150E21NCMB1A",
      "150E21NCMC1A",
      "160E21CCM32",
      "160E21NCM32",
      "160E21NCM52",
      "160E23N/160E23T",
      "170E21",
      "170E22",
      "170E22NCM21",
      "170E22NCM31",
      "170E22NCM51",
      "170E22NLM31",
      "170E22NLM51",
      "170E22RCM31",
      "170E22T",
      "170E22TLM22",
      "170E22TLM2B",
      "170E25",
      "170E25T",
      "170E28",
      "170E28NCM22",
      "170E28NCM32",
      "170E28NCM51",
      "170E28T",
      "170E28TLM21",
      "170E30NLA31",
      "170E30NLA52",
      "170E30TLA21",
      "170E30TLA22",
      "170S28",
      "180C33",
      "180E32",
      "180E33",
      "200",
      "200E37",
      "200S36",
      "200S36NCA43",
      "200S36NLA43",
      "200S38",
      "200S41",
      "200S42",
      "200S44",
      "200S44N",
      "230E22",
      "230E24",
      "240E25",
      "240E28",
      "240E28N2M53",
      "240E28NCA63",
      "240E28NCM53",
      "240E28NCM63",
      "240E28NLM54",
      "240E28NLM63",
      "240E28NLM73",
      "240E28RCA33",
      "240E28RCA63",
      "240E30NLA63",
      "240E30NLA73",
      "256",
      "260",
      "260-23",
      "260-25",
      "260-30",
      "260E25",
      "260E28",
      "260E28NCM53",
      "260E30NCM24",
      "260E30NCM54",
      "320-30",
      "320-32",
      "320E18T",
      "35-8",
      "380",
      "380E31",
      "380E37H",
      "380T38",
      "380T42",
      "40.10",
      "40.10/49",
      "400E37",
      "410T44N2M46",
      "410T44NCA16",
      "410T44NCM16",
      "410T44NLA46",
      "430E31T",
      "450",
      "450C33T",
      "450E32T",
      "450E32TY",
      "450E33T",
      "450E33TY",
      "450E37T",
      "450E37T/HR",
      "450E37T/P",
      "450E42TZ",
      "450S38D",
      "450S38T/HR",
      "450S38T/P",
      "450S42T",
      "450S42T/HR",
      "450S42T/P",
      "460S36",
      "460S36T",
      "460S36TLA14",
      "49.10",
      "490S38T",
      "490S38T/P",
      "490S39T",
      "490S41T",
      "490S42T",
      "490S42T/P",
      "490S44T",
      "490S46T",
      "530S36T",
      "530S36TLA85A",
      "570S38T",
      "570S41T",
      "570S42T",
      "570S46T",
      "600S44TLA05",
      "600S44TLA15",
      "70C14G",
      "720E42HT",
      "720T42T",
      "740S41T",
      "740S41TZ",
      "740S42TZ",
      "740T48TLA17",
      "740T48TLM47",
      "80",
      "800S48TZ",
      "90E14",
      "AD410T45",
      "AS440S48T/P",
      "AS600S44TYPA85",
      "AT600S44TYPA85",
      "CC170E22",
      "CC90E14",
      "CURSOR",
      "D1T1A4C8-STRALIS",
      "DAILY",
      "DAILY50C17FURGONVIDRIAD",
      "DC4910",
      "DC4910S",
      "DUCATO",
      "EURO",
      "EUROCARGO",
      "EUROTECH",
      "EUROTRAKKER",
      "HD",
      "M 40.10",
      "MASSIF",
      "MH",
      "ML",
      "MLL",
      "MP190E37W",
      "MP380E37H",
      "MP410E44H/80",
      "MP440E42T",
      "MP720E37HT",
      "R7T4B3B8-STRALIS",
      "STRALIS",
      "TECTOR",
      "TRAKKER",
      "TUBO",
      "TURBO",
      "VERTIS"
  ],
  "JAC": [
      "HFC1035K",
      "HFC1041K",
      "HFC1061K",
      "JAC",
      "S2",
      "S3",
      "S5",
      "S7",
      "T6",
      "X200"
  ],
  "JAGUAR": [
      "DAIMLER",
      "F-PACE",
      "F-TYPE",
      "SOVEREIGN",
      "S-TYPE",
      "VANDENPLAS",
      "XE",
      "XF",
      "XFR",
      "XJ",
      "XJ8",
      "XJR",
      "XJR8",
      "XJS",
      "XK8",
      "XKE",
      "XKR",
      "XKR8",
      "X-TYPE"
  ],
  "JAWA": [
      "CZ",
      "DAYTONA",
      "DD",
      "JAWA",
      "RVM",
      "SPYDER",
      "SUPERNOVA",
      "TEKKEN"
  ],
  "JEEP": [
      "CHEROKEE",
      "CHEROKKE",
      "COMMANDER",
      "COMPASS",
      "GLADIATOR",
      "GR.",
      "GRAND",
      "LIBERTY",
      "PATRIOT",
      "RENEGADE",
      "WRANGLER",
      "WRANGLER/4.0L"
  ],
  "JETOUR": [
      "X70"
  ],
  "JIANSHE": [
      "JS",
      "JS110-3",
      "JS110-3H",
      "JS110ATV",
      "JS110-C",
      "JS125",
      "JS125-3",
      "JS125-5B",
      "JS125-6B",
      "JS125-7",
      "JS125F",
      "JS150-3",
      "JS250ATV-3",
      "JS250ATV-5",
      "JS250ATV-F2",
      "JS400ATV"
  ],
  "JINCHENG": [
      "JC",
      "JC110-9",
      "JC125-12",
      "JC125-17B",
      "JC250-6"
  ],
  "JMC": [
      "CONQUER",
      "N 900",
      "N-601",
      "N800"
  ],
  "JMSTAR": [
      "ALPINA",
      "NEVADA"
  ],
  "KAWASAKI": [
      "BAYOU",
      "BRUTE",
      "CONCOURS",
      "ELIMINATOR",
      "EN",
      "EN500",
      "EN500-A3",
      "EN500-A5",
      "EN500-A6L",
      "EN500A7",
      "EN500B2",
      "EN500-BI",
      "EN500-C",
      "EN500-C1",
      "EN500-C2",
      "ER-6N",
      "EX",
      "EX250",
      "EX250-F",
      "EX250-F10",
      "EX250-F12",
      "EX250-F6",
      "EX250-F6L",
      "EX250-F7",
      "EX250-F8",
      "EX250-F8L",
      "EX250F9",
      "EX250-F9L",
      "EX250-II3",
      "KE100-B11",
      "KFX",
      "KFX450R",
      "KFX50",
      "KFX700",
      "KFX90",
      "KLE650",
      "KLF250A6F",
      "KLF250A8F",
      "KLF250A9F",
      "KLF250ABF",
      "KLR",
      "KLX",
      "KLX110",
      "KLX250",
      "KLX250-D1",
      "KLX250-D4",
      "KLX250-E1",
      "KLX250SF",
      "KLX450A8F",
      "KLX450R",
      "KSF450B8F",
      "KSF450B9F",
      "KSF450BBF",
      "KSF50",
      "KSF50-A3",
      "KSF50BDF",
      "KVF400",
      "KVF400-A1",
      "KVF400-A2",
      "KVF400-B1",
      "KVF400-C",
      "KVF400-C1",
      "KVF400-C2",
      "KVF650",
      "KVF650DCS",
      "KVF650F9F",
      "KVF750",
      "KVF750D8F",
      "KVF750DAF",
      "KVF750DBF",
      "KVF750FBF",
      "KVF750GSC",
      "KVF750HCF",
      "KVF750LCF",
      "KX",
      "KX250",
      "KX250F",
      "KX250-K1",
      "KX250-K3",
      "KX250L",
      "KX250-N2",
      "KX250W9F",
      "KX450F",
      "KX80-W",
      "KX85",
      "LF250A",
      "NINJA",
      "PRAIRE",
      "PRAIRIE",
      "TERYX",
      "TERYX4",
      "VERSYS",
      "VERSYS-X",
      "VN",
      "VN2000",
      "VN2000-A",
      "VN900",
      "VULCAN",
      "Z 400",
      "Z1000",
      "Z650",
      "Z750",
      "Z800",
      "Z900",
      "Z900RS",
      "ZR",
      "ZR1100",
      "ZR750-C3",
      "ZX",
      "ZX10",
      "ZX1000",
      "ZX1000-C",
      "ZX-10R",
      "ZX1200-B",
      "ZX14",
      "ZX600",
      "ZX600-C5",
      "ZX600-D2",
      "ZX600-E3",
      "ZX600-E4",
      "ZX600-F",
      "ZX600-F1",
      "ZX600-F2",
      "ZX600-F3",
      "ZX600-F3L",
      "ZX600-G",
      "ZX600R",
      "ZX636-B",
      "ZX636C",
      "ZX-6R",
      "KVF750GCS",
      "KVF750HCS",
      "KVF750JCF"
  ],
  "KEEWAY": [
      "KLIGHT",
      "RK",
      "TARGET",
      "TX"
  ],
  "KELLER": [
      "CONQUISTA",
      "JET",
      "K65",
      "K65-MT",
      "K65-TR",
      "KN110-10",
      "KN110-7",
      "KN110-8",
      "KN110-9",
      "KN125-11",
      "KN125-12",
      "KN150-13",
      "KN-150-13",
      "KN150-3",
      "KN150GY",
      "KN200GY",
      "KN200R",
      "KN250-3",
      "KN250GY",
      "KN260GY",
      "KR150S",
      "KR200GY",
      "KR200ST-6",
      "KR250ST-8",
      "KR250-STIXE",
      "KR260",
      "KR260A/",
      "KR260GY",
      "STRATUS",
      "XTREME",
      "YB",
      "YB150T",
      "YB150T-10",
      "YB150T-12",
      "YB150T-15",
      "YB150T-2",
      "YB150T-5",
      "YB150T-7",
      "YBR260"
  ],
  "KIA": [
      "AVELLA",
      "BESTA",
      "CARENS",
      "CARNIVAL",
      "CEE",
      "CERATO",
      "CERES",
      "CLARUS",
      "COMBI",
      "FRONTIER",
      "GRAND",
      "GTX",
      "HI-BESTA",
      "K 2900",
      "K 3500",
      "K 3600S",
      "K-2400",
      "K2400",
      "K2500",
      "K-2700",
      "K2700",
      "K3500",
      "K3600",
      "K3600S",
      "MAGENTIS",
      "MOHAVE",
      "MORNING",
      "OPIRUS",
      "PICANTO",
      "PREGIO",
      "PRIDE",
      "RHINO",
      "RIO",
      "RONDO",
      "SEDONA",
      "SELTOS",
      "SEPHIA",
      "SORENTO",
      "SOUL",
      "SPORTAGE",
      "TOPIC",
      "TRADE"
  ],
  "KIDEN": [
      "KD"
  ],
  "KIKAI": [
      "DK",
      "DK125",
      "DK150",
      "DK250"
  ],
  "KONISA": [
      "CL",
      "CL125-15",
      "KE125-26",
      "KE125-28H",
      "KE200",
      "KE250",
      "LF125-J",
      "LX",
      "LX110-3A",
      "LX150-6A",
      "QS110-5",
      "QS200-8",
      "YJ-110B",
      "YJ-150B",
      "YJ-250B",
      "YJ-250D"
  ],
  "KTM": [
      "1090",
      "1190",
      "125",
      "125SX",
      "1290",
      "150",
      "200",
      "200EXC",
      "250",
      "250EXC",
      "250EXC-F",
      "300",
      "350",
      "390",
      "450",
      "450SXF",
      "50",
      "500",
      "50SX",
      "530",
      "65",
      "690",
      "790",
      "85",
      "950",
      "990",
      "ADVENTURE",
      "DUKE",
      "KTM",
      "MOTO",
      "RC"
  ],
  "KYC": [
      "MAMUT",
      "X3"
  ],
  "KYMCO": [
      "ACTIV",
      "AGILITY",
      "AK",
      "AXR110",
      "BET",
      "DINK",
      "DJ",
      "DJ50",
      "DOWNTOWN",
      "GRAND",
      "HEROISM",
      "HIPSTER",
      "KB",
      "LIKE",
      "MXER",
      "MXU",
      "MXU375",
      "PEOPLE",
      "PLEASURE",
      "PULSAR",
      "QUANNON",
      "SPIKE",
      "STRYKER",
      "SUPER",
      "SUPER9",
      "TOP",
      "UXV",
      "UXV500",
      "VENOX",
      "VISA",
      "XCITING",
      "XJR",
      "X-TOWN",
      "YUP",
      "ZING",
      "ZX50"
  ],
  "LADA": [
      "21043",
      "2107",
      "AFALINA",
      "LAIKA",
      "NIVA",
      "SAMARA"
  ],
  "LANCER": [
      "LANCER"
  ],
  "LAND ROVER": [
      "110",
      "DEFENDER",
      "DISCOVERY",
      "FREELANDER",
      "LR3",
      "RANGE",
      "RANGER"
  ],
  "LEGNANO": [
      "CAPRI",
      "IMOLA",
      "MILANO",
      "MODENA",
      "MONZA",
      "VERONA",
      "YG110-2"
  ],
  "LEXUS": [
      "ES",
      "GS",
      "GS300",
      "IS",
      "LC",
      "LS",
      "LS-400",
      "LX",
      "NX",
      "RC",
      "RX",
      "UX"
  ],
  "LIFAN": [
      "1022",
      "FOISON",
      "KP200M",
      "KP200T",
      "KP350",
      "KPV150",
      "LF125",
      "LF150",
      "LF150T",
      "LF5021XXY",
      "M7",
      "MYWAY",
      "V16",
      "X50",
      "X60",
      "X70",
      "XP200"
  ],
  "LOTUS": [
      "ELISE",
      "EVORA",
      "EXIGE"
  ],
  "MACK": [
      "CT",
      "CV713",
      "GRANITE",
      "GU813",
      "RD688S",
      "RD688SX"
  ],
  "MARUTI": [
      "800",
      "ALTO"
  ],
  "MARUTI SUZUKI": [
      "800"
  ],
  "MASERATI": [
      "3200",
      "3500",
      "GHIBLI",
      "GRAN",
      "LEVANTE",
      "QUATROPORTE",
      "QUATTROPORTE",
      "SPYDER"
  ],
  "MAVERICK": [
      "BIG",
      "BLACK",
      "CROSS",
      "FOX",
      "GO",
      "HALLEY",
      "MA",
      "MA110-3",
      "MA110-A",
      "MA125-12",
      "MA125-2",
      "MA125-5",
      "MA125-7",
      "MA125-7A",
      "MA125-GY",
      "MA125-T6",
      "MA150-12",
      "MA150-13",
      "MA200-13",
      "MA200-GY",
      "MA250",
      "MA70",
      "MA70-D",
      "OMEGA",
      "PANTER",
      "SCAPE",
      "SPORT",
      "STREET",
      "TOP",
      "TRY",
      "XRT",
      "MOTORCYCLES"
  ],
  "MAXUS": [
      "DUNESTAR"
  ],
  "MAZDA": [
      "3 ",
      "323",
      "323F",
      "626",
      "929",
      "B 2200",
      "B 2600",
      "B2000/2200",
      "B2200",
      "B2500",
      "B2500D",
      "B2500DUG92",
      "B2900",
      "E2200/PICKUP",
      "MAZDA",
      "MIATA",
      "MPV",
      "MVP",
      "MX3",
      "MX5",
      "MX-5",
      "MX6",
      "PRIMACY",
      "PROTEGE",
      "PROTEGE/PRO",
      "RX7",
      "5 GT"
  ],
  "MCLAREN": [
      "570S"
  ],
  "MERCEDES BENZ": [
      "05",
      "1019",
      "1117",
      "1214",
      "1214/45",
      "1214/48",
      "1214C",
      "1215/48",
      "1215C",
      "1222",
      "1315",
      "1317",
      "1317/37",
      "1414",
      "1417",
      "1417C",
      "1418",
      "1419",
      "1518",
      "1614",
      "1618",
      "1620",
      "1622",
      "1623",
      "1624",
      "1625",
      "1625L",
      "1633",
      "1635",
      "1718",
      "1718A",
      "1718M",
      "1719",
      "1720",
      "1720A",
      "1720K",
      "1721S/32",
      "1722",
      "1722S/32",
      "1723",
      "1728",
      "1824",
      "1834",
      "1840",
      "1919",
      "1919K",
      "1923",
      "1928",
      "1933",
      "1935",
      "1935/1938/1941",
      "1935/1938/41",
      "1935AS",
      "1936",
      "1938",
      "1941",
      "1944S",
      "2038",
      "2222B",
      "2224",
      "2224B",
      "2225",
      "2235",
      "230C",
      "2318",
      "2318/2325",
      "2320",
      "2324",
      "2325",
      "2423",
      "2426",
      "2428",
      "2528",
      "2540",
      "2626",
      "2628",
      "2631",
      "2632K",
      "2636",
      "2638",
      "2640S",
      "2726",
      "290",
      "300",
      "313",
      "320",
      "3228",
      "3229",
      "3233",
      "3234",
      "3328I",
      "3535",
      "412",
      "412D",
      "413",
      "450",
      "500",
      "693",
      "695",
      "709",
      "709D/4250/3700",
      "710",
      "711D",
      "712C",
      "813",
      "912",
      "912/913",
      "912-42",
      "914",
      "914/42,5",
      "914C",
      "958",
      "A 170",
      "A 190",
      "A 200",
      "A140",
      "A150",
      "A160",
      "A170",
      "A200",
      "A250",
      "A45",
      "ACCELO",
      "ACTROS",
      "ACTROS2042",
      "AMG",
      "AROCS",
      "ATEGO",
      "ATRON",
      "AXOR",
      "B170",
      "B180",
      "B200",
      "BMO",
      "C 180",
      "C 200",
      "C 220",
      "C 230",
      "C 240",
      "C 250",
      "C 270",
      "C 280",
      "C 300",
      "C 320",
      "C180",
      "C200",
      "C220",
      "C230",
      "C250",
      "C280",
      "C300",
      "C320",
      "C320W",
      "C350",
      "C400",
      "C43",
      "C55",
      "C63",
      "CL",
      "CLA",
      "CLC",
      "CLK",
      "CLK200",
      "CLK350",
      "CLK430",
      "CLK500",
      "CLS",
      "DB",
      "E 200",
      "E 220",
      "E 240",
      "E 250",
      "E 270",
      "E 280",
      "E 290",
      "E 300",
      "E 320",
      "E 350",
      "E 430",
      "E 450",
      "E 500",
      "E220",
      "E250",
      "E300",
      "E320",
      "E400",
      "E50",
      "E500",
      "E55",
      "E63",
      "G500",
      "GL",
      "GLA",
      "GLB",
      "GLC",
      "GLE",
      "GLK",
      "GLK300",
      "GLS",
      "L 1114",
      "L 1214",
      "L 1215/42",
      "L 1215/48",
      "L 1217",
      "L 1218",
      "L 1218/48",
      "L 1517/48",
      "L 1619/45",
      "L 1620/18/17",
      "L 1620/45",
      "L 1620/51",
      "L 1622/51",
      "L 1623",
      "L 1633",
      "L 1941",
      "L 2221/54",
      "L 2325/51",
      "L 2638",
      "L 710",
      "L 710/37",
      "L 710/42",
      "L 710/712",
      "L 710D/37",
      "L 712",
      "L 911",
      "L 912",
      "L 912-42.5",
      "L 913",
      "L1017A",
      "L-1114",
      "L1214/42/48",
      "L1214/48",
      "L-1215",
      "L-1218",
      "L-1318",
      "L-1414",
      "L-1418",
      "L1418R",
      "L-1618/51",
      "L-1620",
      "L1620/51",
      "L1622",
      "L-1622",
      "L1622/51",
      "L-1624",
      "L1632",
      "L1633",
      "L1634",
      "L-1926",
      "L1938",
      "L-1938",
      "L-1938/1941",
      "L1938/56",
      "L2624",
      "L-2635",
      "L-712/42.5",
      "L-913D/42",
      "L914/42",
      "L914/42.5",
      "L914/42/42.5",
      "LA",
      "LA-1418/51",
      "LAK",
      "LB",
      "LF-1113B",
      "LK",
      "LK-1318",
      "LK1418R",
      "LK1617/39",
      "LK1620",
      "LK-2318",
      "LO",
      "LO-814",
      "LO-915",
      "LP",
      "LPK808",
      "LS",
      "LS1622",
      "LS-1622",
      "LS1622/45",
      "LS1633",
      "LS-1633",
      "LS-1634",
      "LS1938",
      "LS-1941",
      "LS-2635",
      "MB",
      "MB-160",
      "MERCEDES",
      "MERCEDES-AMG",
      "ML",
      "O 500",
      "O-400",
      "O-500",
      "OC1214",
      "OF",
      "OF1722",
      "S 300",
      "S 320",
      "S 400",
      "S 500",
      "S500",
      "S550",
      "S65",
      "SL",
      "SL-320",
      "SLK",
      "SLK200",
      "SLK250",
      "SLK350",
      "SLS",
      "SPORTCOUPE",
      "SPRINTER",
      "V 220",
      "V 230",
      "V230",
      "V250",
      "VIANO",
      "VITO",
      "OF-1724"
  ],
  "MERCEDES": [
      "BENZ/COMIL",
      "BENZ/MARCOPOLO"
  ],
  "METRO": [
      "MD"
  ],
  "MG": [
      "MGF",
      "MIDGET"
  ],
  "MINI": [
      "CLUBMAN",
      "COOPER",
      "COUNTRYMAN",
      "JOHN",
      "ONE"
  ],
  "MITSUBISHI": [
      "3000",
      "3000/GT",
      "ASX",
      "CANTER",
      "COLT",
      "ECLIPSE",
      "ECLIPSE/RS",
      "ENDEAVOR",
      "GALA./SAPPORO",
      "GALA.2000",
      "GALA.OTROS",
      "GALANT",
      "L 200",
      "L 300",
      "L200",
      "L300",
      "LANCER",
      "MONTERO",
      "MONTERO/LS/SR/GLS",
      "NATIVA",
      "NEW",
      "OUTLANDER",
      "PAJERO",
      "SPACE"
  ],
  "MONDIAL": [
      "BD",
      "CARGO",
      "CD200Y",
      "DAX",
      "DAX70",
      "EX150K",
      "FD110L",
      "FD110S",
      "FD125",
      "FD150",
      "FD150L",
      "FD150LN",
      "FD150Q",
      "FD150S",
      "FD150SV",
      "FD150Y",
      "FD200",
      "FD200-RS",
      "FD200S",
      "FD200ST",
      "FD250",
      "FD250H",
      "FD250Q",
      "FD250R",
      "FD250S",
      "FD250ST",
      "FD250X",
      "FD250Y",
      "FD250YR",
      "FD300LN",
      "FD300S",
      "FD50",
      "FD50A",
      "FD50S",
      "FREE",
      "HD",
      "HD125",
      "HD125L",
      "HD150L",
      "HD250A",
      "HD250W",
      "HD254A",
      "KX",
      "KX50",
      "LD",
      "LD110",
      "LD110H",
      "LD110K",
      "LD110L",
      "LD110Q",
      "LD110S",
      "LD110Y",
      "LD110YT",
      "LD125L",
      "MD",
      "MD125K",
      "MD125K1",
      "MD150",
      "MD150N",
      "MONKEY",
      "MS",
      "MS50D",
      "MS50E",
      "QJ100E",
      "QJ110E",
      "RD",
      "RD125",
      "RD125K",
      "RD150K",
      "RD150L",
      "RD200K",
      "RD250",
      "RD250R",
      "RD250S",
      "RV",
      "SCARABEO",
      "SILVER",
      "TB100",
      "TB125",
      "TD",
      "TD150L",
      "TD200K",
      "TD200K-2",
      "VD250P",
      "W150",
      "W250"
  ],
  "MOTO GUZZI": [
      "V7",
      "V85",
      "V9"
  ],
  "MOTOMEL": [
      "B110",
      "B125",
      "C 125",
      "C100",
      "C110",
      "C150",
      "C250",
      "CA",
      "CA100",
      "CA100DB",
      "CA110",
      "CD",
      "CD100",
      "CD125",
      "CG",
      "CITY",
      "CUSTOM",
      "CX",
      "CX150",
      "CX250",
      "DK",
      "DK200",
      "DREAM",
      "E110",
      "ECO",
      "FORZA",
      "GO",
      "GORILLA",
      "GT",
      "GV650",
      "HYOSUNG",
      "KRAKEN",
      "LYNX",
      "M 70",
      "M70",
      "MEDAL",
      "MEGELLI250",
      "MOTARD",
      "MX110",
      "MX250",
      "NF100",
      "PITBULL",
      "PX110",
      "QS250",
      "QUEST",
      "S 250",
      "S110",
      "S2",
      "S250",
      "S3",
      "S70",
      "SIRIUS",
      "SIRIUS200",
      "SKUA",
      "SKUA200",
      "SKUA250",
      "SL",
      "SL125",
      "SQ",
      "SQ50",
      "SR",
      "SR200",
      "STRATO",
      "SUNTRIKE",
      "T150",
      "T200",
      "TRACKER",
      "TRUCKER",
      "VOLKANO",
      "VX150",
      "X3M",
      "XMM"
  ],
  "MV AGUSTA": [
      "BRUTALE",
      "F3",
      "F4",
      "RIVALE",
      "STRADALE",
      "TURISMO"
  ],
  "NAKAI": [
      "CHANGAN",
      "DELIVERY",
      "SC1011",
      "YANTAI"
  ],
  "NISSAN": [
      "350Z",
      "370Z",
      "4X2",
      "4X4",
      "AD",
      "ALTIMA",
      "CABINA",
      "DOBLE",
      "ECO-T.100",
      "FRONTIER",
      "GT-R",
      "INTERSTAR",
      "JUKE",
      "KICKS",
      "L-80.095",
      "LEAF",
      "LUCINO",
      "MARCH",
      "MAXIMA",
      "MICRA",
      "MURANO",
      "NAVARA",
      "NISSAN",
      "NOTE",
      "NP300",
      "PATHFINDER",
      "PATROL",
      "PRIMASTAR",
      "PRIMERA",
      "QUEST",
      "QUEST/XE/GXE",
      "SENTRA",
      "SERENA",
      "SERENA/2.0",
      "SUNNY",
      "TEANA",
      "TERRANO",
      "TIIDA",
      "TITAN",
      "TRADE",
      "VERSA",
      "X TERRA",
      "XTERRA",
      "X-TERRA",
      "X-TRAIL",
      "ARMADA"
  ],
  "NISSAN DIESEL": [
      "CPC16"
  ],
  "NORTON": [
      "COMMANDO"
  ],
  "NUUV": [
      "M+",
      "N SPORT",
      "NGT",
      "U PRO"
  ],
  "OKINOI": [
      "150",
      "250",
      "DROP",
      "NAKED",
      "OKIMOTARD",
      "OKINOI",
      "OKN",
      "R 150",
      "R 250",
      "ST",
      "STREET",
      "SUPERMOTARD",
      "TUNING"
  ],
  "OLMO": [
      "FLASH",
      "XT"
  ],
  "OPEL": [
      "ASTRA",
      "COMBO",
      "CORSA",
      "MERIVA",
      "VECTRA",
      "VIVARO",
      "ZAFIRA"
  ],
  "PANTHER": [
      "110F",
      "110R",
      "70F",
      "90F",
      "WR110",
      "WR125",
      "WR150",
      "WR150T",
      "WR200",
      "WR250"
  ],
  "PANTHER QUADS": [
      "110",
      "200R",
      "70",
      "90",
      "ATV",
      "WR",
      "150"
  ],
  "PEUGEOT": [
      "1007",
      "106",
      "106S16",
      "107",
      "2008",
      "205",
      "206",
      "206XR",
      "206XRD",
      "206XRD3",
      "206XRD3PK",
      "206XRD3PKDAB",
      "206XRD5",
      "206XRD5PK",
      "206XRD5PKDAB",
      "206XRN3",
      "206XRN3PK",
      "206XRN3PKDAB",
      "206XRN5",
      "206XRN5PK",
      "206XRN5PKDAB",
      "206XS",
      "206XSN3DABLA",
      "206XTN5DAB",
      "206XTN5DABLA",
      "207",
      "208",
      "3008",
      "301",
      "306",
      "306BRLD4AA",
      "306BRLD5AA",
      "306BRLHDI4",
      "306BRLN4",
      "306BRLN4AA",
      "306BRLN5",
      "306BRLN5AA",
      "306EQHDI4ABS",
      "306EQHDI5ABS",
      "306EQN4ABS",
      "306EQN5ABS",
      "306EQTD4ABS",
      "306EQTD5ABS",
      "306RLEN3ABS",
      "306SOLHDI4",
      "306SOLN4",
      "306TMHDI5",
      "306TMN3",
      "306TMN5",
      "307",
      "307SW",
      "308",
      "308S",
      "4008",
      "405",
      "405STYLED",
      "405STYLEDAA",
      "405STYLEDPOL",
      "405STYLEN",
      "405STYLENAA",
      "406",
      "406ST",
      "407",
      "408",
      "5008",
      "504",
      "504/GD/G",
      "504D/SRDTCA/XSDTF/XSDTAX",
      "505",
      "508",
      "605",
      "607",
      "806",
      "806/ST",
      "806ST",
      "807",
      "AMB.",
      "BERLINA",
      "BOXER",
      "C3",
      "CABRIOLET",
      "D 504",
      "EXPERT",
      "GRAND",
      "HOGGAR",
      "J5",
      "LA",
      "PAR",
      "PARFUD6",
      "PARFUD6PL",
      "PARFUD6PLDA",
      "PARFUD6PLDAA",
      "PARFUD8PLDA",
      "PARFUD8PLDAA",
      "PARFUN6",
      "PARPAD2PLN1",
      "PARPAD2PLN2",
      "PARPAD2PLN3",
      "PARPADDAA",
      "PARPADDAADAB",
      "PARPAHDI2PLC",
      "PARPAN2PLN1",
      "PARPAN2PLN2",
      "PARPAN2PLN3",
      "PARPANDAA",
      "PARPANDAADAB",
      "PART",
      "PARTNER",
      "PUP",
      "P-UP",
      "RANCH",
      "RCZ",
      "TRAVELLER",
      "XSARA"
  ],
  "PIAGGIO": [
      "M68",
      "MP3",
      "VESPA"
  ],
  "PLYMOUTH": [
      "GRAND",
      "VOYAGER"
  ],
  "POLARIS": [
      "RANGER",
      "RZR",
      "SCRAMBLER",
      "SPORTSMAN"
  ],
  "PONTIAC": [
      "GRAND",
      "SOLSTICE"
  ],
  "PORSCHE": [
      "718",
      "911",
      "911/T/S",
      "911S",
      "911T",
      "987",
      "993",
      "BOXSTER",
      "C2",
      "CARRERA",
      "CAYENNE",
      "CAYMAN",
      "F-PACE",
      "GT3",
      "GT4",
      "MACAN",
      "PANAMERA",
      "R75",
      "TARGA",
      "TURBO"
  ],
  "PROTON": [
      "416",
      "420",
      "420D"
  ],
  "QINGQI": [
      "QS90-2"
  ],
  "RAM": [
      "RAM"
  ],
  "RANGE ROVER": [
      "4.6",
      "VOGUE"
  ],
  "RENAULT": [
      "19",
      "21",
      "25",
      "ALASKAN",
      "CAPTUR",
      "CBH",
      "CLIO",
      "CLIO2",
      "D 19",
      "D16",
      "DG230-20",
      "DG290.TL",
      "DUSTER",
      "EXPRESS",
      "FLUENCE",
      "GRAND",
      "KANGOO",
      "PROFESIONAL",
      "KERAX",
      "KOLEOS",
      "KWID",
      "LAGUNA",
      "LATITUDE",
      "LOGAN",
      "MAGNUM",
      "MASTER",
      "MEGANE",
      "MEGANEII",
      "MIDLUM",
      "MODUS",
      "MS",
      "MURANO",
      "NUEVA",
      "NUEVO",
      "OROCH",
      "PREMIUM",
      "R 19",
      "R19",
      "R-19",
      "R-20",
      "R-21",
      "R-21/GTX",
      "RODEO",
      "SANDERO",
      "SCENIC",
      "STEPWAY",
      "SYMBOL",
      "TIIDA",
      "TRAFIC",
      "TWINGO",
      "VEL"
  ],
  "ROVER": [
      "200",
      "214",
      "216",
      "218",
      "220",
      "414",
      "416",
      "420",
      "45",
      "4S",
      "618",
      "620",
      "623",
      "75",
      "820",
      "COUNTY",
      "E-RS25",
      "R 25",
      "R 45",
      "R 75",
      "R75",
      "RANGE",
      "ROVER"
  ],
  "ROYAL ENFIELD": [
      "BULLET",
      "CLASSIC",
      "CONTINENTAL",
      "HIMALAYAN",
      "INTERCEPTOR",
      "METEOR",
      "SCRAM"
  ],
  "RVM": [
      "250-9",
      "400",
      "CF650",
      "CF650TR",
      "CZ",
      "JAWA250",
      "RM600",
      "RVM",
      "TEKKEN",
      "TEKKEN-250"
  ],
  "SAAB": [
      "9.3",
      "9.5",
      "900",
      "9000",
      "9000/CD/CDE",
      "900S/SE/SE",
      "9-3",
      "AERO",
      "ARC"
  ],
  "SAAB SCANIA": [
      "P 114",
      "P 124",
      "P 94",
      "P93H",
      "P94",
      "R 114",
      "R 124",
      "R113",
      "T 114",
      "T 124",
      "T113H"
  ],
  "SANTANA": [
      "JX",
      "SAMURAI",
      "SJ-410",
      "VITARA"
  ],
  "SANXING": [
      "SXZ1045B",
      "SXZ1045BP",
      "SXZ1047",
      "SXZ1047W",
      "SXZ3043"
  ],
  "SATURN": [
      "VUE"
  ],
  "SCANIA": [
      "114C",
      "114G",
      "114L",
      "124C",
      "124G",
      "124L",
      "144L",
      "164L",
      "93",
      "93H",
      "94D",
      "94G",
      "G310",
      "G320",
      "G340",
      "G360",
      "G380",
      "G400",
      "G410",
      "G420",
      "G440",
      "G450",
      "G460",
      "G470",
      "G480",
      "G500",
      "K250",
      "K310",
      "K400",
      "K440",
      "P 380",
      "P094DB",
      "P114",
      "P114CA",
      "P114GB",
      "P114LA6X2",
      "P124",
      "P124CA",
      "P124CB",
      "P124GA",
      "P124GB",
      "P124LB",
      "P230",
      "P250",
      "P270",
      "P280",
      "P310",
      "P320",
      "P340",
      "P360",
      "P380",
      "P400",
      "P410",
      "P420",
      "P440",
      "P94",
      "P94CB",
      "P94DB",
      "R113",
      "R113E",
      "R113H",
      "R113M",
      "R113MA",
      "R114",
      "R114GA",
      "R114GB",
      "R124",
      "R124GA",
      "R124GB",
      "R124LBX2",
      "R142H",
      "R142HS",
      "R142MA",
      "R142ML",
      "R144",
      "R144LA4X2NA530",
      "R340",
      "R360",
      "R380",
      "R400",
      "R410",
      "R420",
      "R440",
      "R450",
      "R470",
      "R480",
      "R500",
      "R540",
      "R560",
      "R580",
      "R620",
      "S450",
      "S500",
      "S540",
      "S620",
      "T113E",
      "T113H",
      "T114GA",
      "T114GB",
      "T124GA",
      "T124GB",
      "T124LA",
      "R460"
  ],
  "SCANIA/MARCOPOL": [
      "O "
  ],
  "SEAT": [
      "ALHAMBRA",
      "ALTEA",
      "CORDOBA",
      "FURGON",
      "IBIZA",
      "INCA",
      "LEON",
      "TOLEDO"
  ],
  "SERO": [
      "ELECTRIC"
  ],
  "SHERCO": [
      "250",
      "300"
  ],
  "SHINERAY": [
      "G01",
      "T30",
      "T32",
      "X30",
      "X7"
  ],
  "SIAM": [
      "QU",
      "TRENDER"
  ],
  "SKODA": [
      "FELICIA"
  ],
  "SMART": [
      "FORFOUR",
      "FORTWO",
      "PASSION",
      "SMART"
  ],
  "SOUEAST": [
      "DX3"
  ],
  "SPACE": [
      "GHT1047",
      "GHT1055"
  ],
  "SSANGYONG": [
      "ACTYON",
      "CHAIRMAN",
      "ISTANA",
      "KORANDO",
      "KYRON",
      "MUSSO",
      "OM662",
      "REXTON",
      "RODIUS"
  ],
  "SUBARU": [
      "FORESTER",
      "IMPREZA",
      "LEGACY",
      "LEGACY/GX/GL/LS/LX/L",
      "OUTBACK",
      "TRIBECA",
      "WRX",
      "XV"
  ],
  "SUMO": [
      "CG125",
      "KCS2002F",
      "KCS2002G",
      "SB110",
      "SG125",
      "SL200"
  ],
  "SUNEQUIP": [
      "RANCHER"
  ],
  "SUNRA": [
      "GRACE",
      "HAWK",
      "LEO",
      "LMJR",
      "MIKU"
  ],
  "SUZUKI": [
      "ALTO",
      "AN",
      "AX",
      "AX100",
      "AX-100",
      "AX100A",
      "AX100V",
      "BALENO",
      "BURGMAN",
      "CARRY",
      "DL1000",
      "DL1000XA",
      "DL1050RC",
      "DL650",
      "DL650A",
      "DL650XA",
      "DR-Z400S",
      "EN125",
      "EN125-2A",
      "EN125-HU",
      "FUN",
      "G.",
      "GN",
      "GN125E",
      "GN125EN",
      "GN125ER",
      "GN125ES",
      "GN125ET",
      "GN125-F",
      "GN125H",
      "GRAND",
      "GS",
      "GSF650",
      "GSF650SA",
      "GSR",
      "GSR600",
      "GSX",
      "GSX1100F",
      "GSX150",
      "GSX600FS",
      "GSX-R",
      "GSXR",
      "GSX-R1000",
      "GSX-R1100",
      "GSX-R1100WS",
      "GSXR600",
      "GSX-R600",
      "GSX-R600V",
      "GSX-R600WN",
      "GSX-S750",
      "GSX-S750A",
      "GW250",
      "JIMNY",
      "KINGQUAD",
      "LT",
      "LT-A400FK7",
      "LT-A400K7",
      "LT-A750XZK8",
      "LT-F",
      "LT-F250",
      "LT-F250R",
      "LT-F400FK8",
      "LT-R450",
      "LT-Z400",
      "QUADSPORT",
      "RM",
      "RMZ250",
      "RM-Z250",
      "RM-Z450",
      "SAMURAI",
      "SAMURAI/413",
      "SANTANA",
      "SJ",
      "SJ110",
      "SJ110D",
      "SV650A",
      "SV650XA",
      "SWIFT",
      "SX4",
      "VITARA",
      "VITARA/JLX",
      "V-STROM",
      "VZ800",
      "VZ800W",
      "VZ800Y",
      "WAGON",
      "XL-7",
      "CELERIO"
  ],
  "SUZUKI - SANTANA": [
      "SAMURAI"
  ],
  "SYM": [
      "CITYCOM",
      "CROX",
      "FIDDLE",
      "JOYRIDE",
      "MAXSYM",
      "SYMPHONY",
      "T1"
  ],
  "TATA": [
      "207",
      "407",
      "608",
      "608/37",
      "609/38",
      "SAFARI",
      "SFC",
      "SUMO",
      "SUMO-EX",
      "TATAMOBILE",
      "TELCO",
      "TELCOLINE"
  ],
  "TIBO": [
      "AERO",
      "CAT",
      "CHARGER",
      "COASTER",
      "HUNTER",
      "JET",
      "NITRO",
      "RAIDER",
      "RALLY",
      "RIDER",
      "RS",
      "SAMURAI",
      "SHARK",
      "STORM",
      "TAZZ",
      "ZONDA"
  ],
  "TOYOTA": [
      "4 RUNNER",
      "4 RUNNER/V6/2.8",
      "4RN",
      "4WD",
      "AURIS",
      "AVALON",
      "AVENSIS",
      "CAMRY",
      "CAMRY/XLE/LE/DX",
      "C-HR",
      "COASTER",
      "COROLLA",
      "CORONA",
      "ETIOS",
      "EXTRACAB",
      "FJ",
      "FORTUNER",
      "GR",
      "HIACE",
      "HI-ACE",
      "HIGHLANDER",
      "HILUX",
      "INNOVA",
      "LAND",
      "LEXUS",
      "NEW",
      "PRIUS",
      "RAV",
      "RAV4",
      "RAV4J",
      "SIENNA",
      "SR",
      "SR5",
      "SUPRA",
      "SW4",
      "TACOMA",
      "TUNDRA",
      "YARIS",
      "86"
  ],
  "TRIUMPH": [
      "BONNEVILLE",
      "EXPLORER",
      "ROCKET",
      "SCRAMBLER",
      "SPEED",
      "STREET",
      "THRUXTON",
      "TIGER"
  ],
  "TVS": [
      "NTORQ",
      "RTR"
  ],
  "VOGE": [
      "300",
      "500DS",
      "500R",
      "650DS"
  ],
  "VOLKSWAGEN": [
      "10.150",
      "13.180",
      "13.180E",
      "13180",
      "14150",
      "15.180",
      "15.180E",
      "15.190",
      "15160",
      "15170",
      "15180",
      "17.190",
      "17.190DC",
      "17.210",
      "17.230",
      "17.250",
      "17.280",
      "17160",
      "17210",
      "17220",
      "17300",
      "17310",
      "17-310",
      "18.280",
      "18.310",
      "18.330",
      "18310",
      "19.320",
      "19.330",
      "19.360",
      "19.390",
      "19.420",
      "25.320",
      "25.420",
      "26.260",
      "26.280",
      "26.420",
      "26260",
      "26300",
      "26310",
      "31.280",
      "31.320",
      "31.330",
      "5.140",
      "7100",
      "8.150",
      "9.150",
      "9.160",
      "9140",
      "9150",
      "AMAROK",
      "BEETLE",
      "BLACK",
      "BORA",
      "CADDY",
      "CARAVELLE",
      "CONSTELLATION",
      "CROSS",
      "CROSSFOX",
      "DELIVERY",
      "DERBY",
      "FOX",
      "FUSCA",
      "GOL",
      "GOL/GL/GLI",
      "GOLF",
      "HIGH",
      "JETTA",
      "MOVE",
      "MULTIVAN",
      "NEW",
      "NIVUS",
      "NUEVO",
      "PARA",
      "PARATI",
      "PASSAT",
      "PASSAT/GL/VR6/TDI",
      "PEPPER",
      "PHAETON",
      "POLO",
      "QUANTUM",
      "RABBIT",
      "ROUTAN",
      "SANTANA",
      "SAVEIRO",
      "SCIROCCO",
      "SHARAN",
      "SURAN",
      "TAKE",
      "TAOS",
      "T-CROSS",
      "THE",
      "TIGUAN",
      "TOUAREG",
      "TOURAN",
      "TRANSPORTER",
      "VENTO",
      "VIRTUS",
      "VOLKSWAGEN",
      "VOYAGE",
      "WHITE",
      "13.190"
  ],
  "VOLVO": [
      "B 290",
      "B380R",
      "B450R",
      "C30",
      "C70",
      "FH",
      "FH12",
      "FH12-380/4600",
      "FH12-420/3700-",
      "FH400",
      "FH440",
      "FH520",
      "FM",
      "FM12",
      "FM370",
      "FM9",
      "NH12",
      "NH12-380/4300",
      "NH12-380/5600-4X2R",
      "NH12-420/5600",
      "NL",
      "NL10-320/4200",
      "NL10-320/4250",
      "NL10-320/4600",
      "NL10-320/5400",
      "NL10-320/5800",
      "NL-12",
      "NL12-360",
      "NL12-360/4200",
      "NL12-360/4250",
      "NL12-360/4600",
      "NL12-360/5400",
      "NL12-410/4200",
      "NL12-410/4250",
      "NL12-410/4600",
      "NL12-410/5400",
      "S40",
      "S40TD",
      "S60",
      "S70",
      "S80",
      "S90",
      "V40",
      "V40TD",
      "V50",
      "V60",
      "V70",
      "VM",
      "VM17",
      "VM210",
      "VM23",
      "VM260",
      "VM310",
      "VM330",
      "VNL",
      "VNL660",
      "XC",
      "XC40",
      "XC60",
      "XC70",
      "XC90",
      "B270"
  ],
  "WULING": [
      "LZW1010PL",
      "LZW1010VHW",
      "LZW6320H"
  ],
  "YAMAHA": [
      "900",
      "BLASTER",
      "CRYPTON",
      "CYGNUS",
      "FASCINO",
      "FAZER",
      "FJR",
      "FJR1300A",
      "FJR1300AS",
      "FZ",
      "FZ1",
      "FZ16",
      "FZ1N",
      "FZ1S",
      "FZ25",
      "FZ6",
      "FZ6-N",
      "FZ6NHG",
      "FZ6-S",
      "FZ6SAHG",
      "FZ6SHG",
      "FZ8",
      "FZ8-N",
      "FZ8-S",
      "FZ-S",
      "GRIZZLY",
      "MT",
      "MT01",
      "MT03",
      "MT-07",
      "MT-07ST",
      "MT-09ST",
      "NMAX",
      "NM-X",
      "PW50",
      "R15",
      "R6",
      "RAPTOR",
      "RAY",
      "ROAD",
      "ROYAL",
      "SUPER",
      "SZ15RR",
      "T105",
      "T105E",
      "T110",
      "T110C",
      "TDM900",
      "TDM900A",
      "TENERE",
      "TTR",
      "TTR125",
      "TTR125E",
      "TTR125LW",
      "TTR125LWE",
      "TTR230",
      "TTR230T",
      "TTR230W",
      "TZ125",
      "TZR",
      "V STAR",
      "V-MAX",
      "WARRIOR",
      "WR250F",
      "WR250R",
      "WR250X",
      "WR450F",
      "XJ6-F",
      "XJ6-N",
      "XJ6-S",
      "XJR",
      "XP",
      "XSR900",
      "XT",
      "XT225",
      "XT225D",
      "XT600A",
      "XT600E",
      "XT600ED",
      "XT660R",
      "XT660X",
      "XT660Z",
      "XTZ",
      "XTZ125E",
      "XTZ125K",
      "XTZ150",
      "XTZ250",
      "XTZ250ABS",
      "XV1900A",
      "XV950",
      "XV950R",
      "XVA1100",
      "XVS",
      "XVS11",
      "XVS1100",
      "XVS1100A",
      "XVS1300A",
      "XVS65",
      "XVS650",
      "XVS650A",
      "XVS950A",
      "XVS950CUD",
      "XVZ1300AT",
      "YBR",
      "YBR125",
      "YBR125E",
      "YBR125ED",
      "YBR125K",
      "YBR250",
      "YBRZ",
      "YF",
      "YFM",
      "YFM125A",
      "YFM125GBL",
      "YFM125RAW",
      "YFM250",
      "YFM250R",
      "YFM25BYB",
      "YFM25BYGR",
      "YFM25RAL",
      "YFM25RAW",
      "YFM25RSP2X",
      "YFM25RSPA",
      "YFM25RXGY",
      "YFM25RXL",
      "YFM25RZW",
      "YFM25XLE",
      "YFM25XLGR",
      "YFM25XNE",
      "YFM300",
      "YFM350",
      "YFM350A",
      "YFM350AS",
      "YFM350ER",
      "YFM350FW",
      "YFM350FWA",
      "YFM350FWB",
      "YFM350FWHC",
      "YFM350FWX",
      "YFM350FXK",
      "YFM350GW",
      "YFM350R",
      "YFM350U",
      "YFM350X",
      "YFM350XE",
      "YFM350XG",
      "YFM350XH",
      "YFM350XJL",
      "YFM350XJY",
      "YFM350XKGY",
      "YFM350XKR",
      "YFM35FGAGR",
      "YFM35FGAL",
      "YFM35FGDGR",
      "YFM35FGHX",
      "YFM35FGIAG",
      "YFM35FGIHY",
      "YFM35FGIWR",
      "YFM35FGIYB",
      "YFM35FGXL",
      "YFM35FGYL",
      "YFM35GAL",
      "YFM35GXGR",
      "YFM35GYGR",
      "YFM35RAL",
      "YFM35RDW",
      "YFM35RLX",
      "YFM35RSE2W",
      "YFM35RWL",
      "YFM35RXGY",
      "YFM35RXL",
      "YFM35RYW",
      "YFM35RZW",
      "YFM35XHGR",
      "YFM35XXGY",
      "YFM35XYL",
      "YFM400FWAN",
      "YFM450FWA",
      "YFM450FWAN",
      "YFM550F",
      "YFM550FWA",
      "YFM5FGYL",
      "YFM660",
      "YFM660FP",
      "YFM660FWA",
      "YFM660R",
      "YFM660RN",
      "YFM700",
      "YFM700FWAD",
      "YFM700R",
      "YFM700RSEL",
      "YFM700RSES",
      "YFM70RSEY",
      "YFM70RSPX",
      "YFM70RXGY",
      "YFM70RXL",
      "YFM7FGBL",
      "YFM7FGHA",
      "YFM7FGHY",
      "YFM7FGHZ",
      "YFM7FGPAGR",
      "YFM7FGPAL",
      "YFM7FGPBGR",
      "YFM7FGPHA",
      "YFM7FGPHB",
      "YFM7FGPHY",
      "YFM7FGPSED",
      "YFM7FGPSEY",
      "YFM7FGPSPA",
      "YFM7FGPSPX",
      "YFM7FGPYB",
      "YFM7FGXL",
      "YFM7FGYB",
      "YFM7FGYL",
      "YFM7FGZL",
      "YFM7RAL",
      "YFM7RAW",
      "YFM7RDW",
      "YFM7RSE",
      "YFM7RSEA",
      "YFM7RSED",
      "YFM7RSEDB",
      "YFM7RSEZ",
      "YFM80",
      "YFM-80",
      "YFM80F",
      "YFM80G",
      "YFM80GHL",
      "YFM80GHX",
      "YFM80GWL",
      "YFM80GXGR",
      "YFM80H",
      "YFM80-J",
      "YFM80R",
      "YFM80RVL",
      "YFM80RVW",
      "YFM80RXL",
      "YFM90R",
      "YFS",
      "YFS200",
      "YFS-200",
      "YFS200F-GR",
      "YFS200G-L",
      "YFS200GN",
      "YFS200-GR",
      "YFS200H",
      "YFS200H-B",
      "YFS200H-L",
      "YFS200J-R",
      "YFS200J-Y",
      "YFS200K",
      "YFS200K-L",
      "YFS200K-R",
      "YFZ",
      "YFZ350",
      "YFZ350G",
      "YFZ350H",
      "YFZ350H-R",
      "YFZ350H-W",
      "YFZ350J-B",
      "YFZ350J-R",
      "YFZ450",
      "YFZ450R",
      "YFZ450RAL",
      "YFZ450RSEY",
      "YFZ450RSEZ",
      "YFZ450RYL",
      "YFZ450RYW",
      "YFZ450RZL",
      "YFZ450SL",
      "YFZ450SP2X",
      "YFZ450SPV",
      "YFZ450SPX",
      "YFZ450VL",
      "YFZ450WGY",
      "YFZ450WL",
      "YFZ450X",
      "YFZ450XBBZ",
      "YFZ450XGY",
      "YFZ450XL",
      "YFZ450XZL",
      "YFZ450XZW",
      "YFZ450YL",
      "YFZ450YW",
      "YFZ45BB",
      "YFZ45BW",
      "YFZ45FGYB",
      "YFZ45RBL",
      "YFZ45RBW",
      "YFZ45RSED",
      "YP400",
      "YS250",
      "YXR700F",
      "YZ",
      "YZ125F1",
      "YZ250",
      "YZ250F",
      "YZ250FX",
      "YZ250X",
      "YZ426F",
      "YZ450F",
      "YZ450FX",
      "YZ85",
      "YZ85LW",
      "YZF",
      "YZF250R",
      "YZF-R1",
      "YZF-R6",
      "YFM35FGBGR",
      "YFM35FGBL",
      "YFM35FGHB",
      "YFM35FGIAL",
      "YFM35XVL",
      "YFM45FGAL",
      "YFM45FGHB",
      "YFM5FGHA",
      "YFM7FGPSEZ",
      "YFM7RSEB",
      "YFZ450XAL",
      "YFZ45BWE",
      "YFZ45RDL",
      "YFZ45RDW",
      "YFZ45RSEB"
  ],
  "YUEJIN": [
      "HQ1608",
      "NJ",
      "NJ1026",
      "NJ1030DA",
      "NJ1030DAS",
      "NJ1035DER",
      "NJ1041",
      "NJ1061",
      "NJ1062",
      "NJ1062DC",
      "NJ1062DCW",
      "NJ1062DEW",
      "NJ3061",
      "NJ3062",
      "NJ3062DE",
      "NJ6604D",
      "NJ6700D"
  ],
  "ZANELLA": [
      "CECCATO",
      "CICL.",
      "CICL.SOL",
      "CICLOMOTOR",
      "CRUISER",
      "CUATRICICLO",
      "CUSTOM",
      "DELIBERY",
      "DELIVERY",
      "DIRTBIKE",
      "DUE",
      "E STYLER",
      "EXPLORER",
      "FX",
      "FX110",
      "FX125",
      "FX150",
      "FX200",
      "FX250",
      "FX300",
      "FX400",
      "FX50",
      "GFORCE",
      "GFORCE250",
      "GFORCE500",
      "GT2I",
      "HJ",
      "HJ110",
      "HJ125-7",
      "HOT",
      "MADASS",
      "MOD",
      "MOTO",
      "MOTONETA",
      "NEW",
      "PATAGONIA",
      "PATAGONIAN",
      "POCKET",
      "RALLY",
      "RAPTOR",
      "RK6",
      "RKS",
      "RKV",
      "RX",
      "RX150",
      "RX200",
      "RX250",
      "RZ",
      "RZ3",
      "RZ7",
      "SAPUCAI",
      "SEXY",
      "SOL",
      "SPEEDCRUISER",
      "SPEEDLIGHT",
      "STYLER",
      "SWING",
      "TRAKTOR",
      "TRICARGO",
      "TX",
      "VENTO",
      "Z MAX",
      "Z TRUCK",
      "ZANELLA",
      "ZB",
      "ZB110",
      "ZB110D",
      "ZB125",
      "Z-CAP",
      "Z-CARGA",
      "ZCARGA",
      "ZCT",
      "ZCT110L",
      "ZCT125",
      "ZCT150",
      "ZR",
      "ZSC125",
      "ZTT",
      "ZTT200",
      "ZTT250"
  ]
}


// export const modelsByBrand: any = {
//     "3-STAR": [
//       "SXZ1045B",
//       "SXZ1045BDW (CABINA",
//       "SXZ1047",
//       "SXZ1047W",
//       "SXZ3043"
//     ],
//     "ACURA": ["MDX 3.5L", "MDX 3.7L"],
//     "AGRALE": [
//       "10000",
//       "13000",
//       "13000",
//       "14000",
//       "14000 6X2",
//       "6000",
//       "6000",
//       "6000 CD",
//       "6000D",
//       "7500",
//       "7500",
//       "7500 CD",
//       "7500 CD",
//       "8500",
//       "8500",
//       "8500",
//       "8500 CD",
//       "8500 CE",
//       "8500 TCA",
//       "8500 TURBO",
//       "8500CD",
//       "8700",
//       "9200",
//       "9200",
//       "9200",
//       "9200 TCA",
//       "A 10000",
//       "A 8700",
//       "A10000",
//       "A7500",
//       "A8700",
//       "CHASIS CON CABINA A 8700",
//       "FURGOVAN 6000",
//       "MA 10.0 V.4.400 3.8 5 162",
//       "MA 8.5",
//       "MA 8.5",
//       "MT 15.0 LE",
//       "MT 15.0 LE V 5500 6.7 5 226"
//     ],
//     "AIXAM": ["CROSSLINE"],
//     "ALFA ROMEO": [
//       "145",
//       "145 1.8 TS 16V",
//       "145 1.9 JTD",
//       "145 16V TSL 1.8",
//       "145 2.0 QUADRIFOGLIO",
//       "145 2.0 TS 16V",
//       "145 2.0TI",
//       "145 P TD",
//       "145 P TD",
//       "145 QUADRIFOGLIO 2.0TI",
//       "145 TD",
//       "145 TS 16V",
//       "145 TS GT 1.8 16V",
//       "145 TS QUADRIFOGLIO AGV",
//       "146 1.4TS",
//       "146 1.6",
//       "146 1.8 TS 16V",
//       "146 1.8 TS 16V",
//       "146 1.9 JTD",
//       "146 1.9 JTD",
//       "146 16V TS TI STEP A 2.0",
//       "146 TD",
//       "146 TD 2.0",
//       "146 TD LUXE 2.0",
//       "146 TI",
//       "146 TI 16V",
//       "146 TI 2.0",
//       "146 TS GT 1.8 16V",
//       "146 TS GT 1.8 16V",
//       "146 TS L STEP 1.8",
//       "147 1.6 MPI 16V T. SPARK",
//       "147 1.6 MPI 16V T. SPARK",
//       "147 1.9 JTD",
//       "147 1.9 JTD",
//       "147 1.9 JTDM",
//       "147 2.0 MPI 16V TSPARK",
//       "147 2.0 T.SPARK",
//       "147 2.0 T.SPARK",
//       "147 2.0MPI 16V",
//       "147 2.0MPI 16V SELESPEED",
//       "147 2.0MPI 16V TSPARK",
//       "147 3.2 GTA V6 24V",
//       "147 SELESPEED",
//       "147 SELESPEED",
//       "147 T.SPARK 1.6",
//       "147 T.SPARK 1.6",
//       "147 TWIN SPARK 1.6",
//       "147 TWIN SPARK 1.6",
//       "156",
//       "156 1.6 T.SPARK",
//       "156 1.6TS",
//       "156 1.8 T.SPARK",
//       "156 1.8TS",
//       "156 1.9 JTD",
//       "156 1.9 JTD SPORTWAGON",
//       "156 2.0 T. SPARK",
//       "156 2.0TS",
//       "156 2.4 JTD",
//       "156 2.4 JTD SPORTWAGON",
//       "156 2.4TD",
//       "156 2.5 V6 24V",
//       "156 2.5 V6 24V",
//       "156 CROSSWAGON Q4 1.9",
//       "156 GTA 3.2 V6 24V",
//       "159 1.8 TBI",
//       "159 1.9 JTDM",
//       "159 1.9 JTDM",
//       "159 1.9JTD",
//       "159 1.9JTS",
//       "159 2.2JTS",
//       "159 2.2JTS",
//       "159 2.4JTD",
//       "159 3.2 JTS Q4",
//       "159 3.2JTS",
//       "159 COUPE BRERA 2.2 JTS",
//       "159 COUPE BRERA 2.2 JTS",
//       "159 COUPE BRERA 3.2 JTS",
//       "159 TI",
//       "159 TI 2.2",
//       "159 TI 2.2",
//       "166 2.5 V6 24V",
//       "166 3.0 V6 24V",
//       "166 3.2 V6 24V",
//       "2000",
//       "2000 SPIDER",
//       "2600",
//       "ALFA 145",
//       "ALFA 145 TS 16V 2.0",
//       "ALFA 146 TS 1.6",
//       "ALFA 156 2.0",
//       "BRERA",
//       "BRERA",
//       "GIULIA 200CV",
//       "GIULIA 280CV",
//       "GIULIA 280CV VELOCE",
//       "GIULIA QUADRIFOGLIO",
//       "GIULIA QUADRIFOLIO",
//       "GIULIETTA",
//       "GIULIETTA 170CV",
//       "GIULIETTA DISTINCTIVE 120",
//       "GIULIETTA PROGRESSION",
//       "GIULIETTA PROGRESSION 170",
//       "GIULIETTA QUADRIFOGLIO",
//       "GT",
//       "GT 1.8",
//       "GT 1.9 JTD",
//       "GT 3.2 24V",
//       "GT 3.2 JTS",
//       "GT 3.2 V6 24V",
//       "GT 3.2 V6 24V",
//       "GTV",
//       "GTV 2000 TS 16V",
//       "GTV 3.0 T.SPARK 24V",
//       "GTV 3.0 V6 24V",
//       "GTV V6 TB",
//       "GTV6 2.5",
//       "MITO",
//       "MITO DISTINCTIVE",
//       "MITO JUNIOR",
//       "MITO PROGRESSION",
//       "MITO PROGRESSION 135 CV",
//       "MITO QV",
//       "MITO VELOCE",
//       "SPIDER",
//       "SPIDER 2.2 JTS",
//       "SPIDER 3.0 T.SPARK",
//       "SPIDER 3.2 JTS",
//       "SPIDER T.SPARK 16V L",
//       "SPIDER V6",
//       "STELVIO 200CV",
//       "STELVIO 200CV",
//       "STELVIO 280CV SUPER",
//       "STELVIO QUADRIFOGLIO",
//       "STELVIO VELOCE",
//       "T.SPARK 1.6 146",
//       "T_SPARK 16V SPIDER",
//       "VELOCE SPIDER"
//     ],
//     "APPIA": [
//       "ANDINA 110",
//       "BERAKA 125",
//       "BERAKA 125",
//       "BLESS 150",
//       "BREZZA 150",
//       "BREZZA 150",
//       "BREZZA EURO 150",
//       "BREZZA EURO 150",
//       "CITIPLUS 110",
//       "CITIPLUS 110 T",
//       "CITIPLUS110",
//       "DCN 110",
//       "GEREMY 125",
//       "GEREMY 125",
//       "HARDWIND 200",
//       "LEYENDA 150",
//       "LEYENDA 150",
//       "MONTERO 150",
//       "MONTERO 150",
//       "REGIA 125",
//       "REGIA 125",
//       "REGIA 125",
//       "STRONGER 150",
//       "STRONGER 150",
//       "STRONGER 250",
//       "VECTRA 110",
//       "VECTRA 110",
//       "VECTRA 110 N"
//     ],
//     "APRILIA": [
//       "APRILIA SR 160",
//       "DORSODURO 900",
//       "RSV4 RF",
//       "RSV4 RR",
//       "SHIVER 900",
//       "STX150",
//       "TUONO V4 1100 FACTORY",
//       "TUONO V4 1100 RR",
//       "TUONO V4 FACTORY",
//       "TUONO V4 RR"
//     ],
//     "ARCTIC CAT": [
//       "300",
//       "ALTERRA 450",
//       "DVX300",
//       "WILDCAT TRAIL XT",
//       "WILDCAT X",
//       "XR 500"
//     ],
//     "ARO": [
//       "10 SPARTANA",
//       "10.0",
//       "10.1",
//       "10.2",
//       "10.3",
//       "10.4",
//       "10.8",
//       "10.9",
//       "10.9"
//     ],
//     "ASIA": [
//       "AM 825",
//       "BUS DLX 25 AS.",
//       "COMBI",
//       "COMBI AM 825",
//       "R2 VAN",
//       "TOPIC",
//       "TOPIC 12C",
//       "TOPIC 15C DLX",
//       "TOPIC 15C EST",
//       "TOPIC 3V",
//       "TOPIC 3V",
//       "TOPIC AM 725 12C DLX",
//       "TOPIC AM715 STD",
//       "TOPIC AM725",
//       "TOPIC AM-725 15C-DLX",
//       "TOPIC AM-725/15C-DLX",
//       "TOPIC DLX",
//       "TOPIC J2",
//       "TOWER A111",
//       "TOWNER",
//       "TOWNER",
//       "TOWNER",
//       "TOWNER",
//       "TOWNER A111 DLX",
//       "TOWNER A114",
//       "TOWNER A114 SDX",
//       "TOWNER A114/SDX",
//       "TOWNER COACH MPI",
//       "TOWNER COACH MPI STD",
//       "TOWNER COACH SDX",
//       "TOWNER COACH STD",
//       "TOWNER DLX MPI",
//       "TOWNER MPI",
//       "TOWNER P/VAN MPI",
//       "TOWNER PICK UP",
//       "TOWNER PICK UP MPI",
//       "TOWNER SDX MPI",
//       "TOWNER SDX MPI (STD)"
//     ],
//     "AUDI": [
//       "Q2 40 TFSI S TRONIC",
//       "A1 1,4 TFSI S TRONIC",
//       "A1 1.2 T FSI",
//       "A1 1.2 TFSI ATTRACTION",
//       "A1 1.4 TFSI STRONIC",
//       "A1 1.4T FSI",
//       "A1 1.4T FSI SLINE",
//       "A1 SPORTBACK 1.0 TFSI",
//       "A1 SPORTBACK 1.4 TFSI",
//       "A1 SPORTBACK 1.4 TFSI",
//       "A1 SPORTBACK 30 TFSI",
//       "A1 SPORTBACK 35 TFSI",
//       "A3 SPORTBACK 1.9 TDI",
//       "A3 1.4",
//       "A3 1.4 T FSI",
//       "A3 1.4 T FSI",
//       "A3 1.4 T FSI STRONIC",
//       "A3 1.4 TFSI MANUAL (150CV)",
//       "A3 1.6",
//       "A3 1.6",
//       "A3 1.6 MANUAL",
//       "A3 1.8",
//       "A3 1.8",
//       "A3 1.8 T",
//       "A3 1.8 T",
//       "A3 1.8 T FSI",
//       "A3 1.8 T FSI",
//       "A3 1.8 T FSI QUATTRO",
//       "A3 1.8 T FSI QUATTRO",
//       "A3 1.8T QUATTRO",
//       "A3 1.9 TDI",
//       "A3 1.9 TDI",
//       "A3 2.0 FSI",
//       "A3 2.0 T",
//       "A3 2.0 TDI",
//       "A3 2.0 TDI",
//       "A3 2.0 TDI MANUAL",
//       "A3 2.0 TDI QUATTRO",
//       "A3 2.0 TDI QUATTRO",
//       "A3 2.0 TFSI",
//       "A3 2.0T FSI",
//       "A3 3.2 V6 QUATTRO",
//       "A3 CABRIO 1.8 T FSI",
//       "A3 SEDAN 1.4 TFSI S-TRONIC",
//       "A3 SEDAN 2.0TFSI S-TRONIC",
//       "A3 SEDAN 35 TFSI S TRONIC",
//       "A3 SEDAN 40 TFSI S TRONIC",
//       "A3 SPORTBACK 1.4 T FSI",
//       "A3 SPORTBACK 1.4 TFSI",
//       "A3 SPORTBACK 1.6",
//       "A3 SPORTBACK 1.6 MANUAL",
//       "A3 SPORTBACK 1.6 MANUAL",
//       "A3 SPORTBACK 1.6 S-TRONIC",
//       "A3 SPORTBACK 1.8 T FSI",
//       "A3 SPORTBACK 1.8 TFSI",
//       "A3 SPORTBACK 2.0 FSI",
//       "A3 SPORTBACK 2.0 T FSI",
//       "A3 SPORTBACK 2.0 TDI",
//       "A3 SPORTBACK 2.0 TDI",
//       "A3 SPORTBACK 2.0 TDI S-",
//       "A3 SPORTBACK 2.0 TFSI",
//       "A3 SPORTBACK 2.0 TFSI S-",
//       "A3 SPORTBACK 2.0T FSI S-",
//       "A3 SPORTBACK 2.0T FSI S-",
//       "A3 SPORTBACK 2.0TDI",
//       "A3 SPORTBACK 3.2 V6",
//       "A3 SPORTBACK 35 TFSI S",
//       "A3 SPORTBACK 40 TFSI",
//       "A3 SPORTBACK 40 TFSI S",
//       "A4 1.6",
//       "A4 1.8",
//       "A4 1.8",
//       "A4 1.8 T",
//       "A4 1.8 T",
//       "A4 1.8 T",
//       "A4 1.8 T FSI",
//       "A4 1.8 T FSI",
//       "A4 1.8 T FSI MANUAL",
//       "A4 1.8 T FSI MULTITRONIC",
//       "A4 1.8 T QUATTRO",
//       "A4 1.8 T QUATTRO",
//       "A4 1.8 T STANDARD MANUAL",
//       "A4 1.8 TFSI MANUAL",
//       "A4 1.8 TFSI MANUAL STD",
//       "A4 1.8 TFSI MULTITRONIC",
//       "A4 1.8T FSI MULTITRONIC",
//       "A4 1.8T FSI MULTITRONIC STD",
//       "A4 1.8T MANUAL",
//       "A4 1.8T MULTITRONIC",
//       "A4 1.9 TDI",
//       "A4 1.9 TDI",
//       "A4 1.9 TDI MULTITRONIC",
//       "A4 1.9 TDI QUATTRO",
//       "A4 1.9 TDI QUATTRO",
//       "A4 2.0 QUATTRO",
//       "A4 2.0 T FSI QUATTRO",
//       "A4 2.0 TDI",
//       "A4 2.0 TDI",
//       "A4 2.0 TDI ATTRACTION",
//       "A4 2.0 TDI MANUAL",
//       "A4 2.0 TDI MULTITRONIC",
//       "A4 2.0 TFSI 252 CV S-TRONIC",
//       "A4 2.0T FSI",
//       "A4 2.0T QUATTRO",
//       "A4 2.0T QUATTRO",
//       "A4 2.0T SLINE",
//       "A4 2.4",
//       "A4 2.4",
//       "A4 2.4 QUATTRO",
//       "A4 2.5 TDI QUATTRO",
//       "A4 2.5 TDI QUATTRO",
//       "A4 2.5TDI",
//       "A4 2.5TDI",
//       "A4 2.6 QUATTRO",
//       "A4 2.8",
//       "A4 2.8",
//       "A4 2.8 QUATTRO",
//       "A4 2.8 QUATTRO",
//       "A4 3.0",
//       "A4 3.0",
//       "A4 3.0 CABRIO",
//       "A4 3.0 QUATTRO",
//       "A4 3.0 TDI QUATTRO",
//       "A4 3.0 TDI QUATTRO",
//       "A4 3.2 FSI QUATTRO",
//       "A4 3.2 FSI QUATTRO",
//       "A4 3.2 QUATTRO",
//       "A4 40 TFSI",
//       "A4 40 TFSI ADVANCED",
//       "A4 ALLROAD 2.0T FSI",
//       "A4 ATTRACTION 1.8 TFSI",
//       "A4 ATTRACTION 1.8 TFSI",
//       "A4 ATTRACTION 2.0 TDI",
//       "A4 AVANT 1.8 TFSI MANUAL",
//       "A4 AVANT 1.8T FSI",
//       "A4 AVANT 1.8T MULTITRONIC",
//       "A4 AVANT 1.9 TDI",
//       "A4 AVANT 2.0 5V",
//       "A4 AVANT 2.0 TDI",
//       "A4 AVANT 2.0 TDI",
//       "A4 AVANT 2.0T QUATTRO",
//       "A4 AVANT ATTRACTION 1.8",
//       "A4 TDI",
//       "A5 2.0T FSI",
//       "A5 2.0T FSI QUATTRO",
//       "A5 3.0T FSI QUATTRO",
//       "A5 3.2 QUATTRO",
//       "A5 CABRIO 3.0T FSI QUATTRO",
//       "A5 CABRIO 3.2 FSI QUATTRO",
//       "A5 SPORTBACK 1.8 TFSI",
//       "A5 SPORTBACK 2.0 TDI",
//       "A5 SPORTBACK 2.0T FSI",
//       "A5 SPORTBACK 2.0T FSI",
//       "A5 SPORTBACK 2.0T FSI",
//       "A5 SPORTBACK 2.0T FSI",
//       "A5 SPORTBACK 3.0T FSI",
//       "A5 SPORTBACK 3.2 FSI",
//       "A5 SPORTBACK 3.2 FSI",
//       "A6 2.4",
//       "A6 2.4",
//       "A6 2.4 QUATTRO",
//       "A6 2.4 QUATTRO",
//       "A6 2.5 TDI",
//       "A6 2.5 TDI",
//       "A6 2.5 TDI QUATTRO",
//       "A6 2.5 TDI QUATTRO",
//       "A6 2.7 T QUATTRO",
//       "A6 2.7TDI",
//       "A6 2.8",
//       "A6 2.8",
//       "A6 2.8 FSI",
//       "A6 2.8 QUATTRO",
//       "A6 2.8 QUATTRO",
//       "A6 2.8 QUATTRO",
//       "A6 3.0 QUATTRO",
//       "A6 3.0 QUATTRO",
//       "A6 3.0 TDI QUATTRO",
//       "A6 3.0T FSI QUATTRO",
//       "A6 3.2 FSI QUATTRO",
//       "A6 3.2 QUATTRO",
//       "A6 4.2 FSI QUATTRO",
//       "A6 4.2 QUATTRO",
//       "A6 4.2 QUATTRO",
//       "A6 55 TFSI STRONIC QUATTRO",
//       "A6 ALLROAD 2.7 TDI",
//       "A6 ALLROAD 3.0 TDI",
//       "A6 ALLROAD 3.0T FSI",
//       "A6 ALLROAD 3.2 FSI QUATTRO",
//       "A6 ALLROAD 4.2 FSI QUATTRO",
//       "A6 ALLROAD 55 TFSI STRONIC",
//       "A6 AVANT 1.9 TDI",
//       "A6 QUATTRO",
//       "A6 SECURITY 4.2 FSI",
//       "A6 SECURITY 4.2 QUATTRO",
//       "A7 SPORTBACK 3.0 T FSI",
//       "A7 SPORTBACK 3.0 TDI",
//       "A7 SPORTBACK 55 TFSI",
//       "A8 3.0 TDI QUATTRO",
//       "A8 4.0 T FSI QUATTRO",
//       "A8 4.2",
//       "A8 4.2 FSI QUATTRO",
//       "A8 4.2 QUATTRO",
//       "A8 L 4.0 TFSI QUATTRO",
//       "A8 L 4.2 FSI QUATTRO",
//       "A8 L 60 TFSI TIPTRONIC",
//       "ALLROAD 2.5 TDI QUATTRO",
//       "ALLROAD 2.5 TDI QUATTRO",
//       "ALLROAD 2.7 T QUATTRO",
//       "ALLROAD 2.7 TDI QUATTRO",
//       "ALLROAD 3.0 TDI QUATTRO",
//       "ALLROAD 3.0T FSI QUATTRO",
//       "ALLROAD 3.0T FSI QUATTRO",
//       "ALLROAD 3.2 FSI QUATTRO",
//       "ALLROAD 4.2 FSI QUATTRO",
//       "AVANT S6",
//       "E-TRON 55 QUATTRO",
//       "E-TRON SPORTBACK 55",
//       "Q2 1.4 TFSI",
//       "Q2 1.4 TFSI SERIE",
//       "Q2 1.4 TFSI STRONIC",
//       "Q2 2.0 TFSI AT QUATTRO",
//       "Q2 30 TFSI STRONIC",
//       "Q2 35 TFSI ADVANCED",
//       "Q2 35 TFSI ADVANCED",
//       "Q2 35 TFSI S TRONIC",
//       "Q2 35 TFSI S TRONIC SERIE",
//       "Q3 1.4 TFSI",
//       "Q3 1.4 TFSI",
//       "Q3 1.4 TFSI STRONIC",
//       "Q3 2.0 TDI QUATTRO",
//       "Q3 35 TFSI STRONIC",
//       "Q3 40 TFSI STRONIC",
//       "Q3 SPORTBACK 35 TFSI",
//       "Q3 SPORTBACK 40 TFSI",
//       "Q5 2.0 TDI QUATTRO",
//       "Q5 2.0 TFSI S TRONIC",
//       "Q5 2.0T FSI QUATTRO",
//       "Q5 3.0 TDI QUATTRO",
//       "Q5 3.0 TFSI QUATTRO",
//       "Q5 3.2 FSI QUATTRO",
//       "Q5 45 TFSI ADVANCED",
//       "Q5 45 TFSI OFFROAD",
//       "Q5 45 TFSI S-TRONIC",
//       "Q5 SECURITY 2.0 TFSI S",
//       "Q5 SECURITY 45 TFSI S",
//       "Q7 3.0 TDI",
//       "Q7 3.0 TDI QUATTRO",
//       "Q7 3.0 V6T FSI QUATTRO",
//       "Q7 3.0T FSI QUATTRO",
//       "Q7 3.6 QUATTRO",
//       "Q7 4.2 FSI QUATTRO",
//       "Q7 4.2 TDI QUATTRO",
//       "Q7 45 TDI TIPTRONIC",
//       "Q7 55 TFSI TIPTRONIC",
//       "Q7 55 TFSI TIPTRONIC",
//       "Q7 55 TFSI TIPTRONIC SLINE",
//       "Q7 SLINE",
//       "Q8 45 TDI TIPTRONIC",
//       "Q8 55 TFSI TIPTRONIC",
//       "R8 4.2 QUATTRO",
//       "R8 5.2 FSI QUATTRO",
//       "R8 5.2 FSI V10 PLUS QUATTRO",
//       "R8 SPYDER 5.2 FSI QUATTRO",
//       "RS3 SEDAN 2.5 TFSI QUATTRO",
//       "RS3 SPORTBACK 2.5 TFSI",
//       "RS4",
//       "RS4 4.2 QUATTRO",
//       "RS5 4.2 FSI QUATTRO",
//       "RS5 COUPE 2.9TFSI",
//       "RS6",
//       "RS6",
//       "RS6 5.0 T FSI QUATTRO",
//       "RS7 SPORTBACK 4.0 TFSI",
//       "S3",
//       "S3 2.0 T FSI QUATTRO",
//       "S3 2.0 TFSI QUATTRO",
//       "S3 SPORTBACK 2.0 T FSI",
//       "S4",
//       "S4 3.0 TFSI V6 TIPTRONIC",
//       "S4 3.0 V6T FSI QUATTRO",
//       "S4 3.0T FSI QUATTRO",
//       "S4 4.2 QUATTRO",
//       "S5 3.0 V6T FSI QUATTRO",
//       "S5 4.2 QUATTRO",
//       "S5 COUPE 3.0 TFSI V6",
//       "S5 SPORTBACK 3.0 TFSI V6",
//       "S6",
//       "S6 4.0 V8T FSI QUATTRO",
//       "S8 4.2 QUATTRO",
//       "SQ5 3.0 V6 TFSI TIPTRONIC",
//       "TT 1.8",
//       "TT 1.8 T",
//       "TT 1.8 T",
//       "TT 2.0 FSI",
//       "TT COUPE",
//       "TT COUPE 1.8",
//       "TT COUPE 1.8T FSI",
//       "TT COUPE 2.0 T FSI",
//       "TT COUPE 3.2 QUATTRO",
//       "TT COUPE QUATTRO",
//       "TT ROADSTER",
//       "TT ROADSTER 1.8T FSI",
//       "TT ROADSTER 2.0 T FSI",
//       "TT ROADSTER 3.2 QUATTRO",
//       "TT ROADSTER QUATTRO",
//       "TT RS COUPE 2.5 TFSI S",
//       "TT RS COUPE 2.5 TFSI S",
//       "TT RS COUPE 2.5T FSI",
//       "TTS COUPE 2.0 TFSI QUATTRO",
//       "TTS ROADSTER 2.0 TFSI",
//       "A1 SPORTBACK 1.4 TFSI",
//       "A1 SPORTBACK 1.4 TFSI SLINE",
//       "A4 1.8 TFSI ATTRACTION M/T",
//       "A4 3.0 T FSI QUATTRO",
//       "Q3 2.0 TFSI QUATTRO"
//     ],
//     "BAIC": ["D20 HATCHBACK", "X25", "X35", "X55", "X65"],
//     "BAJAJ": [
//       "AVENGER 200",
//       "AVENGER 200",
//       "AVENGER 220",
//       "AVENGER 220",
//       "AVENGER 220",
//       "BOXER BM 150",
//       "DISCOVER 125",
//       "DOMINAR 250",
//       "DOMINAR D400",
//       "PULSAR 150",
//       "PULSAR 150",
//       "PULSAR 180",
//       "PULSAR 180",
//       "PULSAR 200",
//       "PULSAR 200",
//       "ROUSER 135",
//       "ROUSER 135",
//       "ROUSER 135 LS",
//       "ROUSER 135LS",
//       "ROUSER 150",
//       "ROUSER 180",
//       "ROUSER 180",
//       "ROUSER 180",
//       "ROUSER 200",
//       "ROUSER 200",
//       "ROUSER 200",
//       "ROUSER 220",
//       "ROUSER 220",
//       "ROUSER 220",
//       "ROUSER AS 200",
//       "ROUSER NS 125",
//       "ROUSER NS 150",
//       "ROUSER NS 160",
//       "ROUSER NS 200 FI ABS",
//       "ROUSER RS 200",
//       "ROUSER220F",
//       "V 15",
//       "XCD125",
//       "XCD125"
//     ],
//     "BELAVTOMAZ": [
//       "BTM 5336",
//       "BTM 5336",
//       "BTM 53362",
//       "BTM 53371",
//       "BTM 54323",
//       "BTM 54328",
//       "BTM 5551",
//       "BTM 64229"
//     ],
//     "BENELLI": [
//       "180 S",
//       "302 R",
//       "302 S",
//       "502C",
//       "752S",
//       "CAFFENERO",
//       "IMPERIALE 400",
//       "LEONCINO 250",
//       "LEONCINO 500",
//       "SETA",
//       "TNT 135",
//       "TNT 600",
//       "TNT15",
//       "TNT25",
//       "TNT25",
//       "TNT300",
//       "TNT600GT",
//       "TRK 502",
//       "TRK 502 X",
//       "TRK251",
//       "ZAFFERANO"
//     ],
//     "BETA": [
//       "ARK",
//       "ARROW 150",
//       "ARROW 80",
//       "BK150",
//       "BS110-1",
//       "EIKON",
//       "EIKON",
//       "ENDURO",
//       "EURO",
//       "EURO",
//       "FINDER",
//       "FOUR-AE/90",
//       "GRINDER",
//       "LX250-8",
//       "MINICROSS",
//       "PANDA",
//       "QM 125 T 10V",
//       "QM 200 GY",
//       "QM200GY CHRONO",
//       "QM200GY CRHONO",
//       "QM200GY MOTARD 2.0",
//       "QM200GY TR2.0",
//       "QUADRA",
//       "RR450",
//       "RT250 ST",
//       "RT250 ST2",
//       "TEMPO",
//       "ZT310R",
//       "ZT310T",
//       "ZT310V",
//       "ZT310X"
//     ],
//     "BETAMOTOR": [
//       "ADVENTURE",
//       "AKVO",
//       "ARROW",
//       "BK",
//       "BK",
//       "BOY",
//       "BS 110-1",
//       "CAYMAN",
//       "CHRONO 2.5",
//       "ENDURO MX 50",
//       "FOUR",
//       "FOXTER",
//       "GRINDER",
//       "MASTER XLF",
//       "MINICROSS",
//       "MINICROSS LEM",
//       "MOTARD 2.0",
//       "NEOS",
//       "PANDA",
//       "RK6 CROSS",
//       "RK6 ENDURO",
//       "RR 125 2T",
//       "RR 125 MINI",
//       "RR 300 2T",
//       "RR 350 X",
//       "RR 390",
//       "RR 430",
//       "RR 450",
//       "RR 450 X",
//       "RR 480",
//       "RR 4T 430",
//       "SCOOBY",
//       "TEMPO",
//       "TR 2.0",
//       "TR 2.5",
//       "URBA",
//       "X-TRAINER 300",
//       "ZONTES 310R",
//       "ZONTES 310X",
//       "ZT310R",
//       "ZT310T",
//       "ZT310V",
//       "ZT310X",
//       "MOTARD 2.5"
//     ],
//     "BLAC": ["BJ1041", "BJ1041C4DG", "BJ1041C4SG", "BJ1050", "BJ1061"],
//     "BLACKSTONE": [
//       "BKS110",
//       "BKS200",
//       "BKS200 II",
//       "BKS200S",
//       "BKS250",
//       "BKS250 II",
//       "BKS250S",
//       "BKS250S II",
//       "BKS300",
//       "BKS300 4V",
//       "BKS300S",
//       "BKS300S 2V",
//       "BKS300S 4V",
//       "BKS50",
//       "JX200ST-3",
//       "JX250ST",
//       "JX250ST-2",
//       "JX250ST-4A",
//       "JX50ST-4"
//     ],
//     "BMW": [
//       "116 D",
//       "116I",
//       "116I",
//       "118 D",
//       "118D",
//       "118I",
//       "118I",
//       "120D",
//       "120D",
//       "120I",
//       "120I",
//       "120I",
//       "120I",
//       "125 D",
//       "125 D",
//       "125 I",
//       "125 I",
//       "130I",
//       "130I",
//       "135 I",
//       "1M",
//       "218I",
//       "220 I",
//       "220 I",
//       "220I",
//       "300 / 16 / 18 / 20 / 23 / 25",
//       "316 I",
//       "316 I",
//       "316 IA",
//       "316 IA",
//       "316 TI",
//       "316 TI",
//       "316 TI COMPACT",
//       "316I",
//       "316I",
//       "316I",
//       "316I",
//       "316I COMPACT",
//       "318 ED",
//       "318 IA",
//       "318 IC",
//       "318 TDS",
//       "318 TDS",
//       "318 TDS",
//       "318 TI",
//       "318 TI",
//       "318 TI COMPACT",
//       "318 TI COMPACT",
//       "318 TIA",
//       "318 TIA COMPACT",
//       "318I",
//       "318TDS COMPACT",
//       "320 CI",
//       "320 CI",
//       "320 CI",
//       "320 D",
//       "320 D",
//       "320 D TOURING",
//       "320 I",
//       "320 I",
//       "320 IA",
//       "320 IA",
//       "320 IR2",
//       "320 IS",
//       "320 IS",
//       "320 TD",
//       "320 TD",
//       "320I 18",
//       "323",
//       "323 CI",
//       "323 TI",
//       "323 TI",
//       "323I",
//       "323I",
//       "323I",
//       "323TI A",
//       "324 D",
//       "325 CI",
//       "325 CI",
//       "325 CI A",
//       "325 I",
//       "325 IA",
//       "325 TI",
//       "325I",
//       "325I",
//       "325I",
//       "325I",
//       "328",
//       "328",
//       "328 I",
//       "328 I",
//       "328 I",
//       "328 I",
//       "328 I",
//       "328 I",
//       "328 IA",
//       "328 IA",
//       "328 IA",
//       "328 IC",
//       "328 IC",
//       "328 ICA",
//       "328 IS",
//       "328 IS",
//       "328 ISA",
//       "328CI",
//       "328I GRAN TURISMO",
//       "328I TOURING",
//       "330 CI",
//       "330 CI",
//       "330 CI A",
//       "330 D",
//       "330 D",
//       "330 I",
//       "330 I A",
//       "330 XD",
//       "330 XD",
//       "330I",
//       "330I",
//       "335I",
//       "335I",
//       "428I",
//       "430I",
//       "430I",
//       "430I",
//       "435I",
//       "435I",
//       "435I",
//       "440I",
//       "440I",
//       "523 I",
//       "525 D",
//       "525 I",
//       "525 IA",
//       "525 TDS",
//       "525 TDS",
//       "525I",
//       "525I",
//       "528",
//       "528 I",
//       "528 I",
//       "528 IA",
//       "530 IA TOURING",
//       "530D",
//       "530D A",
//       "530D A",
//       "530I",
//       "530IA",
//       "535 I",
//       "535I GT",
//       "535I GT",
//       "540 IA",
//       "540I",
//       "540I",
//       "540I PROTECTION",
//       "540IA PROTECTION",
//       "545I",
//       "550I",
//       "635 CSI",
//       "635 M",
//       "645CI",
//       "645CI CABRIO",
//       "650",
//       "650 I",
//       "650CI",
//       "650CI",
//       "650CI CABRIO",
//       "650I",
//       "650I CABRIO",
//       "740",
//       "740 IA",
//       "740 IL",
//       "740I",
//       "745",
//       "745 I",
//       "745 I",
//       "745IA",
//       "750",
//       "750 I",
//       "750 IAL",
//       "750 IAL",
//       "750 IL",
//       "750LI XDRIVE",
//       "C 600 SPORT",
//       "C650 SPORT",
//       "C650GT",
//       "F 650",
//       "F 650 GS",
//       "F 650 GS DAKAR",
//       "F 650 SM",
//       "F 650 ST",
//       "F 700 GS",
//       "F 750 GS",
//       "F 800 GS",
//       "F 800 GS ADVENTURE",
//       "F 800 R",
//       "F 850 GS",
//       "F650",
//       "F650 CS",
//       "F800ST",
//       "G 310 GS",
//       "G 450 X",
//       "G 650 GS",
//       "G310R",
//       "G650 X CHALLENGE",
//       "G650 X COUNTRY",
//       "G650GS SERTAO",
//       "GR 1200",
//       "K1200 R",
//       "K1200GT",
//       "K1200LT",
//       "K1200S",
//       "K1300GT",
//       "K1300R",
//       "K1600B",
//       "K1600GTL EXCLUSIVE",
//       "M 140 I",
//       "M 140 I",
//       "M 240 I",
//       "M 240 I",
//       "M 3",
//       "M 3",
//       "M 3",
//       "M 3",
//       "M 5",
//       "M135 I",
//       "M135 I",
//       "M135I XDRIVE",
//       "M2",
//       "M235I",
//       "M240I",
//       "M240I XDRIVE",
//       "M3/M5",
//       "M340I XDRIVE",
//       "M4",
//       "M4",
//       "M4",
//       "M440I XDRIVE",
//       "M5 TOURING",
//       "M6",
//       "M6",
//       "M6",
//       "M850I XDRIVE",
//       "R 1100 GS",
//       "R 1100 R",
//       "R 1100 RS",
//       "R 1100 RT",
//       "R 1150 RT",
//       "R 1250 GS",
//       "R 1250 GS ADVENTURE",
//       "R 1250 RT",
//       "R 18",
//       "R 18 CLASSIC",
//       "R NINE T",
//       "R NINE T PURE",
//       "R NINE T RACER",
//       "R NINE T SCRAMBLER",
//       "R NINE T URBAN G/S",
//       "R1100S",
//       "R1150 GS ADVENTURE",
//       "R1150GS",
//       "R1150R",
//       "R1200",
//       "R1200C",
//       "R1200C INDEPENDENT",
//       "R1200CL",
//       "R1200GS",
//       "R1200GS ADVENTURE",
//       "R1200R",
//       "R1200RS",
//       "R1200RT",
//       "S1000R",
//       "S1000RR",
//       "S1000XR",
//       "X1 1.8I",
//       "X1 18I",
//       "X1 2.0D",
//       "X1 2.8 I",
//       "X1 20I",
//       "X1 20I",
//       "X1 25I",
//       "X1 SDRIVE 18I",
//       "X1 SDRIVE 20I",
//       "X2 20I",
//       "X2 M35I",
//       "X2 SDRIVE 20I",
//       "X3 2.0 D",
//       "X3 2.0I",
//       "X3 2.5I",
//       "X3 2.5SI",
//       "X3 20I",
//       "X3 28I",
//       "X3 3.0D",
//       "X3 3.0I",
//       "X3 3.0SI",
//       "X3 35I",
//       "X3 M",
//       "X3 M40I",
//       "X3 XDRIVE 20I",
//       "X3 XDRIVE 25I",
//       "X3 XDRIVE 30E",
//       "X3 XDRIVE 30I",
//       "X4 20I",
//       "X4 28I",
//       "X4 30I X DRIVE",
//       "X4 35I",
//       "X4 M40I",
//       "X5",
//       "X5 XDRIVE 40D",
//       "X5 3.0 SI",
//       "X5 3.0D",
//       "X5 3.0DA",
//       "X5 3.0I",
//       "X5 4.4I",
//       "X5 4.4IA",
//       "X5 4.8I",
//       "X5 4.8IS",
//       "X5 40I X DRIVE",
//       "X5 50I",
//       "X5 M",
//       "X5 XDRIVE 50I",
//       "X6 3.0I",
//       "X6 3.0SI",
//       "X6 3.5I",
//       "X6 30D",
//       "X6 35I",
//       "X6 4.4I",
//       "X6 4.4SI",
//       "X6 50I",
//       "X6 M",
//       "X6 XDRIVE 40I",
//       "Z3",
//       "Z3",
//       "Z3 2.2",
//       "Z3 2.2",
//       "Z3 2.2 I",
//       "Z3 2.2 I",
//       "Z3 2.2I ROADSTER",
//       "Z3 2.8",
//       "Z3 M",
//       "Z3 M ROADSTER",
//       "Z3 ROADSTER",
//       "Z3 ROADSTER",
//       "Z4 2.5",
//       "Z4 2.5I",
//       "Z4 23I",
//       "Z4 3.0 SI",
//       "Z4 3.0 SI",
//       "Z4 3.0I",
//       "Z4 3.0I",
//       "Z4 3.0I",
//       "Z4 35I",
//       "Z4 35IS",
//       "Z4 35IS",
//       "Z4 SDRIVE 35IS",
//       "Z8",
//       "K1600GTL",
//       "640 I",
//       "640 I",
//       "640I COUPE",
//       "K1600GT",
//       "X5 35I",
//       "Z4 20I",
//       "Z4 28I"
//     ],
//     "BRAVA": [
//       "ALPINA 110",
//       "ALPINA 125",
//       "ALTINO 150",
//       "ALTINO 150",
//       "ALTINO 180",
//       "ALTINO 200",
//       "APOLO 110",
//       "APOLO 110",
//       "AQUILA 200",
//       "AQUILA 200",
//       "ATHENE 125",
//       "BR200 JUMPER",
//       "BR200 JUMPER",
//       "BR250 ADVENTURE",
//       "BR250 ADVENTURE",
//       "DAYSTAR 250",
//       "DAYSTAR 250",
//       "DAYSTAR ROUTIER 250",
//       "ELEKTRA 150",
//       "ELEKTRA 150",
//       "LAZER 150",
//       "LAZER 150",
//       "LAZER 150SP",
//       "LAZER 150SP",
//       "LAZER 70",
//       "NEPAL 250",
//       "NEPAL 250",
//       "NEVADA 100",
//       "NEVADA 100",
//       "NEVADA 110",
//       "NEVADA 110",
//       "NEVADA 110 SP",
//       "NEVADA 125",
//       "NEVADA 125",
//       "NEVADA 125 SP",
//       "TEXANA HS 200",
//       "TEXANA HS 200",
//       "TEXANA HS 250",
//       "WINSTAR 150",
//       "WINSTAR 150"
//     ],
//     "CADILLAC": ["ESCALADE ESV"],
//     "CAN-AM": [
//       "COMMANDER 1000 X",
//       "COMMANDER 1000 XT",
//       "COMMANDER 800 R",
//       "DEFENDER BASE HD8",
//       "DEFENDER DPS HD8",
//       "DEFENDER DPS HD9",
//       "DEFENDER HD8",
//       "DEFENDER MAX DPS HD8",
//       "DEFENDER MAX XT HD10",
//       "DEFENDER XT HD10",
//       "DS 250",
//       "DS 450 X",
//       "DS 450 X MS",
//       "DS 90",
//       "DS 90 X",
//       "DS450",
//       "DS450 EFI XMX",
//       "DS450 EFI XXC",
//       "MAVERICK TRAIL BASE 800 EFI",
//       "MAVERICK X3 XRS TURBO R",
//       "MAVERICK XDS 1000 TEFI",
//       "OUTLANDER 1000",
//       "OUTLANDER 1000 EFI",
//       "OUTLANDER 1000 MAX XT",
//       "OUTLANDER 1000 X MR",
//       "OUTLANDER 1000 XT",
//       "OUTLANDER 1000 XT-P",
//       "OUTLANDER 500 XT",
//       "OUTLANDER 650 XT",
//       "OUTLANDER 800 R EFI X MR",
//       "OUTLANDER 800 XT",
//       "OUTLANDER 800R EFI XT-P",
//       "OUTLANDER 800R MAX XT",
//       "OUTLANDER 800RXT",
//       "OUTLANDER DPS 570",
//       "OUTLANDER L MAX DPS 450",
//       "OUTLANDER MAX 400",
//       "OUTLANDER MAX 500 EFI XT",
//       "OUTLANDER MAX 650 XT P",
//       "OUTLANDER MAX 800R EFI",
//       "OUTLANDER MAX 800R EFI XT",
//       "OUTLANDER MAX 800R EFI XT-",
//       "OUTLANDER MAX DPS 570",
//       "OUTLANDER MAX LIMITED",
//       "OUTLANDER MAX LTD 1000",
//       "OUTLANDER MAX LTD 1000R",
//       "OUTLANDER MAX LTD 800",
//       "OUTLANDER MAX XT 1000R",
//       "OUTLANDER MAX XT 400",
//       "OUTLANDER MAX XT 400 HO",
//       "OUTLANDER MAX XT 570",
//       "OUTLANDER MAX XT 650",
//       "OUTLANDER MAX XT 650 HO",
//       "OUTLANDER MAX XT-P 1000R",
//       "OUTLANDER MAX XT-P 850",
//       "R SPYDER F3",
//       "RENEGADE 500",
//       "RENEGADE 1000 X XC",
//       "RENEGADE 500 EFI",
//       "RENEGADE 570",
//       "RENEGADE 800 H.O. EFI",
//       "RENEGADE 800 R",
//       "RENEGADE 800 R EFI XXC",
//       "RENEGADE 800 R XC",
//       "RENEGADE 800 X",
//       "SPYDER",
//       "SPYDER RS",
//       "SPYDER RSS",
//       "SPYDER RT",
//       "OUTLANDER 1000 MAX",
//       "OUTLANDER 1000 MAX",
//       "OUTLANDER 500",
//       "OUTLANDER 500 MAX",
//       "OUTLANDER MAX DPS 500",
//       "OUTLANDER MAX DPS 800R",
//       "OUTLANDER MAX XT-P 1000"
//     ],
//     "CERRO": [
//       "BIX CE 110-21",
//       "BIX CE110-21",
//       "CE 100",
//       "CE 110",
//       "CE 150-13",
//       "CE 150-13",
//       "CE125-12",
//       "CE150-13",
//       "CERRO COW CE70",
//       "CERRO DOT CE70-7",
//       "CHAMICAL CE 150",
//       "COLT CE 200",
//       "COLT CE 200",
//       "COLT CE 200",
//       "COW CE 70",
//       "DOT CE 70-7",
//       "FIRE 110R",
//       "FLY CE110",
//       "FORMER 250",
//       "LINK CE-110",
//       "LINK CE-110",
//       "LINKCE-110",
//       "MOV CE 125QT - 10",
//       "PRINCE CE-150-16",
//       "PRINCE CE-250-16",
//       "R9 CE125T - 8",
//       "ROUTER CE 250-16",
//       "SCRATCH 200 R",
//       "SCRATCH 200 R",
//       "SPEED 200",
//       "SUNNY",
//       "XTREME 250"
//     ],
//     "CF MOTO": ["250NK", "CF250-A"],
//     "CFMOTO": [
//       "300NK",
//       "300NK",
//       "400NK",
//       "400NK",
//       "400NK",
//       "650GT",
//       "650MT",
//       "650MT",
//       "650MT",
//       "650NK",
//       "650NK",
//       "700CL-X",
//       "800MT TOURING",
//       "ATV CFORCE 1000",
//       "ATV CFORCE 400",
//       "ATV CFORCE 625",
//       "CF500",
//       "CF500-2",
//       "CF500-2A",
//       "CF500-5",
//       "CF500-5A",
//       "CF500-5B",
//       "CF500-5C",
//       "CF500-A",
//       "CF500AU 6L",
//       "CFORCE 1000",
//       "CFORCE 450",
//       "CFORCE 550",
//       "CFORCE 625",
//       "UFORCE 1000",
//       "UFORCE 550",
//       "ZFORCE 1000",
//       "ZFORCE 1000 SPORT"
//     ],
//     "CHANGAN": [
//       "CS15",
//       "CS15 COMFORT DCT",
//       "CS15 COMFORT MT",
//       "CS15 LUXURY DCT",
//       "CS75 ELITE AT 2WD",
//       "M201 CARGO VAN",
//       "MD201",
//       "MD201 BOX",
//       "MD201 BOX REFRIGERADA"
//     ],
//     "CHERY": [
//       "ARRIZO 3 1.5 COMFORT",
//       "ARRIZO 3 1.5 COMFORT MT",
//       "ARRIZO 5 1.5 COMFORT MT",
//       "ARRIZO 5 1.5 LUXURY CVT",
//       "ARRIZO 5 1.5 LUXURY MT",
//       "ARRIZO 5 1.5 LUXURY MT",
//       "BEAT",
//       "FACE",
//       "FULWIN HATCHBACK",
//       "FULWIN SEDAN",
//       "FULWIN SEDAN",
//       "IQ 1.1",
//       "M11 A3 1.6",
//       "M12 A3 1.6 HATCHBACK",
//       "NEW QQ CONFORT",
//       "NEW QQ LIGHT",
//       "QQ BASIC",
//       "QQ COMFORT CS",
//       "QQ CONFORT",
//       "QQ LIGHT",
//       "QQ LIGHT LS",
//       "T11 TIGGO 2.0 4X4",
//       "TIGGO 1.6 COMFORT",
//       "TIGGO 2 - 1.5 LUXURY MT",
//       "TIGGO 2 1.5 COMFORT AT",
//       "TIGGO 2 1.5 COMFORT MT",
//       "TIGGO 2 PRO 1.5 5MT",
//       "TIGGO 2 PRO 1.5 5MT",
//       "TIGGO 2 PRO 1.5 CVT",
//       "TIGGO 2.0",
//       "TIGGO 2.0 COMFORT",
//       "TIGGO 2.0 LUXURY A/T",
//       "TIGGO 2.0I LUXURY",
//       "TIGGO 3 1.6 COMFORT",
//       "TIGGO 3 1.6 LUXURY CVT",
//       "TIGGO 3 1.6 LUXURY MT",
//       "TIGGO 3 GLS 1.6",
//       "TIGGO 4 1.5 5MT COMFORT",
//       "TIGGO 4 1.5 CVT COMFORT",
//       "TIGGO 4 2.0 COMFORT CVT",
//       "TIGGO 4 2.0 COMFORT MT",
//       "TIGGO 4 PRO 1.5T CVT",
//       "TIGGO 5 2.0 COMFORT",
//       "TIGGO 5 2.0 COMFORT CVT",
//       "TIGGO 5 2.0 LUXURY CVT",
//       "TIGGO 5 2.0 LUXURY MT",
//       "TIGGO 5 2.0 MT COMFORT",
//       "TIGGO 7",
//       "TIGGO 7 1.5T LUXURY 6MT",
//       "TIGGO 8 1.5T 6DCT LUXURY",
//       "SKIN HATCHBACK",
//       "SKIN SEDAN"
//     ],
//     "CHEVROLET": [
//       "14000 TURBO DIESEL",
//       "14000 TURBO DIESEL",
//       "1500 SILVERADO 4X2",
//       "1500 SPORTSIDE",
//       "15-190",
//       "16-220",
//       "2500 SILVERADO HD",
//       "6000",
//       "6000 TURBO DIESEL",
//       "6000 TURBO DIESEL",
//       "6-150 TURBO DIESEL",
//       "AGILE 5P 1.4 LS",
//       "AGILE 5P 1.4 LT",
//       "AGILE 5P 1.4 LTZ",
//       "AGILE 5P LS SPIRIT 1.4",
//       "AGILE 5P LT SPIRIT 1.4",
//       "AGILE 5P LTZ SPIRIT 1.4",
//       "AGILE LS 5P 1.4N",
//       "AGILE LS PROCREAUTO",
//       "AGILE LT + LLANTAS 5P 1.4N",
//       "AGILE LT 5P 1.4N",
//       "AGILE LTZ 1.4N C/GOOGLE",
//       "AGILE LTZ 5P 1.4N",
//       "AGILE LTZ EFFECT 1.4N",
//       "ASTRA 1.6",
//       "ASTRA 1.8",
//       "ASTRA CARAVAN GL 1.7 TD",
//       "ASTRA CARAVAN GLS 1.7TD",
//       "ASTRA CD 2.0 16V",
//       "ASTRA CD 2.0 16V",
//       "ASTRA CD 2.0 DTI",
//       "ASTRA CD-X 2.0 16V",
//       "ASTRA GL 1.7 TURBO DIESEL",
//       "ASTRA GL 1.8 MPFI",
//       "ASTRA GL 1.8L",
//       "ASTRA GL 2.0",
//       "ASTRA GL 2.0",
//       "ASTRA GL 2.0 DTI",
//       "ASTRA GL 2.0 DTI",
//       "ASTRA GL 2.0 MPFI",
//       "ASTRA GL 2.0 MPFI",
//       "ASTRA GL DI 16V",
//       "ASTRA GLS",
//       "ASTRA GLS",
//       "ASTRA GLS",
//       "ASTRA GLS 1.7 TD",
//       "ASTRA GLS 1.7 TD",
//       "ASTRA GLS 2.0",
//       "ASTRA GLS 2.0",
//       "ASTRA GLS 2.0 DTI",
//       "ASTRA GLS 2.0 DTI",
//       "ASTRA GLS DI 16V",
//       "ASTRA GLS FULL",
//       "ASTRA GSI 16V",
//       "ASTRA GSI 2.4 16V",
//       "ASTRA TURBO DIESEL",
//       "ASTRO",
//       "ASTRO",
//       "ASTRO LS",
//       "AVALANCHE 5.3 GAS V8 4X4",
//       "AVALANCHE 5.3L",
//       "AVALANCHE 5.3L",
//       "AVEO 1.2 LS",
//       "AVEO 1.4",
//       "AVEO 1.4",
//       "AVEO G3 LS 1.6N M/T",
//       "AVEO G3 LT 1.6N A/T",
//       "AVEO G3 LT 1.6N M/T",
//       "AVEO LS",
//       "AVEO LS 1.6N MT",
//       "AVEO LT 1.4",
//       "AVEO LT 1.6",
//       "AVEO LT 1.6N AT",
//       "AVEO LT 1.6N MT",
//       "BLAZER",
//       "BLAZER",
//       "BLAZER",
//       "BLAZER 2.8 DLX T.",
//       "BLAZER 2.8 DLX",
//       "BLAZER 2.8DLX",
//       "BLAZER 2.8DLX",
//       "BLAZER 4X4",
//       "BLAZER DLX",
//       "BLAZER DLX (4X2)",
//       "BLAZER DLX 2.4 NAFTA 4X2",
//       "BLAZER DLX 2.4 NAFTA 4X2",
//       "BLAZER DLX MPFI",
//       "BLAZER DLX MPFI",
//       "BLAZER DLX TURBO DIESEL",
//       "BLAZER DLX TURBO DIESEL",
//       "BLAZER EXECUTIVE 4.3",
//       "BLAZER EXECUTIVE 4.3",
//       "BLAZER K5",
//       "BLAZER K5",
//       "BLAZER LS",
//       "BLAZER LS",
//       "BLAZER LT",
//       "BLAZER S10",
//       "BLAZER S10",
//       "BLAZER TAHOE 4X4",
//       "C 1500",
//       "C 1500 SILVERADO",
//       "C 1500 SILVERADO (CAB.Y",
//       "C.60",
//       "C10 BLAZER/S10",
//       "CAMARO",
//       "CAMARO",
//       "CAMARO LS",
//       "CAMARO LT",
//       "CAMARO RS",
//       "CAMARO RS",
//       "CAMARO SIX SS",
//       "CAMARO SIX SS",
//       "CAMARO SS",
//       "CAMARO ZL1",
//       "CAPTIVA",
//       "CAPTIVA 2.0 DIESEL",
//       "CAPTIVA 2.0 DIESEL",
//       "CAPTIVA 2.0 DIESEL LT",
//       "CAPTIVA 2.0 DIESEL LT",
//       "CAPTIVA 2.0 DIESEL LTX",
//       "CAPTIVA 2.0 DIESEL LTZ",
//       "CAPTIVA 2.0DIESEL LTX",
//       "CAPTIVA 2.0DIESEL LTZ",
//       "CAPTIVA 2.4 DIESEL",
//       "CAPTIVA 2.4 LT MANUAL",
//       "CAPTIVA LS 2.4 M/T",
//       "CAPTIVA LT 2.2 D M/T",
//       "CAPTIVA LT 2.4 M/T",
//       "CAPTIVA LT AWD",
//       "CAPTIVA LTZ",
//       "CAPTIVA LTZ 2.2 D A/T",
//       "CAPTIVA SPORT",
//       "CAPTIVA SPORT AWD",
//       "CAVALIER LJX69",
//       "CELTA 1.4 LS",
//       "CELTA 1.4 LS",
//       "CELTA 1.4 LS AA",
//       "CELTA 1.4 LS AA",
//       "CELTA 1.4 LT",
//       "CELTA 1.4 LT",
//       "CELTA 1.4 LT AA",
//       "CELTA 1.4 LT AA",
//       "CELTA 1.4L",
//       "CELTA 1.4N LT SPIRIT",
//       "CELTA 1.4N LT SPIRIT",
//       "CELTA ADVANTAGE AA+DIR",
//       "CELTA ADVANTAGE PACK",
//       "CELTA LS 1.4N AA+DIR",
//       "CELTA LS 1.4N AB+ABS",
//       "CELTA LT 1.4N AA+DIR",
//       "CELTA LT 1.4N AB+ABS",
//       "CELTA LT 1.4N AB+DIR",
//       "CELTA LT 1.4N PACK",
//       "CHEVROLET CAMION 6000",
//       "CHEVROLET CAMION 6000",
//       "CHEVROLET CONQUEST",
//       "CHEVROLET CONQUEST",
//       "CHEVROLET TRACKER AWD",
//       "CHEVROLET TRACKER AWD",
//       "CHEVROLET TRACKER AWD",
//       "CHEVROLET TRACKER FWD LT",
//       "CHEVROLET TRACKER FWD",
//       "CHEVY",
//       "CLASSIC 1.4 N LS",
//       "CLASSIC 1.4 N LT",
//       "CLASSIC 3 PTAS CARGO 1.4N",
//       "CLASSIC 3 PTAS CITY LS + AA",
//       "CLASSIC 3 PTAS CITY LS 1.4N",
//       "CLASSIC 3 PTAS LIFE 1.4N",
//       "CLASSIC 3 PTAS LS AA + DIR",
//       "CLASSIC 3 PTAS LT 1.4N",
//       "CLASSIC 4 P LS + LLANTAS",
//       "CLASSIC 4 P LS ABS+AIRBAG",
//       "CLASSIC 4 P LS PACK 1.4N",
//       "CLASSIC 4 P LS PROCREAUTO",
//       "CLASSIC 4 PTAS LS 1.4N",
//       "CLASSIC 4 PTAS LS AA + DIR",
//       "CLASSIC 4 PTAS LT 1.4N",
//       "CLASSIC 4 PTAS LT SPIRIT 1.4N",
//       "CLASSIC 4P LS A+D SPIRIT",
//       "CLASSIC 4P LT PACK 1.4N",
//       "CLASSIC 4P LT SPIRIT PACK",
//       "CLASSIC AA+DIR 1.4 N LS",
//       "CLASSIC WAGON LIFE 1.4N",
//       "CLASSIC WAGON LS AA + DIR",
//       "CLASSIC WAGON LS TAXI",
//       "CLASSIC WAGON LT 1.4N",
//       "COBALT LT 1.3D M/T",
//       "COBALT LT1.8N M/T",
//       "COBALT LTZ 1.8N A/T",
//       "COBALT LTZ 1.8N M/T",
//       "CORSA",
//       "CORSA",
//       "CORSA",
//       "CORSA 1.6",
//       "CORSA 1.7 DIESEL",
//       "CORSA 1.7 DIESEL",
//       "CORSA 3 PTAS CITY 1.6N",
//       "CORSA 3 PTAS CITY 1.6N",
//       "CORSA 3 PTAS GL 1.7 DIESEL",
//       "CORSA 3 PTAS GL 1.7 DIESEL",
//       "CORSA 3 PTAS. CITY 1.4 N 16V",
//       "CORSA 3 PTAS. GL 1.7 WIND",
//       "CORSA 3 PTAS. GL 1.7 WIND",
//       "CORSA 3 PTAS. SUPER 1.4 16V",
//       "CORSA 3 PTAS.SPORT 1.4",
//       "CORSA 3 PTAS.SPORT 1.4",
//       "CORSA 3P. CITI 1.0 N.",
//       "CORSA 3P. CITI 1.0 N.",
//       "CORSA 3PTAS CITY 1,4 N 16V",
//       "CORSA 3PTAS SUPER 1.4 N",
//       "CORSA 4 PTAS. GL 1.7 D",
//       "CORSA 4 PTAS. GL 1.7 D",
//       "CORSA 4 PTAS. GLS 1.6 8V N",
//       "CORSA 4 PTAS. GLS 1.6 8V N",
//       "CORSA 4 PTAS. GLS 1.7 D",
//       "CORSA 4 PTAS. GLS 1.7 D",
//       "CORSA 4 PTAS. GLS SUPER 1.6",
//       "CORSA 4 PTAS. GLS SUPER 1.6",
//       "CORSA 4 PTAS. SUPER DIESEL",
//       "CORSA 4 PTAS. SUPER DIESEL",
//       "CORSA 4 PTAS. WIND 1.7 D",
//       "CORSA 4 PTAS. WIND 1.7 D",
//       "CORSA 4 PUERTAS GL 1.7",
//       "CORSA 4 PUERTAS GL 1.7",
//       "CORSA 4 PUERTAS GL A/A Y",
//       "CORSA 4 PUERTAS GL A/A Y",
//       "CORSA 4 PUERTAS GLN",
//       "CORSA 4 PUERTAS GLN",
//       "CORSA 4 PUERTAS GLS 16V",
//       "CORSA 4 PUERTAS GLS 16V",
//       "CORSA 4 PUERTAS WIND 1.6",
//       "CORSA 4 PUERTAS WIND 1.6",
//       "CORSA 4DR SUPER 1.0 DOHC",
//       "CORSA 4DR SUPER 1.0 DOHC",
//       "CORSA 4P GL SUPER 1.6L",
//       "CORSA 4P GL SUPER 1.6L",
//       "CORSA CITY D",
//       "CORSA CLASSIC 1.4",
//       "CORSA CLASSIC 1.6 SPIRIT",
//       "CORSA CLASSIC 16 SOHC",
//       "CORSA CLASSIC 3 P CARGO",
//       "CORSA CLASSIC 3 P HANDY",
//       "CORSA CLASSIC 3 PTAS BASE",
//       "CORSA CLASSIC 3 PTAS BASE",
//       "CORSA CLASSIC 3 PTAS",
//       "CORSA CLASSIC 3 PTAS CITY",
//       "CORSA CLASSIC 3 PTAS CITY",
//       "CORSA CLASSIC 3 PTAS GL 1.4",
//       "CORSA CLASSIC 3 PTAS GL 1.6",
//       "CORSA CLASSIC 3 PTAS GLS",
//       "CORSA CLASSIC 3 PTAS GLS",
//       "CORSA CLASSIC 3 PTAS LIFE",
//       "CORSA CLASSIC 3 PTAS. BASE",
//       "CORSA CLASSIC 3 PTAS. BASE",
//       "CORSA CLASSIC 3 PTAS. BASE",
//       "CORSA CLASSIC 3 PTAS. BASE",
//       "CORSA CLASSIC 3 PTAS. FULL",
//       "CORSA CLASSIC 3 PTAS. FULL",
//       "CORSA CLASSIC 3 PTAS. FULL",
//       "CORSA CLASSIC 3 PTAS. FULL",
//       "CORSA CLASSIC 3P AA + DIR",
//       "CORSA CLASSIC 3P CITY 1.4",
//       "CORSA CLASSIC 4 PTAS BASE",
//       "CORSA CLASSIC 4 PTAS BASE",
//       "CORSA CLASSIC 4 PTAS GL 1.4",
//       "CORSA CLASSIC 4 PTAS GL 1.6",
//       "CORSA CLASSIC 4 PTAS GLS",
//       "CORSA CLASSIC 4 PTAS GLS",
//       "CORSA CLASSIC 4 PTAS. BASE",
//       "CORSA CLASSIC 4 PTAS. BASE",
//       "CORSA CLASSIC 4 PTAS. BASE",
//       "CORSA CLASSIC 4 PTAS. BASE",
//       "CORSA CLASSIC 4 PTAS. FULL",
//       "CORSA CLASSIC 4 PTAS. FULL",
//       "CORSA CLASSIC 4 PTAS. FULL",
//       "CORSA CLASSIC 4 PTAS. FULL",
//       "CORSA CLASSIC BASE 1.4 N",
//       "CORSA CLASSIC GL 1.4 N",
//       "CORSA CLASSIC GLS 1.4 N",
//       "CORSA CLASSIC SUPER 1.6 N",
//       "CORSA CLASSIC SW BASE + AC",
//       "CORSA CLASSIC SW BASE/AC",
//       "CORSA CLASSIC SW LIFE 1.4N",
//       "CORSA CLASSIC WAGON BASE",
//       "CORSA CLASSIC WAGON BASE",
//       "CORSA CLASSIC WAGON BASE",
//       "CORSA CLASSIC WAGON BASE",
//       "CORSA CLASSIC WAGON FULL",
//       "CORSA CLASSIC WAGON FULL",
//       "CORSA CLASSIC WAGON FULL",
//       "CORSA CLASSIC WAGON FULL",
//       "CORSA CLASSIC WAGON GL",
//       "CORSA CLASSIC WAGON",
//       "CORSA CLASSIC WAGON GLS",
//       "CORSA CLASSIC WAGON GLS",
//       "CORSA COMBO 1.7",
//       "CORSA COMBO 1.7 DIESEL",
//       "CORSA COMFORT 1.7D",
//       "CORSA COMFORT 1.8",
//       "CORSA EASYTRONIC 1.8L",
//       "CORSA EASYTRONIC 1.8L",
//       "CORSA EASYTRONICA 1.8L",
//       "CORSA GL 1.4 E.F.I.",
//       "CORSA GL 1.6 E.F.I.",
//       "CORSA GL 1.6 M.P.F.I.",
//       "CORSA GL 1.6 M.P.F.I.",
//       "CORSA GL 1.6 M.P.F.I.",
//       "CORSA GL 1.6 MPFI",
//       "CORSA GL 1.6 MPFI",
//       "CORSA GL 1.6 MPFI",
//       "CORSA GL 1.6 MPFI 3P",
//       "CORSA GL 1.6 MPFI 3P",
//       "CORSA GL 1.6 MPFI 4P C/AA",
//       "CORSA GL 1.6 MPFI 5P",
//       "CORSA GL 1.6 MPFI 5P",
//       "CORSA GL 1.6 MPFI SWING",
//       "CORSA GL 1.6D",
//       "CORSA GL 1.7 DIESEL",
//       "CORSA GL PLUS",
//       "CORSA GLS 1.6 M.P.F.I.",
//       "CORSA GLS 16V",
//       "CORSA GLS 16V",
//       "CORSA GLS 16V 1.6 MPFI",
//       "CORSA II 1.8",
//       "CORSA II 1.8L 4P GL AA+DIR",
//       "CORSA II 1.8L 5P GL AA+DIR",
//       "CORSA II 4 P GL AA+DIR GAS",
//       "CORSA II 4 PTAS GL DTI 1.7",
//       "CORSA II 4 PTAS GL DTI 1.7",
//       "CORSA II 4 PTAS GL GAS 1.8",
//       "CORSA II 4 PTAS GL GAS 1.8",
//       "CORSA II 4 PTAS GL PACK DTI",
//       "CORSA II 4 PTAS GL PACK DTI",
//       "CORSA II 4 PTAS GL PACK GAS",
//       "CORSA II 4 PTAS GLS DTI 1.7",
//       "CORSA II 4 PTAS GLS DTI 1.7",
//       "CORSA II 4 PTAS GLS GAS 1.8",
//       "CORSA II 4 PTAS. GLS FULL DTI",
//       "CORSA II 4 PTAS. GLS FULL DTI",
//       "CORSA II 4 PTAS. GLS FULL",
//       "CORSA II 4 PTAS. GLS FULL",
//       "CORSA II 4P 1.8L EASYTRONIC",
//       "CORSA II 4P 1.8L EASYTRONIC",
//       "CORSA II 4P CD DIESEL",
//       "CORSA II 4P CD GAS 1.8",
//       "CORSA II 4P CD GAS 1.8",
//       "CORSA II 4P GL AA DIR DIESEL",
//       "CORSA II 4P GLS GAS 1.8",
//       "CORSA II 5 PTAS GL DTI 1.7",
//       "CORSA II 5 PTAS GL DTI 1.7",
//       "CORSA II 5 PTAS GL GAS 1.8",
//       "CORSA II 5 PTAS GL GAS 1.8",
//       "CORSA II 5 PTAS GL PACK DTI",
//       "CORSA II 5 PTAS GL PACK GAS",
//       "CORSA II 5 PTAS GL PACK GAS",
//       "CORSA II 5 PTAS GLS DTI 1.7",
//       "CORSA II 5 PTAS GLS DTI 1.7",
//       "CORSA II 5 PTAS GLS GAS 1.8",
//       "CORSA II 5 PTAS GLS GAS 1.8",
//       "CORSA II 5 PTAS. GLS FULL DTI",
//       "CORSA II 5 PTAS. GLS FULL DTI",
//       "CORSA II 5 PTAS. GLS FULL",
//       "CORSA II 5 PTAS. GLS FULL",
//       "CORSA II 5P 1.8L EASYTRONIC",
//       "CORSA II 5P CD GAS 1.8",
//       "CORSA II 5P CD GAS 1.8",
//       "CORSA II 5P GL AA DIR DIESEL",
//       "CORSA II 5P GL AA+DIR GAS",
//       "CORSA II 5P REEF 1.8 N",
//       "CORSA II CD 1.8",
//       "CORSA II CD 1.8",
//       "CORSA II CD DIESEL",
//       "CORSA II EASYTRONIC 1.8",
//       "CORSA II EASYTRONIC 1.8",
//       "CORSA II GL PACK DTI 1.7",
//       "CORSA II GL PACK GAS 1.8",
//       "CORSA PLUS",
//       "CORSA WAGON 1.4 MPFI",
//       "CORSA WAGON 1.4 MPFI",
//       "CORSA WAGON 1.7 DIESEL",
//       "CORSA WAGON 1.7 DIESEL",
//       "CORSA WAGON 5 PTAS. GL",
//       "CORSA WAGON 5 PTAS. GL",
//       "CORSA WAGON 5 PTAS. GL",
//       "CORSA WAGON 5 PTAS. GL",
//       "CORSA WAGON 5 PTAS. GLS",
//       "CORSA WAGON 5 PTAS. GLS",
//       "CORSA WAGON GL",
//       "CORSA WAGON GL",
//       "CORSA WAGON GL 1.6 MPFI",
//       "CORSA WAGON GL A/A Y D/P",
//       "CORSA WAGON GL A/A Y D/P",
//       "CORSA WAGON GLS 16V",
//       "CORSA WAGON GLS 16V",
//       "CORSA WAGON GLS 16V",
//       "CORSA WAGON GLS 16V MPFI",
//       "CORSA WAGON SUPER 1.0",
//       "CORSA WAGON SUPER 1.0",
//       "CORSA WAGON WIND 1.6 N",
//       "CORSA WAGON WIND 1.6 N",
//       "CORSA WAGON WIND 1.7 D",
//       "CORSA WAGON WIND 1.7 D",
//       "CORSA WIND 1,7 DIESEL 3",
//       "CORSA WIND 1.6 MPFI 3P",
//       "CORSA WIND 1.6 MPFI 3P",
//       "CORSA WIND 1.6 MPFI 5P",
//       "CORSA WIND 1.6 MPFI 5P",
//       "CORSA WIND 1.6L",
//       "CORSA WIND 1.7 DIESEL 3",
//       "CORVETTE",
//       "CORVETTE",
//       "CORVETTE Z06",
//       "CRUZE 1.4 LTZ",
//       "CRUZE 1.8 LT",
//       "CRUZE 1.8 LT",
//       "CRUZE 1.8 LT A/T",
//       "CRUZE 1.8 LT A/T",
//       "CRUZE 1.8 LTZ",
//       "CRUZE 1.8 LTZ",
//       "CRUZE 1.8 LTZ A/T",
//       "CRUZE 1.8 LTZ A/T",
//       "CRUZE 2.0D LT",
//       "CRUZE 2.0D LT A/T",
//       "CRUZE 2.0D LT A/T",
//       "CRUZE 2.0D LTZ",
//       "CRUZE 2.0D LTZ A/T",
//       "CRUZE 2.0D LTZ A/T",
//       "CRUZE 4P 1.4 TURBO LS MT",
//       "CRUZE 4P 1.4 TURBO LT MT",
//       "CRUZE 4P 1.4 TURBO LTZ + AT",
//       "CRUZE 4P 1.4 TURBO LTZ AT",
//       "CRUZE 4P 1.4 TURBO LTZ MT",
//       "CRUZE 4P 1.4 TURBO",
//       "CRUZE 4P 1.4T LT AT",
//       "CRUZE 4P 1.4T LT MT",
//       "CRUZE 4P 1.4T LTZ AT",
//       "CRUZE 4P 1.4T LTZ MT",
//       "CRUZE 4P 1.4T MIDNIGHT AT",
//       "CRUZE 4P 1.4T MT",
//       "CRUZE 4P 1.4T PREMIER AT",
//       "CRUZE 4P 1.4T PREMIER II AT",
//       "CRUZE 4P 1.4T PREMIER MT",
//       "CRUZE 5P 1.4 TURBO LT MT",
//       "CRUZE 5P 1.4 TURBO LTZ + AT",
//       "CRUZE 5P 1.4 TURBO LTZ AT",
//       "CRUZE 5P 1.4 TURBO LTZ MT",
//       "CRUZE 5P 1.4 TURBO",
//       "CRUZE 5P 1.4T LT AT",
//       "CRUZE 5P 1.4T LT MT",
//       "CRUZE 5P 1.4T LTZ AT",
//       "CRUZE 5P 1.4T PREMIER AT",
//       "CRUZE 5P 1.4T PREMIER II AT",
//       "CRUZE 5P 1.4T PREMIER MT",
//       "CRUZE 5P 1.4T RS AT",
//       "CRUZE II HB LS 1.8",
//       "CRUZE II LS 1.8",
//       "CRUZE LS",
//       "CRUZE LT",
//       "CS 10 703 P.U",
//       "D-14000",
//       "EQUINOX",
//       "EQUINOX",
//       "EQUINOX 1.5T LS FWD AT",
//       "EQUINOX 1.5T LT FWD AT",
//       "EQUINOX 1.5T PREMIER AWD",
//       "EQUINOX 1.5T RS FWD A",
//       "EQUINOX LS",
//       "G. VITARA 5P 2.0 A/T",
//       "G. VITARA 5P 2.0 A/T",
//       "G. VITARA 5P 2.0 M/T",
//       "G. VITARA 5P 2.0 M/T",
//       "G. VITARA 5P 2.0 TDI",
//       "G. VITARA 5P 2.0 TDI",
//       "GEO TRACKER",
//       "GMT SILVERADO D.ASPIRADA",
//       "GMT SILVERADO D.ASPIRADA",
//       "GMT SILVERADO D.TUR LUX",
//       "GMT SILVERADO D.TUR LUX",
//       "GMT SILVERADO D.TURBO",
//       "GMT SILVERADO D.TURBO",
//       "GMT SILVERADO D.TURBO",
//       "GMT SILVERADO D.TURBO",
//       "GMT SILVERADO D.TURBO",
//       "GMT SILVERADO D.TURBO",
//       "GMT SILVERADO DLX 4.1",
//       "GMT SILVERADO DLX 4.1",
//       "GMT SILVERADO G.4.1 MPFI",
//       "GMT SILVERADO G.4.1 MPFI",
//       "GRAND BLAZER",
//       "GRAND BLAZER DLX TURBO",
//       "GRAND BLAZER DLX TURBO",
//       "GRAND VITARA 2.0A",
//       "GRAND VITARA 2.0M",
//       "GRAND VITARA TDI",
//       "IMPALA LTZ",
//       "K 1500",
//       "K 1500 SPORTSIDE",
//       "K1500 SILVERADO",
//       "KALOS SE",
//       "KODIAC",
//       "KODIAK 14-190",
//       "LUV 2.2 (CABINA DOBLE)",
//       "LUV 2.5 DIESEL CABINA",
//       "LUV 2.5 DIESEL CABINA",
//       "LUV 2.5 TD CABINA DOBLE",
//       "LUV 2.5 TD CABINA SIMPLE",
//       "LUV 2.8 TD CABINA DOBLE",
//       "LUV DLX (CABINA DOBLE) 4X4",
//       "LUV SPACECAB DLX",
//       "LUV TFR16HDL",
//       "LUV TFR54HDL 2.5 DIESEL C.",
//       "LUV TFR54HSL 2.5 DIESEL",
//       "LUV TFR55HDL-TD (CABINA",
//       "LUV TFS16HDL",
//       "LUV TFS16HDL",
//       "LUV TFS55HDL-TD (CABINA",
//       "LUV.TRF/TFS/16 HDL",
//       "LUV-TFS52H",
//       "MALIBU",
//       "MATIZ",
//       "MERIVA 1.8",
//       "MERIVA EASYTRONIC 1.8L",
//       "MERIVA GL 1.7 DTI",
//       "MERIVA GL 1.8",
//       "MERIVA GL PLUS 1.7DTI",
//       "MERIVA GL PLUS 1.8 SOHC",
//       "MERIVA GLS 1.7 DTI",
//       "MERIVA GLS 1.8",
//       "MERIVA GLS 1.8 DOHC",
//       "MERIVA GLS 1.8 SOHC",
//       "MERIVA GLS EASYRTONIC 1.8",
//       "MERIVA GLS EASYTRONIC 1.8",
//       "MONTANA LS",
//       "MONTANA SPORT",
//       "N 300",
//       "NKR",
//       "NPR",
//       "NPR66PL-5LXY",
//       "NUBIRA",
//       "ONIX 1.0T AT LTZ",
//       "ONIX 1.0T AT PREMIER",
//       "ONIX 1.0T MT LTZ",
//       "ONIX 1.0T MT PREMIER",
//       "ONIX 1.0T MT RS",
//       "ONIX 1.2 LT MT",
//       "ONIX 1.2 LT MT TECH",
//       "ONIX 1.2 LT MT TECH ONSTAR",
//       "ONIX 1.2 MT",
//       "ONIX 1.4 MT LT",
//       "ONIX 1.4 N EFFECT",
//       "ONIX 1.4 N LTZ A/T",
//       "ONIX 5P 1.4 N ACTIV",
//       "ONIX 5P 1.4 N ACTIVE",
//       "ONIX JOY 1.4 MT",
//       "ONIX JOY 5P 1.4 N LS MT",
//       "ONIX JOY 5P 1.4 N LS MT +",
//       "ONIX JOY BLACK 1.4 MT",
//       "ONIX LTZ 1.4",
//       "ONIX PLUS 1.0T AT LTZ",
//       "ONIX PLUS 1.0T AT PREMIER",
//       "ONIX PLUS 1.0T AT PREMIER",
//       "ONIX PLUS 1.0T MT LTZ",
//       "ONIX PLUS 1.0T MT PREMIER",
//       "ONIX PLUS 1.2 LT MT",
//       "ONIX PLUS 1.2 LT MT TECH",
//       "ONIX PLUS 1.2 LT MT TECH",
//       "ONIX PLUS 1.2 MT",
//       "ONIX PLUS 1.4 MT JOY",
//       "ONIX PLUS 1.4 MT JOY BLACK",
//       "PICK-UP C10",
//       "PICK-UP C10 SIL/CUS",
//       "PRISMA 1.4 LS",
//       "PRISMA 1.4 LT",
//       "PRISMA 1.4 N LTZ A/T",
//       "PRISMA JOY 4P 1.4 N LS MT",
//       "PRISMA JOY 4P 1.4 N LS MT +",
//       "S 10 CD 2.8 TD 4X4 HC AT",
//       "S 10 TURBO DIESEL (CABINA",
//       "S-10",
//       "S10 2.4 4X2 GAS STD",
//       "S10 2.5TD CABINA DOBLE DLX",
//       "S10 2.5TD CABINA SIMPLE",
//       "S10 2.8 4X2 LS",
//       "S10 2.8 4X4 LS",
//       "S10 2.8 DLX 4X4 T.I.",
//       "S10 2.8 DLX T.I. (CABINA",
//       "S10 2.8 LIMITED 4X4",
//       "S10 2.8 STD TI (CABINA",
//       "S10 2.8 T.I. (CABINA DOBLE)",
//       "S10 2.8 T.I. (CABINA SIMPLE)",
//       "S10 2.8 TD 4X4 LTZ M/T",
//       "S10 2.8STD",
//       "S10 2.8TD 4X2 LS",
//       "S10 2.8TD 4X2 LT",
//       "S10 2.8TD 4X2 LTZ",
//       "S10 2.8TD 4X2 MIDNIGHT MT",
//       "S10 2.8TD 4X4 LS",
//       "S10 2.8TD 4X4 LT",
//       "S10 2.8TD 4X4 LTZ A/T",
//       "S10 2.8TD 4X4 MIDNIGHT MT",
//       "S10 2.8TDI DLX 4X2",
//       "S10 2.8TDI DLX 4X2",
//       "S10 2.8TDI DLX 4X2",
//       "S10 2.8TDI DLX 4X4",
//       "S10 2.8TDI DLX 4X4",
//       "S10 2.8TDI DLX 4X4",
//       "S10 2.8TDI EXECUTIVE 4X4",
//       "S10 2.8TDI LIMITED 4X4",
//       "S10 2.8TDI LIMITED 4X4",
//       "S10 2.8TDI LIMITED 4X4",
//       "S10 2.8TDI STD 4X2",
//       "S10 2.8TDI STD 4X2",
//       "S10 2.8TDI STD 4X2",
//       "S10 2.8TDI STD 4X2",
//       "S10 2.8TDI STD 4X2",
//       "S10 2.8TDI STD 4X2",
//       "S10 2.8TDI STD 4X4",
//       "S10 2.8TDI STD 4X4",
//       "S10 2.8TDI STD 4X4",
//       "S10 2.8TI CARDON 4X4",
//       "S10 2.8TI CARDON 4X4",
//       "S10 CD 2.8 TD 4X2 HC",
//       "S10 CD 2.8 TD 4X4 HC AT",
//       "S10 CD 2.8 TD 4X4 HC MT",
//       "S10 CD 2.8 TD 4X4 LT AT",
//       "S10 CD 2.8 TD 4X4 Z71 AT",
//       "S10 DE LUXE",
//       "S10 DE LUXE EFI",
//       "S10 DE LUXE MPFI (DOBLE",
//       "S10 DLX 2.5 TD (CABINA",
//       "S10 DLX 2.5 TD (CABINA",
//       "S10 EFI",
//       "S10 STD 2.5 TD (CABINA",
//       "S10 STD 2.5 TD (CABINA",
//       "S10 TURBO DIESEL",
//       "S10 TURBO DIESEL DE LUXE",
//       "S10 TURBO DIESEL DE LUXE",
//       "SILVERADO",
//       "SILVERADO 1500 4X4",
//       "SILVERADO 1500 4X4",
//       "SILVERADO 1500 LS",
//       "SILVERADO 2500 HD",
//       "SILVERADO 3500 (CABINA",
//       "SILVERADO CONQUEST",
//       "SILVERADO CONQUEST",
//       "SILVERADO CONQUEST",
//       "SILVERADO CONQUEST",
//       "SILVERADO CONQUEST",
//       "SILVERADO TURBO DIESEL",
//       "SONIC 1.6 LT M/T",
//       "SONIC 1.6 LT M/T",
//       "SONIC 1.6 LTZ A/T",
//       "SONIC 1.6 LTZ A/T",
//       "SONIC 1.6 LTZ M/T",
//       "SONIC 1.6 LTZ M/T",
//       "SPARK GT LT",
//       "SPARK LS",
//       "SPARK LT",
//       "SPIN 1.8 N 7P LTZ M/T",
//       "SPIN 1.8 N 7P LTZ M/T",
//       "SPIN 1.8 N LT M/T",
//       "SPIN 1.8 N LT M/T",
//       "SPIN 1.8 N LTZ A/T",
//       "SPIN 1.8 N LTZ A/T",
//       "SPIN 1.8 N LTZ M/T",
//       "SPIN 1.8 N LTZ M/T",
//       "SPIN 1.8N 7P PREMIER A/T",
//       "SPIN 1.8N 7P PREMIER M/T",
//       "SPIN 1.8N PREMIER M/T",
//       "SPIN ACTIV 1.8N A/T",
//       "SPIN ACTIV 1.8N M/T",
//       "SPIN ACTIV 7P 1.8N A/T",
//       "SPIN ACTIVE 1.8 LTZ A/T",
//       "SPIN ACTIVE 1.8 LTZ M/T",
//       "SUBURBAN",
//       "SUBURBAN",
//       "SUBURBAN",
//       "SUBURBAN",
//       "SUBURBAN 2500 LS",
//       "SUBURBAN 2500 LS",
//       "SUBURBAN 3500",
//       "SUBURBAN 4WD",
//       "SUBURBAN CARRYALL",
//       "SUBURBAN DIESEL 6.2 L",
//       "SUBURBAN FUEL INJECTION",
//       "SUBURBAN FUEL INJENCTION",
//       "SUBURBAN LS",
//       "SUBURBAN LS",
//       "SUBURBAN LS",
//       "SUBURBAN LT 1500",
//       "SUBURBAN LT 1500",
//       "SUBURBAN LT 1500",
//       "SUBURBAN LT 2500",
//       "SUBURBAN LT 2500",
//       "SUBURBAN LT 4X4",
//       "SUBURBAN LT 4X4",
//       "SUBURBAN SILVERADO",
//       "SUBURBAN SW",
//       "TAHOE",
//       "TAHOE LS",
//       "TAHOE LT",
//       "TIGRA 16V",
//       "TRACKER",
//       "TRACKER",
//       "TRACKER",
//       "TRACKER 1.2T AT",
//       "TRACKER 1.2T AT",
//       "TRACKER 1.2T AT LTZ",
//       "TRACKER 1.2T AT LTZ",
//       "TRACKER 1.2T AT PREMIER",
//       "TRACKER 1.2T AT PREMIER",
//       "TRACKER 1.2T MT",
//       "TRACKER 1.2T MT",
//       "TRACKER AWD LTZ",
//       "TRACKER AWD LTZ +",
//       "TRACKER AWD PREMIER",
//       "TRACKER AWD PREMIER",
//       "TRACKER AWD PREMIER +",
//       "TRACKER DELUXE 2.0 L",
//       "TRACKER DELUXE 2.0 L DIESEL",
//       "TRACKER FWD LT",
//       "TRACKER FWD LTZ",
//       "TRACKER FWD PREMIER",
//       "TRACKER LS 1.8",
//       "TRACKER LTZ 1.8 FLEX",
//       "TRACKER MIDNIGHT FWD MT",
//       "TRAIL BLAZER LS",
//       "TRAIL BLAZER LTZ",
//       "TRAILBLAZER",
//       "TRAILBLAZER 2.8 4X4",
//       "TRAILBLAZER 2.8 CTDI LT M/T",
//       "TRAVERSE LT",
//       "TROOPER",
//       "UPLANDER LS",
//       "VECTRA 2.2 16V CD",
//       "VECTRA 2.4 DOHC A/T",
//       "VECTRA CD 2.0 16V",
//       "VECTRA CD 2.2 16V",
//       "VECTRA CD 2.4",
//       "VECTRA CD 2.4",
//       "VECTRA CD 2.4 A/T",
//       "VECTRA CD 2.4 A/T",
//       "VECTRA ELEGANCE 2.0N",
//       "VECTRA ELEGANCE 2.4N",
//       "VECTRA ELITE 2.4N",
//       "VECTRA ELITE 2.4N A/T",
//       "VECTRA GL 2.0 M.P.F.I.",
//       "VECTRA GL 2.0 MPFI",
//       "VECTRA GL 2.2 MPFI",
//       "VECTRA GLS 2.0",
//       "VECTRA GLS 2.0",
//       "VECTRA GLS 2.2 MPFI",
//       "VECTRA GLS 2.4",
//       "VECTRA GT",
//       "VECTRA TDL 16V",
//       "VECTRA TURBO DIESEL",
//       "VENTURE LT",
//       "VITARA JX",
//       "ZAFIRA CD 2.0 16V",
//       "ZAFIRA GL",
//       "ZAFIRA GL 2.0 8V NAFTA",
//       "ZAFIRA GL 2.0 8V NAFTA",
//       "ZAFIRA GLS",
//       "ZAFIRA GLS 2.0 16V NAFTA",
//       "ZAFIRA GLS 2.0 16V NAFTA",
//       "ZAFIRA GLS AUTOMATIC 2.0",
//       "EQUINOX LT",
//       "CELTA 1.4N LS SPIRIT",
//       "COBALT 1.3D LT",
//       "COBALT 1.3D LTZ",
//       "COBALT 1.8 N LT",
//       "COBALT 1.8 N LTZ",
//       "COBALT 1.8 N LTZ A/T",
//       "MONTANA 1.8 LS AA+DIR",
//       "MONTANA 1.8 LS PACK",
//       "ONIX 1.4 N LT",
//       "ONIX 1.4 N LTZ",
//       "PRISMA 1.4 N LT",
//       "PRISMA 1.4 N LTZ",
//       "SPARK GT",
//       "SPIN 1.3 D 7P LTZ M/T",
//       "SPIN 1.3 D LT M/T",
//       "SPIN 1.3 D LTZ M/T",
//       "SPIN 1.8 N 7P LTZ A/T",
//       "SPIN 1.8N LT M/T",
//       "SPIN 1.8N LT M/T",
//       "SPIN 1.8N LTZ M/T",
//       "SPIN 1.8N LTZ M/T",
//       "TRAIL BLAZER LT",
//       "TRAILBLAZER 2.8 CTDI 4X4 LT",
//       "TRAILBLAZER 2.8 CTDI 4X4 LTZ"
//     ],
//     "CHRYSLER": [
//       "300 C",
//       "300 M",
//       "300C 3.5L V6",
//       "300C 5.7L V8 HEMI",
//       "CARAVAN",
//       "CARAVAN FWD",
//       "CARAVAN SE",
//       "CARAVAN SE 2.4L",
//       "CARAVAN SE 2.5 TD",
//       "CARAVAN SE 3.3L",
//       "CARAVAN SE CRD",
//       "CARAVAN SE TD",
//       "CARAVAN SPORT 3.3",
//       "CHEROKEE",
//       "CHEROKEE TURBO DIESEL",
//       "CHEROKEE/LAREDO/SPORT/T",
//       "CROSSFIRE",
//       "CROSSFIRE",
//       "CROSSFIRE COUPE LIMITED",
//       "CROSSFIRE ROADSTER",
//       "DAKOTA SPORT CLUB CAB",
//       "G.CHEROKEE/LAREDO/LIMITE",
//       "GRAND CARAVAN",
//       "GRAND CARAVAN LE",
//       "GRAND CARAVAN LIMITED",
//       "GRAND CARAVAN LIMITED",
//       "GRAND CARAVAN LIMITED",
//       "GRAND CARAVAN SE",
//       "GRAND CARAVAN SE TD",
//       "GRAND CHEROKEE LAREDO",
//       "GRAND CHEROKEE LAREDO",
//       "GRAND CHEROKEE LIMITED",
//       "GRAND CHEROKEE ORVIS",
//       "GRAND VOYAGER",
//       "GRAND VOYAGER LE",
//       "GRAND VOYAGER SE",
//       "JEEP CHEROKEE",
//       "JEEP CHEROKEE SPORT",
//       "JEEP GRAND CHEROKEE",
//       "JEEP GRAND CHEROKEE",
//       "JEEP GRAND CHEROKEE",
//       "JEEP WRANGLER",
//       "NEON",
//       "NEON 2000 LE",
//       "NEON HIGHLINE",
//       "NEON HIGHLINE",
//       "NEON HIGHLINE LE",
//       "NEON LE",
//       "NEON LX",
//       "NEON RT",
//       "NEON SE",
//       "NEON SPORT",
//       "NEON SPORT AUTOMATICO",
//       "PACIFICA",
//       "PT CRUISER",
//       "PT CRUISER - LIMITED",
//       "PT CRUISER CLASSIC",
//       "PT CRUISER CLASSIC CRD",
//       "PT CRUISER CLASSIC TD",
//       "PT CRUISER GT TURBO",
//       "PT CRUISER GT TURBO",
//       "PT CRUISER LIMITED",
//       "PT CRUISER TOURING",
//       "PT CRUISER TOURING",
//       "RAM 150 WS REG. CAB.",
//       "RAM 250 LT REG. CAB.",
//       "SEBRING JX",
//       "SEBRING LE",
//       "SEBRING LIMITED 2.7L",
//       "SEBRING LX",
//       "SEBRING LX",
//       "SEBRING LXI",
//       "SEBRING TOURING",
//       "STRATUS",
//       "STRATUS LE",
//       "STRATUS LX",
//       "STRATUS LX CABRIOLET",
//       "STRATUS LXI",
//       "STRATUS R/T 2.4L 16 VALVE",
//       "TOWN & COUNTRY",
//       "TOWN & COUNTRY EX",
//       "TOWN & COUNTRY FWD",
//       "TOWN & COUNTRY LIMITED",
//       "TOWN & COUNTRY LIMITED",
//       "TOWN & COUNTRY LIMITED",
//       "TOWN & COUNTRY LX",
//       "TOWN & COUNTRY LX 3.8",
//       "TOWN & COUNTRY LXI",
//       "TOWN & COUNTRY TOURING",
//       "VOYAGER",
//       "VOYAGER",
//       "VOYAGER 2.5 TD",
//       "VOYAGER 2.5 TD-C",
//       "VOYAGER GS",
//       "VOYAGER LE TURBO DIESEL",
//       "VOYAGER LX",
//       "VOYAGER SE",
//       "VOYAGER SE CRD",
//       "VOYAGER SE TURBO DIESEL",
//       "WRANGLER 4X4",
//       "WRANGLER UNLIMITED"
//     ],
//     "CITROEN": [
//       "BERFUD6",
//       "BERFUD6PL",
//       "BERFUD6PLDA",
//       "BERFUD6PLDAA",
//       "BERFUD8PLDA",
//       "BERFUD8PLDAA",
//       "BERLINGO",
//       "BERLINGO",
//       "BERLINGO",
//       "BERLINGO 1.4 SX",
//       "BERLINGO 1.4I SPACEVAN SX",
//       "BERLINGO 1.6 HDI",
//       "BERLINGO 1.6 HDI 90 SX",
//       "BERLINGO 1.8 I",
//       "BERLINGO 1.8I",
//       "BERLINGO 1.9 D",
//       "BERLINGO 1.9D",
//       "BERLINGO 1.9D",
//       "BERLINGO 1.9D",
//       "BERLINGO 1.9D PACK PLC",
//       "BERLINGO 1.9D PACK PLC",
//       "BERLINGO 2.0 HDI",
//       "BERLINGO 2.0 HDI",
//       "BERLINGO 2PLC 2.0 HDI",
//       "BERLINGO DIESEL MULT 2PLC",
//       "BERLINGO DIESEL MULT 2PLC",
//       "BERLINGO DIESEL MULT 2PLC",
//       "BERLINGO DIESEL MULT 2PLC",
//       "BERLINGO DPL",
//       "BERLINGO FUAA",
//       "BERLINGO FUD",
//       "BERLINGO FUDA",
//       "BERLINGO FURGON 1.4 I",
//       "BERLINGO FURGON 1.4 I FULL",
//       "BERLINGO FURGON 1.4 I",
//       "BERLINGO FURGON 1.4I",
//       "BERLINGO FURGON 1.4I",
//       "BERLINGO FURGON 1.4I",
//       "BERLINGO FURGON 1.4I",
//       "BERLINGO FURGON 1.4I",
//       "BERLINGO FURGON 1.4I CLUB",
//       "BERLINGO FURGON 1.4I CLUB",
//       "BERLINGO FURGON 1.4I FULL",
//       "BERLINGO FURGON 1.4I FULL",
//       "BERLINGO FURGON 1.4I PACK",
//       "BERLINGO FURGON 1.4I PACK",
//       "BERLINGO FURGON 1.4I PACK",
//       "BERLINGO FURGON 1.4I PACK",
//       "BERLINGO FURGON 1.4I PACK",
//       "BERLINGO FURGON 1.4I PLC",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.6 HDI",
//       "BERLINGO FURGON 1.9 D 600",
//       "BERLINGO FURGON 1.9D",
//       "BERLINGO FURGON 2.0 HDI",
//       "BERLINGO FURGON 600 KG",
//       "BERLINGO FURGON 600 KG",
//       "BERLINGO FURGON 800 KG",
//       "BERLINGO FURGON 800KG",
//       "BERLINGO FURGON HDI 92",
//       "BERLINGO FURGON HDI 92",
//       "BERLINGO FURGON HDI 92",
//       "BERLINGO FURGON HDI 92",
//       "BERLINGO FURGON HDI 92",
//       "BERLINGO FURGON HDI 92",
//       "BERLINGO FURGON VTI 115",
//       "BERLINGO FURGON VTI 115",
//       "BERLINGO FURGON VTI 115",
//       "BERLINGO FURGON VTI 115",
//       "BERLINGO FURG",
//       "BERLINGO FURG",
//       "BERLINGO FURG",
//       "BERLINGO HDI",
//       "BERLINGO HDI90 XTR",
//       "BERLINGO MULTIESPACE 1.9D",
//       "BERLINGO MULTISPACE 1.4 L",
//       "BERLINGO MULTISPACE 1.4I X",
//       "BERLINGO MULTISPACE 1.4I X",
//       "BERLINGO MULTISPACE 1.4I X",
//       "BERLINGO MULTISPACE 1.6",
//       "BERLINGO MULTISPACE 1.6",
//       "BERLINGO MULTISPACE 1.6",
//       "BERLINGO MULTISPACE 1.6",
//       "BERLINGO MULTISPACE 1.6",
//       "BERLINGO MULTISPACE 1.6",
//       "BERLINGO MULTISPACE 1.6",
//       "BERLINGO MULTISPACE 1.6 I",
//       "BERLINGO MULTISPACE 1.6i",
//       "BERLINGO MULTISPACE 1.6I",
//       "BERLINGO MULTISPACE 1.6i",
//       "BERLINGO MULTISPACE 1.6I",
//       "BERLINGO MULTISPACE 1.6I",
//       "BERLINGO MULTISPACE 1.6I",
//       "BERLINGO MULTISPACE 1.6I",
//       "BERLINGO MULTISPACE 1.6I",
//       "BERLINGO MULTISPACE 1.6I",
//       "BERLINGO MULTISPACE 1.6I",
//       "BERLINGO MULTISPACE 1.6I",
//       "BERLINGO MULTISPACE 1.6I X",
//       "BERLINGO MULTISPACE 1.6I X",
//       "BERLINGO MULTISPACE 1.6I",
//       "BERLINGO MULTISPACE 1.8 2",
//       "BERLINGO MULTISPACE 1.8",
//       "BERLINGO MULTISPACE 1.8I",
//       "BERLINGO MULTISPACE 1.8I",
//       "BERLINGO MULTISPACE 1.8I",
//       "BERLINGO MULTISPACE 1.8I",
//       "BERLINGO MULTISPACE 1.9 D",
//       "BERLINGO MULTISPACE 1.9D",
//       "BERLINGO MULTISPACE 1.9D",
//       "BERLINGO MULTISPACE 1.9D",
//       "BERLINGO MULTISPACE 1.9D",
//       "BERLINGO MULTISPACE 110",
//       "BERLINGO MULTISPACE HDI",
//       "BERLINGO MULTISPACE HDI",
//       "BERLINGO MULTISPACE HDI",
//       "BERLINGO MULTISPACE HDI",
//       "BERLINGO MULTISPACE HDI",
//       "BERLINGO MULTISPACE HDI",
//       "BERLINGO MULTISPACE VTI",
//       "BERLINGO MULTISPACE VTI",
//       "BERLINGO MULTISPACE VTI",
//       "BERLINGO MULTISPACE VTI",
//       "BERLINGO MULTISPACE VTI",
//       "BERLINGO MULTISPACE1.4I",
//       "BERLINGO MULTISPACE1.6I",
//       "BERLINGO MULTISPACE1.6I",
//       "BERLINGO MULTISPACE1.6I X",
//       "BERLINGO NAFTA MULT 2PLC",
//       "BERLINGO NAFTA MULT 2PLC",
//       "BERLINGO NAFTA MULT 2PLC",
//       "BERLINGO NAFTA MULT 2PLC",
//       "BERLINGO NPL",
//       "BERLINGO2.0 HDI",
//       "BERMUDPLDAA",
//       "BERMUDPLDAB",
//       "BERMUL2D",
//       "BERMUL2DAB",
//       "BERMUL2N",
//       "BERMUL2NAB",
//       "BERMUNPLDAA",
//       "BERMUNPLDAB",
//       "C 15 RD",
//       "C- ELYS",
//       "C- ELYS",
//       "C.15 E",
//       "C.15 E",
//       "C.15D/E/RD",
//       "C1",
//       "C15 D",
//       "C15 D",
//       "C15 FIRST 1.9 D",
//       "C2",
//       "C3 1.1",
//       "C3 1.1 I X",
//       "C3 1.4 HDI EXCLUSIVE",
//       "C3 1.4 HDI SX",
//       "C3 1.4 HDI X",
//       "C3 1.4 I SX",
//       "C3 1.4 I X",
//       "C3 1.4HDI",
//       "C3 1.4HDI XTR",
//       "C3 1.4I",
//       "C3 1.4I SX PACK",
//       "C3 1.4I XTR",
//       "C3 1.6",
//       "C3 1.6I 16V EXCLUSIVE",
//       "C3 1.6I 16V XTR",
//       "C3 1.6I SX",
//       "C3 90 FEEL",
//       "C3 90 LIVE",
//       "C3 90 ORIGINE",
//       "C3 90 ORIGINE PACK ZENITH",
//       "C3 90 SOUNDTRACK",
//       "C3 90 START",
//       "C3 90 TENDANCE PACK",
//       "C3 AIRCROSS",
//       "C3 AIRCROSS 1.6 VTI 115",
//       "C3 AIRCROSS 1.6 VTI 115",
//       "C3 AIRCROSS 1.6 VTI 115",
//       "C3 AIRCROSS 1.6 VTI 115",
//       "C3 AIRCROSS 1.6 VTI 115",
//       "C3 AIRCROSS 1.6 VTI 115",
//       "C3 AIRCROSS 1.6 VTI 115",
//       "C3 AIRCROSS 1.6 VTI 115",
//       "C3 AIRCROSS 1.6I 16V",
//       "C3 AIRCROSS 1.6I 16V",
//       "C3 AIRCROSS 1.6I 16V SX",
//       "C3 AIRCROSS 1.6VTI 115",
//       "C3 AIRCROSS 1.6VTI 115",
//       "C3 AIRCROSS 115 FEEL",
//       "C3 AIRCROSS 115 FEEL RD6",
//       "C3 AIRCROSS 115 LIVE",
//       "C3 AIRCROSS 115 SHINE",
//       "C3 AIRCROSS 115 SHINE",
//       "C3 AIRCROSS 115 SHINE RD6",
//       "C3 AIRCROSS 115 SHINE W",
//       "C3 AIRCROSS 115 SHINE W",
//       "C3 AIRCROSS 90 LIVE",
//       "C3 AIRCROSS 90 LIVE RD6",
//       "C3 AIRCROSS EXCLUSIVE",
//       "C3 AIRCROSS SX",
//       "C3 AIRCROSS VTI 115 FEEL",
//       "C3 AIRCROSS VTI 115 AT6",
//       "C3 AIRCROSS VTI 115 AT6",
//       "C3 AIRCROSS VTI 115 AT6",
//       "C3 AIRCROSS VTI 115 AT6",
//       "C3 AIRCROSS VTI 115 AT6",
//       "C3 AIRCROSS VTI 115 AT6",
//       "C3 AIRCROSS VTI 115 AT6",
//       "C3 AIRCROSS VTI 115 AT6",
//       "C3 AIRCROSS VTI 115 AT6",
//       "C3 AIRCROSS VTI 115 FEEL",
//       "C3 AIRCROSS VTI 115 FEEL",
//       "C3 AIRCROSS VTI 115 FEEL",
//       "C3 AIRCROSS VTI 115 LIVE",
//       "C3 AIRCROSS VTI 115 LIVE",
//       "C3 AIRCROSS VTI 115 LIVE",
//       "C3 AIRCROSS VTI 115 LIVE",
//       "C3 AIRCROSS VTI 115 SHINE",
//       "C3 AIRCROSS VTI 115 SHINE",
//       "C3 AIRCROSS VTI 115 SHINE",
//       "C3 AIRCROSS VTI 115 URBAN",
//       "C3 EXCLUSIVE",
//       "C3 EXCLUSIVE 120",
//       "C3 ORIGINE 90",
//       "C3 PICASSO 1.5I 90 ORIGINE",
//       "C3 PICASSO 1.5I 90 ORIGINE",
//       "C3 PICASSO 1.6 VTI 115",
//       "C3 PICASSO 1.6 VTI 115",
//       "C3 PICASSO 1.6 VTI 115",
//       "C3 PICASSO 1.6 VTI 115",
//       "C3 PICASSO 1.6 VTI 115 TEN.",
//       "C3 PICASSO 1.6 VTI 115",
//       "C3 PICASSO 1.6I 16V",
//       "C3 PICASSO 1.6I 16V",
//       "C3 PICASSO 1.6I 16V",
//       "C3 PICASSO 1.6I 16V",
//       "C3 PICASSO 1.6I 16V SX",
//       "C3 PICASSO 1.6I 16V SX",
//       "C3 PLURIEL",
//       "C3 PURETECH 82 FEEL AM22",
//       "C3 PURETECH 82 FEEL LOOK",
//       "C3 PURETECH 82 FIRST",
//       "C3 PURETECH 82 LIVE PACK",
//       "C3 TENDANCE 90",
//       "C3 VTI 115 AT6 FEEL AM18",
//       "C3 VTI 115 AT6 FEEL AM19",
//       "C3 VTI 115 AT6 FEEL AM20",
//       "C3 VTI 115 AT6 FEEL AM20.5",
//       "C3 VTI 115 AT6 FEEL AM21",
//       "C3 VTI 115 AT6 INFINIT",
//       "C3 VTI 115 AT6 ORIGINS",
//       "C3 VTI 115 AT6 SHINE AM18",
//       "C3 VTI 115 AT6 SHINE AM19",
//       "C3 VTI 115 AT6 SHINE AM20",
//       "C3 VTI 115 AT6 SHINE AM20.5",
//       "C3 VTI 115 AT6 URBAN TRAIL",
//       "C3 VTI 115 EAT6 FEEL PACK",
//       "C3 VTI 115 EAT6 FEEL PACK",
//       "C3 VTI 115 EAT6 FIRST",
//       "C3 VTI 115 EXCLUSIVE",
//       "C3 VTI 115 EXCLUSIVE PACK",
//       "C3 VTI 115 FEEL",
//       "C3 VTI 115 FEEL AM18",
//       "C3 VTI 115 FEEL AM19",
//       "C3 VTI 115 FEEL AM20",
//       "C3 VTI 115 FEEL AM20.5",
//       "C3 VTI 115 FEEL AM21",
//       "C3 VTI 115 FEEL AM22",
//       "C3 VTI 115 LIVE AM18",
//       "C3 VTI 115 LIVE AM19",
//       "C3 VTI 115 LIVE AM20",
//       "C3 VTI 115 LIVE AM20.5",
//       "C3 VTI 115 LIVE AM21",
//       "C3 VTI 115 ORIGINS",
//       "C3 VTI 115 SHINE",
//       "C3 VTI 115 SHINE AM17",
//       "C3 VTI 115 SHINE AM18",
//       "C3 VTI 115 SHINE AM19",
//       "C3 VTI 115 TECHNO",
//       "C3 VTI 115 URBAN TRAIL",
//       "C3 VTI 115 URBAN TRAIL",
//       "C4 1.6",
//       "C4 1.6 16V VTR",
//       "C4 1.6 HDI",
//       "C4 1.6 HDI VTR",
//       "C4 1.6I 16V X",
//       "C4 2.0 HDI EXCLUSIVE",
//       "C4 2.0 HDI SX",
//       "C4 2.0 HDI X",
//       "C4 2.0 VTR",
//       "C4 2.0I 16V BVA EXCLUSIVE",
//       "C4 2.0I 16V EXCLUSIVE",
//       "C4 2.0I 16V EXCLUSIVE PLUS",
//       "C4 2.0I 16V SX",
//       "C4 2.0I 16V SX BVA",
//       "C4 2.0I 16V X",
//       "C4 2.0I 16V X PACK LOOK",
//       "C4 5 PUERTAS 1.6 HDI SX",
//       "C4 5P 1.6 HDI SX AM73",
//       "C4 5P 1.6I 16V",
//       "C4 5P 1.6I 16V X",
//       "C4 5P 1.6I 16V X PACK",
//       "C4 5P 1.6I 16V X PACK LOOK",
//       "C4 5P 1.6I 16V X PACK PLUS",
//       "C4 5P 1.6I X PACK LOOK",
//       "C4 5P 1.6I X PACK PLUS",
//       "C4 5P 2.0 HDI EXCLUSIVE",
//       "C4 5P 2.0 HDI SX",
//       "C4 5P 2.0 HDI X",
//       "C4 5P 2.0I 16V EXCLUSIVE",
//       "C4 5P 2.0I 16V EXCLUSIVE",
//       "C4 5P 2.0I 16V EXCLUSIVE",
//       "C4 5P 2.0I 16V SX",
//       "C4 5P 2.0I 16V SX AM73",
//       "C4 5P HDI SX AM73",
//       "C4 AIRCROSS 4X2 BVA",
//       "C4 AIRCROSS 4X4 BVA",
//       "C4 AIRCROSS TENDANCE 2.0I",
//       "C4 AIRCROSS TENDANCE 2.0I",
//       "C4 AIRCROSS TENDANCE 2.0I",
//       "C4 CACTUS PURETECH 110",
//       "C4 CACTUS PURETECH 110",
//       "C4 CACTUS PURETECH 82",
//       "C4 CACTUS THP 165 EAT6",
//       "C4 CACTUS THP 165 EAT6",
//       "C4 CACTUS THP 165 EAT6",
//       "C4 CACTUS THP 165 EAT6",
//       "C4 CACTUS THP 165 EAT6",
//       "C4 CACTUS THP 165 EAT6",
//       "C4 CACTUS VTI 115 C-SERIES",
//       "C4 CACTUS VTI 115 EAT6 C-",
//       "C4 CACTUS VTI 115 EAT6 FEEL",
//       "C4 CACTUS VTI 115 EAT6 FEEL",
//       "C4 CACTUS VTI 115 EAT6 FEEL",
//       "C4 CACTUS VTI 115 EAT6 FEEL",
//       "C4 CACTUS VTI 115 EAT6 FEEL",
//       "C4 CACTUS VTI 115 EAT6 FEEL",
//       "C4 CACTUS VTI 115 EAT6 FEEL",
//       "C4 CACTUS VTI 115 EAT6 FEEL",
//       "C4 CACTUS VTI 115 EAT6 FEEL",
//       "C4 CACTUS VTI 115 EAT6",
//       "C4 CACTUS VTI 115 EAT6 RIP",
//       "C4 CACTUS VTI 115 EAT6 X-",
//       "C4 CACTUS VTI 115 FEEL",
//       "C4 CACTUS VTI 115 FEEL",
//       "C4 CACTUS VTI 115 FEEL",
//       "C4 CACTUS VTI 115 FEEL",
//       "C4 CACTUS VTI 115 FEEL",
//       "C4 CACTUS VTI 115 FEEL",
//       "C4 CACTUS VTI 115 FEEL PACK",
//       "C4 CACTUS VTI 115 FEEL PACK",
//       "C4 CACTUS VTI 115 FEEL PACK",
//       "C4 CACTUS VTI 115 FEEL PACK",
//       "C4 CACTUS VTI 115 FEEL PACK",
//       "C4 CACTUS VTI 115 FEEL PACK",
//       "C4 CACTUS VTI 115 FEEL PACK",
//       "C4 CACTUS VTI 115 FEEL PACK",
//       "C4 CACTUS VTI 115 FEEL PACK",
//       "C4 CACTUS VTI 115 LIVE",
//       "C4 CACTUS VTI 115 LIVE",
//       "C4 CACTUS VTI 115 LIVE",
//       "C4 CACTUS VTI 115 ORIGINS",
//       "C4 CACTUS VTI 115 RIP CURL",
//       "C4 CACTUS VTI 115 X-SERIES",
//       "C4 LOUNGE 1.6 HDI",
//       "C4 LOUNGE 1.6 HDI",
//       "C4 LOUNGE 1.6 HDI",
//       "C4 LOUNGE 1.6I THP 163 AT6",
//       "C4 LOUNGE 1.6I THP 163 AT6",
//       "C4 LOUNGE 1.6I THP 163 AT6",
//       "C4 LOUNGE 143 FEEL PACK",
//       "C4 LOUNGE 143 LIVE",
//       "C4 LOUNGE 143 ORIGINE",
//       "C4 LOUNGE 143 ORIGINE",
//       "C4 LOUNGE 143 TENDANCE",
//       "C4 LOUNGE 143 TENDANCE",
//       "C4 LOUNGE 143 TENDANCE",
//       "C4 LOUNGE 143 TENDANCE",
//       "C4 LOUNGE 143 TENDANCE",
//       "C4 LOUNGE 2.0I 16V ORIGINE",
//       "C4 LOUNGE 2.0I 16V",
//       "C4 LOUNGE HDI 115",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115 MT6",
//       "C4 LOUNGE HDI 115",
//       "C4 LOUNGE HDI 115",
//       "C4 LOUNGE HDI 115",
//       "C4 LOUNGE THP 163 AT6",
//       "C4 LOUNGE THP 163 AT6",
//       "C4 LOUNGE THP 163 AT6",
//       "C4 LOUNGE THP 163 AT6",
//       "C4 LOUNGE THP 165 AT6",
//       "C4 LOUNGE THP 165 AT6",
//       "C4 LOUNGE THP 165 AT6 FEEL",
//       "C4 LOUNGE THP 165 AT6 FEEL",
//       "C4 LOUNGE THP 165 AT6 FEEL",
//       "C4 LOUNGE THP 165 AT6 FEEL",
//       "C4 LOUNGE THP 165 AT6",
//       "C4 LOUNGE THP 165 AT6",
//       "C4 LOUNGE THP 165 AT6",
//       "C4 LOUNGE THP 165 AT6",
//       "C4 LOUNGE THP 165 AT6",
//       "C4 LOUNGE THP 165 AT6",
//       "C4 LOUNGE THP 165 EAT6",
//       "C4 LOUNGE THP 165 EAT6",
//       "C4 LOUNGE THP 165 EAT6",
//       "C4 LOUNGE THP 165 EAT6",
//       "C4 LOUNGE THP 165 MT6",
//       "C4 LOUNGE THP 165 MT6",
//       "C4 LOUNGE THP 165 MT6",
//       "C4 LOUNGE THP 165 MT6",
//       "C4 LOUNGE THP 165 MT6",
//       "C4 LOUNGE THP 165 MT6",
//       "C4 LOUNGE THP 165 MT6",
//       "C4 LOUNGE THP 165 MT6",
//       "C4 LOUNGE THP 165 MT6",
//       "C4 LOUNGE THP 165 MT6",
//       "C4 LOUNGE THP 165 MT6",
//       "C4 LOUNGE THP 165 MT6",
//       "C4 LOUNGE THP 165 MT6 S",
//       "C4 LOUNGE THP 165 MT6 S",
//       "C4 LOUNGE THP 165 MT6",
//       "C4 LOUNGE THP 165 MT6",
//       "C4 LOUNGE THP 165 ORIGINE",
//       "C4 LOUNGE VTI 115 LIVE",
//       "C4 LOUNGE VTI 115 LIVE",
//       "C4 LOUNGE VTI 115 LIVE",
//       "C4 LOUNGE VTI 115 LIVE",
//       "C4 LOUNGE VTI 115 LIVE",
//       "C4 NOUVELLE VTI 120 BVM",
//       "C4 PALLAS 2.0",
//       "C4 PICASSO",
//       "C4 PICASSO",
//       "C4 PICASSO 1.6 HDI",
//       "C4 PICASSO 1.6 HDI",
//       "C4 PICASSO 1.6 HDI",
//       "C4 PICASSO 1.6 HDI ORIGINE",
//       "C4 PICASSO 1.6 HDI",
//       "C4 PICASSO 1.6HDI",
//       "C4 PICASSO 1.8 16V SX",
//       "C4 PICASSO 165 THP AT6 FEEL",
//       "C4 PICASSO 2.0 HDI",
//       "C4 PICASSO 2.0I 16V BVA",
//       "C4 PICASSO 2.0I 16V BVA",
//       "C4 PICASSO 2.0I 16V BVA",
//       "C4 PICASSO 2.0I 16V BVA",
//       "C4 PICASSO 2.0I 16V",
//       "C4 PICASSO HDI 115 MT6",
//       "C4 PICASSO HDI 115 MT6",
//       "C4 PICASSO THP 165 AT6 FEEL",
//       "C4 PICASSO THP 165 AT6 FEEL",
//       "C4 SEDAN 1.6 HDI SX",
//       "C4 SEDAN 1.6 HDI SX AM73",
//       "C4 SEDAN 1.6I 16V X",
//       "C4 SEDAN 2.0 HDI EXCLUSIVE",
//       "C4 SEDAN 2.0 HDI SX",
//       "C4 SEDAN 2.0 HDI X",
//       "C4 SEDAN 2.0I 16V EXCLUSIVE",
//       "C4 SEDAN 2.0I 16V EXCLUSIVE",
//       "C4 SEDAN 2.0I 16V EXCLUSIVE",
//       "C4 SEDAN 2.0I 16V SX",
//       "C4 SEDAN 2.0I 16V SX AM73",
//       "C4 SEDAN 2.0I 16V SX CUERO",
//       "C4 SEDAN 2.0I 16V SX VBA",
//       "C4 SEDAN 2.0I 16V X",
//       "C4 SPACETOURER HDI 115",
//       "C4 SPACETOURER HDI 115",
//       "C4 SPACETOURER HDI 115",
//       "C4 SPACETOURER THP 165",
//       "C4 SPACETOURER THP 165",
//       "C4 SPACETOURER THP 165",
//       "C4 SPACETOURER THP 165",
//       "C4 SPACETOURER THP 165",
//       "C4 VTS",
//       "C4 VTS 2.0 HDI",
//       "C4 X 1.6I",
//       "C5 1.6 HDI",
//       "C5 1.6 HDI",
//       "C5 1.6I TURBO EXCLUSIVE",
//       "C5 1.8I 16V",
//       "C5 2.0 16V HPI EXCLUSIVE",
//       "C5 2.0 HDI",
//       "C5 2.0 HDI CONFORT",
//       "C5 2.0 HDI DYNAMIQUE",
//       "C5 2.0 HDI SX",
//       "C5 2.0 HDI SX (BASE)",
//       "C5 2.0 HDI X",
//       "C5 2.0I",
//       "C5 2.0I 16V SX",
//       "C5 2.0I CONFORT",
//       "C5 2.0I DYNAMIQUE",
//       "C5 2.1I CONFORT",
//       "C5 3.0I AUTOMATICO",
//       "C5 3.0I V6 EXCLUSIVE",
//       "C5 3.0I V6 EXCLUSIVE BVA",
//       "C5 AIRCROSS THP 165 EAT6",
//       "C5 AIRCROSS THP 165 EAT6",
//       "C5 AIRCROSS THP 165 EAT6",
//       "C6 2.7 V6 HDI EXCLISVE",
//       "C6 2.7 V6 HDI EXCLUSIVE",
//       "C6 3.0 V6 EXCLUSIVE",
//       "C6 3.0I V6 EXCLUSIVE",
//       "C-ELYSEE VTI 115 FEEL",
//       "C-ELYSEE VTI 115 FEEL AM18",
//       "C-ELYSEE VTI 115 FEEL AM20",
//       "C-ELYSEE VTI 115 LIVE AM19",
//       "C-ELYS",
//       "DS 3",
//       "DS 3 SO CHIC VTI 120",
//       "DS 3 SPORT CHIC THP 156",
//       "DS 3 SPORT CHIP THP 156",
//       "DS 3 THP 156 MT6 SPORT",
//       "DS 3 VTI 120 1955",
//       "DS 3 VTI 120 SO CHIC AM32",
//       "DS 4 165 THP AT6 SO CHIC",
//       "DS3 1.6 TURBO SPORT CHIC",
//       "DS3 1.6 TURBO SPORT CHIC",
//       "DS3 1.6 VTI 120 SO CHIC",
//       "DS4 1.6I TURBO SO CHIC",
//       "DS4 THP 156 AT6 SO CHIC",
//       "GRAND C4 PICASSO 1.6HDI",
//       "GRAND C4 PICASSO 115 HDI",
//       "GRAND C4 PICASSO 165 THP",
//       "GRAND C4 PICASSO 2.0I BVA",
//       "GRAND C4 PICASSO HDI 115",
//       "GRAND C4 PICASSO THP 165",
//       "GRAND C4 PICASSO THP 165",
//       "GRAND C4 SPACETOURER HDI",
//       "GRAND C4 SPACETOURER HDI",
//       "GRAND C4 SPACETOURER THP",
//       "GRAND C4 SPACETOURER THP",
//       "GRAND C4 SPACETOURER THP",
//       "GRAND C4 SPACETOURER THP",
//       "GRAND C4 SPACETOURER THP",
//       "JUMPER 2.3 HDI 33 M",
//       "JUMPER 2.3 HDI 33 M AM16",
//       "JUMPER 2.3 HDI 35 LH",
//       "JUMPER 2.3 HDI 35 LH AM16",
//       "JUMPER 2.3 HDI 35 MH",
//       "JUMPER 2.3 HDI 35 MH AM16",
//       "JUMPER 2.3 HDI ALTO",
//       "JUMPER 2.3 HDI ALTO 2010",
//       "JUMPER 2.3 HDI PACK",
//       "JUMPER 2.3 HDI PACK 2010",
//       "JUMPER 2.5D",
//       "JUMPER 2.5D",
//       "JUMPER 2.8HDI",
//       "JUMPER 2.8HDI",
//       "JUMPER 2.8HDI ALTO",
//       "JUMPER 2.8TD",
//       "JUMPER 27C",
//       "JUMPER 27C",
//       "JUMPER 31M",
//       "JUMPER 31M",
//       "JUMPER 31M 2.8TD",
//       "JUMPER 33 M HDI 127 AM16",
//       "JUMPER 330C 2.8D",
//       "JUMPER 330M 2.8D",
//       "JUMPER 35 LH HDI 127 AM16",
//       "JUMPER 35 MH HDI 127",
//       "JUMPER 435 L1H1 HDI 130",
//       "JUMPER 435 L1H1 HDI 130",
//       "JUMPER 435 L2H1 HDI 130",
//       "JUMPER 435 L2H1 HDI 130",
//       "JUMPER 435 L2H1 HDI 130",
//       "JUMPER 435 L2H2 HDI 130",
//       "JUMPER 435 L2H2 HDI 130",
//       "JUMPER 435 L2H2 HDI 130",
//       "JUMPER 435 L3H2 HDI 130",
//       "JUMPER 435 L3H2 HDI 130",
//       "JUMPER 435 L3H2 HDI 130",
//       "JUMPER 440 L3H2 HDI 130",
//       "JUMPER 440 L4H2 HDI 130",
//       "JUMPER CH 435 L3 HDI 130",
//       "JUMPER CH 435 L3 HDI 130",
//       "JUMPER CH 435 L3 HDI 130",
//       "JUMPER CH 435 L3 HDI MT6",
//       "JUMPER CH 440 L3 HDI 130",
//       "JUMPER CH 440 L3 HDI 130",
//       "JUMPER CHASIS L3 HDI 130",
//       "JUMPER L2H1 HDI 130 MT6",
//       "JUMPER L2H2 HDI 130 MT6",
//       "JUMPER L3H2 HDI 130 MT6",
//       "JUMPY 1.9 TD",
//       "JUMPY 2.0 HDI",
//       "JUMPY L3 HDI 115",
//       "JUMPY L3 HDI 115 MT6",
//       "JUMPY L3 HDI 115 MT6",
//       "JUMPY L3 HDI 115 MT6",
//       "JUMPY L3 HDI 115 MT6",
//       "JUMPY L3 HDI 115 MT6",
//       "JUMPY L3 HDI 115 MT6",
//       "NUEVO C3 1.5I 90 EXCLUSIVE",
//       "NUEVO C4 PICASSO HDI 115",
//       "NUEVO C4 PICASSO HDI 115",
//       "NUEVO C4 PICASSO HDI 115",
//       "NUEVO GRAND C4 PICASSO",
//       "SAXO",
//       "SAXO 1.1",
//       "SAXO 1.1",
//       "SAXO 1.4 VTS",
//       "SAXO 1.5 D",
//       "SAXO 1.5 D",
//       "SAXO 1.6I VTS",
//       "SAXO SX 1.4I",
//       "SAXO VTR 1.6I",
//       "SAXO VTS",
//       "SPACETOURER L2 HDI 150",
//       "SPACETOURER L2 HDI 150",
//       "XANTIA",
//       "XANTIA `2 3.0I",
//       "XANTIA `2 SX 1.9TD",
//       "XANTIA `2 SX 2.0 HDI",
//       "XANTIA `2 SX 2.0I",
//       "XANTIA 1.8I",
//       "XANTIA 1.8I",
//       "XANTIA 1.9 SD",
//       "XANTIA 1.9 TD",
//       "XANTIA 16V",
//       "XANTIA 2.0 HDI ACTIVA",
//       "XANTIA 2.0I",
//       "XANTIA 2.0I SX",
//       "XANTIA I",
//       "XANTIA SX 1.9 TD",
//       "XANTIA SX 2.0I",
//       "XANTIA SX 2.0I 16V",
//       "XANTIA TURBO D",
//       "XANTIA/TD/I/16V",
//       "XSARA",
//       "XSARA 1.6 I 16V VTR",
//       "XSARA 1.6I",
//       "XSARA 1.6I",
//       "XSARA 1.8I",
//       "XSARA 1.9 D",
//       "XSARA EXCLUSIVE 2.0I",
//       "XSARA PICASSO",
//       "XSARA PICASSO 1.6 HDI",
//       "XSARA PICASSO 1.6 I",
//       "XSARA PICASSO 1.6I 16V",
//       "XSARA PICASSO 2.0 HDI",
//       "XSARA PICASSO 2.0 HDI",
//       "XSARA PICASSO 2.0 I 16V",
//       "XSARA PICASSO 2.0HDI",
//       "XSARA PICASSO 2.0I",
//       "XSARA PICASSO 2.0I 16V",
//       "XSARA PICASSO II 2.0I 16V",
//       "XSARA SX 1.4 I",
//       "XSARA SX 1.6 I",
//       "XSARA SX 1.8I",
//       "XSARA SX 1.8I 16V",
//       "XSARA SX 1.9D",
//       "XSARA SX 1.9TD",
//       "XSARA SX 1.9TD",
//       "XSARA SX 2.0 HDI",
//       "XSARA SX 2.0I",
//       "XSARA VTS 2.0I",
//       "XSARA X 1.9 D",
//       "ZX",
//       "ZX 1.4 AVANTAGE",
//       "ZX 1.4I",
//       "ZX 1.4I",
//       "ZX 1.4I",
//       "ZX 1.8I",
//       "ZX 1.8I",
//       "ZX 1.9 TD HARMONIE",
//       "ZX 1.9 TD TENTATION",
//       "ZX 1.9D",
//       "ZX 1.9D",
//       "ZX 1.9D BREAK",
//       "ZX AUDACE",
//       "ZX AUDACE",
//       "ZX AURA 1.6TD",
//       "ZX AURA 1.8I",
//       "ZX AVANT/AUR/VO",
//       "ZX AVANTAGE",
//       "ZX AVANTAGE 1.9 D",
//       "ZX AVANTAGE 1.9 D",
//       "ZX AVANTAGE 1.9 TD",
//       "ZX BREAK 1.6I",
//       "ZX BREAK 1.9D SX",
//       "ZX BREAK TONIC",
//       "ZX BREAK X 1.9 D",
//       "ZX DIESEL AVANTAGE",
//       "ZX OTROS",
//       "ZX SX 1.8I",
//       "ZX SX 1.9 TD",
//       "ZX TENTATION 1.6",
//       "ZX TURBO D",
//       "C3 1.1I AUDACE",
//       "C1 1.0I AUDACE",
//       "C3 1.4I SX AM75",
//       "C3 1.4I SX PACK SEGURIDAD",
//       "C3 1.4I SX PLUS",
//       "C3 1.6I 16V EXCLUSIVE AM75",
//       "C3 AIRCROSS 1.6I 16V",
//       "C3 AIRCROSS 1.6I 16V SX",
//       "C3 PICASSO",
//       "C3 PICASSO 1.6I 16V",
//       "C4 1.4",
//       "C4 1.6 HDI VTR",
//       "C5 2.0 HDI 110 VIVACE",
//       "C5 2.0 HPI",
//       "NUEVO C3 1.5I 90",
//       "NUEVO C3 1.5I 90 ORIGINE",
//       "NUEVO C3 1.5I 90 ORIGINE",
//       "NUEVO C3 1.5I 90 TENDANCE",
//       "NUEVO C3 1.5I 90 TENDANCE",
//       "NUEVO C3 1.6 VTI 115",
//       "NUEVO C3 1.6 VTI 115",
//       "XSARA PICASSO 1.6I 16V EXC.",
//       "XSARA VTS 2.0 HDI",
//       "ZX 1.9D MONACO"
//     ],
//     "CORADIR": ["S2-100", "S2-100AA"],
//     "CORVEN": [
//       "DX 70",
//       "ENERGY 110",
//       "ENERGY 110 BY CORVEN",
//       "ENERGY 110 BY CORVEN",
//       "ENERGY 125",
//       "EXPERT 150",
//       "EXPERT 80",
//       "HUNTER 150",
//       "HUNTER 150 BY CORVEN",
//       "HUNTER 150 BY CORVEN",
//       "HUNTER 160",
//       "HUNTER 200",
//       "INDIANA 256 BY CORVEN",
//       "LX 250",
//       "MIRAGE 110",
//       "MIRAGE 110 BY CORVEN",
//       "MIRAGE 110 BY CORVEN",
//       "TERRAIN 150",
//       "TERRAIN 150",
//       "TERRAIN 250",
//       "TERRAIN 250S",
//       "TERRAIN 250X",
//       "TERRAIN 250X",
//       "TRIAX 150",
//       "TRIAX 150",
//       "TRIAX 200",
//       "TRIAX 200 MOTARD",
//       "TRIAX 250",
//       "TRIAX 250",
//       "TRIAX 250 TOURING",
//       "TXR 250 L",
//       "TXR 300 L",
//       "HUNTER 160",
//       "HUNTER 200",
//       "TRIAX 200"
//     ],
//     "DA DALT": [
//       "CHAT",
//       "CICLOCARGA",
//       "CICLOCARGA",
//       "CICLOCARGA DOBLE BANDEJA",
//       "DS 110",
//       "SIGNIA"
//     ],
//     "DACIA": [
//       "BERLINA 1.6",
//       "BREAK 1.6",
//       "DOKKER 1.5",
//       "DUSTER 1.5",
//       "LOGAN",
//       "R1.6 4X4 (CABINA DOBLE)",
//       "R1.6 4X4 (CABINA SIMPLE)",
//       "R524 GT 1.6",
//       "SANDERO",
//       "SANDERO STEPWAY"
//     ],
//     "DAELIM": ["BZ1", "DAYSTAR", "QL 125", "SZ2", "SZ2"],
//     "DAEWOO": [
//       "CIELO",
//       "CIELO BX",
//       "CIELO BX 1.5",
//       "CIELO GL",
//       "CIELO GLE",
//       "CIELO GLX",
//       "CIELO GTX",
//       "CIELO/GLE/GL",
//       "DAMAS",
//       "DAMAS",
//       "DAMAS COACH DLX",
//       "DAMAS COACH STD",
//       "DAMAS VAN",
//       "DAMAS VAN STD",
//       "DAMAS VAN/VAN STD",
//       "ESPERO",
//       "ESPERO 1.5 DLX",
//       "ESPERO 2.0 CD",
//       "ESPERO 2.0 DLX DELUXE",
//       "ESPERO 2.0 LTR DELUXE",
//       "ESPERO 2.0 MPI",
//       "ESPERO 2000",
//       "ESPERO 2000I",
//       "ESPERO CD 2.0 MPI",
//       "ESPERO DLX",
//       "ESPERO FULL",
//       "ESPERO GLS",
//       "ESPERO MFPI ABS 2000",
//       "ESPERO MFPI AUTOMATIC",
//       "ESPERO MFPI AUTOMATIC",
//       "ISTANA ELITE",
//       "LABO DLX",
//       "LABO STD",
//       "LANOS",
//       "LANOS",
//       "LANOS",
//       "LANOS 1.3",
//       "LANOS 1.3",
//       "LANOS 1.5 DOHC",
//       "LANOS 1.5 S",
//       "LANOS 1.5 S",
//       "LANOS 1.5 S",
//       "LANOS 1.5 SOHC",
//       "LANOS 1.6 SX",
//       "LANOS 1.6 SX",
//       "LANOS 1.6 SX",
//       "LANOS ROMEO 1.6 DOHC",
//       "LANOS S",
//       "LANOS S",
//       "LANOS S",
//       "LANOS SE",
//       "LANOS SE",
//       "LANOS SE",
//       "LANOS SX",
//       "LANOS SX",
//       "LANOS SX",
//       "LANOS SX 1.5",
//       "LANOS SX 1.5",
//       "LANOS SX 1.6",
//       "LEGANZA CDX",
//       "LEGANZA SX",
//       "MATIZ",
//       "MATIZ MS",
//       "MATIZ MS",
//       "MATIZ S",
//       "MATIZ SE",
//       "MUSSO 3200",
//       "NUBIRA 1.5 DOHC",
//       "NUBIRA 1.6 S",
//       "NUBIRA 1.8 DOHC",
//       "NUBIRA 1.8 DOHC",
//       "NUBIRA 2.0 CDX",
//       "NUBIRA 2.0 CDX",
//       "NUBIRA 2.0 CDX",
//       "NUBIRA CDX",
//       "NUBIRA CDX",
//       "NUBIRA CDX",
//       "NUBIRA CDX 1.8",
//       "NUBIRA S",
//       "NUBIRA S",
//       "NUBIRA SPAGON 1800",
//       "NUBIRA SX",
//       "NUBIRA SX",
//       "NUBIRA SX 1.6",
//       "TICO",
//       "TICO",
//       "TICO SE",
//       "TICO SL",
//       "TICO SX",
//       "TICO SX AUTOMATICO",
//       "KALOS 1.4"
//     ],
//     "DAIHATSU": [
//       "APPLAUSE",
//       "APPLAUSE",
//       "APPLAUSE",
//       "APPLAUSE 16X",
//       "APPLAUSE AUTOMA",
//       "CUORE",
//       "CUORE",
//       "CUORE",
//       "DELTA",
//       "DELTA",
//       "DELTA DIESEL",
//       "DELTA ROUTE VAN",
//       "DELTA V-116",
//       "DELTA V-116",
//       "DELTA V-116-HU",
//       "DELTA V-118D",
//       "DELTA V-118D",
//       "DELTA V118D-JU",
//       "DELTA V118D-JU",
//       "DELTA V119 LHY",
//       "DELTA V119 LHY",
//       "ECODELTA V119L",
//       "F300",
//       "FEROZA",
//       "FEROZA F300",
//       "FEROZA SE",
//       "FEROZA SX",
//       "GRAND MOVE",
//       "HIJET B.VAN",
//       "M.CUORE L40",
//       "MIRA",
//       "MIRA",
//       "MOVE",
//       "ROCKY",
//       "ROCKY D",
//       "ROCKY SE 4WD",
//       "ROCKY SE 4WD",
//       "ROCKY WAGON F78",
//       "SIRION",
//       "TERIOS",
//       "TERIOS DX",
//       "TERIOS SX"
//     ],
//     "DAIMLER BENZ": ["LP 813"],
//     "DAYAMA": ["DA-250ST 2"],
//     "DEUTZ": [
//       "STALLION - 2 15.160 CH/LA",
//       "STALLION 1 15.160 CH/CO",
//       "STALLION-3 12.130 CH/CO",
//       "STALLION-4 12.130 CH/LA"
//     ],
//     "DEUTZ AGRALE": [
//       "DINAMIC-13",
//       "DYNAMIC-01",
//       "DYNAMIC-01 CH/CORTO",
//       "DYNAMIC-02",
//       "DYNAMIC-02 DOBLE CABINA",
//       "DYNAMIC-03",
//       "DYNAMIC-03 VAN",
//       "DYNAMIC-04",
//       "DYNAMIC-04 CH/CORTO",
//       "DYNAMIC-06",
//       "DYNAMIC-06 CH/LARGO",
//       "DYNAMIC-10",
//       "DYNAMIC-10 CH/CORTO",
//       "DYNAMIC-11",
//       "DYNAMIC-11 CH/LARGO",
//       "DYNAMIC-12",
//       "DYNAMIC-12 DOBLE CABINA",
//       "DYNAMIC-13 CH/CORTO",
//       "DYNAMIC-14",
//       "DYNAMIC-14 CH/LARGO",
//       "STARK- 19 CH/CORTO TURBO",
//       "STARK-15",
//       "STARK-15 CH/CORTO",
//       "STARK-16",
//       "STARK-16 CH/CORTO TURBO",
//       "STARK-17",
//       "STARK-17 CH/LARGO",
//       "STARK-18",
//       "STARK-18 CH/LARGO TURBO"
//     ],
//     "DEUTZ-AGRALE": ["STARK", "STARK TURBO"],
//     "DFM": [
//       "1064",
//       "1216",
//       "1216-E5",
//       "DUOLICA 1063CJ10",
//       "DUOLICA 1064 CJ 10",
//       "DUOLICA 1064 CJ 10",
//       "DUOLICA 1064 DF 1216",
//       "T01"
//     ],
//     "DFSK": ["C31", "C32", "C35", "K01H"],
//     "DIMEX": [
//       "2625 TURBO AFTERCOOLER",
//       "433-160",
//       "451-160",
//       "551-175",
//       "551-175",
//       "551-190",
//       "551-190",
//       "551-250",
//       "551-250",
//       "55125002",
//       "7400-370",
//       "74-310",
//       "91-370",
//       "D 1416",
//       "D 1422 T",
//       "D 1621 T",
//       "D 1721 N",
//       "D 1721 N-01",
//       "D 1721 T",
//       "D 1721 T-01",
//       "D 1725 N",
//       "D 1725 T",
//       "D 1730 T",
//       "D 2730 N"
//     ],
//     "DINA": ["433-160"],
//     "DODGE": [
//       "B2500 4X4",
//       "CALIBER 2.0 CRD",
//       "CARAVAN",
//       "CARAVAN ASKL52",
//       "CARAVAN EC",
//       "CARAVAN ES",
//       "CARAVAN FWD",
//       "CARAVAN LE",
//       "CARAVAN LX",
//       "CARAVAN SE",
//       "CARAVAN SE",
//       "CARAVAN SE ASKH52",
//       "CARAVAN SE SPORT",
//       "CARAVAN SPORT",
//       "CARAVAN SXT",
//       "CARAVAN/SE/ES/LE",
//       "CHALLENGER",
//       "CHALLENGER R/T",
//       "CHALLENGER SRT",
//       "CHALLENGER SRT HELLCAT",
//       "DAKOTA",
//       "DAKOTA",
//       "DAKOTA CLUB CAB 2.5",
//       "DAKOTA CLUB CAB 3.9",
//       "DAKOTA CLUB CAB SPORT",
//       "DAKOTA CLUB CAB SPORT 4X4",
//       "DAKOTA CLUB CAB SPORT TD",
//       "DAKOTA LE (CABINA Y MEDIA)",
//       "DAKOTA SPORT",
//       "DAKOTA SPORT 4X2 TD",
//       "DAKOTA SPORT 4X4",
//       "DAKOTA SPORT 4X4",
//       "DAKOTA SPORT 4X4 V8",
//       "DAKOTA SPORT CABINA",
//       "DAKOTA SPORT TD CABINA",
//       "DAKOTA SPORT V6 MAGNUM",
//       "DAKOTA TD",
//       "DURANGO LIMITED 5.7L HEMI",
//       "DURANGO LIMITED 5.7L HEMI",
//       "DURANGO SLT",
//       "DURANGO SLT",
//       "DURANGO SLT V8 MAGNUM",
//       "DURANGO SLT V8 MAGNUM",
//       "FORZA LX",
//       "GRAND CARAVAN",
//       "GRAND CARAVAN EL",
//       "GRAND CARAVAN ES",
//       "GRAND CARAVAN EX",
//       "GRAND CARAVAN LE",
//       "GRAND CARAVAN SE",
//       "GRAND CARAVAN SPORT",
//       "GRAND CARAVAN SXT",
//       "GRAND CARAVAN/SE/LE",
//       "JOURNEY",
//       "JOURNEY R/T",
//       "JOURNEY R/T 2.4",
//       "JOURNEY R/T 2.7",
//       "JOURNEY R/T 3.6L",
//       "JOURNEY SE 2.4",
//       "JOURNEY SXT 2.4",
//       "JOURNEY SXT 2.7 A/T",
//       "NEON",
//       "NEON",
//       "NEON",
//       "NEON BASE",
//       "NEON OTROS",
//       "NEON SPORT",
//       "NEON SX 2.0",
//       "NEON/SPORT",
//       "NITRO",
//       "RAM",
//       "RAM",
//       "RAM",
//       "RAM",
//       "RAM 150",
//       "RAM 150",
//       "RAM 1500",
//       "RAM 1500",
//       "RAM 1500",
//       "RAM 1500",
//       "RAM 1500 4X4",
//       "RAM 1500 4X4 CABINA Y",
//       "RAM 1500 5.7 V8 LARAMIE",
//       "RAM 1500 CABINA Y MEDIA",
//       "RAM 1500 LARAMIE SLT",
//       "RAM 1500 LARAMIE SLT",
//       "RAM 1500 SPORT",
//       "RAM 1500 V8 MAGNUM",
//       "RAM 250",
//       "RAM 250",
//       "RAM 2500",
//       "RAM 2500",
//       "RAM 2500",
//       "RAM 2500",
//       "RAM 2500 4X4",
//       "RAM 2500 4X4 CABINA Y",
//       "RAM 2500 4X4 HEAVY DUTY",
//       "RAM 2500 CABINA Y MEDIA",
//       "RAM 2500 LARAMIE QUAD",
//       "RAM 2500 LARAMIE QUAD",
//       "RAM 2500 LARAMIE SLT 4X4",
//       "RAM 2500 LARAMIE SLT CAB.",
//       "RAM 2500 SLT 4X4 TD HEAVY",
//       "RAM 2500 SLT LARAMIE",
//       "RAM 2500 SLT LARAMIE",
//       "RAM 2500 SLT QC 4X4 TD",
//       "RAM 2500 SLT QUAD CAB 4X4",
//       "RAM 2500 V8 MAGNUM",
//       "RAM 350",
//       "RAM 350",
//       "RAM 350 ESPECTRUN",
//       "RAM 3500",
//       "RAM 3500",
//       "RAM 3500",
//       "RAM HIGH TOP",
//       "RAM MRM",
//       "RAM SLT 1500",
//       "RAM SLT 1500",
//       "RAM SLT 2500 (CABINA Y",
//       "RAM2500 LARAMIE 4X4TD",
//       "STRATUS",
//       "STRATUS SE",
//       "STRATUS/LX",
//       "VIPER GTS",
//       "VIPER RT 10",
//       "JOURNEY R/T 3.6L",
//       "JOURNEY SXT 3.5"
//     ],
//     "DONGFENG": ["MINI STAR"],
//     "DS": [
//       "DS 3 110 AT6 SO CHIC",
//       "DS 3 CABRIO PURETECH 110",
//       "DS 3 CABRIO PURETECH 110",
//       "DS 3 CABRIO THP 208 MT6",
//       "DS 3 CROSSBACK PURETECH",
//       "DS 3 CROSSBACK PURETECH",
//       "DS 3 CROSSBACK PURETECH",
//       "DS 3 CROSSBACK PURETECH",
//       "DS 3 CROSSBACK PURETECH",
//       "DS 3 CROSSBACK PURETECH",
//       "DS 3 CROSSBACK PURETECH",
//       "DS 3 CROSSBACK PURETECH",
//       "DS 3 PURE TECH 110 AT6",
//       "DS 3 PURETECH 110 AT6 CAFE",
//       "DS 3 PURETECH 110 AT6",
//       "DS 3 PURETECH 110 AT6",
//       "DS 3 PURETECH 110 AT6 SO",
//       "DS 3 PURETECH 110 AT6 SO",
//       "DS 3 THP 165 MT6 SPORT",
//       "DS 3 THP 208 MT6 S&S",
//       "DS 3 THP 208 MT6 S&S",
//       "DS 3 VTI 120 BE CHIC",
//       "DS 3 VTI 120 GIVENCHY",
//       "DS 4 THP 163 AT6 BE CHIC CB",
//       "DS 4 THP 163 AT6 BE CHIC",
//       "DS 4 THP 163 AT6 SO CHIC",
//       "DS 4 THP 163 AT6 SO CHIC",
//       "DS 4 THP 208 MT6 S&S",
//       "DS 7 BASTILLE 215 MY23",
//       "DS 7 CROSSBACK 165 A/T",
//       "DS 7 CROSSBACK E-TENSE 4X4",
//       "DS 7 CROSSBACK HDI 180",
//       "DS 7 CROSSBACK HDI 180",
//       "DS 7 CROSSBACK HDI 180",
//       "DS 7 CROSSBACK HDI 180",
//       "DS 7 CROSSBACK HDI 180",
//       "DS 7 CROSSBACK HDI 180",
//       "DS 7 CROSSBACK HDI 180",
//       "DS 7 CROSSBACK HDI 180",
//       "DS 7 CROSSBACK HDI 180",
//       "DS 7 CROSSBACK HDI 180",
//       "DS 7 CROSSBACK HDI 180",
//       "DS 7 CROSSBACK PURE TECH",
//       "DS 7 CROSSBACK PURETECH",
//       "DS 7 CROSSBACK PURETECH",
//       "DS 7 CROSSBACK THP 165",
//       "DS 7 RIVOLI 215 MY23",
//       "DS 9 RIVOLI 215 AT8",
//       "DS3 THP 156 MT6 SPORT",
//       "DS3 VTI 120 SO CHIC AM40"
//     ],
//     "DUCATI": [
//       "1199 PANIGALE ABS",
//       "1299 PANIGALE",
//       "1299 PANIGALE S",
//       "748",
//       "748 S",
//       "748 SPS",
//       "748R",
//       "749",
//       "749 S",
//       "899 PANIGALE",
//       "999 S",
//       "DIAVEL",
//       "DIAVEL",
//       "DIAVEL CARBON",
//       "DIAVEL CARBON",
//       "HYPERMOTARD",
//       "HYPERMOTARD 939",
//       "M750",
//       "MONSTER 1200",
//       "MONSTER 1200 S",
//       "MONSTER 600",
//       "MONSTER 600 DARK",
//       "MONSTER 620",
//       "MONSTER 620 DARK",
//       "MONSTER 620 DARK I.E.",
//       "MONSTER 696",
//       "MONSTER 696 ABS",
//       "MONSTER 750",
//       "MONSTER 750 DARK",
//       "MONSTER 796",
//       "MONSTER 796 ABS",
//       "MONSTER 797",
//       "MONSTER 821",
//       "MONSTER 900",
//       "MONSTER 900 CROMO",
//       "MONSTER 900 DARK",
//       "MONSTER 900S",
//       "MONSTER 900S 4",
//       "MONSTER S2R 1000",
//       "MONSTER S4R",
//       "MONSTER S4R TESTASTRETTA",
//       "MONSTER S4RS",
//       "MULTISTRADA",
//       "MULTISTRADA 1000 DS",
//       "MULTISTRADA 1000DS S",
//       "MULTISTRADA 1200",
//       "MULTISTRADA 1200 ENDURO",
//       "MULTISTRADA 1200 S",
//       "MULTISTRADA 1200 S",
//       "MULTISTRADA 1200 TOURING",
//       "MULTISTRADA 1260",
//       "MULTISTRADA 1260 S",
//       "MULTISTRADA 950",
//       "MULTISTRADA 950 S",
//       "PANIGALE V4",
//       "PANIGALE V4S",
//       "PAUL SMART 1000",
//       "PAUL SMART 1000 LE",
//       "SCRAMBLER 1100",
//       "SCRAMBLER 1100 SPECIAL",
//       "SCRAMBLER 1100 SPORT",
//       "SCRAMBLER 400 SIXTY 2",
//       "SCRAMBLER 800 FLAT TRACK",
//       "SCRAMBLER 800 CLASSIC",
//       "SCRAMBLER 800 FUL",
//       "SCRAMBLER 800 FULL",
//       "SCRAMBLER 800 ICON",
//       "SCRAMBLER 800 ICON DARK",
//       "SCRAMBLER 800 URBAN",
//       "SCRAMBLER ICON",
//       "SCRAMBLER ICON DARK",
//       "SPORT 1000",
//       "SPORT TOURING 2",
//       "SPORT TOURING 4",
//       "ST3",
//       "STREETFIGHTER V4 S",
//       "SUPER SPORT 750",
//       "SUPER SPORT 900",
//       "SUPERSPORT",
//       "XDIAVEL",
//       "XDIAVEL S"
//     ],
//     "ELITE": ["ELITE 125"],
//     "ELPRA ELECTRIC": [
//       "CITY-M8-CITY CHOPER- CY",
//       "FOLK/ZMAJ05",
//       "INDIE",
//       "MASTER",
//       "TRES DUO",
//       "TWIST/LX05/TW"
//     ],
//     "EUROMOT": [
//       "GTX250B A",
//       "GTX250B B",
//       "HJ110-2",
//       "HJ125-7",
//       "HJ125T-11A",
//       "HJ125T-11A"
//     ],
//     "FAW": ["ACTIS T80", "ACTIS T80", "T33", "X40"],
//     "FERESA": [
//       "AV-120",
//       "AV-120",
//       "TITA ELECTRICA S2-100",
//       "TITA ELECTRICA S2-300"
//     ],
//     "FERRARI": [
//       "330 GT",
//       "360",
//       "360 F1",
//       "360 F1 SPIDER",
//       "360 MODENA",
//       "360 MODENA F1",
//       "430",
//       "430",
//       "430 SCUDERIA",
//       "430 SCUDERIA SPIDER 16M",
//       "456 GT",
//       "456 M GT",
//       "458 ITALIA",
//       "458 SPIDER",
//       "488 GTB",
//       "488 PISTA",
//       "488 SPIDER",
//       "550 MARANELLO",
//       "599 GTB",
//       "CALIFORNIA",
//       "F12 BERLINETTA",
//       "F355 BERLINETTA",
//       "F355 F1 B",
//       "F355 F1 SP",
//       "F355 GTS",
//       "F355 SP",
//       "F355 SPIDER",
//       "F430",
//       "F430",
//       "F599 GTB",
//       "PORTOFINO"
//     ],
//     "FIAT": [
//       "16V 2.0",
//       "185 MAREA 1.9 TD HLX",
//       "185 MAREA 2.0 HL",
//       "185 MAREA 2.0 TD SW",
//       "185 MAREA 2.0 TDS",
//       "185 MAREA 2.4 TD SW",
//       "185 MAREA 2.4 TDS",
//       "330",
//       "330.26",
//       "330.26 P/HY",
//       "500",
//       "500 1.2",
//       "500 1.3 16V JTD",
//       "500 1.4 16V CABRIO",
//       "500 1.4 16V CABRIO LOUNGE",
//       "500 1.4 16V LOUNGE",
//       "500 1.4 16V LOUNGE",
//       "500 1.4 16V SPORT",
//       "500 1.4 16V SPORT",
//       "500 1.4 8V CULT",
//       "500 1.4 MPI 16V",
//       "500 595 TURISMO",
//       "500 ABARTH 1.4 16V",
//       "500 ABARTH 1.4 16V",
//       "500 ABARTH 595 TURISMO",
//       "500 AIR AT 1.4 16V",
//       "500 C 1.4 16V",
//       "500 C 1.4 16V",
//       "500 LOUNGE 1.2",
//       "500 LOUNGE AIR AT 1.4 16V",
//       "500 LOUNGE AIR AT 1.4 16V",
//       "500 LOUNGE AIR AT 1.4 16V",
//       "500 SPORT 16V",
//       "500 SPORT AIR 1.4 16V",
//       "500L 1.4 16V",
//       "500L 1.4 16V",
//       "500X",
//       "500X CROSS 1.4 16V AT9",
//       "500X CROSS 1.4 16V AT9",
//       "500X POP 1.4 16V",
//       "500X POP 1.4 16V",
//       "ARGO DRIVE 1.3",
//       "ARGO HGT 1.8",
//       "ARGO PRECISION 1.8",
//       "ARGO PRECISION 1.8 AT6",
//       "BARCHETTA 1.8 16V",
//       "BRAVA ELX 1.6 16V",
//       "BRAVA ELX 1.6 16V MPI",
//       "BRAVA ELX 1.6 MPI 16V",
//       "BRAVA ELX 1.6 MPI 16V",
//       "BRAVA ELX 1.8 16V",
//       "BRAVA ELX 1.9 JTD",
//       "BRAVA ELX 1.9 JTD",
//       "BRAVA ELX 1.9 JTD",
//       "BRAVO 1.4 16V DYNAMIC",
//       "BRAVO 1.4 16V SPORT",
//       "BRAVO 1.4 DYNAMIC",
//       "BRAVO 1.4 SPORT",
//       "BRAVO 1.6 SX",
//       "BRAVO 1.9",
//       "BRAVO 1.9 JTD 16V SPORT",
//       "BRAVO DYNAMIC 1.4 TURBO",
//       "BRAVO DYNAMIC 1.4 TURBO",
//       "BRAVO DYNAMIC 1.6",
//       "BRAVO DYNAMIC 1.6",
//       "BRAVO HGT 2.0 16V",
//       "BRAVO HGT 2.0 20V",
//       "BRAVO SPORT 1.4 TURBO",
//       "BRAVO SPORT 1.4 TURBO",
//       "CROMA",
//       "CROMA 1.9JTD 16V 150CV",
//       "CROMA 2.0 TDI",
//       "CROMA 2.0 TDI",
//       "CROMA 2.2 MPI 16V",
//       "CROMA 2.4 MJ 20V",
//       "CROMA TURBO DIESEL",
//       "CROMA TURBO DIESEL",
//       "CRONOS 1.3 GSE BZ",
//       "CRONOS 1.3 MT",
//       "CRONOS DRIVE 1.3 GSE BZ",
//       "CRONOS DRIVE 1.3 GSE CVT",
//       "CRONOS DRIVE 1.3 MT",
//       "CRONOS LIKE 1.3 GSE BZ",
//       "CRONOS PRECISION 1.3 GSE",
//       "CRONOS PRECISION 1.8 AT",
//       "CRONOS PRECISION 1.8 MT",
//       "CRONOS STILE 1.3 GSE BZ",
//       "DOBLO 1.3 JTD",
//       "DOBLO 1.4 16V",
//       "DOBLO 1.4 16V",
//       "DOBLO 1.9 ELX",
//       "DOBLO 1.9 MULTIJET",
//       "DOBLO 1.9D",
//       "DOBLO CARGO 1.4 16V",
//       "DOBLO CARGO 1.4 16V",
//       "DUCATO",
//       "DUCATO",
//       "DUCATO",
//       "DUCATO",
//       "DUCATO 1.9D P.C. 10",
//       "DUCATO 10",
//       "DUCATO 10 1.9D",
//       "DUCATO 10 Q.LI",
//       "DUCATO 13",
//       "DUCATO 14",
//       "DUCATO 14 2.5D",
//       "DUCATO 14 Q/D",
//       "DUCATO 14D",
//       "DUCATO 14Q",
//       "DUCATO 15 1.9D",
//       "DUCATO 15 2..8D",
//       "DUCATO 15 2.8 D",
//       "DUCATO 15 2.8D (CORTO)",
//       "DUCATO 15 COMBINATO",
//       "DUCATO 180 MULTIJET",
//       "DUCATO 2.0D P.C.10",
//       "DUCATO 2.5 TURBO DIESEL",
//       "DUCATO 2.5D",
//       "DUCATO 2.5D 14 MAXI",
//       "DUCATO 2.5D MAXI GRAN",
//       "DUCATO 2.5D P.C. 10",
//       "DUCATO 2.5D P.M. 14",
//       "DUCATO 2.5D P.M. 14",
//       "DUCATO 2.5D P.M. PLS",
//       "DUCATO 2.5D P.M. PLS",
//       "DUCATO 2.5D P.M.14",
//       "DUCATO 2.5D P.M.14",
//       "DUCATO 2.5D PM 14 GRAN",
//       "DUCATO 2.5D PM MAXI GRAN",
//       "DUCATO 2.5D PM PLS",
//       "DUCATO 2.8 JTD",
//       "DUCATO 2.8D",
//       "DUCATO 2.8D",
//       "DUCATO 2.8D",
//       "DUCATO 2.8D MAXI GRAN",
//       "DUCATO 2.8D PM",
//       "DUCATO 2.8D PM GRAN",
//       "DUCATO 2.8D PM14",
//       "DUCATO 2.8D PM14",
//       "DUCATO 2.8D PM14 GRAN",
//       "DUCATO 2.8D PM15",
//       "DUCATO 2.8D PM15 GRAN",
//       "DUCATO CARGO 2.3 JTD",
//       "DUCATO CARGO 2.8 IDTD",
//       "DUCATO CARGO 2.8JTD",
//       "DUCATO CARGO 2.8JTD",
//       "DUCATO CARGO MEDIO L2H1",
//       "DUCATO CARGO PASO CORTO",
//       "DUCATO CHASIS CABINA 2.3",
//       "DUCATO CHASIS CABINA L3H1",
//       "DUCATO COMBINATO",
//       "DUCATO COMBINATO 2.3 JTD",
//       "DUCATO COMBINATO 2.8",
//       "DUCATO COMBINATO 2.8",
//       "DUCATO COMBINATO 2.8 JTD",
//       "DUCATO COMBINATO 2.8 JTD",
//       "DUCATO COMBINATO 2.8D",
//       "DUCATO COMBINATO 2.8JTD",
//       "DUCATO COMBINATO 2.8TD",
//       "DUCATO D10",
//       "DUCATO F10 DS",
//       "DUCATO FURGON",
//       "DUCATO MAXI 2.8D",
//       "DUCATO MAXI CARGO L4H2",
//       "DUCATO MAXI CARGO",
//       "DUCATO MAXICARGO 2.3 JTD",
//       "DUCATO MAXICARGO 2.8 ID",
//       "DUCATO MAXICARGO 2.8JTD",
//       "DUCATO MAXICARGO 2.8JTD",
//       "DUCATO MAXICARGO JTD",
//       "DUCATO MINIBUS",
//       "DUCATO OTROS",
//       "DUCATO Q 14 DIESEL",
//       "DUCATO VETRATO 15 2.8D",
//       "DUNA",
//       "DUNA 1.7 D",
//       "DUNA 70",
//       "DUNA CLS",
//       "DUNA CLS",
//       "DUNA CS",
//       "DUNA CS",
//       "DUNA CS D",
//       "DUNA CSAA",
//       "DUNA CSAA",
//       "DUNA CSD",
//       "DUNA CSD",
//       "DUNA CSD",
//       "DUNA CSDAA",
//       "DUNA CSDAA",
//       "DUNA CSI",
//       "DUNA CSL",
//       "DUNA CSL",
//       "DUNA DIESEL 1.7",
//       "DUNA DS",
//       "DUNA DS",
//       "DUNA ELBA",
//       "DUNA NAFTA",
//       "DUNA OTROS",
//       "DUNA S",
//       "DUNA S",
//       "DUNA S 1.4 INYECCION",
//       "DUNA S 1.4 INYECCION",
//       "DUNA S CONFORT 1.3 MPI",
//       "DUNA S CONFORT 1.3 MPI",
//       "DUNA S-AA",
//       "DUNA S-AA",
//       "DUNA SCV / SCR 1.6 / CSL",
//       "DUNA SD",
//       "DUNA SD",
//       "DUNA SD /SDL 1.3",
//       "DUNA SD-AA",
//       "DUNA SD-AA",
//       "DUNA SDL",
//       "DUNA SDL 1.7",
//       "DUNA SDL 1.7 / SDL AA / CSD",
//       "DUNA SDR 1.7 / CSLD /",
//       "DUNA SDR 1.7 / CSLD /",
//       "DUNA SX",
//       "DUNA SX",
//       "DUNA SX (1,7 D.)",
//       "DUNA SX (1.7 D.)",
//       "DUNA SXAA",
//       "DUNA SXAA",
//       "DUNA SXAA (1,7 D.)",
//       "DUNA SXAA (1.7 D.)",
//       "ELBA",
//       "ELBA CSL",
//       "ELBA INNOCENTI 1.7D/1.6",
//       "ELBA OTROS",
//       "EUROCARGO 150 E 20",
//       "EUROCARGO 150 E 23",
//       "EUROCARGO 160 E 23",
//       "FIORINO",
//       "FIORINO",
//       "FIORINO",
//       "FIORINO 1.3 MPI",
//       "FIORINO 1.3 MPI",
//       "FIORINO 1.3 MPI 8V FIRE",
//       "FIORINO 1.3 MULTIJET",
//       "FIORINO 1.7 DIESEL",
//       "FIORINO 1.7 TD",
//       "FIORINO 147",
//       "FIORINO 147",
//       "FIORINO ENDURANCE 1.4 MT",
//       "FIORINO FIRE 1242 MPI 8V",
//       "FIORINO FURGON NAFTA / FU",
//       "FIORINO N/D",
//       "FIORINO OTROS",
//       "FIORINO PANORAMA",
//       "FIORINO PANORAMA KAT 1.4",
//       "FIORINO PU NAFTA/PU NAFTA",
//       "FIORINO P-UP",
//       "FIORINO QUBO 1.4 8V ACTIVE",
//       "FIORINO QUBO 1.4 8V",
//       "FIORINO TREKKING 1.5 I.E.",
//       "FIORINO WORKING 1.5 I.E.",
//       "FIORINO WORKING 1.7D",
//       "FIORINO WORKING 1.7D",
//       "GRAND SIENA ATTRACTIVE",
//       "GRAND SIENA ATTRACTIVE",
//       "GRAND SIENA ESSENCE 1.6",
//       "GRAND SIENA ESSENCE 1.6",
//       "GRAND SIENA ESSENCE 1.6",
//       "GRAND SIENA ESSENCE 1.6",
//       "IDEA 1.4",
//       "IDEA ADVENTURE 1.6 16V",
//       "IDEA ADVENTURE 1.8 8V",
//       "IDEA ATTRACTIVE 1.4 8V",
//       "IDEA ELX 1.4 MPI 8V",
//       "IDEA ESSENCE 1.6 16V",
//       "IDEA HLX 1.8MPI 8V",
//       "IDEA SPORTING 1.6 16V",
//       "IVECO DAILY 49.10 Chasis Con",
//       "LINEA 1.9 16V",
//       "LINEA ABSOLUTE 1.8 16V",
//       "LINEA ABSOLUTE 1.8 16V",
//       "LINEA DUALOGIC 1.9 16V",
//       "LINEA HLX 1.8 16V",
//       "LINEA HLX 1.8 16V DUALOGIC",
//       "MAREA 1.6",
//       "MAREA 1.6 ELX",
//       "MAREA 1.6 SX",
//       "MAREA 1.9 TD ELX",
//       "MAREA 2.0 HLX",
//       "MAREA ELX",
//       "MAREA ELX",
//       "MAREA ELX 1.8",
//       "MAREA ELX 1.9 JTD",
//       "MAREA ELX 1.9 JTD",
//       "MAREA ELX 1.9 JTD",
//       "MAREA ELX 1.9 JTD",
//       "MAREA ELX 2.0 20V",
//       "MAREA ELX 2.0 20V",
//       "MAREA ELX 2.0 20V MPI",
//       "MAREA ELX TD100",
//       "MAREA HLX",
//       "MAREA HLX",
//       "MAREA HLXD",
//       "MAREA HLXD",
//       "MAREA SX",
//       "MAREA SX",
//       "MAREA SX 1.6 MPI 16V",
//       "MAREA SX 1.8",
//       "MAREA SX 2.0 20V",
//       "MAREA SX BIPOWER",
//       "MAREA TD 75 SX",
//       "MAREA TD100 ELX",
//       "MAREA TD100 ELX",
//       "MAREA TD100 HLX",
//       "MAREA WEEKEND 1.9 ELX TD",
//       "MAREA WEEKEND ELX 1.9 JTD",
//       "MAREA WEEKEND ELX 2.0",
//       "MOBI 1.0 8V EASY",
//       "MOBI 1.0 8V WAY",
//       "MOBI LIKE 1.0",
//       "MULTIPLA 1.9 JTD",
//       "NUEVO FIORINO 1.4 8V",
//       "NUEVO PUNTO ATTRACTIVE",
//       "NUEVO PUNTO ATTRACTIVE",
//       "NUEVO PUNTO ESSENCE 1.6",
//       "NUEVO PUNTO ESSENCE 1.6",
//       "NUEVO PUNTO ESSENCE 1.6",
//       "NUEVO PUNTO ESSENCE 1.6",
//       "NUEVO PUNTO ESSENCE 1.6",
//       "NUEVO PUNTO ESSENCE 1.6",
//       "NUEVO UNO ATTRACTIVE 1.4",
//       "NUEVO UNO CARGO 1.4 8V",
//       "NUEVO UNO SPORTING 1.4",
//       "NUEVO UNO WAY 1.4 8V",
//       "PALIO (326) ATTRACTIVE 5P",
//       "PALIO (326) ATTRACTIVE 5P",
//       "PALIO (326) ESCENCE 5P 1.6",
//       "PALIO (326) ESSENCE 5P 1.6",
//       "PALIO 1.4",
//       "PALIO 1.4",
//       "PALIO 1.4 FIRE",
//       "PALIO 1.4 FIRE",
//       "PALIO 1.4 FIRE",
//       "PALIO 1.6",
//       "PALIO 1.6",
//       "PALIO 1.6 SPI",
//       "PALIO 1.7 TD",
//       "PALIO 1.7 TD",
//       "PALIO 1.8R",
//       "PALIO 1.8R",
//       "PALIO 16V",
//       "PALIO 16V",
//       "PALIO 16V MPI",
//       "PALIO ADVENTURE 1.6",
//       "PALIO ADVENTURE 1.6 16V",
//       "PALIO ADVENTURE 1.7TD",
//       "PALIO ADVENTURE 1.8MPI V8",
//       "PALIO ADVENTURE LOCKER",
//       "PALIO ATTRACTIVE 1.4 8V",
//       "PALIO EDX 1.3",
//       "PALIO EDX 1.3",
//       "PALIO EL",
//       "PALIO EL",
//       "PALIO EL 1.6 SPI",
//       "PALIO EL 1.6 SPI",
//       "PALIO EL 1.7 TD",
//       "PALIO EL 1.7 TD",
//       "PALIO EL 3P",
//       "PALIO EL 3P",
//       "PALIO EL 5P",
//       "PALIO EL 5P",
//       "PALIO EL TD 3P",
//       "PALIO EL TD 3P",
//       "PALIO ELD 3P",
//       "PALIO ELD 3P",
//       "PALIO ELD 5P",
//       "PALIO ELD 5P",
//       "PALIO ELX 1.4 8V",
//       "PALIO ELX 1.4 8V",
//       "PALIO ELX 1.7TD",
//       "PALIO ELX 1.7TD",
//       "PALIO ELX 1242 FIRE 16V",
//       "PALIO ELX 1242 FIRE 16V",
//       "PALIO ELX 16V",
//       "PALIO ELX 16V",
//       "PALIO ELX TD",
//       "PALIO ELX TD",
//       "PALIO ELX TD (3P)",
//       "PALIO ELX TD (3P)",
//       "PALIO ELX TD (5P)",
//       "PALIO ELX TD (5P)",
//       "PALIO ELX TORQUE 16V (3P)",
//       "PALIO ELX TORQUE 16V (3P)",
//       "PALIO ELX TORQUE 16V (5P)",
//       "PALIO ELX TORQUE 16V (5P)",
//       "PALIO ESSENCE 1.6 16V",
//       "PALIO ESSENCE 1.6 16V",
//       "PALIO EX FIRE 16V",
//       "PALIO EX FIRE 16V",
//       "PALIO EX FIRE 16V (3P)",
//       "PALIO EX FIRE 16V (3P)",
//       "PALIO EX FIRE 16V (5P)",
//       "PALIO EX FIRE 16V (5P)",
//       "PALIO EX TD",
//       "PALIO EX TD",
//       "PALIO FIRE",
//       "PALIO FIRE 1.4",
//       "PALIO FIRE 1.4",
//       "PALIO FIRE 1.4",
//       "PALIO FIRE 1.4 3P",
//       "PALIO FIRE 1.4 3P",
//       "PALIO FIRE 1.4 5P",
//       "PALIO FIRE 1.4 8V",
//       "PALIO FIRE 1242 MPI 16V",
//       "PALIO FIRE 1242 MPI 16V",
//       "PALIO FIRE 3P 1.4 8V BENZ",
//       "PALIO FIRE 3P 1.4 8V BENZ",
//       "PALIO FIRE 5P 1.4 8V BENZ",
//       "PALIO FIRE 5P 1.4 8V BENZ",
//       "PALIO FIRE RSTII 3P 1.4 8V BZ",
//       "PALIO FIRE RSTII 3P 1.4 8V BZ",
//       "PALIO FIRE RSTII 5P 1.4 8V BZ",
//       "PALIO FIRE RSTII 5P 1.4 8V BZ",
//       "PALIO HL 16V",
//       "PALIO HL 16V",
//       "PALIO HL 3P",
//       "PALIO HL 3P",
//       "PALIO HL 5P",
//       "PALIO HL 5P",
//       "PALIO HLD 3P",
//       "PALIO HLD 3P",
//       "PALIO HLD 5P",
//       "PALIO HLD 5P",
//       "PALIO HLX 1.8 MPI 8V",
//       "PALIO HLX 1.8 MPI 8V",
//       "PALIO S (3P)",
//       "PALIO S (3P)",
//       "PALIO S (5P)",
//       "PALIO S (5P)",
//       "PALIO S 1.3 MPI (3 P)",
//       "PALIO S 1.3 MPI (3 P)",
//       "PALIO S 1.3 MPI (5P)",
//       "PALIO S 1.3 MPI (5P)",
//       "PALIO S 1.3MPI",
//       "PALIO S 1.3MPI",
//       "PALIO S 1.6 SPI",
//       "PALIO S 1.6 SPI",
//       "PALIO SD (3P)",
//       "PALIO SD (3P)",
//       "PALIO SD (5P)",
//       "PALIO SD (5P)",
//       "PALIO SPORTING 1.6 16V",
//       "PALIO SPORTING 1.6 16V",
//       "PALIO STILE 1.6 16V",
//       "PALIO STILE 3P",
//       "PALIO STILE 3P",
//       "PALIO STILE 5P",
//       "PALIO STILE 5P",
//       "PALIO STILE TD",
//       "PALIO STILE TD",
//       "PALIO SX 1.3 MPI",
//       "PALIO SX 1.3 MPI",
//       "PALIO SX 1.3 MPI (3P)",
//       "PALIO SX 1.3 MPI (3P)",
//       "PALIO SX 1.3 MPI (5P)",
//       "PALIO SX 1.3 MPI (5P)",
//       "PALIO SX 1.6 SPI",
//       "PALIO SX 1.6 SPI",
//       "PALIO SX 1.6 SPI (3P)",
//       "PALIO SX 1.6 SPI (3P)",
//       "PALIO SX 1.6 SPI (5P)",
//       "PALIO SX 1.6 SPI (5P)",
//       "PALIO SX 1.7TD",
//       "PALIO SX TD (3P)",
//       "PALIO SX TD (3P)",
//       "PALIO WEEKEND",
//       "PALIO WEEKEND (F4)",
//       "PALIO WEEKEND (F4)",
//       "PALIO WEEKEND (F4)",
//       "PALIO WEEKEND (F4)",
//       "PALIO WEEKEND (F4)",
//       "PALIO WEEKEND (F4)",
//       "PALIO WEEKEND (F4)",
//       "PALIO WEEKEND (F4)",
//       "PALIO WEEKEND 1.4",
//       "PALIO WEEKEND 1.6",
//       "PALIO WEEKEND 1.6",
//       "PALIO WEEKEND 1.6",
//       "PALIO WEEKEND 1.6 SPI",
//       "PALIO WEEKEND 1.7 TD",
//       "PALIO WEEKEND 16V",
//       "PALIO WEEKEND 16V MPI",
//       "PALIO WEEKEND ELX 1.4",
//       "PALIO WEEKEND ELX 1.7 TD",
//       "PALIO WEEKEND ELX 1.7 TD",
//       "PALIO WEEKEND ELX 1.8 MPI",
//       "PALIO WEEKEND ELX 1.8 MPI",
//       "PALIO WEEKEND ELX 16V",
//       "PALIO WEEKEND ELX TD",
//       "PALIO WEEKEND ELX TD",
//       "PALIO WEEKEND ELX TD",
//       "PALIO WEEKEND ELX TORQUE",
//       "PALIO WEEKEND ELX TORQUE",
//       "PALIO WEEKEND STILE 1.6",
//       "PALIO WEEKEND STILE 1.7 TD",
//       "PALIO WEEKEND STILE 16V",
//       "PALIO WEEKEND STILE 16V",
//       "PALIO WEEKEND TD",
//       "PALIO WEEKEND TD",
//       "PALIO WEEKEND TD",
//       "PALIO WEEKEND TD",
//       "PALIO WEEKEND TD LUX",
//       "PALIO WEEKEND TDS",
//       "PALIO WEEKEND TDS",
//       "PALIO WEEKEND TDS SW",
//       "PALIO WEEKEND TREKKING",
//       "PALIO YOUNG",
//       "PALIO YOUNG (3P)",
//       "PALIO YOUNG (3P)",
//       "PALIO YOUNG (5P)",
//       "PALIO YOUNG (5P)",
//       "PALIO YOUNG 1.3 MPI",
//       "PALIO YOUNG 1.3 MPI",
//       "PALIO YOUNG TD",
//       "PANDA",
//       "PANDA 1.2 CLIMBING 4X4",
//       "PANDA 1.3 JTD CROSS",
//       "PANDA 4X4",
//       "PANDA TREKKING",
//       "PREMIO",
//       "PREMIO 1.6",
//       "PREMIO CS",
//       "PREMIO CSL",
//       "PREMIO CSL",
//       "PREMIO OTROS",
//       "PREMIO S",
//       "PREMIO S/CSL",
//       "PREMIO/CS",
//       "PULSE AUDACE 1.0T CVT",
//       "PULSE DRIVE 1.3 CVT",
//       "PULSE DRIVE 1.3 MT5",
//       "PULSE IMPETUS 1.0T CVT",
//       "PUNTO",
//       "PUNTO",
//       "PUNTO ATTRACTIVE 1.4 8V",
//       "PUNTO ELX",
//       "PUNTO ELX 1.3 JTD",
//       "PUNTO ELX 1.4",
//       "PUNTO ESSENCE 1.6 16V",
//       "PUNTO ESSENCE 1.6 16V",
//       "PUNTO EVO SPIRIT",
//       "PUNTO HLX 1.8",
//       "PUNTO S TD60",
//       "PUNTO SPORTING 1.6 16V",
//       "PUNTO SPORTING 1.6 16V",
//       "PUNTO SPORTING1.8",
//       "PUNTO STAR 60",
//       "QUBO 1.4 8V DYNAMIC",
//       "QUBO 1.4 8V ACTIVE",
//       "QUBO 1.4 8V TREKKING",
//       "SCUDO 2.0 JTD",
//       "SCUDO 2.0 JTD",
//       "SCUDO EL 2.0 JTD",
//       "SIENA (F4) EL 1.4 8V",
//       "SIENA (F4) EL 1.4 8V",
//       "SIENA 1.4",
//       "SIENA 1.4 8V ATTRACTIVE",
//       "SIENA 1.4 FIRE",
//       "SIENA 1.6 16V",
//       "SIENA 1.6 16V ESSENCE",
//       "SIENA 1.6 16V ESSENCE",
//       "SIENA 1.6 EL",
//       "SIENA 1.6 EL",
//       "SIENA ATTRACTIVE 1.4",
//       "SIENA ATTRACTIVE 1.4",
//       "SIENA EDX 1.3 MPI",
//       "SIENA EDX 1.3 MPI",
//       "SIENA EL 1.4",
//       "SIENA EL 1.4 8V",
//       "SIENA EL 1.4 BENZINA",
//       "SIENA EL 1.4 BENZINA",
//       "SIENA EL 1.6",
//       "SIENA EL 1.6 16V",
//       "SIENA EL 1.6SPI",
//       "SIENA EL 4P",
//       "SIENA EL 4P",
//       "SIENA ELD 4P",
//       "SIENA ELD 4P",
//       "SIENA ELTDS 1.7",
//       "SIENA ELX 1.4",
//       "SIENA ELX 1.4 8V",
//       "SIENA ELX 1.7 TD",
//       "SIENA ELX 16V",
//       "SIENA ELX 4P 1.4RSTIII BZ",
//       "SIENA ELX 4P 1.4RSTIII BZ",
//       "SIENA ELX FIRE 1242 MPI 16V",
//       "SIENA ELX TD",
//       "SIENA ELX TD",
//       "SIENA ELX TD",
//       "SIENA ELX TORQUE 16V",
//       "SIENA ELX TORQUE 16V",
//       "SIENA ESSENCE 1.6 16V BZ",
//       "SIENA ESSENCE 1.6 16V BZ",
//       "SIENA EX FIRE 1.3 MPI 16V",
//       "SIENA EX FIRE 1242 MPI 16V",
//       "SIENA EX FIRE 16V",
//       "SIENA EX FIRE 16V",
//       "SIENA EX FIRE 16V",
//       "SIENA EX FIRE 16V P/GNC",
//       "SIENA EX FIRE 16V P/GNC",
//       "SIENA EX TD",
//       "SIENA EX TD",
//       "SIENA EX TD",
//       "SIENA FIRE 1.4",
//       "SIENA FIRE 1242 MPI 16V",
//       "SIENA FIRE 4P 1.4MPI 8V HP",
//       "SIENA FIRE 4P 1.4MPI 8V HP",
//       "SIENA FLP ELX 1.4 BENZI",
//       "SIENA FLP ELX 1.4 BENZI",
//       "SIENA FLP HLX 1.8 BENZI",
//       "SIENA FLP HLX 1.8 BENZI",
//       "SIENA HL 4P",
//       "SIENA HL 4P",
//       "SIENA HLD 4P",
//       "SIENA HLD 4P",
//       "SIENA HLX 1.8MPI 8V",
//       "SIENA S",
//       "SIENA S",
//       "SIENA SD",
//       "SIENA SD",
//       "SIENA STILE 4P",
//       "SIENA STILE 4P",
//       "SIENA STILE TD",
//       "SIENA STILE TD",
//       "SIENA SX 1.6 SPI",
//       "SIENA SX 1.6 SPI",
//       "SIENA SX 1.6 SPI P/GNC",
//       "SIENA SX 1.6 SPI P/GNC",
//       "STILE 1.6 16V MPI",
//       "STILO 1.6",
//       "STILO 1.8 16V",
//       "STILO 1.8 16V MPI",
//       "STILO 1.8 16V MPI",
//       "STILO 1.8 16V MPI",
//       "STILO 1.8 MPI 8V",
//       "STILO 1.9 JTD",
//       "STILO 1.9 JTD",
//       "STILO 1.9 JTD",
//       "STILO 1.9 JTD",
//       "STILO 1.9 JTD",
//       "STRADA 1.3",
//       "STRADA ADVENTURE (F4) 1.6",
//       "STRADA ADVENTURE (F4) 1.6",
//       "STRADA ADVENTURE (F4) 1.6",
//       "STRADA ADVENTURE (F4) 1.6",
//       "STRADA ADVENTURE 1.6",
//       "STRADA ADVENTURE 1.6",
//       "STRADA ADVENTURE 1.7TD",
//       "STRADA ADVENTURE 1.8MPI",
//       "STRADA ADVENTURE 1.8MPI",
//       "STRADA ADVENTURE 1.8MPI",
//       "STRADA ENDURANCE 1.4 CD",
//       "STRADA ENDURANCE 1.4 CS",
//       "STRADA EX 1.7TD",
//       "STRADA FIRE MPI 8V",
//       "STRADA FREEDOM 1.4 CD",
//       "STRADA LX 1.6MPI 16V",
//       "STRADA LX 1.7TD",
//       "STRADA RANCH 1.3 CVT",
//       "STRADA TREKKING (F4) 1.3",
//       "STRADA TREKKING (F4) 1.3",
//       "STRADA TREKKING (F4) 1.3",
//       "STRADA TREKKING (F4) 1.3",
//       "STRADA TREKKING (F4) 1.4 CC",
//       "STRADA TREKKING (F4) 1.4 CC",
//       "STRADA TREKKING 1.3 16V",
//       "STRADA TREKKING 1.3 JTD",
//       "STRADA TREKKING 1.3 JTD",
//       "STRADA TREKKING 1.4",
//       "STRADA TREKKING 1.6",
//       "STRADA TREKKING 1.7TD",
//       "STRADA TREKKING 1.7TD",
//       "STRADA TREKKING 1.7TD",
//       "STRADA TREKKING 1.7TD",
//       "STRADA TREKKING 1.8 MPI 8V",
//       "STRADA TREKKING 1.8 MPI 8V",
//       "STRADA TREKKING 1.8MPI 8V",
//       "STRADA TREKKING 1.8MPI 8V",
//       "STRADA TREKKING 1.8MPI8V",
//       "STRADA TREKKING 1.8MPI8V",
//       "STRADA VOLCANO 1.3 CD",
//       "STRADA WORKING (F4) 1.4 CC",
//       "STRADA WORKING (F4) 1.4 CC",
//       "STRADA WORKING (F4) 1.4",
//       "STRADA WORKING (F4) 1.4",
//       "STRADA WORKING 1.4",
//       "STRADA WORKING 1.7 TD",
//       "TIPO EASY 1.6 AT6",
//       "TIPO POP 1.6 AT6",
//       "TORO ENDURANCE 2.0 AT9",
//       "TORO FREEDOM 1.8 16V 4X2",
//       "TORO FREEDOM 1.8 CD AT6",
//       "TORO FREEDOM 2.0 16V 4X2",
//       "TORO FREEDOM 2.0 16V 4X4",
//       "TORO FREEDOM 2.0 16V 4X4",
//       "TORO FREEDOM 2.0 CD AT9",
//       "TORO RANCH 2.0 AT9 4WD",
//       "TORO RANCH 2.0 CD AT9 4X4",
//       "TORO ULTRA 2.0 CD AT9 4X4",
//       "TORO VOLCANO 1.3T CD AT6",
//       "TORO VOLCANO 2.0 16V 4X4",
//       "TORO VOLCANO 2.0 CD AT9",
//       "UNO",
//       "UNO",
//       "UNO 1.0 IE",
//       "UNO 1.3",
//       "UNO 1.3",
//       "UNO 1.3 MPI 8V FIRE",
//       "UNO 1.3 MPI 8V FIRE",
//       "UNO 1.3MPI",
//       "UNO 1.4 8V",
//       "UNO 1.4 8V",
//       "UNO 1.5 TREND",
//       "UNO 1.6R",
//       "UNO 1.7",
//       "UNO 1.7 D",
//       "UNO 1.7 DS",
//       "UNO 1.7/D",
//       "UNO 45 STYLE",
//       "UNO 60 S",
//       "UNO 60 SD",
//       "UNO BASE FIRE",
//       "UNO CDL 3P",
//       "UNO CL 1.7D",
//       "UNO CLI",
//       "UNO CS",
//       "UNO CS",
//       "UNO CS",
//       "UNO CS 1.7D",
//       "UNO CS 1500",
//       "UNO CS 3P",
//       "UNO CS 3P",
//       "UNO CS 5P",
//       "UNO CS 5P",
//       "UNO CS DIESEL",
//       "UNO CS DIESEL",
//       "UNO CSAA 3P",
//       "UNO CSAA 3P",
//       "UNO CSAA 5P",
//       "UNO CSAA 5P",
//       "UNO CSD 1.7/3P",
//       "UNO CSD 1.7/3P",
//       "UNO CSD 1.7/5P",
//       "UNO CSD 1.7/5P",
//       "UNO CSDAA 1.7/3P",
//       "UNO CSDAA 1.7/3P",
//       "UNO CSDAA 1.7/5P",
//       "UNO CSDAA 1.7/5P",
//       "UNO CSL",
//       "UNO DIESEL",
//       "UNO DS",
//       "UNO FIRE 1.0 IE",
//       "UNO FIRE 1242 MPI 8V",
//       "UNO FIRE 1242 MPI 8V",
//       "UNO FIRE 1242 MPI 8V",
//       "UNO FIRE MPI 8V",
//       "UNO FIRE MPI 8V",
//       "UNO S",
//       "UNO S",
//       "UNO S",
//       "UNO S (1.4 - 3 P.)",
//       "UNO S (1.4 - 3 P.)",
//       "UNO S (1.4 - 5 P.)",
//       "UNO S (1.4 - 5 P.)",
//       "UNO S (1.7 D.- 3 P.)",
//       "UNO S (1.7 D.- 3 P.)",
//       "UNO S (1.7 D.- 5 P.)",
//       "UNO S (1.7 D.- 5 P.)",
//       "UNO S 1.3",
//       "UNO S 1.3",
//       "UNO S 1.3 MPI",
//       "UNO S 1.3 MPI",
//       "UNO S 1.3 MPI (3P)",
//       "UNO S 1.3 MPI (3P)",
//       "UNO S 1.3 MPI (5P)",
//       "UNO S 1.3 MPI (5P)",
//       "UNO S 1.4 INYECCION 3P",
//       "UNO S 1.4 INYECCION 3P",
//       "UNO S 1.4 INYECCION 5P",
//       "UNO S 1.4 INYECCION 5P",
//       "UNO S 1.4 SPI (3P) S.E.",
//       "UNO S 1.4 SPI (3P) S.E.",
//       "UNO S 1.4 SPI (5P) S.E.",
//       "UNO S 1.4 SPI (5P) S.E.",
//       "UNO S 1.4/CARB/3P",
//       "UNO S 1.4/CARB/3P",
//       "UNO S 1.4/CARB/5P",
//       "UNO S 1.4/CARB/5P",
//       "UNO S CARGO",
//       "UNO SAA (1,7 D.- 3 P.)",
//       "UNO SAA (1,7 D.- 5 P.)",
//       "UNO SAA (1.7 D.- 3 P.)",
//       "UNO SAA (1.7 D.- 5 P.)",
//       "UNO SC (1,4 - 3 P.)",
//       "UNO SC (1.4 - 3 P.)",
//       "UNO SCR",
//       "UNO SCRI 3P",
//       "UNO SCRI 5P",
//       "UNO SCV /SCR 3P",
//       "UNO SCV-5P/ SCR 5P",
//       "UNO SL 1.4 / 1.4 (3P) CL 1.6 /",
//       "UNO SMART",
//       "UNO SMART 1.1",
//       "UNO SMART/1.1",
//       "UNO SPORTING 1.4 8V",
//       "UNO SPORTING 1.4 8V",
//       "UNO START",
//       "UNO STING",
//       "UNO STING",
//       "UNO STING 1.0 IE",
//       "UNO STING 1.0 IE",
//       "UNO SUITE",
//       "UNO SUITE 1.4IE",
//       "UNO SX (3 P.)",
//       "UNO SX (3 P.)",
//       "UNO SX (5 P.)",
//       "UNO SX (5 P.)",
//       "UNO SX 1.4 IE",
//       "UNO SX AA SPEED",
//       "UNO SXAA (3 P.)",
//       "UNO SXAA (3 P.)",
//       "UNO SXAA (5 P.)",
//       "UNO SXAA (5 P.)",
//       "UNO TD",
//       "UNO TREND",
//       "UNO TURBO",
//       "UNO TURBO 1.4",
//       "UNO TURBO 1.4 IE",
//       "UNO TURBO/1.4",
//       "UNO VAN FIRE 1242 MPI 8V",
//       "UNO VAN FIRE 1242 MPI 8V",
//       "UNO VAN FIRE MPI 8V",
//       "UNO VAN FIRE MPI 8V",
//       "UNO WAY 1.3",
//       "UNO WAY 1.3 MPI 8V",
//       "UNO WAY 1.3 MPI 8V",
//       "UNO WAY 1.4",
//       "UNO WAY 1.4 8V",
//       "VETRATO GV 2800 DSPM",
//       "WEEKEND ADVENTURE 1.6",
//       "WEEKEND ADVENTURE 1.6",
//       "WEEKEND ADVENTURE 1.6",
//       "WEEKEND ATTRACTIVE 1.4",
//       "WEEKEND ATTRACTIVE 1.4",
//       "WEEKEND ATTRACTIVE 1.4 8V",
//       "WEEKEND TREKKING 1.6",
//       "WORKING 1.7D",
//       "500 ABARTH 1.4 16V T-JET",
//       "BRAVO DYNAMIC 1.6 16V",
//       "DOBLO 1.2",
//       "DOBLO 1.4 16V ACTIVE",
//       "DOBLO CARGO 1.4 16V",
//       "GRAND SIENA ATTRACTIVE",
//       "GRAND SIENA ESSENCE 1.6",
//       "GRAND SIENA ESSENCE 1.6",
//       "PALIO ADVENTURE 1.6 16V",
//       "PALIO WEEKEND ADVENTURE",
//       "PALIO WEEKEND ATTRACTIVE",
//       "PALIO WEEKEND TREKKING",
//       "STRADA WORKING 1.4 8V",
//       "STRADA WORKING 1.4 8V"
//     ],
//     "FIAT IVECO": ["170 NC 26", "330-26", "330-30", "330-35"],
//     "FORD": [
//       "150 CUSTOM",
//       "150 CUSTOM",
//       "8000",
//       "8000",
//       "BRONCO SPORT BIG BEND",
//       "BRONCO SPORT WILDTRAK",
//       "C-1517",
//       "CARGO 1119-39",
//       "CARGO 1119-42",
//       "CARGO 1317",
//       "CARGO 1317E",
//       "CARGO 1415",
//       "CARGO 1415",
//       "CARGO 1416",
//       "CARGO 1416",
//       "CARGO 1517",
//       "CARGO 1517",
//       "CARGO 1517E",
//       "CARGO 1519 35 AMT",
//       "CARGO 1519 48 AMT",
//       "CARGO 1519-35",
//       "CARGO 1519-48",
//       "CARGO 1617",
//       "CARGO 1617",
//       "CARGO 1622",
//       "CARGO 1716",
//       "CARGO 1716",
//       "CARGO 1721",
//       "CARGO 1721",
//       "CARGO 1722",
//       "CARGO 1722",
//       "CARGO 1722",
//       "CARGO 1722",
//       "CARGO 1722",
//       "CARGO 1722/35",
//       "CARGO 1722/43",
//       "CARGO 1722/48",
//       "CARGO 1722E",
//       "CARGO 1722E",
//       "CARGO 1722E MAX TRUCK",
//       "CARGO 1722E/43 REPTO",
//       "CARGO 1722E/43 REPTO",
//       "CARGO 1723 4X2 - CD AMT 37",
//       "CARGO 1723 4X2 - CD AMT 37",
//       "CARGO 1723 4X2 - CD AMT 37",
//       "CARGO 1723 4X2 - CD AMT 48",
//       "CARGO 1723 4X2 - CD MT 37",
//       "CARGO 1723 4X2 - CD MT 37",
//       "CARGO 1723 4X2 - CD MT 43",
//       "CARGO 1723 4X2 - CD MT 43",
//       "CARGO 1723 4X2 - CD MT 48",
//       "CARGO 1723 4X2 - CN AMT",
//       "CARGO 1723 4X2 - CN AMT",
//       "CARGO 1723 4X2 - CN MT 43",
//       "CARGO 1723 4X2 - CN MT 48",
//       "CARGO 1723 4X2 CN MT 37",
//       "CARGO 1723 6X2 - 48 CD AMT",
//       "CARGO 1723 6X2 - 48 CD MT",
//       "CARGO 1723 6X2 - 53 CD AMT",
//       "CARGO 1723 6X2 - 53 CD MT",
//       "CARGO 1723-43 4X2 CD AMT",
//       "CARGO 1723-48 4X2 CN AMT",
//       "CARGO 1723-48 CN AMT 6X2",
//       "CARGO 1723-48 CN MT 6X2",
//       "CARGO 1723-53 CN AMT 6X2",
//       "CARGO 1723-53 CN MT 6X2",
//       "CARGO 1729 - 37 CN MT",
//       "CARGO 1729 - 43 CN AMT",
//       "CARGO 1729 - 43 CN MT",
//       "CARGO 1729 - 48 CN AMT",
//       "CARGO 1729 - 48 CN MT",
//       "CARGO 1729 6X2 - 48 CD AMT",
//       "CARGO 1729 6X2 - 48 CN",
//       "CARGO 1729 6X2 - 48 CN MT",
//       "CARGO 1729 6X2 -48 CD MT",
//       "CARGO 1729 6X2 -48 CD MT",
//       "CARGO 1729 6X2 53 CD MT",
//       "CARGO 1729-37 CD AMT",
//       "CARGO 1729-37 CD MT",
//       "CARGO 1729-37 CN AMT",
//       "CARGO 1729-48 CD AMT",
//       "CARGO 1729-48 CD MT",
//       "CARGO 1729-48 CN AMT",
//       "CARGO 1729-48 CN MT",
//       "CARGO 1730",
//       "CARGO 1730",
//       "CARGO 1730",
//       "CARGO 1731 6X2 53 CD AMT",
//       "CARGO 1731 6X2 53 CN AMT",
//       "CARGO 1731 6X2-48 CD AMT",
//       "CARGO 1731 6X2-48 CN AMT",
//       "CARGO 1731 6X2-48 CN AMT",
//       "CARGO 1831",
//       "CARGO 1832E",
//       "CARGO 1832E",
//       "CARGO 1932",
//       "CARGO 1932",
//       "CARGO 1932",
//       "CARGO 1932",
//       "CARGO 1932",
//       "CARGO 1933 - 48 CN MT",
//       "CARGO 1933 48 CD AL AMT",
//       "CARGO 1933 48 CD ST AMT",
//       "CARGO 1933 48 CN ST AMT",
//       "CARGO 1933-36 CD AMT",
//       "CARGO 1933-37 CD MT",
//       "CARGO 1933-37 CD MT",
//       "CARGO 1933-48 CD MT",
//       "CARGO 2042",
//       "CARGO 2042 6X2 - 33 CD AMT",
//       "CARGO 2625",
//       "CARGO 2631",
//       "CARGO 2632",
//       "CARGO 2632E",
//       "CARGO 2842 6X2 - 33 CD AMT",
//       "CARGO 3129-34",
//       "CARGO 3129-45",
//       "CARGO 3131 6X4 45 AMT V",
//       "CARGO 3131 6X4-34-H",
//       "CARGO 3131 6X4-34-V",
//       "CARGO 3131 6X4-45-H",
//       "CARGO 3131 6X4-45-V",
//       "CARGO 712",
//       "CARGO 8000",
//       "CARGO 8000",
//       "CARGO 914",
//       "CARGO 914",
//       "CARGO 915",
//       "CARGO 915",
//       "CARGO 915E",
//       "CARGO 916",
//       "CF8000",
//       "COURIER",
//       "COURIER",
//       "COURIER",
//       "COURIER SI",
//       "COURIER XL",
//       "COURIER XL PLUS",
//       "D 900-88",
//       "D-1614",
//       "ECOSPORT 1.4L TDCI 4X2 XL",
//       "ECOSPORT 1.4L TDCI 4X2 XL",
//       "ECOSPORT 1.4L TDCI 4X2 XLS",
//       "ECOSPORT 1.4L TDCI 4X2 XLS",
//       "ECOSPORT 1.4L TDCI XL PLUS",
//       "ECOSPORT 1.6 L 4X2 XL PLUS",
//       "ECOSPORT 1.6 L 4X2 XLS",
//       "ECOSPORT 1.6L 4X2 XL",
//       "ECOSPORT 1.6L 4X2 XL C/1AB",
//       "ECOSPORT 1.6L 4X2 XL PLUS",
//       "ECOSPORT 1.6L 4X2 XL PLUS",
//       "ECOSPORT 1.6L 4X2 XL PLUS",
//       "ECOSPORT 1.6L 4X2 XL S/AB",
//       "ECOSPORT 1.6L 4X2 XLS ABS",
//       "ECOSPORT 1.6L 4X2 XLS ABS",
//       "ECOSPORT 1.6L 4X2 XLS",
//       "ECOSPORT 1.6L 4X2 XLS MP3",
//       "ECOSPORT 1.6L 4X2 XLS PLUS",
//       "ECOSPORT 1.6L 4X2 XLS S/AB",
//       "ECOSPORT 1.6L 4X2 XLS S/AC",
//       "ECOSPORT 1.6L 4X2 XLT",
//       "ECOSPORT 1.6L 4X2 XLT",
//       "ECOSPORT 1.6L 4X2 XLT PLUS",
//       "ECOSPORT 1.6L 4X2 XLT SVP",
//       "ECOSPORT 2.0 L 4X2 XLT",
//       "ECOSPORT 2.0 XLT PLUS",
//       "ECOSPORT 2.0L 4WD XLT",
//       "ECOSPORT 2.0L 4WD XLT MP3",
//       "ECOSPORT 2.0L 4WD XLT",
//       "ECOSPORT 2.0L 4X2 XLT AT",
//       "ECOSPORT 2.0L 4X2 XLT LOW",
//       "ECOSPORT 2.0L 4X2 XLT MP3",
//       "ECOSPORT 2.0L 4X2 XLT PLUS",
//       "ECOSPORT 2.0L 4X2 XLT PLUS",
//       "ECOSPORT 2.0L 4X2 XLT PLUS",
//       "ECOSPORT 2.0L 4X2 XLT PLUS",
//       "ECOSPORT 2.0L XLS 4X2",
//       "ECOSPORT 2.0L XLT PLUS",
//       "ECOSPORT 2.0L XLT PLUS",
//       "ECOSPORT 2.0L XLT PLUS SVP",
//       "ECOSPORT FREESTYLE 1.5L",
//       "ECOSPORT FREESTYLE 2.0L AT",
//       "ECOSPORT S 1.5L MT N",
//       "ECOSPORT SE 1.5L AT N",
//       "ECOSPORT SE 1.5L DSL MT",
//       "ECOSPORT SE 1.5L MT N",
//       "ECOSPORT SE 2.0L AT N",
//       "ECOSPORT STORM 2.0L AT",
//       "ECOSPORT TITANIUM 1.5L AT",
//       "ECOSPORT TITANIUM 1.5L MT",
//       "ECOSPORT TITANIUM 2.0L AT",
//       "EDGE",
//       "EDGE",
//       "ESCAPE",
//       "ESCAPE",
//       "ESCAPE 2.0 SE",
//       "ESCAPE SE",
//       "ESCAPE XLS 4X2",
//       "ESCAPE XLS 4X2",
//       "ESCAPE XLS 4X4",
//       "ESCAPE XLT",
//       "ESCAPE XLT 4X4",
//       "ESCORT",
//       "ESCORT",
//       "ESCORT",
//       "ESCORT",
//       "ESCORT",
//       "ESCORT 1.3 GHIA",
//       "ESCORT 1.3 GHIA",
//       "ESCORT 1.3 JLA",
//       "ESCORT 1.4",
//       "ESCORT 1.4",
//       "ESCORT 1.4",
//       "ESCORT 1.4 CL",
//       "ESCORT 1.4 GHIA",
//       "ESCORT 1.4I CLX",
//       "ESCORT 1.6 CL DIESEL",
//       "ESCORT 1.6 CLX",
//       "ESCORT 1.6 CLX",
//       "ESCORT 1.6 DIESEL",
//       "ESCORT 1.6I 16V",
//       "ESCORT 1.6I 16V",
//       "ESCORT 1.8 GHIA",
//       "ESCORT 1.8 GHIA",
//       "ESCORT 1.8 TD",
//       "ESCORT 1.8 TD",
//       "ESCORT 1.8 TD",
//       "ESCORT 1100",
//       "ESCORT CABRIOLET",
//       "ESCORT CL 1.8 5P. 45B",
//       "ESCORT CL DIESEL",
//       "ESCORT CL DIESEL",
//       "ESCORT CLIPPER 16V GHIA",
//       "ESCORT CLX",
//       "ESCORT CLX",
//       "ESCORT CLX",
//       "ESCORT CLX",
//       "ESCORT CLX",
//       "ESCORT CLX 1.6I",
//       "ESCORT CLX 1.8 4P D",
//       "ESCORT CLX 1.8 4P N",
//       "ESCORT CLX 1.8 4P. N 45F",
//       "ESCORT CLX 1.8 4P. N 45L",
//       "ESCORT CLX 1.8 4P.D 451",
//       "ESCORT CLX 1.8 4P.D 45A",
//       "ESCORT CLX 1.8 4P.D 45B",
//       "ESCORT CLX 1.8 4P.D 45S",
//       "ESCORT CLX 1.8 4P.D 45T",
//       "ESCORT CLX 1.8 4P.N 451",
//       "ESCORT CLX 1.8 4P.N 452",
//       "ESCORT CLX 1.8 4P.N 45A",
//       "ESCORT CLX 1.8 4P.N 45B",
//       "ESCORT CLX 1.8 4P.N 45S",
//       "ESCORT CLX 1.8 4P.N 45T",
//       "ESCORT CLX 1.8 5P D",
//       "ESCORT CLX 1.8 5P D 45F",
//       "ESCORT CLX 1.8 5P D 45R",
//       "ESCORT CLX 1.8 5P D.45M",
//       "ESCORT CLX 1.8 5P N",
//       "ESCORT CLX 1.8 5P N 45D",
//       "ESCORT CLX 1.8 5P N 45F",
//       "ESCORT CLX 1.8 5P N 45G",
//       "ESCORT CLX 1.8 5P N.45M",
//       "ESCORT CLX 1.8 5P N.45N",
//       "ESCORT CLX 1.8 5P TD",
//       "ESCORT CLX 1.8 5P. N 45L",
//       "ESCORT CLX 1.8 5P.D 451",
//       "ESCORT CLX 1.8 5P.D 45B",
//       "ESCORT CLX 1.8 5P.D 45S",
//       "ESCORT CLX 1.8 5P.D 45T",
//       "ESCORT CLX 1.8 5P.N 451",
//       "ESCORT CLX 1.8 5P.N 45A",
//       "ESCORT CLX 1.8 5P.N 45B",
//       "ESCORT CLX 1.8 5P.N 45S",
//       "ESCORT CLX 1.8 5P.N 45T",
//       "ESCORT CLX 1.8 D (3 P)",
//       "ESCORT CLX 1.8 D (4 P)",
//       "ESCORT CLX 1.8 D (5 P)",
//       "ESCORT CLX 1.8 I (4 P)",
//       "ESCORT CLX 1.8 I (5 P)",
//       "ESCORT CLX 1.8I (3 P)",
//       "ESCORT CLX 5P 1.8L N",
//       "ESCORT CLX DIESEL",
//       "ESCORT CLX DIESEL",
//       "ESCORT CLX DIESEL",
//       "ESCORT COSWORTH",
//       "ESCORT CROSS LX 1.6 5P N",
//       "ESCORT CROSS RURAL LX 1.6",
//       "ESCORT GHIA",
//       "ESCORT GHIA",
//       "ESCORT GHIA 1.4",
//       "ESCORT GHIA 1.8",
//       "ESCORT GHIA 1.8 3P D",
//       "ESCORT GHIA 1.8 3P N",
//       "ESCORT GHIA 1.8 4P D",
//       "ESCORT GHIA 1.8 4P N",
//       "ESCORT GHIA 1.8 4P.D 45E",
//       "ESCORT GHIA 1.8 4P.D 45U",
//       "ESCORT GHIA 1.8 4P.D 45Y",
//       "ESCORT GHIA 1.8 4P.N 45E",
//       "ESCORT GHIA 1.8 4P.N 45U",
//       "ESCORT GHIA 1.8 4P.N 45W",
//       "ESCORT GHIA 1.8 4P.N 45Y",
//       "ESCORT GHIA 1.8 5P D 45T",
//       "ESCORT GHIA 1.8 5P N",
//       "ESCORT GHIA 1.8 5P N. 45P",
//       "ESCORT GHIA 1.8 5P N.45Q",
//       "ESCORT GHIA 1.8 5P TD",
//       "ESCORT GHIA 1.8 5P.D 45E",
//       "ESCORT GHIA 1.8 5P.D 45U",
//       "ESCORT GHIA 1.8 5P.D 45W",
//       "ESCORT GHIA 1.8 5P.D 45Y",
//       "ESCORT GHIA 1.8 5P.N 45E",
//       "ESCORT GHIA 1.8 5P.N 45U",
//       "ESCORT GHIA 1.8 5P.N 45W",
//       "ESCORT GHIA 1.8 5P.N 45Y",
//       "ESCORT GHIA 1.8 D (4 P)",
//       "ESCORT GHIA 1.8 D (5 P)",
//       "ESCORT GHIA 1.8 I (4 P)",
//       "ESCORT GHIA 1.8 I (5 P)",
//       "ESCORT GHIA 1.8 TD",
//       "ESCORT GHIA 1.8 TD",
//       "ESCORT GHIA 1.8D (3 P)",
//       "ESCORT GHIA 1.8I (3 P)",
//       "ESCORT GHIA 5P D",
//       "ESCORT GHIA CLX 1.8 5P N",
//       "ESCORT GHIA DIESEL",
//       "ESCORT GHIA S 1.8 L",
//       "ESCORT GHIA SX - 1.6 L",
//       "ESCORT GL",
//       "ESCORT GL 1.6i",
//       "ESCORT GL 1.8 3P N 45E",
//       "ESCORT GL 1.8 I",
//       "ESCORT GL 1.8i",
//       "ESCORT GL 4P N",
//       "ESCORT GL 5P 1.6L N",
//       "ESCORT GL 5P N",
//       "ESCORT GL I 1.6",
//       "ESCORT GLX 1.6i",
//       "ESCORT GLX 1.8 I",
//       "ESCORT GLX 1.8i",
//       "ESCORT GLX 5P 1.8 N",
//       "ESCORT GLXD",
//       "ESCORT GT",
//       "ESCORT HOBBY 1.0",
//       "ESCORT LX",
//       "ESCORT LX",
//       "ESCORT LX",
//       "ESCORT LX",
//       "ESCORT LX - 1.6 L",
//       "ESCORT LX 1.6 4P N",
//       "ESCORT LX 1.6 5P N",
//       "ESCORT LX 1.8 3P D",
//       "ESCORT LX 1.8 3P N",
//       "ESCORT LX 1.8 3P N 453",
//       "ESCORT LX 1.8 3P N 45E",
//       "ESCORT LX 1.8 3P N 45L",
//       "ESCORT LX 1.8 3P. N 45K",
//       "ESCORT LX 1.8 4P D",
//       "ESCORT LX 1.8 4P D 45J",
//       "ESCORT LX 1.8 4P N",
//       "ESCORT LX 1.8 4P N 45P",
//       "ESCORT LX 1.8 4P TD",
//       "ESCORT LX 1.8 4P. D 45F",
//       "ESCORT LX 1.8 4P. D 45H",
//       "ESCORT LX 1.8 4P. N 45F",
//       "ESCORT LX 1.8 4P. N 45G",
//       "ESCORT LX 1.8 4P. N 45H",
//       "ESCORT LX 1.8 4P.D 451",
//       "ESCORT LX 1.8 4P.D 452",
//       "ESCORT LX 1.8 4P.D 453",
//       "ESCORT LX 1.8 4P.D 45A",
//       "ESCORT LX 1.8 4P.D 45B",
//       "ESCORT LX 1.8 4P.D 45C",
//       "ESCORT LX 1.8 4P.D 45D",
//       "ESCORT LX 1.8 4P.D 45S",
//       "ESCORT LX 1.8 4P.N 451",
//       "ESCORT LX 1.8 4P.N 452",
//       "ESCORT LX 1.8 4P.N 453",
//       "ESCORT LX 1.8 4P.N 45A",
//       "ESCORT LX 1.8 4P.N 45B",
//       "ESCORT LX 1.8 4P.N 45C",
//       "ESCORT LX 1.8 4P.N 45D",
//       "ESCORT LX 1.8 4P.N 45S",
//       "ESCORT LX 1.8 5P D",
//       "ESCORT LX 1.8 5P D 45F",
//       "ESCORT LX 1.8 5P D 45H",
//       "ESCORT LX 1.8 5P D 45J",
//       "ESCORT LX 1.8 5P N",
//       "ESCORT LX 1.8 5P N 45F",
//       "ESCORT LX 1.8 5P N 45H",
//       "ESCORT LX 1.8 5P N 45J",
//       "ESCORT LX 1.8 5P N 45R",
//       "ESCORT LX 1.8 5P N.45M",
//       "ESCORT LX 1.8 5P N.45P",
//       "ESCORT LX 1.8 5P TD",
//       "ESCORT LX 1.8 5P.D 451",
//       "ESCORT LX 1.8 5P.D 452",
//       "ESCORT LX 1.8 5P.D 453",
//       "ESCORT LX 1.8 5P.D 45A",
//       "ESCORT LX 1.8 5P.D 45B",
//       "ESCORT LX 1.8 5P.D 45C",
//       "ESCORT LX 1.8 5P.D 45D",
//       "ESCORT LX 1.8 5P.D 45S",
//       "ESCORT LX 1.8 5P.N 451",
//       "ESCORT LX 1.8 5P.N 452",
//       "ESCORT LX 1.8 5P.N 453",
//       "ESCORT LX 1.8 5P.N 45A",
//       "ESCORT LX 1.8 5P.N 45B",
//       "ESCORT LX 1.8 5P.N 45C",
//       "ESCORT LX 1.8 5P.N 45D",
//       "ESCORT LX 1.8 5P.N 45S",
//       "ESCORT LX 1.8 D (4 P)",
//       "ESCORT LX 1.8 D (5 P)",
//       "ESCORT LX 1.8 I (4 P)",
//       "ESCORT LX 1.8 I (5 P)",
//       "ESCORT LX 1.8I (3 P)",
//       "ESCORT LX DIESEL",
//       "ESCORT LX DIESEL",
//       "ESCORT LX DIESEL",
//       "ESCORT LX PONY",
//       "ESCORT OTROS",
//       "ESCORT RS 1.6",
//       "ESCORT RS 1.6",
//       "ESCORT RURAL CLX 1.8 D",
//       "ESCORT RURAL CLX 1.8 D 451",
//       "ESCORT RURAL CLX 1.8 D 45A",
//       "ESCORT RURAL CLX 1.8 D 45B",
//       "ESCORT RURAL CLX 1.8 D 45R",
//       "ESCORT RURAL CLX 1.8 D 45S",
//       "ESCORT RURAL CLX 1.8 D 45T",
//       "ESCORT RURAL CLX 1.8 N",
//       "ESCORT RURAL CLX 1.8 N 451",
//       "ESCORT RURAL CLX 1.8 N 45A",
//       "ESCORT RURAL CLX 1.8 N 45B",
//       "ESCORT RURAL CLX 1.8 N 45F",
//       "ESCORT RURAL CLX 1.8 N 45G",
//       "ESCORT RURAL CLX 1.8 N 45L",
//       "ESCORT RURAL CLX 1.8 N 45S",
//       "ESCORT RURAL CLX 1.8 N 45T",
//       "ESCORT RURAL CLX 1.8 N.",
//       "ESCORT RURAL CLX 1.8 TD",
//       "ESCORT RURAL CLX 1.8.D 45L",
//       "ESCORT RURAL GHIA 1.8 D",
//       "ESCORT RURAL GHIA 1.8 D",
//       "ESCORT RURAL GHIA 1.8 D",
//       "ESCORT RURAL GHIA 1.8 N",
//       "ESCORT RURAL GHIA 1.8 N",
//       "ESCORT RURAL GHIA 1.8 N",
//       "ESCORT RURAL GHIA 1.8 N",
//       "ESCORT RURAL GHIA 1.8 TD",
//       "ESCORT RURAL GL 1.6 N",
//       "ESCORT RURAL GL 1.8 N",
//       "ESCORT RURAL GLX 1.8L N",
//       "ESCORT RURAL LX 1.6 N",
//       "ESCORT RURAL LX 1.8 D",
//       "ESCORT RURAL LX 1.8 N",
//       "ESCORT RURAL LX 1.8 N 451",
//       "ESCORT RURAL LX 1.8 N 452",
//       "ESCORT RURAL LX 1.8 N 453",
//       "ESCORT RURAL LX 1.8 N 45A",
//       "ESCORT RURAL LX 1.8 N 45B",
//       "ESCORT RURAL LX 1.8 N 45C",
//       "ESCORT RURAL LX 1.8 N 45D",
//       "ESCORT RURAL LX 1.8 N 45F",
//       "ESCORT RURAL LX 1.8 N 45H",
//       "ESCORT RURAL LX 1.8 N 45J",
//       "ESCORT RURAL LX 1.8 N 45S",
//       "ESCORT RURAL LX 1.8 N.45P",
//       "ESCORT RURAL LX 1.8 TD",
//       "ESCORT RURAL LX 1.8.D 451",
//       "ESCORT RURAL LX 1.8.D 452",
//       "ESCORT RURAL LX 1.8.D 453",
//       "ESCORT RURAL LX 1.8.D 45A",
//       "ESCORT RURAL LX 1.8.D 45B",
//       "ESCORT RURAL LX 1.8.D 45C",
//       "ESCORT RURAL LX 1.8.D 45D",
//       "ESCORT RURAL LX 1.8.D 45S",
//       "ESCORT RURAL LX 1.8D 45H",
//       "ESCORT SE",
//       "ESCORT SW CLX 1.8 D (4 P)",
//       "ESCORT SW CLX 1.8 I (4 P)",
//       "ESCORT SW GHIA 1.8 D (4 P)",
//       "ESCORT SW GHIA 1.8 I (4 P)",
//       "ESCORT SW LX 1.8 D (4 P)",
//       "ESCORT SW LX 1.8 I (4 P)",
//       "EXCURSION LIMITED",
//       "EXCURSION LIMITED",
//       "EXCURSION LIMITED 4X4",
//       "EXCURSION XLT",
//       "EXPEDITION",
//       "EXPEDITION",
//       "EXPEDITION EDDIE BAUER",
//       "EXPEDITION EDDIE BAUER",
//       "EXPEDITION EDDIE BAUER",
//       "EXPEDITION EDDIE BAUER",
//       "EXPEDITION XLT",
//       "EXPEDITION XLT 4X4",
//       "EXPEDITION XLT TRITON V8",
//       "EXPEDITION XLT TRITON V8",
//       "EXPLORER",
//       "EXPLORER",
//       "EXPLORER",
//       "EXPLORER",
//       "EXPLORER 4.0 I 4X4",
//       "EXPLORER 4X4 4WD",
//       "EXPLORER ADVANCETRAC RSC",
//       "EXPLORER EDDIE BAUER",
//       "EXPLORER EDDIE BAUER",
//       "EXPLORER LIMITED",
//       "EXPLORER LIMITED",
//       "EXPLORER SPORT",
//       "EXPLORER SPORT",
//       "EXPLORER SPORT TRAC XLT",
//       "EXPLORER XL",
//       "EXPLORER XL",
//       "EXPLORER XL CONTROL TRAC",
//       "EXPLORER XLS",
//       "EXPLORER XLS",
//       "EXPLORER XLT",
//       "EXPLORER XLT",
//       "EXPLORER XLT ADVANCETRAC",
//       "EXPLORER/XLT",
//       "F 150",
//       "F 150",
//       "F 150",
//       "F 150",
//       "F 150 XL",
//       "F 150 XL",
//       "F 250 SUPER DUTY",
//       "F 250 SUPER DUTY",
//       "F 250 SUPER DUTY XLT",
//       "F 250 XL",
//       "F 250 XLT",
//       "F 350 XL SUPER DUTY",
//       "F-100",
//       "F-100",
//       "F-100 (DOBLE CABINA)",
//       "F-100 4.9",
//       "F-100 4.9I",
//       "F-100 4X2",
//       "F-100 4X2 XL 3.9D CAB.SIMP.",
//       "F-100 4X2 XL PLUS 3.9D",
//       "F-100 4X2 XL PLUS 3.9D",
//       "F-100 4X2 XLT 3.9D CAB.DOB.",
//       "F-100 4X2 XLT 3.9D",
//       "F100 4X4",
//       "F100 4X4",
//       "F100 4X4",
//       "F-100 4X4 D",
//       "F-100 4X4 XL 3.9D CAB.SIMP.",
//       "F-100 4X4 XL PLUS 3.9D",
//       "F-100 4X4 XL PLUS 3.9D",
//       "F-100 4X4 XLT 3.9D CAB.DOB.",
//       "F-100 4X4 XLT 3.9D",
//       "F-100 CD 4X2 XL PLUS 3.9D",
//       "F-100 CD 4X2 XLT 3.9D",
//       "F-100 CD 4X4 XL PLUS 3.9D",
//       "F-100 CD 4X4 XLT 3.9D",
//       "F-100 CS 4X2 XL 3.9D",
//       "F-100 CS 4X2 XL PLUS 3.9D",
//       "F-100 CS 4X2 XLT PLUS 3.9D",
//       "F-100 CS 4X4 XL 3.9D",
//       "F-100 CS 4X4 XL PLUS 3.9D",
//       "F-100 CS 4X4 XLT 3.9D",
//       "F-100 CUSTOM",
//       "F100 D 4X2 (DOBLE CABINA)",
//       "F-100 DIESEL",
//       "F-100 DIESEL",
//       "F-100 DIESEL (117",
//       "F-100 DIESEL (CARROZADO)",
//       "F-100 DIESEL 4X4",
//       "F-100 DIESEL SUPER CABINA",
//       "F-100 DIESEL TURBO",
//       "F-100 D-SC (138,8')-3,9L XLT",
//       "F-100 D-SC (138.8",
//       "F-100 DSL",
//       "F-100 NAFTA",
//       "F-100 NAFTA SUPERCAB",
//       "F100 PICK-UP",
//       "F-100 SC (138.8",
//       "F-100 STD TURBO DIESEL",
//       "F-100 SUPER CABINA",
//       "F-100 SUPER CABINA DIESEL",
//       "F100 SUPER DUTY (NAFTA)",
//       "F100 SUPER DUTY DSL",
//       "F-100 XL",
//       "F-100 XL DIESEL",
//       "F-100 XL DIESEL SUPER DUTY",
//       "F100 XLT",
//       "F-100 XLT (DOBLE CABINA)",
//       "F-100 XLT DIESEL",
//       "F-100 XLT DIESEL SUPER",
//       "F-100 XLT TURBO DIESEL",
//       "F-100/88 STD 4X2",
//       "F-100/88 STD 4X2 DIESEL",
//       "F-100/88 XLT 4X2",
//       "F-100/88 XLT 4X2 DIESEL",
//       "F100/F101 OTROS",
//       "F-1000",
//       "F-1000 D 4X2",
//       "F-1000 D 4X4",
//       "F-14.000",
//       "F-14.000 H.D.",
//       "F-14.000 H.D.",
//       "F-14000/HD",
//       "F150",
//       "F-150",
//       "F-150 (PATRULLERA)",
//       "F150 / SUPER CABINA",
//       "F-150 4X2",
//       "F-150 4X2 DIESEL",
//       "F-150 4X2 XLT TRIM",
//       "F-150 4X4",
//       "F-150 5.4 TRITON",
//       "F-150 5.4 TRITON",
//       "F150 CAB",
//       "F150 CUST/CAB/L",
//       "F150 CUSTOM",
//       "F150 CUSTOM",
//       "F-150 CUSTOM 4X4",
//       "F-150 DIESEL (133",
//       "F150 FLAIR S-CAB",
//       "F-150 FLARESIDE",
//       "F-150 FLARESIDE CABINA Y",
//       "F150 FLARESIDE XLT",
//       "F150 HARLEY DAVIDSON",
//       "F-150 KING RANCH",
//       "F-150 LARIAT",
//       "F-150 LARIAT",
//       "F150 LARIAT 5.4 TRITON 4X4",
//       "F-150 LARIAT LUXURY 3.5L V6",
//       "F-150 LARIAT LUXURY 5.0L V8",
//       "F150 OTROS",
//       "F150 RANG/ECONO",
//       "F150 RANGER",
//       "F-150 RAPTOR 3.5L V6",
//       "F-150 STYLESIDE",
//       "F-150 SUPERCAB",
//       "F150 SUPERCREW",
//       "F-150 SVT RAPTOR",
//       "F-150 TRITON V8",
//       "F-150 TRITON V8",
//       "F-150 TRITON V8 FX4 OFF",
//       "F150 TRITON V8 LARIAT",
//       "F-150 XL 4X2 STYLESIDE",
//       "F150 XL 4X4",
//       "F-150 XL 4X4 STYLESIDE",
//       "F-150 XL I",
//       "F-150 XL TRITON V8",
//       "F-150 XL TRITON V8",
//       "F-150 XL TRITON V8 4X4",
//       "F-150 XLT",
//       "F-150 XLT",
//       "F-150 XLT",
//       "F-150 XLT",
//       "F-150 XLT 4X2",
//       "F-150 XLT 4X4",
//       "F150 XLT 4X4 (CABINA Y",
//       "F-150 XLT FLAIR",
//       "F-150 XLT FLAIR S-CAB",
//       "F150 XLT FLAIR/FLAIR S-CAB",
//       "F-150 XLT FLARESIDE",
//       "F150 XLT LARIAT LIMITED",
//       "F-150 XLT S-CAB",
//       "F-150 XLT STYLESIDE",
//       "F-150 XLT TRITON V8",
//       "F-150 XLT TRITON V8",
//       "F-150 XLT TRITON V8 FX4 OFF",
//       "F150 XLT/XLT S-CAB",
//       "F-250",
//       "F250 CAMION F150 DIES 133",
//       "F250 LARIAT SUPER DUTY",
//       "F250 TURBO DIESEL",
//       "F-250 XL SUPER DUTY 4X4",
//       "F250 XLT SUPER DUTY",
//       "F250 XLT SUPER DUTY",
//       "F250 XLT SUPER DUTY",
//       "F350 CAMION",
//       "F350 LARIAT SUPER DUTY",
//       "F350 LARIAT SUPER DUTY",
//       "F350 SUPER DUTY POWER",
//       "F-350 XL DIESEL (DOBLE",
//       "F-350 XL SUPER DUTY",
//       "F-350 XL TURBO DIESEL",
//       "F350LARIAT SUPER DUTY",
//       "F400 D C/CABINA / F4000",
//       "F-4000",
//       "F-4000 D",
//       "F-4000 D",
//       "F-4000 D 4X4",
//       "F-4000 DIESEL - 3,9L",
//       "F-4000/D",
//       "F-450",
//       "F600 CAMION / F100 PICK-UP",
//       "F-800",
//       "F-800",
//       "FESTIVA",
//       "FESTIVA GL",
//       "FESTIVA L",
//       "FESTIVA XL",
//       "FIESTA",
//       "FIESTA",
//       "FIESTA 1.3",
//       "FIESTA 1.4 GHIA",
//       "FIESTA 1.4 TDCI",
//       "FIESTA 1.4L AMBIENTE MP3",
//       "FIESTA 1.4L AMBIENTE TDCI",
//       "FIESTA 1.4L EDGE PLUS TDCI",
//       "FIESTA 1.4L ENERGY TDCI",
//       "FIESTA 1.4L FLEET TDCI",
//       "FIESTA 1.6L AMBIENTE",
//       "FIESTA 1.6L AMBIENTE",
//       "FIESTA 1.6L AMBIENTE AT",
//       "FIESTA 1.6L AMBIENTE AT",
//       "FIESTA 1.6L AMBIENTE MP3",
//       "FIESTA 1.6L AMBIENTE PLUS",
//       "FIESTA 1.6L AMBIENTE PLUS",
//       "FIESTA 1.6L AMBIENTE PLUS",
//       "FIESTA 1.6L EDGE PLUS",
//       "FIESTA 1.6L EDGE PLUS AT",
//       "FIESTA 1.6L EDGE PLUS AT",
//       "FIESTA 1.6L ENERGY",
//       "FIESTA 1.6L ENERGY MP3",
//       "FIESTA 1.6L FLEET",
//       "FIESTA 1.6L FLEET",
//       "FIESTA 1.6L S",
//       "FIESTA 1.6L S",
//       "FIESTA 1.6L S PLUS",
//       "FIESTA 1.6L S PLUS",
//       "FIESTA 1.6L SE",
//       "FIESTA 1.6L SE",
//       "FIESTA 1.6L SE PLUS",
//       "FIESTA 1.6L SE PLUS",
//       "FIESTA 1.6L SE PLUS",
//       "FIESTA 1.6L SE PLUS",
//       "FIESTA 1.6L SE POWERSHIFT",
//       "FIESTA 1.6L SE POWERSHIFT",
//       "FIESTA 1.6L TITANIUM",
//       "FIESTA 1.6L TITANIUM",
//       "FIESTA 1.6L TITANIUM",
//       "FIESTA 1.6L TITANIUM",
//       "FIESTA 1.8 D",
//       "FIESTA 1600",
//       "FIESTA AMBIENTE 5P",
//       "FIESTA AMBIENTE MP3",
//       "FIESTA AMBIENTE MP3 TDCI",
//       "FIESTA AMBIENTE PLUS MP3",
//       "FIESTA AMBIENTE TDCI 5P",
//       "FIESTA CLX",
//       "FIESTA CLX 1.4",
//       "FIESTA CLX 1.8D",
//       "FIESTA CLX D",
//       "FIESTA CLX D",
//       "FIESTA CLX D FULL",
//       "FIESTA CURRIER",
//       "FIESTA EDGE 5P",
//       "FIESTA EDGE MP3 TDCI",
//       "FIESTA EDGE PLUS 5P",
//       "FIESTA EDGE PLUS MP3",
//       "FIESTA EDGE TDCI 5P",
//       "FIESTA ENERGY 5P",
//       "FIESTA ENERGY MP3",
//       "FIESTA ENERGY TDCI 5P",
//       "FIESTA FLEET",
//       "FIESTA GHIA DIESEL",
//       "FIESTA JA8",
//       "FIESTA KINETIC DESIGN",
//       "FIESTA KINETIC DESIGN",
//       "FIESTA KINETIC DESIGN",
//       "FIESTA KINETIC DESIGN",
//       "FIESTA KINETIC DESIGN",
//       "FIESTA L",
//       "FIESTA LX",
//       "FIESTA LX",
//       "FIESTA LX 1.8D",
//       "FIESTA LX D",
//       "FIESTA LX D",
//       "FIESTA MAX 1.4 AMBIENTE",
//       "FIESTA MAX 1.4L AMBIENTE",
//       "FIESTA MAX 1.4L EDGE PLUS",
//       "FIESTA MAX 1.4L ENERGY",
//       "FIESTA MAX 1.4L FLEET TDCI",
//       "FIESTA MAX 1.6 AMBIENTE",
//       "FIESTA MAX 1.6L AMBIENTE",
//       "FIESTA MAX 1.6L AMBIENTE",
//       "FIESTA MAX 1.6L AMBIENTE",
//       "FIESTA MAX 1.6L AMBIENTE",
//       "FIESTA MAX 1.6L EDGE PLUS",
//       "FIESTA MAX 1.6L EDGE PLUS",
//       "FIESTA MAX 1.6L ENERGY",
//       "FIESTA MAX 1.6L ENERGY",
//       "FIESTA MAX 1.6L FLEET",
//       "FOCUS",
//       "FOCUS",
//       "FOCUS",
//       "FOCUS",
//       "FOCUS 1.6",
//       "FOCUS 1.6",
//       "FOCUS 1.6 TDCI",
//       "FOCUS 1.6 TDCI",
//       "FOCUS 1.8 TDCI",
//       "FOCUS 1.8 TDCI",
//       "FOCUS 1.8 TDDI",
//       "FOCUS 1.8 TDDI",
//       "FOCUS 1.8 TDI",
//       "FOCUS 1.8 TDI",
//       "FOCUS 1.8 TDI",
//       "FOCUS 1.8 TDI",
//       "FOCUS 1.8 TURBO DIESEL",
//       "FOCUS 1.8 TURBO DIESEL",
//       "FOCUS 4P 1.6L N MT S",
//       "FOCUS 4P 2.0L N AT SE",
//       "FOCUS 4P 2.0L N AT SE PLUS",
//       "FOCUS 4P 2.0L N AT",
//       "FOCUS 4P 2.0L N GLX",
//       "FOCUS 4P 2.0L N GLX",
//       "FOCUS 4P 2.0L N LUX AUTOM",
//       "FOCUS 4P 2.0L N MT SE",
//       "FOCUS 4P 2.0L N MT SE PLUS",
//       "FOCUS 4P 2.0L N MT",
//       "FOCUS 4P 2.0L NAFRA",
//       "FOCUS 5P 1.6L N MT S",
//       "FOCUS 5P 2.0L N AT SE",
//       "FOCUS 5P 2.0L N AT SE PLUS",
//       "FOCUS 5P 2.0L N AT",
//       "FOCUS 5P 2.0L N GLX AUTOM",
//       "FOCUS 5P 2.0L N LUX AUTOM",
//       "FOCUS 5P 2.0L N LUX",
//       "FOCUS 5P 2.0L N MT SE",
//       "FOCUS 5P 2.0L N MT SE PLUS",
//       "FOCUS 5P 2.0L N MT",
//       "FOCUS 5P 2.0L NAFTA GLX",
//       "FOCUS 5P EDGE 1.8L TDI",
//       "FOCUS 5P EDGE 2.0L NAFTA",
//       "FOCUS 5P GHIA 2.0L NAFTA",
//       "FOCUS 5P GLX BASE 2.0L N",
//       "FOCUS AMBIENTE 1.6L",
//       "FOCUS AMBIENTE 1.6L",
//       "FOCUS AMBIENTE 4P 1.6L N",
//       "FOCUS AMBIENTE 4P 1.8 L N",
//       "FOCUS AMBIENTE 4P 1.8L N",
//       "FOCUS AMBIENTE 4P 1.8L TD",
//       "FOCUS AMBIENTE 4P 1.8L TDI",
//       "FOCUS AMBIENTE 4P 1.8TD",
//       "FOCUS AMBIENTE 5P 1.6L N",
//       "FOCUS AMBIENTE 5P 1.6L N",
//       "FOCUS AMBIENTE 5P 1.8 L N",
//       "FOCUS AMBIENTE 5P 1.8L N",
//       "FOCUS AMBIENTE 5P 1.8L TD",
//       "FOCUS AMBIENTE 5P 1.8L TD",
//       "FOCUS AMBIENTE 5P 1.8L TDI",
//       "FOCUS CLX 1.8 5P N",
//       "FOCUS CLX 1.8 5P N",
//       "FOCUS CLX 2.0 4P N",
//       "FOCUS CLX 2.0 4P N",
//       "FOCUS CLX 2.0L 4P N AT",
//       "FOCUS CLX 2.0L 4P N C/AUT.",
//       "FOCUS CLX 2.0L 4P N C/AUT.",
//       "FOCUS CLX 2.0L 4P N MT",
//       "FOCUS CLX 2.0L 5P N",
//       "FOCUS CLX 4P 1.8L TD",
//       "FOCUS CLX 4P 1.8L TD",
//       "FOCUS C-MAX 1.6",
//       "FOCUS C-MAX 1.6TDCI",
//       "FOCUS C-MAX 2.0 TDCI",
//       "FOCUS DSL",
//       "FOCUS EDGE 1.6L N",
//       "FOCUS EDGE 1.6L N",
//       "FOCUS EDGE 2.0L NAFTA 4P",
//       "FOCUS EDGE 4P 1.6L N",
//       "FOCUS EDGE 4P 1.8L N",
//       "FOCUS EDGE 4P 1.8L TD",
//       "FOCUS EDGE 4P 1.8L TD",
//       "FOCUS EDGE 4P 1.8L TDI",
//       "FOCUS EDGE 4P 2.0L N",
//       "FOCUS EDGE 4P 2.0L N",
//       "FOCUS EDGE 4P N AUT.",
//       "FOCUS EDGE 4P N AUT.",
//       "FOCUS EDGE 5P 1.6L N",
//       "FOCUS EDGE 5P 1.6L N",
//       "FOCUS EDGE 5P 1.8L N",
//       "FOCUS EDGE 5P 1.8L N",
//       "FOCUS EDGE 5P 2.0 L N",
//       "FOCUS EXE GHIA 1.8L DIESEL",
//       "FOCUS EXE GHIA 2.0L NAFTA",
//       "FOCUS EXE GHIA 2.0L NAFTA",
//       "FOCUS EXE STYLE 1.6L NAFTA",
//       "FOCUS EXE STYLE 1.8L DIESEL",
//       "FOCUS EXE TREND 1.6L",
//       "FOCUS EXE TREND 2.0L",
//       "FOCUS EXE TREND PLUS 1.8L",
//       "FOCUS EXE TREND PLUS 2.0L",
//       "FOCUS GHIA",
//       "FOCUS GHIA",
//       "FOCUS GHIA",
//       "FOCUS GHIA 1.8 4 P TD",
//       "FOCUS GHIA 1.8 4P TD",
//       "FOCUS GHIA 1.8 5P TD",
//       "FOCUS GHIA 1.8 5P TD",
//       "FOCUS GHIA 1.8L DIESEL",
//       "FOCUS GHIA 1.8TDCI",
//       "FOCUS GHIA 1.8TDCI",
//       "FOCUS GHIA 2.0",
//       "FOCUS GHIA 2.0",
//       "FOCUS GHIA 2.0 4P N",
//       "FOCUS GHIA 2.0 4P N",
//       "FOCUS GHIA 2.0 4P N",
//       "FOCUS GHIA 2.0 5P N",
//       "FOCUS GHIA 2.0 5P N",
//       "FOCUS GHIA 2.0L NAFTA",
//       "FOCUS GHIA 2.0L NAFTA AT",
//       "FOCUS GHIA 2.OL NAFTA",
//       "FOCUS GHIA 4P N C. AUT.",
//       "FOCUS GHIA 4P N C. AUT.",
//       "FOCUS GHIA 4P N C.AUT.",
//       "FOCUS GHIA 5P 2.0 L N C.AUT",
//       "FOCUS GHIA D",
//       "FOCUS GHIA D",
//       "FOCUS GHIA DURATEC 2.0",
//       "FOCUS GHIA TD 1.8",
//       "FOCUS GHIA TD 1.8",
//       "FOCUS GHIA XR 5P 2.0 L N",
//       "FOCUS GL 5P 1.8L N",
//       "FOCUS GLX 2.0L 5P N AT",
//       "FOCUS GLX 4P 2.0L N",
//       "FOCUS GLX 5P 1.8L N",
//       "FOCUS L 1.8 TDI",
//       "FOCUS L 1.8 TDI",
//       "FOCUS L 1.8TD AMBIENTE",
//       "FOCUS LX",
//       "FOCUS LX 1.8 5P N",
//       "FOCUS LX 1.8 5P N",
//       "FOCUS LX 1.8 5P TD",
//       "FOCUS LX 1.8 5P TD",
//       "FOCUS LX 2.0L 3P N AT",
//       "FOCUS LX 2.0L 4P N MT",
//       "FOCUS LX TDI",
//       "FOCUS LX TDI",
//       "FOCUS ONE AMBIENTE 4P",
//       "FOCUS ONE AMBIENTE 5P",
//       "FOCUS ONE AMBIENTE TX 4P",
//       "FOCUS ONE EDGE 4P 1.6L N",
//       "FOCUS ONE EDGE 5P 1.6 N",
//       "FOCUS ONE ENERGY 4P 1.6L",
//       "FOCUS ONE ENERGY 5P 1.6L",
//       "FOCUS RS",
//       "FOCUS SE",
//       "FOCUS SE",
//       "FOCUS STYLE 1.6L NAFTA",
//       "FOCUS STYLE 1.8L DIESEL",
//       "FOCUS TDI GHIA",
//       "FOCUS TREND 1.6L NAFTA",
//       "FOCUS TREND 1.8 TDCI",
//       "FOCUS TREND 1.8 TDCI",
//       "FOCUS TREND 2.0L NAFTA",
//       "FOCUS TREND 5P 2.0L N AT",
//       "FOCUS TREND PLUS 1.8L",
//       "FOCUS TREND PLUS 2.0L",
//       "FOCUS ZX3",
//       "FOCUS ZX5",
//       "FREESTAR",
//       "FREESTAR SE",
//       "FREESTAR SES",
//       "F-SUPER DUTY",
//       "F-SUPER DUTY 4X4",
//       "FUSION",
//       "FUSION",
//       "FUSION HYBRID SE",
//       "FUSION SE",
//       "FUSION TDCI",
//       "GHIA 4P 2.0L N AUTOM",
//       "KA",
//       "KA 1.6",
//       "KA 1.6 BLACK",
//       "KA 1.6 XR",
//       "KA ACTION 1.6L",
//       "KA FLY 1.0L",
//       "KA FLY PLUS 1.0L",
//       "KA FLY VIRAL 1.0L",
//       "KA FLY VIRAL 1.6L",
//       "KA FREESTYLE 1.5L",
//       "KA FREESTYLE 1.5L MT",
//       "KA FREESTYLE SE 1.5L MT",
//       "KA FREESTYLE SEL 1.5L AT",
//       "KA FREESTYLE SEL 1.5L MT",
//       "KA PULSE 1.6L",
//       "KA S 1.5L",
//       "KA SE 1.5L",
//       "KA SE 1.5L AT",
//       "KA SE 1.5L AT",
//       "KA SEDAN S",
//       "KA SEDAN SE",
//       "KA SEDAN SEL",
//       "KA SEL 1.5L",
//       "KA SEL 1.5L",
//       "KA SEL 1.5L AT",
//       "KA SEL 1.5L AT",
//       "KA TATTOO PLUS 1.6L",
//       "KA TECNO",
//       "KA TECNO GL",
//       "KA TOP PULSE1.6L",
//       "KA VIRAL",
//       "KUGA",
//       "KUGA SE 2.5L HIBRIDO AT",
//       "KUGA SEL 1.6L 6AT AWD",
//       "KUGA SEL 1.6T 6AT AWD",
//       "KUGA SEL 1.6T 6AT AWD",
//       "KUGA SEL 1.6T 6MT FWD",
//       "KUGA SEL 2.0L ECOBOOST",
//       "KUGA SEL 2.0L ECOBOOST",
//       "KUGA SELMT6 ECOBOOST",
//       "KUGA SEP MT6 ECOBOOST",
//       "KUGA TITANIUM 1.6T 6AT",
//       "KUGA TITANIUM 1.6T 6AT",
//       "KUGA TITANIUM 2.0L",
//       "KUGA TITANIUM 2.0L",
//       "KUGA TITANIUM 2.5L",
//       "KUGA TITANIUM 2.5T AT 4X4",
//       "KUGA TITANIUM 2.5T AT 4X4",
//       "KUGA TREND 2.5T MT 4X4",
//       "L 8000",
//       "L 8000",
//       "L8501",
//       "L8513",
//       "LN 800",
//       "LN 8000",
//       "LN/LNT LN 800",
//       "LN/LNT LN/L/8000",
//       "LN/LNT LNT 800",
//       "LN/LNT LNT/LTS/ 8000",
//       "LNT 8000",
//       "LNT LN 800",
//       "LNT LN 8000",
//       "LNT LNT 800",
//       "LNT LNT 8000",
//       "LT 8501",
//       "LTS 8000",
//       "LTS 9513",
//       "MAVERICK LARIAT 2.0L 4X4",
//       "MAVERICK XLT 2.0L 4X2 AT",
//       "MONDEO",
//       "MONDEO",
//       "MONDEO",
//       "MONDEO 16V",
//       "MONDEO 2.0 DIESEL",
//       "MONDEO 2.0 EXPRESSIVE",
//       "MONDEO 2.0 TDCI",
//       "MONDEO 2.0 TDCI",
//       "MONDEO CLX",
//       "MONDEO CLX",
//       "MONDEO CLX",
//       "MONDEO CLX D",
//       "MONDEO CLX D",
//       "MONDEO CLX NAFTA / CLX",
//       "MONDEO CLX TD RURAL",
//       "MONDEO GHIA",
//       "MONDEO GHIA",
//       "MONDEO GHIA",
//       "MONDEO GHIA 1.8L DIESEL",
//       "MONDEO GHIA 1.8L TDCI",
//       "MONDEO GHIA 16 V",
//       "MONDEO GHIA 16 V",
//       "MONDEO GHIA 2.0",
//       "MONDEO GHIA 2.0 TDCI",
//       "MONDEO GHIA 2.0L NAFTA",
//       "MONDEO GHIA 2.3L",
//       "MONDEO GHIA TD",
//       "MONDEO GHIA TD AT",
//       "MONDEO GHIA V6 AT",
//       "MONDEO GHIA/GHIA 16V",
//       "MONDEO GLX",
//       "MONDEO GLX 16V",
//       "MONDEO GLX TD",
//       "MONDEO HIBRIDO",
//       "MONDEO HIBRIDO TITANIUM",
//       "MONDEO LX",
//       "MONDEO OTROS",
//       "MONDEO SE 2.5L AT",
//       "MONDEO SE ECOBOOST 2.0L",
//       "MONDEO SEL ECOBOOST 2.0L",
//       "MONDEO ST 200",
//       "MONDEO ST TDCI",
//       "MONDEO ST220",
//       "MONDEO TD",
//       "MONDEO TD",
//       "MONDEO TDCI",
//       "MONDEO TITANIUM",
//       "MONDEO TITANIUM 1.8L",
//       "MONDEO TITANIUM 1.8L",
//       "MONDEO TITANIUM 2.0 TDCI",
//       "MONDEO TITANIUM 2.0L",
//       "MONDEO TITANIUM 2.0L",
//       "MONDEO TITANIUM 2.3",
//       "MONDEO TITANIUM 2.5T",
//       "MONDEO TITANIUM",
//       "MUSTANG",
//       "MUSTANG",
//       "MUSTANG",
//       "MUSTANG",
//       "MUSTANG GT",
//       "MUSTANG GT",
//       "MUSTANG GT",
//       "MUSTANG GT 2D",
//       "MUSTANG GT 5.0",
//       "MUSTANG GT 5.0 10AT",
//       "MUSTANG MACH 1",
//       "MUSTANG MACH 1 PACK",
//       "MUSTANG SHELBY COBRA",
//       "MUSTANG SHELBY GT 500",
//       "MUSTANG SHELBY GT350",
//       "NEW FIESTA 1.6",
//       "NUEVA RANGER CS 4X2 XL",
//       "NUEVA RANGER CS 4X2 XL",
//       "NUEVA RANGER CS 4X4 XL",
//       "NUEVA RANGER DC 4X2 XL",
//       "NUEVA RANGER DC 4X2 XL",
//       "NUEVA RANGER DC 4X2 XLS",
//       "NUEVA RANGER DC 4X2 XLS",
//       "NUEVA RANGER DC 4X2 XLT",
//       "NUEVA RANGER DC 4X2 XLT",
//       "NUEVA RANGER DC 4X4 LTD",
//       "NUEVA RANGER DC 4X4 LTD",
//       "NUEVA RANGER DC 4X4 XL",
//       "NUEVA RANGER DC 4X4 XLT",
//       "NUEVA RANGER DC 4X4 XLT",
//       "PUMA",
//       "PUMA 1.6",
//       "RANGER",
//       "RANGER",
//       "RANGER",
//       "RANGER BR.XLT C/C 4X2 N",
//       "RANGER 4X2 2.3L N",
//       "RANGER 4X2 XLT 2.8L D",
//       "RANGER BAH.XL C/C 4X2 N",
//       "RANGER BAH.XLT C/C 4X2 N",
//       "RANGER BAH.XLT C/C 4X2 N",
//       "RANGER BEL. XL C/C 4X2 N",
//       "RANGER BEL. XL C/C 4X2 N",
//       "RANGER BLACK EDITION CC",
//       "RANGER BR. XL C/C 4X2 D 55V",
//       "RANGER CC XL 4X2 2.5L N MT",
//       "RANGER CS 4X2 2.3L N",
//       "RANGER CS 4x2 F TRUCK 2.3L",
//       "RANGER CS 4X2 F TRUCK 2.8L",
//       "RANGER CS 4x2 F-TRUCK 3.0",
//       "RANGER CS 4X2 PLUS 2.8L D",
//       "RANGER CS 4X2 XL 2.2L D",
//       "RANGER CS 4X2 XL 2.5L N",
//       "RANGER CS 4X2 XL 2.8L D",
//       "RANGER CS 4X2 XL 3.0L D",
//       "RANGER CS 4X2 XL PLUS 2.3L",
//       "RANGER CS 4X2 XL PLUS",
//       "RANGER CS 4x2 XLS 2.3L N",
//       "RANGER CS 4X2 XLS 2.8L D",
//       "RANGER CS 4X2 XLS 3.0L D",
//       "RANGER CS 4X4 PLUS 2.8L D",
//       "RANGER CS 4X4 XL 2.2L D",
//       "RANGER CS 4X4 XL 2.8L D",
//       "RANGER CS 4X4 XL 3.0L D",
//       "RANGER CS 4X4 XL PLUS 3.0L",
//       "RANGER CUSTOM",
//       "RANGER DC 4X2 LTD 2.3L N",
//       "RANGER DC 4X2 XL 2.2L D",
//       "RANGER DC 4X2 XL 2.3L N",
//       "RANGER DC 4X2 XL 2.5L N",
//       "RANGER DC 4X2 XL 2.8 L D",
//       "RANGER DC 4x2 XL 3.0 L D",
//       "RANGER DC 4X2 XL 3.0L D",
//       "RANGER DC 4x2 XL PLUS 2.3 L",
//       "RANGER DC 4X2 XL PLUS 2.8L",
//       "RANGER DC 4X2 XL PLUS 3.0L",
//       "RANGER DC 4X2 XLS 2.3L N",
//       "RANGER DC 4x2 XLS 2.8L D",
//       "RANGER DC 4X2 XLS 3.0L D",
//       "RANGER DC 4X2 XLS 3.2L D",
//       "RANGER DC 4X2 XLS AT 3.2L D",
//       "RANGER DC 4X2 XLT 2.3L N",
//       "RANGER DC 4X2 XLT 2.5L N",
//       "RANGER DC 4X2 XLT 3.0L D",
//       "RANGER DC 4X2 XLT 3.0LD",
//       "RANGER DC 4X2 XLT AT 2.2L D",
//       "RANGER DC 4X2 XLT AT 3.2L D",
//       "RANGER DC 4X2 XLT MT 3.2L",
//       "RANGER DC 4X4 FX4 AT 3.2 L",
//       "RANGER DC 4X4 L TD 3.0L D",
//       "RANGER DC 4X4 LTD 2.8L D",
//       "RANGER DC 4X4 LTD AT 3.2L",
//       "RANGER DC 4X4 LTD MT 3.2L",
//       "RANGER DC 4X4 SUPERDUTY",
//       "RANGER DC 4X4 XL 2.2L D",
//       "RANGER DC 4X4 XL 2.8L D",
//       "RANGER DC 4X4 XL 3.0L D",
//       "RANGER DC 4X4 XL 3.0L D",
//       "RANGER DC 4X4 XL PLUS 2.8L",
//       "RANGER DC 4X4 XL PLUS 3.0L",
//       "RANGER DC 4X4 XLS 2.8L",
//       "RANGER DC 4X4 XLS 3.0L D",
//       "RANGER DC 4X4 XLS MT 3.2L",
//       "RANGER DC 4X4 XLT 2.8L D",
//       "RANGER DC 4X4 XLT 2.8L D",
//       "RANGER DC 4X4 XLT 2.8L D",
//       "RANGER DC 4X4 XLT 3.0L D",
//       "RANGER DC 4X4 XLT 3.0L D",
//       "RANGER DC 4X4 XLT AT 3.2L D",
//       "RANGER DC 4X4 XLT HARDR",
//       "RANGER DC 4X4 XLT MT 3.2L",
//       "RANGER DC 4X4 XLT PLUS",
//       "RANGER DC 4X4 XLT PLUS",
//       "RANGER HERITAGE XLT",
//       "RANGER LIMITED 4X4 2.8D",
//       "RANGER LTD C/C 4X4 2.8L D",
//       "RANGER LTD C/C 4X4 2.8L D",
//       "RANGER LTD C/C 4X4 D",
//       "RANGER LTD C/C 4X4 D",
//       "RANGER PA.XL C/C 4X2 D 551",
//       "RANGER RAPTOR 2.0L BIT 4X4",
//       "RANGER RAPTOR 3.0L V6 BIT",
//       "RANGER RC 4X2 2.3L N",
//       "RANGER S/R/C XL 4X2 N 555",
//       "RANGER S/R/C XLT 4X2 N 553",
//       "RANGER S/R/C XLT 4X2 N 555",
//       "RANGER XL",
//       "RANGER XL 2.3 4X2",
//       "RANGER XL 2.3 I RC",
//       "RANGER XL 4X2",
//       "RANGER XL 4X2 2.8L D",
//       "RANGER XL 4X2 2.8L D",
//       "RANGER XL 4X2 2.8L D F-",
//       "RANGER XL 4X2 D",
//       "RANGER XL 4X2 D",
//       "RANGER XL 4X2 D 551",
//       "RANGER XL 4X2 D 55P",
//       "RANGER XL 4X2 D 55V",
//       "RANGER XL 4X2 D F-TRUCK",
//       "RANGER XL 4X2 D F-TRUCK",
//       "RANGER XL 4X2 DSL",
//       "RANGER XL 4X2 N",
//       "RANGER XL 4X2 N 551",
//       "RANGER XL 4X2 N 552",
//       "RANGER XL 4X2 N 554",
//       "RANGER XL 4X2 N 555",
//       "RANGER XL 4X2 N 55P",
//       "RANGER XL 4X2 N 55V",
//       "RANGER XL 4X2 R/C 2.3L N",
//       "RANGER XL 4X2 R/C 2.3L N",
//       "RANGER XL 4X2 S/C 2.3L N",
//       "RANGER XL 4X2 S/C 2.3L N",
//       "RANGER XL 4X4",
//       "RANGER XL 4X4",
//       "RANGER XL 4X4 D",
//       "RANGER XL 4X4 D",
//       "RANGER XL 4X4 D 551",
//       "RANGER XL 4X4 D 552",
//       "RANGER XL 4X4 D 555",
//       "RANGER XL 4X4 D 55V",
//       "RANGER XL 4X4 DSL",
//       "RANGER XL 4X4 N",
//       "RANGER XL 4X4 N 55V",
//       "RANGER XL C/C 4X2 2.3L N",
//       "RANGER XL C/C 4X2 2.3L N",
//       "RANGER XL C/C 4X2 2.8L D",
//       "RANGER XL C/C 4X2 2.8L D",
//       "RANGER XL C/C 4X2 D",
//       "RANGER XL C/C 4X2 D",
//       "RANGER XL C/C 4X2 D 551",
//       "RANGER XL C/C 4X2 D 552",
//       "RANGER XL C/C 4X2 D 553",
//       "RANGER XL C/C 4X2 D 55V",
//       "RANGER XL C/C 4X2 N",
//       "RANGER XL C/C 4X2 N 551",
//       "RANGER XL C/C 4X2 N 553",
//       "RANGER XL C/C 4X2 N 554",
//       "RANGER XL C/C 4X2 N 55V",
//       "RANGER XL C/C 4X4 2.8L D",
//       "RANGER XL C/C 4X4 D",
//       "RANGER XL C/C 4X4 D 551",
//       "RANGER XL C/C 4X4 D 552",
//       "RANGER XL C/C 4X4 D 553",
//       "RANGER XL C/C 4X4 D 555",
//       "RANGER XL C/C 4X4 D 55V",
//       "RANGER XL C/C 4X4 N",
//       "RANGER XL C/C/ 4X2 N 552",
//       "RANGER XL C/CABINA 4X4 2.8",
//       "RANGER XL R/C 4X4 2.8L D",
//       "RANGER XL S/C 4X2 2.8L D",
//       "RANGER XL S/C 4X2 2.8L D",
//       "RANGER XL S/C 4X2 D",
//       "RANGER XL S/C 4X2 N",
//       "RANGER XL S/C 4X4 2.8L D",
//       "RANGER XL S/C 4X4 D",
//       "RANGER XL S/C 4X4 D 551",
//       "RANGER XL S/C 4X4 N",
//       "RANGER XLT",
//       "RANGER XLT",
//       "RANGER XLT 2.3 I RC",
//       "RANGER XLT 4X2",
//       "RANGER XLT 4X2 D",
//       "RANGER XLT 4X2 D 551",
//       "RANGER XLT 4X2 D 558",
//       "RANGER XLT 4X2 D 55V",
//       "RANGER XLT 4X2 DSL",
//       "RANGER XLT 4X2 N",
//       "RANGER XLT 4X2 N 552",
//       "RANGER XLT 4X2 N 557",
//       "RANGER XLT 4X2 N 55P",
//       "RANGER XLT 4X2 N 55V",
//       "RANGER XLT 4X4",
//       "RANGER XLT 4X4",
//       "RANGER XLT 4X4 (CABINA Y",
//       "RANGER XLT 4X4 D",
//       "RANGER XLT 4X4 D 556",
//       "RANGER XLT 4X4 D 558",
//       "RANGER XLT 4X4 D 559",
//       "RANGER XLT 4X4 D 55V",
//       "RANGER XLT 4X4 DSL",
//       "RANGER XLT 4X4 N",
//       "RANGER XLT 4X4 N 551",
//       "RANGER XLT 4X4 N 55V",
//       "RANGER XLT C/C 4X2 2.3L N",
//       "RANGER XLT C/C 4X2 2.8L D",
//       "RANGER XLT C/C 4X2 2.8L D",
//       "RANGER XLT C/C 4X2 D",
//       "RANGER XLT C/C 4X2 D 552",
//       "RANGER XLT C/C 4X2 D 553",
//       "RANGER XLT C/C 4X2 D 55A",
//       "RANGER XLT C/C 4X2 D 55L",
//       "RANGER XLT C/C 4X2 D 55V",
//       "RANGER XLT C/C 4X2 N",
//       "RANGER XLT C/C 4X2 N 557",
//       "RANGER XLT C/C 4X2 N 55L",
//       "RANGER XLT C/C 4X2 N 55V",
//       "RANGER XLT C/C 4X4 2.8L D",
//       "RANGER XLT C/C 4X4 2.8L D",
//       "RANGER XLT C/C 4X4 D",
//       "RANGER XLT C/C 4X4 D 551",
//       "RANGER XLT C/C 4X4 D 553",
//       "RANGER XLT C/C 4X4 D 554",
//       "RANGER XLT C/C 4X4 D 55V",
//       "RANGER XLT C/C 4X4 N",
//       "RANGER XLT C/C 4X4 N 551",
//       "RANGER XLT C/C 4X4 N 55L",
//       "RANGER XLT C/C 4X4 N 55V",
//       "RANGER XLT LTD C/C 4X4 2.8L",
//       "RANGER XLT LTD C/C 4X4 2.8L",
//       "RANGER XLT LTD C/C 4X4 2.8L",
//       "RANGER XLT S/C 4X2 D",
//       "RANGER XLT S/C 4X2 N",
//       "RANGER XLT S/C 4X2 N 552",
//       "RANGER XLT S/C 4X4 D",
//       "RANGER XLT S/C 4X4 D 551",
//       "RANGER XLT S/C 4X4 D 55V",
//       "RANGER XLT S/C 4X4 N",
//       "RANGER XLTC/C 4X4 D 55L",
//       "RANGER/XL",
//       "RANGER2 CS 4X2 XL 2.2L D",
//       "RANGER2 CS 4X2 XL 2.5L N",
//       "RANGER2 CS 4X2 XL SAFETY",
//       "RANGER2 CS 4X2 XL SAFETY",
//       "RANGER2 CS 4X2 XL SAFETY",
//       "RANGER2 CS 4X2 XL SAFETY",
//       "RANGER2 CS 4X4 XL SAFETY",
//       "RANGER2 CS 4X4 XL SAFRTY",
//       "RANGER2 DC 4X2 LTD MT",
//       "RANGER2 DC 4X2 XL 2.2L D",
//       "RANGER2 DC 4X2 XL 2.5L N",
//       "RANGER2 DC 4X2 XL SAFETY",
//       "RANGER2 DC 4X2 XL SAFETY",
//       "RANGER2 DC 4X2 XL SAFETY",
//       "RANGER2 DC 4X2 XL SAFETY",
//       "RANGER2 DC 4X2 XL SAFETY",
//       "RANGER2 DC 4X2 XLS 3.2L D",
//       "RANGER2 DC 4X2 XLS 3.2L",
//       "RANGER2 DC 4X2 XLS AT 3.2L",
//       "RANGER2 DC 4X2 XLT 2.5L N",
//       "RANGER2 DC 4X2 XLT 3.2L D",
//       "RANGER2 DC 4X2 XLT 3.2L",
//       "RANGER2 DC 4X2 XLT AT 3.2L",
//       "RANGER2 DC 4X4 LTD AT 3.2L",
//       "RANGER2 DC 4X4 LTD AT 3.2L",
//       "RANGER2 DC 4X4 LTD MT",
//       "RANGER2 DC 4X4 LTD MT",
//       "RANGER2 DC 4X4 XL 3.2L D",
//       "RANGER2 DC 4X4 XL SAFETY",
//       "RANGER2 DC 4X4 XL SAFETY",
//       "RANGER2 DC 4X4 XLS 3.2L D",
//       "RANGER2 DC 4X4 XLS 3.2L",
//       "RANGER2 DC 4X4 XLT 3.2L D",
//       "RANGER2 DC 4X4 XLT 3.2L",
//       "RANGER2 DC 4X4 XLT AT 3.2L",
//       "RANGER2 DC XLT 4X2 2.5L N",
//       "RANGER2 DC XLT AT 3.2L DSL",
//       "S- MAX TITANIUM 2.0L AT",
//       "S- MAX TREND 2.0L AT",
//       "S-MAX 1.8L TDCI TREND",
//       "S-MAX 1.8L TDCI TREND",
//       "S-MAX 2.0I TREND",
//       "S-MAX 2.0L TDCI TREND AT",
//       "S-MAX 2.0L TREND",
//       "S-MAX TITANIUM 2.3L AT",
//       "S-MAX TITANIUM 2.3L AT",
//       "TAURUS",
//       "TAURUS",
//       "TAURUS GL",
//       "TAURUS GL",
//       "TAURUS LX",
//       "TAURUS SE",
//       "TERRITORY TITANIUM 1.5L",
//       "TERRITORY TREND 1.5L GTDI",
//       "TOURNEO CONNECT TDCI",
//       "TOURNEO CONNECT TREND",
//       "TRANSIT",
//       "TRANSIT",
//       "TRANSIT",
//       "TRANSIT",
//       "TRANSIT",
//       "TRANSIT 100T 300",
//       "TRANSIT 100T260",
//       "TRANSIT 110 T 260 TDCI",
//       "TRANSIT 120-S",
//       "TRANSIT 125 T 460 C/AC",
//       "TRANSIT 125 T330",
//       "TRANSIT 125 T350",
//       "TRANSIT 190",
//       "TRANSIT 190-L",
//       "TRANSIT 2.2 TDCI",
//       "TRANSIT 2.2L 125 T330 TM",
//       "TRANSIT 2.2L 125 T330 TM",
//       "TRANSIT 2.2L 125 T350 TA",
//       "TRANSIT 2.2L 125 T350 TA",
//       "TRANSIT 2.2L 350L",
//       "TRANSIT 2.2L 350M",
//       "TRANSIT 2.4L 115 T330 TM",
//       "TRANSIT 2.4L 115 T330 TM",
//       "TRANSIT 2.4L 115 T350 TA",
//       "TRANSIT 2.4L 115 T350 TA",
//       "TRANSIT 330",
//       "TRANSIT 330S VAN 2.4L TCD",
//       "TRANSIT 350L",
//       "TRANSIT BUS 2.2L 14",
//       "TRANSIT BUS 2.2L 460E",
//       "TRANSIT BUS 2.4L 14",
//       "TRANSIT C409",
//       "TRANSIT CH 2.2L 470E",
//       "TRANSIT CH 460 C/AC",
//       "TRANSIT CH 460 S/AC",
//       "TRANSIT CONNECT T220 TDCI",
//       "TRANSIT CONNECT T220 TDCI",
//       "TRANSIT KOMBI-VSD",
//       "TRANSIT T330",
//       "TRANSIT T350",
//       "TRANSIT TOURNEO",
//       "TRANSIT TOURNEO",
//       "TRANSIT VAN LARGA TE P8 AT",
//       "TRANSIT2.2L 350L TE",
//       "TRANSIT2.2L 350M TE",
//       "ECOSPORT FREESTYLE 1.6L",
//       "ECOSPORT FREESTYLE 2.0L",
//       "ECOSPORT S 1.5L TDCI MT",
//       "ECOSPORT S 1.6L MT N",
//       "ECOSPORT SE 1.5L TDCI MT",
//       "ECOSPORT SE 1.6L MT N",
//       "ECOSPORT SE 2.0L MT N",
//       "ECOSPORT TITANIUM 1.6L MT",
//       "ECOSPORT TITANIUM 2.0L AT",
//       "ECOSPORT TITANIUM 2.0L MT",
//       "F250 XL SUPER DUTY",
//       "FOCUS 1.6 TDCI TREND",
//       "MONDEO 1.8 TD",
//       "MONDEO 1.8 TD CLX",
//       "TOURNEO CONNECT"
//     ],
//     "FOTON": [
//       "AUMARK S1 614",
//       "AUMARK S1 614",
//       "AUMARK S3 1016",
//       "AUMARK S3 815",
//       "AUMARK S3 815",
//       "AUMARK S3 916",
//       "AUMARK S3 916",
//       "GRATOUR T3",
//       "GRATOUR T3",
//       "GRATOUR V5",
//       "GRATOUR V5",
//       "TM1",
//       "TM1",
//       "TM1",
//       "TM1 CS",
//       "TUNLAND",
//       "TUNLAND 2.0",
//       "TUNLAND LUXURY",
//       "VIEW CS2"
//     ],
//     "GAC GONOW": ["GA6420CL"],
//     "GAF": ["GX 125"],
//     "GALLOPER": [
//       "DSL EXCEED",
//       "EXCEED",
//       "EXCEED GSL (LWB) 3.0 V6",
//       "EXCEED INTERCOOLER TURBO",
//       "EXCEED INTERCOOLER TURBO",
//       "EXCEED INTERCOOLER TURBO",
//       "INNOVATION",
//       "INNOVATION V6 WAGON",
//       "SANTAMO",
//       "SANTAMO",
//       "SANTAMO 4X4",
//       "SANTAMO 4X4",
//       "SANTAMO AWD",
//       "TURBO WAGON",
//       "TURBO WAGON",
//       "XL TURBO WAGON",
//       "XL V6 WAGON"
//     ],
//     "GAMMA": [
//       "CFORCE 450",
//       "CFORCE 550",
//       "EAGLE 500 EFI",
//       "MOUNTAINEER 450",
//       "MOUNTAINEER 800 LTD",
//       "UFORCE 800",
//       "VANGUARD 150",
//       "ZFORCE 550 EX",
//       "ZFORCE 625 EX",
//       "ZFORCE 800 EX"
//     ],
//     "GARELLI SAHEL": ["SAHEL 150", "SAHEL 200"],
//     "GAZ": [
//       "22171",
//       "2705-24",
//       "2705-44",
//       "2752",
//       "322132",
//       "3302",
//       "3302",
//       "3302 D",
//       "33023",
//       "33023",
//       "33027",
//       "3307",
//       "3307 (NAFTERO)",
//       "3309",
//       "3309",
//       "33097",
//       "66-40"
//     ],
//     "GEELY": [
//       "515 GL",
//       "515 GS",
//       "515 HATCH GL",
//       "515 HATCH GS",
//       "EMGRAND FE 3 GL",
//       "EMGRAND FE 3 GS",
//       "EMGRAND GS - GL",
//       "EMGRAND GS - GS",
//       "EMGRAND GS - GSP",
//       "EMGRAND GS-GC A/T",
//       "EMGRAND GS-GC M/T",
//       "EMGRAND X7 SPORT - GL AT",
//       "EMGRAND X7 SPORT - GS MT",
//       "EMGRAND X7 SPORT - GT AT",
//       "LC 1.3 GB",
//       "LC 1.3 GL",
//       "LC CROSS GL"
//     ],
//     "GEO": ["TRACKER", "TRACKER"],
//     "GHIGGERI": ["VITA 110", "VITA 110 SPORT TUNNING"],
//     "GILERA": [
//       "AC4",
//       "AG50",
//       "AX100",
//       "C 110",
//       "C100 ESTILO",
//       "C110",
//       "C110 ESTILO",
//       "C110 ESTILO",
//       "C50",
//       "C70",
//       "CD 100-3B",
//       "EG2",
//       "FR 200 HOT BEAR",
//       "FR 250",
//       "FR110 FREE RUNNER",
//       "FR-110 FREE RUNNER",
//       "FR-125 SII",
//       "FR150 FREE RUNNER",
//       "FR-150 R",
//       "FR200 FREE RUNNER",
//       "FR200 HOT BEAR",
//       "FR-200 R",
//       "FR-200R",
//       "FR-250",
//       "FR-250",
//       "FR250 FREE RUNNER",
//       "FR250 HOT BEAR",
//       "FR250 WC FREE RUNNER",
//       "FREE RUNNER 4T 125 F/D",
//       "FR-X 300",
//       "FR-X250",
//       "FRX300",
//       "FU",
//       "FUTURA",
//       "FUTURA",
//       "FUTURA",
//       "FUTURA 110",
//       "FX-125",
//       "FX-125",
//       "G1",
//       "G1",
//       "GA 50-4T",
//       "GA 50-4T PRONTO",
//       "GA 70-4T",
//       "GA 70-4T PRONTO",
//       "GA125",
//       "GA-125",
//       "GA-50",
//       "GA-50 PRONTO",
//       "GLA 110",
//       "GLA100",
//       "GLA-110",
//       "GX1",
//       "JL150",
//       "LC 150",
//       "LM-50",
//       "LM-50",
//       "LX100-3A",
//       "LX100-3B",
//       "QAX100",
//       "QAX110-4T",
//       "QAX-125",
//       "QM 100",
//       "QM100-12",
//       "QM100-7B",
//       "QM100-9",
//       "QM110-7B",
//       "QM110-9",
//       "QM125-10",
//       "QM125-11A",
//       "QM125-7B",
//       "QM125T-10A",
//       "QM125T-10A",
//       "QM125T-10D",
//       "QM125T-10D",
//       "QS90-2",
//       "SAHEL 150",
//       "SG 150",
//       "SMASH",
//       "SMASH",
//       "SMASH",
//       "SMASH R",
//       "SMASH R",
//       "SMASH T",
//       "SMX 200",
//       "SMX 250",
//       "SMX 250",
//       "SMX 400",
//       "SMX400",
//       "SPEEDY",
//       "SUPER",
//       "VC 200 R",
//       "VC 200R",
//       "VC 70",
//       "VC125",
//       "VC125",
//       "VC150",
//       "VC150",
//       "VC250",
//       "VC650",
//       "VC650",
//       "VC-R 250",
//       "VOGE 300",
//       "VOGE 300 RALLY",
//       "VOGE 300AC",
//       "VOGE 300DS",
//       "VOGE 500DS",
//       "VOGE 500R",
//       "VOGE 650DS",
//       "XKODIAC 250",
//       "XKODIAC 250 MOTARD",
//       "YL 150",
//       "YL 150",
//       "YL 200",
//       "YL 275",
//       "YL125T-3",
//       "YL200"
//     ],
//     "GMC": [
//       "ACADIA SLT AWD",
//       "JIMMY",
//       "JIMMY SLE",
//       "JIMMY SLT",
//       "KODIAK C7H042",
//       "SAVANA 1500",
//       "SIERRA CLASIC 1500",
//       "SUBURBAN",
//       "SUBURBAN",
//       "SUBURBAN 1500",
//       "SUBURBAN 1500LT",
//       "SUBURBAN 6.2L DIESEL",
//       "SUBURBAN SLT",
//       "YUKON",
//       "YUKON DENALI",
//       "YUKON GT",
//       "YUKON SLT",
//       "YUKON XL DENALI",
//       "ACADIA 3.6L"
//     ],
//     "GREAT WALL": [
//       "H6 DIGNITY",
//       "HAVAL H1 COMFORT",
//       "HAVAL H1 COMFORT",
//       "HAVAL H1 ELITE",
//       "HAVAL H1 LUXURY",
//       "HAVAL H2 COMFORT 1.5T",
//       "HAVAL H2 LUXURY 1.5T A/T",
//       "POER 4WD SUPER LUXURY",
//       "WINGLE 5 LUXURY",
//       "WINGLE 5 STANDARD",
//       "WINGLE 5 STANDARD",
//       "WINGLE 5 STANDARD 4WD",
//       "WINGLE 6 DIGNITY 4WD ESP",
//       "WINGLE 7 LUXURY 4WD",
//       "WINGLE 7 STANDARD 2WD"
//     ],
//     "GUERRERO": [
//       "ARGEN-CARGO",
//       "G 50",
//       "G100 DE LUXE",
//       "G100 TRIP",
//       "G110",
//       "G110",
//       "G110 FLASH",
//       "G110 TRIP",
//       "G110 TRIP",
//       "G110 WAY",
//       "G110DB",
//       "G110DL",
//       "G110DL",
//       "G110DL",
//       "G125 TRIP",
//       "G3R200",
//       "G50 AE",
//       "G50AE ECONO",
//       "G90 ECONO",
//       "G90 ECONO",
//       "GC 125",
//       "GC 125",
//       "GC 230 QUEEN",
//       "GC125 QUEEN",
//       "GC125QUEEN",
//       "GC150",
//       "GC150 QUEEN",
//       "GC150 URBAN",
//       "GC200",
//       "GC200 QUEEN",
//       "GE 110",
//       "GFT110 MAPUCHE",
//       "GFT150 MAPUCHE",
//       "GFT200 MAPUCHE",
//       "GFT250 MAPUCHE",
//       "GFT250 S MAPUCHE",
//       "GFT300 MAPUCHE",
//       "GFT350X",
//       "GK110",
//       "GMX 150",
//       "GMX 150",
//       "GMX250",
//       "GMX250",
//       "GN110 KEOKEN",
//       "GPR 200",
//       "GPR 250",
//       "GPR250",
//       "GR1",
//       "GR1 200",
//       "GR5 200",
//       "GR6",
//       "GRF250",
//       "GRF250X",
//       "GRF250X",
//       "GRF70",
//       "GRF90",
//       "GRM 150",
//       "GSL 150",
//       "GSL 150",
//       "GSL150 ANDIAMO",
//       "GSL150 WEAPON",
//       "GT 70",
//       "GT110",
//       "GT70",
//       "GVL400",
//       "GVL400",
//       "GXL 125",
//       "GXL 150",
//       "GXL150",
//       "GXL150 TUNDRA",
//       "GXM200",
//       "GXR 250",
//       "GXR200 TUNDRA",
//       "GXR200 TUNDRA",
//       "GXR250 TUNDRA",
//       "GXR300",
//       "GXR300",
//       "WG 100"
//     ],
//     "HAOJUE": ["NK 150"],
//     "HARLEY DAVIDSON": [
//       "1200 CUSTOM XLC",
//       "1200 LOW XL",
//       "DYNA STREET BOB",
//       "DYNA SUPERGILDE",
//       "DYNA SUPERGLIDE",
//       "DYNA WIDE GLIDE",
//       "E.CLIDE",
//       "ELECTRA GLIDE",
//       "FAT BOY",
//       "FL SS SOFTAIL SLIM S",
//       "FLFB FAT BOY",
//       "FLFBS ANX FAT BOY 114",
//       "FLH 1200",
//       "FLHP ROAD KING POLICE",
//       "FLHR ROAD KING",
//       "FLHR ROAD KING POLICE",
//       "FLHRC ROAD KING CLASSIC",
//       "FLHRCI ROAD KING CLASSIC",
//       "FLHRI ROAD KING EFI",
//       "FLHRS ROAD KING CUSTOM",
//       "FLHRSI ROAD KING CUSTOM",
//       "FLHT ELECTRA GLIDE",
//       "FLHT ELECTRA GLIDE",
//       "FLHTC ELECTRA GLIDE",
//       "FLHTCI ELECTRA GLIDE",
//       "FLHTCU ULTRA",
//       "FLHTCUI ELECTRA GLIDE",
//       "FLHTK ELECTRA GLIDE ULTRA",
//       "FLHX STREET GLIDE",
//       "FLHXS STREET GLIDE SPECIAL",
//       "FLS SOFTAIL SLIM",
//       "FLSL SLIM",
//       "FLSTC HERITAGE SOFTAIL",
//       "FLSTF FAT BOY",
//       "FLSTFB FAT BOY SPECIAL",
//       "FLSTFBS FAT BOY S",
//       "FLSTFI FAT BOY ANNIV",
//       "FLSTFI FAT BOY EFI",
//       "FLSTN HERITAGE SOFTAIL",
//       "FLSTN SOFTAIL DELUXE",
//       "FLSTNI SOFTAIL DELUXE",
//       "FLSTS HERITAGE SPRINGER",
//       "FLSTSE CVO SOFTAIL",
//       "FLTRU ROAD GLIDE ULTRA",
//       "FLTRXS ROAD GLIDE SPECIAL",
//       "FXBB STREET BOB",
//       "FXBRS BREAKOUT 114",
//       "FXCWC ROCKER C",
//       "FXD DYNA SUPER GLIDE",
//       "FXDB DYNA STREET BOB",
//       "FXDBI DYNA STREET BOB",
//       "FXDC DYNA SUPER GLIDE",
//       "FXDCI DYNA SUPER GLIDE",
//       "FXDF DYNA FAT BOB",
//       "FXDL 103 LOW RIDER",
//       "FXDL DYNA LOW RIDER",
//       "FXDR 114",
//       "FXDSCN DYNA LOW RIDER",
//       "FXDWG 103 WIDE GLIDE",
//       "FXDWG DYNA WIDE GLIDE",
//       "FXDX DYNA SUPER GLIDE",
//       "FXDXI DYNA SUPER GLIDE",
//       "FXSB BREAKOUT",
//       "FXST SOFTAIL STANDARD",
//       "FXSTB NIGHT TRAIN",
//       "FXSTB SOFTAIL NIGHT TRAIN",
//       "FXSTBI NIGHT TRAIN (EFI)",
//       "FXSTC SOFTAIL CUSTOM",
//       "FXSTD SOFTAIL DEUCE",
//       "FXSTI SOFTAIL (EFI)",
//       "FXSTS SPRINGER SOFTAIL",
//       "NIGTH TRAIN",
//       "ROAD KING",
//       "ROAD KING POLICE",
//       "SOFTAIL",
//       "SPORSTER 883 HD",
//       "SPORTER 1200 CUSTOM XLC",
//       "SPORTER 1200 LOW XL",
//       "SPORTER XL1200C",
//       "SPORTSTER",
//       "SPORTSTER 1200 CUSTOM",
//       "SPORTSTER 1200 ROADSTER",
//       "SPORTSTER 1200 XLC",
//       "SPORTSTER 883",
//       "SPORTSTER 883 CUSTOM",
//       "SPORTSTER 883 XL",
//       "SPORTSTER 883 XL CUSTOM",
//       "SPORTSTER 883 XLL",
//       "SPORTSTER 883R",
//       "SPORTSTER XL1200 CUSTOM",
//       "SPORTSTER XL1200 LOW",
//       "V-ROD",
//       "VRSCA V-ROD",
//       "VRSCAW V-ROD",
//       "VRSCB V-ROD",
//       "VRSCDX",
//       "VRSCDX NIGHT ROD SPECIAL",
//       "VRSCR STREET ROD",
//       "XG 750 STREET 750",
//       "XL 1200 C",
//       "XL 1200 CUSTOM",
//       "XL 1200 FORTY EIGHT",
//       "XL 1200C SPORTSTER",
//       "XL 1200R SPORTSTER 1200",
//       "XL 1200S",
//       "XL 1200S SPORTSTER SPORT",
//       "XL 1200X XL FORTY EIGTH",
//       "XL 50 SPORTSTER 50",
//       "XL 883 N IRON",
//       "XL 883 SPORTSTER 883",
//       "XL 883 SPORTSTER STANDARD",
//       "XL 883C CUSTOM",
//       "XL 883C SPORTSTER",
//       "XL SPORTSTER 1200",
//       "XL SPORTSTER 883",
//       "XL SPORTSTER 883L",
//       "XL SPORTSTER 883R",
//       "XL1200C 1200 CUSTOM",
//       "XL1200C SPORTSTER 1200",
//       "XL1200N NIGHTSTER",
//       "XL1200NS IRON 1200",
//       "XL1200R SPORTSTER",
//       "XL1200T SUPERLOW 1200T",
//       "XL1200V SPORTSTER",
//       "XL1200X FORTY-EIGHT",
//       "XL883C SPORTSTER 883",
//       "XL883L SPORTSTER 883",
//       "XL883L SUPERLOW",
//       "XL883N IRON 883",
//       "XL883N SPORTSTER",
//       "XL883R SPORTSTER R",
//       "XLH SPORTSTER 1200",
//       "XLH SPORTSTER 883",
//       "XLH SPORTSTER 883 HUGGER",
//       "FLSTF SOFTAIL FATBOY",
//       "SOFTAIL DE LUXE",
//       "SOFTAIL DELUXE"
//     ],
//     "HAVAL": [
//       "H1 COMFORT",
//       "H1 ELITE",
//       "H1 LUXURY",
//       "H2 COMFORT 1.5T",
//       "H2 LUXURY 1.5T A/T",
//       "H6 2.0",
//       "H6 2WD",
//       "H6 4WD",
//       "H6 DIGNITY",
//       "H6 DIGNITY",
//       "JOLION 1.5T 7DCT SUPREME"
//     ],
//     "HEI BAO": ["HB1605", "HB1605-6"],
//     "HERO": [
//       "DASH",
//       "DASH",
//       "DASH",
//       "HERO 150",
//       "HUNK",
//       "HUNK",
//       "HUNK 150",
//       "HUNK 160R",
//       "HUNK 200",
//       "HUNK SPORTS",
//       "HUNK SPORTS",
//       "IGNITOR",
//       "IGNITOR",
//       "IGNITOR I3S",
//       "XPULSE 200",
//       "XPULSE 200T",
//       "HUNK 190R",
//       "IGNITOR"
//     ],
//     "HINO": [
//       "300 / 514",
//       "300 / 716",
//       "300 / 816",
//       "300 / 816",
//       "300 816",
//       "300/ 616",
//       "500 FM280",
//       "500 GH280",
//       "500 SG 350",
//       "XZU720L HKFRP3 - 300 816"
//     ],
//     "HONDA": [
//       "301CBR600F3",
//       "ACCORD",
//       "ACCORD",
//       "ACCORD",
//       "ACCORD",
//       "ACCORD",
//       "ACCORD EX",
//       "ACCORD 2.0 AT",
//       "ACCORD 2.0 EXI",
//       "ACCORD 2.0 I",
//       "ACCORD 2.0 ILS",
//       "ACCORD 2.0I ES",
//       "ACCORD 2.0I ES VTEC",
//       "ACCORD 25TH ANNIVERSARY",
//       "ACCORD D160 G4C",
//       "ACCORD DX",
//       "ACCORD DX",
//       "ACCORD EX",
//       "ACCORD EX",
//       "ACCORD EX",
//       "ACCORD EX V6",
//       "ACCORD EX VTEC V6",
//       "ACCORD EX/DX/LX/LXI",
//       "ACCORD EXI",
//       "ACCORD EXL",
//       "ACCORD EXL",
//       "ACCORD EXL",
//       "ACCORD EXL AUTOMATICO",
//       "ACCORD EXL AUTOMATICO",
//       "ACCORD EXL/EXVL",
//       "ACCORD EX-R",
//       "ACCORD EX-R",
//       "ACCORD EX-R",
//       "ACCORD EXR AUTOMATICO",
//       "ACCORD EX-R V6",
//       "ACCORD EX-R V6",
//       "ACCORD EX-RL",
//       "ACCORD EX-RL",
//       "ACCORD EXT 2.0T",
//       "ACCORD LX",
//       "ACCORD LX",
//       "ACCORD LX",
//       "ACCORD LX",
//       "ACCORD LX AUTOMATICO",
//       "ACCORD LXI",
//       "ACCORD LXI",
//       "ACCORD LXI",
//       "ACCORD SE",
//       "ACCORD SEI",
//       "ACCORD TYPE R VTEC",
//       "ACCORD V6",
//       "ACCORD V6",
//       "ACCORD V6",
//       "AFRICA TWIN",
//       "BIZ 125",
//       "BIZ 125 ES",
//       "BIZ 125 FI",
//       "BIZ 125 GP",
//       "BIZ 125 KS",
//       "BIZ 125 KS",
//       "BIZ 125 KSST",
//       "C100 BIZ",
//       "C100 BIZ ES",
//       "C100P",
//       "C105 BIZ",
//       "C105 BIZ",
//       "C105 BIZ ES",
//       "C105 BIZ KS",
//       "C70 POINT",
//       "CB 125",
//       "CB 125T",
//       "CB 190 R",
//       "CB 190 R REPSOL",
//       "CB 250 TWISTER",
//       "CB 300 R",
//       "CB TWISTER",
//       "CB1",
//       "CB1 TUF",
//       "CB1000 R",
//       "CB125F TWISTER",
//       "CB125F TWISTER",
//       "CB500F",
//       "CB500X",
//       "CB600F",
//       "CB919",
//       "CBR 1000",
//       "CBR 1000 FM",
//       "CBR 1000 FN",
//       "CBR 1000F",
//       "CBR 1000RR",
//       "CBR 600",
//       "CBR 600 F",
//       "CBR 600 F2 LM",
//       "CBR 600 F2L",
//       "CBR 600 F3T",
//       "CBR 600 F4X",
//       "CBR 600 FN",
//       "CBR 600 FR",
//       "CBR 600 FS",
//       "CBR 600F HURRICANE",
//       "CBR 600F3L",
//       "CBR 900 R",
//       "CBR 900 RRS",
//       "CBR1100",
//       "CBR1100X",
//       "CBR1100XV",
//       "CBR1100XX",
//       "CBR300RA",
//       "CBR600 F3T",
//       "CBR600 FP",
//       "CBR600 RR",
//       "CBR600 SE",
//       "CBR600F2",
//       "CBR600F2N",
//       "CBR600F3",
//       "CBR600F4",
//       "CBR600FM",
//       "CBR600FV",
//       "CBR600FV-ED",
//       "CBR600RR RACING",
//       "CBR600RR3",
//       "CBR900",
//       "CBR900 RRP",
//       "CBR900 RS",
//       "CBR900RE",
//       "CBR900RR",
//       "CBR900RRL",
//       "CBR900RRN",
//       "CBR900RRV",
//       "CBR900RRW-ED",
//       "CBR929RR",
//       "CBR954RR",
//       "CBX250",
//       "CBX250",
//       "CG 125 CARGO",
//       "CG 150 ESD TITAN",
//       "CG 150 TITAN",
//       "CG125",
//       "CG125 ES",
//       "CG125 FAN",
//       "CG125 KS",
//       "CG125 TITAN",
//       "CG125 TITAN ES",
//       "CG125 TITAN KS",
//       "CG-125 TODAY",
//       "CG150 TITAN",
//       "CG150 TITAN ESD",
//       "CG150 TITAN KS",
//       "CG150ESD",
//       "CG150KS",
//       "CGL125",
//       "CH 125 ELITE",
//       "CITY EX M/T",
//       "CITY EXL A/T",
//       "CITY EXL M/T",
//       "CITY LX M/T",
//       "CIVIC",
//       "CIVIC",
//       "CIVIC",
//       "CIVIC 1.6 ESI",
//       "CIVIC 1.6 EX AUTOMATICO",
//       "CIVIC 1.6 EX AUTOMATICO",
//       "CIVIC DX",
//       "CIVIC DX",
//       "CIVIC EX",
//       "CIVIC EX",
//       "CIVIC EX",
//       "CIVIC EX",
//       "CIVIC EX 1.6",
//       "CIVIC EX COUPE",
//       "CIVIC EXI",
//       "CIVIC EX-L",
//       "CIVIC EXS",
//       "CIVIC EXS A/T",
//       "CIVIC EX-T",
//       "CIVIC HX",
//       "CIVIC LSI",
//       "CIVIC LSI",
//       "CIVIC LX",
//       "CIVIC LX",
//       "CIVIC LX 1.5 AUTOMATICO",
//       "CIVIC LX AUTOMATICO",
//       "CIVIC LX SPECIAL",
//       "CIVIC LXMT",
//       "CIVIC LXS",
//       "CIVIC SI",
//       "CIVIC SI",
//       "CIVIC SI",
//       "CIVIC VTI",
//       "CIVIC VTI",
//       "CIVIC VTI",
//       "CIVIC VX",
//       "CIVIC WAGON",
//       "CIVIC WAGON",
//       "CR 125",
//       "CR 125 RS",
//       "CR 125 RT",
//       "CR 250",
//       "CR 250 R",
//       "CR 250 RR",
//       "CR 250 RS",
//       "CR 250 RT",
//       "CR125 RN",
//       "CR125 RP",
//       "CR-125 RS",
//       "CR125R",
//       "CR125RM",
//       "CR125RT",
//       "CR-250 RS",
//       "CR250R",
//       "CR250RP",
//       "CR250RT",
//       "CR250RV",
//       "CR500",
//       "CR500R",
//       "CR500RM",
//       "CR80R",
//       "CR80RB",
//       "CR80RP",
//       "CR80RR",
//       "CR80RS",
//       "CR80RS",
//       "CR80RT",
//       "CR80S",
//       "CR85R",
//       "CRF 1000 L",
//       "CRF 1100A2L AFRICA TWIN",
//       "CRF 1100AL AFRICA TWIN",
//       "CRF 1100D2L AFRICA TWIN",
//       "CRF 1100DL AFRICA TWIN",
//       "CRF 150F",
//       "CRF 230F",
//       "CRF 250R",
//       "CRF 250X",
//       "CRF 450R",
//       "CRF1000L",
//       "CRF1000L AFRICA TWIN",
//       "CRF1000L MT",
//       "CRF100F",
//       "CRF150R",
//       "CRF250L",
//       "CRF450X",
//       "CRF50F",
//       "CRF50FE",
//       "CRF50FG",
//       "CRF70F",
//       "CRF80F",
//       "CRV",
//       "CRV",
//       "CRV 4WD",
//       "CR-V 4WD",
//       "CR-V EX",
//       "CR-V EX 2WD",
//       "CR-V EXL",
//       "CR-V EXT AWD",
//       "CRV I",
//       "CRV LX",
//       "CRV LX",
//       "CR-V LX 2WD",
//       "CRV SI",
//       "CRV-EX",
//       "CRV-EX",
//       "CRVI",
//       "ELITE",
//       "FALCON 400",
//       "FIT",
//       "FIT COOL L4 MT",
//       "FIT EX",
//       "FIT EX CVT",
//       "FIT EXL",
//       "FIT EXL 1.5 A/T",
//       "FIT EXL CVT",
//       "FIT LX",
//       "FIT LX A/T",
//       "FIT LX-L",
//       "FOURTRAX 300 4X4",
//       "FOURTRAX 400EX",
//       "GLH 150",
//       "HR-V",
//       "HR-V",
//       "HR-V 1.6I 4WD",
//       "HR-V EX",
//       "HR-V EX CVT",
//       "HR-V EXL",
//       "HR-V EXL CVT",
//       "HR-V LX",
//       "HR-V LX CVT",
//       "HR-V LX MT",
//       "HR-V VTEC",
//       "HURRICANE CBR1000",
//       "INVICTA",
//       "LEAD",
//       "LEAD",
//       "LEAD 50",
//       "LEAD 50",
//       "LEAD 50",
//       "LEAD 50R",
//       "LEAD 50S",
//       "LEAD ER50",
//       "LEAD ER50",
//       "LEAD SS 50",
//       "LEGEND",
//       "LEGEND",
//       "LEGEND",
//       "LEGEND 2P",
//       "LEGEND 4P",
//       "NC700X",
//       "NC700X",
//       "NC750X",
//       "NC750XA",
//       "NC750XD",
//       "NEW ELITE",
//       "NEW ELITE",
//       "NF 100 WAVE",
//       "NF100 WAVE",
//       "NF100 WAVE CAST DRUM",
//       "NF100 WAVE CD",
//       "NF100 WAVE CDB",
//       "NF100 WAVE SD",
//       "NF100 WAVE SPOKE DRUM",
//       "NF100 WAVE SPOKE DRUM",
//       "NT700VA",
//       "NX4",
//       "NX4 FALCON",
//       "NX4 FALCON",
//       "NX400",
//       "NXR125",
//       "ODYSSEY",
//       "ODYSSEY",
//       "ODYSSEY EX",
//       "ODYSSEY EX",
//       "ODYSSEY EXL",
//       "ODYSSEY LX",
//       "ODYSSEY LX",
//       "ODYSSEY OTROS",
//       "PCX 150",
//       "PCX 150",
//       "PCX 150 DLX",
//       "PILOT",
//       "PILOT",
//       "PILOT EX 2WD",
//       "PILOT EX SPORT",
//       "PILOT TOURING",
//       "PILOT TOURING AWD",
//       "POP100",
//       "PRELUDE 2.2",
//       "PRELUDE 2.2 VTI",
//       "PRELUDE 2.2 VTI",
//       "RINCON 650 TRX650FA",
//       "SDH 125 46 STORM",
//       "SDH125 46 STORM CDB",
//       "SDH125-46 STORM",
//       "SDH125T 22",
//       "SHADOW",
//       "SHADOW 750",
//       "SHADOW SPIRIT",
//       "STORM",
//       "STREAM",
//       "TRANSA LP XL600VK",
//       "TRANSALP",
//       "TRANSALP 600V",
//       "TRX 420 TM",
//       "TRX 650 FGA",
//       "TRX250D",
//       "TRX250ES",
//       "TRX250EX",
//       "TRX250EX",
//       "TRX250TE",
//       "TRX250TM",
//       "TRX250-X",
//       "TRX350",
//       "TRX350",
//       "TRX350 FE",
//       "TRX350 FE",
//       "TRX350 FM",
//       "TRX350 FM",
//       "TRX350 TE",
//       "TRX350 TE",
//       "TRX350 TM",
//       "TRX350 TM",
//       "TRX350FE",
//       "TRX350TE",
//       "TRX350TM",
//       "TRX400EX",
//       "TRX400EX",
//       "TRX400FGA",
//       "TRX400X",
//       "TRX420FA",
//       "TRX420FA6",
//       "TRX420FE",
//       "TRX420FM",
//       "TRX420FM1",
//       "TRX420FPA",
//       "TRX420FPE",
//       "TRX420FPM",
//       "TRX420PFA",
//       "TRX420TE",
//       "TRX420TE RANCHER",
//       "TRX420TE7",
//       "TRX420TM1",
//       "TRX450",
//       "TRX450",
//       "TRX450ER",
//       "TRX450FE",
//       "TRX450FM",
//       "TRX450R",
//       "TRX500",
//       "TRX500 FM",
//       "TRX500FA",
//       "TRX500FA",
//       "TRX500FE",
//       "TRX500FGA",
//       "TRX500FPA",
//       "TRX500FPE",
//       "TRX500FPM",
//       "TRX500FW",
//       "TRX500TM6",
//       "TRX520FM1",
//       "TRX650FA",
//       "TRX680FA",
//       "TRX680FA RINCON",
//       "TRX680FG RINCON",
//       "TRX680FGA",
//       "V MEN SDH 125 42",
//       "VARADERO",
//       "VFR800X",
//       "VLX STEED 400",
//       "VT 750C",
//       "VT1100 SHADOW SPIRIT",
//       "VT1100C SHADOW SPIRIT",
//       "VT750",
//       "VT750 CA SHADOW",
//       "VT750 SHADOW",
//       "VT750 SHADOW SPIRIT",
//       "VT750C2",
//       "VT750C2W-ED",
//       "VT750CD2",
//       "VT750CDA SHADOW ACE",
//       "VT750CDB SHADOW ACE",
//       "VT750CDC SHADOW ACE",
//       "VT750DC SHADOW SPIRIT",
//       "VT750DCB SHADOW",
//       "VTX1300",
//       "WAVE",
//       "WAVE 110",
//       "WAVE 110 S",
//       "WAVE 110 S CD",
//       "WAVE 110S",
//       "WAVE 110S CD",
//       "WR-V EX CVT",
//       "WR-V EXL CVT",
//       "XL1000V",
//       "XL1000V VARADERO",
//       "XL200",
//       "XL200R",
//       "XL650V",
//       "XL650V TRANSALP",
//       "XL700V",
//       "XL700VA",
//       "XLR 125",
//       "XR 125L",
//       "XR 150 L",
//       "XR 190 L",
//       "XR 190L",
//       "XR 250",
//       "XR 250 BAJA",
//       "XR 250 LS",
//       "XR 250 RL",
//       "XR 250 RM",
//       "XR 250 RS-DK",
//       "XR 250 RT",
//       "XR 250 RT-DK",
//       "XR 250 TORNADO",
//       "XR RALLY",
//       "XR125L",
//       "XR250",
//       "XR250",
//       "XR250 LLP",
//       "XR250 LN",
//       "XR250 LP",
//       "XR250 RN",
//       "XR250 RP",
//       "XR250 RR-DK",
//       "XR250 RS",
//       "XR250 TORNADO",
//       "XR250L",
//       "XR250LR",
//       "XR250R",
//       "XR250RR",
//       "XR250RT",
//       "XR250RV",
//       "XR650L",
//       "XRE 300 RALLY",
//       "XRE300",
//       "XRE300",
//       "XRE300 RALLY",
//       "XRV750",
//       "XRV750 AFRICA TWIN",
//       "XRV750V",
//       "XRV750V-ED",
//       "PILOT EXL",
//       "TRX680FAC"
//     ],
//     "HUMMER": ["H2"],
//     "HUSQVARNA": [
//       "701 ENDURO",
//       "701 SUPERMOTO",
//       "FC 350",
//       "FC 450",
//       "FC250",
//       "FE 250",
//       "FE 350",
//       "FE 501",
//       "FX 350",
//       "HQV TE 300I",
//       "NORDEN 901",
//       "SVARTPILEN 200",
//       "SVARTPILEN 401",
//       "SVARTPILEN 401",
//       "SVARTPILEN 701",
//       "TC 125",
//       "TC 85",
//       "TE 150",
//       "TE 250I",
//       "TE 300",
//       "TE 300I",
//       "TE 300I",
//       "TE250",
//       "TX 125",
//       "TX 300",
//       "TXC250R",
//       "VITPILEN 401",
//       "VITPILEN 401",
//       "VITPILEN 701",
//       "VITPLEN 701"
//     ],
//     "HYUNDAI": [
//       "ACCENT",
//       "ACCENT",
//       "ACCENT",
//       "ACCENT 1.5 CS",
//       "ACCENT 1.5 CS",
//       "ACCENT 1.5 DOHC",
//       "ACCENT EURO CS",
//       "ACCENT GLS",
//       "ACCENT GLS",
//       "ACCENT GLS",
//       "ACCENT GLS FULL",
//       "ACCENT GLS FULL",
//       "ACCENT GLS/GS",
//       "ACCENT GS",
//       "ACCENT GT",
//       "ACCENT LS",
//       "ACCENT OTROS",
//       "ACCENT PRIME",
//       "ACCENT PRIME GLS",
//       "AERO EXPRESS",
//       "ATOS",
//       "ATOS GL",
//       "ATOS GLS",
//       "ATOS PRIME GL",
//       "ATOS PRIME GLS",
//       "ATOZ",
//       "ATOZ VENTURE",
//       "AVANTE",
//       "AVANTE",
//       "AVANTE 1.5",
//       "AVANTE 1.5 GLS",
//       "AVANTE TOURING 1.5 ELS",
//       "AVANTE TOURING 1.5 ELS DLX",
//       "AVANTE TOURING 1.8 ELS DLX",
//       "CHORUS",
//       "COUNTY",
//       "COUPE FX V6",
//       "CRETA",
//       "ELANTRA",
//       "ELANTRA",
//       "ELANTRA",
//       "ELANTRA 1.6 GLS",
//       "ELANTRA 1.6 GLS",
//       "ELANTRA 1.6 GLS/1.8 GLS/GLS",
//       "ELANTRA 1.8",
//       "ELANTRA 1.8 GL",
//       "ELANTRA 1.8 GL",
//       "ELANTRA 1.8 GLS",
//       "ELANTRA 1.8 GLS",
//       "ELANTRA 2.0 GLS",
//       "ELANTRA 2.0 GLS",
//       "ELANTRA GL",
//       "ELANTRA GLD",
//       "ELANTRA GLD",
//       "ELANTRA GLS",
//       "ELANTRA GLS",
//       "ELANTRA GLS",
//       "ELANTRA GLS AUTOMATICO",
//       "EURO ACCENT",
//       "FX",
//       "GALLOPER 2.5 DSL EXCEED",
//       "GALLOPER 2.5 EXCEED TDI",
//       "GALLOPER EXCEED",
//       "GALLOPER EXCEED",
//       "GALLOPER EXCEED TCI LWB",
//       "GALLOPER EXCEED TDI",
//       "GALLOPER GL",
//       "GALLOPER II",
//       "GALLOPER II 2.5 TC LWB",
//       "GALLOPER II 2.5TCI LWB",
//       "GALLOPER II EXCEED 2.5 TDI",
//       "GALLOPER II EXCEED 3.0 V6",
//       "GALLOPER II EXCEED 4X4",
//       "GALLOPER II S EXCEED",
//       "GALLOPER II TDI",
//       "GALLOPER INNOVATION",
//       "GALLOPER SANTAMO",
//       "GALLOPER TD",
//       "GALLOPER TDI",
//       "GALLOPER XL",
//       "GENESIS COUPE 2.0",
//       "GENESIS COUPE 2.0 T",
//       "GENESIS COUPE 3.8",
//       "GETZ 1.1",
//       "GETZ 1.1",
//       "GETZ GL",
//       "GETZ GLS",
//       "GETZ TB",
//       "GRACE",
//       "GRACE",
//       "GRACE GRAND SALOON",
//       "GRACE H 100",
//       "GRACE LH",
//       "GRACE SUPER",
//       "GRACE TOUR GRAN SALOON",
//       "GRAND I 10 1.2",
//       "GRAND I 10 1.2",
//       "GRAND I10 1.0",
//       "GRAND SANTA FE 2.2 CRDI",
//       "GRAND SANTA FE 4WD 3.3L",
//       "H 100",
//       "H 100",
//       "H 100",
//       "H 100",
//       "H 100",
//       "H 100",
//       "H 100",
//       "H 100 (AMBULANCIA)",
//       "H 100 GRAND SALOON",
//       "H 100 GRAND SALOON",
//       "H 100 MIGHTY",
//       "H 100 MINIBUS",
//       "H 100 PORTER",
//       "H 100 SUPER",
//       "H 100 SUPER",
//       "H 100 SUPER",
//       "H 100 SUPER",
//       "H 250",
//       "H1",
//       "H1",
//       "H1",
//       "H-1",
//       "H-1",
//       "H1 2.5",
//       "H-1 2.5 DIESEL TCI",
//       "H1 HSV CRDI",
//       "H1 SV",
//       "H-1 SVX",
//       "H-1 SVX",
//       "H100 NEW GRACE GRAND",
//       "H350",
//       "H350",
//       "H350",
//       "HD 65 (CABINA DOBLE)",
//       "HD 65 (CABINA DOBLE)",
//       "HD35 L",
//       "HD35 L",
//       "HD65",
//       "HD65",
//       "HD65 DLX",
//       "HD72",
//       "HD72",
//       "HD72 DLX",
//       "HD72 DLX LARGO",
//       "HD78",
//       "HD78",
//       "I 10 1.1 GLS",
//       "I 10 1.2",
//       "I 10 1248 CC MPFI DOHC AT",
//       "I 10 1248 CC MPFI DOHC MT",
//       "I 10 AUTOMATICO",
//       "I 30",
//       "I 30 1.4",
//       "I 30 1.4 MANUAL",
//       "I 30 1.6",
//       "I 30 1.6 AUTOMATICO",
//       "I 30 1.6 GLS",
//       "I 30 1.6 MANUAL",
//       "I 30 2.0",
//       "I10 1.0",
//       "I10 GLS 1248 CC MANUAL",
//       "I30 1.6 AUTOMATICO",
//       "I30 1.6 GDI",
//       "I30 1.8",
//       "I30 CW 2.0",
//       "IONIQ HYBRID",
//       "KONA",
//       "KONA 1.6T",
//       "KONA 1.6T",
//       "LANTRA GLS 1.6",
//       "MATRIX 1.5 CRDI",
//       "MATRIX 1.6 GL",
//       "MATRIX 1.6 GLS",
//       "MIGHTY",
//       "MIGHTY",
//       "MIGHTY",
//       "NEW LANTRA GLS",
//       "NEW TUCSON GL 2.0",
//       "NEW TUCSON GL 2.0",
//       "PORTER DLX",
//       "PRO ACCENT 1.5TGR",
//       "SANTA FE",
//       "SANTA FE",
//       "SANTA FE 2.2 4WD CRDI",
//       "SANTA FE 2.2 CRDI",
//       "SANTA FE 2.2 CRDI",
//       "SANTA FE 2.4",
//       "SANTA FE 2.4",
//       "SANTA FE 2.4 GLS",
//       "SANTA FE 2.4 GLS",
//       "SANTA FE 2.7 V6",
//       "SANTA FE 2WD",
//       "SANTA FE 2WD GL",
//       "SANTA FE 2WD GLS",
//       "SANTA FE 2WD GLS",
//       "SANTA FE 3.5",
//       "SANTA FE 4WD",
//       "SANTA FE 4WD CRDI",
//       "SANTA FE 4WD V6",
//       "SANTA FE GL 4WD",
//       "SANTA FE GLS 4WD",
//       "SANTA FE SPORT",
//       "SANTA FE TDI 4WD",
//       "SANTAMO",
//       "SANTAMO",
//       "SANTAMO DOHC DLX (203D)",
//       "SANTAMO PLUS 2WD",
//       "SONATA",
//       "SONATA",
//       "SONATA 16 V GLS",
//       "SONATA 2.0 GLSI",
//       "SONATA CRDI",
//       "SONATA GLS",
//       "SONATA GLS V6",
//       "SONATA III",
//       "SONATA OTROS",
//       "SONATA V6",
//       "STAREX",
//       "STAREX",
//       "STAREX CLUB 4WD",
//       "STAREX H1",
//       "STAREX H1 HSV",
//       "STAREX H1 SVX",
//       "STAREX JUMBO SV",
//       "STAREX JUMBO SVX",
//       "STAREX RV CLUB 4WD",
//       "STAREX RV SV",
//       "STAREX RV SVX",
//       "STAREX RV SVX",
//       "STAREX SV",
//       "STAREX SVX",
//       "STAREX SVX",
//       "STAREX SVX 4WD",
//       "STARIA 2.2D 4WD",
//       "TERRACAN 4WD CRDI",
//       "TERRACAN EX290 CRDI",
//       "TIBURON",
//       "TIBURON SPECIAL",
//       "TIBURON SRX",
//       "TIBURON SRX",
//       "TIBURON TGX",
//       "TIBURON TGX",
//       "TIBURON TGX 2.0",
//       "TIBURON TURBULANCE 2.0",
//       "TRAJET 2.0 CRDI",
//       "TRAJET 2.0 CRDI",
//       "TRAJET GL",
//       "TRAJET GLS",
//       "TRAJET TD",
//       "TUCSON",
//       "TUCSON 2.0 4WD",
//       "TUCSON 2.0 4WD CRDI",
//       "TUCSON 2.0 4WD CRDI",
//       "TUCSON 2.0 AUTOMATICO",
//       "TUCSON 2.0 DOCH",
//       "TUCSON 2.0 DOHC",
//       "TUCSON 2.0 DOHC",
//       "TUCSON 2.0 DOHC CAJA",
//       "TUCSON 2.0 DOHC GLS",
//       "TUCSON 2.0 GL DOHC",
//       "TUCSON 2.0 GLS 2WD",
//       "TUCSON 2.0 MANUAL",
//       "TUCSON 2WD 2.0",
//       "TUCSON 2WD 2.0",
//       "TUCSON 2WD 2.0 CRDI",
//       "TUCSON 2WD 2.0 CRDI",
//       "TUCSON 4WD 2.0 DOHC",
//       "TUCSON 4WD CRDI",
//       "TUCSON 4WD V6",
//       "TUCSON ECO SPORT UTILITY",
//       "TUCSON GL CRDI",
//       "TUCSON TURBO",
//       "VELOSTER",
//       "VELOSTER 1.6T",
//       "VELOSTER 2.0",
//       "VERACRUZ 3.8 V6",
//       "VERACRUZ CRDI",
//       "VERNA 1.3",
//       "VERNA 1.3 SL",
//       "VERNA 1.5",
//       "VERNA 1.5 SV",
//       "VERNA SENSE 1.3L",
//       "I 10 1.2 AUTOMATICO",
//       "TUCSON 2.0 GL DOHC",
//       "TUCSON 2.0 MX",
//       "TUCSON V6"
//     ],
//     "IMSA": [
//       "EVO X",
//       "IM 110 TRACK EVO",
//       "IM 110T TRACK",
//       "IM 125 ROAD X",
//       "IM 125 ROADX",
//       "IM 125 T",
//       "IM 125R.4 ROAD",
//       "IM 150 ROAD X",
//       "IM110 E EVO",
//       "IM110E",
//       "IM110T TRACK",
//       "IM125 R.4",
//       "IM125R.4 ROAD",
//       "IM125T",
//       "IM125T TROPHY",
//       "IM150 ROADX",
//       "ROAD 100"
//     ],
//     "INDIAN": [
//       "CHIEF DARK HORSE",
//       "CHIEF VINTAGE",
//       "SCOUT",
//       "SCOUT BOBBER",
//       "SCOUT BOBBER SIXTY",
//       "SCOUT SIXTY"
//     ],
//     "INFINITI": ["FX 45"],
//     "INNOCENTI": ["ELBA 1.7 D"],
//     "INTERNACIONAL": ["4700", "4700 4X2", "9670", "9670"],
//     "INTERNATIONAL": [
//       "2500 S",
//       "2554 6X4",
//       "2654 6X4",
//       "2654 6X4",
//       "2654 8X4",
//       "2674 6X4",
//       "2674 6X4",
//       "2674 6X4",
//       "4300",
//       "4300 SBA 4X2",
//       "4600",
//       "4700 4X2",
//       "4700 4X2",
//       "4700 4X2",
//       "4800 4X4",
//       "4800 DT466E",
//       "4900",
//       "4900",
//       "4900 250 HP",
//       "4900 4X2",
//       "4900 4X2",
//       "4900 6X4",
//       "4900 6X4",
//       "4900 DT 466",
//       "4900 DT 466",
//       "4900 DT466E",
//       "4954",
//       "8100 6X4",
//       "8100 6X4",
//       "8100 6X4",
//       "8200 4X2",
//       "8200 4X2",
//       "8300 6X4",
//       "9200",
//       "9200",
//       "9200",
//       "9200 I",
//       "9300",
//       "9300",
//       "9300",
//       "9600",
//       "9670",
//       "9670",
//       "9700",
//       "9700",
//       "9700",
//       "9700 6X4",
//       "9800 4X2",
//       "9800 6X4",
//       "9800 6X4",
//       "9800 6X4",
//       "9900",
//       "CO9600",
//       "COF 9600 6X4",
//       "COF 9670",
//       "COF 9670",
//       "MSC 1510",
//       "S1800",
//       "S1900",
//       "S1900",
//       "S2500",
//       "TRANSTAR II COF 5470",
//       "TRANSTAR II COF 5470"
//     ],
//     "ISUZU": [
//       "2.5 DIESEL CABINA DOBLE",
//       "2.5 DIESEL CABINA SIMPLE",
//       "2.5 TURBO DIESEL CAB DOBLE",
//       "2.5 TURBO DIESEL CAB",
//       "2.8 TD 4X2 CABINA DOBLE",
//       "2.8 TD 4X4 CABINA DOBLE",
//       "4X2 (CABINA SIMPLE)",
//       "AMIGO",
//       "AMIGO",
//       "AMIGO XS",
//       "AXIOM XS",
//       "B241",
//       "CHR660",
//       "DMAX LS",
//       "D-MAX RBD 4X4 AT",
//       "D-MAX RBD 4X4 MT",
//       "DOBLE CABINA 4X2",
//       "DOBLE CABINA 4X4 TD",
//       "DOBLE CABINA 4X4 TFS16H-",
//       "DOBLE CABINA TURBO DIESEL",
//       "NHR 2.8 DIESEL DIRECT",
//       "NKR 3.6 DIESEL DIRECT",
//       "NPR",
//       "NPR",
//       "NPR",
//       "NPR 4.3 DIESEL DIRECT",
//       "NPR 66",
//       "NPR 66P",
//       "NPR 70 4.8TD INTERCOOLER",
//       "NPR65LL3.9D INT",
//       "NPR65LL3.9D INT",
//       "NPR65PL3.9D INT",
//       "NPR65PL3.9D INT",
//       "NPR70 LL 4.8D INTERCOOLER",
//       "NPR70 PL 4.8D INTERCOOLER",
//       "NPR75 K",
//       "NPR75 L",
//       "NPR75 L",
//       "NQR90 L",
//       "RODEO",
//       "RODEO",
//       "RODEO",
//       "RODEO LS",
//       "RODEO LS",
//       "RODEO LS V6",
//       "RODEO S",
//       "RODEO SPORT",
//       "RODEO SX",
//       "RODEO SX",
//       "RODEO V6 24 VALVE 4WD",
//       "RODEO XS 4WD V6",
//       "SPACE",
//       "SPACE CAB 4X4 DIESEL",
//       "SPACE CAB LS",
//       "TFR16H-00",
//       "TFR54H DIESEL (DOBLE",
//       "TFR54H DIESEL (DOBLE",
//       "TFR54H LPD D (CABINA Y",
//       "TFR54H-00",
//       "TFR54H-00 BASE (RBA)",
//       "TFR54H-00 C/A (RBD)",
//       "TFR54H-00 RBB",
//       "TFR54H-10",
//       "TFR54H-20",
//       "TFR54H-20 (RBA)",
//       "TFR54H-20 (RBD)",
//       "TFR54H-20 RBB",
//       "TFR54H-20/00",
//       "TFR54HDAL",
//       "TFR54HPAL",
//       "TFR54HSAL",
//       "TFR55H-00 TURBO",
//       "TFR55H-10 TURBO (CABINA Y",
//       "TFR55H-20 TURBO (CABINA",
//       "TFR55HDAL",
//       "TFR55HPAL",
//       "TFR55HSAL",
//       "TFR69HDEI",
//       "TFR69HDI",
//       "TFR69HPEI",
//       "TFR69HSEI",
//       "TFR69HSI",
//       "TFS 54/TFR54",
//       "TFS54F-00",
//       "TFS54H-10",
//       "TFS54H-20",
//       "TFS54H-20 (RBA)",
//       "TFS54H-20 (RBD)",
//       "TFS55H-00 TURBO",
//       "TFS55H-10",
//       "TFS55H10 TURBO",
//       "TFS55H-20",
//       "TFS55H20 TURBO",
//       "TFS55HDAL",
//       "TFS55HSAL",
//       "TFS69-HD",
//       "TFS69HDE (CABINA DOBLE)",
//       "TFS69HDEI",
//       "TFS69HPEI",
//       "TFS69HSEI",
//       "TROOPER",
//       "TROOPER",
//       "TROOPER (3 PUERTAS)",
//       "TROOPER (5 PUERTAS)",
//       "TROOPER 3.0D INTERCOOLER",
//       "TROOPER 3.0D INTERCOOLER",
//       "TROOPER 3.1 D",
//       "TROOPER 3.1 D",
//       "TROOPER 3.1 D INTERCOOLER",
//       "TROOPER 3.1D INTERCOOLER",
//       "TROOPER 3.5 (3 PUERTAS)",
//       "TROOPER 3.5 (5 PUERTAS)",
//       "TROOPER II",
//       "TROOPER II LS",
//       "TROOPER II TURBO DIESEL",
//       "TROOPER LS",
//       "TROOPER LS 3.5L",
//       "TROOPER SCOUT",
//       "TROOPER TDI",
//       "TROOPER XS",
//       "TROPPER II",
//       "TURBO DIESEL CABINA",
//       "VEHICROSS"
//     ],
//     "IVECO": [
//       "120 E15H",
//       "120 E18",
//       "120E15",
//       "120E15",
//       "140E18",
//       "150E20N/150E20T",
//       "150E21NCMB1A",
//       "150E21NCMB1A",
//       "150E21NCMC1A",
//       "150E21NCMC1A",
//       "160E21CCM32",
//       "160E21NCM32",
//       "160E21NCM52",
//       "160E23N/160E23T",
//       "170E21",
//       "170E21",
//       "170E22",
//       "170E22",
//       "170E22 MLL",
//       "170E22 MLL",
//       "170E22 RSU",
//       "170E22 RSU",
//       "170E22NCM21",
//       "170E22NCM21",
//       "170E22NCM31",
//       "170E22NCM31",
//       "170E22NCM51",
//       "170E22NCM51",
//       "170E22NLM31",
//       "170E22NLM31",
//       "170E22NLM51",
//       "170E22NLM51",
//       "170E22RCM31",
//       "170E22RCM31",
//       "170E22RCM31",
//       "170E22T",
//       "170E22T",
//       "170E22T MLL",
//       "170E22T MLL",
//       "170E22TLM22",
//       "170E22TLM22",
//       "170E22TLM2B",
//       "170E22TLM2B",
//       "170E25",
//       "170E25 MLL",
//       "170E25T",
//       "170E25T MLL",
//       "170E28 MLC",
//       "170E28 MLC",
//       "170E28 MLC",
//       "170E28 MLL",
//       "170E28 MLL",
//       "170E28 MLL",
//       "170E28 RSU",
//       "170E28 RSU",
//       "170E28NCM22",
//       "170E28NCM22",
//       "170E28NCM22",
//       "170E28NCM32",
//       "170E28NCM32",
//       "170E28NCM51",
//       "170E28NCM51",
//       "170E28NCM51",
//       "170E28T MLC",
//       "170E28T MLL",
//       "170E28TLM21",
//       "170E28TLM21",
//       "170E30NLA31",
//       "170E30NLA31",
//       "170E30NLA31",
//       "170E30NLA52",
//       "170E30TLA21",
//       "170E30TLA21",
//       "170E30TLA22",
//       "170E30TLA22",
//       "170S28",
//       "180C33 MLL",
//       "180C33 MLL",
//       "180C33 MLL",
//       "180E32",
//       "180E33",
//       "180E33",
//       "200 E 41 H",
//       "200E37",
//       "200S36 C9",
//       "200S36 C9",
//       "200S36NCA43",
//       "200S36NCA43",
//       "200S36NLA43",
//       "200S38",
//       "200S38",
//       "200S41",
//       "200S41",
//       "200S42",
//       "200S42",
//       "200S44",
//       "200S44",
//       "200S44 AT",
//       "200S44N AT",
//       "200S44N AT",
//       "200S44N AT",
//       "230E22",
//       "230E22",
//       "230E24",
//       "240E25",
//       "240E25 RSU",
//       "240E25 TECTOR",
//       "240E28 MLC",
//       "240E28 MLL",
//       "240E28N2M53",
//       "240E28N2M53",
//       "240E28N2M53",
//       "240E28NCA63",
//       "240E28NCM53",
//       "240E28NCM63",
//       "240E28NLM54",
//       "240E28NLM54",
//       "240E28NLM63",
//       "240E28NLM63",
//       "240E28NLM63",
//       "240E28NLM73",
//       "240E28NLM73",
//       "240E28RCA33",
//       "240E28RCA33",
//       "240E28RCA33",
//       "240E28RCA63",
//       "240E30NLA63",
//       "240E30NLA73",
//       "256 M26 6X6",
//       "256 M26 6X6",
//       "260 PC26 6X6 D",
//       "260 U 26",
//       "260-23 AHB",
//       "260-25",
//       "260-25",
//       "260-25 AHB",
//       "260-30",
//       "260E25",
//       "260E25",
//       "260E28",
//       "260E28",
//       "260E28 MLC",
//       "260E28 MLC",
//       "260E28NCM53",
//       "260E28NCM53",
//       "260E30NCM24",
//       "260E30NCM24",
//       "260E30NCM24",
//       "260E30NCM54",
//       "260E30NCM54",
//       "260E30NCM54",
//       "320-30",
//       "320-32",
//       "320E18T",
//       "35-8",
//       "35-8",
//       "380 TRAKKER",
//       "380E31",
//       "380E37H",
//       "380T38",
//       "380T42",
//       "40.10 W 4X4",
//       "40.10 WM (4X4)",
//       "40.10/49 12V",
//       "400E37",
//       "410T44N2M46",
//       "410T44NCA16",
//       "410T44NCA16",
//       "410T44NCA16",
//       "410T44NCM16",
//       "410T44NCM16",
//       "410T44NLA46",
//       "430E31T",
//       "450 E 41 HT",
//       "450C33T MLL",
//       "450C33T MLL",
//       "450C33T MLL",
//       "450E32T",
//       "450E32TY",
//       "450E33T",
//       "450E33T",
//       "450E33T CURSOR",
//       "450E33T MLL",
//       "450E33TY",
//       "450E37T",
//       "450E37T/HR",
//       "450E37T/P",
//       "450E42TZ",
//       "450S38D",
//       "450S38T/HR",
//       "450S38T/P",
//       "450S42T",
//       "450S42T/HR",
//       "450S42T/P",
//       "460S36 AT-",
//       "460S36 AT-",
//       "460S36T",
//       "460S36T",
//       "460S36T AT",
//       "460S36T AT",
//       "460S36TLA14",
//       "460S36TLA14",
//       "49.10",
//       "490S38T",
//       "490S38T HR",
//       "490S38T/P",
//       "490S39T",
//       "490S41T",
//       "490S41T STRALIS",
//       "490S42T",
//       "490S42T HR",
//       "490S42T/P",
//       "490S44T",
//       "490S44T AT",
//       "490S44T AT",
//       "490S44T AT",
//       "490S46T",
//       "490S46T MLL",
//       "530S36T",
//       "530S36T AT",
//       "530S36T AT",
//       "530S36T AT",
//       "530S36TLA85A",
//       "570S38T",
//       "570S41T",
//       "570S42T",
//       "570S46T",
//       "600S44TLA05",
//       "600S44TLA05",
//       "600S44TLA05",
//       "600S44TLA15",
//       "600S44TLA15",
//       "600S44TLA15",
//       "70C14G",
//       "720E42HT",
//       "720T42T",
//       "740S41T",
//       "740S41TZ",
//       "740S42TZ",
//       "740S42TZ",
//       "740T48TLA17",
//       "740T48TLA17",
//       "740T48TLM47",
//       "80 E",
//       "800S48TZ",
//       "800S48TZ",
//       "90E14",
//       "AD410T45",
//       "AS440S48T/P",
//       "AS600S44TYPA85",
//       "AS600S44TYPA85",
//       "AT600S44TYPA85",
//       "AT600S44TYPA85",
//       "CC170E22",
//       "CC170E22",
//       "CC170E22",
//       "CC170E22 PASO 5950",
//       "CC90E14",
//       "CURSOR 190 E 31",
//       "CURSOR 190 E 31",
//       "CURSOR 450 E 31 T",
//       "CURSOR 450 E 31 T",
//       "CURSOR 450E33T",
//       "D1T1A4C8-STRALIS",
//       "DAILY",
//       "DAILY",
//       "DAILY 30-130",
//       "DAILY 30S13 PASO 3000",
//       "DAILY 35 S 14 2.3 AGILE",
//       "DAILY 35.10",
//       "DAILY 35.10",
//       "DAILY 35.10",
//       "DAILY 35-150",
//       "DAILY 35C14 PASO 3750",
//       "DAILY 35C15 PASO 3750",
//       "DAILY 35S13 PASO 3520",
//       "DAILY 35S13 PASO 4100",
//       "DAILY 35S15 PASO 3000",
//       "DAILY 35S15 PASO 3300",
//       "DAILY 40.10 WM",
//       "DAILY 40.12 VIDRIADO",
//       "DAILY 40.13 PASO 2800 4X4",
//       "DAILY 40.13 PASO 3300",
//       "DAILY 40.13 PASO 3600",
//       "DAILY 40.13 PASO 3600",
//       "DAILY 40.13 VIDRIADO PASO",
//       "DAILY 40.13W",
//       "DAILY 40S14 PASO 3000",
//       "DAILY 40S14 PASO 3300",
//       "DAILY 40S14 PASO 3450",
//       "DAILY 45 170",
//       "DAILY 45.10",
//       "DAILY 45S16 VIDRIADO PASO",
//       "DAILY 49.10",
//       "DAILY 49.10",
//       "DAILY 49.10 CAB",
//       "DAILY 49.10 W.B. 3310",
//       "DAILY 49.10 W.B. 3950",
//       "DAILY 49.10/49.10 CAB",
//       "DAILY 49.12",
//       "DAILY 49.12",
//       "DAILY 49.12",
//       "DAILY 49.12 VIDRIADO",
//       "DAILY 50.13 FURGON 3950",
//       "DAILY 50.13 FURGON",
//       "DAILY 50.13 PASO 3600",
//       "DAILY 50.13 PASO 3600",
//       "DAILY 50.13 PASO 3950",
//       "DAILY 50.13 VIDRIADO PASO",
//       "DAILY 50-170",
//       "DAILY 50C16 PASO 3950",
//       "DAILY 50C16 PASO 3950",
//       "DAILY 50C17 VETRATO",
//       "DAILY 50C17 VIDRIADO PASO",
//       "DAILY 55-170",
//       "DAILY 55-170",
//       "DAILY 55-170",
//       "DAILY 55C16 PASO 3300",
//       "DAILY 55C16 PASO 3750",
//       "DAILY 55C16 PASO 3750 CD",
//       "DAILY 55C16 PASO 3750 CD",
//       "DAILY 55C16 PASO 3950",
//       "DAILY 55C17 PASO 3300",
//       "DAILY 55C17 PASO 3750",
//       "DAILY 55C17 PASO 3750",
//       "DAILY 55C17 PASO 3950",
//       "DAILY 59.12",
//       "DAILY 60.12",
//       "DAILY 65C15",
//       "DAILY 70.12",
//       "DAILY 70.13 PASO 3600",
//       "DAILY 70.13 PASO 3600",
//       "DAILY 70.13 PASO 4180",
//       "DAILY 70.13 PASO 4180",
//       "DAILY 70-170",
//       "DAILY 70-170",
//       "DAILY 70-170 SCUDATO",
//       "DAILY 70C14G",
//       "DAILY 70C16 PASO 3750",
//       "DAILY 70C16 PASO 4350",
//       "DAILY 70C16 PASO 4350",
//       "DAILY 70C17 PASO 3990",
//       "DAILY 70C17 PASO 4350",
//       "DAILY 70C17 PASO 4350",
//       "DAILY 70C17 PASO 4350",
//       "DAILY 70C17 PASO 4350",
//       "DAILY 70C17 SCUDATTO",
//       "DAILY CC170E22 PASO 5950",
//       "DAILY CITY 35.10",
//       "DAILY CITY 35.10 PASO 2800",
//       "DAILY CITY 35.10 PASO 3300",
//       "DAILY50C17FURGONVIDRIAD",
//       "DC4910",
//       "DC4910S",
//       "DUCATO MAXICARGO 2.8 JTD",
//       "EURO",
//       "EURO 12-14",
//       "EUROCARGO 140E24",
//       "EUROCARGO 150 E21H",
//       "EUROCARGO 150E21",
//       "EUROCARGO 160 E23N",
//       "EUROCARGO 170E23",
//       "EUROCARGO ML 120 E18",
//       "EUROCARGO ML 120 E18",
//       "EUROCARGO ML150E18K",
//       "EUROTECH 180 E27",
//       "EUROTECH 190 E24",
//       "EUROTECH 190 E27",
//       "EUROTECH 190 E31 P",
//       "EUROTECH 330 E34",
//       "EUROTECH MP440E42TZ/P",
//       "EUROTRAKKER 260 E30",
//       "EUROTRAKKER MP190E37H",
//       "EUROTRAKKER MP190E37H",
//       "EUROTRAKKER MP190E37H",
//       "EUROTRAKKER MP380E37H",
//       "EUROTRAKKER MP380E37H",
//       "EUROTRAKKER MP380E37H",
//       "EUROTRAKKER MP720E37HT",
//       "EUROTRAKKER MPC 340 E 34",
//       "EUROTRAKKER MPC 340E 34",
//       "HD 200S38",
//       "HD 200S38",
//       "HD 450S38T",
//       "HD 450S38T/HR",
//       "HD 450S38T/P",
//       "M 40.10 WM 4X4",
//       "MASSIF 25S15 SW 5P",
//       "MH 190 E31",
//       "ML 140E24W PASO 3915 4X4",
//       "ML 150 E24 WS",
//       "ML 180 E27H",
//       "MLL 320E",
//       "MP190E37W EUROTRAKKER",
//       "MP190E37W EUROTRAKKER",
//       "MP380E37H",
//       "MP410E44H/80",
//       "MP440E42T",
//       "MP440E42T",
//       "MP720E37HT",
//       "R7T4B3B8-STRALIS",
//       "R7T4B3B8-STRALIS",
//       "STRALIS 430",
//       "STRALIS 460S36T",
//       "STRALIS 480",
//       "STRALIS 490S44T",
//       "STRALIS 490S48T",
//       "STRALIS 800S56TZ PASO 3500",
//       "STRALIS AD260S30Y",
//       "STRALIS AS440S50TY",
//       "STRALIS AT440S33T",
//       "STRALIS HI STREET 26-330",
//       "TECTOR 110-190",
//       "TECTOR 150E21",
//       "TECTOR 170E25T",
//       "TECTOR 240E28",
//       "TECTOR 240E28 PASO 4815",
//       "TECTOR 90-190",
//       "TECTOR ATTACK 150E21 PASO",
//       "TECTOR ATTACK 150E21 PASO",
//       "TECTOR ATTACK 150E21 PASO",
//       "TECTOR ATTACK 170E22",
//       "TECTOR ATTACK 170E22T",
//       "TRAKKER 350",
//       "TRAKKER 380",
//       "TRAKKER 450",
//       "TRAKKER AD410T50",
//       "TRAKKER AT 410T50",
//       "TUBO DAILY 40.12 VIDRIADO",
//       "TUBO DAILY 49.12 VIDRIADO",
//       "TURBO DAILY 35.10",
//       "TURBO DAILY 35.10",
//       "TURBO DAILY 35.10",
//       "TURBO DAILY 35.10 D (DOBLE",
//       "TURBO DAILY 35.8",
//       "TURBO DAILY 40.10",
//       "TURBO DAILY 40.10",
//       "TURBO DAILY 40.12 VIDRIADO",
//       "TURBO DAILY 45.10",
//       "TURBO DAILY 49.12",
//       "TURBO DAILY 49.12",
//       "TURBO DAILY 49.12 VIDRIADO",
//       "TURBO DAILY 49-10",
//       "TURBO DAILY 49-10",
//       "TURBO DAILY 49-10",
//       "TURBO DAILY 49-10",
//       "TURBO DAILY 59.12",
//       "TURBO DAILY 59.12",
//       "TURBO DAILY 59.12",
//       "TURBO DAILY 59.12",
//       "VERTIS 130V18 PASO 4750",
//       "VERTIS 130V19 PASO 4815"
//     ],
//     "JAC": [
//       "HFC1035K",
//       "HFC1041K",
//       "HFC1041K",
//       "HFC1061K",
//       "HFC1061K",
//       "JAC S2 1.5 MT INTELLIGENT FL",
//       "S2 1.5 MT INTELLIGENT",
//       "S2 1.5 MT LUXURY",
//       "S3 1.6 CVT INTELLIGENT",
//       "S3 1.6 MT INTELLIGENT",
//       "S5 2.0T DCT INTELLIGENT",
//       "S7 2.0 6 DCT LUXURY",
//       "S7 2.0T DCT INTELLIGENT",
//       "T6 2.0 4X2 DC 6MT LUXURY",
//       "T6 2.0 4X4 DC 6MT LUXURY",
//       "X200 2.0 CS 6MT LUXURY"
//     ],
//     "JAGUAR": [
//       "DAIMLER 4.0",
//       "DAIMLER DOUBLE SIX",
//       "DAIMLER SIX",
//       "F-PACE 2.0D AWD",
//       "F-PACE 30T AWD",
//       "F-PACE 35T AWD",
//       "F-PACE 35T AWD R-SPORT",
//       "F-PACE R-SPORT",
//       "F-TYPE",
//       "F-TYPE V8 S",
//       "SOVEREIGN 4.0",
//       "SOVEREIGN CHASIS LARGO",
//       "S-TYPE",
//       "S-TYPE 3.0 V6 SE",
//       "S-TYPE 4.2 V8",
//       "S-TYPE V6",
//       "S-TYPE V6 EXECUTIVE",
//       "S-TYPE V8 4.0",
//       "VANDENPLAS XJ6",
//       "XE 25T",
//       "XE 25T R-SPORT",
//       "XE 25T SE",
//       "XE S",
//       "XF 2.0",
//       "XF 3.0 V6",
//       "XFR V8 5.0 SUPERCHARGED",
//       "XJ 4.2",
//       "XJ 4.2C",
//       "XJ SUPER V8 4.2",
//       "XJ8 3.2",
//       "XJ8 3.2",
//       "XJ8 4.0",
//       "XJR",
//       "XJR8 4.0",
//       "XJS",
//       "XK8",
//       "XK8",
//       "XKE",
//       "XKE",
//       "XKR 4.0",
//       "XKR 4.0",
//       "XKR 4.2 V8 CABRIOLET",
//       "XKR V8 5.0 SUPERCHARGED",
//       "XKR V8 COUPE",
//       "XKR8 4.0 COUPE",
//       "X-TYPE 2.0 DIESEL SE",
//       "X-TYPE 2.0 V6",
//       "X-TYPE 2.0 V6 SE",
//       "X-TYPE 2.2 SE",
//       "X-TYPE 3.0 V6 SE",
//       "X-TYPE 2.5"
//     ],
//     "JAWA": [
//       "CZ 200",
//       "DAYTONA 350",
//       "DD 300 E",
//       "DD 300 E",
//       "JAWA 350",
//       "JAWA 350",
//       "RVM 600",
//       "SPYDER 350",
//       "SUPERNOVA 150",
//       "TEKKEN"
//     ],
//     "JEEP": [
//       "CHEROKEE",
//       "CHEROKEE",
//       "CHEROKEE",
//       "CHEROKEE 3.7",
//       "CHEROKEE CHIEF",
//       "CHEROKEE CLASSIC",
//       "CHEROKEE CLASSIC",
//       "CHEROKEE CLASSIC MANUAL",
//       "CHEROKEE CLASSIC MANUAL",
//       "CHEROKEE CLASSIC TD",
//       "CHEROKEE CLASSIC TURBO",
//       "CHEROKEE CLASSIC TURBO",
//       "CHEROKEE COUNTRY",
//       "CHEROKEE CRD",
//       "CHEROKEE CRD LIMITED",
//       "CHEROKEE LAREDO",
//       "CHEROKEE LAREDO",
//       "CHEROKEE LIMITED",
//       "CHEROKEE LIMITED 3.7",
//       "CHEROKEE LIMITED TD",
//       "CHEROKEE PIONEER TURBO",
//       "CHEROKEE SE",
//       "CHEROKEE SE",
//       "CHEROKEE SPORT",
//       "CHEROKEE SPORT",
//       "CHEROKEE SPORT",
//       "CHEROKEE SPORT 3.7",
//       "CHEROKEE SPORT 4.0 L HIGH",
//       "CHEROKEE SPORT AUT.",
//       "CHEROKEE SPORT CRD",
//       "CHEROKEE SPORT MAN.",
//       "CHEROKEE SPORT TD",
//       "CHEROKEE TD",
//       "CHEROKEE TD",
//       "CHEROKEE TRAILHAWK",
//       "CHEROKKE PIONEER",
//       "COMMANDER",
//       "COMMANDER LIMITED",
//       "COMMANDER LIMITED 2.0",
//       "COMMANDER OVERLAND 2.0",
//       "COMPASS",
//       "COMPASS",
//       "COMPASS LATITUDE FWD CVT",
//       "COMPASS LIMITED",
//       "COMPASS LIMITED 1.3T AT6",
//       "COMPASS LIMITED 2.0 AT9",
//       "COMPASS LIMITED 2.4 AT9",
//       "COMPASS LIMITED RALLYE",
//       "COMPASS LONGITUDE 1.3T",
//       "COMPASS LONGITUDE 2.0",
//       "COMPASS LONGITUDE 2.4",
//       "COMPASS LONGITUDE 2.4",
//       "COMPASS SERIE-S T270 AT6",
//       "COMPASS SPORT 1.3T AT6",
//       "COMPASS SPORT 2.4 AT6",
//       "COMPASS SPORT 2.4 AUT",
//       "COMPASS SPORT 2.4 MT6",
//       "COMPASS SPORT 2.4L",
//       "COMPASS SPORT 2.4L",
//       "COMPASS SPORT 4X4 2.4",
//       "COMPASS TRAILHAWK 2.0",
//       "COMPASS TRAILHAWK 2.0",
//       "GLADIATOR OVERLAND 3.6L",
//       "GLADIATOR RUBICON 3.6L",
//       "GR. CHEROKEE LIMITED",
//       "GR. CHEROKEE LIMITED",
//       "GRAND CHEROKEE",
//       "GRAND CHEROKEE",
//       "GRAND CHEROKEE LAREDO",
//       "GRAND CHEROKEE LAREDO",
//       "GRAND CHEROKEE LAREDO",
//       "GRAND CHEROKEE LAREDO",
//       "GRAND CHEROKEE LAREDO",
//       "GRAND CHEROKEE LAREDO",
//       "GRAND CHEROKEE LAREDO",
//       "GRAND CHEROKEE LAREDO",
//       "GRAND CHEROKEE LAREDO",
//       "GRAND CHEROKEE LAREDO-W",
//       "GRAND CHEROKEE LAREDO-W",
//       "GRAND CHEROKEE LIMITED",
//       "GRAND CHEROKEE LIMITED",
//       "GRAND CHEROKEE LIMITED",
//       "GRAND CHEROKEE LIMITED",
//       "GRAND CHEROKEE LIMITED",
//       "GRAND CHEROKEE LIMITED",
//       "GRAND CHEROKEE LIMITED",
//       "GRAND CHEROKEE LIMITED",
//       "GRAND CHEROKEE LIMITED",
//       "GRAND CHEROKEE LIMITED",
//       "GRAND CHEROKEE LIMITED-",
//       "GRAND CHEROKEE LIMITED-",
//       "GRAND CHEROKEE LIMITED-",
//       "GRAND CHEROKEE LIMITED-",
//       "GRAND CHEROKEE LTD",
//       "GRAND CHEROKEE ORVIS",
//       "GRAND CHEROKEE",
//       "GRAND CHEROKEE",
//       "GRAND CHEROKEE",
//       "GRAND CHEROKEE SE",
//       "GRAND CHEROKEE SPECIAL",
//       "GRAND CHEROKEE SPECIAL",
//       "GRAND CHEROKEE SRT-8",
//       "GRAND CHEROKEE SRT-8",
//       "GRAND CHEROKEE TSI",
//       "LIBERTY 3.7L",
//       "LIBERTY 3.7L",
//       "LIBERTY 3.7L LIMITED",
//       "LIBERTY 3.7L LIMITED",
//       "LIBERTY CRD",
//       "LIBERTY CRD LIMITED",
//       "LIBERTY SPORT",
//       "LIBERTY SPORT",
//       "PATRIOT",
//       "PATRIOT",
//       "PATRIOT LIMITED A/T",
//       "PATRIOT SPORT FWD",
//       "RENEGADE 1.8L SPORT",
//       "RENEGADE 2.4L LONGITUDE",
//       "RENEGADE LIMITED",
//       "RENEGADE LONGITUDE 1.8L",
//       "RENEGADE LONGITUDE T270",
//       "RENEGADE SERIE-S T270 AT6",
//       "RENEGADE SPORT 1.8 AT6",
//       "RENEGADE SPORT 1.8 MT5",
//       "RENEGADE SPORT 1.8L AT6",
//       "RENEGADE SPORT 1.8L MT5",
//       "RENEGADE TRAILHAWK 2.0",
//       "RENEGADE TRAILHAWK T270",
//       "WRANGLER",
//       "WRANGLER 3.8L (2 PUERTAS)",
//       "WRANGLER 3.8L (4 PUERTAS)",
//       "WRANGLER 4.0L",
//       "WRANGLER OTROS",
//       "WRANGLER RIO GRANDE",
//       "WRANGLER RUBICON 4X4",
//       "WRANGLER SAHARA 4.0L",
//       "WRANGLER SE 4X4",
//       "WRANGLER SPORT 3.8L",
//       "WRANGLER SPORT 4.0L",
//       "WRANGLER TJ",
//       "WRANGLER TJ 4.0L SPORT",
//       "WRANGLER UNLIMITED",
//       "WRANGLER UNLIMITED",
//       "WRANGLER UNLIMITED",
//       "WRANGLER UNLIMITED",
//       "WRANGLER UNLIMITED",
//       "WRANGLER UNLIMITED",
//       "WRANGLER UNLIMITED",
//       "WRANGLER/4.0L",
//       "WRANGLER RUBICON 3.6L",
//       "WRANGLER SPORT 3.6L",
//       "WRANGLER UNLIMITED 3.6L",
//       "WRANGLER UNLIMITED 3.6L"
//     ],
//     "JETOUR": ["X70"],
//     "JIANSHE": [
//       "JS 125",
//       "JS 125-3",
//       "JS 125-6B",
//       "JS 125-7F",
//       "JS110-3",
//       "JS110-3H",
//       "JS110ATV",
//       "JS110-C",
//       "JS125",
//       "JS125-3",
//       "JS125-5B",
//       "JS125-6B",
//       "JS125-7",
//       "JS125F",
//       "JS150-3",
//       "JS250ATV-3",
//       "JS250ATV-5",
//       "JS250ATV-5",
//       "JS250ATV-F2",
//       "JS400ATV"
//     ],
//     "JINCHENG": [
//       "JC 110-19",
//       "JC 110-9",
//       "JC 125-17B",
//       "JC110-9",
//       "JC125-12",
//       "JC125-17B",
//       "JC250-6"
//     ],
//     "JMC": ["CONQUER N800", "N 900", "N 900", "N-601", "N800"],
//     "JMSTAR": ["ALPINA 110", "NEVADA 110"],
//     "KAWASAKI": [
//       "BAYOU 220",
//       "BAYOU 250",
//       "BRUTE FORCE 300",
//       "BRUTE FORCE 650 4X4",
//       "BRUTE FORCE 750 4X4 I EPS",
//       "BRUTE FORCE 750 4X4I",
//       "BRUTE FORCE VTWIN 650",
//       "BRUTE FORCE VTWIN 750",
//       "CONCOURS 14",
//       "ELIMINATOR SPORT EDITION",
//       "EN 500 A 7 I",
//       "EN 500 A2",
//       "EN 500 A4",
//       "EN 500-A3 (EN500)",
//       "EN500",
//       "EN500 A6",
//       "EN500 VULCAN",
//       "EN500-A3",
//       "EN500-A5",
//       "EN500-A6L",
//       "EN500A7",
//       "EN500B2",
//       "EN500-BI",
//       "EN500-C",
//       "EN500-C1",
//       "EN500-C2",
//       "ER-6N",
//       "EX 250 F",
//       "EX 250-F3,L",
//       "EX 250-H3",
//       "EX250",
//       "EX250 F4 NINJA R",
//       "EX250 H",
//       "EX250-F",
//       "EX250-F10",
//       "EX250-F12",
//       "EX250-F6",
//       "EX250-F6L",
//       "EX250-F7",
//       "EX250-F8",
//       "EX250-F8L",
//       "EX250F9",
//       "EX250-F9L",
//       "EX250-II3",
//       "KE100-B11",
//       "KFX 400",
//       "KFX450R",
//       "KFX50",
//       "KFX700",
//       "KFX700 V FORCE",
//       "KFX90",
//       "KLE650 VERSYS",
//       "KLF250A6F",
//       "KLF250A8F",
//       "KLF250A9F",
//       "KLF250ABF",
//       "KLR 650",
//       "KLR 650",
//       "KLX 250 S",
//       "KLX110",
//       "KLX250",
//       "KLX250-D1",
//       "KLX250-D4",
//       "KLX250-E1",
//       "KLX250SF",
//       "KLX450A8F",
//       "KLX450R",
//       "KSF450B8F",
//       "KSF450B9F",
//       "KSF450BBF",
//       "KSF50",
//       "KSF50-A3",
//       "KSF50BDF",
//       "KVF400 4X4",
//       "KVF400-A1",
//       "KVF400-A2",
//       "KVF400-B1",
//       "KVF400-C",
//       "KVF400-C",
//       "KVF400-C1",
//       "KVF400-C2",
//       "KVF650",
//       "KVF650DCS",
//       "KVF650F9F",
//       "KVF750",
//       "KVF750D8F",
//       "KVF750DAF",
//       "KVF750DBF",
//       "KVF750FBF",
//       "KVF750GSC",
//       "KVF750HCF",
//       "KVF750LCF",
//       "KX 250",
//       "KX250",
//       "KX250 K2",
//       "KX250F",
//       "KX250-K1",
//       "KX250-K3",
//       "KX250L",
//       "KX250-N2",
//       "KX250W9F",
//       "KX450F",
//       "KX80-W",
//       "KX85",
//       "LF250A",
//       "NINJA",
//       "NINJA 1000",
//       "NINJA 1000 ABS",
//       "NINJA 1000 SX",
//       "NINJA 250",
//       "NINJA 400 ABS",
//       "NINJA 400 ABS",
//       "NINJA 600",
//       "NINJA 650 ABS",
//       "NINJA 650R",
//       "NINJA ZX - 10R ABS",
//       "NINJA ZX - 14R ABS",
//       "NINJA ZX-10R",
//       "NINJA ZX-12R",
//       "NINJA ZX-14",
//       "NINJA ZX6",
//       "NINJA ZX636",
//       "NINJA ZX-6R",
//       "NINJA ZX6R ABS",
//       "PRAIRE 360 4X4",
//       "PRAIRIE 360",
//       "PRAIRIE 360 4X4",
//       "TERYX 4X4",
//       "TERYX 4X4 NRA OUTDOORS",
//       "TERYX 750 FI 4X4",
//       "TERYX 750 FI 4X4 LE",
//       "TERYX 750 FI 4X4 NRA",
//       "TERYX 750 FI SPORT 4X4",
//       "TERYX4 750 4X4 EPS",
//       "TERYX4 750 4X4 EPS LE",
//       "TERYX4 750 4X4 EPS",
//       "VERSYS",
//       "VERSYS 1000",
//       "VERSYS 650 ABS",
//       "VERSYS 650 ABS",
//       "VERSYS X 300 ABS",
//       "VERSYS-X 300 ABS",
//       "VN 900 VULCAN",
//       "VN2000",
//       "VN2000-A",
//       "VN900",
//       "VULCAN 500",
//       "VULCAN 900",
//       "VULCAN 900 CUSTOM",
//       "VULCAN S ABS",
//       "Z 400 ABS",
//       "Z 400 ABS",
//       "Z1000",
//       "Z1000 ABS",
//       "Z650 ABS",
//       "Z750 LTD",
//       "Z800",
//       "Z900 ABS",
//       "Z900RS",
//       "ZR 750-C3",
//       "ZR1100 C2",
//       "ZR750-C3",
//       "ZX 1000",
//       "ZX 1200A",
//       "ZX 6",
//       "ZX 6 E1",
//       "ZX 600",
//       "ZX 600 CZ L NINJA",
//       "ZX 600 J",
//       "ZX 600-C3",
//       "ZX 600-C4",
//       "ZX 600-D3 (ZZ-R600)",
//       "ZX 600-D3L",
//       "ZX10",
//       "ZX1000",
//       "ZX1000-C",
//       "ZX-10R",
//       "ZX1200-B",
//       "ZX14",
//       "ZX14 CONCOURS",
//       "ZX600 CH",
//       "ZX600 DZ",
//       "ZX600 E2",
//       "ZX600-C5",
//       "ZX600-C5",
//       "ZX600-D2",
//       "ZX600-E3",
//       "ZX600-E4",
//       "ZX600-F",
//       "ZX600-F1",
//       "ZX600-F2",
//       "ZX600-F3",
//       "ZX600-F3L",
//       "ZX600-G",
//       "ZX600R",
//       "ZX636-B",
//       "ZX636C NINJA",
//       "ZX-6R",
//       "KVF750GCS",
//       "KVF750HCS",
//       "KVF750JCF"
//     ],
//     "KEEWAY": ["KLIGHT 202", "RK", "TARGET", "TX 200"],
//     "KELLER": [
//       "CONQUISTA 150",
//       "JET MAX 250",
//       "K65",
//       "K65",
//       "K65-MT",
//       "K65-TR",
//       "KN110-10",
//       "KN110-7",
//       "KN110-7",
//       "KN110-8",
//       "KN110-8",
//       "KN110-8",
//       "KN110-9",
//       "KN125-11",
//       "KN125-11",
//       "KN125-11",
//       "KN125-12",
//       "KN125-12",
//       "KN150-13",
//       "KN-150-13",
//       "KN150-3",
//       "KN150GY",
//       "KN150GY",
//       "KN200GY",
//       "KN200GY",
//       "KN200R",
//       "KN250-3",
//       "KN250-3",
//       "KN250GY",
//       "KN260GY",
//       "KN260GY",
//       "KR150S",
//       "KR200GY",
//       "KR200ST-6",
//       "KR250ST-8",
//       "KR250-STIXE",
//       "KR260",
//       "KR260",
//       "KR260 A",
//       "KR260A/",
//       "KR260GY",
//       "STRATUS 260",
//       "STRATUS 260",
//       "XTREME 150",
//       "YB 150 T-150",
//       "YB 150 T-2",
//       "YB150T",
//       "YB150T EXOTIC",
//       "YB150T-10",
//       "YB150T-12 CHAMPIONS",
//       "YB150T-15",
//       "YB150T-2",
//       "YB150T-5 EXOTIC",
//       "YB150T-5 TROPICAL",
//       "YB150T-7 MATRIX",
//       "YBR260 STRATUS"
//     ],
//     "KIA": [
//       "AVELLA 1.3 GXI",
//       "AVELLA 1.5 GLX",
//       "AVELLA 1.5L",
//       "AVELLA 1.5L GLXI",
//       "AVELLA DELTA",
//       "AVELLA DELTA",
//       "AVELLA DELTA GS",
//       "AVELLA GXI",
//       "AVELLA II",
//       "BESTA",
//       "BESTA",
//       "BESTA",
//       "BESTA",
//       "BESTA 12 EST",
//       "BESTA 12C",
//       "BESTA 12C EST",
//       "BESTA 12C EST 4X4",
//       "BESTA 12C ST",
//       "BESTA 2.2L 9C",
//       "BESTA 2.2L 9C EST",
//       "BESTA 3V",
//       "BESTA 3V ST",
//       "BESTA 3VAN",
//       "BESTA 9C EST",
//       "BESTA 9C EST",
//       "BESTA 9C EST 2.7L",
//       "BESTA 9C EST 4WD",
//       "BESTA K2700",
//       "BESTA LST",
//       "CARENS",
//       "CARENS EX CRDI A/T",
//       "CARENS LS",
//       "CARENS LX",
//       "CARENS LX CRDI",
//       "CARENS LX CRDI A/T",
//       "CARENS RS",
//       "CARNIVAL",
//       "CARNIVAL 2.9 CRDI",
//       "CARNIVAL 2.9 TDI",
//       "CARNIVAL EX",
//       "CARNIVAL EX 2.2 AT CRDI",
//       "CARNIVAL EX 2.2 AT CRDI",
//       "CARNIVAL EX 2.7 V6 AT",
//       "CARNIVAL EX 2.9",
//       "CARNIVAL EX 2.9 CRDI",
//       "CARNIVAL EX 2.9 CRDI AT",
//       "CARNIVAL EX V6",
//       "CARNIVAL GS",
//       "CARNIVAL GS A/T",
//       "CARNIVAL GS TDI",
//       "CARNIVAL GS TDI A/T",
//       "CARNIVAL GS V6",
//       "CARNIVAL GS V6 A/T",
//       "CARNIVAL LAND",
//       "CARNIVAL LAND 2.9 TDI",
//       "CARNIVAL LAND TURBO",
//       "CARNIVAL LS",
//       "CARNIVAL LS 2.9 TDI",
//       "CARNIVAL LS 2.9 TDI AUT.",
//       "CARNIVAL LS A/T",
//       "CARNIVAL LX",
//       "CARNIVAL M/T 2.9 DSL CRDI",
//       "CARNIVAL PARK 2.9TDI",
//       "CARNIVAL PARK TURBO",
//       "CARNIVAL SX 2.2 CRDI A/T",
//       "CEE D",
//       "CERATO EX 1.6 A/T",
//       "CERATO EX 1.6 A/T",
//       "CERATO EX 1.6 CRDI",
//       "CERATO EX 1.6 M/T",
//       "CERATO EX 1.6 M/T",
//       "CERATO EX 1.6L M/T",
//       "CERATO EX 2.0 A/T",
//       "CERATO EX 2.0 CRDI",
//       "CERATO EX 2.0 M/T",
//       "CERATO FORTE SX 2.0 A/T",
//       "CERATO GOLD CVVT",
//       "CERATO KOUP 1.6L",
//       "CERATO KOUP 2.0L",
//       "CERATO KOUP 2.0L M/T",
//       "CERATO SX 2.0 A/T",
//       "CERATO SX 2.0 A/T",
//       "CERATO SX 2.0 A/T GT-LINE",
//       "CERATO SX PLUS 2.0 A/T",
//       "CERES",
//       "CERES 4X4 CARGO",
//       "CERES 4X4 DUMP",
//       "CLARUS 1.8 GLX",
//       "CLARUS 2.0L GLX",
//       "CLARUS SLX",
//       "COMBI",
//       "FRONTIER 3000",
//       "GRAND CARNIVAL EX TDI",
//       "GRAND SPORTAGE",
//       "GRAND SPORTAGE TDI",
//       "GTX SEPHIA",
//       "HI-BESTA EST 2.7",
//       "K 2900",
//       "K 3500",
//       "K 3600S",
//       "K 3600S",
//       "K-2400",
//       "K2400 (CABINA DOBLE)",
//       "K2400 10S",
//       "K2500",
//       "K2500",
//       "K2500",
//       "K-2700",
//       "K-2700",
//       "K-2700",
//       "K2700 4X4",
//       "K2700 II",
//       "K-2700 II 4X4",
//       "K2700 II 4X4 CABINA DOBLE",
//       "K2700 L/B S/C",
//       "K2700 STD (CABINA SIMPLE)",
//       "K3500 S",
//       "K3600 25S",
//       "K3600 33S",
//       "K3600S 25S",
//       "K3600S 33S",
//       "K3600S 33S",
//       "K3600S 33S STD",
//       "MAGENTIS EX",
//       "MAGENTIS EX V6",
//       "MAGENTIS SE",
//       "MOHAVE",
//       "MOHAVE 3.8L V6 AT (NAFTA)",
//       "MOHAVE 3.8L V6 AT (NAFTA)",
//       "MOHAVE EX 3.8L V6 AT",
//       "MORNING EX 1.2",
//       "OPIRUS GL",
//       "PICANTO",
//       "PICANTO 1.2",
//       "PICANTO A/T",
//       "PICANTO EX",
//       "PICANTO EX 1.0 M/T",
//       "PICANTO EX 1.2 A/T",
//       "PICANTO EX 1.2 M/T",
//       "PICANTO EX A/T",
//       "PICANTO EX MANUAL",
//       "PREGIO",
//       "PREGIO",
//       "PREGIO",
//       "PREGIO 2.7 12P GS",
//       "PREGIO 2.7 12P GS STD",
//       "PREGIO 2.7 12P RS",
//       "PREGIO 3.0",
//       "PREGIO 3.0",
//       "PREGIO 3.0 (AMBULANCIA)",
//       "PREGIO GS",
//       "PREGIO GS",
//       "PREGIO GS",
//       "PREGIO GS 12 PASAJEROS",
//       "PREGIO GS 9P",
//       "PREGIO LS",
//       "PREGIO LS",
//       "PREGIO RS",
//       "PREGIO RS",
//       "PREGIO RS",
//       "PREGIO RS (AMBULANCIA)",
//       "PREGIO RS DIESEL",
//       "PRIDE",
//       "PRIDE",
//       "PRIDE 1.1 L POP STD",
//       "PRIDE 1.1L POP",
//       "PRIDE 1.3L",
//       "PRIDE 1.3L",
//       "PRIDE 1.3L GLXI",
//       "PRIDE 1.3L GLXI",
//       "PRIDE BETA",
//       "PRIDE POP",
//       "PRIDE YOUNG",
//       "RHINO",
//       "RIO",
//       "RIO",
//       "RIO 5",
//       "RIO CVVT",
//       "RIO CVVT",
//       "RIO EX 1.4",
//       "RIO EX 1.4",
//       "RIO EX 1.4 A/T",
//       "RIO EX 1.4 M/T",
//       "RIO EX 1.4 M/T",
//       "RIO EX 1.6 A/T",
//       "RIO EX CVVT",
//       "RIO EX1.6 M/T",
//       "RIO SX 1.6 A/T",
//       "RONDO 2.0 AT CRDI",
//       "RONDO EX",
//       "RONDO EX AT",
//       "RONDO EX AT GSL",
//       "RONDO EX CRDI",
//       "RONDO LX CRDI",
//       "SEDONA EX",
//       "SEDONA LX",
//       "SELTOS EX 1.6 A/T",
//       "SELTOS LX 1.6 A/T",
//       "SEPHIA",
//       "SEPHIA",
//       "SEPHIA 1.5",
//       "SEPHIA 1.5L A/T",
//       "SEPHIA 1.5L GTX",
//       "SEPHIA 1.5L GTX A/T",
//       "SEPHIA 1.6",
//       "SEPHIA 1.8",
//       "SEPHIA II",
//       "SEPHIA II LS",
//       "SEPHIA LEO",
//       "SEPHIA LS",
//       "SEPHIA RS",
//       "SORENTO",
//       "SORENTO",
//       "SORENTO 2.2 CRDI AT",
//       "SORENTO 2.2 CRDI MT",
//       "SORENTO 2.5 CRDI M/T",
//       "SORENTO EX",
//       "SORENTO EX",
//       "SORENTO EX 2.2 A/T 4X2 CRDI",
//       "SORENTO EX 2.2 A/T 4X4 CRDI",
//       "SORENTO EX 2.2 M/T 4X2",
//       "SORENTO EX 2.4 A/T",
//       "SORENTO EX 2.4 A/T",
//       "SORENTO EX 2.4 A/T 4X2",
//       "SORENTO EX 2.4 A/T 4X4",
//       "SORENTO EX 2.4L",
//       "SORENTO EX 2.4L",
//       "SORENTO EX 2.4L",
//       "SORENTO EX M/T",
//       "SORENTO EX TD",
//       "SORENTO EX TDI",
//       "SORENTO EX V6",
//       "SORENTO EX V6",
//       "SORENTO LX",
//       "SORENTO LX",
//       "SORENTO SX 2.2 CRDI AT",
//       "SOUL",
//       "SOUL 1.6 MT CLASSIC EX",
//       "SOUL 1.6L A/T",
//       "SOUL 1.6L M/T",
//       "SOUL EX 1.6 A/T",
//       "SOUL EX 1.6 M/T",
//       "SPORTAGE",
//       "SPORTAGE",
//       "SPORTAGE 2.0 MRDI 4WD",
//       "SPORTAGE 2.0 MRDI 4WD",
//       "SPORTAGE 2.0L A/T",
//       "SPORTAGE 2.2 DIESEL",
//       "SPORTAGE 2DR 4X4",
//       "SPORTAGE 4WD MR DIESEL",
//       "SPORTAGE 4X2",
//       "SPORTAGE 4X2 2.0 CAJA",
//       "SPORTAGE 4X2 CRDI",
//       "SPORTAGE AT MRDI 4WD",
//       "SPORTAGE AT MRDI 4WD",
//       "SPORTAGE CRDI",
//       "SPORTAGE DLX",
//       "SPORTAGE DLX MR 4WD",
//       "SPORTAGE DLX MR 4WD",
//       "SPORTAGE EX",
//       "SPORTAGE EX 2.0 A/T 4X2",
//       "SPORTAGE EX 2.0 A/T 4X2",
//       "SPORTAGE EX 2.0 A/T 4X4",
//       "SPORTAGE EX 2.0 A/T 4X4",
//       "SPORTAGE EX 2.0 MPI A/T",
//       "SPORTAGE EX 2.0L",
//       "SPORTAGE EX 2.0L A/T",
//       "SPORTAGE EX 2.0L A/T",
//       "SPORTAGE EX 4X2 MANUAL",
//       "SPORTAGE EX CRDI A/T",
//       "SPORTAGE EX CRDI M/T",
//       "SPORTAGE LIMITED",
//       "SPORTAGE LX",
//       "SPORTAGE LX",
//       "SPORTAGE LX 2.0 MPI A/T 4X2",
//       "SPORTAGE M/T",
//       "SPORTAGE M/T",
//       "SPORTAGE MR DIESEL",
//       "SPORTAGE SX 2.0 MPI A/T",
//       "SPORTAGE T. INTERCOOLER",
//       "SPORTAGE TDI 4X4",
//       "SPORTAGE TLX",
//       "SPORTAGE WAGON TCI",
//       "SPORTAGE WAGON TDI",
//       "TOPIC",
//       "TRADE",
//       "TRADE 2.5T",
//       "CERATO EX",
//       "CERATO EX 2.0 A/T",
//       "CERATO FORTE 1.6 FULL M/T",
//       "CERATO FORTE 2.0 A/T",
//       "PICANTO LX",
//       "RONDO",
//       "RONDO EX 2.0 A/T",
//       "SORENTO EX 2.2 DIESEL A/T",
//       "SORENTO EX 2.4L 4X2",
//       "SORENTO EX 2.5 DIESEL",
//       "SOUL 1.6 M/T CLASSIC"
//     ],
//     "KIDEN": ["KD 250 V"],
//     "KIKAI": [
//       "DK 110 CC",
//       "DK 110 CC CLASSIC",
//       "DK 110 CS",
//       "DK 150 AS",
//       "DK125 ATVA",
//       "DK150 AS",
//       "DK250 AC",
//       "DK250 ATV A"
//     ],
//     "KONISA": [
//       "CL 110-5",
//       "CL 110-7",
//       "CL125-15",
//       "CL125-15 SPORTSMAN",
//       "KE125-26",
//       "KE125-28H",
//       "KE200",
//       "KE250",
//       "LF125-J",
//       "LX",
//       "LX 150",
//       "LX110-3A",
//       "LX150-6A",
//       "QS110-5",
//       "QS200-8",
//       "YJ-110B",
//       "YJ-150B",
//       "YJ-250B",
//       "YJ-250D"
//     ],
//     "KTM": [
//       "1090 ADVENTURE",
//       "1090 ADVENTURE R",
//       "1190 ADVENTURE",
//       "1190 ADVENTURE R",
//       "125 EXC",
//       "125SX",
//       "1290 SUPER ADVENTURE",
//       "1290 SUPER ADVENTURE R",
//       "1290 SUPER ADVENTURE S",
//       "1290 SUPERDUKE R",
//       "150 SX",
//       "200 DUKE",
//       "200EXC",
//       "250 EXC TPI",
//       "250 EXC TPI SIX DAYS",
//       "250 EXC-F SIX DAYS",
//       "250 SX-F",
//       "250 XC-F",
//       "250EXC",
//       "250EXC-F",
//       "300 EXC",
//       "300 EXC TPI",
//       "300 EXC TPI SIX DAYS",
//       "300 EXC-E",
//       "300 XC-W SIX DAYS",
//       "350 EXC-F",
//       "350 EXC-F",
//       "350 EXC-F SIX DAYS",
//       "350 SX-F",
//       "350 XCF",
//       "350 XCF-W SIX DAYS",
//       "390 DUKE",
//       "450 EXC",
//       "450 EXC RACING",
//       "450 EXC-F",
//       "450 EXC-F SIX DAYS",
//       "450 EXC-R",
//       "450 SX-F",
//       "450 XC-F",
//       "450SXF",
//       "50 SX PRO JUNIOR",
//       "50 SX PRO SENIOR",
//       "50 SX PRO SENIOR LC",
//       "500 EXC",
//       "500 EXC SIX DAYS",
//       "50SX",
//       "530 EXC-R",
//       "65 SX",
//       "690 DUKE",
//       "690 ENDURO",
//       "690 ENDURO R",
//       "690 RALLY REPLICA",
//       "690 SM",
//       "690 SMC R",
//       "790 ADVENTURE",
//       "790 ADVENTURE R",
//       "790 DUKE",
//       "85 SX",
//       "950 ADVENTURE",
//       "990 ADVENTURE",
//       "990 ADVENTURE R",
//       "990 SM T",
//       "990 SUPERDUKE",
//       "ADVENTURE 390",
//       "ADVENTURE 790",
//       "ADVENTURE 790 R",
//       "DUKE 200",
//       "DUKE 200 G2",
//       "DUKE 390",
//       "KTM 250 ADVENTURE",
//       "KTM 250 DUKE",
//       "KTM 250 EXC-F",
//       "KTM 350 EXC-F",
//       "MOTO KTM 250 EXC-F",
//       "MOTO KTM 390 DUKE",
//       "RC 200",
//       "RC 390",
//       "RC 390"
//     ],
//     "KYC": [
//       "MAMUT",
//       "MAMUT",
//       "MAMUT BOX",
//       "MAMUT BOX REFRIGERADO",
//       "X3",
//       "X3",
//       "X3 CARGO BOX (CARROZADA)",
//       "X3 REFRIGERATOR"
//     ],
//     "KYMCO": [
//       "ACTIV 110",
//       "ACTIV 110",
//       "ACTIV 125 SRX",
//       "ACTIV 125SR",
//       "AGILITY 125",
//       "AGILITY 125",
//       "AGILITY 125",
//       "AGILITY 200I",
//       "AGILITY 50",
//       "AGILITY CITY 200 I",
//       "AGILITY RS 125 NAKED",
//       "AK 550I",
//       "AXR110",
//       "BET & WIN 250",
//       "DINK 150",
//       "DJ 50",
//       "DJ50 REFINED",
//       "DOWNTOWN 300 I",
//       "DOWNTOWN 300I",
//       "DOWNTOWN 350I ABS",
//       "GRAND DINK 150",
//       "GRAND DINK 250",
//       "GRAND KING 125",
//       "GRAND KING 125",
//       "HEROISM 125",
//       "HIPSTER 125",
//       "KB 100",
//       "LIKE 125",
//       "LIKE 125",
//       "LIKE 150I",
//       "LIKE 200 I",
//       "LIKE 200I",
//       "MXER 150",
//       "MXER 250 R",
//       "MXER 50",
//       "MXER 50R",
//       "MXER 90R",
//       "MXU 150",
//       "MXU 250",
//       "MXU 300",
//       "MXU 500",
//       "MXU375",
//       "PEOPLE 150",
//       "PEOPLE 200S",
//       "PEOPLE 250",
//       "PEOPLE 250",
//       "PEOPLE 50",
//       "PEOPLE GT 300 I",
//       "PEOPLE S 150I",
//       "PLEASURE",
//       "PLEASURE 125",
//       "PULSAR 125",
//       "PULSAR 125 LUXE",
//       "PULSAR UP 125",
//       "PULSAR UP 125",
//       "QUANNON",
//       "SPIKE 125",
//       "SPIKE 125R",
//       "STRYKER 125",
//       "STRYKER 150",
//       "SUPER 9",
//       "SUPER9 50",
//       "SUPER9 AIR",
//       "TOP BOY 100",
//       "UXV 500",
//       "UXV 700",
//       "UXV 700I",
//       "UXV500",
//       "VENOX 250",
//       "VISA R 110",
//       "XCITING 500",
//       "XCITING 500 RI",
//       "XJR",
//       "X-TOWN 250I",
//       "YUP 50",
//       "ZING 150",
//       "ZX50"
//     ],
//     "LADA": [
//       "21043",
//       "2107",
//       "AFALINA 21100 1.5",
//       "AFALINA 21102 1.5 I",
//       "AFALINA 21103 1.5 I 16V",
//       "AFALINA 21110 1.5 I",
//       "LAIKA 21043",
//       "LAIKA 21043",
//       "LAIKA 21043 1.6",
//       "LAIKA 21053",
//       "LAIKA 21053 1.5",
//       "LAIKA 21053 1.6",
//       "LAIKA 21053/70",
//       "LAIKA 21070",
//       "LAIKA 21074",
//       "LAIKA OTROS",
//       "NIVA",
//       "NIVA 2121",
//       "NIVA 21213",
//       "NIVA 21213 1.7",
//       "NIVA 21214 1.7 I",
//       "NIVA 21217",
//       "NIVA 21217 1.6",
//       "SAMARA",
//       "SAMARA",
//       "SAMARA 21083 1.5I",
//       "SAMARA 21090",
//       "SAMARA 21093 1.5",
//       "SAMARA 21093 1.5 I",
//       "SAMARA 21093 1.5 I",
//       "SAMARA 21099",
//       "SAMARA 21099 1.5",
//       "SAMARA 21099 1.5 I"
//     ],
//     "LANCER": ["LANCER"],
//     "LAND ROVER": [
//       "110",
//       "110 COUNTY STATION",
//       "DEFENDER",
//       "DEFENDER 110 2.5 TD",
//       "DEFENDER 110 D",
//       "DEFENDER 110 S.WAGON 4X4",
//       "DEFENDER 110 S.WAGON 4X4",
//       "DEFENDER 110 SW 2.2",
//       "DEFENDER 110 SW 2.4",
//       "DEFENDER 110 SW 2.4 ALLOY",
//       "DEFENDER 110 SW 2.4 S",
//       "DEFENDER 110 TD5 CREW",
//       "DEFENDER 110 TD5 S.",
//       "DEFENDER 110 TD5 STATION",
//       "DEFENDER 110 TD5 STATION",
//       "DEFENDER 110 TDI",
//       "DEFENDER 110 TDI",
//       "DEFENDER 110 TDI",
//       "DEFENDER 110 TDI S.WAGON",
//       "DEFENDER 110 TDI SW",
//       "DEFENDER 110 TOMB RAIDER",
//       "DEFENDER 130",
//       "DEFENDER 130",
//       "DEFENDER 90",
//       "DEFENDER 90",
//       "DEFENDER 90 STATION",
//       "DEFENDER 90 TD5",
//       "DEFENDER 90 TD5 HARD TOP",
//       "DEFENDER 90 TDI",
//       "DEFENDER 90 TDI",
//       "DEFENDER 90 TDI S.WAGON",
//       "DEFENDER 90 TDI SOFT TOP",
//       "DEFENDER 90 TDI STATION",
//       "DEFENDER 90 V8",
//       "DEFENDER HARD TOP 90 TDI",
//       "DEFENDER HARD TOP 90 TDI",
//       "DEFENDER PICK UP 110 TDI",
//       "DEFENDER STATION WAGON",
//       "DEFENDER STATION WAGON",
//       "DEFENDER TD5",
//       "DISCOVERY",
//       "DISCOVERY 3 2.7 TDV6 HSE",
//       "DISCOVERY 3 2.7 TDV6 SE",
//       "DISCOVERY 3 V8",
//       "DISCOVERY 4 2.7 TD V6 SE",
//       "DISCOVERY 4 2.7 TDV6 HSE",
//       "DISCOVERY 4 3.0 SDV6 HSE",
//       "DISCOVERY 4 3.0 TD V6 HSE",
//       "DISCOVERY HSE SI6",
//       "DISCOVERY HSE TD6",
//       "DISCOVERY LR3 SE V8",
//       "DISCOVERY SE",
//       "DISCOVERY SE7",
//       "DISCOVERY SPORT HSE SD4",
//       "DISCOVERY SPORT HSE SI4",
//       "DISCOVERY SPORT SE SI4",
//       "DISCOVERY SPORT SI4",
//       "DISCOVERY TD5",
//       "DISCOVERY TD5 HSE",
//       "DISCOVERY TDI",
//       "DISCOVERY TDI ES",
//       "DISCOVERY TDI XS",
//       "DISCOVERY TDI/V8",
//       "DISCOVERY V8",
//       "DISCOVERY V8 ES",
//       "DISCOVERY V8 XS",
//       "FREELANDER 1.8",
//       "FREELANDER 1.8 ES",
//       "FREELANDER 1.8 S",
//       "FREELANDER 1.8 XEI",
//       "FREELANDER 1.8L 3P",
//       "FREELANDER 2 2.2 TD4 S AT",
//       "FREELANDER 2 2.2 TD4 S MT",
//       "FREELANDER 2 2.2 TD4 SE AT",
//       "FREELANDER 2 3.2 I6 HSE",
//       "FREELANDER 2 3.2 I6 S AT",
//       "FREELANDER 2 I6 HSE AT",
//       "FREELANDER 2 SE TD4 MT",
//       "FREELANDER 2 SI4 2.0 S",
//       "FREELANDER 2 SI4 HSE",
//       "FREELANDER 2 SI4 SE",
//       "FREELANDER 2 TD4 HSE AT",
//       "FREELANDER 2 TD4 SE AT",
//       "FREELANDER 2.0 TD4 - 5",
//       "FREELANDER 2.0 TD4 HARD",
//       "FREELANDER 2.0 TD4 SOFT",
//       "FREELANDER 2.0 XEDI (5",
//       "FREELANDER 2.0D",
//       "FREELANDER TD4",
//       "FREELANDER V6 - 5 PUERTAS",
//       "FREELANDER V6 SE",
//       "LR3 HSE",
//       "LR3 V8 SE",
//       "RANGE ROVER",
//       "RANGE ROVER 2.5 DIESEL DSE",
//       "RANGE ROVER 3.6 HSE TDV8",
//       "RANGE ROVER 4.0 SE",
//       "RANGE ROVER 4.2 V8",
//       "RANGE ROVER 4.4 TDV8 HSE",
//       "RANGE ROVER 4.6 HSE",
//       "RANGE ROVER 4.6 VOGUE",
//       "RANGE ROVER COUNTY LWB",
//       "RANGE ROVER EVOQUE",
//       "RANGE ROVER EVOQUE HSE",
//       "RANGE ROVER EVOQUE P300",
//       "RANGE ROVER EVOQUE P300",
//       "RANGE ROVER EVOQUE SD4",
//       "RANGE ROVER EVOQUE SI4",
//       "RANGE ROVER HSE",
//       "RANGE ROVER SDE",
//       "RANGE ROVER SPORT 2.7",
//       "RANGE ROVER SPORT 3.0",
//       "RANGE ROVER SPORT 3.0",
//       "RANGE ROVER SPORT 3.0",
//       "RANGE ROVER SPORT 3.0 V6",
//       "RANGE ROVER SPORT 3.6",
//       "RANGE ROVER SPORT 4.2 V8",
//       "RANGE ROVER SPORT 4.4L V8",
//       "RANGE ROVER SPORT 5.0 V8",
//       "RANGE ROVER SPORT HSE",
//       "RANGE ROVER SPORT TDV6",
//       "RANGE ROVER SPORT V8 5.0",
//       "RANGE ROVER SPORT V8 5.0",
//       "RANGE ROVER SPORT V8 5.0",
//       "RANGE ROVER TD",
//       "RANGE ROVER TD6",
//       "RANGE ROVER TDI",
//       "RANGE ROVER TDV8",
//       "RANGE ROVER V8",
//       "RANGE ROVER V8 4.0",
//       "RANGE ROVER VELAR D240 S",
//       "RANGE ROVER VELAR P250 R-",
//       "RANGE ROVER VELAR P250 S",
//       "RANGE ROVER VELAR P300 S",
//       "RANGE ROVER VELAR P380 R-",
//       "RANGE ROVER VOGUE HSE TD",
//       "RANGE ROVER VOGUE SE",
//       "RANGE ROVER VOGUE",
//       "RANGE ROVER VOGUE TD V8",
//       "RANGER RO",
//       "DEFENDER 110 TD5"
//     ],
//     "LEGNANO": [
//       "CAPRI",
//       "IMOLA",
//       "MILANO",
//       "MILANO SPRINT",
//       "MODENA",
//       "MONZA",
//       "MONZA",
//       "VERONA",
//       "VERONA",
//       "YG110-2"
//     ],
//     "LEXUS": [
//       "ES 300",
//       "ES 300H LUXURY",
//       "GS 300",
//       "GS 350 F-SPORT",
//       "GS 450H F-SPORT",
//       "GS 450H LUXURY",
//       "GS300",
//       "IS 200",
//       "IS 300",
//       "IS 300 F-SPORT",
//       "IS 300H LUXURY",
//       "LC 500",
//       "LS 430",
//       "LS 500H EXECUTIVE",
//       "LS 500H F-SPORT",
//       "LS-400",
//       "LX 570",
//       "NX 300 F-SPORT",
//       "NX 300H LUXURY",
//       "NX 350 F-SPORT ML",
//       "NX 350H LUXURY",
//       "RC 350 F-SPORT",
//       "RX 330",
//       "RX 350 F-SPORT",
//       "RX 350 F-SPORT+ ML BGM",
//       "RX 450H F-SPORT",
//       "RX 450H LUXURY",
//       "RX 500H F-SPORT+ BGM",
//       "RX 500H F-SPORT+ ML",
//       "RX 500H F-SPORT+ ML BGM",
//       "UX 250H",
//       "UX 250H DYNAMIC",
//       "UX 250H F-SPORT",
//       "GS 350",
//       "GS 450H"
//     ],
//     "LIFAN": [
//       "1022 1.3",
//       "FOISON",
//       "FOISON BOX",
//       "FOISON BOX",
//       "FOISON BOX (CARROZADA)",
//       "FOISON CARGO",
//       "KP200M",
//       "KP200T",
//       "KP350",
//       "KPV150",
//       "LF125",
//       "LF150",
//       "LF150T",
//       "LF5021XXY",
//       "M7 2.0 VVT",
//       "MYWAY",
//       "MYWAY 1.8",
//       "MYWAY 1.8 VVT",
//       "V16",
//       "X50 1.5 VVT",
//       "X50 1.5 VVT TX",
//       "X60 1.8 VVT",
//       "X70",
//       "X70",
//       "X70 2.0 VVT",
//       "XP200"
//     ],
//     "LOTUS": [
//       "ELISE CUP 250",
//       "ELISE SPORT",
//       "ELISE SPORT 220",
//       "EVORA 400",
//       "EVORA SPORT 410",
//       "EXIGE 350 ROADSTER",
//       "EXIGE SPORT 350",
//       "EXIGE SPORT 350 ROADSTER",
//       "EXIGE SPORT 380",
//       "EXIGE SPORT 380 ROADSTER"
//     ],
//     "MACK": [
//       "CT 713",
//       "CV713",
//       "CV713 GRANITE",
//       "GRANITE GU813E",
//       "GRANITE GU813E",
//       "GRANITE GU813E",
//       "GU813 E",
//       "GU813 E",
//       "RD688S",
//       "RD688S",
//       "RD688S",
//       "RD688SX",
//       "RD688SX"
//     ],
//     "MARUTI": ["800 MPI", "ALTO GL 1.0", "ALTO GL 1.0"],
//     "MARUTI SUZUKI": ["800"],
//     "MASERATI": [
//       "3200 GT",
//       "3500 GT",
//       "3500 GT",
//       "3500 GTI",
//       "3500 GTI SEBRING",
//       "GHIBLI",
//       "GHIBLI S",
//       "GHIBLI S Q4",
//       "GHIBLI S Q4 GRANLUSSO",
//       "GHIBLI S Q4 GRANSPORT",
//       "GRAN TURISMO",
//       "LEVANTE",
//       "LEVANTE GRANLUSSO",
//       "LEVANTE GRANSPORT",
//       "LEVANTE GTS",
//       "LEVANTE S",
//       "LEVANTE S GRANLUSSO",
//       "LEVANTE TROFEO",
//       "QUATROPORTE V8",
//       "QUATTROPORTE",
//       "QUATTROPORTE GTS",
//       "QUATTROPORTE S",
//       "QUATTROPORTE S Q4",
//       "QUATTROPORTE SPORT GT",
//       "SPYDER CAMBIOCORSA"
//     ],
//     "MAVERICK": [
//       "BIG FORCE 250",
//       "BLACK STAR",
//       "CROSS",
//       "FOX",
//       "GO",
//       "HALLEY",
//       "MA 110-2",
//       "MA110-3",
//       "MA110-A",
//       "MA125-12",
//       "MA125-2",
//       "MA125-5",
//       "MA125-7",
//       "MA125-7A",
//       "MA125-GY",
//       "MA125-T6",
//       "MA150-12",
//       "MA150-13",
//       "MA200-13",
//       "MA200-GY",
//       "MA250",
//       "MA70",
//       "MA70-D",
//       "OMEGA",
//       "PANTER",
//       "SCAPE 200",
//       "SPORT 110",
//       "STREET",
//       "TOP",
//       "TRY",
//       "XRT",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES",
//       "MOTORCYCLES"
//     ],
//     "MAXUS": ["DUNESTAR 400"],
//     "MAZDA": [
//       "3",
//       "3",
//       "323",
//       "323",
//       "323 2.0 LX DIESEL",
//       "323 BB4S",
//       "323 BB4S 16 VALVE GLX",
//       "323 BC8E F GLX",
//       "323 BC8F F GLX",
//       "323 BC9B F GLX",
//       "323 BC9B F GLX",
//       "323 BC9C F GLX",
//       "323 BC9C LCK GLX",
//       "323 BK2N GLX",
//       "323 BK2R GLX",
//       "323 F",
//       "323 F GLX",
//       "323 F GLX",
//       "323 GLX",
//       "323 GLX",
//       "323 HS",
//       "323 LX",
//       "323 PROTEGE",
//       "323 SE",
//       "323 SE",
//       "323F (BC9B LBJ) GLX",
//       "323F (BG5C LAQ) GLX",
//       "323F BC8W 1.3L",
//       "323F BC9C LCK GLX",
//       "323F BD6K DIESEL",
//       "323F BG5C LBH",
//       "323F BG5C LBH GLX",
//       "323F BG5C LBJ",
//       "323F BG5D GLX B",
//       "323F BG5D LCA",
//       "323F BG5D LCA GLX",
//       "323F BG5D LCR",
//       "323F BG5F LAP",
//       "323F BG5G GLX",
//       "323F BG5G LA4",
//       "323F BG5G LA4 GLX",
//       "323F BH4C DIESEL",
//       "323F BH4C GLX DIESEL",
//       "323F BK3D",
//       "323F BK3D GLX",
//       "323F BK3F GLX",
//       "626",
//       "626",
//       "626",
//       "626 2.0I",
//       "626 2.0L AT GLX",
//       "626 DX",
//       "626 ES",
//       "626 GB2C",
//       "626 GB2L",
//       "626 GB3A SW DIESEL",
//       "626 GC5V DIESEL",
//       "626 GC6N HB DIESEL",
//       "626 GC6N HB DIESEL",
//       "626 GC8T HB GLX",
//       "626 GC8V HB AT",
//       "626 GC8W GLX",
//       "626 GE6L",
//       "626 GE6L GLX",
//       "626 GE6N",
//       "626 GE6N GLX",
//       "626 GE6W",
//       "626 GE6W GLX",
//       "626 GE7B",
//       "626 GE7B GLX",
//       "626 GF3H GLX SW",
//       "626 GF3K GLX SW",
//       "626 GLX",
//       "626 GLX",
//       "626 GLX",
//       "626 GLX",
//       "626 GLX 2.2 I GB2L",
//       "626 GLX GC9A",
//       "626 LX",
//       "626 LX 2.0 D",
//       "626 LX A",
//       "626 LXA",
//       "929",
//       "929 HG56",
//       "929 L RURAL",
//       "929 LAUT",
//       "B 2200",
//       "B 2600 SE5",
//       "B2000/2200",
//       "B2200 DX (CABINA DOBLE)",
//       "B2200 I",
//       "B2200 UF27",
//       "B2200 UF32",
//       "B2200 UG83 SDX",
//       "B2500",
//       "B2500 4X4 (DOBLE CABINA)",
//       "B2500 DX (UG84)",
//       "B2500 DX 4X2 (CABINA",
//       "B2500 DX 4X2 (CABINA",
//       "B2500 DX 4X4 (CABINA",
//       "B2500 DX 4X4 (CABINA",
//       "B2500 SDX (UG93)",
//       "B2500 SDX 4X2 (CABINA",
//       "B2500 SDX 4X4 (CABINA",
//       "B2500 STD (UG91)",
//       "B2500 STD (UG92)",
//       "B2500 UG81",
//       "B2500 UG81 DX",
//       "B2500 UG81 DX STD",
//       "B2500 UG82 DX",
//       "B2500 UG82 DX STD",
//       "B2500 UG91 DX (CABINA",
//       "B2500 UG91 DX STD (CABINA",
//       "B2500 UG92 DX (CABINA",
//       "B2500 UG92 DX STD (CABINA",
//       "B2500 UG93 SDX (CABINA",
//       "B2500 UK77 SDX",
//       "B2500 UK77 STD",
//       "B2500D SDX",
//       "B2500DUG92 (CABINA",
//       "B2900 CABINA DOBLE",
//       "B2900 DX 4X2 (CABINA",
//       "B2900 DX 4X4 (CABINA",
//       "B2900 DX 4X4 (CABINA",
//       "B2900 SDX 4X4 (CABINA",
//       "E2200/PICKUP",
//       "MAZDA 2",
//       "MIATA MX5",
//       "MIATA MX5",
//       "MPV",
//       "MPV",
//       "MPV",
//       "MPV - V6",
//       "MPV DX",
//       "MPV ES",
//       "MPV GLX",
//       "MPV LC41",
//       "MPV LC44",
//       "MPV LX",
//       "MPV LXE",
//       "MVP",
//       "MX3 BS",
//       "MX3 GS V6",
//       "MX3 V6 EA91",
//       "MX5",
//       "MX5",
//       "MX-5 MIATA",
//       "MX-5 MIATA NC22",
//       "MX5 NB86",
//       "MX6",
//       "MX6 GC8N",
//       "MX6 GC8P",
//       "MX6 LS",
//       "PRIMACY 2.0 DVTD",
//       "PROTEGE",
//       "PROTEGE DX",
//       "PROTEGE LX",
//       "PROTEGE PRO ES P",
//       "PROTEGE/PRO ES P",
//       "RX7",
//       "5 GT 2.3"
//     ],
//     "MCLAREN": ["570S"],
//     "MERCEDES BENZ": [
//       "05 1114/45",
//       "1019 AF",
//       "1117 D",
//       "1117 D",
//       "1214",
//       "1214/45",
//       "1214/48",
//       "1214C",
//       "1215/48",
//       "1215C",
//       "1222",
//       "1315 C/48",
//       "1315 C/48",
//       "1317",
//       "1317/37",
//       "1414",
//       "1417",
//       "1417C II",
//       "1418",
//       "1418 R",
//       "1418 R",
//       "1419",
//       "1518",
//       "1614",
//       "1618",
//       "1620",
//       "1622",
//       "1622",
//       "1623",
//       "1624",
//       "1625",
//       "1625 S",
//       "1625L II",
//       "1633",
//       "1635",
//       "1718",
//       "1718",
//       "1718 K",
//       "1718A",
//       "1718M",
//       "1719",
//       "1720",
//       "1720A",
//       "1720A",
//       "1720A 4X4",
//       "1720K",
//       "1721S/32",
//       "1722",
//       "1722",
//       "1722",
//       "1722S/32",
//       "1723 S/32",
//       "1728",
//       "1824",
//       "1834",
//       "1840 S",
//       "1919 45C",
//       "1919 45L",
//       "1919K",
//       "1923",
//       "1928",
//       "1928 AS 4X4",
//       "1933 LS",
//       "1935",
//       "1935",
//       "1935/1938/1941 OTROS",
//       "1935/1938/41",
//       "1935AS",
//       "1936 AK",
//       "1938",
//       "1938",
//       "1938 S",
//       "1941",
//       "1944S",
//       "2038",
//       "2222B",
//       "2224",
//       "2224B",
//       "2225 B",
//       "2225 B 6X4",
//       "2235",
//       "230C",
//       "2318",
//       "2318/2325",
//       "2320 L6X2/4",
//       "2324",
//       "2325",
//       "2423 B",
//       "2423 K",
//       "2423 K",
//       "2426",
//       "2428",
//       "2528 6X4",
//       "2528 6X4/4",
//       "2540",
//       "2626",
//       "2628",
//       "2631",
//       "2631 B 6X4",
//       "2632K",
//       "2636",
//       "2636",
//       "2638 AK",
//       "2640S 6X4",
//       "2726 B",
//       "2726 K",
//       "290 E",
//       "300 TE",
//       "313 CDI/F 4025",
//       "320 SW",
//       "3228",
//       "3229",
//       "3233 B",
//       "3234 B",
//       "3328I",
//       "3535 8X4",
//       "412 DF",
//       "412D",
//       "413 CDI/F 3550",
//       "450 SEL",
//       "500 SLC",
//       "693 VERSION 1720 36",
//       "693 VERSION 1720 48",
//       "693 VERSION 1720 48",
//       "693 VERSION 1720/36",
//       "693 VERSION 1720/48",
//       "695 VERSION 1735 45",
//       "695 VERSION 1735 51",
//       "709",
//       "709D/4250/3700",
//       "710",
//       "710",
//       "711D",
//       "712C",
//       "813",
//       "912",
//       "912",
//       "912/913",
//       "912/913 OTROS",
//       "912-42",
//       "914",
//       "914/42,5",
//       "914/42,5",
//       "914C",
//       "958 ATEGO 1720 36",
//       "958 ATEGO 1720 42",
//       "958 ATEGO 1720 48",
//       "958 ATEGO 1726 36",
//       "958 ATEGO 1726 42",
//       "958 ATEGO 1726 42 CE",
//       "958 ATEGO 1726 S 36 CE",
//       "958 ATEGO 1729 42 CD TE",
//       "958 ATEGO 1729 S 36 CD TE",
//       "A 170 CDI ELEGANCE",
//       "A 190 AVANTGARDE",
//       "A 190 ELEGANCE",
//       "A 200 PROGRESSIVE",
//       "A 200 STYLE",
//       "A140 CLASSIC",
//       "A150",
//       "A160",
//       "A160",
//       "A160 CLASSIC",
//       "A160 ELEGANCE",
//       "A170 CDI",
//       "A170 CDI ESP ELEGANCE",
//       "A200",
//       "A200 BLUE EFFICIENCY",
//       "A200 CDI ELEGANCE",
//       "A200 PROGRESSIVE SEDAN",
//       "A200 STYLE SEDAN",
//       "A250",
//       "A250 AMG-LINE",
//       "A250 AMG-LINE SEDAN",
//       "A250 BLUE EFFICIENCY",
//       "A250 BLUE EFFICIENCY SPORT",
//       "A250 SPORT",
//       "A45 AMG 4MATIC",
//       "ACCELO 1016",
//       "ACCELO 1016 / 39",
//       "ACCELO 1016 / 39",
//       "ACCELO 815",
//       "ACCELO 815",
//       "ACCELO 815",
//       "ACCELO 915C",
//       "ACTROS 1841 LS",
//       "ACTROS 1846 LS",
//       "ACTROS 2041",
//       "ACTROS 2041 S",
//       "ACTROS 2042 L",
//       "ACTROS 2042 LS",
//       "ACTROS 2044 AK",
//       "ACTROS 2044 S",
//       "ACTROS 2045 LS",
//       "ACTROS 2046 S",
//       "ACTROS 2048 LS",
//       "ACTROS 2548 LS",
//       "ACTROS 2636",
//       "ACTROS 2636 LS",
//       "ACTROS 2645 LS",
//       "ACTROS 2646 LS",
//       "ACTROS 2646 LS",
//       "ACTROS 2646 S",
//       "ACTROS 2651 LS",
//       "ACTROS 2655 LS",
//       "ACTROS 2663 LS",
//       "ACTROS 3336",
//       "ACTROS 3336K",
//       "ACTROS 3341",
//       "ACTROS 3341 S",
//       "ACTROS 3341K",
//       "ACTROS 3342 S",
//       "ACTROS 3344 AK",
//       "ACTROS 3344 B",
//       "ACTROS 3344 K",
//       "ACTROS 3344 S",
//       "ACTROS 3344 S",
//       "ACTROS 3351 LS",
//       "ACTROS 3353",
//       "ACTROS 4141B",
//       "ACTROS 4144 K",
//       "ACTROS 4844K",
//       "ACTROS 4844K",
//       "ACTROS2042",
//       "AMG GT S",
//       "AROCS 2051 AK",
//       "AROCS 3336 A",
//       "AROCS 3342K",
//       "AROCS 4136B",
//       "AROCS 4845K",
//       "AROCS 4845K",
//       "ATEGO 1315",
//       "ATEGO 1418",
//       "ATEGO 1419",
//       "ATEGO 1517A 4X4",
//       "ATEGO 1720",
//       "ATEGO 1721",
//       "ATEGO 1721",
//       "ATEGO 1721 36",
//       "ATEGO 1721 36",
//       "ATEGO 1721 48",
//       "ATEGO 1721 48",
//       "ATEGO 1725",
//       "ATEGO 1725",
//       "ATEGO 1725 A",
//       "ATEGO 1725 S",
//       "ATEGO 1725 S",
//       "ATEGO 1725/48",
//       "ATEGO 1726",
//       "ATEGO 1726",
//       "ATEGO 1726 42 CE",
//       "ATEGO 1726 S",
//       "ATEGO 1726 S",
//       "ATEGO 1729",
//       "ATEGO 1729 RECOLECTOR",
//       "ATEGO 1729 RECOLECTOR",
//       "ATEGO 1729 S",
//       "ATEGO 2425",
//       "ATEGO 2425",
//       "ATEGO 2426",
//       "ATEGO 2426",
//       "ATEGO 2430",
//       "ATEGO 2730B",
//       "ATEGO 2730K",
//       "ATRON 1635 S",
//       "ATRON 1720",
//       "AXOR 1933",
//       "AXOR 1933",
//       "AXOR 1933",
//       "AXOR 1933 S",
//       "AXOR 1933 S",
//       "AXOR 1933LS",
//       "AXOR 1933LS",
//       "AXOR 2035 S",
//       "AXOR 2035 S",
//       "AXOR 2036",
//       "AXOR 2036 ROBUST",
//       "AXOR 2036 STARKER",
//       "AXOR 2036S",
//       "AXOR 2036S ROBUST",
//       "AXOR 2036S STARKER",
//       "AXOR 2040 S",
//       "AXOR 2040 S",
//       "AXOR 2041",
//       "AXOR 2041S",
//       "AXOR 2044 S",
//       "AXOR 2044 S",
//       "AXOR 2533 LS",
//       "AXOR 2535S",
//       "AXOR 2536 LS STARKER",
//       "AXOR 2540 S",
//       "AXOR 2541 LS",
//       "AXOR 2544 LS",
//       "AXOR 2640S",
//       "AXOR 2831",
//       "AXOR 2831 B",
//       "AXOR 2831 K",
//       "AXOR 3131",
//       "AXOR 3131B",
//       "AXOR 3131K",
//       "AXOR 3340",
//       "AXOR 3340",
//       "AXOR 3340",
//       "AXOR 3340K",
//       "AXOR 3340S",
//       "AXOR 3344S",
//       "B170",
//       "B180",
//       "B180 CDI",
//       "B180 CDI",
//       "B200",
//       "B200 AUTOMATICO CITY",
//       "B200 CDI",
//       "B200 PROGRESSIVE",
//       "B200 STYLE",
//       "B200 TURBO",
//       "BMO 368 VERSION 1621L55",
//       "BMO 368 VERSION 1621L55",
//       "BMO 368 VERSION 1721L62",
//       "BMO 384 VERSION 1621 CA",
//       "BMO 384 VERSION 1621 CA",
//       "BMO 384 VERSION 1621 CM",
//       "BMO 384 VERSION 1621 CM",
//       "BMO 384 VERSION 1722 59",
//       "BMO 693 VERSION ATRON",
//       "BMO 693 VERSION ATRON",
//       "BMO 695 VERSION 1634 S 45",
//       "BMO 695 VERSION ATRON",
//       "BMO 695 VERSION ATRON",
//       "BMO 695 VERSION ATRON",
//       "C 180",
//       "C 200",
//       "C 200",
//       "C 200 DIESEL",
//       "C 200 ELEGANCE",
//       "C 200 KOMPRESSOR",
//       "C 200 KOMPRESSOR",
//       "C 200 KOMPRESSOR",
//       "C 200 KOMPRESSOR",
//       "C 200 KOMPRESSOR",
//       "C 200 KOMPRESSOR SPORT",
//       "C 200 KOMPRESSOR SPORT",
//       "C 200 KOMPRESSOR",
//       "C 220 CDI ELEGANCE",
//       "C 220 CDI SPORTCOUPE",
//       "C 220 DIESEL",
//       "C 230",
//       "C 230 SPORTCOUPE",
//       "C 240",
//       "C 240",
//       "C 250 DT ELEGANCE",
//       "C 250 TURBO DIESEL",
//       "C 250 TURBO DIESEL",
//       "C 270 CDI",
//       "C 270 CDI ELEGANCE",
//       "C 280",
//       "C 280",
//       "C 280 ELEGANCE",
//       "C 280 SPORT",
//       "C 280 T",
//       "C 300",
//       "C 300 AMG LINE",
//       "C 300 AMG-LINE",
//       "C 300 CITY COUPE",
//       "C 300 ELEGANCE",
//       "C 300 MHEV",
//       "C 320",
//       "C 320 CDI AVANTGARDE",
//       "C 320 CDI ELEGANCE",
//       "C180 KOMPRESSOR",
//       "C200 AVANTGARD",
//       "C200 AVANTGARDE",
//       "C200 BLUE EFFICIENCY",
//       "C200 BLUE EFFICIENCY",
//       "C200 CDI",
//       "C200 CGI",
//       "C200 CGI AUTOMATICO",
//       "C200 CGI BLUE EFFICIENCY",
//       "C200 EDITION C",
//       "C200 KOMPRESSOR",
//       "C200 KOMPRESSOR",
//       "C200 KOMPRESSOR",
//       "C200 KOMPRESSOR SPORT",
//       "C220 CDI",
//       "C220 CDI",
//       "C220 CDI",
//       "C220 CDI AVANTGARDE",
//       "C220 CDI BLUE EFFICIENCY",
//       "C220 CDI ELEGANCE",
//       "C220 CDI SPORT EDITION",
//       "C220 CDI TD CLASSIC",
//       "C220 TURBO DIESEL",
//       "C230 KOMPRESSOR",
//       "C230 KOMPRESSOR",
//       "C230 KOMPRESSOR SPORT",
//       "C230 SPORT EDITION",
//       "C230 T",
//       "C230 V6",
//       "C250",
//       "C250",
//       "C250 AMG LINE",
//       "C250 BLUE EFFICIENCY",
//       "C250 BLUE EFFICIENCY",
//       "C250 CGI BLUE EFFICIENCY",
//       "C250 EDITION C",
//       "C250 TURBO DIESEL",
//       "C280 AVANTGARDE",
//       "C300 AVANTGARDE",
//       "C300 CABRIO",
//       "C320 CDI",
//       "C320W",
//       "C350",
//       "C350 AVANTGARDE",
//       "C350 BLUE EFFICIENCY",
//       "C350 ELEGANCE",
//       "C400 4 MATIC",
//       "C400 4 MATIC COUPE",
//       "C43 AMG",
//       "C55 AMG",
//       "C63 AMG",
//       "C63 AMG",
//       "CL 500",
//       "CL 600",
//       "CLA 200",
//       "CLA 200 URBAN",
//       "CLA 250",
//       "CLA 45 AMG 4MATIC",
//       "CLC 200 KOMPRESSOR",
//       "CLC 230",
//       "CLC 230",
//       "CLC 250",
//       "CLC 350",
//       "CLK 2.3 KOMPRESSOR",
//       "CLK 230 KOMPRESSOR",
//       "CLK 230 KOMPRESSOR",
//       "CLK 230 KOMPRESSOR",
//       "CLK 270 CDI",
//       "CLK 320",
//       "CLK 320",
//       "CLK 320",
//       "CLK 320 ELEGANCE",
//       "CLK 320 ELEGANCE",
//       "CLK 320 SPORT",
//       "CLK 350",
//       "CLK 350",
//       "CLK 350 AVANTGARDE",
//       "CLK 350 ELEGANCE",
//       "CLK 350 ELEGANCE",
//       "CLK 500",
//       "CLK 500",
//       "CLK 55 AMG",
//       "CLK 55 AMG",
//       "CLK 55 AMG",
//       "CLK200",
//       "CLK200 KOMPRESSOR",
//       "CLK350 AVANTGARDE",
//       "CLK350 ELEGANCE",
//       "CLK430",
//       "CLK430",
//       "CLK430",
//       "CLK500 AVANTGARDE",
//       "CLK500 ELEGANCE",
//       "CLS 350",
//       "CLS 350",
//       "CLS 350 BLUE EFFICIENCY",
//       "CLS 350 BLUE EFFICIENCY",
//       "CLS 500",
//       "CLS 500",
//       "CLS 63 AMG",
//       "DB 1619",
//       "E 200 CDI",
//       "E 220 D ALL-TERRAIN",
//       "E 240",
//       "E 250",
//       "E 270 CDI",
//       "E 280",
//       "E 280 ELEGANCE",
//       "E 290 TURBO DIESEL",
//       "E 290 TURBO DIESEL",
//       "E 300 ELEGANCE",
//       "E 300 TD",
//       "E 320",
//       "E 320",
//       "E 320 CDI ELEGANCE",
//       "E 320 ELEGANCE",
//       "E 350",
//       "E 350",
//       "E 350 AVANTGARDE",
//       "E 350 AVANTGARDE SPORT",
//       "E 350 BLUE EFFICIENCY",
//       "E 350 ELEGANCE",
//       "E 350 ELEGANCE",
//       "E 430",
//       "E 430",
//       "E 450 4MATIC",
//       "E 450 4MATIC COUPE",
//       "E 500",
//       "E 500",
//       "E 500 ELEGANCE",
//       "E220 CDI AVANTGARDE",
//       "E250 BLUE EFFICIENCY",
//       "E250 CGI BLUE EFFICIENCY",
//       "E300 AVANTGARDE SPORT",
//       "E320 CDI",
//       "E320 CDI",
//       "E320 CDI V6",
//       "E400",
//       "E400 4MATIC",
//       "E400 4MATIC COUPE",
//       "E50",
//       "E500 AVANTGARDE",
//       "E500 AVANTGARDE",
//       "E55 AMG",
//       "E55 AMG AVANTGARDE",
//       "E63 AMG",
//       "E63 AMG S",
//       "G500",
//       "GL 500 4MATIC",
//       "GLA 200",
//       "GLA 200",
//       "GLA 200 PROGRESSIVE",
//       "GLA 250",
//       "GLA 250 4MATIC",
//       "GLB 200 ADVANCE",
//       "GLB 250 4MATIC",
//       "GLC 300 4MATIC",
//       "GLC 300 AMG-LINE",
//       "GLC 300 COUPE AMG-LINE",
//       "GLC 300OFF-ROAD",
//       "GLC 350 E 4MATIC",
//       "GLE 400 4MATIC",
//       "GLE 400 4MATIC COUPE",
//       "GLE 450 4MATIC (MHEV)",
//       "GLE 450 4MATIC",
//       "GLE 500 GUARD",
//       "GLK 280 4MATIC",
//       "GLK300 4MATIC",
//       "GLS 500",
//       "L 1114",
//       "L 1214",
//       "L 1215/42",
//       "L 1215/42 L 1215/48",
//       "L 1215/48",
//       "L 1217",
//       "L 1218 R",
//       "L 1218/48",
//       "L 1517/48",
//       "L 1619/45",
//       "L 1620/18/17",
//       "L 1620/45",
//       "L 1620/51",
//       "L 1622/51",
//       "L 1623",
//       "L 1633",
//       "L 1633",
//       "L 1941",
//       "L 1941",
//       "L 2221/54",
//       "L 2325/51",
//       "L 2638",
//       "L 710",
//       "L 710",
//       "L 710/37 E1",
//       "L 710/42 E1",
//       "L 710/712",
//       "L 710D/37 L 710/42 CAM",
//       "L 712",
//       "L 911",
//       "L 912",
//       "L 912",
//       "L 912-42.5",
//       "L 912-42.5",
//       "L 912-42.5",
//       "L 913",
//       "L1017A",
//       "L-1114",
//       "L1214/42/48",
//       "L1214/48",
//       "L-1215",
//       "L-1218",
//       "L-1218 EL",
//       "L-1318",
//       "L-1414",
//       "L-1418",
//       "L1418R",
//       "L-1618/51",
//       "L-1620",
//       "L-1620",
//       "L1620/51",
//       "L1622",
//       "L1622",
//       "L-1622",
//       "L1622/51",
//       "L1622/51 LS1622/45",
//       "L-1624",
//       "L-1624",
//       "L1632",
//       "L1633",
//       "L1634",
//       "L1634",
//       "L-1926",
//       "L1938",
//       "L-1938",
//       "L-1938",
//       "L-1938/1941",
//       "L1938/56",
//       "L1938/56",
//       "L2624 6X4",
//       "L-2635",
//       "L-712/42.5",
//       "L-913D/42 CAM",
//       "L914/42",
//       "L914/42.5",
//       "L914/42.5",
//       "L914/42/42.5",
//       "LA 1418",
//       "LA-1418/51",
//       "LA-1418/51",
//       "LA-1418/51",
//       "LAK 1418",
//       "LB 2318",
//       "LB 2318 (6X4)",
//       "LF-1113B",
//       "LK 1215/42",
//       "LK 1218 EL",
//       "LK 1218 R",
//       "LK 1218/42",
//       "LK 1418",
//       "LK 1618/42",
//       "LK 1620/42",
//       "LK 1620/42",
//       "LK 2318/2325",
//       "LK 2325",
//       "LK 2635 6X4",
//       "LK 2638",
//       "LK-1318",
//       "LK1418R",
//       "LK1617/39",
//       "LK1620",
//       "LK-2318",
//       "LK-2318",
//       "LK-2318 (6X4)",
//       "LO 916",
//       "LO-814",
//       "LO-915",
//       "LP 1113",
//       "LP 1215/48",
//       "LP 1420/48",
//       "LP 1722/32 S",
//       "LP 913",
//       "LPK808",
//       "LS 1622/45",
//       "LS 1623",
//       "LS 1632",
//       "LS 1632/45",
//       "LS 1633/45",
//       "LS 1935",
//       "LS 1938",
//       "LS 1938/46",
//       "LS 1941/1938",
//       "LS 2638",
//       "LS1622",
//       "LS1622",
//       "LS-1622",
//       "LS1622/45",
//       "LS1633",
//       "LS1633",
//       "LS-1633",
//       "LS-1634",
//       "LS-1634",
//       "LS1938",
//       "LS1938",
//       "LS-1941",
//       "LS-2635",
//       "MB 140 D 2.9",
//       "MB-160",
//       "MERCEDES AMG A 35",
//       "MERCEDES-AMG A 35 SEDAN",
//       "MERCEDES-AMG A 45 S",
//       "MERCEDES-AMG C 43",
//       "MERCEDES-AMG C 43 COUPE",
//       "MERCEDES-AMG C 63 S",
//       "MERCEDES-AMG C63 S",
//       "MERCEDES-AMG E 63 S",
//       "MERCEDES-AMG E53 4MATIC",
//       "MERCEDES-AMG GLA 45",
//       "MERCEDES-AMG GLC 43",
//       "MERCEDES-AMG GLC 43",
//       "MERCEDES-AMG GLE 53",
//       "MERCEDES-AMG GLE 63 S",
//       "MERCEDES-AMG GLE COUPE",
//       "MERCEDES-AMG GT R",
//       "MERCEDES-AMG GT4 63S",
//       "MERCEDES-AMG GTC",
//       "MERCEDES-AMG SL 63",
//       "MERCEDES-AMG SLC 43",
//       "ML 230",
//       "ML 270 CDI",
//       "ML 280 CDI",
//       "ML 320",
//       "ML 320 CDI",
//       "ML 320 CDI 4MATIC",
//       "ML 350",
//       "ML 350 4MATIC",
//       "ML 350 4MATIC BLUE",
//       "ML 350 CDI 4MATIC",
//       "ML 400 4MATIC",
//       "ML 400 CDI",
//       "ML 430",
//       "ML 500",
//       "ML 500 4MATIC",
//       "ML 63 AMG 4MATIC",
//       "O 500 M",
//       "O 500 U",
//       "O-400 RSD",
//       "O-400 RSD",
//       "O-400 RSE",
//       "O-400 RSE",
//       "O-500 RS",
//       "O-500 RSD",
//       "OC1214",
//       "OF 1621",
//       "OF 1721",
//       "OF1722",
//       "S 300 TURBO DIESEL",
//       "S 320",
//       "S 320 CDI",
//       "S 320 L",
//       "S 400 CDI",
//       "S 500 BLUE EFFICIENCY",
//       "S500",
//       "S500",
//       "S500 (VERSION LARGA)",
//       "S550",
//       "S65 AMG V12 BITURBO",
//       "SL 320",
//       "SL 320",
//       "SL 320",
//       "SL 320 ROADSTER",
//       "SL 350",
//       "SL 350 ROADSTER",
//       "SL 500",
//       "SL 500",
//       "SL 500 AMG",
//       "SL 500 ROADSTER",
//       "SL 55 AMG",
//       "SL 55 AMG",
//       "SL 55 AMG ROADSTER",
//       "SL 55 AMG V8 KOMPRESSOR",
//       "SL 600",
//       "SL 600",
//       "SL 600 V12",
//       "SL 63 AMG V8 BITURBO",
//       "SL 65 AMG",
//       "SL 65 AMG BLACK SERIES",
//       "SL 65 AMG V12 BITURBO",
//       "SL-320 R",
//       "SLK 200",
//       "SLK 200",
//       "SLK 200 KOMPRESSOR",
//       "SLK 230",
//       "SLK 230",
//       "SLK 230 COMPRESOR",
//       "SLK 230 KOMPRESSOR",
//       "SLK 230 KOMPRESSOR",
//       "SLK 230 ROADSTER",
//       "SLK 250",
//       "SLK 250 ROADSTER",
//       "SLK 32 AMG",
//       "SLK 320",
//       "SLK 350",
//       "SLK 350",
//       "SLK 350 ROADSTER",
//       "SLK 55 AMG",
//       "SLK 55 AMG ROADSTER",
//       "SLK200 BLUE EFFICIENCY",
//       "SLK200 KOMPRESSOR",
//       "SLK250 BLUE EFFICIENCY",
//       "SLK350 BLUE EFFICIENCY",
//       "SLS AMG",
//       "SPORTCOUPE C230 V6",
//       "SPRINTER 212D",
//       "SPRINTER 308 CDI/F 3000",
//       "SPRINTER 308 CDI/F 3000",
//       "SPRINTER 310 D / C3000",
//       "SPRINTER 310 D / C3550",
//       "SPRINTER 310 D / CH3550",
//       "SPRINTER 310 D / F3000",
//       "SPRINTER 310 D / F3550",
//       "SPRINTER 310 D / P3550",
//       "SPRINTER 310D",
//       "SPRINTER 310D",
//       "SPRINTER 310D",
//       "SPRINTER 311 CDI",
//       "SPRINTER 311 CDI",
//       "SPRINTER 311 CDI",
//       "SPRINTER 311 CDI FURGON",
//       "SPRINTER 311 CDI STREET",
//       "SPRINTER 311 CDI/3000-",
//       "SPRINTER 311 CDI/3000-",
//       "SPRINTER 311 CDI/3000-",
//       "SPRINTER 311 CDI/C 3000",
//       "SPRINTER 311 CDI/C 3000",
//       "SPRINTER 311 CDI/C 3550",
//       "SPRINTER 311 CDI/C 3550",
//       "SPRINTER 311 CDI/CH 350",
//       "SPRINTER 311 CDI/CH 3550",
//       "SPRINTER 311 CDI/F 3000",
//       "SPRINTER 311 CDI/F 3000",
//       "SPRINTER 311 CDI/F 3550",
//       "SPRINTER 311 CDI/F 3550",
//       "SPRINTER 311 CDI/P 3550",
//       "SPRINTER 311CDI/3000-",
//       "SPRINTER 311D",
//       "SPRINTER 311D",
//       "SPRINTER 311D",
//       "SPRINTER 312 D / C3000",
//       "SPRINTER 312 D / C3000",
//       "SPRINTER 312 D / C3550",
//       "SPRINTER 312 D / C3550",
//       "SPRINTER 312 D / CH3550",
//       "SPRINTER 312 D / CH3550",
//       "SPRINTER 312 D / F3000",
//       "SPRINTER 312 D / F3000",
//       "SPRINTER 312 D / F3550",
//       "SPRINTER 312 D / F3550",
//       "SPRINTER 312 D / P3550",
//       "SPRINTER 312 D / P3550",
//       "SPRINTER 312D",
//       "SPRINTER 312D",
//       "SPRINTER 313 CDI",
//       "SPRINTER 313 CDI",
//       "SPRINTER 313 CDI",
//       "SPRINTER 313 CDI/3000-",
//       "SPRINTER 313 CDI/3550-",
//       "SPRINTER 313 CDI/3550-",
//       "SPRINTER 313 CDI/3550-",
//       "SPRINTER 313 CDI/C 3000",
//       "SPRINTER 313 CDI/C 3000",
//       "SPRINTER 313 CDI/C 3550",
//       "SPRINTER 313 CDI/C 3550",
//       "SPRINTER 313 CDI/CH 350",
//       "SPRINTER 313 CDI/CH 3550",
//       "SPRINTER 313 CDI/F 3000",
//       "SPRINTER 313 CDI/F 3000",
//       "SPRINTER 313 CDI/F 3550",
//       "SPRINTER 313 CDI/F 3550",
//       "SPRINTER 313 CDI/F 4025",
//       "SPRINTER 313 CDI/P 3550",
//       "SPRINTER 313 CDI/P 3550",
//       "SPRINTER 313 CDI-F 3000 TN",
//       "SPRINTER 313 CDI-F 3550 TE",
//       "SPRINTER 313 CDI-F 3550 TE",
//       "SPRINTER 313 CDI-F 3550 TN",
//       "SPRINTER 313 CDI-F 3550 TN",
//       "SPRINTER 313CDI/3000-",
//       "SPRINTER 313CDI/3000-",
//       "SPRINTER 313CDI/3550-",
//       "SPRINTER 313CDI-3000-",
//       "SPRINTER 314 /C 3000",
//       "SPRINTER 314 /C 3550",
//       "SPRINTER 314 /CH 3550",
//       "SPRINTER 314 /F 3000",
//       "SPRINTER 314 /F 3000",
//       "SPRINTER 314 /F 3550",
//       "SPRINTER 314 /F 4025",
//       "SPRINTER 314 /P 3550",
//       "SPRINTER 316 CDI CHASIS",
//       "SPRINTER 316 CDI FURGON",
//       "SPRINTER 316 CDI FURGON",
//       "SPRINTER 316 CDI FURGON",
//       "SPRINTER 316 CDI FURGON",
//       "SPRINTER 411 CDI/CH 3550",
//       "SPRINTER 411 CDI/CH 4025",
//       "SPRINTER 411 CDI/F 3250",
//       "SPRINTER 411 CDI/F 3250",
//       "SPRINTER 412 D / CH3550",
//       "SPRINTER 412 D / CH3550",
//       "SPRINTER 412 D / CH4025",
//       "SPRINTER 412 D / CH4025",
//       "SPRINTER 412 D / W3550",
//       "SPRINTER 412 D / W3550",
//       "SPRINTER 412 D / W4025",
//       "SPRINTER 412 D / W4025",
//       "SPRINTER 412D",
//       "SPRINTER 412D",
//       "SPRINTER 412D",
//       "SPRINTER 413 CDI/C 4025",
//       "SPRINTER 413 CDI/C 4025",
//       "SPRINTER 413 CDI/CH 3550",
//       "SPRINTER 413 CDI/CH 3550",
//       "SPRINTER 413 CDI/CH 4025",
//       "SPRINTER 413 CDI/CH 4025",
//       "SPRINTER 413 CDI/F 4025",
//       "SPRINTER 413 CDI/F 4025",
//       "SPRINTER 413 CDI/F 4025",
//       "SPRINTER 413 CDI/W 4025",
//       "SPRINTER 413 CDI-C 4025 TE",
//       "SPRINTER 413 CDI-CH 4025",
//       "SPRINTER 414 / CH 3550",
//       "SPRINTER 414 /C 4025",
//       "SPRINTER 414 /CH 3550",
//       "SPRINTER 414 /CH 4025",
//       "SPRINTER 414 /F 4025",
//       "SPRINTER 415 CDI /C 3665",
//       "SPRINTER 415 CDI /CH 3665",
//       "SPRINTER 415 CDI /F 3250",
//       "SPRINTER 415 CDI /F 3665",
//       "SPRINTER 415 CDI /F 4325",
//       "SPRINTER 415 CDI 3665",
//       "SPRINTER 415 CDI/3250-",
//       "SPRINTER 415 CDI/3665-",
//       "SPRINTER 415 CDI/3665-",
//       "SPRINTER 415 CDI-3250-",
//       "SPRINTER 415 CDI-3250-",
//       "SPRINTER 415 CDI-3665-",
//       "SPRINTER 415 CDI-3665-",
//       "SPRINTER 415 CDI-3665-",
//       "SPRINTER 415 CDI-C 3665 TE",
//       "SPRINTER 415 CDI-CH 3665",
//       "SPRINTER 415 CDI-F 3250 TN",
//       "SPRINTER 415 CDI-F 3250 TN",
//       "SPRINTER 415 CDI-F 3665 TE",
//       "SPRINTER 415 CDI-F 3665 TE",
//       "SPRINTER 415 CDI-F 3665 TN",
//       "SPRINTER 415 CDI-F 3665 TN",
//       "SPRINTER 415 CDI-F 4325 TE",
//       "SPRINTER 415 CDI-F 4325 TE",
//       "SPRINTER 416 CDI CHASIS",
//       "SPRINTER 416 CDI FURGON",
//       "SPRINTER 416 CDI FURGON",
//       "SPRINTER 416 CDI FURGON",
//       "SPRINTER 416 CDI",
//       "SPRINTER 416 CDI",
//       "SPRINTER 515 CDI",
//       "SPRINTER 515 CDI",
//       "SPRINTER 515 CDI /CH 4325",
//       "SPRINTER 515 CDI /F 4325",
//       "SPRINTER 515 CDI/C 4325",
//       "SPRINTER 515 CDI-C 4325 TE",
//       "SPRINTER 515 CDI-CH 4325",
//       "SPRINTER 515 CDI-F 4325 TE",
//       "SPRINTER 515 CDI-F 4325 TE",
//       "SPRINTER 515 CDI-F 4325 XL",
//       "SPRINTER 516 CDI CHASIS",
//       "SPRINTER 516 CDI FURGON",
//       "SPRINTER 516 CDI FURGON",
//       "SPRINTER 516 CDI MIDIBUS",
//       "SPRINTER V413 CDI/F 4025",
//       "V 220 CDI",
//       "V 230",
//       "V230 TURBODIESEL",
//       "V250",
//       "VIANO 2.2 CDI",
//       "VIANO 2.2 CDI AMBIENTE",
//       "VIANO 2.2 CDI TREND",
//       "VIANO 2.3",
//       "VIANO 2.3 TD",
//       "VITO",
//       "VITO 108 D",
//       "VITO 108 D",
//       "VITO 108 D",
//       "VITO 108 D",
//       "VITO 109 CDI",
//       "VITO 110 CDI",
//       "VITO 110 D",
//       "VITO 110 D",
//       "VITO 110 D",
//       "VITO 110 D",
//       "VITO 111 CDI",
//       "VITO 111 CDI",
//       "VITO 111 CDI",
//       "VITO 111 CDI FURGON MIXTO",
//       "VITO 111 CDI FURGON MIXTO",
//       "VITO 115 CDI",
//       "VITO 119",
//       "VITO 121 TOURER 7+1",
//       "VITO 2.2 CDI",
//       "VITO 2.3 TD",
//       "VITO L",
//       "VITO L 112 CDI",
//       "VITO PLUS 111 CDI VIDRIADO",
//       "VITO TOURER 111 CDI",
//       "VITO TOURER 119",
//       "VITO TOURER 121",
//       "VITO TOURER 121 7+1",
//       "VITO TOURER 121 7+1",
//       "VITO TOURER 121 8+1",
//       "A170",
//       "B200 BLUE EFFICIENCY",
//       "OF-1724",
//       "SPRINTER 308 D"
//     ],
//     "MERCEDES": [
//       "BENZ/COMIL",
//       "BENZ/MARCOPOLO",
//       "BENZ/MARCOPOLO",
//       "BENZ/MARCOPOLO",
//       "BENZ/MARCOPOLO",
//       "BENZ/MARCOPOLO"
//     ],
//     "METRO": ["MD 6601D"],
//     "MG": ["MGF", "MGF", "MGF 1.8I", "MGF 1.8I VVC", "MIDGET TD"],
//     "MINI": [
//       "CLUBMAN COOPER",
//       "CLUBMAN COOPER S",
//       "CLUBMAN COOPER S",
//       "COOPER",
//       "COOPER",
//       "COOPER",
//       "COOPER",
//       "COOPER",
//       "COOPER CABRIO",
//       "COOPER COUNTRYMAN",
//       "COOPER COUPE",
//       "COOPER S",
//       "COOPER S",
//       "COOPER S",
//       "COOPER S ALL4 CM",
//       "COOPER S CABRIO",
//       "COOPER S CLUBMAN",
//       "COOPER S COUNTRYMAN",
//       "COOPER S COUNTRYMAN",
//       "COOPER S COUPE",
//       "COOPER S PACEMAN",
//       "COOPER S PACEMAN ALL4",
//       "COOPER S ROADSTER",
//       "COUNTRYMAN COOPER",
//       "COUNTRYMAN COOPER S",
//       "COUNTRYMAN COOPER S",
//       "COUNTRYMAN JOHN COOPER",
//       "JOHN COOPER WORKS",
//       "JOHN COOPER WORKS",
//       "JOHN COOPER WORKS",
//       "JOHN COOPER WORKS GP",
//       "ONE",
//       "ONE",
//       "ONE COUNTRYMAN",
//       "COOPER SD COUNTRYMAN",
//       "JOHN COOPER WORKS COUPE"
//     ],
//     "MITSUBISHI": [
//       "3000",
//       "3000 GT",
//       "3000 GT SL",
//       "3000 GT VR4",
//       "3000 GTV 4",
//       "3000/GT",
//       "ASX",
//       "ASX 1.6",
//       "CANTER",
//       "CANTER",
//       "COLT",
//       "COLT GLXI",
//       "ECLIPSE",
//       "ECLIPSE",
//       "ECLIPSE GS",
//       "ECLIPSE GS",
//       "ECLIPSE GS-T",
//       "ECLIPSE GS-T",
//       "ECLIPSE GSX",
//       "ECLIPSE GSX AWD",
//       "ECLIPSE GSX/GS",
//       "ECLIPSE GT",
//       "ECLIPSE RS",
//       "ECLIPSE RS",
//       "ECLIPSE SPYDER",
//       "ECLIPSE SPYDER GS",
//       "ECLIPSE SPYDER GS-T",
//       "ECLIPSE/RS",
//       "ENDEAVOR LS",
//       "GALA./SAPPORO",
//       "GALA.2000 SUPER SALOON",
//       "GALA.OTROS",
//       "GALANT 2.0I",
//       "GALANT 2000.SS",
//       "GALANT 2000GSR",
//       "GALANT GL 1600",
//       "GALANT GLS TD",
//       "GALANT GLSI",
//       "GALANT LS",
//       "GALANT SUPER SALOON",
//       "GALANT SUPER SALOON 2000",
//       "L 200",
//       "L 300",
//       "L 300",
//       "L 300",
//       "L200 (CABINA DOBLE)",
//       "L200 2.5 D 2WD (CABINA",
//       "L200 2.5 DIESEL",
//       "L200 2.5 DIESEL (CABINA",
//       "L200 2.5 DIESEL (CABINA",
//       "L200 2WD (CABINA DOBLE)",
//       "L200 4WD 2.5 D T (CABINA",
//       "L200 4WD 2.5 DIESEL",
//       "L200 4WD 2.5 DIESEL (CAB.",
//       "L200 4WD 2.5 DIESEL TURBO",
//       "L200 4X4 (CABINA DOBLE)",
//       "L200 4X4 2.5 GLS",
//       "L200 4X4 2.5 TDI",
//       "L200 CAB.SIMPLE",
//       "L200 CABINA SIMPLE",
//       "L200 DI-D",
//       "L200 DI-D COMMON RAIL AT",
//       "L200 DI-D COMMON RAIL MT",
//       "L200 DI-D KATANA COMMON",
//       "L200 GL 2WD (CABINA",
//       "L200 GL 4WD (CABINA",
//       "L200 GL 4WD (CABINA",
//       "L200 GLS (CABINA DOBLE)",
//       "L200 GLS 4WD TURBO",
//       "L200 GLS TDI (CABINA DOBLE)",
//       "L200 GLX (CABINA DOBLE)",
//       "L200 GLX 4WD (CABINA",
//       "L200 OUTDOOR HPE",
//       "L200 OUTDOOR HPE",
//       "L200 SPORT 4X4",
//       "L200 SPORT GLS",
//       "L200 SPORT GLS",
//       "L200 SPORT HPE",
//       "L200 SPORT HPE",
//       "L200 SPORTERO DI-D",
//       "L200 TD (CABINA DOBLE)",
//       "L200 TRITON GLS",
//       "L200 TRITON GLX",
//       "L200 TRITON HPE 3.2 CR",
//       "L200 TRITON HPE 3.2 L",
//       "L200 TRITON OUTDOOR",
//       "L300 2.5 D",
//       "L300 2.5 D",
//       "L300 2.5 D",
//       "L300 GLX",
//       "L300 MINIBUS",
//       "L300 OTROS",
//       "L300 PANEL VAN",
//       "L300 PICKUP",
//       "L300 VAN",
//       "LANCER",
//       "LANCER 1600 GLXI",
//       "LANCER 1600GSR/GLXI",
//       "LANCER 2.0 GLS A/T",
//       "LANCER EVOLUTION GSR",
//       "LANCER EVOLUTION IX",
//       "LANCER EVOLUTION V",
//       "LANCER EVOLUTION VI",
//       "LANCER EVOLUTION VIII",
//       "LANCER GLS",
//       "LANCER GLX",
//       "LANCER GLXI",
//       "LANCER GLXI",
//       "LANCER GT",
//       "LANCER OTROS",
//       "LANCER STATION WAGON",
//       "LANCER STATION WAGON",
//       "LANCER STATION WAGON",
//       "MONTERO",
//       "MONTERO",
//       "MONTERO",
//       "MONTERO",
//       "MONTERO 2.8 GLS",
//       "MONTERO 2.8 GLS",
//       "MONTERO 2.8 GLS",
//       "MONTERO 2.8 GLX",
//       "MONTERO 2.8 GLX",
//       "MONTERO 2.8 TDI",
//       "MONTERO 3.2 DID",
//       "MONTERO 3.2 DID A/T",
//       "MONTERO 4WD",
//       "MONTERO 4X4",
//       "MONTERO DI-D 3.2 GLS",
//       "MONTERO DOHC-24 V6 3500",
//       "MONTERO GLS",
//       "MONTERO GLS V6-3500",
//       "MONTERO GLS V6-3500",
//       "MONTERO GLX 2.5",
//       "MONTERO INTERCOOLER 2.5",
//       "MONTERO INTERCOOLER 2.5",
//       "MONTERO INTERCOOLER",
//       "MONTERO INTERCOOLER T",
//       "MONTERO INTERCOOLER TD",
//       "MONTERO INTERCOOLER TD",
//       "MONTERO INTERCOOLER TD",
//       "MONTERO INTERCOOLER",
//       "MONTERO INTERCOOLER",
//       "MONTERO INTERCOOLER",
//       "MONTERO IO (3 PUERTAS)",
//       "MONTERO IO (5 PUERTAS)",
//       "MONTERO LIMITED",
//       "MONTERO LS",
//       "MONTERO METAL TOP",
//       "MONTERO SHR GLS",
//       "MONTERO SPORT",
//       "MONTERO SPORT",
//       "MONTERO SPORT 3.5 XS",
//       "MONTERO SPORT ES",
//       "MONTERO SPORT GLS",
//       "MONTERO SPORT GLS 2.5 TDI",
//       "MONTERO SPORT LIMITED",
//       "MONTERO SPORT LS",
//       "MONTERO SPORT LS",
//       "MONTERO SPORT LS 4WD V6",
//       "MONTERO SPORT XLS",
//       "MONTERO SPORT XLS 4WD",
//       "MONTERO SR",
//       "MONTERO TURBO",
//       "MONTERO TURBO DIESEL GLS",
//       "MONTERO TURBO I",
//       "MONTERO TURBO",
//       "MONTERO TURBO/I",
//       "MONTERO XLS",
//       "MONTERO/LS/SR/GLS",
//       "NATIVA 2.5 GLS TDI",
//       "NATIVA 2.5 GLX TDI",
//       "NATIVA LS 4WD V6",
//       "NATIVA MONTERO SPORT",
//       "NEW GALANT",
//       "OUTLANDER",
//       "OUTLANDER",
//       "OUTLANDER 3.0",
//       "OUTLANDER GLS",
//       "OUTLANDER LS",
//       "OUTLANDER SPORT",
//       "OUTLANDER SPORT",
//       "OUTLANDER SPORT GLS 2.0",
//       "PAJERO",
//       "PAJERO 4X4 TURBO DIESEL",
//       "PAJERO DID",
//       "PAJERO EXCEED",
//       "PAJERO GDI",
//       "PAJERO GLS",
//       "PAJERO GLS 2.8 TDI",
//       "PAJERO GLS-B",
//       "PAJERO GLX",
//       "PAJERO GLX-B",
//       "PAJERO SPORT",
//       "PAJERO SPORT GLS 2.5TDI",
//       "PAJERO SPORT GLX 2.5 TDI",
//       "PAJERO SPORT GLX 5.2 TDI",
//       "PAJERO TD GLS",
//       "PAJERO TURBO DIESEL",
//       "SPACE WAGON",
//       "SPACE WAGON",
//       "SPACE WAGON",
//       "SPACE WAGON 2.4 GLX",
//       "SPACE WAGON 2.4 GLX",
//       "SPACE WAGON 2400 GLX",
//       "SPACE WAGON GLX",
//       "SPACE WAGON GLX TURBO",
//       "SPACE WAGON GLXI",
//       "SPACE WAGON GLXI",
//       "SPACE WAGON GLXI",
//       "SPACE WAGON TURBO DIESEL"
//     ],
//     "MONDIAL": [
//       "BD 90",
//       "CARGO",
//       "CARGO C 2.",
//       "CARGO VR",
//       "CD200Y",
//       "DAX 70",
//       "DAX 70",
//       "DAX 90",
//       "DAX70",
//       "EX150K",
//       "FD110L",
//       "FD110S",
//       "FD125",
//       "FD150",
//       "FD150L",
//       "FD150LN",
//       "FD150Q",
//       "FD150S",
//       "FD150SV",
//       "FD150Y",
//       "FD200",
//       "FD200-RS",
//       "FD200S",
//       "FD200ST",
//       "FD250",
//       "FD250H",
//       "FD250Q",
//       "FD250R",
//       "FD250S",
//       "FD250ST",
//       "FD250X",
//       "FD250Y",
//       "FD250YR",
//       "FD300LN",
//       "FD300S",
//       "FD50",
//       "FD50A",
//       "FD50S",
//       "FREE",
//       "FREE CARGO",
//       "HD 125 A",
//       "HD 125 L",
//       "HD 150",
//       "HD 150",
//       "HD 150 L",
//       "HD 250",
//       "HD125",
//       "HD125L",
//       "HD150L",
//       "HD250A",
//       "HD250W",
//       "HD254A",
//       "KX 50",
//       "KX50",
//       "LD 110 H",
//       "LD 110 H1",
//       "LD 110 MAX-AD",
//       "LD 110 MAX-RT",
//       "LD 110 S",
//       "LD 110 Y",
//       "LD 110 Y T",
//       "LD 110-AD",
//       "LD 110-RT",
//       "LD 110S-AD",
//       "LD 110S-AT",
//       "LD 110S-RT",
//       "LD 125 L",
//       "LD PISTA S",
//       "LD110",
//       "LD110",
//       "LD110 A 3V",
//       "LD110H",
//       "LD110K",
//       "LD110L",
//       "LD110Q",
//       "LD110S",
//       "LD110Y",
//       "LD110YT",
//       "LD125L MAX R",
//       "MD 125",
//       "MD 150",
//       "MD 150 ALLEGRO",
//       "MD125K",
//       "MD125K1",
//       "MD150",
//       "MD150N",
//       "MONKEY",
//       "MS 50",
//       "MS50D",
//       "MS50E",
//       "QJ100E",
//       "QJ110E",
//       "RD 125 L",
//       "RD 150 CLASSIC",
//       "RD 150 H",
//       "RD 150 H",
//       "RD 150 L",
//       "RD 150-AD",
//       "RD 150N-AD",
//       "RD 150N-RT",
//       "RD 150-RT",
//       "RD125",
//       "RD125K",
//       "RD150K",
//       "RD150L",
//       "RD200K",
//       "RD250",
//       "RD250R",
//       "RD250S",
//       "RV 125",
//       "RV 125",
//       "SCARABEO",
//       "SCARABEO",
//       "SILVER FOX 100",
//       "TB100",
//       "TB125",
//       "TD 150",
//       "TD 150 L",
//       "TD 200 P",
//       "TD 250",
//       "TD150L",
//       "TD200K",
//       "TD200K-2",
//       "VD250P",
//       "W150",
//       "W250 SPORT"
//     ],
//     "MOTO GUZZI": [
//       "V7 II STORNELLO",
//       "V7 III ANNIVERSARIO",
//       "V7 III RACER",
//       "V7 III SPECIAL",
//       "V7 III STONE",
//       "V85 TT",
//       "V9 BOBBER",
//       "V9 ROAMER"
//     ],
//     "MOTOMEL": [
//       "B110",
//       "B110",
//       "B110",
//       "B110 TUNNING",
//       "B110 TUNNING",
//       "B125",
//       "B125",
//       "C 125",
//       "C 125",
//       "C100 DLX",
//       "C100 DLX",
//       "C100 SE",
//       "C100 SE",
//       "C110",
//       "C110",
//       "C110 DLX",
//       "C110 DLX",
//       "C110 SE",
//       "C150",
//       "C150 SII",
//       "C250",
//       "CA 110",
//       "CA100 DB",
//       "CA100DB",
//       "CA110",
//       "CD 125",
//       "CD100",
//       "CD100",
//       "CD125",
//       "CG 125",
//       "CG 150",
//       "CG 150",
//       "CITY CARGO 110",
//       "CUSTOM",
//       "CUSTOM 100",
//       "CUSTOM 100",
//       "CUSTOM 150",
//       "CUSTOM 150",
//       "CUSTOM 200",
//       "CUSTOM 200",
//       "CX 150",
//       "CX150",
//       "CX250",
//       "CX250",
//       "DK 200",
//       "DK200",
//       "DREAM",
//       "E110",
//       "ECO",
//       "ECO AUTOMATIC",
//       "FORZA",
//       "FORZA",
//       "FORZA SE",
//       "FORZA SE",
//       "GO 125 VINTAGE",
//       "GORILLA",
//       "GORILLA",
//       "GORILLA 110",
//       "GORILLA 110",
//       "GT",
//       "GV650",
//       "HYOSUNG",
//       "KRAKEN",
//       "KRAKEN 50",
//       "KRAKEN 90",
//       "KRAKEN 90",
//       "LYNX 110",
//       "M 70",
//       "M70 AUTO",
//       "MEDAL",
//       "MEGELLI250",
//       "MOTARD",
//       "MOTARD 200",
//       "MX110",
//       "MX250",
//       "NF100",
//       "PITBULL",
//       "PITBULL",
//       "PITBULL 200",
//       "PX110",
//       "QS250",
//       "QUEST",
//       "QUEST",
//       "S 250",
//       "S110",
//       "S110",
//       "S2",
//       "S2",
//       "S250",
//       "S3",
//       "S70",
//       "S70",
//       "SIRIUS 150",
//       "SIRIUS 190",
//       "SIRIUS 250",
//       "SIRIUS200",
//       "SKUA 125 X-TREME",
//       "SKUA 200",
//       "SKUA 200 V6",
//       "SKUA ADVENTURE",
//       "SKUA200",
//       "SKUA250",
//       "SKUA250",
//       "SL 125",
//       "SL 125",
//       "SL125",
//       "SQ 50",
//       "SQ50",
//       "SR 150",
//       "SR 200",
//       "SR200",
//       "STRATO",
//       "STRATO",
//       "STRATO ALPINO",
//       "STRATO EURO 150",
//       "STRATO FUN 80",
//       "SUNTRIKE",
//       "T150",
//       "T200",
//       "TRACKER",
//       "TRACKER",
//       "TRUCKER",
//       "VOLKANO 250",
//       "VX150",
//       "VX150",
//       "X3M",
//       "X3M",
//       "XMM",
//       "XMM"
//     ],
//     "MV AGUSTA": [
//       "BRUTALE 1090",
//       "BRUTALE 675",
//       "BRUTALE 800",
//       "BRUTALE 800 DRAGSTER",
//       "BRUTALE 800 DRAGSTER RC",
//       "BRUTALE 800 RR",
//       "BRUTALE RR",
//       "F3 675",
//       "F3 800",
//       "F3 800 RC",
//       "F4 1000 S/1+1",
//       "F4 RR",
//       "RIVALE 800",
//       "STRADALE 800",
//       "TURISMO VELOCE",
//       "TURISMO VELOCE 800",
//       "TURISMO VELOCE LUSSO"
//     ],
//     "NAKAI": [
//       "CHANGAN SC 1010",
//       "DELIVERY",
//       "SC1011",
//       "YANTAI YTQ 1030 CD",
//       "YANTAI YTQ 1030 CS"
//     ],
//     "NISSAN": [
//       "350Z 3.5L",
//       "350Z 3.5L 24V",
//       "350Z ROADSTER M/T",
//       "370Z A/T",
//       "370Z M/T",
//       "370Z ROADSTER A/T",
//       "370Z ROADSTER M/T",
//       "4X2 (DOBLE CABINA DIESEL)",
//       "4X4 (DOBLE CABINA DIESEL)",
//       "4X4 DOBLE CABINA (NAFTA)",
//       "AD WAGON 1.6",
//       "AD WAGON 1.6 LX",
//       "ALTIMA 2.5 S",
//       "ALTIMA ADVANCE MEDIA-",
//       "ALTIMA ADVANCE PURE",
//       "CABINA DOBLE 2.4 I",
//       "CABINA DOBLE 2.5 DIESEL",
//       "CABINA DOBLE 4X4 2.5 TDI",
//       "CABINA DOBLE 4X4 DIESEL",
//       "CABINA DOBLE 4X4 DIESEL",
//       "CABINA SIMPLE 2.0",
//       "CABINA SIMPLE 2.4 I",
//       "CABINA SIMPLE 2.5 DIESEL",
//       "CABINA SIMPLE 3.2 4X4",
//       "CABINA SIMPLE 4X2 NAFTA",
//       "CABINA SIMPLE 4X4 DIESEL",
//       "CABINA SIMPLE DIESEL 2.7",
//       "CABINA Y MEDIA DIESEL",
//       "DOBLE CABINA 2.0",
//       "DOBLE CABINA 4X4 DIESEL",
//       "DOBLE CABINA 4X4 NAFTA",
//       "DOBLE CABINA AX 4X4 DIESEL",
//       "DOBLE CABINA DIESEL 2.7",
//       "DOBLE CABINA TRUCK XE 4X4",
//       "ECO-T.100",
//       "FRONTIER 4X2 SE 2.8TD",
//       "FRONTIER 4X2 XE",
//       "FRONTIER 4X2 XE",
//       "FRONTIER 4X2 XE2.8TDI",
//       "FRONTIER 4X2SE 2.8TDI",
//       "FRONTIER 4X2XE 2.8TDI",
//       "FRONTIER 4X2XE 2.8TDI",
//       "FRONTIER 4X4 XE",
//       "FRONTIER 4X4 SE",
//       "FRONTIER 4X4 XE2.8TDI",
//       "FRONTIER 4X4SE 2.8TDI",
//       "FRONTIER 4X4SE 2.8TDI",
//       "FRONTIER 4X4XE 2.8TDI",
//       "FRONTIER CD LE 2.3 D 4X2 MT",
//       "FRONTIER CD LE 2.3 D 4X4 AT",
//       "FRONTIER CD LE 2.3 D 4X4 MT",
//       "FRONTIER CD S 2.3 D 4X2 MT",
//       "FRONTIER CD S 2.3 D 4X4 MT",
//       "FRONTIER CD SE 2.3 D 4X2",
//       "FRONTIER CD SE 2.3 D 4X4",
//       "FRONTIER CD XE 2.3 D 4X2",
//       "FRONTIER CD XE 2.3 D 4X4",
//       "FRONTIER CD X-GEAR 2.3 D",
//       "FRONTIER CD X-GEAR 2.3 D",
//       "FRONTIER CD X-GEAR PLUS",
//       "FRONTIER KING CAB",
//       "FRONTIER LE 4WD 5AT LUXE",
//       "FRONTIER LE 4WD 6MT",
//       "FRONTIER LE 4WD 6MT LUXE",
//       "FRONTIER NP300",
//       "FRONTIER PLATINUM 4X2 AT",
//       "FRONTIER PLATINUM 4X4 AT",
//       "FRONTIER PRO4X 4X4 AT 2.3",
//       "FRONTIER S 4X2 AT 2.3 D CD",
//       "FRONTIER S 4X2 MT 2.3 D CD",
//       "FRONTIER S 4X4 MT 2.3 D CD",
//       "FRONTIER SE PLUS 4X2 DIESEL",
//       "FRONTIER XE 4X2 AT 2.3 D CD",
//       "FRONTIER XE 4X2 MT 2.3 D",
//       "FRONTIER XE 4X4 MT 2.3 D",
//       "FRONTIER X-GEAR 4X4 AT 2.3",
//       "GT-R",
//       "INTERSTAR DCI 120",
//       "JUKE",
//       "KICKS 1.6 ADVANCE CVT",
//       "KICKS 1.6 ADVANCE CVT PLUS",
//       "KICKS 1.6 ADVANCE MT",
//       "KICKS 1.6 EXCLUSIVE CVT",
//       "KICKS 1.6 EXCLUSIVE CVT BT",
//       "KICKS 1.6 SENSE MT",
//       "KICKS ADVANCE CVT",
//       "KICKS ADVANCE CVT F2",
//       "KICKS ADVANCE CVT UCL",
//       "KICKS ADVANCE CVT UCL 2",
//       "KICKS ADVANCE MT",
//       "KICKS ADVANCE MT F2",
//       "KICKS EXCLUSIVE CVT",
//       "KICKS EXCLUSIVE CVT BT",
//       "KICKS EXCLUSIVE CVT BT F2",
//       "KICKS EXCLUSIVE CVT F2",
//       "KICKS SENSE MT",
//       "KICKS SENSE MT F2",
//       "KICKS SPECIAL EDITION CVT",
//       "KICKS SPECIAL EDITION CVT",
//       "KICKS X-PLAY",
//       "L-80.095",
//       "LEAF TEKNA",
//       "LUCINO GSE",
//       "LUCINO GSE",
//       "MARCH",
//       "MARCH ACENTA",
//       "MARCH ACTIVE MT",
//       "MARCH ACTIVE PURE DRIVE",
//       "MARCH ACTIVE PURE DRIVE",
//       "MARCH ADVANCE AT",
//       "MARCH ADVANCE MEDIA-",
//       "MARCH ADVANCE MEDIA-",
//       "MARCH ADVANCE MEDIA-",
//       "MARCH ADVANCE MT",
//       "MARCH ADVANCE PURE",
//       "MARCH ADVANCE PURE",
//       "MARCH SENSE AT",
//       "MARCH SENSE MT",
//       "MARCH SENSE PURE DRIVE",
//       "MARCH SENSE PURE DRIVE F2",
//       "MARCH SPORT PURE DRIVE",
//       "MARCH SR",
//       "MARCH VISIA",
//       "MARCH VISIA PLUS",
//       "MAXIMA",
//       "MAXIMA 30 GV",
//       "MAXIMA 30G",
//       "MAXIMA GLE",
//       "MAXIMA GXE",
//       "MAXIMA QX",
//       "MICRA",
//       "MICRA",
//       "MURANO AWD 4X4",
//       "MURANO EXCLUSIVE CVT",
//       "MURANO EXCLUSIVE CVT",
//       "MURANO SE AWD",
//       "MURANO SL",
//       "MURANO SL AWD",
//       "MURANO XTRONIC CVT",
//       "NAVARA 2.5 TDI",
//       "NAVARA 2.5 TDI 4X4 (CAB",
//       "NISSAN (DIESEL)",
//       "NISSAN (DOBLE CABINA",
//       "NISSAN (DOBLE CABINA",
//       "NISSAN PATHFINDER",
//       "NISSAN PATROL",
//       "NISSAN TERRANO V6",
//       "NOTE ADVANCE CVT",
//       "NOTE ADVANCE PURE DRIVE",
//       "NOTE EXCLUSIVE CVT PURE",
//       "NOTE SENSE CVT",
//       "NOTE SENSE PURE DRIVE",
//       "NOTE SR CVT",
//       "NP300 FRONTIER LE 4X2",
//       "NP300 FRONTIER LE 4X4 AT",
//       "NP300 FRONTIER LE 4X4",
//       "NP300 FRONTIER SE 4X2",
//       "NP300 FRONTIER XE 4X4",
//       "PATHFINDER",
//       "PATHFINDER",
//       "PATHFINDER 3.3",
//       "PATHFINDER 4X4 DIESEL",
//       "PATHFINDER ARMADA SE",
//       "PATHFINDER LE 2.5DCI",
//       "PATHFINDER LE 4X4",
//       "PATHFINDER SE 3.0",
//       "PATHFINDER SE 3.3",
//       "PATHFINDER SE 3.3 LUXURY",
//       "PATHFINDER SE 3.5",
//       "PATHFINDER SE 4.0L",
//       "PATHFINDER SE V6",
//       "PATHFINDER XE V6",
//       "PATROL",
//       "PATROL",
//       "PATROL 4WD",
//       "PATROL 4X4",
//       "PATROL 4X4",
//       "PATROL 4X4 GR",
//       "PATROL GL 4X4",
//       "PATROL GR",
//       "PATROL GR 4X4 TD",
//       "PATROL SGL-D",
//       "PATROL STATION WAGON",
//       "PRIMASTAR",
//       "PRIMASTAR DCI 100",
//       "PRIMASTAR DCI 150",
//       "PRIMERA",
//       "PRIMERA",
//       "PRIMERA 2.0 SLX",
//       "PRIMERA 2.0 SLX",
//       "PRIMERA GX",
//       "PRIMERA GX",
//       "PRIMERA GXE",
//       "PRIMERA GXE PLUS 22",
//       "PRIMERA SLX 2.0 TD",
//       "PRIMERA TD 2.0 DLX",
//       "QUEST",
//       "QUEST",
//       "QUEST GLE",
//       "QUEST GXE",
//       "QUEST XE",
//       "QUEST/XE/GXE",
//       "SENTRA 1.8 S",
//       "SENTRA 2.0 ADVANCE CVT",
//       "SENTRA 2.0 ADVANCE MT",
//       "SENTRA 2.0 EXCLUSIVE CVT",
//       "SENTRA 2.0 SR CVT",
//       "SENTRA ACENTA 6MT",
//       "SENTRA ACENTA XTRONIC",
//       "SENTRA ADVANCE MT",
//       "SENTRA ADVANCE PURE",
//       "SENTRA EXCLUSIVE CVT",
//       "SENTRA EXCLUSIVE CVT PURE",
//       "SENTRA GLE",
//       "SENTRA GSX",
//       "SENTRA GXE",
//       "SENTRA GXE",
//       "SENTRA II GXE",
//       "SENTRA II XE",
//       "SENTRA SENSE",
//       "SENTRA SENSE PURE DRIVE",
//       "SENTRA SR CVT",
//       "SENTRA SR CVT PURE DRIVE",
//       "SENTRA SR PURE DRIVE",
//       "SENTRA SV PURE DRIVE",
//       "SENTRA TEKNA XTRONIC CVT",
//       "SENTRA XTRONIC CVT",
//       "SERENA 2.0 SLX",
//       "SERENA 2.0D SGX",
//       "SERENA 2.3 D",
//       "SERENA 2.3 D SLX",
//       "SERENA 2.3 SLX",
//       "SERENA/2.0 SLX/2.3",
//       "SUNNY EX SALOON",
//       "TEANA 250 XV V6",
//       "TEANA 350 XV V6",
//       "TERRANO 2.4",
//       "TERRANO 2.7 TD",
//       "TERRANO II",
//       "TERRANO II 2.7 TD",
//       "TERRANO II 2.7TDI",
//       "TERRANO II MISTRAL",
//       "TERRANO II SE",
//       "TERRANO II SE (3 PUERTAS)",
//       "TERRANO II SE 2.4",
//       "TERRANO II SE 2.7 TDI",
//       "TERRANO II SGX",
//       "TERRANO II SGX LARGO DISEL",
//       "TERRANO II SLX",
//       "TERRANO II SR",
//       "TERRANO SE LUXURY TD27 TI",
//       "TIIDA",
//       "TIIDA",
//       "TIIDA 1.8 6MT ACENTA",
//       "TIIDA 1.8 6MT ACENTA",
//       "TIIDA 1.8 6MT TEKNA",
//       "TIIDA 1.8 6MT TEKNA",
//       "TIIDA 1.8 6MT VISIA",
//       "TIIDA 1.8 6MT VISIA",
//       "TIIDA 1.8 6MT VISIA SE",
//       "TITAN V8 5.6",
//       "TRADE",
//       "TRADE",
//       "TRADE 100",
//       "TRADE 2.0",
//       "TRADE 2.0",
//       "TRADE 3.0",
//       "TRADE 65ND",
//       "TRADE 75",
//       "VERSA",
//       "VERSA",
//       "VERSA 1.6 ADVANCE CVT",
//       "VERSA 1.6 ADVANCE MT",
//       "VERSA 1.6 EXCLUSIVE CVT",
//       "VERSA 1.6 SENSE CVT",
//       "VERSA 1.6 SENSE MT",
//       "VERSA ACENTA",
//       "VERSA ACENTA A/T",
//       "VERSA ADVANCE AT",
//       "VERSA ADVANCE AT PURE",
//       "VERSA ADVANCE AT PURE",
//       "VERSA ADVANCE MT",
//       "VERSA ADVANCE MT PURE",
//       "VERSA ADVANCE MT PURE",
//       "VERSA EXCLUSIVE AT",
//       "VERSA EXCLUSIVE AT PURE",
//       "VERSA EXCLUSIVE AT PURE",
//       "VERSA S",
//       "VERSA SENSE AT",
//       "VERSA SENSE AT PURE DRIVE",
//       "VERSA SENSE MT",
//       "VERSA SENSE MT PURE DRIVE",
//       "VERSA SENSE MT PURE DRIVE",
//       "VERSA V-DRIVE DRIVE MT",
//       "VERSA V-DRIVE PLUS AT",
//       "VERSA VISIA",
//       "X TERRA",
//       "X TERRA XE",
//       "XTERRA",
//       "X-TERRA SE",
//       "X-TRAIL",
//       "X-TRAIL",
//       "X-TRAIL 2.2 DIESEL",
//       "X-TRAIL 2.5 6MT",
//       "X-TRAIL 2.5 6MT ACENTA",
//       "X-TRAIL 2.5 6MT VISIA",
//       "X-TRAIL 2.5 ADVANCE A/T",
//       "X-TRAIL 2.5 CVT",
//       "X-TRAIL 2.5 CVT ACENTA",
//       "X-TRAIL 2.5 CVT TEKNA",
//       "X-TRAIL 2.5 CVT VISIA",
//       "X-TRAIL 2.5 NAFTA",
//       "X-TRAIL 2.5 NAFTA MANUAL",
//       "X-TRAIL ACENTA 2.0 DCI 6MT",
//       "X-TRAIL ACENTA 2.5 6MT",
//       "X-TRAIL ACENTA 2.6 6MT",
//       "X-TRAIL ADVANCE CVT",
//       "X-TRAIL EXCLUSIVE CVT",
//       "X-TRAIL TEKNA XTRONIC CVT",
//       "X-TRAIL X 2.5 6MT",
//       "X-TRAIL X 2.5 A/T",
//       "X-TRAIL X 2.5 CVT",
//       "X-TRAIL XLTD CVT",
//       "ARMADA SE",
//       "FRONTIER LE 4X4 OFF ROAD",
//       "FRONTIER LE 4X4 OFF ROAD",
//       "FRONTIER LE 4X4 OFFROAD",
//       "INTERSTAR DCI 90",
//       "PRIMASTAR DCI 115",
//       "PRIMERA GX 16V"
//     ],
//     "NISSAN DIESEL": [
//       "CPC16",
//       "CPC16 MHLA",
//       "CPC16 MHLA",
//       "CPC16 TURBO DIESEL",
//       "CPC16 TURBO DIESEL"
//     ],
//     "NORTON": ["COMMANDO 961"],
//     "NUUV": ["M+ SPORT", "N SPORT", "NGT", "U PRO"],
//     "OKINOI": [
//       "150 R",
//       "250 R",
//       "DROP 125",
//       "NAKED 150",
//       "OKIMOTARD 250",
//       "OKINOI 110",
//       "OKINOI 125",
//       "OKN 250",
//       "OKN 300",
//       "OKN DROP 125",
//       "R 150 N",
//       "R 250 N",
//       "ST 150 N",
//       "STREET 150",
//       "SUPERMOTARD",
//       "TUNING 125"
//     ],
//     "OLMO": ["FLASH 110 S", "XT 150", "XT 150"],
//     "OPEL": [
//       "ASTRA 1.7 CDTI",
//       "ASTRA 1.7 CDTI",
//       "ASTRA 1.8",
//       "ASTRA GL / GSI",
//       "ASTRA GLS 1.7 TD",
//       "ASTRA GLS 1.7 TD",
//       "COMBO 1.7 DTI",
//       "CORSA",
//       "CORSA",
//       "CORSA 1.2",
//       "MERIVA",
//       "MERIVA 1.7 CDTI",
//       "VECTRA 2.0I CDX",
//       "VECTRA 2.2 DTI",
//       "VECTRA CDX",
//       "VIVARO 2.5 CDTI",
//       "VIVARO CDTI",
//       "ZAFIRA",
//       "ZAFIRA 1.6 16V COMFORT",
//       "ZAFIRA 1.6I",
//       "ZAFIRA 1.7 CDTI",
//       "ZAFIRA 1.8 16V ELEGANCE",
//       "ZAFIRA 2.0 DTI 16V ELEGANCE",
//       "ZAFIRA 2.0 ELEGANCE",
//       "ZAFIRA 2.0DTI",
//       "ZAFIRA A",
//       "ZAFIRA DI 16V",
//       "COMBO CDTI",
//       "CORSA 1.2 SWING",
//       "ZAFIRA 1.9 CDTI",
//       "ZAFIRA 2.2 16V SPORT",
//       "ZAFIRA 2.2 DTI ELEGANCE"
//     ],
//     "PANTHER": [
//       "110F",
//       "110R",
//       "70F",
//       "90F",
//       "WR110",
//       "WR125",
//       "WR150",
//       "WR150T",
//       "WR200",
//       "WR250"
//     ],
//     "PANTHER QUADS": [
//       "110 R",
//       "200R",
//       "70 F",
//       "90 F",
//       "ATV WR110",
//       "ATV WR125",
//       "WR 150",
//       "WR 200",
//       "WR 250",
//       "150 R"
//     ],
//     "PEUGEOT": [
//       "1007",
//       "1007 1.4 HDI",
//       "106",
//       "106",
//       "106 DIESEL",
//       "106 LONG BEACH",
//       "106 MAX 1.1",
//       "106 MAX 1.4E",
//       "106 MAX 1.4E",
//       "106 MAX 1.5D",
//       "106 QUICK SILVER",
//       "106 QUICK SILVER TECHO",
//       "106 QUIKSILVER 1.1",
//       "106 ROLAND GARROS 1.4L",
//       "106 ROLAND GARROS 1.4L",
//       "106 XN",
//       "106 XN",
//       "106 XN EQUINOXE",
//       "106 XND",
//       "106 XR",
//       "106 XR",
//       "106 XSI",
//       "106 ZEN 1.4E",
//       "106 ZEN 1.4E",
//       "106S16",
//       "107",
//       "2008 ACTIVE 1.6",
//       "2008 ACTIVE 1.6 115",
//       "2008 ACTIVE 1.6 AM20",
//       "2008 ACTIVE 1.6 AM21",
//       "2008 ALLURE 1.6",
//       "2008 ALLURE 1.6 115",
//       "2008 ALLURE 1.6 AM20",
//       "2008 ALLURE 1.6 AM21",
//       "2008 ALLURE 1.6 AM22",
//       "2008 ALLURE 1.6 TIPTRONIC",
//       "2008 ALLURE 1.6 TIPTRONIC 6",
//       "2008 ALLURE 1.6 TIPTRONIC 6",
//       "2008 CROSSWAY 1.6",
//       "2008 CROSSWAY 1.6 115",
//       "2008 FELINE 1.6",
//       "2008 FELINE 1.6 115",
//       "2008 FELINE 1.6 AM20",
//       "2008 FELINE 1.6 AM21",
//       "2008 FELINE 1.6 AM22",
//       "2008 FELINE 1.6 AM22.5",
//       "2008 FELINE 1.6 AM23",
//       "2008 FELINE 1.6 TIPTRONIC",
//       "2008 FELINE 1.6 TIPTRONIC 6",
//       "2008 FELINE 1.6 TIPTRONIC 6",
//       "2008 FELINE 1.6 TIPTRONIC",
//       "2008 FELINE 1.6 TIPTRONIC",
//       "2008 FELINE 1.6 TIPTRONIC",
//       "2008 FELINE 1.6 TIPTRONIC",
//       "2008 FELINE TIPTRONIC 1.6",
//       "2008 GRIFFE 1.6 THP",
//       "2008 IN CONCERT 1.6 AM20",
//       "2008 IN CONCERT 1.6 THP",
//       "2008 IN CONCERT 1.6",
//       "2008 SPORT 1.6 THP",
//       "2008 SPORT 1.6 THP 165",
//       "2008 SPORT 1.6 THP AM20",
//       "2008 SPORT 1.6 THP",
//       "2008 SPORT 1.6 THP",
//       "2008 SPORT 1.6 THP",
//       "2008 SPORT 1.6 THP",
//       "2008 SPORTWAY 1.6 115",
//       "205 1.4",
//       "205 1.8 DIESEL",
//       "205 CHARRO",
//       "205 CLD",
//       "205 D",
//       "205 D",
//       "205 GL",
//       "205 GL",
//       "205 GL",
//       "205 GLD",
//       "205 GLI",
//       "205 JUNIOR",
//       "205 MITO",
//       "205 MITO",
//       "205 MITO 1.4",
//       "205 ROLAND GARROS",
//       "205 SR",
//       "205 SRD",
//       "205 SX 1.3",
//       "205 XS",
//       "205 XT",
//       "206 1.6 SW EXCLUSIVE",
//       "206 1.6 SW XR PRESENCE",
//       "206 ACTIVE 1.4 N 5P",
//       "206 ALLURE 1.4 N 5P",
//       "206 COUPE CABRIOLET",
//       "206 COUPE CABRIOLET 2.0",
//       "206 CREAMFIELDS 1.6",
//       "206 CREAMFIELDS 1.9 D",
//       "206 DIESEL",
//       "206 ESCAPADE 1.6",
//       "206 GENERATION",
//       "206 GENERATION PLUS",
//       "206 GT",
//       "206 GTI",
//       "206 GTI",
//       "206 HDI",
//       "206 HDI 1.4",
//       "206 HDI 1.4",
//       "206 IRB 1.4 3P",
//       "206 IRB 1.4 5P",
//       "206 IRB 1.9D 5P",
//       "206 LIVE! 1.4 3P",
//       "206 LIVE! 1.4 5P",
//       "206 LIVE! 1.9D 5P",
//       "206 PREMIUM 1.6",
//       "206 PREMIUM 1.6 5P",
//       "206 PREMIUM 1.6 5P NAV",
//       "206 PREMIUM 1.6 5P",
//       "206 PREMIUM 1.6 5P",
//       "206 PREMIUM 1.9 D 5P NAV",
//       "206 PREMIUM 1.9D 5P",
//       "206 PREMIUM HDI 5P",
//       "206 PREMIUM HDI 5P NAV",
//       "206 PRESENCE 1.4 3P",
//       "206 QUIKSILVER",
//       "206 RALLYE",
//       "206 RC",
//       "206 RWC 1.4 3P",
//       "206 RWC 1.4 5P",
//       "206 RWC 1.9D 5P",
//       "206 SW CONFORT 1.4",
//       "206 SW CONFORT 1.6",
//       "206 SW PREMIUM 1.6",
//       "206 SW PREMIUM 1.6",
//       "206 SW QUIKSILVER 1.6",
//       "206 SW XS 2.0",
//       "206 SW XS PREMIUM 1.6",
//       "206 SW XT 1.6",
//       "206 X DESIGN 1.6",
//       "206 X DESIGN 1.9 D",
//       "206 X DESIGN 1.9 D",
//       "206 X-DESIGN 1.6 3P",
//       "206 XE 1.4 3P",
//       "206 X-LINE 1.4 3P NAV",
//       "206 X-LINE 1.4 5P",
//       "206 X-LINE 1.4 5P NAV",
//       "206 X-LINE 1.4. 3P",
//       "206 X-LINE 1.9 D 5P",
//       "206 X-LINE 1.9 D 5P NAV",
//       "206 XN",
//       "206 XN 1,4 3P",
//       "206 XN 1.4 3P",
//       "206 XND",
//       "206 XR",
//       "206 XR",
//       "206 XR 1.4",
//       "206 XR 1.4",
//       "206 XR 1.4",
//       "206 XR 1.4 3P",
//       "206 XR 1.4 3P DA",
//       "206 XR 1.4 3P DA AA",
//       "206 XR 1.4 3P MUX",
//       "206 XR 1.4 5P",
//       "206 XR 1.4 5P DA",
//       "206 XR 1.4 5P DA AA",
//       "206 XR 1.4 5P MUX",
//       "206 XR 1.4 AA 3P MUX",
//       "206 XR 1.4 AA 5P MUX",
//       "206 XR 1.6",
//       "206 XR 1.6",
//       "206 XR 1.6 3P",
//       "206 XR 1.6 5P",
//       "206 XR 1.6 5P PACK MUX",
//       "206 XR 1.6 PACK 3P MUX",
//       "206 XR 3P",
//       "206 XR 3P P",
//       "206 XR 3P PA",
//       "206 XR 5P",
//       "206 XR 5P P",
//       "206 XR 5P PA",
//       "206 XR 90 3P",
//       "206 XR 90 3P AA",
//       "206 XR 90 3P FULL",
//       "206 XR 90 3P PACK",
//       "206 XR 90 5P",
//       "206 XR 90 5P FULL",
//       "206 XR 90 5P PACK",
//       "206 XR CONFORT 1.4",
//       "206 XR PREMIUM 1.6 3P",
//       "206 XR PREMIUM 1.6 5P",
//       "206 XR PREMIUM 1.6 5P",
//       "206 XR PRESENCE 1.4 3P",
//       "206 XRD 3P",
//       "206 XRD 3P",
//       "206 XRD 3P AA",
//       "206 XRD 3P AA MUX",
//       "206 XRD 3P FULL",
//       "206 XRD 3P MUX",
//       "206 XRD 3P P",
//       "206 XRD 3P PA",
//       "206 XRD 3P PACK",
//       "206 XRD 3P PACK MUX",
//       "206 XRD 5P",
//       "206 XRD 5P",
//       "206 XRD 5P",
//       "206 XRD 5P AA",
//       "206 XRD 5P AA MUX",
//       "206 XRD 5P FULL",
//       "206 XRD 5P MUX",
//       "206 XRD 5P P",
//       "206 XRD 5P PA",
//       "206 XRD 5P PACK",
//       "206 XRD 5P PACK MUX",
//       "206 XRD AA 3P MUX",
//       "206 XRD AA MUX",
//       "206 XRD CONFORT",
//       "206 XRD PACK 3P MUX",
//       "206 XRD PACK 5P MUX",
//       "206 XRD PREMIUM",
//       "206 XRD PREMIUM",
//       "206 XRD PREMIUM 3P",
//       "206 XRD PREMIUM 5P",
//       "206 XRD PRES",
//       "206 XS 1.6",
//       "206 XS 1.6",
//       "206 XS 1.6 3P ABS",
//       "206 XS 1.6 3P NAV",
//       "206 XS 1.6 SW",
//       "206 XS 1.6 SW",
//       "206 XS 1.6E",
//       "206 XS 110",
//       "206 XS 3P",
//       "206 XS 90",
//       "206 XS PREMIUM 1.6 3P NAV",
//       "206 XS PREMIUM 1.6 5P",
//       "206 XS PREMIUM 1.6 5P",
//       "206 XS PREMIUM HDI 3P",
//       "206 XS PREMIUM HDI 3P NAV",
//       "206 XS PREMIUM HDI 5P",
//       "206 XS PREMUM 1.6 3P",
//       "206 XS TC 110 MUX",
//       "206 XT 1.6",
//       "206 XT 1.6",
//       "206 XT 1.6 5P",
//       "206 XT 1.6 5P ABS",
//       "206 XT 1.6 TIPTRONIC",
//       "206 XT 1.6E",
//       "206 XT 1.9 D 5P",
//       "206 XT 110",
//       "206 XT 110 MUX",
//       "206 XT 5P",
//       "206 XT 5P LA",
//       "206 XT 90",
//       "206 XT HDI",
//       "206 XT PREMIUM 1.6 5P",
//       "206 XT PREMIUM 1.6 5P NAV",
//       "206 XT PREMIUM 1.6 5P",
//       "206 XT PREMIUM 1.6 5P",
//       "206 XT PREMIUM HDI 5P",
//       "206 XT PREMIUM HDI 5P NAV",
//       "206 XT TC 110 MUX",
//       "206 XTD",
//       "206 XTD 1.9",
//       "206 XTD 1.9",
//       "206XR 3P 1.4",
//       "206XR 3P 1.4 DA",
//       "206XR 3P 110CV",
//       "206XR 5P 1.4",
//       "206XR 5P 1.4 DA",
//       "206XR 5P 110CV",
//       "206XRD 1.9",
//       "206XRD 1.9",
//       "206XRD3",
//       "206XRD3PK",
//       "206XRD3PKDAB",
//       "206XRD5",
//       "206XRD5PK",
//       "206XRD5PKDAB",
//       "206XRN3",
//       "206XRN3PK",
//       "206XRN3PKDAB",
//       "206XRN5",
//       "206XRN5PK",
//       "206XRN5PKDAB",
//       "206XS 110CV",
//       "206XSN3DABLA",
//       "206XTN5DAB",
//       "206XTN5DABLA",
//       "207",
//       "207",
//       "207 CC 120CV",
//       "207 CC 150CV",
//       "207 CC 150CV",
//       "207 CC 156 CV",
//       "207 COMPACT ACTIVE 1.4 HDI",
//       "207 COMPACT ACTIVE 1.4 N",
//       "207 COMPACT ACTIVE 1.4 N",
//       "207 COMPACT ACTIVE 1.4 N",
//       "207 COMPACT ALLURE 1.4",
//       "207 COMPACT ALLURE 1.4",
//       "207 COMPACT ALLURE 1.4 N",
//       "207 COMPACT ALLURE 1.4 N",
//       "207 COMPACT ALLURE 1.6 N",
//       "207 COMPACT ESCAPADE",
//       "207 COMPACT FELINE 1.4 HDI",
//       "207 COMPACT FELINE 1.4 HDI",
//       "207 COMPACT FELINE 1.6 N",
//       "207 COMPACT FELINE 1.6 N",
//       "207 COMPACT FELINE 1.6 N",
//       "207 COMPACT FELINE 1.6 N",
//       "207 COMPACT GRIFFE 1.6 N",
//       "207 COMPACT GRIFFE 1.6 N",
//       "207 COMPACT QUIKSILVER",
//       "207 COMPACT QUIKSILVER",
//       "207 COMPACT SW XS 1.6",
//       "207 COMPACT SW XT 1.6",
//       "207 COMPACT SW XT HDI",
//       "207 COMPACT XR 1.4",
//       "207 COMPACT XR 1.4 3P",
//       "207 COMPACT XR 1.4 5P",
//       "207 COMPACT XR 1.9D",
//       "207 COMPACT XR 1.9D 5P",
//       "207 COMPACT XR HDI 1.4",
//       "207 COMPACT XS 1.4",
//       "207 COMPACT XS 1.4 5P",
//       "207 COMPACT XS 1.6 5P",
//       "207 COMPACT XS 1.9D",
//       "207 COMPACT XS 1.9D 5P",
//       "207 COMPACT XS HDI 1.4",
//       "207 COMPACT XS HDI 5",
//       "207 COMPACT XT 1.6",
//       "207 COMPACT XT 1.6 3P",
//       "207 COMPACT XT 1.6 5P",
//       "207 COMPACT XT 1.6 5P",
//       "207 COMPACT XT 1.6",
//       "207 COMPACT XT 1.9D 5P",
//       "207 COMPACT XT HDI",
//       "207 COMPACT XT HDI 1.4",
//       "207 COMPACT XT HDI 3P",
//       "207 COMPACT XT HDI 5",
//       "207 COMPACT XT HDI 5P",
//       "207 COMPACT XT PREMIUM",
//       "207 COMPACT XT PREMIUM",
//       "207 COMPACT XT PREMIUM",
//       "207 COMPACT XT PREMIUM",
//       "207 COMPACT XT PREMIUM",
//       "207 GTI 156 CV",
//       "207 GTI 156 CV",
//       "207 PASSION XR",
//       "207 PASSION XR S",
//       "207 PREMIUM",
//       "207 RC",
//       "207 SW XR",
//       "207 XS PACK 1.6 HDI 90 CV",
//       "208",
//       "208 ACTIVE 1.5",
//       "208 ACTIVE 1.5 N",
//       "208 ACTIVE 1.6",
//       "208 ACTIVE 1.6 115",
//       "208 ACTIVE 1.6L",
//       "208 ACTIVE 1.6L AM23",
//       "208 ACTIVE 1.6L AM23.5",
//       "208 ACTIVE 1.6L TIPTRONIC",
//       "208 ACTIVE PACK 1.6L",
//       "208 ACTIVE PACK 1.6L",
//       "208 ACTIVE TIPTRONIC 1.6L",
//       "208 ACTIVE TIPTRONIC 1.6L",
//       "208 ALLURE 1.2",
//       "208 ALLURE 1.5 N",
//       "208 ALLURE 1.5 N",
//       "208 ALLURE 1.5 NAV",
//       "208 ALLURE 1.5 SMEG",
//       "208 ALLURE 1.6",
//       "208 ALLURE 1.6 115",
//       "208 ALLURE 1.6 5P TIPTRONIC",
//       "208 ALLURE 1.6 N",
//       "208 ALLURE 1.6 TIPTRONIC 6",
//       "208 ALLURE 1.6 TIPTRONIC 6",
//       "208 ALLURE 1.6 TIPTRONIC",
//       "208 ALLURE 1.6 TIPTRONIC",
//       "208 ALLURE 1.6L",
//       "208 ALLURE 1.6L AM23",
//       "208 ALLURE 1.6L AM23.5",
//       "208 ALLURE 1.6L TIPTRONIC",
//       "208 ALLURE 1.6N",
//       "208 ALLURE PACK 1.6L",
//       "208 ALLURE PACK 1.6L",
//       "208 ALLURE PLUS 1.6 HDI",
//       "208 ALLURE PLUS 1.6 HDI 92",
//       "208 ALLURE TIPTRONIC 1.6L",
//       "208 ALLURE TIPTRONIC 1.6L",
//       "208 FELINE 1.6",
//       "208 FELINE 1.6 115",
//       "208 FELINE 1.6 N",
//       "208 FELINE 1.6 N PACK CUIR",
//       "208 FELINE 1.6 TIPTRONIC 6",
//       "208 FELINE 1.6 TIPTRONIC 6",
//       "208 FELINE 1.6L TIPTRONIC",
//       "208 FELINE TIPTRONIC 1.6L",
//       "208 FELINE TIPTRONIC 1.6L",
//       "208 GT 1.6 THP",
//       "208 GT 1.6 THP 165",
//       "208 GT LINE TIPTRONIC 130",
//       "208 GT TIPTRONIC 130 CV",
//       "208 GT TIPTRONIC 130 CV",
//       "208 GTI",
//       "208 IN CONCERT 1.6 115",
//       "208 LIKE 1.2L",
//       "208 LIKE 1.2L 82CV AM23",
//       "208 LIKE 1.2L AM23",
//       "208 LIKE PACK 1.2L AM23",
//       "208 LIKE PACK 1.6L",
//       "208 LIKE PACK 1.6L AM23",
//       "208 NEW LIKE 1.2L AM23.5",
//       "208 ROADTRIP 1.6L AM23",
//       "208 ROADTRIP TIPTRONIC",
//       "208 STYLE",
//       "208 STYLE 1.6L AM23",
//       "208 STYLE AT23.5",
//       "208 STYLE MT23.5",
//       "208 STYLE TIPTRONIC 1.6L",
//       "208 URBAN TECH 1.6",
//       "208 URBAN TECH 1.6 115",
//       "208 XY",
//       "3008",
//       "3008 1.6 A/T",
//       "3008 1.6 THP",
//       "3008 ALLURE",
//       "3008 ALLURE THP TIP",
//       "3008 ALLURE THP TIPTRONIC",
//       "3008 ALLURE THP TIPTRONIC",
//       "3008 ALLURE THP TIPTRONIC",
//       "3008 ALLURE THP TIPTRONIC",
//       "3008 ALLURE TIPTRONIC",
//       "3008 FELINE",
//       "3008 FELINE TIPTRONIC",
//       "3008 FELINE TIPTRONIC HDI",
//       "3008 GT LINE HDI TIPTRONIC",
//       "3008 GT LINE TIPTRONIC",
//       "3008 GT PACK HDI TIPTRONIC",
//       "3008 GT PACK HDI TIPTRONIC",
//       "3008 GT PACK HDI TIPTRONIC",
//       "3008 GT PACK HYBRID4 AM22",
//       "3008 GT PACK PHEV PRS",
//       "3008 GT PACK THP TIPTRONIC",
//       "3008 GT PACK THP TIPTRONIC",
//       "3008 GT PACK THP TIPTRONIC",
//       "3008 GT-LINE HDI TIP",
//       "3008 GT-LINE HDI TIPTRONIC",
//       "3008 GT-LINE THP TIP",
//       "3008 GT-LINE THP TIPTRONIC",
//       "3008 PREMIUM 156 CV",
//       "3008 PREMIUM PLUS 156 CV",
//       "3008 PREMIUM PLUS 156CV",
//       "3008 PREMIUM PLUS 156CV",
//       "3008 PREMIUM PLUS 163 CV",
//       "3008 PREMIUM PLUS 163 CV",
//       "3008 ROLAND GARROS",
//       "3008 ROLAND GARROS",
//       "301 ALLURE 1.6",
//       "301 ALLURE 1.6 HDI",
//       "301 ALLURE 1.6 HDI",
//       "301 ALLURE PLUS 1.6",
//       "301 ALLURE PLUS 1.6 HDI",
//       "301 ALLURE PLUS 1.6",
//       "306",
//       "306",
//       "306",
//       "306 1.8 CABRIOLET",
//       "306 1.8 L",
//       "306 2.0 CABRIOLET",
//       "306 2.0 HDI",
//       "306 2.0 HDI",
//       "306 2.0 TD HDI",
//       "306 BOULEVARD",
//       "306 BOULEVARD",
//       "306 BOULEVARD 1.9 DT",
//       "306 BOULEVARD 1.9D",
//       "306 BOULEVARD 1.9D",
//       "306 BOULEVARD 2.0 HDI",
//       "306 BREAK KILIWI 1.9D",
//       "306 BREAK XR 2.0 HDI",
//       "306 BRL. 4P. D",
//       "306 BRL. 4P. N",
//       "306 BRL.5P.D",
//       "306 BRL.5P.N",
//       "306 EQUINOXE",
//       "306 EQUINOXE TD",
//       "306 EQUINOXE TD",
//       "306 EQX. 4P N",
//       "306 EQX. 4P. D",
//       "306 EQX. 5P. D",
//       "306 EQX. 5P. N",
//       "306 GRAFFIC DIESEL",
//       "306 GRAFFIC TD",
//       "306 GTI",
//       "306 KILIWI 1.9D",
//       "306 OTROS",
//       "306 RLY 3P. N",
//       "306 ROLAND GARROS 1.8",
//       "306 ROLAND GARROS 1.8E",
//       "306 ROLAND GARROS 1.8E",
//       "306 S",
//       "306 S 16",
//       "306 S 16",
//       "306 SL",
//       "306 SL",
//       "306 SL 1.8",
//       "306 SRD",
//       "306 SRDT",
//       "306 ST",
//       "306 ST",
//       "306 ST",
//       "306 STD",
//       "306 STD",
//       "306 STDT",
//       "306 STYLE",
//       "306 STYLE",
//       "306 STYLE",
//       "306 TARIFFA",
//       "306 TD",
//       "306 XN",
//       "306 XN",
//       "306 XN",
//       "306 XN",
//       "306 XN",
//       "306 XN 4P",
//       "306 XN 4P 16V",
//       "306 XN 5P",
//       "306 XN 5P 16V",
//       "306 XN AA 4P",
//       "306 XN AA 5P",
//       "306 XND",
//       "306 XND",
//       "306 XND",
//       "306 XND",
//       "306 XND 4P",
//       "306 XND 5P",
//       "306 XND AA 4P",
//       "306 XND AA 5P",
//       "306 XND/XRD",
//       "306 XR",
//       "306 XR",
//       "306 XR",
//       "306 XR",
//       "306 XR 1.8",
//       "306 XR 16 V 4P",
//       "306 XR 16V 5P",
//       "306 XR 8V 4P",
//       "306 XR 8V 5P",
//       "306 XR DIESEL",
//       "306 XR DIESEL",
//       "306 XR DIESEL",
//       "306 XR HDI",
//       "306 XR/XSI/XS",
//       "306 XRD",
//       "306 XRD",
//       "306 XRD",
//       "306 XRD",
//       "306 XRD",
//       "306 XRD 1.9",
//       "306 XRD 1.9",
//       "306 XRD 4P",
//       "306 XRD 5P",
//       "306 XRD BREAK 1.9",
//       "306 XRD T 5P",
//       "306 XRDT",
//       "306 XRDT",
//       "306 XRDT",
//       "306 XRDT",
//       "306 XRDT 1.9",
//       "306 XRDT 4P",
//       "306 XS",
//       "306 XS",
//       "306 XS 1.8",
//       "306 XSDT",
//       "306 XSI",
//       "306 XT",
//       "306 XT",
//       "306 XT 1.8",
//       "306 XT 16 V ABS",
//       "306 XT 16V",
//       "306 XT 2.0 HDI",
//       "306 XT 2.0 HDI",
//       "306 XT 2.0 HDI",
//       "306 XT DIESEL",
//       "306 XT DIESEL",
//       "306 XTD",
//       "306 XTDT",
//       "306 XTDT",
//       "306 XTDT",
//       "306 XTDT",
//       "306 XTDT 1.9",
//       "306 XTDT ABS",
//       "306 XTDT/XRDT",
//       "306BRLD4AA",
//       "306BRLD5AA",
//       "306BRLHDI4",
//       "306BRLN4",
//       "306BRLN4AA",
//       "306BRLN5",
//       "306BRLN5AA",
//       "306EQHDI4ABS",
//       "306EQHDI5ABS",
//       "306EQN4ABS",
//       "306EQN5ABS",
//       "306EQTD4ABS",
//       "306EQTD5ABS",
//       "306RLEN3ABS",
//       "306SOLHDI4",
//       "306SOLN4",
//       "306TMHDI5",
//       "306TMN3",
//       "306TMN5",
//       "307 1.6",
//       "307 1.6",
//       "307 1.6 X-LINE",
//       "307 2.0",
//       "307 CC 1.6",
//       "307 CC 1.6",
//       "307 CC 2.0",
//       "307 CC 2.0 180 CV",
//       "307 CC 2.0 TIPTRONIC",
//       "307 CC 2.0 TIPTRONIC 143CV",
//       "307 COUPE CABRIOLET",
//       "307 HDI",
//       "307 HDI",
//       "307 HDI",
//       "307 HDI",
//       "307 HDI XS",
//       "307 HDI XS",
//       "307 LIVE 5P 1.6 110 CV",
//       "307 LIVE 5P 2.0 HDI 110 CV",
//       "307 RALLYE",
//       "307 RWC 1.6 5P 110 CV",
//       "307 RWC 2.0 DHI 5P 90 CV",
//       "307 SS LA DOLFINA 2.0 143",
//       "307 SW 1.6",
//       "307 SW 2.0",
//       "307 SW 2.0 A/T",
//       "307 SW HDI",
//       "307 SW HDI 1.6",
//       "307 SW PREMIUM 1.6HDI",
//       "307 SW PREMIUM 2.0",
//       "307 SW PREMIUM 2.0 143CV",
//       "307 SW PREMIUM 2.0 HDI",
//       "307 SW PREMIUM 2.0",
//       "307 SW PREMIUM HDI",
//       "307 SW PREMIUM HDI 110 CV",
//       "307 Test Match 1.6",
//       "307 TEST MATCH HDI",
//       "307 XR 1.6",
//       "307 XR 1.6",
//       "307 XR 1.6",
//       "307 XR 1.6",
//       "307 XR 1.6 110CV",
//       "307 XR 1.6 16V",
//       "307 XR 1.6 5P 110CV",
//       "307 XR 2.0 HDI 4P 90 CV",
//       "307 XR 2.0HDI",
//       "307 XR HDI",
//       "307 XR PACK 1.6",
//       "307 XRD 2.0HDI",
//       "307 XRD 2.0HDI",
//       "307 XS 1.6",
//       "307 XS 1.6",
//       "307 XS 1.6",
//       "307 XS 1.6 4P 110CV",
//       "307 XS 1.6 5P 110CV",
//       "307 XS 2.0",
//       "307 XS 2.0 HDI",
//       "307 XS 2.0 HDI 4P 110 CV",
//       "307 XS 2.0 HDI 4P 90 CV",
//       "307 XS 2.0 HDI 5P 110 CV",
//       "307 XS 2.0 HDI 5P 90CV",
//       "307 XS PREMIUM 2.0",
//       "307 XS PREMIUM 2.0 4P",
//       "307 XS PREMIUM 2.0 5P",
//       "307 XS PREMIUM 2.0 HDI 4P",
//       "307 XS PREMIUM 2.0 HDI 4P",
//       "307 XS PREMIUM 2.0 HDI 5P",
//       "307 XS PREMIUM 2.0 HDI 5P",
//       "307 XS PREMIUM HDI",
//       "307 XS PREMIUM HDI",
//       "307 XS PREMIUM TIPTRONIC",
//       "307 XS PREMIUM TIPTRONIC",
//       "307 XS PREMIUM TIPTRONIC",
//       "307 XS PREMIUMHDI 5P",
//       "307 XSI 2.0",
//       "307 XSI 2.0 143 CV",
//       "307 XSI 2.0 TIPTRONIC",
//       "307 XT 1.6 4P 110 CV",
//       "307 XT 1.6 5P 110CV",
//       "307 XT 2.0",
//       "307 XT 2.0 HDI",
//       "307 XT 2.0 HDI",
//       "307 XT 2.0 HDI 4P 110CV",
//       "307 XT 2.0 HDI 5P 110 CV",
//       "307 XT 2.0 PREMIUM",
//       "307 XT HDI PREMIUM",
//       "307 XT PACK 2.0 HDI",
//       "307 XT PACK 2.0 TRIPTRONIC",
//       "307 XT PREMIUM 1.6 5P",
//       "307 XT PREMIUM 2.0",
//       "307 XT PREMIUM 2.0 4P 143",
//       "307 XT PREMIUM 2.0 4P",
//       "307 XT PREMIUM 2.0 4P",
//       "307 XT PREMIUM 2.0 5P",
//       "307 XT PREMIUM 2.0 5P",
//       "307 XT PREMIUM 2.0 HDI 4P",
//       "307 XT PREMIUM 2.0 HDI 5P",
//       "307 XT PREMIUM 2.0 HDI 5P",
//       "307 XT PREMIUM 2.0",
//       "307 XT PREMIUM HDI",
//       "307 XT PREMIUM HDI 4P 90",
//       "307 XT PREMIUM TIPTRONIC",
//       "307SW PREMIUM",
//       "307SW ROLAND GARROS",
//       "308 1.6",
//       "308 1.6 HDI",
//       "308 ACTIVE 1.6",
//       "308 ACTIVE 1.6 NAV",
//       "308 ACTIVE 1.6N",
//       "308 ACTIVE HDI",
//       "308 ALLURE 1.6",
//       "308 ALLURE 1.6 AM20",
//       "308 ALLURE 1.6 AM22",
//       "308 ALLURE 1.6 HDI",
//       "308 ALLURE 1.6 HDI AM20",
//       "308 ALLURE 1.6 HDI AM22",
//       "308 ALLURE 1.6N",
//       "308 ALLURE 1.6N NAV",
//       "308 ALLURE HDI",
//       "308 ALLURE HDI NAV",
//       "308 ALLURE PACK 1.6",
//       "308 ALLURE PACK 1.6 HDI",
//       "308 ALLURE PACK THP",
//       "308 ALLURE PLUS 2.0",
//       "308 CC",
//       "308 CC 1.6 T 143 CV",
//       "308 CC 1.6 T 152 CV",
//       "308 CC 1.6 T 152 CV",
//       "308 CC 1.6 T TIPTRONIC THP",
//       "308 CC 1.6 T TIPTRONIC THP",
//       "308 CC 1.6 TURBO THP 152",
//       "308 CC 1.6 TURBO THP 156",
//       "308 CONFORT HDI",
//       "308 FELINE 1.6 HDI",
//       "308 FELINE 1.6 HDI AM20",
//       "308 FELINE 1.6 HDI AM22",
//       "308 FELINE 2.0 N",
//       "308 FELINE HDI",
//       "308 FELINE THP",
//       "308 FELINE THP TIPRONIC",
//       "308 FELINE THP TIPTRONIC",
//       "308 FELINE THP TIPTRONIC",
//       "308 FELINE TIPTRONIC 2.0 N",
//       "308 GTI 200 CV",
//       "308 ROLAND GARROS",
//       "308 ROLAND GARROS HDI",
//       "308 ROLAND GARROS THP",
//       "308 SPORT",
//       "308 SW",
//       "308 SW CONFORT HDI",
//       "308S ALLURE PLUS TIPTRONIC",
//       "308S ALLURE PLUS TIPTRONIC",
//       "308S GT",
//       "308S GTI",
//       "308S GTI 270",
//       "308S GTI 270 COUPE",
//       "308S GTI COUPE FRANCHE",
//       "4008 ALLURE 4X4",
//       "4008 ALLURE 4X4 CVT",
//       "4008 FELINE 4X4 CVT 2.0",
//       "405",
//       "405 BL GLD 19",
//       "405 DIESEL",
//       "405 DL",
//       "405 GL 1.9 DIESEL",
//       "405 GL BREAK",
//       "405 GLD",
//       "405 GLD",
//       "405 GLD 1.9",
//       "405 GLD 1.9",
//       "405 GLD BREAK",
//       "405 GLD EMBASSY",
//       "405 GLD SILLAGE",
//       "405 GLD TC",
//       "405 GLD TURBO",
//       "405 GLD/GLD/EMBASSY",
//       "405 GLDT",
//       "405 GLI",
//       "405 GLI 1.8",
//       "405 GLI/GL",
//       "405 GLX",
//       "405 GR",
//       "405 GR (T. fij.)",
//       "405 GR (T.cor.) / GRI",
//       "405 GR 1.9",
//       "405 GR TC",
//       "405 GR TF",
//       "405 GRD",
//       "405 GRDT SIGNAT",
//       "405 GRI",
//       "405 GRI",
//       "405 GRI",
//       "405 OTROS",
//       "405 S",
//       "405 SIGNATURE",
//       "405 SIGNATURE",
//       "405 SIGNATURE D",
//       "405 SIGNATURE DIESEL",
//       "405 SIGNATURE N",
//       "405 SIGNATURE TD",
//       "405 SIGNATURE/D",
//       "405 SILLAGE",
//       "405 SILLAGE D",
//       "405 SILLAGE N",
//       "405 SLI",
//       "405 SR",
//       "405 SR",
//       "405 SR",
//       "405 SR SC",
//       "405 SRI",
//       "405 SRI / SRIABS",
//       "405 SRI AUTOMATICO",
//       "405 SRI CM",
//       "405 STI",
//       "405 STI",
//       "405 STYLE",
//       "405 STYLE",
//       "405 STYLE D",
//       "405 STYLE DA",
//       "405 STYLE N",
//       "405 STYLE NA",
//       "405 SXI",
//       "405STYLED",
//       "405STYLEDAA",
//       "405STYLEDPOL",
//       "405STYLEN",
//       "405STYLENAA",
//       "406",
//       "406",
//       "406",
//       "406 2.0",
//       "406 2.0",
//       "406 2.2",
//       "406 COUPE 3.0",
//       "406 HDI",
//       "406 HDI",
//       "406 HPI",
//       "406 PACK CONFORT 2.0 HDI",
//       "406 PACK CONFORT 2.2",
//       "406 PACK CONFORT ST 3.0",
//       "406 SL",
//       "406 SLDT",
//       "406 SLDT",
//       "406 SR",
//       "406 SR",
//       "406 SR 2.0 HDI",
//       "406 SR 2.0 HDI",
//       "406 SRDT",
//       "406 SRDT",
//       "406 ST",
//       "406 ST",
//       "406 ST 1.8",
//       "406 ST 2.0 HDI",
//       "406 ST 2.0 HDI",
//       "406 ST 2.2",
//       "406 STDT",
//       "406 STDT",
//       "406 STDT 2.0 HDI",
//       "406 SV",
//       "406 SV 2.0",
//       "406 SV 2.0",
//       "406 SV 2.0 HDI",
//       "406 SV 2.0 HDI TD",
//       "406 SV 2.0 TD",
//       "406 SV 2.1 TD",
//       "406 SV 2.2",
//       "406 SV 3.0 V6",
//       "406 SVDT",
//       "406 XS PREMIUM 2.2",
//       "406 XS PREMIUM HDI",
//       "406 XT PREMIUM 3.0 V6",
//       "406 XT PREMIUM 3.0 V6",
//       "406 XT PREMIUM HDI",
//       "406 XT PREMIUM HDI BREAK",
//       "406ST PACK CONFORT V6",
//       "407",
//       "407 2.0",
//       "407 3.0 V6 TIPTRONIC",
//       "407 A/T HDI",
//       "407 HDI",
//       "407 HDI TIPTRONIC",
//       "407 SPORT 2.0 HDI",
//       "407 SR",
//       "407 SR SPORT 2.0",
//       "407 SR SPORT HDI",
//       "407 ST CONFORT 2.0HDI",
//       "407 ST CONFORT HDI",
//       "407 ST SPORT 2.2",
//       "407 ST SPORT 2.2 TIPTRONIC",
//       "407 ST SPORT HDI",
//       "407 ST SPORT HDI TIPTRONIC",
//       "407 SV HDI",
//       "407 SV SPORT 2.0",
//       "407 SV SPORT 2.2",
//       "407 SV SPORT HDI",
//       "407 SV SPORT HDI 2.2",
//       "407 SV SPORT HDI TIPTRONIC",
//       "407 SV SPORT V6 TIPTRONIC",
//       "407 SW",
//       "407 SW 2.0 HDI",
//       "407 SW EXECUTIVE HDI",
//       "407 SW EXECUTIVE HDI",
//       "407 SW SV SPORT 2.2",
//       "407 SW SV SPORT HDI",
//       "407 SW SV SPORT HDI",
//       "407 V6 SPORT TIPTRONIC",
//       "408 ACTIVE 1.6",
//       "408 ALLURE 1.6",
//       "408 ALLURE 1.6 AM20",
//       "408 ALLURE 1.6 AM22",
//       "408 ALLURE 1.6 HDI",
//       "408 ALLURE 1.6 HDI AM20",
//       "408 ALLURE 1.6 HDI AM22",
//       "408 ALLURE 2.0",
//       "408 ALLURE 2.0",
//       "408 ALLURE 2.0 N NAV",
//       "408 ALLURE 2.0N",
//       "408 ALLURE HDI",
//       "408 ALLURE HDI NAV",
//       "408 ALLURE PACK 1.6",
//       "408 ALLURE PACK 1.6 HDI",
//       "408 ALLURE PACK THP",
//       "408 ALLURE PLUS 2.0",
//       "408 ALLURE PLUS THP",
//       "408 ALLURE TIPTRONIC 2.0N",
//       "408 ALLURE+ 2.0N",
//       "408 ALLURE+ HDI",
//       "408 ALLURE+ TIPTRONIC 2.0N",
//       "408 FELINE 1.6 HDI",
//       "408 FELINE 1.6 HDI AM20",
//       "408 FELINE 1.6 HDI AM22",
//       "408 FELINE 2.0N",
//       "408 FELINE HDI",
//       "408 FELINE THP TIPTRONIC",
//       "408 FELINE THP TIPTRONIC",
//       "408 FELINE THP TIPTRONIC",
//       "408 SPORT 163 CV",
//       "5008 1.6",
//       "5008 1.6 A/T",
//       "5008 ALLURE",
//       "5008 ALLURE 7 PLAZAS",
//       "5008 ALLURE PACK HDI TIP",
//       "5008 ALLURE PACK HDI",
//       "5008 ALLURE PACK THP TIP",
//       "5008 ALLURE PACK THP",
//       "5008 ALLURE PLUS 7 PLAZAS",
//       "5008 ALLURE PLUS HDI TIP",
//       "5008 ALLURE PLUS HDI",
//       "5008 ALLURE PLUS HDI",
//       "5008 ALLURE PLUS HDI",
//       "5008 ALLURE PLUS HDI",
//       "5008 ALLURE PLUS THP",
//       "5008 ALLURE PLUS TIPTRONIC",
//       "5008 ALLURE THP TIP",
//       "5008 ALLURE THP TIPTRONIC",
//       "5008 ALLURE TIPTRONIC",
//       "5008 ALLURE TIPTRONIC 5",
//       "5008 ALLURE TRIPTRONIC",
//       "5008 FELINE",
//       "5008 FELINE TIPTRONIC",
//       "5008 FELINE TRIPTRONIC",
//       "504",
//       "504 AMD DIE.",
//       "504 D",
//       "504 FAMILIAR",
//       "504 G",
//       "504 G",
//       "504 GD",
//       "504 GD",
//       "504 GD",
//       "504 GD TX",
//       "504 GL DIESEL",
//       "504 GPU",
//       "504 GR",
//       "504 GRD DIESEL",
//       "504 NAFTERO",
//       "504 OTROS",
//       "504 P-UP DIE",
//       "504 P-UP G/GD/GRD",
//       "504 SDL",
//       "504 SL",
//       "504 SLAA",
//       "504 SLD",
//       "504 SLD AA",
//       "504 SLD DA",
//       "504 SLD TX",
//       "504 SRTF/FA/XS TF/XS",
//       "504 SRX",
//       "504 XS",
//       "504 XS AA.",
//       "504 XSD",
//       "504 XSD AA",
//       "504 XSDT",
//       "504 XSDTF",
//       "504 XSI",
//       "504/GD/G",
//       "504D/SRDTCA/XSDTF/XSDTAX",
//       "505",
//       "505",
//       "505 GL",
//       "505 GL",
//       "505 GL",
//       "505 GLS",
//       "505 GR/SR/SC",
//       "505 OTROS",
//       "505 RS",
//       "505 S",
//       "505 S",
//       "505 SI",
//       "505 SR",
//       "505 SR CONFOR",
//       "505 SR FULL",
//       "505 SR S.CONF",
//       "505 SRX",
//       "505 ST",
//       "505 ST",
//       "505 STI",
//       "508 1.6 TIPTRONIC",
//       "508 ALLURE 2.0 HDI 163 CV",
//       "508 ALLURE N 156 CV TIP",
//       "508 FELINE 2.0 HDI 163 CV",
//       "508 FELINE 2.0 HDI TIP",
//       "508 FELINE HDI",
//       "508 FELINE N 156 CV TIP",
//       "508 FELINE TIPTRONIC",
//       "508 GT 2.2 HDI 2000CV TIP",
//       "605 AUT",
//       "605 OTROS",
//       "605 SV",
//       "605 SV 3.0",
//       "605 SV3.0 AUTOM",
//       "605 SVDT",
//       "607 2.2 AUTOMATICA",
//       "607 2.2 HDI",
//       "607 3.0 V6 TIPTRONIC",
//       "607 V6 AUTOMATICA",
//       "607 V6 MANUAL",
//       "806",
//       "806 OTROS",
//       "806 SR",
//       "806 SRDT",
//       "806 SRDT",
//       "806 ST",
//       "806 ST 2.0",
//       "806 ST HDI",
//       "806 ST TURBO",
//       "806 SV",
//       "806 SVDT",
//       "806/ST",
//       "806ST TD",
//       "807 FELINE 2.0 HDI",
//       "807 HDI 2.0",
//       "807 SR 2.0",
//       "807 SR 2.0 HDI",
//       "807 ST 2.0 HDI",
//       "807 SV 2.0 HDI",
//       "AMB. 504D/N",
//       "BERLINA 504 SX",
//       "BOXER",
//       "BOXER",
//       "BOXER 2.2",
//       "BOXER 270 CD",
//       "BOXER 270C",
//       "BOXER 310 C DIESEL",
//       "BOXER 320 M 2.5 TD",
//       "BOXER 320 MH",
//       "BOXER 320M 2.5D",
//       "BOXER 320M 2.5D",
//       "BOXER 320M 2.8D",
//       "BOXER 320M 2.8D",
//       "BOXER 320M TURBO DIESEL",
//       "BOXER 330C 2.8D",
//       "BOXER 330M 2.3 HDI",
//       "BOXER 330M 2.8HDI",
//       "BOXER 330M 2.8TD",
//       "BOXER 330M CONFORT 2.3",
//       "BOXER 350 LH 2.3 HDI",
//       "BOXER 350 LH CONFORT2.3",
//       "BOXER 350 LH TD",
//       "BOXER 350 MH",
//       "BOXER 350 MH 2.3 HDI",
//       "BOXER 350 MH CONFORT 2.3",
//       "BOXER 350LH CONFORT",
//       "BOXER 350M 2.8D",
//       "BOXER 350MH 2.8HDI",
//       "BOXER 350MH 2.5TD",
//       "BOXER 350MH 2.8D",
//       "BOXER 350MH 2.8TD",
//       "BOXER COMBI 320M 2.8D",
//       "BOXER COMBI 320M 2.8D",
//       "BOXER COMBI 330M",
//       "BOXER COMBI 330M 2.8 D",
//       "BOXER COMBI 330M 2.8 TD",
//       "BOXER COMBI 330M",
//       "BOXER COMBI 330M",
//       "BOXER COMBI 330M",
//       "BOXER FT 320M 2.8D",
//       "BOXER FT 330M 2.8D",
//       "BOXER MINIBUS 15+1 2.8 TD",
//       "BOXER PREMIUM 2.2 HDI",
//       "BOXER PREMIUM 2.2 HDI",
//       "BOXER PREMIUM 2.2 HDI",
//       "BOXER PREMIUM 2.2 HDI",
//       "BOXER PREMIUM 2.2 HDI",
//       "BOXER PREMIUM 2.2 HDI",
//       "BOXER PREMIUM 2.2 HDI",
//       "BOXER PREMIUM 2.2 HDI",
//       "BOXER PREMIUM 2.2 HDI 440",
//       "BOXER PREMIUM 2.2 HDI 440",
//       "BOXER TOLE",
//       "BOXER TURBO DIESEL",
//       "BOXER TURBO DIESEL",
//       "C3 AIRCROSS 1.6 VTI 115 EXC.",
//       "C3 PICASSO 1.6 VTI 115 EXC.",
//       "CABRIOLET 306",
//       "D 504 2304 CC",
//       "EXPERT",
//       "EXPERT",
//       "EXPERT 1.6 HDI CONFORT",
//       "EXPERT 1.9 D TURBO",
//       "EXPERT 2.0 HDI CONFORT",
//       "EXPERT CONFORT 1.9D",
//       "EXPERT CONFORT HDI",
//       "EXPERT HDI",
//       "EXPERT HDI CAJA MANUAL",
//       "EXPERT PREMIUM 1.6 HDI",
//       "EXPERT PREMIUM 1.6 HDI 6P",
//       "EXPERT ST 1.9D",
//       "EXPERT TEPEE 2.0 HDI",
//       "EXPERT TEPEE BUSINESS HDI",
//       "EXPERT TEPEE BUSINESS HDI",
//       "EXPERT TURBO D",
//       "GRAND RAID MAYA",
//       "HOGGAR ESCAPADE 1.6",
//       "HOGGAR XR 1.6",
//       "HOGGAR XS 1.6",
//       "J5 D",
//       "LA DOLFINA 2.0 HDI 110 CV",
//       "PAR FU D DA 600",
//       "PAR FU D DA PLC 600",
//       "PAR FU D DA PLC AA 800",
//       "PAR FU N DA 600",
//       "PAR FU N DA 600 P/GNC",
//       "PAR URB D",
//       "PAR URB D AA",
//       "PAR URB N",
//       "PAR URB N AA",
//       "PARFUD6",
//       "PARFUD6PL",
//       "PARFUD6PLDA",
//       "PARFUD6PLDAA",
//       "PARFUD8PLDA",
//       "PARFUD8PLDAA",
//       "PARFUN6",
//       "PARPAD2PLN1",
//       "PARPAD2PLN2",
//       "PARPAD2PLN3",
//       "PARPADDAA",
//       "PARPADDAADAB",
//       "PARPAHDI2PLC",
//       "PARPAN2PLN1",
//       "PARPAN2PLN2",
//       "PARPAN2PLN3",
//       "PARPANDAA",
//       "PARPANDAADAB",
//       "PART AA-800K",
//       "PARTNER",
//       "PARTNER",
//       "PARTNER",
//       "PARTNER",
//       "PARTNER 1.6N AA",
//       "PARTNER 1.8I",
//       "PARTNER 1.9 D",
//       "PARTNER 1.9 D 2000",
//       "PARTNER 1.9 PLC",
//       "PARTNER 1.9AA",
//       "PARTNER 1.9D",
//       "PARTNER 1.9D",
//       "PARTNER 1.9D CONFORT",
//       "PARTNER 1.9DA",
//       "PARTNER 170C DIESEL",
//       "PARTNER 800K",
//       "PARTNER AAPL",
//       "PARTNER BRKD",
//       "PARTNER BRKN",
//       "PARTNER COMBISPACE",
//       "PARTNER CONFORT 1.4",
//       "PARTNER CONFORT 1.4 5",
//       "PARTNER CONFORT 1.6",
//       "PARTNER CONFORT 1.6 115 5",
//       "PARTNER CONFORT 1.6 115",
//       "PARTNER CONFORT 1.6 115",
//       "PARTNER CONFORT 1.6 5",
//       "PARTNER CONFORT 1.6 HDI",
//       "PARTNER CONFORT 1.6 HDI 5",
//       "PARTNER CONFORT 1.6 HDI",
//       "PARTNER CONFORT 1.6 HDI",
//       "PARTNER CONFORT 1.6 HDI",
//       "PARTNER CONFORT 1.6 HDI",
//       "PARTNER CONFORT 1.6 VTC",
//       "PARTNER CONFORT HDI 1.6",
//       "PARTNER DAPL",
//       "PARTNER DPL",
//       "PARTNER DPLC PRESENCE",
//       "PARTNER FURGON 1.4",
//       "PARTNER FURGON 1.4 D",
//       "PARTNER FURGON 1.4 GNC",
//       "PARTNER FURGON 1.4 N",
//       "PARTNER FURGON 1.4 N",
//       "PARTNER FURGON 1.4 N",
//       "PARTNER FURGON 1.4 N",
//       "PARTNER FURGON 1.4 N",
//       "PARTNER FURGON 1.4 N",
//       "PARTNER FURGON 1.4 PLC",
//       "PARTNER FURGON 1.4N PLC",
//       "PARTNER FURGON 1.6 N",
//       "PARTNER FURGON 1.9 D",
//       "PARTNER FURGON 1.9 D PLC",
//       "PARTNER FURGON 1.9 D PLC",
//       "PARTNER FURGON 1.9",
//       "PARTNER FURGON D",
//       "PARTNER FURGON D",
//       "PARTNER FURGON DIESEL DA",
//       "PARTNER FURGON DIESEL DA",
//       "PARTNER FURGON DIESEL DA",
//       "PARTNER FURGON NAFTA DA",
//       "PARTNER FURGON NAFTA DA",
//       "PARTNER HDI",
//       "PARTNER PATAGONICA 1 PLC",
//       "PARTNER PATAGONICA 1 PLC",
//       "PARTNER PATAGONICA 1 PLC",
//       "PARTNER PATAGONICA 1.4",
//       "PARTNER PATAGONICA 1.6",
//       "PARTNER PATAGONICA 1.6",
//       "PARTNER PATAGONICA 1.6",
//       "PARTNER PATAGONICA 1.6",
//       "PARTNER PATAGONICA 1.6",
//       "PARTNER PATAGONICA 1.6",
//       "PARTNER PATAGONICA 1.6",
//       "PARTNER PATAGONICA 1.6 N",
//       "PARTNER PATAGONICA 1.6N",
//       "PARTNER PATAGONICA 1.6N",
//       "PARTNER PATAGONICA 1.9 D",
//       "PARTNER PATAGONICA 1PLC",
//       "PARTNER PATAGONICA 1PLC",
//       "PARTNER PATAGONICA 2 PLC",
//       "PARTNER PATAGONICA 2 PLC",
//       "PARTNER PATAGONICA 2 PLC",
//       "PARTNER PATAGONICA 2PLC",
//       "PARTNER PATAGONICA 2PLC",
//       "PARTNER PATAGONICA 2PLC",
//       "PARTNER PATAGONICA D 02",
//       "PARTNER PATAGONICA D AA",
//       "PARTNER PATAGONICA D",
//       "PARTNER PATAGONICA HDI",
//       "PARTNER PATAGONICA HDI",
//       "PARTNER PATAGONICA HDI",
//       "PARTNER PATAGONICA HDI",
//       "PARTNER PATAGONICA HDI",
//       "PARTNER PATAGONICA PACK",
//       "PARTNER PATAGONICA PACK",
//       "PARTNER PATAGONICA VTC",
//       "PARTNER PATAGONICA VTC",
//       "PARTNER PATAGONICA VTC",
//       "PARTNER PATAGONICA VTC",
//       "PARTNER PATAGONICA VTC",
//       "PARTNER PATAGONICA VTC",
//       "PARTNER PATAGONICA VTC",
//       "PARTNER PATAGONICA VTC",
//       "PARTNER PRESENCE 1.4",
//       "PARTNER PRESENCE 1.6",
//       "PARTNER PRESENCE AA 1.4",
//       "PARTNER PRESENCE AA 1.6",
//       "PARTNER PRESENCE PLC 1.6",
//       "PARTNER PRESENCE PLC HDI",
//       "PARTNER URBANA 1,4",
//       "PARTNER URBANA 1.4 GNC",
//       "PARTNER URBANA 1.4 PARA",
//       "PARTNER URBANA CONFORT",
//       "PARTNER URBANA CONFORT",
//       "PARTNER URBANA CONFORT",
//       "PARTNER URBANA CONFORT",
//       "PARTNER URBANA D AA",
//       "PARTNER URBANA N",
//       "PARTNER URBANA N AA",
//       "PARTNER URBANA N AA GNC",
//       "PARTNER URBANA N GNC",
//       "PUP 504 G",
//       "PUP 504 GD",
//       "PUP 504 GRD",
//       "PUP 504 GRD PP",
//       "P-UP T4B",
//       "RANCH",
//       "RCZ",
//       "RCZ TIPTRONIC",
//       "TRAVELLER ALLURE PLUS 2.0",
//       "XSARA PICASSO 2.0 HDI",
//       "106 XND",
//       "206 1.4 X-LINE",
//       "206 1.6 X-LINE",
//       "206 SW XS 1.6 HDI",
//       "207 1.6 ALLURE",
//       "207 XLINE 1.4",
//       "3008 1.6 M/T",
//       "308 1.6 VTI CONFORT",
//       "407 SPORT 1.6 HDI",
//       "508 ALLURE HDI TIP",
//       "508 ALLURE N TIP",
//       "508 FELINE HDI TIP",
//       "508 FELINE N TIP",
//       "508 GT HDI TIP",
//       "XSARA PICASSO 1.6I 16V EXC."
//     ],
//     "PIAGGIO": [
//       "M68",
//       "MP3 500 LT BUSINESS (3",
//       "MP3 LT 500IE BUSINESS",
//       "MP3 YOURBAN LT 300 IE (3",
//       "MP3 YOURBAN LT 300 IE",
//       "VESPA 150 PRIMAVERA",
//       "VESPA 150 PRIMAVERA",
//       "VESPA 150 PRIMAVERA 70",
//       "VESPA 150 SPRINT",
//       "VESPA GTS 300",
//       "VESPA GTS 300 IE",
//       "VESPA GTS SUPER 300",
//       "VESPA SXL 150",
//       "VESPA VXL 150"
//     ],
//     "PLYMOUTH": [
//       "GRAND VOYAGER",
//       "GRAND VOYAGER LE",
//       "GRAND VOYAGER S",
//       "GRAND VOYAGER SE",
//       "VOYAGER",
//       "VOYAGER",
//       "VOYAGER ASKL52",
//       "VOYAGER LE",
//       "VOYAGER SE"
//     ],
//     "POLARIS": [
//       "RANGER CREW 800 EFI",
//       "RANGER CREW 900",
//       "RANGER CREW 900 EFI",
//       "RANGER RZR 800 EFI",
//       "RANGER RZR S 800 EFI",
//       "RANGER RZR S 800 EFI",
//       "RANGER RZR XP 900 EFI",
//       "RANGER RZR4 800 EFI",
//       "RZR - 800S",
//       "RZR 4 800 EFI",
//       "RZR 4 900 EFI",
//       "RZR 800 EFI",
//       "RZR 900",
//       "RZR 900 EFI",
//       "RZR PRO XP",
//       "RZR S 800 EFI",
//       "RZR S 900",
//       "RZR S 900 EFI",
//       "RZR S 900 EPS",
//       "RZR XP 1000",
//       "RZR XP TURBO EPS",
//       "SCRAMBLER XP 1000",
//       "SPORTSMAN 450 H.O.",
//       "SPORTSMAN 570 EFI",
//       "SPORTSMAN ACE",
//       "SPORTSMAN ACE 570",
//       "SPORTSMAN XP 1000",
//       "RANGER XP 900 EFI",
//       "RANGER 4X4 800 H.O. EFI",
//       "RANGER RZR 4 XP 900 EFI"
//     ],
//     "PONTIAC": ["GRAND PRIX", "SOLSTICE"],
//     "PORSCHE": [
//       "718 BOXSTER",
//       "718 BOXSTER S",
//       "718 BOXTER S",
//       "718 CAYMAN S",
//       "718 SPYDER",
//       "911",
//       "911",
//       "911 CARRERA",
//       "911 CARRERA",
//       "911 CARRERA 2",
//       "911 CARRERA 4",
//       "911 CARRERA 4",
//       "911 CARRERA 4",
//       "911 CARRERA 4S",
//       "911 CARRERA 4S",
//       "911 CARRERA 4S CABRIOLET",
//       "911 CARRERA S",
//       "911 CARRERA S",
//       "911 E TARGA",
//       "911 GT2",
//       "911 GT2 RS",
//       "911 GT3",
//       "911 GT3 RS",
//       "911 RS",
//       "911 S",
//       "911 S TARGA",
//       "911 SC",
//       "911 SC CARRERA RS",
//       "911 TARGA",
//       "911 TARGA 4",
//       "911 TARGA 4S",
//       "911 TARGA 4S",
//       "911 TURBO",
//       "911 TURBO",
//       "911 TURBO CABRIOLET",
//       "911 TURBO S",
//       "911 TURBO S",
//       "911/T/S",
//       "911S TARGA/TARGA/E TARGA",
//       "911T",
//       "987 CS",
//       "993 CARRERA",
//       "BOXSTER",
//       "BOXSTER",
//       "BOXSTER",
//       "BOXSTER S",
//       "BOXSTER S",
//       "BOXSTER SPYDER",
//       "C2",
//       "CARRERA",
//       "CARRERA",
//       "CARRERA 4",
//       "CARRERA 4",
//       "CARRERA 4S",
//       "CARRERA 4S",
//       "CARRERA 911",
//       "CARRERA 911",
//       "CARRERA RS",
//       "CARRERA S",
//       "CARRERA S",
//       "CAYENNE",
//       "CAYENNE COUPE",
//       "CAYENNE DIESEL",
//       "CAYENNE DIESEL",
//       "CAYENNE GTS",
//       "CAYENNE GTS COUPE",
//       "CAYENNE S",
//       "CAYENNE S COUPE",
//       "CAYENNE S HYBRID",
//       "CAYENNE TURBO",
//       "CAYENNE TURBO S",
//       "CAYMAN",
//       "CAYMAN S",
//       "F-PACE 2.0D AWD",
//       "F-PACE 35T AWD",
//       "F-PACE 35T AWD R-SPORT",
//       "GT3",
//       "GT4",
//       "MACAN",
//       "MACAN GTS",
//       "MACAN S",
//       "MACAN S 2.0 DIESEL",
//       "MACAN S 3.0 DIESEL",
//       "MACAN TURBO",
//       "PANAMERA S",
//       "PANAMERA 4S",
//       "PANAMERA GTS",
//       "PANAMERA TURBO",
//       "R75 2.0 V6",
//       "TARGA 4S",
//       "TURBO"
//     ],
//     "PROTON": ["416 GLXI", "420 TD GLS", "420 TD GLS", "420D GLS"],
//     "QINGQI": ["QS90-2"],
//     "RAM": [
//       "RAM 1500 5.7 V8 LARAMIE",
//       "RAM 1500 SLT LARAMIE 5.7L",
//       "RAM 2500 6.7L LARAMIE 4X4"
//     ],
//     "RANGE ROVER": ["4.6 HSE", "VOGUE", "VOGUE SE"],
//     "RENAULT": [
//       "19",
//       "19 1.4",
//       "19 1.4 E",
//       "19 1.4 E",
//       "19 1.4 S RN",
//       "19 1.6I RN",
//       "19 1.8 ADAGIO",
//       "19 1.8 ADAGIO",
//       "19 1.9 TD",
//       "19 1.9 TDS",
//       "19 1.9D",
//       "19 1.9D DRIVER",
//       "19 16V",
//       "19 ADAGIO",
//       "19 ALIZE 1.8",
//       "19 D",
//       "19 GTS",
//       "19 GTS",
//       "19 GTX",
//       "19 RE",
//       "19 RE",
//       "19 RE A.A.",
//       "19 RE A.A.",
//       "19 RE D AA.DIREC",
//       "19 RE DIESEL",
//       "19 RE DIESEL",
//       "19 RE DIESEL",
//       "19 RE DIESEL",
//       "19 RE DIESEL DA",
//       "19 RL 1.2",
//       "19 RL 1.4",
//       "19 RL 1.4",
//       "19 RL 1.4 E",
//       "19 RL 1.4 E",
//       "19 RL 1.6",
//       "19 RL 1.9 D",
//       "19 RL DIESEL",
//       "19 RL DIESEL",
//       "19 RN",
//       "19 RN (BIC)",
//       "19 RN 1.4",
//       "19 RN 1.4 ING BICORPS",
//       "19 RN 1.4I",
//       "19 RN BIC ABC",
//       "19 RN BIC.",
//       "19 RN CAMARGUE",
//       "19 RN D",
//       "19 RN DIESEL",
//       "19 RN DIESEL TRIC.",
//       "19 RN DIESEL TRIC.",
//       "19 RN INYECCION BIC.",
//       "19 RT",
//       "19 RT",
//       "19 RT 1.7",
//       "19 RT 1.9 TD",
//       "19 RT 1.9D",
//       "19 RTD",
//       "19 RTD/TD",
//       "19 TD",
//       "19 TDE",
//       "19 TR",
//       "19 TS",
//       "19 TSE",
//       "19 TXE",
//       "21",
//       "21 GTX",
//       "21 GTX",
//       "21 GTX",
//       "21 GTXI",
//       "21 TXE",
//       "21 TXE",
//       "21 TXE AUTOMATICO",
//       "21 TXE BREAK NEVADA",
//       "21 TXE BREAK NEVADA",
//       "21 TXE BREAK NEVADA",
//       "21 TXE INYECCION",
//       "21 TXI",
//       "25 GTX",
//       "25 GTX",
//       "ALASKAN CONFORT 2.3 DCI",
//       "ALASKAN CONFORT 2.3 DCI",
//       "ALASKAN CONFORT 4X2 MT",
//       "ALASKAN CONFORT 4X4 MT",
//       "ALASKAN EMOTION 2.3 DCI",
//       "ALASKAN EMOTION 2.3 DCI",
//       "ALASKAN EMOTION 4X2 MT",
//       "ALASKAN EMOTION 4X4 MT",
//       "ALASKAN ICONIC 2.3 DCI 190",
//       "ALASKAN ICONIC 4X4 AT",
//       "ALASKAN ICONIC 4X4 MT",
//       "ALASKAN INTENS 2.3 DCI 190",
//       "ALASKAN INTENS 2.3 DCI 190",
//       "ALASKAN INTENS 2.3 DCI 190",
//       "ALASKAN INTENS 4X2 MT",
//       "ALASKAN INTENS 4X4 MT",
//       "ALASKAN OUTSIDER 2.3 DCI",
//       "CAPTUR BOSE 1.6 CVT",
//       "CAPTUR INTENS 1.6 CVT",
//       "CAPTUR INTENS 1.6 CVT",
//       "CAPTUR INTENS 1.6 CVT SL",
//       "CAPTUR INTENS 2.0",
//       "CAPTUR LIFE 1.6",
//       "CAPTUR ZEN 1.6",
//       "CAPTUR ZEN 2.0",
//       "CBH 385 6X4 T",
//       "CLIO",
//       "CLIO",
//       "CLIO 1.2",
//       "CLIO 1.2",
//       "CLIO 1.2 AUTHENTIQUE",
//       "CLIO 1.2 AUTHENTIQUE CD",
//       "CLIO 1.2 AUTHENTIQUE F4",
//       "CLIO 1.2 RL",
//       "CLIO 1.2 UP",
//       "CLIO 1.2 YAHOO PLUS",
//       "CLIO 1.4 16V",
//       "CLIO 1.5 AUTHENTIQUE",
//       "CLIO 1.5 DCI",
//       "CLIO 1.5 DCI",
//       "CLIO 1.5 DIE FAIRWAY",
//       "CLIO 1.5 EXPRESSION DIESEL",
//       "CLIO 1.5 PRIVILEGE",
//       "CLIO 1.5 PRIVILEGE DIESEL F4",
//       "CLIO 1.6 16V",
//       "CLIO 1.6 AUTHENTIQUE AA",
//       "CLIO 1.6 EXPRESSION F4",
//       "CLIO 1.6 FAIRWAY",
//       "CLIO 1.6 PRIVILEGE F4",
//       "CLIO 1.7",
//       "CLIO 1.9 DIESEL",
//       "CLIO 16V",
//       "CLIO 16V",
//       "CLIO 16V YAHOO PACK PLUS",
//       "CLIO 16V YAHOO PACK PLUS",
//       "CLIO 2 1.2 AUTHENTIQUE CA",
//       "CLIO 2 GPS M 1.6",
//       "CLIO 2 TRIC PRIV",
//       "CLIO 3 PTAS AUTHENTIQUE",
//       "CLIO 3 PTAS BASE",
//       "CLIO 3 PTAS PACK",
//       "CLIO 3 PTAS PACK 1.2 BASE",
//       "CLIO 3 PTAS SL 1.2 GET UP",
//       "CLIO 3 PTAS. 1.6 SL",
//       "CLIO 3 PTAS. PACK PLUS",
//       "CLIO 3P AUTHENTIQUE 1.2",
//       "CLIO 3P AUTHENTIQUE 1.2",
//       "CLIO 3P AUTHENTIQUE 1.2",
//       "CLIO 3P CAMPUS 1.2",
//       "CLIO 3P CAMPUS 1.2 PACK I",
//       "CLIO 3P CAMPUS 1.2 PACK II",
//       "CLIO 5 PTAS AUTHENTIQUE",
//       "CLIO 5 PTAS BASE",
//       "CLIO 5 PTAS PACK",
//       "CLIO 5 PTAS PACK PLUS",
//       "CLIO 5 PTAS. 1.6 SL",
//       "CLIO 5P AUTHENTIQUE 1.2",
//       "CLIO 5P AUTHENTIQUE 1.2",
//       "CLIO 5P CAMPUS 1.2 PACK I",
//       "CLIO 5P CAMPUS 1.2 PACK II",
//       "CLIO AUTHENTIQUE 1.2 16V",
//       "CLIO AUTHENTIQUE 1.2 16V",
//       "CLIO AUTHENTIQUE 1.2 16V",
//       "CLIO AUTHENTIQUE 1.5 DCI",
//       "CLIO BACCARA",
//       "CLIO BASE 1.2",
//       "CLIO BASE 1.2 SIN ABCP",
//       "CLIO BIC 3P AUTHENTIQUE",
//       "CLIO BIC EXPRESSION 1.6 SLIN",
//       "CLIO BIC EXPRESSION SL M1.5",
//       "CLIO CAMPUS",
//       "CLIO CONFORT 1.5 DIE",
//       "CLIO CONFORT 1.6",
//       "CLIO DYNAMIQUE 1.5 DCI",
//       "CLIO DYNAMIQUE 1.6",
//       "CLIO DYNAMIQUE 1.6 16V",
//       "CLIO EXPRESSION 1.5 DCI",
//       "CLIO EXPRESSION 1.6 16V",
//       "CLIO EXPRESSION 1.6 16V",
//       "CLIO EXPRESSION 1.6 16V",
//       "CLIO F2 1.6 AUTENTIQUE",
//       "CLIO F2 RL 1.6 AUT AA",
//       "CLIO F2 RL GNC AUTENTIQUE",
//       "CLIO F2 RN 1.6 TRIC EXPR",
//       "CLIO II 1.6",
//       "CLIO II RN 1.0",
//       "CLIO II RN 1.9",
//       "CLIO II RT 1.6",
//       "CLIO LUXE 1.5 DIE ABS",
//       "CLIO LUXE 1.6 ABS",
//       "CLIO MIO 3P AUTHENTIQUE",
//       "CLIO MIO 3P AUTHENTIQUE",
//       "CLIO MIO 3P CONFORT",
//       "CLIO MIO 3P CONFORT ABS",
//       "CLIO MIO 3P CONFORT PACK",
//       "CLIO MIO 3P CONFORT PACK",
//       "CLIO MIO 3P CONFORT PACK",
//       "CLIO MIO 3P CONFORT PLUS",
//       "CLIO MIO 3P CONFORT PLUS",
//       "CLIO MIO 3P CONFORT PLUS",
//       "CLIO MIO 3P DYNAMIQUE",
//       "CLIO MIO 3P DYNAMIQUE",
//       "CLIO MIO 3P DYNAMIQUE",
//       "CLIO MIO 3P EXP PACKII AB",
//       "CLIO MIO 3P EXPRESSION",
//       "CLIO MIO 3P EXPRESSION",
//       "CLIO MIO 3P EXPRESSION",
//       "CLIO MIO 3P GT LINE",
//       "CLIO MIO 3P PACK",
//       "CLIO MIO 3P PACK ABS ABCP",
//       "CLIO MIO 3P PACK LOOK",
//       "CLIO MIO 3P PACK LOOK ABS",
//       "CLIO MIO 5P CONFORT",
//       "CLIO MIO 5P CONFORT ABS",
//       "CLIO MIO 5P CONFORT PACK",
//       "CLIO MIO 5P CONFORT PACK",
//       "CLIO MIO 5P CONFORT PLUS",
//       "CLIO MIO 5P CONFORT PLUS",
//       "CLIO MIO 5P CONFORT PLUS",
//       "CLIO MIO 5P DYNAMIQUE",
//       "CLIO MIO 5P DYNAMIQUE",
//       "CLIO MIO 5P DYNAMIQUE",
//       "CLIO MIO 5P EXP PACK II AB",
//       "CLIO MIO 5P EXPRESSION",
//       "CLIO MIO 5P EXPRESSION",
//       "CLIO MIO 5P EXPRESSION",
//       "CLIO MIO 5P GT LINE",
//       "CLIO MTV",
//       "CLIO OTROS",
//       "CLIO PACK 1.2 AA DA",
//       "CLIO PACK 1.2 AA DA YAHOO",
//       "CLIO PACK 1.2 DA AA",
//       "CLIO PACK 1.5 DIE DA AA",
//       "CLIO PACK 1.5 DIE DA AA",
//       "CLIO PACK 1.5DCI",
//       "CLIO PACK PLUS 1.2",
//       "CLIO PACK PLUS 1.2 YAHOO",
//       "CLIO PRIVILEGE 1.6 16V",
//       "CLIO RL",
//       "CLIO RL",
//       "CLIO RL 1.2 4 PTAS",
//       "CLIO RL 1.2 ABCP",
//       "CLIO RL 1.4",
//       "CLIO RL 1.9 DIESEL",
//       "CLIO RL 3 PTAS.",
//       "CLIO RL 3 PTAS. A.A.",
//       "CLIO RL 3 PTAS. A.A.",
//       "CLIO RL 5 PTAS.",
//       "CLIO RL 5 PTAS.",
//       "CLIO RL 5 PTAS. AA",
//       "CLIO RL 5 PTAS. AA",
//       "CLIO RL DIE 4 PTAS",
//       "CLIO RL DIE 4 PTAS ABCP",
//       "CLIO RL DIESEL",
//       "CLIO RL DIESEL",
//       "CLIO RL DIESEL 3 P AA/DA",
//       "CLIO RL DIESEL 3 PTAS DA",
//       "CLIO RL DIESEL 3 PTAS.",
//       "CLIO RL DIESEL 3 PTAS.",
//       "CLIO RL DIESEL 5 P AA/DA",
//       "CLIO RL DIESEL 5 PTAS DA.",
//       "CLIO RL DIESEL 5 PTAS. DA",
//       "CLIO RN",
//       "CLIO RN",
//       "CLIO RN",
//       "CLIO RN 1,2",
//       "CLIO RN 1,2 4 PTAS",
//       "CLIO RN 1,2 AA",
//       "CLIO RN 1,2 AA 4PTAS",
//       "CLIO RN 1.2 DA AA PACK 4",
//       "CLIO RN 1.6",
//       "CLIO RN 1.6",
//       "CLIO RN 1.6 16V",
//       "CLIO RN 1.6 16V",
//       "CLIO RN 1.9D",
//       "CLIO RN 1.9D",
//       "CLIO RN 110CV 4 PTAS.",
//       "CLIO RN 110CV CA PACK 4",
//       "CLIO RN 16V 4 PTAS",
//       "CLIO RN 16V 4 PTAS.",
//       "CLIO RN 16V CA 4 PTAS",
//       "CLIO RN 16V CA PACK 4 PTAS",
//       "CLIO RN 1L",
//       "CLIO RN 3 PTAS PACK",
//       "CLIO RN 3 PTAS PACK AA",
//       "CLIO RN 3 PTAS PACK AA",
//       "CLIO RN 3 PTAS.",
//       "CLIO RN 3 PTAS. A.A.",
//       "CLIO RN 3 PTAS. C/A.A.",
//       "CLIO RN 3 PTAS. PACK AA DA",
//       "CLIO RN 3P DIE PACK AA DA",
//       "CLIO RN 5 PTAS",
//       "CLIO RN 5 PTAS AA",
//       "CLIO RN 5 PTAS PACK A.A.",
//       "CLIO RN 5 PTAS.AA",
//       "CLIO RN 5 PTAS.PACK AA DA",
//       "CLIO RN 5P DIE PACK AA DA",
//       "CLIO RN BIC 1.2 AA PACK",
//       "CLIO RN BIC 1.6 110CV",
//       "CLIO RN BIC 1.6 110CV",
//       "CLIO RN BIC 110 CV CA PACK",
//       "CLIO RN BIC CA PACK",
//       "CLIO RN CA",
//       "CLIO RN DIE",
//       "CLIO RN DIE 4 PTAS",
//       "CLIO RN DIE AA",
//       "CLIO RN DIE AA 4 PTAS",
//       "CLIO RN DIE AA PACK",
//       "CLIO RN DIE CA PACK 4 PTAS",
//       "CLIO RN DIE S/STEREO 4",
//       "CLIO RN PACK AA",
//       "CLIO RN PLUS 1.6 110CV",
//       "CLIO RNA 1.2",
//       "CLIO RT",
//       "CLIO RT",
//       "CLIO RT 1.6",
//       "CLIO RT 1.6 - 16V",
//       "CLIO RT 1.6 - 16V",
//       "CLIO RT 1.9D",
//       "CLIO RT 1.9D",
//       "CLIO RT 16V",
//       "CLIO RT 16V 4 PTAS",
//       "CLIO RT 16V ABS 4 PTAS.",
//       "CLIO RT 16V S/ABS 4 PTAS.",
//       "CLIO RT 5 PTAS. C/D.A.",
//       "CLIO RT 5 PTAS. D.A.",
//       "CLIO RT 5 PTAS. D.A. A.A.",
//       "CLIO RT AA",
//       "CLIO RT BIC 16V C/ABS",
//       "CLIO RT BIC 16V S/ABS",
//       "CLIO RT BIC DIE C/ABS",
//       "CLIO RT BIC DIE S/ABS",
//       "CLIO RT BIC S/ABS",
//       "CLIO RT DIE",
//       "CLIO RT DIE 4 PTAS",
//       "CLIO RT DIE 4 PTAS C/ABS",
//       "CLIO RT DIE 4 PTAS S/ABS",
//       "CLIO RT DIE ABS 4 PTAS.",
//       "CLIO RT DIESEL",
//       "CLIO RTA 1.2",
//       "CLIO RTA 1.4",
//       "CLIO RTE",
//       "CLIO RTE 1.9D",
//       "CLIO RXT 1.6",
//       "CLIO SONY BIC 1.2+",
//       "CLIO SONY BIC 1.6 110CV",
//       "CLIO SONY BIC 1.6 110CV GB",
//       "CLIO SPORT 1.6 16V",
//       "CLIO SPORT 2.0 16V",
//       "CLIO SPORT V6",
//       "CLIO TRI 1.2 AUTHENTIQUE F4",
//       "CLIO TRI 1.5 AUTHENTIQUE",
//       "CLIO TRI 1.5 EXPRESSION",
//       "CLIO TRI 1.5 PRIVILEGE DIESEL",
//       "CLIO TRI 1.6 EXPRESSION F4",
//       "CLIO TRI 1.6 PRIVILEGE F4",
//       "CLIO TRI 1.9 AUTHENTIQUE",
//       "CLIO TRI AUTHENTIQUE 1.2",
//       "CLIO TRI GNC AUTHENTIQUE",
//       "CLIO TRIC CONFORT 1.5 DIE",
//       "CLIO TRIC CONFORT 1.6",
//       "CLIO TRIC LUXE 1.5 DIE ABS",
//       "CLIO TRIC LUXE 1.6 ABS",
//       "CLIO TRIC PACK 1.2 DA AA",
//       "CLIO TRIC PACK 1.5 DIE DA AA",
//       "CLIO TRIC PACK 1.6 GNC DA",
//       "CLIO TRIC PACK PLUS 1.2",
//       "CLIO WORK",
//       "CLIO2 AUTHENTIQUE M 1.2 SL",
//       "CLIO2 F2 DIE AA AUTENTIQUE",
//       "CLIO2 F2 RL 1.2 AA AUT",
//       "CLIO2 F2 RL 1.2 AUTENTIQUE",
//       "CLIO2 F2 RL DIE AUTENTIQUE",
//       "CLIO2 F2 RL GNC",
//       "CLIO2 F2 RN 1.2 EXPRESION",
//       "CLIO2 F2 RN 1.2 TRIC EXP.",
//       "CLIO2 F2 RN 1.6 EXPRESSION",
//       "CLIO2 F2 RN DIE EXPRESION",
//       "CLIO2 F2 RT 1,6 PRIVILEGE",
//       "CLIO2 F2 RT 1.6 TRIC EXPR",
//       "CLIO2 F2 RT DIE PRIVILEGE",
//       "CLIO2 F2 TRIC AUT",
//       "CLIO2 F2 TRIC DA AA AUT",
//       "CLIO2 F2 TRIC DIE AA AUT",
//       "CLIO2 F2 TRIC DIE AUT",
//       "CLIO2 F2 TRIC DIE",
//       "CLIO2 F2 TRIC DIE PRIVILEGE",
//       "CLIO2 TRI AUTHENTIQUE",
//       "D 19 WIDE",
//       "D 19 WIDE",
//       "D16",
//       "D16",
//       "DG230-20",
//       "DG290.TL",
//       "DUSTER CONFORT 1.6 4X2",
//       "DUSTER CONFORT PLUS 1.6",
//       "DUSTER CONFORT PLUS 1.6",
//       "DUSTER DAKAR 1.6",
//       "DUSTER DYNAMIQUE 1.6 4X2",
//       "DUSTER DYNAMIQUE 2.0",
//       "DUSTER DYNAMIQUE 2.0",
//       "DUSTER EXPRESSION 1.6 4X2",
//       "DUSTER ICONIC 1.3T 4X4 MT",
//       "DUSTER ICONIC 1.3T CVT",
//       "DUSTER INTENS 1.6 CVT",
//       "DUSTER INTENS 1.6 MT",
//       "DUSTER LOS PUMAS 2.0 4X2",
//       "DUSTER LOS PUMAS 2.0 4X4",
//       "DUSTER LUXE 2.0 4X2 NAV",
//       "DUSTER LUXE 2.0 4X4 NAV",
//       "DUSTER OROCH DYNAMIQUE",
//       "DUSTER OROCH DYNAMIQUE",
//       "DUSTER OROCH DYNAMIQUE",
//       "DUSTER OROCH OUTSIDER",
//       "DUSTER OROCH OUTSIDER",
//       "DUSTER OROCH OUTSIDER",
//       "DUSTER OROCH PRIVILEGE",
//       "DUSTER OROCH",
//       "DUSTER OROCHO PRIVILEGE",
//       "DUSTER PH2 DAKAR 1.6",
//       "DUSTER PH2 DAKAR 2.0",
//       "DUSTER PH2 DAKAR 2.0 4X4",
//       "DUSTER PH2 DYNAMIQUE 1.6",
//       "DUSTER PH2 EXPRESSION 1.6",
//       "DUSTER PH2 EXPRESSION 2.0",
//       "DUSTER PH2 PRIVILEGE 1.6",
//       "DUSTER PH2 PRIVILEGE 1.6 SL",
//       "DUSTER PH2 PRIVILEGE 2.0",
//       "DUSTER PH2 PRIVILEGE 2.0",
//       "DUSTER PRIVILEGE 2.0 4X2",
//       "DUSTER PRIVILEGE 2.0 4X4",
//       "DUSTER TECH ROAD 1.6 4X2",
//       "DUSTER TECH ROAD 2.0 4X4",
//       "EXPRESS",
//       "EXPRESS",
//       "EXPRESS D55",
//       "EXPRESS RL",
//       "EXPRESS RL 1.9D",
//       "EXPRESS RN",
//       "EXPRESS RN",
//       "FLUENCE 1.6 16V CONFORT",
//       "FLUENCE 1.6 16V CONFORT",
//       "FLUENCE 2.0 16V",
//       "FLUENCE 2.0 16V GT",
//       "FLUENCE 2.0 16V LUXE",
//       "FLUENCE 2.0 16V LUXE RLINK",
//       "FLUENCE 2.0 16V LUXE RLINK",
//       "FLUENCE 2.0 16V PRIVILEGE",
//       "FLUENCE 2.0 16V SPORT",
//       "FLUENCE 2.0 PRIVILEGE CVT",
//       "FLUENCE PH2 1.6",
//       "FLUENCE PH2 1.6",
//       "FLUENCE PH2 1.6 LUXE",
//       "FLUENCE PH2 2.0",
//       "FLUENCE PH2 2.0 GT",
//       "FLUENCE PH2 2.0 LUXE",
//       "FLUENCE PH2 2.0 LUXE",
//       "FLUENCE PH2 2.0 LUXE CVT",
//       "FLUENCE PH2 2.0 LUXE F",
//       "FLUENCE PH2 2.0 LUXE PACK",
//       "FLUENCE PH2 2.0 LUXE PACK",
//       "FLUENCE PH2 2.0 LUXE PACK",
//       "FLUENCE PH2 2.0 LUXE PACK",
//       "FLUENCE PH2 2.0 PRIVILEGE",
//       "FLUENCE PH2 2.0 PRIVILEGE",
//       "GRAND SCENIC 1.6",
//       "GRAND SCENIC 1.9",
//       "GRAND SCENIC 2.0 16V",
//       "KANGOO",
//       "KANGOO 1.5 DCI",
//       "KANGOO 1.6",
//       "KANGOO 1.6 + DINAMIQUE",
//       "KANGOO 4X4",
//       "KANGOO ALIZE",
//       "KANGOO AUTH. 1.6 DA AA CD",
//       "KANGOO AUTH. PLUS 1.5 DA",
//       "KANGOO AUTH. PLUS 1.5 DA",
//       "KANGOO AUTH. PLUS 1.5 DA",
//       "KANGOO AUTH. PLUS 1.5 DCI",
//       "KANGOO AUTH. PLUS 1.6 DA",
//       "KANGOO AUTH. PLUS 1.6 DA",
//       "KANGOO AUTH.PLUS 1.5 DA",
//       "KANGOO AUTH.PLUS 1.6 DA",
//       "KANGOO AUTH.PLUS 1.6 DA",
//       "KANGOO AUTHENTIQUE 1.6",
//       "KANGOO AUTHENTIQUE 1.6",
//       "KANGOO AUTHENTIQUE 1.9",
//       "KANGOO AUTHENTIQUE 1.9",
//       "KANGOO CAMPUS 1.5 DCI 2P",
//       "KANGOO CAMPUS 1.6 2P",
//       "KANGOO CAMPUS 1.6 2P 7A",
//       "KANGOO CONFORT 1.5 5A",
//       "KANGOO CONFORT 1.5 5A",
//       "KANGOO CONFORT 1.5 5A",
//       "KANGOO CONFORT 1.5 5A",
//       "KANGOO CONFORT 1.5 DCI",
//       "KANGOO CONFORT 1.5 DCI",
//       "KANGOO CONFORT 1.5 DCI",
//       "KANGOO CONFORT 1.5 DCI",
//       "KANGOO CONFORT 1.5 dCi CD",
//       "KANGOO CONFORT 1.5 DCI",
//       "KANGOO CONFORT 1.5 dCI",
//       "KANGOO CONFORT 1.5 DCI",
//       "KANGOO CONFORT 1.5 SVT",
//       "KANGOO CONFORT 1.6",
//       "KANGOO CONFORT 1.6 5A",
//       "KANGOO CONFORT 1.6 5A CD",
//       "KANGOO CONFORT 1.6 5A CD",
//       "KANGOO CONFORT 1.6 CD AA",
//       "KANGOO CONFORT 1.6 CD AA",
//       "KANGOO CONFORT 1.6 CD",
//       "KANGOO CONFORT 1.6 CD",
//       "KANGOO CONFORT 1.6",
//       "KANGOO CONFORT 1.9",
//       "KANGOO CONFORT 1.9",
//       "KANGOO CONFORT 1.9",
//       "KANGOO CONFORT ABCP 1.6",
//       "KANGOO EDICION LIMITADA",
//       "KANGOO EXP CONFORT 1.6",
//       "KANGOO EXP CONFORT 1.6",
//       "KANGOO EXP CONFORT 1.9",
//       "KANGOO EXP CONFORT 1.9",
//       "KANGOO EXP CONFORT 1.9",
//       "KANGOO EXP GENERIQUE 1.9",
//       "KANGOO EXP GRAND",
//       "KANGOO EXPRESION 1.9",
//       "KANGOO EXPRESS CONFORT",
//       "KANGOO EXPRESS CONFORT",
//       "KANGOO EXPRESS CONFORT",
//       "KANGOO EXPRESS CONFORT",
//       "KANGOO EXPRESS DIE VD",
//       "KANGOO EXPRESS G.",
//       "KANGOO EXPRESS",
//       "KANGOO EXPRESS GRAND",
//       "KANGOO EXPRESS RLC",
//       "KANGOO EXPRESS RN 1.9D",
//       "KANGOO EXPRESS RN 1.9D",
//       "KANGOO EXPRESS RN DIE",
//       "KANGOO EXPRESS RNC",
//       "KANGOO EXPRESS RNC 1.2",
//       "KANGOO EXPRESSION 1.6",
//       "KANGOO GENERIQUE 1.5 DCI",
//       "KANGOO GENERIQUE 1.5 dCI",
//       "KANGOO GET UP 1.5 DA AA",
//       "KANGOO GET UP 1.6 DA AA",
//       "KANGOO GET UP 1.6 DA AA",
//       "KANGOO GRAND CONFORT",
//       "KANGOO GRAND CONFORT",
//       "KANGOO II EXPRESS CONFORT",
//       "KANGOO II EXPRESS CONFORT",
//       "KANGOO II EXPRESS CONFORT",
//       "KANGOO II EXPRESS CONFORT",
//       "KANGOO II EXPRESS CONFORT",
//       "KANGOO II EXPRESS CONFORT",
//       "KANGOO II EXPRESS CONFORT",
//       "KANGOO II EXPRESS CONFORT",
//       "KANGOO II EXPRESS CONFORT",
//       "KANGOO II EXPRESS CONFORT",
//       "KANGOO II EXPRESS CONFORT",
//       "KANGOO II EXPRESS CONFORT",
//       "KANGOO II EXPRESS",
//       "KANGOO II EXPRESS",
//       "KANGOO II EXPRESS WORK",
//       "PROFESIONAL 1.6 SCE",
//       "KANGOO II LIFE 1.6 SCE",
//       "KANGOO II STEPWAY 1.5 DCI",
//       "KANGOO II STEPWAY 1.6 SCE",
//       "KANGOO II ZEN 1.6 SCE",
//       "KANGOO PACK 1.6 1 PLC",
//       "KANGOO PACK 1.9 1 PLC",
//       "KANGOO PACK PLUS 1.6 GNC",
//       "KANGOO PACK PLUS 1.9",
//       "KANGOO PACK PLUS 1.9",
//       "KANGOO PACK PLUS",
//       "KANGOO PACK PLUS",
//       "KANGOO PH3 AUTH. 1.6 1P",
//       "KANGOO PH3 AUTH. PLUS 1.6",
//       "KANGOO PH3 AUTH. PLUS1.6",
//       "KANGOO PH3 CONFORT 1.5",
//       "KANGOO PH3 CONFORT 1.5",
//       "KANGOO PH3 CONFORT 1.5",
//       "KANGOO PH3 CONFORT 1.5",
//       "KANGOO PH3 CONFORT 1.6",
//       "KANGOO PH3 CONFORT 1.6",
//       "KANGOO PH3 CONFORT PACK",
//       "KANGOO PH3 GENERIQUE 1.6",
//       "KANGOO PH3 GRAN",
//       "KANGOO PH3 SPORTWAY 1.6",
//       "KANGOO RL 1.0",
//       "KANGOO RL 1.0 AA ABCP",
//       "KANGOO RL 1.0 ABCP",
//       "KANGOO RL 1.0 C/AA",
//       "KANGOO RL 1.0 FURGON",
//       "KANGOO RL 1.0 FURGON",
//       "KANGOO RL 1.2",
//       "KANGOO RL 1.2",
//       "KANGOO RL 1.6 AA DA ABCP",
//       "KANGOO RL 1.6 DA ABCP",
//       "KANGOO RL 1.6 DA ABCP",
//       "KANGOO RL 1.6 FURGON",
//       "KANGOO RL 11 CV DA PLC IZQ",
//       "KANGOO RL 110CV AA PLC",
//       "KANGOO RL 110CV PLC IZQ",
//       "KANGOO RL AA S/ABCP 2PLC",
//       "KANGOO RL DA",
//       "KANGOO RL DA AA ABCP",
//       "KANGOO RL DA AA ABCP 2PLC",
//       "KANGOO RL DA ABCP PLC IZQ",
//       "KANGOO RL DA S/ABCP PLC",
//       "KANGOO RL DIE AA ABCP 2PL",
//       "KANGOO RL DIE AA S/ABCP",
//       "KANGOO RL DIE ABCP PLC IZQ",
//       "KANGOO RL DIE DA",
//       "KANGOO RL DIE EXPR 2PLC",
//       "KANGOO RL DIE S/ABCP PLC",
//       "KANGOO RL DIESEL AA ABCP",
//       "KANGOO RL EXP 110CV GNC",
//       "KANGOO RL EXP DIE DA AA",
//       "KANGOO RL EXP. 110 CV",
//       "KANGOO RL EXPRESS",
//       "KANGOO RL EXPRESS DIE",
//       "KANGOO RL EXPRESS DIE",
//       "KANGOO RL EXPRESS DIE DA",
//       "KANGOO RL EXPRESS DIE DA",
//       "KANGOO RL EXPRESS S/RADIO",
//       "KANGOO RL GNC AA PLC IZQ",
//       "KANGOO RL GNC PLC IZQ",
//       "KANGOO RL NAFTA AA ABCP",
//       "KANGOO RN",
//       "KANGOO RN 1.0",
//       "KANGOO RN 1.0 AA ABCP",
//       "KANGOO RN 1.2",
//       "KANGOO RN 1.2 16V",
//       "KANGOO RN 1.2 16V PACK",
//       "KANGOO RN 1.4",
//       "KANGOO RN 1.4",
//       "KANGOO RN 1.6",
//       "KANGOO RN 1.6 ABCP",
//       "KANGOO RN 1.6 ABCP",
//       "KANGOO RN ABCP PLC IZQ",
//       "KANGOO RN DA AA PACK",
//       "KANGOO RN DA AA PACK",
//       "KANGOO RN DIE ABCP PLCIZQ",
//       "KANGOO RN DIE DA AA PACK",
//       "KANGOO RN DIE DA AA PACK",
//       "KANGOO RN EXP DIE DA AA",
//       "KANGOO RN EXP.DIE DA AA",
//       "KANGOO RNE",
//       "KANGOO RNE 1.4",
//       "KANGOO RNE D55",
//       "KANGOO RT 110CV ABCP PLC",
//       "KANGOO RT ABCP PLC IZQ",
//       "KANGOO RT DIE ABCP PLCIZQ",
//       "KANGOO SL MONTAGNE M",
//       "KANGOO SPORTWAY 1.5",
//       "KANGOO SPORTWAY 1.6",
//       "KANGOO SPORTWAY 1.6",
//       "KANGOO SPORTWAY 1.6",
//       "KANGOO SPORTWAY 1.9",
//       "KANGOO VU GENERIQUE 1.9",
//       "KANGOO Z.E 2A",
//       "KANGOO Z.E. MAXI 2A",
//       "KANGOO Z.E. MAXI 5A",
//       "KERAX 300.19",
//       "KERAX 300.26 6X4",
//       "KERAX 420.50 DCI 8X4 XHD",
//       "KERAX 440 DXI",
//       "KERAX 440 DXI",
//       "KERAX 440 DXI",
//       "KOLEOS 2.5",
//       "KOLEOS 2.5",
//       "KOLEOS 2.5 DYNAMIQUE 4X2",
//       "KOLEOS 2.5 DYNAMIQUE 4X4",
//       "KOLEOS 2.5 DYNAMIQUE 4X4",
//       "KOLEOS 2.5 DYNAMIQUE 4X4",
//       "KOLEOS 2.5 EXPRESSION",
//       "KOLEOS 2.5L DYNAMIQUE",
//       "KOLEOS 2.5L DYNAMIQUE",
//       "KOLEOS 2.5L EXPRESSION 4X2",
//       "KOLEOS 2.5L PRIVILEGE 4X4",
//       "KOLEOS 2.5L PRIVILEGE 4X4",
//       "KOLEOS DYNAMIQUE 4X4 MT",
//       "KOLEOS EXPRESSION 4X2 MT",
//       "KOLEOS INTENS 2.5 4WD CVT",
//       "KOLEOS PH2 INTENS 2.5 4WD",
//       "KOLEOS PH3 DYN. PLUS 4X4",
//       "KOLEOS PH3 DYN. PLUS 4X4",
//       "KOLEOS PH3 EXP. 4X2 MT",
//       "KOLEOS PH3 PRIV 4X4 CVT",
//       "KOLEOS PRIVILEGE 4X4 CVT",
//       "KOLEOS PRIVILEGE 4X4 MT",
//       "KOLEOS ZEN 2.5 2WD CVT",
//       "KWID ICONIC 1.0",
//       "KWID INTENS 1.0",
//       "KWID LIFE 1.0",
//       "KWID OUTSIDER 1.0",
//       "KWID ZEN 1.0",
//       "LAGUNA",
//       "LAGUNA",
//       "LAGUNA 1.9 DCI",
//       "LAGUNA 1.9 DCI",
//       "LAGUNA 1.9 DCI",
//       "LAGUNA 2.0 16V",
//       "LAGUNA 2.0 16V",
//       "LAGUNA 2.0 RXE",
//       "LAGUNA 2.0 RXE",
//       "LAGUNA 2.0 RXE",
//       "LAGUNA 2.0T",
//       "LAGUNA 2.0T DYNAMIQUE",
//       "LAGUNA 3.0 24V",
//       "LAGUNA 3.0 V6 24V",
//       "LAGUNA 3.0 V6 24V",
//       "LAGUNA GRAN TOUR 1.9 DCI",
//       "LAGUNA GRAN TOUR 1.9 DCI",
//       "LAGUNA GRAN TOUR 2.0 16V",
//       "LAGUNA GRAN TOUR 2.0 16V",
//       "LAGUNA GRAN TOUR 3.0 24V",
//       "LAGUNA GRAN TOUR 3.0 24V",
//       "LAGUNA NEVADA RXT 1.8",
//       "LAGUNA RTI V6",
//       "LAGUNA RXE",
//       "LAGUNA RXE",
//       "LAGUNA RXE 1.8 16V",
//       "LAGUNA RXE 1.9 DTI",
//       "LAGUNA RXE 2.0 S",
//       "LAGUNA RXE/RXE 2.0/RXE 2.0",
//       "LAGUNA RXT 2.0",
//       "LAGUNA RXT 2.0 16V",
//       "LAGUNA RXT 2.0 16V",
//       "LAGUNA RXT 2.2DT",
//       "LAGUNA RXT V6 24V",
//       "LAGUNA RXT V6 I",
//       "LATITUDE DYNAMIQUE",
//       "LATITUDE LUXE",
//       "LATITUDE PRIVILEGE",
//       "LOGAN 1.5 DCI CONFORT",
//       "LOGAN 1.5 DCI LUXE",
//       "LOGAN 1.5 DCI PACK",
//       "LOGAN 1.6 16V",
//       "LOGAN 1.6 16V CONFORT",
//       "LOGAN 1.6 16V LUXE",
//       "LOGAN 1.6 8V BASE",
//       "LOGAN 1.6 8V CONFORT I",
//       "LOGAN 1.6 8V CONFORT I",
//       "LOGAN 1.6 8V CONFORT II",
//       "LOGAN 1.6 8V CONFORT II",
//       "LOGAN 1.6 8V PACK",
//       "LOGAN 1.6 8V PACK I",
//       "LOGAN 1.6 8V PACK I",
//       "LOGAN 1.6 8V PACK II",
//       "LOGAN 1.6 8V PACK II",
//       "LOGAN AUTHENTIQUE 1.6",
//       "LOGAN AUTHENTIQUE 1.6",
//       "LOGAN EXPRESSION 1.6",
//       "LOGAN EXPRESSION 1.6 PACK",
//       "LOGAN LIFE PACK 1.6",
//       "LOGAN PH2 CONFORT 1.5 CDI",
//       "LOGAN PH2 CONFORT 1.5 DCI",
//       "LOGAN PH2 CONFORT 1.6 8V",
//       "LOGAN PH2 CONFORT PLUS",
//       "LOGAN PH2 CONFORT PLUS",
//       "LOGAN PH2 INTENS 1.6",
//       "LOGAN PH2 INTENS 1.6",
//       "LOGAN PH2 INTENS 1.6 CVT",
//       "LOGAN PH2 LIFE 1.6",
//       "LOGAN PH2 LIFE 1.6",
//       "LOGAN PH2 LIFE PLUS 1.6",
//       "LOGAN PH2 PACK 1.5 DCI",
//       "LOGAN PH2 PACK 1.6",
//       "LOGAN PH2 PACK 1.6 8V",
//       "LOGAN PH2 PACK PLUS 1.6 8V",
//       "LOGAN PH2 ZEN 1.6",
//       "LOGAN PH2 ZEN 1.6",
//       "LOGAN SL AVANTAGE 1.6",
//       "MAGNUM 440 E-TECH 4X2",
//       "MAGNUM 470 18T",
//       "MAGNUM 480 E-TECH 4X2",
//       "MAGNUM AE 420",
//       "MAGNUM AE 420 TI",
//       "MAGNUM AE 430",
//       "MAGNUM AE 500",
//       "MASTER",
//       "MASTER",
//       "MASTER",
//       "MASTER 1.9 D",
//       "MASTER 2.5D",
//       "MASTER 2.5D T35",
//       "MASTER 2.8 DTI",
//       "MASTER 2.8 DTI T35",
//       "MASTER 2.8 DTI T35",
//       "MASTER 2.8 TD",
//       "MASTER 2.8D T35",
//       "MASTER 2.8D T35 (TECHO",
//       "MASTER DCI 100",
//       "MASTER DCI 120",
//       "MASTER DCI 120",
//       "MASTER DCI 120",
//       "MASTER DCI 120 CORTO",
//       "MASTER DCI 120 CORTO",
//       "MASTER DCI 120 LARGO",
//       "MASTER DCI 120 MEDIO",
//       "MASTER MINIBUS",
//       "MASTER PH3 DCI 120 L1H1",
//       "MASTER PH3 DCI 120 L1H1",
//       "MASTER PH3 DCI 120 L1H1",
//       "MASTER PH3 DCI 120 L1H2",
//       "MASTER PH3 DCI 120 L1H2",
//       "MASTER PH3 DCI 120 L2H2",
//       "MASTER PH3 DCI 120 L2H2",
//       "MASTER PH3 DCI 120 L3H2",
//       "MASTER PH3 DCI 120 PKCNF",
//       "MASTER PH3 DCI 120 PKELE",
//       "MASTER PH3 DCI 120 PKLUX",
//       "MASTER PH3 DCI120 PKLUX",
//       "MEGANE 1.5 D",
//       "MEGANE 1.5 DCI",
//       "MEGANE 1.5 DCI",
//       "MEGANE 1.5 DCI",
//       "MEGANE 1.5 DCI",
//       "MEGANE 1.6 16V",
//       "MEGANE 1.6 16V",
//       "MEGANE 1.6 16V",
//       "MEGANE 1.9 D RT",
//       "MEGANE 1.9 RT DT",
//       "MEGANE 1.9 RT DT",
//       "MEGANE 1.9 RT DTI",
//       "MEGANE 1.9D",
//       "MEGANE 1.9DCI",
//       "MEGANE 2.0 16V",
//       "MEGANE 2.0 16V",
//       "MEGANE 2.0 16V",
//       "MEGANE 2.0 16V LUXE",
//       "MEGANE 2.0 16V LUXE",
//       "MEGANE ALIZE",
//       "MEGANE AU 1.9 DTI",
//       "MEGANE AUTENTIQUE DIE",
//       "MEGANE AUTENTIQUE TRI",
//       "MEGANE AUTHENTIQUE 1.6",
//       "MEGANE BIC 1.6 16V SL",
//       "MEGANE BIC 1.6 L FAIRWAY",
//       "MEGANE BIC 1.6 L PACK PLUS",
//       "MEGANE BIC 1.6L 16V SL",
//       "MEGANE BIC AUTHENTIQUE",
//       "MEGANE BIC EXPRESSION 1.6",
//       "MEGANE BIC SPORTWAY",
//       "MEGANE BIC SPORTWAY",
//       "MEGANE BIC SPORTWAY",
//       "MEGANE CABRIOLET",
//       "MEGANE CABRIOLET 1.6 16V",
//       "MEGANE CABRIOLET 2.0",
//       "MEGANE CLASSIC 1.9",
//       "MEGANE CLASSIC 1.9 DTI",
//       "MEGANE CLASSIC RN",
//       "MEGANE CLASSIC RN 1.6",
//       "MEGANE CLASSIC RN 1.9",
//       "MEGANE CLASSIC RT 1.6",
//       "MEGANE CLASSIC RT 2.0",
//       "MEGANE CLASSIC RXE 2.0",
//       "MEGANE CLASSIC RXT 1.9 TDI",
//       "MEGANE COUPE",
//       "MEGANE COUPE",
//       "MEGANE COUPE 1.4 16V",
//       "MEGANE COUPE 2.0 16V",
//       "MEGANE D",
//       "MEGANE DYNAMIQUE 1.5D",
//       "MEGANE DYNAMIQUE 1.9DCI",
//       "MEGANE DYNAMIQUE 2.0",
//       "MEGANE DYNAMIQUE 2.0",
//       "MEGANE EXPRESION BIC 16V",
//       "MEGANE EXPRESION DIE BIC",
//       "MEGANE EXPRESION TRI GNC",
//       "MEGANE EXPRESION TRIC",
//       "MEGANE G.TOUR 1.6 16V",
//       "MEGANE G.TOUR 1.6 16V",
//       "MEGANE GRAND TOUR 1.5",
//       "MEGANE GRAND TOUR 1.5",
//       "MEGANE GRAND TOUR 1.6",
//       "MEGANE GRAND TOUR 2.0",
//       "MEGANE GRAND TOUR 2.0",
//       "MEGANE GTOUR 1.5DCI",
//       "MEGANE II 1.6 16V",
//       "MEGANE II 1.6 16V CONFORT",
//       "MEGANE II 1.6 16V CONFORT",
//       "MEGANE II 1.6 16V LUXE",
//       "MEGANE II 1.6 16V LUXE",
//       "MEGANE II 1.6 L PRIVILEGE",
//       "MEGANE II 1.6L EXPRESSION",
//       "MEGANE II 1.9 DCI",
//       "MEGANE II 2.0 16V",
//       "MEGANE II 2.0 16V",
//       "MEGANE II 2.0 16V LUXE",
//       "MEGANE II 2.0 16V LUXE",
//       "MEGANE II 2.0 16V PRIVILEGE",
//       "MEGANE II 2.0 16V PRIVILEGE",
//       "MEGANE II 2.0 16V SL IN",
//       "MEGANE II 2.0 16V SL SPORT",
//       "MEGANE II COUPE CABRIOLET",
//       "MEGANE II DCI CONFORT",
//       "MEGANE II DCI CONFORT",
//       "MEGANE II DCI EXPRESSION",
//       "MEGANE II DCI LUXE",
//       "MEGANE II DCI PRIVILEGE",
//       "MEGANE II G.TOUR 1.6 16V",
//       "MEGANE II G.TOUR 1.6 16V",
//       "MEGANE II G.TOUR 2.0 16V",
//       "MEGANE II G.TOUR 2.0 16V",
//       "MEGANE II G.TOUR DCI LUXE",
//       "MEGANE II G.TOUR DCI LUXE",
//       "MEGANE II GTOUR 16V",
//       "MEGANE II GTOUR 16V",
//       "MEGANE II GTOUR 2.0 16V",
//       "MEGANE II GTOUR 2.0 16V",
//       "MEGANE III 1.5",
//       "MEGANE III 2.0 16V LUXE",
//       "MEGANE III 2.0 16V LUXE R01",
//       "MEGANE III 2.0 16V LUXE TN",
//       "MEGANE III 2.0 16V",
//       "MEGANE III 2.0 16V",
//       "MEGANE III 2.0T 180 CV",
//       "MEGANE III EXPRESSION HB",
//       "MEGANE III PH2 2.0 16V LUXE",
//       "MEGANE III PH2 2.0 16V",
//       "MEGANE III PH2 LUXE PACK",
//       "MEGANE III RS 250CV",
//       "MEGANE III RS 265 CV",
//       "MEGANE L RXE",
//       "MEGANE L RXT 1.9 DT",
//       "MEGANE L RXT 1.9 DT",
//       "MEGANE PRI TRI F2 CAJA AUT",
//       "MEGANE PRIVILEGE 1.9 DTI",
//       "MEGANE PRIVILEGE 1.9 DTI",
//       "MEGANE PRIVILEGE 1.9 TD",
//       "MEGANE PRIVILEGE BIC F2",
//       "MEGANE PRIVILEGE TD BIC",
//       "MEGANE PRIVILEGE TD TRIC",
//       "MEGANE PRIVILEGE TRI F2",
//       "MEGANE RL AA TD TRI F2",
//       "MEGANE RL AA TRI 16V F2",
//       "MEGANE RL GNC AA",
//       "MEGANE RL TD BIC F2",
//       "MEGANE RL TD TRI F2",
//       "MEGANE RL TRI 16V F2",
//       "MEGANE RL TRI GNC",
//       "MEGANE RN",
//       "MEGANE RN 1.6 16V",
//       "MEGANE RN BIC",
//       "MEGANE RN BIC 16V F2 BASE",
//       "MEGANE RN BIC AA 16V F2",
//       "MEGANE RN BIC AA F2",
//       "MEGANE RN BIC AA PACK 16V",
//       "MEGANE RN TD",
//       "MEGANE RN TD",
//       "MEGANE RN TD AA PACK TRI",
//       "MEGANE RN TD BIC AA F2",
//       "MEGANE RN TD BIC F2",
//       "MEGANE RN TD TRI AA F2",
//       "MEGANE RN TD TRI F2",
//       "MEGANE RN TD TRI S/RADIO",
//       "MEGANE RN TRI 100CV F2",
//       "MEGANE RN TRI 100CV F2",
//       "MEGANE RN TRI AA F2",
//       "MEGANE RN TRI F2",
//       "MEGANE RN TRIC AA PACK",
//       "MEGANE RT",
//       "MEGANE RT",
//       "MEGANE RT 1.6",
//       "MEGANE RT 1.6",
//       "MEGANE RT 1.6E",
//       "MEGANE RT 2.0",
//       "MEGANE RT 2.0 16V",
//       "MEGANE RT BIC ABCP AA",
//       "MEGANE RT BIC ABS F2",
//       "MEGANE RT BIC ABS F2",
//       "MEGANE RT BIC F2",
//       "MEGANE RT BIC S/ABS",
//       "MEGANE RT BIC. ABCP",
//       "MEGANE RT BIC. ABCP",
//       "MEGANE RT DT BIC",
//       "MEGANE RT DT BIC ABCP",
//       "MEGANE RT DT BIC.ABCP",
//       "MEGANE RT DT TRIC.",
//       "MEGANE RT DT TRIC. ABCP",
//       "MEGANE RT TD ABCP ABS BIC",
//       "MEGANE RT TD ABCP ABS TRI",
//       "MEGANE RT TD BIC ABS F2",
//       "MEGANE RT TD TRI ABS F2",
//       "MEGANE RT TRI",
//       "MEGANE RT TRI ABCP AA",
//       "MEGANE RT TRI ABS F2",
//       "MEGANE RT TRI F2",
//       "MEGANE RT TRI F4R CAJA",
//       "MEGANE RT TRIC S/ABS",
//       "MEGANE RT TRIC.",
//       "MEGANE RT TRIC. 2.0",
//       "MEGANE RT TRIC. 2.0",
//       "MEGANE RT TRIC. ABCP",
//       "MEGANE RT TRIC. ABCP",
//       "MEGANE RTE 1.9D",
//       "MEGANE RTE 1.9D",
//       "MEGANE RXE",
//       "MEGANE RXE 1.6",
//       "MEGANE RXE 1.9 DTI",
//       "MEGANE RXE 1.9 DTI",
//       "MEGANE RXE 1.9 TDI",
//       "MEGANE RXE 1.9 TDI",
//       "MEGANE RXE 1.9 TDI",
//       "MEGANE RXE 2.0",
//       "MEGANE RXE 2.0",
//       "MEGANE RXE ABCP ABS",
//       "MEGANE RXE ABCP ABS",
//       "MEGANE RXE ABCP ABS BIC",
//       "MEGANE RXE ABCP ABS BIC.",
//       "MEGANE RXE ABCP ABS BIC.",
//       "MEGANE RXE ABCP ABS",
//       "MEGANE RXE ABCP ABS TRIC",
//       "MEGANE RXE BIC 1.6",
//       "MEGANE RXE BIC ABS CUE F2",
//       "MEGANE RXE BIC ABS F2",
//       "MEGANE RXE BIC T. CORR.",
//       "MEGANE RXE BIC T.CORR.F2",
//       "MEGANE RXE BIC.",
//       "MEGANE RXE BIC. ABCP",
//       "MEGANE RXE BIC. ABCP",
//       "MEGANE RXE F4R ABCP ABS",
//       "MEGANE RXE TD BIC S/TC F2",
//       "MEGANE RXE TD TRI",
//       "MEGANE RXE TD TRI S/TC F2",
//       "MEGANE RXE TRI ABCP ABS T",
//       "MEGANE RXE TRI F2",
//       "MEGANE RXE TRI T. CORR.",
//       "MEGANE RXE TRI T. CORR. F2",
//       "MEGANE RXE TRIC.",
//       "MEGANE RXE TRIC. 2.0",
//       "MEGANE RXE TRIC. 2.0",
//       "MEGANE RXE TRIC. ABCP",
//       "MEGANE RXE TRIC. ABCP",
//       "MEGANE RXT 2.0",
//       "MEGANE SCENIC 1.4 16V",
//       "MEGANE SCENIC 1.5 DCI",
//       "MEGANE SCENIC 1.6 16V",
//       "MEGANE SCENIC 1.6 16V",
//       "MEGANE SCENIC 1.9",
//       "MEGANE SCENIC 1.9 DCI",
//       "MEGANE SCENIC 1.9 DTI",
//       "MEGANE SCENIC 1.9 DTI",
//       "MEGANE SCENIC 1.9 DTI",
//       "MEGANE SCENIC 1.9 RX4",
//       "MEGANE SCENIC 1.9 RXT TDI",
//       "MEGANE SCENIC 1.9 TDI",
//       "MEGANE SCENIC 16 V",
//       "MEGANE SCENIC 16 V",
//       "MEGANE SCENIC 2.0",
//       "MEGANE SCENIC 2.0 16V",
//       "MEGANE SCENIC CENTURY",
//       "MEGANE SCENIC II 1.5 DCI",
//       "MEGANE SCENIC RN",
//       "MEGANE SCENIC RN 1.6E",
//       "MEGANE SCENIC RT",
//       "MEGANE SCENIC RT 1.6",
//       "MEGANE SCENIC RT 1.6 16V",
//       "MEGANE SCENIC RT 1.6E",
//       "MEGANE SCENIC RT 1.9 DTI",
//       "MEGANE SCENIC RT 1.9 DTI",
//       "MEGANE SCENIC RT 1.9TD",
//       "MEGANE SCENIC RT 2.0",
//       "MEGANE SCENIC RT 2L",
//       "MEGANE SCENIC RTE 1.9TD",
//       "MEGANE SCENIC RX4",
//       "MEGANE SCENIC RX4 1.9 DCI",
//       "MEGANE SCENIC RX4 1.9 DTI",
//       "MEGANE SCENIC RXE 1.9 DCI",
//       "MEGANE SCENIC RXE 1.9 DTI",
//       "MEGANE SCENIC RXE 16V",
//       "MEGANE SCENIC RXE 2.0",
//       "MEGANE SCENIC RXE 2.0 16V",
//       "MEGANE SCENIC RXI 2.0 16V",
//       "MEGANE SCENIC RXT 1.6",
//       "MEGANE SCENIC RXT 1.6 16V",
//       "MEGANE SCENIC RXT 1.6E",
//       "MEGANE SCENIC RXT 1.9D",
//       "MEGANE SCENIC RXT 1.9D",
//       "MEGANE SCENIC RXT 2.0",
//       "MEGANE SCENIC TURBO",
//       "MEGANE SPORT",
//       "MEGANE TD 1.9 ECO",
//       "MEGANE TRI 1.6 ECO",
//       "MEGANE TRI 1.6 L GNC PACK",
//       "MEGANE TRI 1.6 L GNC PACK",
//       "MEGANE TRI 1.6 L PACK",
//       "MEGANE TRI 1.6 L PACK PLUS",
//       "MEGANE TRI 1.6+GNC ECO",
//       "MEGANE TRI 1.6L 16V SL",
//       "MEGANE TRI 1.6L PACK PLUS",
//       "MEGANE TRI 1.9 L TD PACK",
//       "MEGANE TRI 1.9 L TD PACK",
//       "MEGANE TRI 1.9L TD PACK",
//       "MEGANE TRI AUTHENTIQUE",
//       "MEGANE TRI AUTHENTIQUE",
//       "MEGANE TRI AUTHENTIQUE",
//       "MEGANE TRI AUTHENTIQUE",
//       "MEGANE TRI EXPRESSION 1.6",
//       "MEGANE TRI EXPRESSION 1.9",
//       "MEGANE TRIC 1.6 L FAIRWAY",
//       "MEGANE TRIC 1.9 L DTI",
//       "MEGANE TRIC EXPRESION TD",
//       "MEGANE TRIC SPORTWAY",
//       "MEGANE TRIC SPORTWAY",
//       "MEGANE TRIC SPORTWAY",
//       "MEGANE UNIQUE BIC 16V",
//       "MEGANE UNIQUE TRIC 16V",
//       "MEGANEII GTOUR 2.0 16V",
//       "MIDLUM 150 12B",
//       "MIDLUM 220 DXI",
//       "MIDLUM 250.16",
//       "MIDLUM 250.16",
//       "MIDLUM 270 DCI",
//       "MIDLUM 300 DCI",
//       "MIDLUM 300 DXI",
//       "MIDLUM 300 DXI",
//       "MIDLUM 300 DXI",
//       "MIDLUM 300 DXI",
//       "MIDLUM 300 T DCI",
//       "MIDLUM DXI",
//       "MODUS 1.2",
//       "MODUS 1.5",
//       "MS 300 P",
//       "MS 300 P",
//       "MURANO XTRONIC CVT",
//       "NUEVA MASTER CC",
//       "NUEVA MASTER L1H1",
//       "NUEVA MASTER L1H1 AA",
//       "NUEVA MASTER L2H2 AA",
//       "NUEVA MASTER L3H2 AA",
//       "NUEVO DUSTER ICONIC 1.3T",
//       "NUEVO DUSTER ICONIC 1.3T",
//       "NUEVO DUSTER INTENS 1.6",
//       "NUEVO DUSTER INTENS 1.6",
//       "NUEVO DUSTER LIFE 1.6",
//       "NUEVO DUSTER OUTSIDER",
//       "NUEVO DUSTER ZEN 1.6",
//       "NUEVO LOGAN",
//       "NUEVO LOGAN",
//       "NUEVO LOGAN",
//       "NUEVO LOGAN",
//       "NUEVO LOGAN EXPRESSION",
//       "NUEVO LOGAN EXPRESSION",
//       "NUEVO LOGAN PRIVILEGE 1.6",
//       "NUEVO LOGAN PRIVILEGE 1.6",
//       "NUEVO LOGAN PRIVILEGE",
//       "NUEVO LOGAN PRIVILEGE",
//       "NUEVO MASTER CC",
//       "NUEVO MASTER L1H1",
//       "NUEVO MASTER L1H1 AA",
//       "NUEVO MASTER L2H2 AA",
//       "NUEVO MASTER L3H2 AA",
//       "NUEVO MASTER MINIBUS",
//       "NUEVO SANDERO",
//       "NUEVO SANDERO",
//       "NUEVO SANDERO",
//       "NUEVO SANDERO",
//       "NUEVO SANDERO",
//       "NUEVO SANDERO",
//       "NUEVO SANDERO",
//       "NUEVO SANDERO",
//       "NUEVO SANDERO GT LINE",
//       "NUEVO SANDERO PRIVILEGE",
//       "NUEVO SANDERO PRIVILEGE",
//       "NUEVO SANDERO PRIVILEGE",
//       "NUEVO SANDERO PRIVILEGE",
//       "NUEVO SANDERO RS",
//       "NUEVO SANDERO RS RACING",
//       "NUEVO SANDERO STEPWAY",
//       "NUEVO SANDERO STEPWAY",
//       "NUEVO SANDERO STEPWAY",
//       "NUEVO SANDERO STEPWAY",
//       "NUEVO SANDERO STEPWAY",
//       "NUEVO SANDERO STEPWAY",
//       "NUEVO SANDERO STEPWAY",
//       "NUEVO SANDERO STEPWAY",
//       "NUEVO SANDERO STEPWAY",
//       "OROCH EMOTION 1.6 SCE 114",
//       "OROCH ICONIC 1.3 TCE 163",
//       "OROCH OUTSIDER 1.3 TCE",
//       "PREMIUM 250",
//       "PREMIUM 270 DCI",
//       "PREMIUM 300",
//       "PREMIUM 300.19",
//       "PREMIUM 300.19",
//       "PREMIUM 320 DCI 19T",
//       "PREMIUM 320 DXI",
//       "PREMIUM 320 DXI",
//       "PREMIUM 320 DXI",
//       "PREMIUM 320 DXI",
//       "PREMIUM 320DCI",
//       "PREMIUM 320DCI",
//       "PREMIUM 320DCI",
//       "PREMIUM 330 DXI",
//       "PREMIUM 330 DXI",
//       "PREMIUM 340.19 P",
//       "PREMIUM 340.19T",
//       "PREMIUM 370 DCI",
//       "PREMIUM 370P DCI",
//       "PREMIUM 380 DXI",
//       "PREMIUM 380 DXI",
//       "PREMIUM 380 DXI",
//       "PREMIUM 380 DXI",
//       "PREMIUM 385 PRIVILEGE",
//       "PREMIUM 385.19",
//       "PREMIUM 385.19 P",
//       "PREMIUM 385.19T",
//       "PREMIUM 385.24 T",
//       "PREMIUM 400",
//       "PREMIUM 420 DCI",
//       "PREMIUM 420 DCI",
//       "PREMIUM 420P DCI",
//       "PREMIUM 440 DXI",
//       "PREMIUM 440 DXI",
//       "PREMIUM HD 300.26 6X2",
//       "PREMIUM PRIVILEGE 420DCI",
//       "R 19",
//       "R 19",
//       "R 19 RL",
//       "R 19 RL",
//       "R 19 RL 1.4I",
//       "R 19 RL DIESEL",
//       "R 19 RN 1.6I",
//       "R 19 RN DIESEL",
//       "R19 1.4I",
//       "R-19 GTS/TS",
//       "R-19 HATCHBACK",
//       "R19 RE INJECTION",
//       "R19 RE INJECTION AA",
//       "R-19 RL",
//       "R-19 RT-DT",
//       "R-20 OTROS",
//       "R-21 MANAGER",
//       "R-21/GTX",
//       "RODEO C/CAJA C/DA (F8Q)",
//       "RODEO C/CAJA S/DA (F8Q)",
//       "RODEO S/CAJA C/DA (F8Q)",
//       "RODEO S/CAJA S/DA (F8Q)",
//       "SANDERO 1.5 DCI CONFORT",
//       "SANDERO 1.5 DCI LUXE",
//       "SANDERO 1.5 DCI LUXE S/ABS",
//       "SANDERO 1.5 DCI PACK",
//       "SANDERO 1.6 16V CONFORT",
//       "SANDERO 1.6 16V LUXE",
//       "SANDERO 1.6 16V LUXE S/ABS",
//       "SANDERO 1.6 8V BASE",
//       "SANDERO 1.6 8V PACK",
//       "SANDERO AUTH. PACK I 1.6",
//       "SANDERO AUTH. PACK II 1.6",
//       "SANDERO AUTHENTIQUE",
//       "SANDERO EXP 10 16V",
//       "SANDERO EXPRESSION 1.6",
//       "SANDERO EXPRESSION 1.6 8V",
//       "SANDERO GET UP 1.6 16V",
//       "SANDERO GT LINE 1.6 16V",
//       "SANDERO GT LINE 1.6 16V",
//       "SANDERO PH2 CONFORT 1.6",
//       "SANDERO PH2 GT LINE 1.6",
//       "SANDERO PH2 GT LINE 1.6",
//       "SANDERO PH2 INTENS 1.6",
//       "SANDERO PH2 INTENS 1.6",
//       "SANDERO PH2 INTENS 1.6",
//       "SANDERO PH2 LIFE 1.6",
//       "SANDERO PH2 LIFE 1.6",
//       "SANDERO PH2 LIFE PLUS 1.6",
//       "SANDERO PH2 LUXE 1.6 16V",
//       "SANDERO PH2 PACK 1.6 8V",
//       "SANDERO PH2 PACK PLUS 1.6",
//       "SANDERO PH2 RS 2.0",
//       "SANDERO PH2 STEPWAY",
//       "SANDERO PH2 STEPWAY",
//       "SANDERO PH2 STEPWAY",
//       "SANDERO PH2 STEPWAY ZEN",
//       "SANDERO PH2 STEPWAY ZEN",
//       "SANDERO PH2 ZEN 1.6",
//       "SANDERO PH2 ZEN 1.6",
//       "SANDERO PRIVILEGE 1.6 16V",
//       "SANDERO RS",
//       "SANDERO SL TECH RUN",
//       "SANDERO STEPWAY 1.6 16V",
//       "SANDERO STEPWAY 1.6 SL RIP",
//       "SANDERO STEPWAY",
//       "SANDERO STEPWAY CONFORT",
//       "SANDERO STEPWAY CONFORT",
//       "SANDERO STEPWAY CONFORT",
//       "SANDERO STEPWAY",
//       "SANDERO STEPWAY LUXE",
//       "SANDERO STEPWAY LUXE 1.6",
//       "SANDERO STEPWAY PH2",
//       "SANDERO STEPWAY PH2",
//       "SANDERO STEPWAY PH2",
//       "SANDERO STEPWAY PH2",
//       "SANDERO STEPWAY PH2 LUXE",
//       "SANDERO STEPWAY PH2 LUXE",
//       "SANDERO STEPWAY PH2",
//       "SANDERO STEPWAY",
//       "SANDERO STEPWAY SL",
//       "SCENIC",
//       "SCENIC 1.2",
//       "SCENIC 1.5 DCI",
//       "SCENIC 1.6L 16V CONFORT",
//       "SCENIC 1.6L 16V CONFORT",
//       "SCENIC 1.6L 16V CONFORT",
//       "SCENIC 1.6L LUXE",
//       "SCENIC 1.9 DCI",
//       "SCENIC 1.9 DTI",
//       "SCENIC 1.9L DTI CONFORT",
//       "SCENIC 1.9L DTI LUXE",
//       "SCENIC 1.9L TD CONFORT",
//       "SCENIC 1.9L TD LUXE",
//       "SCENIC 16L CONFORT",
//       "SCENIC 16L LUXE",
//       "SCENIC 2 RXT",
//       "SCENIC 2.0 16V PRIVILEGE",
//       "SCENIC 2.0 16V PRIVILEGE",
//       "SCENIC 2.0L LUXE",
//       "SCENIC 2.0L LUXE",
//       "SCENIC 2.0L LUXE AUTO",
//       "SCENIC MEGANE 1.9 DCI",
//       "SCENIC MEGANE RXE",
//       "SCENIC RXE 2.0",
//       "SCENIC RXT 1.6 16V",
//       "SCENIC RXT 1.9DTI",
//       "SCENIC SPORTWAY 1.6L 16V",
//       "STEPWAY JUMP LE 1.6",
//       "STEPWAY PH2 INTENS 1.6",
//       "STEPWAY PH2 ZEN 1.6",
//       "STEPWAY PH2 ZEN PLUS 1.6",
//       "SYMBOL 1.6 PRIVILEGE",
//       "SYMBOL AUTHENTIQUE 1.6",
//       "SYMBOL AUTHENTIQUE 1.6",
//       "SYMBOL AUTHENTIQUE 1.6",
//       "SYMBOL CONFORT 1.5 DCI",
//       "SYMBOL CONFORT 1.5 DCI",
//       "SYMBOL CONFORT 1.6 16V",
//       "SYMBOL CONNECTION 1.5",
//       "SYMBOL CONNECTION 1.6",
//       "SYMBOL EXPRESSION 1.6",
//       "SYMBOL LUXE 1.6 16V",
//       "SYMBOL PACK 1.5 DCI",
//       "SYMBOL PACK 1.6 16V",
//       "SYMBOL PACK 1.6 8V",
//       "SYMBOL SL CONNECTION 1.6",
//       "TIIDA 1.8 6MT TEKNA",
//       "TIIDA 1.8 6MT TEKNA",
//       "TIIDA 1.8 6MT VISIA",
//       "TIIDA 1.8 6MT VISIA",
//       "TRAFIC",
//       "TRAFIC",
//       "TRAFIC",
//       "TRAFIC",
//       "TRAFIC (T5X300)",
//       "TRAFIC /LARGO NAFTA /",
//       "TRAFIC 2.0 D",
//       "TRAFIC CORTO DIESEL",
//       "TRAFIC CORTO DIESEL (F8Q)",
//       "TRAFIC CORTO NAF. S/P LAT",
//       "TRAFIC CORTO NAF.2.2 C/DA",
//       "TRAFIC CORTO NAF.S/P.LAT.",
//       "TRAFIC CORTO NAFTA",
//       "TRAFIC CORTO NAFTA /",
//       "TRAFIC CORTO NAFTA 2.0",
//       "TRAFIC DCI 100",
//       "TRAFIC LARGO C. TECHO SOB.",
//       "TRAFIC LARGO DIE.DA (F8Q)",
//       "TRAFIC LARGO DIESEL",
//       "TRAFIC LARGO DIESEL (F8Q)",
//       "TRAFIC LARGO DIESEL C/DA",
//       "TRAFIC LARGO DIESEL C/DA",
//       "TRAFIC LARGO NAFTA",
//       "TRAFIC LARGO NAFTA CERR.",
//       "TRAFIC LARGO NAFTA D.",
//       "TRAFIC LARGO TA1C",
//       "TRAFIC MASTER TURBO T35D",
//       "TRAFIC MINIBUS CORTO DIES.",
//       "TRAFIC MINIBUS CORTO",
//       "TRAFIC MINIBUS CTO. NF 1.4",
//       "TRAFIC MINIBUS CTO. NF 2.0",
//       "TRAFIC MINIBUS CTO.NF.1.4",
//       "TRAFIC MINIBUS CTO.NF.2.0",
//       "TRAFIC MINIBUS LARGO DIES.",
//       "TRAFIC MINIBUS LARGO",
//       "TRAFIC MINIBUS LARGO N 2.2",
//       "TRAFIC MINIBUS LARGO N 2.2",
//       "TRAFIC MINIBUS LARGO NAF",
//       "TRAFIC MINIBUS LARGO",
//       "TRAFIC OTROS",
//       "TRAFIC PASSENGER DCI 115",
//       "TRAFIC RODEO C/CAJA C/DA",
//       "TRAFIC RODEO C/CAJA N 2.2",
//       "TRAFIC RODEO C/CAJA NAFTA",
//       "TRAFIC RODEO C/CAJA S/DA",
//       "TRAFIC RODEO C/CAJA S/DA",
//       "TRAFIC RODEO C/CAJA/DA",
//       "TRAFIC RODEO S/CAJA C/DA",
//       "TRAFIC RODEO S/CAJA C/DA",
//       "TRAFIC RODEO S/CAJA DIESEL",
//       "TRAFIC RODEO S/CAJA NAFTA",
//       "TRAFIC RODEO S/CAJA S/DA",
//       "TRAFIC RODEO S/CAJA S/DA",
//       "TRAFIC T 1100 D",
//       "TRAFIC T 413",
//       "TRAFIC T1400 D",
//       "TRAFIC T413",
//       "TRAFIC T41F",
//       "TRAFIC T55F",
//       "TRAFIC TA 13",
//       "TRAFIC TA 13",
//       "TRAFIC TA-1C",
//       "TWINGO",
//       "TWINGO",
//       "TWINGO",
//       "TWINGO ALIZE",
//       "TWINGO INITIALE 1.2",
//       "TWINGO OTROS",
//       "VEL SATIS",
//       "VEL SATIS 3.0 DCI INITIALE",
//       "DUSTER CONFORT 1.6 4X2",
//       "DUSTER CONFORT PLUS 1.6",
//       "DUSTER LUXE 2.0 4X2",
//       "DUSTER LUXE 2.0 4X4",
//       "LAGUNA 2.2 DCI",
//       "MASTER PH3 DCI 120 L1H1",
//       "MASTER PH3 DCI 120 L1H1",
//       "MASTER PH3 DCI 120 L1H2",
//       "MASTER PH3 DCI 120 L2H2",
//       "MASTER PH3 DCI 120 L3H2",
//       "MASTER PH3 DCI 120 PACK 2",
//       "MASTER PH3 DCI 120 PACK",
//       "MEGANE 1.4 16V",
//       "MEGANE 1.4 16V",
//       "MEGANE COUPE 1.6 16V",
//       "MEGANE GRAND TOUR 1.9",
//       "MEGANE II 1.6 16V",
//       "SANDERO AUTHENTIQUE",
//       "SANDERO AUTHENTIQUE",
//       "SANDERO EXPRESSION 1.6",
//       "SANDERO PRIVILEGE 1.6 16V",
//       "SANDERO STEPWAY",
//       "SANDERO STEPWAY",
//       "SANDERO STEPWAY",
//       "SANDERO STEPWAY RIP CURL"
//     ],
//     "ROVER": [
//       "200",
//       "200",
//       "200 BRM",
//       "200 SDI",
//       "200 VI",
//       "214 SI",
//       "214 SI",
//       "216 COUPE",
//       "216 DOHC",
//       "216 SI",
//       "216 SLI",
//       "218 SD",
//       "218 SD",
//       "218 VI",
//       "220 SD",
//       "220 SDI",
//       "414 GSI",
//       "414 GSI",
//       "414 GSI/SLI/SLI SALOON",
//       "414 SLI",
//       "416",
//       "416 GSI",
//       "416 GTI",
//       "416 SI",
//       "416 SI",
//       "416 SI SALOON",
//       "416 SI SALOON",
//       "420 D",
//       "420 D",
//       "420 DI",
//       "420 DI",
//       "420 DI",
//       "420 SDI",
//       "420 SI",
//       "420 SLDI",
//       "420 SLI",
//       "45",
//       "45 TURBO DIESEL",
//       "4S",
//       "618 I",
//       "618 SI",
//       "620 GSDI",
//       "620 GSI",
//       "620 SDI",
//       "620 SDI LUX",
//       "620 SI LUX",
//       "620 TI",
//       "623 GSI",
//       "623 SI",
//       "623 SI LUX",
//       "75 1.8",
//       "75 CONNOISSEUR",
//       "820 SI",
//       "820 SI",
//       "820 SI",
//       "820 SI/TI",
//       "820 TI",
//       "COUNTY 110",
//       "E-RS25",
//       "R 25 1.6",
//       "R 25 2.0 TD",
//       "R 45 1.6",
//       "R 45 1.6",
//       "R 45 1.8",
//       "R 45 1.8",
//       "R 45 2.0 AT",
//       "R 45 2.0 TD",
//       "R 45 2.0 TD",
//       "R 75 2.0",
//       "R 75 2.0 D TOURER",
//       "R 75 2.0 TD",
//       "R 75 2.0 TD AT",
//       "R 75 2.0 TOURER",
//       "R 75 2.5",
//       "R 75 2.5 AT",
//       "R75 2.0 V6",
//       "R75 2.5 V6",
//       "RANGE ROVER",
//       "ROVER 414 SLI SALOON"
//     ],
//     "ROYAL ENFIELD": [
//       "BULLET 500",
//       "BULLET CLASSIC EFI",
//       "BULLET ELECTRA CLASSIC EFI",
//       "BULLET ELECTRA EFI",
//       "BULLET TRIALS 500",
//       "CLASSIC 350",
//       "CLASSIC 500",
//       "CONTINENTAL 650",
//       "CONTINENTAL GT",
//       "CONTINENTAL GT 650",
//       "CONTINENTAL GT",
//       "HIMALAYAN",
//       "HIMALAYAN",
//       "INTERCEPTOR 650",
//       "METEOR 350",
//       "SCRAM"
//     ],
//     "RVM": [
//       "250-9",
//       "400 TWINS",
//       "CF650",
//       "CF650TR",
//       "CZ 200-2",
//       "JAWA250",
//       "RM600",
//       "RVM 500",
//       "RVM 500-2",
//       "RVM CZ 250",
//       "TEKKEN 250",
//       "TEKKEN 300",
//       "TEKKEN-250"
//     ],
//     "SAAB": [
//       "9.3",
//       "9.3",
//       "9.3 DIESEL",
//       "9.3 SE",
//       "9.3 SE",
//       "9.3 SE",
//       "9.3 SE AT",
//       "9.3 VECTOR 2.0 T",
//       "9.5",
//       "9.5 AERO",
//       "9.5 ARC 2.3 LPT",
//       "9.5 ARC AT",
//       "9.5 ARC AT",
//       "9.5 GE",
//       "9.5 GRIFFIN",
//       "9.5 LINEAR",
//       "9.5 LINEAR AT",
//       "9.5 SE",
//       "9.5 SE",
//       "9.5 VECTOR AT",
//       "9.5 VECTOR V6 3.0 LPT",
//       "900",
//       "900 I",
//       "900 I",
//       "900 I",
//       "900 S",
//       "900 S",
//       "900 S",
//       "900 SE",
//       "900 SE",
//       "900 SE",
//       "900 SE 2.3I",
//       "900 T",
//       "900 TURBO 2.0",
//       "900 V6",
//       "9000 AERO",
//       "9000 CD",
//       "9000 CDE",
//       "9000 CDEI",
//       "9000 CDET",
//       "9000 CDET AUTOM",
//       "9000 CDI AUTOMA",
//       "9000 CS",
//       "9000 CS",
//       "9000 CS/CSE",
//       "9000 CSE",
//       "9000 CSEI FULL",
//       "9000 CSET",
//       "9000 CSET AUTOM",
//       "9000 CSI AUTOMA",
//       "9000 CST AUTOMA",
//       "9000 CST MANUAL",
//       "9000 GRIFFIN",
//       "9000 I",
//       "9000 S",
//       "9000 TURBO",
//       "9000/CD/CDE",
//       "900S/SE/SE 2.3 I",
//       "9-3 2.0T",
//       "9-3 2.0T",
//       "9-3 2.0T",
//       "AERO 2.0TS",
//       "ARC 2.0T"
//     ],
//     "SAAB SCANIA": [
//       "P 114 CA 6x4 NZ 360",
//       "P 114 CB 6x4 NZ 360",
//       "P 114 GA 4X2 NZ 320",
//       "P 114 GA 4X2 NZ 330",
//       "P 114 GA 6X2 NA 330",
//       "P 114 GB 4X2 NZ 320",
//       "P 114 GB 4X2 NZ 330",
//       "P 124 CA 6x4 NZ 360",
//       "P 124 CB 6x4 NZ 360",
//       "P 94 CB 6x4 NZ 260",
//       "P 94 DB 4X2 NZ 220",
//       "P 94 DB 4x2 NZ 260",
//       "P 94 DB 6X2 NA 220",
//       "P 94 DB 6x2 NA 260",
//       "P 94 GA 4X2 NZ 220",
//       "P 94 GA 4X2 NZ 260",
//       "P93H 4X2 38/46/50/54 DSC",
//       "P94 GA 4X2 NA 260",
//       "R 114 GA 4x2 NZ 320",
//       "R 114 GA 4X2 NZ 330",
//       "R 114 GA 4x2 NZ 360",
//       "R 114 GB 4x2 NZ 320",
//       "R 114 GB 4X2 NZ 330",
//       "R 114 GB 4x2 NZ 360",
//       "R 124 GA 4x2 NZ 360",
//       "R 124 GA 4x2 NZ 420",
//       "R 124 GA 6x4 NZ 360",
//       "R 124 GA 6x4 NZ 420",
//       "R 124 GB 4x2 NZ 360",
//       "R 124 GB 4x2 NZ 420",
//       "R 124 LA 4x2 NA 360",
//       "R 124 LA 4x2 NA 420",
//       "R 124 LA 6x2 NA 360",
//       "R 124 LA 6x2 NA 420",
//       "R113 E6X4 SS5",
//       "T 114 GA 4x2 NZ 320",
//       "T 114 GA 4X2 NZ 330",
//       "T 114 GA 4x2 NZ 360",
//       "T 114 GB 4x2 NZ 320",
//       "T 114 GB 4X2 NZ 330",
//       "T 114 GB 4x2 NZ 360",
//       "T 124 GA 4x2 NZ 360",
//       "T 124 GA 4x2 NZ 420",
//       "T 124 GB 4x2 NZ 360",
//       "T 124 GB 4x2 NZ 420",
//       "T 124 LA 4x2 NA 360",
//       "T 124 LA 4x2 NA 420",
//       "T113H 4X2 42/54 DSC"
//     ],
//     "SANTANA": [
//       "JX",
//       "SAMURAI",
//       "SAMURAI",
//       "SAMURAI 1.9TD",
//       "SAMURAI 1.9TD",
//       "SAMURAI 1.9TD (TECHO DE",
//       "SAMURAI 1.9TD (TECHO",
//       "SJ-410",
//       "VITARA JLX 1.9 D",
//       "VITARA JX 1.9 D"
//     ],
//     "SANXING": ["SXZ1045B", "SXZ1045BP", "SXZ1047", "SXZ1047W", "SXZ3043"],
//     "SATURN": ["VUE"],
//     "SCANIA": [
//       "114C 380",
//       "114C 380",
//       "114G 340",
//       "114L 340",
//       "114L 400",
//       "124C",
//       "124C 420",
//       "124G 420",
//       "124G 420",
//       "124L 400",
//       "124L 420",
//       "124L 420",
//       "124L 470 4X2",
//       "144L 460",
//       "164L V8 480",
//       "93 M",
//       "93 M 4X2 210",
//       "93H",
//       "94D 310",
//       "94G 260",
//       "94G 260",
//       "94G 300",
//       "G310 B6X2",
//       "G320 A4X2",
//       "G320 A6X2",
//       "G320 B6X2",
//       "G320 B6X4",
//       "G340 A4X2",
//       "G340 A4X2",
//       "G340 A6X2",
//       "G340 B4X2",
//       "G340 B4X2",
//       "G340 LA4X2 HHZ",
//       "G360 A6X4",
//       "G360 B4X2",
//       "G360 B4X4",
//       "G380 A4X2",
//       "G380 A4X2",
//       "G380 A6X2",
//       "G380 A6X2",
//       "G380 A6X4",
//       "G380 A6X4",
//       "G380 B4X2",
//       "G380 B4X2",
//       "G380 B6X2",
//       "G380 LA4X2 HNA",
//       "G400",
//       "G400 A6X2",
//       "G400 A6X4",
//       "G400 A6X4",
//       "G400 B6X4",
//       "G410 B4X4",
//       "G410 B6X4",
//       "G410 B6X4",
//       "G410 B8X4",
//       "G410 B8X4",
//       "G420 A4X2",
//       "G420 A4X2",
//       "G420 A6X2",
//       "G420 A6X2",
//       "G420 A6X4",
//       "G420 A6X4",
//       "G420 B 8X4",
//       "G420 B4X2",
//       "G420 B4X2",
//       "G420 B6X4",
//       "G420 B6X4",
//       "G420 B8X4",
//       "G440 A6X2",
//       "G440 A6X4",
//       "G440 A6X4",
//       "G440 B6X4",
//       "G440 B8X4",
//       "G450 A4X2",
//       "G450 A6X2",
//       "G450 A6X4",
//       "G450 B4X2",
//       "G450 B6X2",
//       "G460 A6X4",
//       "G460 B6X6",
//       "G460 B6X6",
//       "G460 B8X4",
//       "G470 A4X2",
//       "G470 A4X2",
//       "G470 B8X4",
//       "G480 B8X4",
//       "G480 B8X4",
//       "G500 A6X4",
//       "G500 B8X4",
//       "K250 B4X2",
//       "K310 B4X2",
//       "K400 B6X2",
//       "K440 B6X2",
//       "P 380",
//       "P094DB 6X2",
//       "P114 GA",
//       "P114CA",
//       "P114GB",
//       "P114LA6X2",
//       "P124",
//       "P124 GA 4X2 NA420",
//       "P124 GA 6X2 NZ 360",
//       "P124 GA 8X4",
//       "P124 LA",
//       "P124 LA4X2NA 420",
//       "P124 LA4X2NA 420",
//       "P124CA",
//       "P124CB",
//       "P124GA",
//       "P124GA 4X2 NZ360",
//       "P124GB 4X2 NZ",
//       "P124LB 6X2 4NA 360",
//       "P230 B4X2",
//       "P250 A4X2",
//       "P250 A4X2",
//       "P250 B4X2",
//       "P250 B4X2",
//       "P250 B6X2",
//       "P250 B8X2",
//       "P270 6X2",
//       "P270 6X4",
//       "P270 B6X2",
//       "P270 B6X4",
//       "P280 A4X2",
//       "P280 B4X2",
//       "P280 B4X2",
//       "P280 B6X2",
//       "P280 B6X2",
//       "P280 B6X4",
//       "P280 B6X4",
//       "P310 4X2",
//       "P310 4X2",
//       "P310 4X2",
//       "P310 6X4",
//       "P310 A4X2",
//       "P310 A4X2",
//       "P310 A6X4",
//       "P310 A6X4",
//       "P310 B4X2",
//       "P310 B4X2",
//       "P310 B4X2",
//       "P310 B6X2",
//       "P310 B6X2",
//       "P310 B6X4",
//       "P310 B6X4",
//       "P310 B8X2",
//       "P310 B8X2",
//       "P310 B8X4",
//       "P310 B8X4",
//       "P320 A4X2",
//       "P320 A6X2",
//       "P320 A6X4",
//       "P320 B4X2",
//       "P320 B6X2",
//       "P320 B6X4",
//       "P320 B6X4",
//       "P320 B8X4",
//       "P320 B8X4",
//       "P340",
//       "P340 4X2",
//       "P340 4X2",
//       "P340 6X2",
//       "P340 6X2",
//       "P340 A4X2",
//       "P340 A4X2",
//       "P340 A6X2",
//       "P340 A6X2",
//       "P340 B4X2",
//       "P340 B4X2",
//       "P340 B6X4",
//       "P340 B8X4",
//       "P340 B8X4",
//       "P360 A6X2",
//       "P360 B4X4",
//       "P360 B6X2",
//       "P360 B6X4",
//       "P360 B8X2",
//       "P360 B8X4",
//       "P360 B8X4",
//       "P380 6X4",
//       "P380 6X4",
//       "P380 6X4",
//       "P380 8X4",
//       "P380 8X4",
//       "P380 A6X4",
//       "P380 A6X4",
//       "P380 A6X4",
//       "P380 A6X4",
//       "P380 B6X4",
//       "P380 B6X4",
//       "P380 B8X4",
//       "P380 B8X4",
//       "P400 A6X4",
//       "P400 A6X4",
//       "P400 B6X4",
//       "P400 B6X4",
//       "P400 B8X4",
//       "P400 B8X4",
//       "P410 A6X2",
//       "P410 A6X4",
//       "P410 B4X2",
//       "P410 B4X4",
//       "P410 B4X4",
//       "P410 B6X2",
//       "P410 B6X4",
//       "P410 B8X4",
//       "P420 4X8",
//       "P420 6X4",
//       "P420 6X4",
//       "P420 6X6",
//       "P420 8X4",
//       "P420 8X4",
//       "P420 A6X4",
//       "P420 A6X4",
//       "P420 B4X4",
//       "P420 B4X4",
//       "P420 B6X4",
//       "P420 B6X4",
//       "P420 B6X6",
//       "P420 B6X6",
//       "P420 B8X4",
//       "P420 LA4X2 MNA",
//       "P440 A6X2",
//       "P440 A6X4",
//       "P440 B6X4",
//       "P440 B8X4",
//       "P440 B8X4",
//       "P94 GA",
//       "P94CB",
//       "P94DB",
//       "R113",
//       "R113",
//       "R113",
//       "R113 E",
//       "R113 E",
//       "R113 E",
//       "R113 ML 6X2",
//       "R113E 6X4",
//       "R113H",
//       "R113H 4X2",
//       "R113H 6X2 C34",
//       "R113H 6X2 D34",
//       "R113H 6X2 S34",
//       "R113M",
//       "R113MA",
//       "R113MA",
//       "R113MA (HORMIGONERO)",
//       "R113MA 4X2 Z",
//       "R114",
//       "R114GA",
//       "R114GB",
//       "R114GB 6X2",
//       "R124",
//       "R124 400",
//       "R124 470",
//       "R124 CB 6X4",
//       "R124 CB 6X6",
//       "R124 CB 6X6 HZ400",
//       "R124 LA",
//       "R124 LA 4X2 NA 470",
//       "R124 LA 4X2 NA420",
//       "R124 LA 4X2 NA420",
//       "R124GA",
//       "R124GB",
//       "R124LBX2",
//       "R124LBX2",
//       "R142H",
//       "R142HS",
//       "R142MA 4X2",
//       "R142MA 4X2",
//       "R142ML 6X4",
//       "R144 LB 4X2",
//       "R144LA4X2NA530",
//       "R340 4X2",
//       "R340 4X2",
//       "R360 A6X2",
//       "R380 4X2",
//       "R380 4X2",
//       "R380 6X2",
//       "R380 6X4",
//       "R380 A4X2",
//       "R380 A4X2",
//       "R380 A6X2",
//       "R380 B4X2",
//       "R380 B4X2",
//       "R400 A4X2",
//       "R400 A6X2",
//       "R400 B4X2",
//       "R400 B4X4",
//       "R410 A6X4",
//       "R410 B4X4",
//       "R420 4X2",
//       "R420 4X2",
//       "R420 6X2",
//       "R420 6X4",
//       "R420 6X4",
//       "R420 6X6",
//       "R420 8X4",
//       "R420 8X4",
//       "R420 A4X2",
//       "R420 A4X2",
//       "R420 A6X2",
//       "R420 A6X4",
//       "R420 B4X2",
//       "R420 B4X2",
//       "R420 LA 4X2 MNA",
//       "R440 A4X2",
//       "R440 A6X2",
//       "R440 B4X2",
//       "R450 A4X2",
//       "R450 A6X2",
//       "R450 A6X4",
//       "R450 B4X2",
//       "R450 B6X2",
//       "R470 A4X2",
//       "R470 A4X2",
//       "R470 A6X2",
//       "R470 A6X4",
//       "R470 B4X2",
//       "R470 B4X2",
//       "R480 4X2",
//       "R480 4X2",
//       "R480 6X4",
//       "R480 A4X2",
//       "R480 A6X2",
//       "R480 A6X4",
//       "R500 A4X2",
//       "R500 A6X2",
//       "R500 A6X4",
//       "R500 A6X4",
//       "R500 B4X2",
//       "R500 B6X2",
//       "R540 A4X2",
//       "R540 A6X2",
//       "R540 A6X4",
//       "R560 A4X2",
//       "R560 A6X4",
//       "R580 A6X4",
//       "R620 A4X2",
//       "R620 A6X2",
//       "R620 A6X4",
//       "S450 A4X2",
//       "S500 A4X2",
//       "S500 A6X2",
//       "S500 A6X4",
//       "S500 B4X2",
//       "S540 A6X2",
//       "S620 A6X2",
//       "T113E",
//       "T113E",
//       "T113E",
//       "T113E 6X4 S42",
//       "T113E OTROS",
//       "T113H 4X2 C54",
//       "T113H 4X2 D54",
//       "T114GA",
//       "T114GB",
//       "T124GA",
//       "T124GB",
//       "T124LA",
//       "R580 A6X2",
//       "164L V8 480",
//       "G310 A4X2",
//       "G310 B4X2",
//       "G360 A4X2",
//       "G360 A6X2",
//       "G360 A6X4",
//       "G360 B4X2",
//       "G360 B6X2",
//       "G410 A4X2",
//       "G410 A6X2",
//       "G410 A6X4",
//       "G410 B4X2",
//       "G410 B6X2",
//       "G460 A4X2",
//       "G460 A6X2",
//       "G460 B4X2",
//       "G460 B6X4",
//       "G460 B8X4",
//       "P124 LB 4X2 NB",
//       "P250 B4X2",
//       "P250 B6X2",
//       "P310 A6X2",
//       "P340 B6X2",
//       "P360 A4X2",
//       "P360 A6X2",
//       "P360 A6X2",
//       "P360 A6X4",
//       "P360 B4X2",
//       "P360 B4X4",
//       "P360 B6X2",
//       "P360 B6X4",
//       "P410 A6X4",
//       "P410 B6X4",
//       "P410 B8X4",
//       "R360 A4X2",
//       "R360 B4X2",
//       "R410 A4X2",
//       "R410 A6X2",
//       "R410 B4X2",
//       "R410 B6X2",
//       "R460 A4X2",
//       "R460 A6X2",
//       "R460 B4X2",
//       "R580 A4X2",
//       "R580 B4X2",
//       "R580 B6X2"
//     ],
//     "SCANIA/MARCOPOL": ["O"],
//     "SEAT": [
//       "ALHAMBRA 1.9 TDI",
//       "ALHAMBRA SE",
//       "ALHAMBRA SXE",
//       "ALHAMBRA TDI",
//       "ALHAMBRA TDI",
//       "ALTEA 2.0 TDI",
//       "ALTEA 4 FREETRACK 2.0 T FSI",
//       "CORDOBA",
//       "CORDOBA",
//       "CORDOBA 1.6",
//       "CORDOBA 1.6",
//       "CORDOBA 1.6 GLX",
//       "CORDOBA 1.6 MI 010",
//       "CORDOBA 1.6 MI 011",
//       "CORDOBA 1.6 MI 01A",
//       "CORDOBA 1.6 MI 01B",
//       "CORDOBA 1.6 MI 020",
//       "CORDOBA 1.6 MI 021",
//       "CORDOBA 1.6 MI 031",
//       "CORDOBA 1.6 MI 032",
//       "CORDOBA 1.6 MI 033",
//       "CORDOBA 1.6 SE",
//       "CORDOBA 1.6 SX",
//       "CORDOBA 1.6 SX",
//       "CORDOBA 1.6 SXE",
//       "CORDOBA 1.8 16V SX",
//       "CORDOBA 1.9 SDI",
//       "CORDOBA 1.9 SDI 010",
//       "CORDOBA 1.9 SDI 011",
//       "CORDOBA 1.9 SDI 01A",
//       "CORDOBA 1.9 SDI 020",
//       "CORDOBA 1.9 SDI 021",
//       "CORDOBA 1.9 SDI 031",
//       "CORDOBA 1.9 SDI 032",
//       "CORDOBA 1.9 SDI 033",
//       "CORDOBA 1.9 TDI",
//       "CORDOBA 1.9 TDI 021",
//       "CORDOBA 1.9 TDI 031",
//       "CORDOBA 1.9 TDI 032",
//       "CORDOBA 1.9 TDI SX",
//       "CORDOBA 1.9 TDI SXE",
//       "CORDOBA 1.9D SE",
//       "CORDOBA 1.9TDI 011",
//       "CORDOBA 2.0",
//       "CORDOBA 2.0 SX",
//       "CORDOBA VARIO 1.6",
//       "CORDOBA VARIO 1.6 SXE",
//       "CORDOBA VARIO 1.9 SDI",
//       "CORDOBA VARIO 1.9 TDI",
//       "CORDOBA VARIO GT 1.9 TDI",
//       "CORDOBA VARIO SE 1.9 SDI",
//       "FURGON",
//       "IBIZA",
//       "IBIZA",
//       "IBIZA 1.0",
//       "IBIZA 1.0",
//       "IBIZA 1.2 GL",
//       "IBIZA 1.4",
//       "IBIZA 1.4",
//       "IBIZA 1.4 BASIC",
//       "IBIZA 1.4 BASIC",
//       "IBIZA 1.4 I",
//       "IBIZA 1.4 SE",
//       "IBIZA 1.4 TDI",
//       "IBIZA 1.5 SXI",
//       "IBIZA 1.6",
//       "IBIZA 1.6",
//       "IBIZA 1.6 SE",
//       "IBIZA 1.6 SE",
//       "IBIZA 1.6 SXE",
//       "IBIZA 1.6 SXE",
//       "IBIZA 1.9 D CL",
//       "IBIZA 1.9 D CL",
//       "IBIZA 1.9 D CLX",
//       "IBIZA 1.9 SDI",
//       "IBIZA 1.9 SDI",
//       "IBIZA 1.9D",
//       "IBIZA 1.9D",
//       "IBIZA 1.9D SE",
//       "IBIZA 1.9D SE",
//       "IBIZA 1.9TD GLX",
//       "IBIZA 1.9TD SXE",
//       "IBIZA 1.9TD SXE",
//       "IBIZA 1.9TD SXE",
//       "IBIZA 1.9TDI",
//       "IBIZA 1.9TDI",
//       "IBIZA 1.9TDI SXE",
//       "IBIZA 2.0",
//       "IBIZA 2.0",
//       "IBIZA 2.0 GTI",
//       "IBIZA 2.0 GTI",
//       "IBIZA 2.0 GTI 16V",
//       "IBIZA 2.0 GTI 16V",
//       "IBIZA FR 1.9 TDI",
//       "IBIZA FR 1.9 TDI",
//       "IBIZA GTI",
//       "IBIZA PASSION 1.4I",
//       "INCA 1.6 991",
//       "INCA 1.6I",
//       "INCA 1.9 SD 991",
//       "INCA 1.9 SD 99A",
//       "INCA 1.9 SD 99B",
//       "INCA 1.9 SDI",
//       "INCA 1.9D",
//       "INCA 1.9D",
//       "LEON 1.6",
//       "LEON 1.8 20 V",
//       "LEON 1.8 T FSI",
//       "LEON 1.8 TSI",
//       "LEON 1.8 TSI AUTOMATIC",
//       "LEON 1.8T",
//       "LEON 1.9 TDI",
//       "LEON 2.0 TDI",
//       "LEON CUPRA 2.0 TSI",
//       "LEON FR 2.0 T FSI",
//       "LEON FR 2.0 TSI",
//       "TOLEDO 1.6",
//       "TOLEDO 1.6",
//       "TOLEDO 1.6 BASE",
//       "TOLEDO 1.6 MAGNUS",
//       "TOLEDO 1.8 20V",
//       "TOLEDO 1.9 D",
//       "TOLEDO 1.9 TDI MAGNUS",
//       "TOLEDO 1.9 TDI MAGNUS",
//       "TOLEDO 1.9 TDI SIGNUM",
//       "TOLEDO 1.9 TDI SXE",
//       "TOLEDO 1.9D MAGNUS",
//       "TOLEDO 1.9TDI",
//       "TOLEDO 1.9TDI",
//       "TOLEDO 2.0 MAGNUS",
//       "TOLEDO 2.0 ST",
//       "TOLEDO 2.0 TDI",
//       "TOLEDO 2.3 V5",
//       "TOLEDO 2.4 V5",
//       "TOLEDO SPORT 2.0",
//       "ALTEA 1.6",
//       "ALTEA 1.9 TDI",
//       "IBIZA 1.9 FR TDI"
//     ],
//     "SERO": ["ELECTRIC CARGO ALTO", "ELECTRIC CARGO BAJO", "ELECTRIC SEDAN"],
//     "SHERCO": ["250 SEF-R", "300 SEF-R", "300 SE-R"],
//     "SHINERAY": ["G01", "T30", "T30", "T32", "X30", "X30", "X7"],
//     "SIAM": ["QU 110", "TRENDER 150"],
//     "SKODA": [
//       "FELICIA COMBI GLX",
//       "FELICIA COMBI GLXI",
//       "FELICIA GLX",
//       "FELICIA GLXI"
//     ],
//     "SMART": [
//       "FORFOUR 1.1 PASSION",
//       "FORFOUR CITY",
//       "FORFOUR PASSION",
//       "FORFOUR PLAY",
//       "FORTWO",
//       "FORTWO",
//       "FORTWO 700 PASSION",
//       "FORTWO PASSION",
//       "FORTWO PLAY",
//       "PASSION",
//       "PASSION",
//       "SMART & PASSION",
//       "SMART & PASSION",
//       "SMART FORFOUR PASSION",
//       "FORTWO CITY"
//     ],
//     "SOUEAST": ["DX3"],
//     "SPACE": ["GHT1047", "GHT1055"],
//     "SSANGYONG": [
//       "ACTYON",
//       "ACTYON",
//       "ACTYON",
//       "CHAIRMAN CM600L",
//       "CHAIRMAN CM600S",
//       "ISTANA",
//       "ISTANA",
//       "ISTANA 6 VAN",
//       "ISTANA ECONOMY",
//       "ISTANA ECONOMY",
//       "ISTANA ELITE",
//       "ISTANA ELITE",
//       "ISTANA ELITE OMNI",
//       "ISTANA PRIME",
//       "ISTANA PRIME OMNI",
//       "ISTANA STD",
//       "ISTANA STD",
//       "ISTANA SUPER PRIME",
//       "ISTANA SUPER PRIME",
//       "ISTANA SUPER PRIME OMNI",
//       "KORANDO",
//       "KORANDO 601",
//       "KORANDO 601 TD",
//       "KORANDO 602 EL",
//       "KORANDO 602 TD",
//       "KYRON 2.0 D",
//       "KYRON 2.0 D",
//       "KYRON 2.0 D",
//       "MUSSO 230 SR",
//       "MUSSO 2300 DOHC",
//       "MUSSO 230SL",
//       "MUSSO 290 SL",
//       "MUSSO 601",
//       "MUSSO 601",
//       "MUSSO 601 TD",
//       "MUSSO 601 TD",
//       "MUSSO 602 DIESEL",
//       "MUSSO 602 EL",
//       "MUSSO 602 EL",
//       "MUSSO 602 EL",
//       "MUSSO 602 TD",
//       "MUSSO 602 TD",
//       "MUSSO 662 LA",
//       "MUSSO IL6 3200",
//       "MUSSO IL6 3200",
//       "MUSSO OM 602 EL",
//       "MUSSO OM 662 EL",
//       "MUSSO PRESTIGE",
//       "MUSSO TD",
//       "OM662 TURBO",
//       "REXTON RX 320",
//       "RODIUS"
//     ],
//     "SUBARU": [
//       "FORESTER",
//       "FORESTER 2.0 X",
//       "FORESTER 2.0 XS",
//       "FORESTER 2.0 XT",
//       "FORESTER 2.0I",
//       "FORESTER 2.0I AWD CVT",
//       "FORESTER 2.0I AWD CVT LTD",
//       "FORESTER 2.0I AWD CVT X",
//       "FORESTER 2.0I AWD CVT XS",
//       "FORESTER 2.0I CVT SI DRIVE",
//       "FORESTER 2.0l DYNAMIC ES",
//       "FORESTER 2.5 AWD",
//       "FORESTER 2.5 XT",
//       "FORESTER 2.5I",
//       "FORESTER 2.5I AWD CVT",
//       "FORESTER 2.5I AWD CVT XS",
//       "FORESTER 2.5I DYNAMIC ES",
//       "FORESTER 2.5I LIMITED",
//       "FORESTER AWD",
//       "FORESTER AWD 2.0X",
//       "FORESTER AWD 2.0XS",
//       "FORESTER AWD 2.5XT",
//       "FORESTER S",
//       "FORESTER S TURBO AWD",
//       "IMPREZA",
//       "IMPREZA 1.5 R",
//       "IMPREZA 1.6 GL",
//       "IMPREZA 1.6 GL 4DOOR",
//       "IMPREZA 1.6 GT 4WD",
//       "IMPREZA 1.8 4D GL",
//       "IMPREZA 1.8 4D GL 5S 4WD",
//       "IMPREZA 1.8 4D GL/GL 4WD",
//       "IMPREZA 1.8 5D GL",
//       "IMPREZA 1.8 GL 4WD",
//       "IMPREZA 2.0",
//       "IMPREZA 2.0 AWD",
//       "IMPREZA 2.0 GT AWD",
//       "IMPREZA 2.0 RX",
//       "IMPREZA 2.0 TURBO",
//       "IMPREZA 2.0 TURBO 4WD",
//       "IMPREZA 2.0 WRX-RA",
//       "IMPREZA 2.0I",
//       "IMPREZA 2.0I 4WD RX",
//       "IMPREZA 2.0I AWD CVT 5D",
//       "IMPREZA 2.0I AWD CVT",
//       "IMPREZA 2.0I AWD CVT",
//       "IMPREZA 2.0I AWD CVT",
//       "IMPREZA 2.0R",
//       "IMPREZA 2.0R",
//       "IMPREZA 2.0R SPORT",
//       "IMPREZA 2.5 WRX STI",
//       "IMPREZA GL",
//       "IMPREZA GL",
//       "IMPREZA GL 2.0",
//       "IMPREZA GL 2.0",
//       "IMPREZA GL 4WD",
//       "IMPREZA GL 4WD",
//       "IMPREZA GT 4WD",
//       "IMPREZA GT 4WD",
//       "IMPREZA GX",
//       "IMPREZA GX",
//       "IMPREZA GX",
//       "IMPREZA L 4WD",
//       "IMPREZA L AWD",
//       "IMPREZA LX",
//       "IMPREZA LX AWD",
//       "IMPREZA OUTBACK (AWD) 2.2",
//       "IMPREZA WRX",
//       "IMPREZA WRX",
//       "IMPREZA WRX 2.5",
//       "IMPREZA WRX 2.5",
//       "IMPREZA WRX STI",
//       "IMPREZA WRX STI 2.5",
//       "IMPREZA XV 2.0R",
//       "LEGACY",
//       "LEGACY",
//       "LEGACY",
//       "LEGACY 2.0 GL",
//       "LEGACY 2.0 GL 4WD",
//       "LEGACY 2.0 GL 4WD",
//       "LEGACY 2.0 GL AWD",
//       "LEGACY 2.0 GL AWD",
//       "LEGACY 2.0I",
//       "LEGACY 2.0I",
//       "LEGACY 2.0R",
//       "LEGACY 2.0R",
//       "LEGACY 2.2 GX AT 4WD",
//       "LEGACY 2.2 INY",
//       "LEGACY 2.5 GT",
//       "LEGACY 2.5I",
//       "LEGACY 2.5I",
//       "LEGACY 3.0R",
//       "LEGACY 3.0R",
//       "LEGACY 4D 3.0 DOHC",
//       "LEGACY 4WD",
//       "LEGACY 4WD",
//       "LEGACY GL",
//       "LEGACY GL",
//       "LEGACY GX",
//       "LEGACY GX",
//       "LEGACY GX 2.5",
//       "LEGACY GX 2.5",
//       "LEGACY GX AWD",
//       "LEGACY L",
//       "LEGACY L 4WD",
//       "LEGACY L 4WD",
//       "LEGACY L AWD",
//       "LEGACY LS",
//       "LEGACY LX AWD",
//       "LEGACY LX AWD 2.0",
//       "LEGACY OTROS",
//       "LEGACY OUTBACK 4WD",
//       "LEGACY OUTBACK 4WD",
//       "LEGACY OUTBACK AWD",
//       "LEGACY OUTBACK LIMITED",
//       "LEGACY STATION WAGON",
//       "LEGACY/GX/GL/LS/LX/L",
//       "OUTBACK",
//       "OUTBACK",
//       "OUTBACK 2.5",
//       "OUTBACK 2.5 AWD",
//       "OUTBACK 2.5 XT",
//       "OUTBACK 2.5I",
//       "OUTBACK 2.5I AWD CVT",
//       "OUTBACK 3.0 AWD",
//       "OUTBACK 3.0R",
//       "OUTBACK 3.6R",
//       "OUTBACK 3.6R AWD CVT",
//       "OUTBACK LIMITED AWD",
//       "OUTBACK SW",
//       "OUTBACK XS 2.5 AUT",
//       "OUTBACK XS 25I",
//       "TRIBECA",
//       "WRX",
//       "WRX 2.0T AWD 6MT",
//       "WRX 2.0T AWD CVT LIMITED",
//       "WRX 2.0T AWD CVT LIMITED",
//       "WRX 2.5T AWD 6MT STI",
//       "WRX STI",
//       "XV",
//       "XV 2.0I AWD CVT",
//       "XV 2.0I AWD CVT DYNAMIC",
//       "XV 2.0I AWD CVT DYNAMIC",
//       "XV 2.0I AWD CVT LTD",
//       "XV 2.0I AWD CVT LTD"
//     ],
//     "SUMO": ["CG125", "KCS2002F", "KCS2002G", "SB110", "SG125 SUPER", "SL200"],
//     "SUNEQUIP": ["RANCHER 150"],
//     "SUNRA": [
//       "GRACE",
//       "GRACE",
//       "HAWK",
//       "HAWK",
//       "LEO",
//       "LMJR",
//       "LMJR",
//       "LMJR",
//       "MIKU MAX",
//       "MIKU MAX"
//     ],
//     "SUZUKI": [
//       "ALTO",
//       "ALTO",
//       "AN 125",
//       "AX 100",
//       "AX 100",
//       "AX 100 MARSHAL",
//       "AX 100 R",
//       "AX 100D",
//       "AX 100D",
//       "AX100",
//       "AX-100",
//       "AX-100",
//       "AX100A",
//       "AX100V",
//       "BALENO",
//       "BALENO",
//       "BALENO",
//       "BALENO 1.3 GA",
//       "BALENO 1.3 GA",
//       "BALENO 1.6 GLX",
//       "BALENO 1.6 GLX",
//       "BALENO 1.6 GS",
//       "BALENO GLX",
//       "BALENO GLX 1.4L A/T",
//       "BALENO GLX 1.4L M/T",
//       "BURGMAN 200",
//       "CARRY 1.3",
//       "DL1000 V-STROM",
//       "DL1000XA",
//       "DL1050RC",
//       "DL650",
//       "DL650 V-STROM",
//       "DL650A",
//       "DL650XA",
//       "DR-Z400S DUAL SPORT",
//       "EN125 HU",
//       "EN125-2A",
//       "EN125-2A",
//       "EN125-HU",
//       "FUN 1.0",
//       "FUN 1.4",
//       "FUN 1.4",
//       "G. VITARA 3P 1.6N 16V M/T",
//       "G. VITARA 3P 1.6N 16V MT",
//       "G. VITARA 5 PTAS 2.0 A/T",
//       "G. VITARA 5 PTAS 2.0 A/T",
//       "G. VITARA 5PTAS 2.0 M/T",
//       "G. VITARA 5PTAS 2.0 M/T",
//       "G. VITARA 5PTAS 2.0 TDI",
//       "G. VITARA 5PTAS 2.0 TDI",
//       "GN 125",
//       "GN 125 H",
//       "GN125E",
//       "GN125EN",
//       "GN125ER",
//       "GN125ES",
//       "GN125ET",
//       "GN125-F",
//       "GN125-F",
//       "GN125H",
//       "GRAND NOMADE",
//       "GRAND NOMADE 2.0",
//       "GRAND NOMADE 2.0",
//       "GRAND NOMADE 2.7",
//       "GRAND NOMADE GLX LTD",
//       "GRAND NOMADE GLX 2.0",
//       "GRAND NOMADE GLX 2.4",
//       "GRAND NOMADE LTD 4X4 2.4",
//       "GRAND NOMADE SPORT LE",
//       "GRAND NOMADE XL7",
//       "GRAND VITARA 1.6 (3",
//       "GRAND VITARA 1.9",
//       "GRAND VITARA 2.0 (3",
//       "GRAND VITARA 2.0 (5",
//       "GRAND VITARA 2.0 (5",
//       "GRAND VITARA 2.0 M",
//       "GRAND VITARA 2.0L",
//       "GRAND VITARA 2.0L",
//       "GRAND VITARA 2.5 V6 (5",
//       "GRAND VITARA 2.5 V6 (5",
//       "GRAND VITARA GLX 2.4 A/T",
//       "GRAND VITARA JIII 2.0L",
//       "GRAND VITARA JIII 2.4L",
//       "GRAND VITARA JLX 2.4L 4X2",
//       "GRAND VITARA JLX-L 2.4 4X4",
//       "GRAND VITARA JLX-L 2.4 4X4",
//       "GRAND VITARA JX 2.4L 4X4 3P",
//       "GRAND VITARA TD (5 PTAS.)",
//       "GRAND VITARA TDI (5",
//       "GRAND VITARA V6",
//       "GRAND VITARA V6",
//       "GRAND VITARA XL-7",
//       "GS 125",
//       "GSF650 BANDIT",
//       "GSF650SA",
//       "GSR 750",
//       "GSR600",
//       "GSX 1300 RK5",
//       "GSX 1300R HAYABUSA",
//       "GSX 1300RA",
//       "GSX 600 FL",
//       "GSX 600 FM",
//       "GSX 600 FR",
//       "GSX 750",
//       "GSX1100F",
//       "GSX150",
//       "GSX600FS",
//       "GSX-R 1100",
//       "GSXR 750",
//       "GSX-R1000",
//       "GSX-R1100 W",
//       "GSX-R1100WS",
//       "GSXR600",
//       "GSX-R600",
//       "GSX-R600V",
//       "GSX-R600WN",
//       "GSX-S750",
//       "GSX-S750A",
//       "GW250 INAZUMA",
//       "JIMNY",
//       "JIMNY 1.3 JLX",
//       "JIMNY 1.3 JX",
//       "JIMNY 1.5 GLX AT",
//       "JIMNY 1.5 GLX MT",
//       "KINGQUAD 400 4X4 ASI",
//       "KINGQUAD 400 FS 4X4",
//       "KINGQUAD 750 AXI 4X4",
//       "LT Z400Z",
//       "LT-A400FK7",
//       "LT-A400K7",
//       "LT-A750XZK8",
//       "LT-F 250 R",
//       "LT-F250",
//       "LT-F250R",
//       "LT-F400FK8",
//       "LT-R450",
//       "LT-Z400",
//       "QUADSPORT Z400",
//       "RM Z450L1",
//       "RMZ250",
//       "RM-Z250",
//       "RM-Z450",
//       "SAMURAI",
//       "SAMURAI",
//       "SAMURAI",
//       "SAMURAI",
//       "SAMURAI 413",
//       "SAMURAI JL",
//       "SAMURAI JX",
//       "SAMURAI/413",
//       "SANTANA",
//       "SANTANA 413 SAMURAI",
//       "SANTANA SJ-410",
//       "SJ 410 JL",
//       "SJ110",
//       "SJ110D",
//       "SV650A",
//       "SV650XA",
//       "SWIFT 1.2L CVT GLX",
//       "SWIFT 1.5",
//       "SWIFT 1.5L",
//       "SWIFT DDIS",
//       "SWIFT GLX",
//       "SWIFT GLX 1.4 M/T",
//       "SWIFT VVT AT 1.5",
//       "SX4",
//       "SX4",
//       "VITARA",
//       "VITARA",
//       "VITARA 2.0 TD INTERCOOLER",
//       "VITARA GL 1.6 L 4X2 5P A/T",
//       "VITARA GL 1.6L 4X2 5P M/T",
//       "VITARA GL+ 1.6L 4X4 5P A/T",
//       "VITARA GLX SR 1.6L 4X4 5P",
//       "VITARA JLX",
//       "VITARA JLX",
//       "VITARA JLX (5 PUERTAS)",
//       "VITARA JLX (TECHO DE LONA)",
//       "VITARA JLX (TECHO DE",
//       "VITARA JLX 1.9D",
//       "VITARA JLX 1.9TD",
//       "VITARA JLX V6",
//       "VITARA JX",
//       "VITARA JX 1.6",
//       "VITARA LONA SE416",
//       "VITARA SE 416 IVPZ",
//       "VITARA V6",
//       "VITARA/JLX",
//       "V-STROM 1000 XT ABS",
//       "V-STROM 250 ABS",
//       "V-STROM 650 XT ABS",
//       "VZ800",
//       "VZ800 MARAUDER",
//       "VZ800W",
//       "VZ800Y MARAUDER",
//       "WAGON R+ 1.0 GA",
//       "WAGON R+GL",
//       "XL-7",
//       "CELERIO"
//     ],
//     "SUZUKI - SANTANA": ["SAMURAI 413 J"],
//     "SYM": [
//       "CITYCOM",
//       "CROX 125",
//       "FIDDLE II 150 S",
//       "JOYRIDE",
//       "MAXSYM 600",
//       "MAXSYM 600I",
//       "SYMPHONY",
//       "T1 180"
//     ],
//     "TATA": [
//       "207",
//       "407 4X4",
//       "608",
//       "608/37",
//       "609/38",
//       "609/38",
//       "SAFARI",
//       "SAFARI EX 4X4",
//       "SFC 709",
//       "SUMO",
//       "SUMO TURBO",
//       "SUMO-EX 4X4",
//       "TATAMOBILE 207",
//       "TELCO SPORT",
//       "TELCOLINE",
//       "TELCOLINE (DOBLE CABINA)",
//       "TELCOLINE TDI (CABINA",
//       "TELCOLINE TDI (CABINA",
//       "TELCOLINE TDI 4X4 (CABINA",
//       "TELCOLINE TDI 4X4-CABINA"
//     ],
//     "TIBO": [
//       "AERO 150",
//       "CAT 150",
//       "CHARGER 200",
//       "CHARGER 250",
//       "COASTER 250",
//       "HUNTER 250",
//       "HUNTER 250",
//       "JET 110",
//       "NITRO 200",
//       "RAIDER 250",
//       "RALLY 70",
//       "RALLY 90",
//       "RIDER 250",
//       "RS 150",
//       "SAMURAI 250",
//       "SHARK 200",
//       "SHARK 250",
//       "STORM 200",
//       "STORM 200",
//       "TAZZ 110",
//       "ZONDA 200",
//       "SHARK 350"
//     ],
//     "TOYOTA": [
//       "4 RUNNER",
//       "4 RUNNER",
//       "4 RUNNER 2.8 D",
//       "4 RUNNER 2.8 D",
//       "4 RUNNER 2.8 D",
//       "4 RUNNER 3.4 V6 LIMITED",
//       "4 RUNNER 4X4 LN130",
//       "4 RUNNER EFI SR5",
//       "4 RUNNER EFI SR5",
//       "4 RUNNER EFI SR5",
//       "4 RUNNER LIMITED",
//       "4 RUNNER SR5",
//       "4 RUNNER SR5",
//       "4 RUNNER SR5 LIMITED",
//       "4 RUNNER SR5 LIMITED",
//       "4 RUNNER SR5 V6",
//       "4 RUNNER SR5 V6",
//       "4 RUNNER TD 3.0",
//       "4 RUNNER TD 3.0/SR5 V6",
//       "4 RUNNER TURBO DIESEL",
//       "4 RUNNER V6",
//       "4 RUNNER/V6/2.8 D",
//       "4RN SRV5",
//       "4WD CABINA SIMPLE",
//       "AURIS",
//       "AVALON XLS",
//       "AVENSIS 1.6 TERRA",
//       "AVENSIS 1.8",
//       "AVENSIS 2.0",
//       "AVENSIS 2.0 A/T",
//       "AVENSIS 2.0 M/T",
//       "AVENSIS 2.2",
//       "AVENSIS WT-I",
//       "CAMRY",
//       "CAMRY",
//       "CAMRY 2.2",
//       "CAMRY 2.4",
//       "CAMRY CE",
//       "CAMRY DX",
//       "CAMRY DX",
//       "CAMRY GL",
//       "CAMRY GL",
//       "CAMRY HV 2.5 ECVT",
//       "CAMRY L4 2.5",
//       "CAMRY LE",
//       "CAMRY LE",
//       "CAMRY LE",
//       "CAMRY SE",
//       "CAMRY V6",
//       "CAMRY V6 3.5",
//       "CAMRY V6 XLE",
//       "CAMRY XLE",
//       "CAMRY XLE SE",
//       "CAMRY/XLE/LE/DX",
//       "C-HR HEV 1.8 ECVT",
//       "C-HR HV 1.8 ECVT",
//       "COASTER",
//       "COASTER 20 PASAJEROS",
//       "COASTER",
//       "COASTER 30 PASAJEROS",
//       "COROLLA",
//       "COROLLA",
//       "COROLLA 1.6",
//       "COROLLA 1.6 GLI",
//       "COROLLA 1.6 SI",
//       "COROLLA 1.6 XLI",
//       "COROLLA 1.6 XLI",
//       "COROLLA 1.8 SE-G",
//       "COROLLA 1.8 XE I",
//       "COROLLA 1.8D DX",
//       "COROLLA 1600",
//       "COROLLA 1600 LIFT BACK",
//       "COROLLA 1600/GL/GLI",
//       "COROLLA 2.0 DXL",
//       "COROLLA 2.0 DXL",
//       "COROLLA 2.0 SEG CVT",
//       "COROLLA 2.0 SEG CVT MY21",
//       "COROLLA 2.0 SEG CVT PIO",
//       "COROLLA 2.0 XEI CVT",
//       "COROLLA 2.0 XEI CVT MY21",
//       "COROLLA 2.0 XEI CVT PIO",
//       "COROLLA 2.0 XEI MT",
//       "COROLLA 2.0 XEI MT MY21",
//       "COROLLA 2.0 XLI CVT",
//       "COROLLA 2.0 XLI CVT MY21",
//       "COROLLA 2.0 XLI CVT PIO",
//       "COROLLA 2.0 XLI MT",
//       "COROLLA 2.0 XLI MT MY21",
//       "COROLLA 2.0 XLI MT PIO",
//       "COROLLA 2.0D",
//       "COROLLA CE",
//       "COROLLA CROSS GR-SPORT",
//       "COROLLA CROSS GR-SPORT",
//       "COROLLA CROSS SEG 2.0 CVT",
//       "COROLLA CROSS SEG 2.0 CVT",
//       "COROLLA CROSS SEG HEV 1.8",
//       "COROLLA CROSS SEG HEV 1.8",
//       "COROLLA CROSS SEG HV 1.8",
//       "COROLLA CROSS SEG LE HV",
//       "COROLLA CROSS XEI 2.0 CVT",
//       "COROLLA CROSS XEI 2.0 CVT",
//       "COROLLA CROSS XEI HEV 1.8",
//       "COROLLA CROSS XEI HEV 1.8",
//       "COROLLA CROSS XEI HV 1.8",
//       "COROLLA CROSS XLI 2.0 CVT",
//       "COROLLA CROSS XLI 2.0 CVT",
//       "COROLLA DIESEL",
//       "COROLLA DX",
//       "COROLLA DX",
//       "COROLLA DX AUTOMATICO",
//       "COROLLA FIELDER",
//       "COROLLA FIELDER SE-G A/T",
//       "COROLLA FIELDER XEI",
//       "COROLLA FX1.6",
//       "COROLLA GL 1.6",
//       "COROLLA GL 1.6",
//       "COROLLA GLI 1.6",
//       "COROLLA GR-S 2.0 CVT",
//       "COROLLA GR-SPORT 2.0 CVT",
//       "COROLLA GR-SPORT 2.0 CVT",
//       "COROLLA HEV 1.8 SEG ECVT",
//       "COROLLA HV 1.8 SEG ECVT",
//       "COROLLA HV 1.8 SEG ECVT",
//       "COROLLA HV 1.8 XEI ECVT",
//       "COROLLA HV 1.8 XEI ECVT",
//       "COROLLA LE",
//       "COROLLA OTROS",
//       "COROLLA S",
//       "COROLLA S 1.8 M/T",
//       "COROLLA SE-G",
//       "COROLLA SE-G 1.8 6M/T",
//       "COROLLA SE-G 1.8 A/T",
//       "COROLLA SE-G 1.8 CVT",
//       "COROLLA SE-G 1.8 M/T",
//       "COROLLA SEG HEV 1.8 ECVT",
//       "COROLLA TERCEL",
//       "COROLLA VE",
//       "COROLLA VERSO",
//       "COROLLA XEI",
//       "COROLLA XEI 1.8 6M/T",
//       "COROLLA XEI 1.8 A/T",
//       "COROLLA XEI 1.8 CVT",
//       "COROLLA XEI 1.8 M/T",
//       "COROLLA XEI 2.0TD",
//       "COROLLA XEI HEV 1.8 ECVT",
//       "COROLLA XEI PACK 1.8 6M/T",
//       "COROLLA XEI PACK 1.8 A/T",
//       "COROLLA XEI PACK 1.8 CVT",
//       "COROLLA XEI PACK 1.8 M/T",
//       "COROLLA XL",
//       "COROLLA XL",
//       "COROLLA XLI",
//       "COROLLA XLI 1.8 6M/T",
//       "COROLLA XLI 1.8 CVT",
//       "COROLLA XLI 1.8 M/T",
//       "COROLLA XRS",
//       "CORONA",
//       "CORONA",
//       "CORONA 2.0",
//       "CORONA 2.0 TD",
//       "CORONA 2000",
//       "CORONA 2000 GL",
//       "CORONA 2000 LIF",
//       "CORONA 2000 ST1911",
//       "CORONA 2000LIF/GL",
//       "CORONA 2000ST 19 IL-",
//       "CORONA A/T GLI3",
//       "CORONA AVENSIS 2.0",
//       "CORONA D 4D",
//       "CORONA D-TURBO GL",
//       "CORONA GL 1.6",
//       "CORONA GLD 2.0",
//       "CORONA GLI 2.0",
//       "CORONA M/T 2000 GLI",
//       "CORONA M/T 2000 GLI/GLD",
//       "CORONA OTROS",
//       "CORONA XL",
//       "ETIOS AIBO 1.5 6M/T",
//       "ETIOS CROSS 1.5 4A/T",
//       "ETIOS CROSS 1.5 6M/T",
//       "ETIOS CROSS 1.5 M/T",
//       "ETIOS PLATINUM 1.5 4A/T",
//       "ETIOS PLATINUM 1.5 4A/T",
//       "ETIOS PLATINUM 1.5 6M/T",
//       "ETIOS PLATINUM 1.5 6M/T",
//       "ETIOS PLATINUM 1.5 M/T",
//       "ETIOS PLATINUM 1.5 M/T",
//       "ETIOS PLATINUM 1.5 M/T 5P",
//       "ETIOS PLATINUM 1.5 M/T 5P",
//       "ETIOS X 1.5 6M/T",
//       "ETIOS X 1.5 6M/T",
//       "ETIOS X 1.5 M/T",
//       "ETIOS X 1.5 M/T",
//       "ETIOS XLS 1.5 4A/T",
//       "ETIOS XLS 1.5 4A/T",
//       "ETIOS XLS 1.5 6M/T",
//       "ETIOS XLS 1.5 6M/T",
//       "ETIOS XLS 1.5 M/T",
//       "ETIOS XLS 1.5 M/T",
//       "ETIOS XLS PACK 1.5 4A/T",
//       "ETIOS XLS PACK 1.5 4A/T",
//       "ETIOS XLS PACK 1.5 6M/T",
//       "ETIOS XLS PACK 1.5 6M/T",
//       "ETIOS XS 1.5 6M/T",
//       "ETIOS XS 1.5 6M/T",
//       "ETIOS XS 1.5 M/T",
//       "ETIOS XS 1.5 M/T",
//       "EXTRACAB",
//       "FJ CRUISER",
//       "FORTUNER",
//       "GR 86 MT",
//       "GR YARIS",
//       "HIACE",
//       "HIACE",
//       "HIACE",
//       "HIACE 12 PASAJEROS",
//       "HI-ACE 12 PASAJEROS/15",
//       "HIACE 15 PASAJEROS",
//       "HIACE COMMUTER",
//       "HIACE COMMUTER 2.8 TDI",
//       "HI-ACE DELIVE",
//       "HIACE GRAND CABIN G 4WD",
//       "HIACE L1H1 2.8 TDI 6AT 3A",
//       "HIACE L2H2 2.8 TDI 6AT 3A",
//       "HI-ACE OTROS",
//       "HIACE WAGON 2.8 TDI 6AT",
//       "HIGHLANDER",
//       "HIGHLANDER",
//       "HILUX",
//       "HILUX (DOBLE CABINA)",
//       "HILUX 2.4",
//       "HILUX 2.4 (CABINA DOBLE)",
//       "HILUX 2.4 CABINA Y MEDIA",
//       "HILUX 2.4 D",
//       "HILUX 2.4 D 4WD",
//       "HILUX 2.4 D CABINA DOBLE",
//       "HILUX 2.4 D CABINA SIMPLE",
//       "HILUX 2.4 TD 4WD (CABINA",
//       "HILUX 2.4/2.8/D/4WD",
//       "HILUX 2.8",
//       "HILUX 2.8 D",
//       "HILUX 2.8 D 4WD",
//       "HILUX 2.8 D 4WD 4 RUNNER",
//       "HILUX 2.8 D 4X4 (CABINA",
//       "HILUX 2.8 D CABINA DOBLE",
//       "HILUX 2.8 D CABINA SIMPLE",
//       "HILUX 2700 EFI",
//       "HILUX 4WD",
//       "HILUX 4WD DOBLE CABINA",
//       "HILUX 4X2 (CABINA DOBLE)",
//       "HILUX 4X2 (CABINA SIMPLE)",
//       "HILUX 4X2 C/D DX PACK",
//       "HILUX 4X2 C/D SR 3.0 TDI - B3",
//       "HILUX 4X2 C/D SRV 2.7 VVTI -",
//       "HILUX 4X2 C/D SRV 2.7 VVTI",
//       "HILUX 4X2 C/D SRV 3.0 TDI -",
//       "HILUX 4X2 C/D SRV 3.0 TDI",
//       "HILUX 4X2 C/S DX 2.4 TDI 6",
//       "HILUX 4X2 C/S DX PACK 2.5",
//       "HILUX 4X2 CABINA DOBLE",
//       "HILUX 4X2 CABINA DOBLE DX",
//       "HILUX 4X2 CABINA DOBLE DX",
//       "HILUX 4X2 CABINA DOBLE DX",
//       "HILUX 4X2 CABINA DOBLE DX",
//       "HILUX 4x2 CABINA DOBLE DX",
//       "HILUX 4X2 CABINA DOBLE DX",
//       "HILUX 4X2 CABINA DOBLE DX",
//       "HILUX 4X2 CABINA DOBLE SR",
//       "HILUX 4X2 CABINA DOBLE SR",
//       "HILUX 4X2 CABINA DOBLE",
//       "HILUX 4X2 CABINA DOBLE",
//       "HILUX 4X2 CABINA DOBLE",
//       "HILUX 4X2 CABINA DOBLE",
//       "HILUX 4X2 CABINA DOBLE",
//       "HILUX 4X2 CABINA DOBLE",
//       "HILUX 4X2 CABINA DOBLE",
//       "HILUX 4X2 CABINA DOBLE",
//       "HILUX 4X2 CABINA SIMPLE DX",
//       "HILUX 4X2 CABINA SIMPLE DX",
//       "HILUX 4X2 CABINA SIMPLE DX",
//       "HILUX 4X2 CABINA SIMPLE DX",
//       "HILUX 4X2 CABINA SIMPLE DX",
//       "HILUX 4X2 CABINA SIMPLE",
//       "HILUX 4x2 Cabina Simple STD",
//       "HILUX 4X2 CABINA SIMPLE",
//       "HILUX 4X2 CC DX 2.4 TDI 6 MT",
//       "HILUX 4X2 COVER DX PACK",
//       "HILUX 4X2 COVER DX PACK",
//       "HILUX 4X2 COVER DX PACK",
//       "HILUX 4X2 CS DX 2.4 TDI 6 MT",
//       "HILUX 4X2 D/C DX 2.4 TDI 6",
//       "HILUX 4X2 D/C SR 2.4 TDI 6",
//       "HILUX 4X2 D/C SR 2.8 TDI 6",
//       "HILUX 4X2 D/C SRV 2.8 TDI 6",
//       "HILUX 4X2 D/C SRV 2.8 TDI 6",
//       "HILUX 4X2 D/C SRV PACK 2.8",
//       "HILUX 4X2 D/C SRX 2.8 TDI 6",
//       "HILUX 4X2 D/C SRX 2.8 TDI 6",
//       "HILUX 4X2 D/C STD 2.4 TDI 6",
//       "HILUX 4X2 DC DX 2.4 TDI 6",
//       "HILUX 4X2 DC SR 2.4 TDI 6 AT",
//       "HILUX 4X2 DC SR 2.4 TDI 6 MT",
//       "HILUX 4X2 DC SRV 2.8 TDI 6",
//       "HILUX 4X2 DC SRV 2.8 TDI 6",
//       "HILUX 4X2 DC SRX 2.8 TDI 6",
//       "HILUX 4X2 DC SRX 2.8 TDI 6",
//       "HILUX 4X2, CABINA DOBLE",
//       "HILUX 4X2, CABINA SIMPLE",
//       "HILUX 4X2/DC",
//       "HILUX 4X4",
//       "HILUX 4X4",
//       "HILUX 4X4 (CABINA DOBLE)",
//       "HILUX 4X4 2.8 D (CABINA Y",
//       "HILUX 4X4 C/D DX PACK",
//       "HILUX 4X4 C/D SR 3.0 TDI - C3",
//       "HILUX 4X4 C/D SRV 2.7 VVTI -",
//       "HILUX 4X4 C/D SRV 2.7 VVTI",
//       "HILUX 4X4 C/D SRV 3.0 TDI -",
//       "HILUX 4X4 C/D SRV 3.0 TDI",
//       "HILUX 4X4 C/D SRV 3.0 TDI",
//       "HILUX 4X4 C/S DX 2.4 TDI 6",
//       "HILUX 4X4 C/S DX PACK 2.5",
//       "HILUX 4X4 CABINA DOBLE",
//       "HILUX 4X4 CABINA DOBLE DX",
//       "HILUX 4X4 CABINA DOBLE DX",
//       "HILUX 4X4 CABINA DOBLE DX",
//       "HILUX 4X4 CABINA DOBLE DX",
//       "HILUX 4X4 CABINA DOBLE DX",
//       "HILUX 4X4 CABINA DOBLE SR",
//       "HILUX 4X4 CABINA DOBLE SR",
//       "HILUX 4X4 CABINA DOBLE SR",
//       "HILUX 4X4 CABINA DOBLE SR",
//       "HILUX 4X4 CABINA DOBLE",
//       "HILUX 4X4 CABINA DOBLE",
//       "HILUX 4X4 CABINA DOBLE",
//       "HILUX 4X4 CABINA DOBLE",
//       "HILUX 4X4 CABINA DOBLE",
//       "HILUX 4X4 CABINA DOBLE",
//       "HILUX 4X4 CABINA DOBLE",
//       "HILUX 4X4 CABINA DOBLE",
//       "HILUX 4X4 CABINA SIMPLE DX",
//       "HILUX 4X4 CABINA SIMPLE DX",
//       "HILUX 4X4 CABINA SIMPLE DX",
//       "HILUX 4X4 CABINA SIMPLE DX",
//       "HILUX 4X4 CC DX 2.4 TDI 6 MT",
//       "HILUX 4X4 COVER DX PACK",
//       "HILUX 4X4 COVER DX PACK",
//       "HILUX 4X4 COVER DX PACK",
//       "HILUX 4X4 CS DX 2.4 TDI 6 MT",
//       "HILUX 4X4 D/C DX 2.4 TDI 6",
//       "HILUX 4X4 D/C GR-S 2.8 TDI 6",
//       "HILUX 4X4 D/C GR-S 2.8 TDI 6",
//       "HILUX 4X4 D/C GR-S II 2.8 TDI",
//       "HILUX 4X4 D/C GR-S II 2.8 TDI",
//       "HILUX 4X4 D/C GR-S V6 VVTI",
//       "HILUX 4X4 D/C LIMITED 2.8",
//       "HILUX 4X4 D/C LIMITED 2.8",
//       "HILUX 4X4 D/C SR 2.8 TDI 6",
//       "HILUX 4X4 D/C SRV 2.8 TDI 6",
//       "HILUX 4X4 D/C SRV 2.8 TDI 6",
//       "HILUX 4X4 D/C SRX 2.8 TDI 6",
//       "HILUX 4X4 D/C SRX 2.8 TDI 6",
//       "HILUX 4X4 DC CONQUEST 2.8",
//       "HILUX 4X4 DC DX 2.4 TDI 6",
//       "HILUX 4X4 DC GR-S III 2.8 TDI",
//       "HILUX 4X4 DC GR-SPORT IV",
//       "HILUX 4X4 DC SR 2.8 TDI 6 AT",
//       "HILUX 4X4 DC SR 2.8 TDI 6 MT",
//       "HILUX 4X4 DC SRV 2.8 TDI 6",
//       "HILUX 4X4 DC SRV 2.8 TDI 6",
//       "HILUX 4X4 DC SRX 2.8 TDI 6",
//       "HILUX 4X4 DC SRX 2.8 TDI 6",
//       "HILUX 4X4 SRV LIMITED",
//       "HILUX 4X4 SRV LIMITED AT",
//       "HILUX 4X4, CABINA DOBLE",
//       "HILUX 4X4, CABINA SIMPLE",
//       "HILUX 4X4, CABINA SIMPLE",
//       "HILUX 4X4/4WD",
//       "HILUX DLX",
//       "HILUX DLX",
//       "HILUX DLX/SR5",
//       "HILUX OTROS",
//       "HILUX RN106L-PRMRE",
//       "HILUX RN106L-PRMRE",
//       "HILUX SR5",
//       "HILUX SRV TDI 4X2",
//       "HILUX SURF",
//       "HILUX SURF SSR",
//       "HILUX SW4",
//       "HILUX SW4 2.7",
//       "HILUX SW4 2.7",
//       "HILUX SW4 3.0 TURBO DIESEL",
//       "HILUX SW4 3.0 TURBO DIESEL",
//       "HILUX SW4 4X2 SR 3.0 TDI",
//       "HILUX SW4 4X2 SRV 2.7 VVT-I",
//       "HILUX SW4 4X2 SRV 2.7 VVT-I",
//       "HILUX SW4 4X4 SRV 3.0 TDI",
//       "HILUX SW4 4X4 SRV 3.0 TDI 5",
//       "HILUX SW4 4X4 SRV 3.0 TDI",
//       "HILUX SW4 4X4 SRV 3.0 TDI",
//       "HILUX SW4 4X4 SRV 3.0 TDI",
//       "HILUX SW4 V6",
//       "HILUX SW4 V6",
//       "INNOVA SR 2.7 NAFTA 6 A/T",
//       "INNOVA SRV 2.7 NAFTA 6 A/T",
//       "LAND CRUISER",
//       "LAND CRUISER",
//       "LAND CRUISER",
//       "LAND CRUISER",
//       "LAND CRUISER 200 VX",
//       "LAND CRUISER 300 VX",
//       "LAND CRUISER BJ 75 LV",
//       "LAND CRUISER D-4D",
//       "LAND CRUISER GX 95",
//       "LAND CRUISER J7",
//       "LAND CRUISER LX TURBO",
//       "LAND CRUISER PRADO",
//       "LAND CRUISER PRADO GX",
//       "LAND CRUISER PRADO TXL AT",
//       "LAND CRUISER PRADO VX",
//       "LAND CRUISER PRADO VX A/T",
//       "LAND CRUISER PRADO VX",
//       "LAND CRUISER STATION",
//       "LAND CRUISER STD",
//       "LAND CRUISER T/TURBO FULL",
//       "LAND CRUISER TD",
//       "LAND CRUISER VX",
//       "LAND CRUISER VX",
//       "LAND CRUISER VX 4WD",
//       "LAND CRUISER VX 4WD",
//       "LAND CRUSIER LX TURBO",
//       "LEXUS LS400",
//       "NEW RAV4 2.0",
//       "PRIUS 1.5 CVT",
//       "PRIUS 1.8 CVT",
//       "PRIUS HV 1.8 CVT",
//       "PRIUS HYBRID",
//       "RAV 4",
//       "RAV 4",
//       "RAV 4",
//       "RAV 4 (4X2)",
//       "RAV 4 2.4 2WD",
//       "RAV 4 2.4 AT",
//       "RAV 4 2.4 AT",
//       "RAV 4 D-4D",
//       "RAV 4 LE SPORT",
//       "RAV 4 SPORT",
//       "RAV 4 SPORT",
//       "RAV 4L",
//       "RAV 4X2 CVT",
//       "RAV 4X2 CVT FULL",
//       "RAV4 2.0 L",
//       "RAV4 2.0 L",
//       "RAV4 2.0 L",
//       "RAV4 2.4 GX",
//       "RAV4 4WD LIMITED V6",
//       "RAV4 4X2 A/T",
//       "RAV4 4X2 A/T FULL",
//       "RAV4 4X2 CVT",
//       "RAV4 4X2 CVT FULL",
//       "RAV4 4X2 TX",
//       "RAV4 4X2 VX",
//       "RAV4 4X4 6A/T",
//       "RAV4 4X4 6A/T FULL",
//       "RAV4 4X4 A/T",
//       "RAV4 4X4 A/T FULL",
//       "RAV4 4X4 TX",
//       "RAV4 4X4 VX",
//       "RAV4 D-CAT",
//       "RAV4 HEV 2.5 AWD LIMITED",
//       "RAV4 HV 2.5 AWD LIMITED",
//       "RAV4 HV 2.5 LIMITED CVT",
//       "RAV4 HV 2.5 XLE CVT",
//       "RAV4 XLE",
//       "RAV4J",
//       "SIENNA",
//       "SIENNA",
//       "SIENNA LE",
//       "SIENNA XLE",
//       "SR 5",
//       "SR5 (CABINA Y MEDIA)",
//       "SR5 4WD CABINA Y MEDIA",
//       "SUPRA GR",
//       "SW4 4X2 SRV 2.7 VVTI 4AT",
//       "SW4 4X2 SRV 2.7 VVTI 4AT",
//       "SW4 4X4 DIAMOND 2.8 TDI 6",
//       "SW4 4X4 DIAMOND 2.8 TDI 6",
//       "SW4 4X4 DIAMOND II 2.8 TDI",
//       "SW4 4X4 GR-S 2.8 TDI 6 AT 7A",
//       "SW4 4X4 SR 2.8 TDI 6 AT 5A",
//       "SW4 4X4 SR 2.8 TDI 6A/T 5A",
//       "SW4 4X4 SR 2.8 TDI 6M/T 5A",
//       "SW4 4X4 SRV 3.0 TDI - C3",
//       "SW4 4X4 SRV 3.0 TDI 5AT",
//       "SW4 4X4 SRV 3.0 TDI CUERO -",
//       "SW4 4X4 SRX 2.7 NAFTA 6A/T",
//       "SW4 4X4 SRX 2.7 NAFTA 6A/T",
//       "SW4 4X4 SRX 2.8 TDI 6 A/T 5A",
//       "SW4 4X4 SRX 2.8 TDI 6 A/T 7A",
//       "SW4 4X4 SRX 2.8 TDI 6 AT 7A",
//       "SW4 4X4 SRX 2.8 TDI 6 M/T",
//       "SW4 4X4 SRX 2.8 TDI 6 MT 7A",
//       "TACOMA 4X4 (CABINA Y",
//       "TACOMA SR5 CABINA Y",
//       "TUNDRA SR5",
//       "TUNDRA SR5",
//       "YARIS",
//       "YARIS",
//       "YARIS",
//       "YARIS 1.0 5P ENTRY",
//       "YARIS 5M / T",
//       "YARIS CVT",
//       "YARIS GLE SPORT",
//       "YARIS S 1.5 6M/T",
//       "YARIS S 1.5 CVT",
//       "YARIS SE",
//       "YARIS XLI 1.5",
//       "YARIS XLS 1.5 6M/T",
//       "YARIS XLS 1.5 6M/T",
//       "YARIS XLS 1.5 CVT",
//       "YARIS XLS 1.5 CVT",
//       "YARIS XLS PACK 1.5 CVT",
//       "YARIS XLS PACK 1.5 CVT",
//       "YARIS XS 1.5 6M/T",
//       "YARIS XS 1.5 6M/T",
//       "YARIS XS AUDIO 1.5 6M/T",
//       "RAV4 LIMITED",
//       "86 FT M/T",
//       "86 GT A/T",
//       "86 GT M/T",
//       "COROLLA VERSO 1.8"
//     ],
//     "TRIUMPH": [
//       "BONNEVILLE T120",
//       "BONNEVILLE T120 BLACK",
//       "BONNEVILLE BOBBER",
//       "BONNEVILLE BOBBER BLACK",
//       "BONNEVILLE BOBBER TFC",
//       "BONNEVILLE SE",
//       "BONNEVILLE SPEEDMASTER",
//       "BONNEVILLE T 100",
//       "BONNEVILLE T 100 BLACK",
//       "BONNEVILLE T100",
//       "EXPLORER XCA",
//       "EXPLORER XCX",
//       "ROCKET 3 R",
//       "ROCKET III TOURING",
//       "SCRAMBLER",
//       "SCRAMBLER 1200 XC",
//       "SCRAMBLER 1200 XE",
//       "SPEED TWIN",
//       "STREET CUP",
//       "STREET SCRAMBLER",
//       "STREET TWIN",
//       "THRUXTON",
//       "THRUXTON R",
//       "TIGER 1050",
//       "TIGER 1200 XCX",
//       "TIGER 800",
//       "TIGER 800 XC",
//       "TIGER 800 XCA",
//       "TIGER 800 XCX",
//       "TIGER 800 XRX",
//       "TIGER 900 GT",
//       "TIGER 900 GT PRO",
//       "TIGER 900 RALLY",
//       "TIGER 900 RALLY PRO",
//       "TIGER EXPLORER",
//       "TIGER EXPLORER XC"
//     ],
//     "TVS": ["NTORQ 125", "RTR 160", "RTR 200"],
//     "VOGE": ["300 AC", "300 DS", "500DS", "500R", "650DS"],
//     "VOLKSWAGEN": [
//       "10.150 E",
//       "13.180",
//       "13.180E",
//       "13180",
//       "14150",
//       "14150",
//       "15.180",
//       "15.180E",
//       "15.190",
//       "15.190 EOD",
//       "15.190 OD",
//       "15160",
//       "15170",
//       "15180",
//       "17.190",
//       "17.190 DC V-TRONIC",
//       "17.190DC",
//       "17.210 OD",
//       "17.230",
//       "17.230 EOD",
//       "17.230 OD",
//       "17.250",
//       "17.250 E",
//       "17.250 E",
//       "17.250 E",
//       "17.250 E",
//       "17.280",
//       "17.280 DC",
//       "17.280 LR",
//       "17.280 LR",
//       "17160",
//       "17210",
//       "17210",
//       "17220",
//       "17220",
//       "17220",
//       "17300",
//       "17300",
//       "17310",
//       "17-310 TITAN",
//       "18.280 OT LE",
//       "18.310 OT",
//       "18.330 OT",
//       "18310",
//       "19.320 E",
//       "19.320 E",
//       "19.320 E",
//       "19.320 E",
//       "19.330 HR",
//       "19.330 HR",
//       "19.330 LR",
//       "19.360 HR",
//       "19.390",
//       "19.420 HR",
//       "25.320 E",
//       "25.320 E",
//       "25.420 HR",
//       "26.260 E",
//       "26.260 EC",
//       "26.280 DC",
//       "26.420 HR",
//       "26260",
//       "26300",
//       "26310",
//       "31.280 DC",
//       "31.320",
//       "31.330 DC",
//       "5.140 E",
//       "7100",
//       "8.150 E",
//       "9.150",
//       "9.160",
//       "9.160 OD",
//       "9140",
//       "9150 E",
//       "AMAROK",
//       "AMAROK 122 CV CS 082",
//       "AMAROK 2.0L TDI 122 CV 4X2",
//       "AMAROK 2.0L TDI 122 CV 4X2",
//       "AMAROK 2.0L TDI 122 CV 4X2",
//       "AMAROK 2.0L TDI 122 CV 4X2",
//       "AMAROK 2.0L TDI 122 CV 4X2",
//       "AMAROK 2.0L TDI 122 CV 4X4",
//       "AMAROK 2.0L TDI 122 CV 4X4",
//       "AMAROK 2.0L TDI 122 CV 4X4",
//       "AMAROK 2.0L TDI 122 CV 4X4",
//       "AMAROK 2.0L TDI 122 CV 4X4",
//       "AMAROK 2.0L TDI 140 CV 4X2",
//       "AMAROK 2.0L TDI 140 CV 4X2",
//       "AMAROK 2.0L TDI 140 CV 4X2",
//       "AMAROK 2.0L TDI 140 CV 4X2",
//       "AMAROK 2.0L TDI 140 CV 4X2",
//       "AMAROK 2.0L TDI 140 CV 4X2",
//       "AMAROK 2.0L TDI 140 CV 4X2",
//       "AMAROK 2.0L TDI 140 CV 4X2",
//       "AMAROK 2.0L TDI 140 CV 4X4",
//       "AMAROK 2.0L TDI 140 CV 4X4",
//       "AMAROK 2.0L TDI 140 CV 4X4",
//       "AMAROK 2.0L TDI 140 CV 4X4",
//       "AMAROK 2.0L TDI 140 CV 4X4",
//       "AMAROK 2.0L TDI 140 CV 4X4",
//       "AMAROK 2.0L TDI 140CV 4X2",
//       "AMAROK 2.0L TDI 140CV 4X4",
//       "AMAROK 2.0L TDI 140CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X2",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180 CV 4X4",
//       "AMAROK 2.0L TDI 180CV 4X2",
//       "AMAROK 2.0L TDI 180CV 4X4",
//       "AMAROK 2.0L TDI 180CV 4X4",
//       "AMAROK 2.0L TDI 180CV 4X4",
//       "AMAROK 2.0L TDI 180CV 4X4",
//       "AMAROK 2.0L TDI 180CV 4X4",
//       "AMAROK 2.0L TDI 180CV 4X4",
//       "AMAROK 2.0L TDI 180CV 4X4",
//       "AMAROK 2.0L TDI 4X2 037",
//       "AMAROK 2.0L TDI 4X2 163CV",
//       "AMAROK 2.0L TDI 4X2 1H2",
//       "AMAROK 2.0L TDI 4X2 1P2",
//       "AMAROK 2.0L TDI 4X2 1T2",
//       "AMAROK 2.0L TDI 4X2 1T8",
//       "AMAROK 2.0L TDI 4X2 228",
//       "AMAROK 2.0L TDI 4X2 240",
//       "AMAROK 2.0L TDI 4X2 2H2",
//       "AMAROK 2.0L TDI 4X2 2N2",
//       "AMAROK 2.0L TDI 4X2 2P2",
//       "AMAROK 2.0L TDI 4X2 2T2",
//       "AMAROK 2.0L TDI 4X2 2T7",
//       "AMAROK 2.0L TDI 4X2 387",
//       "AMAROK 2.0L TDI 4X2 CH2",
//       "AMAROK 2.0L TDI 4X2 CN2",
//       "AMAROK 2.0L TDI 4X2 HC2",
//       "AMAROK 2.0L TDI 4X2 S43",
//       "AMAROK 2.0L TDI 4X2 ST3",
//       "AMAROK 2.0L TDI 4X4 163CV",
//       "AMAROK 2.0L TDI 4X4 181",
//       "AMAROK 2.0L TDI 4X4 1H0",
//       "AMAROK 2.0L TDI 4X4 1H4",
//       "AMAROK 2.0L TDI 4X4 1HC",
//       "AMAROK 2.0L TDI 4X4 1HP",
//       "AMAROK 2.0L TDI 4X4 1N4",
//       "AMAROK 2.0L TDI 4X4 1T0",
//       "AMAROK 2.0L TDI 4X4 1T7",
//       "AMAROK 2.0L TDI 4X4 372",
//       "AMAROK 2.0L TDI 4X4 384",
//       "AMAROK 2.0L TDI 4X4 4T4",
//       "AMAROK 2.0L TDI 4X4 4T7",
//       "AMAROK 2.0L TDI 4X4 531",
//       "AMAROK 2.0L TDI 4X4 BH0",
//       "AMAROK 2.0L TDI 4X4 BHP",
//       "AMAROK 2.0L TDI 4X4 HC4",
//       "AMAROK 2.0L TDI 4X4 HN4",
//       "AMAROK 2.0L TDI 4X4 HP4",
//       "AMAROK 2.0L TDI 4X4 S45",
//       "AMAROK 2.0L TDI 4X4 ST4",
//       "AMAROK 2.0L TDI 4X4 ST5",
//       "AMAROK 2.0LTDI 140CV 4X2",
//       "AMAROK 2.0LTDI 140CV 4X2",
//       "AMAROK 2.0LTDI 140CV 4X2",
//       "AMAROK 2.0LTDI 140CV 4X4",
//       "AMAROK 2.0LTDI 140CV 4X4",
//       "AMAROK BLACK STYLE V6",
//       "AMAROK COMFORTLINE 4X2",
//       "AMAROK COMFORTLINE 4X2",
//       "AMAROK COMFORTLINE 4X4",
//       "AMAROK COMFORTLINE 4X4",
//       "AMAROK CONFOR 2.0L TDI",
//       "AMAROK CONFOR 2.0L TDI",
//       "AMAROK CONFOR 2.0L TDI",
//       "AMAROK CONFOR 2.0L TDI",
//       "AMAROK DC 2.0L TDI 140 CV",
//       "AMAROK DC 2.0L TDI 140 CV",
//       "AMAROK DC 2.0L TDI 140 CV",
//       "AMAROK DC 2.0L TDI 140 CV",
//       "AMAROK DC 2.0L TDI 140 CV",
//       "AMAROK DC 2.0L TDI 140 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180 CV",
//       "AMAROK DC 2.0L TDI 180CV",
//       "AMAROK DC 2.0L TDI 180CV",
//       "AMAROK DC 2.0L TDI 180CV",
//       "AMAROK DC 2.0L TDI 180CV",
//       "AMAROK DC 2.0L TDI 180CV",
//       "AMAROK DC 2.0L TDI 180CV",
//       "AMAROK DC 2.0L TDI 180CV",
//       "AMAROK DC 2.0L TDI 180CV",
//       "AMAROK DC 2.0L TDI 180CV",
//       "AMAROK DC 2.0L TDI 180CV",
//       "AMAROK DC 2.0L TDI 180CV",
//       "AMAROK DC 2.0L TDI 180CV",
//       "AMAROK DC TDI 140 CV M",
//       "AMAROK DC TDI 140 CV M",
//       "AMAROK DC V6 3.0L 258 CV",
//       "AMAROK DC V6 3.0L TDI 4X4",
//       "AMAROK DC V6 BLACK STYLE",
//       "AMAROK DC V6",
//       "AMAROK DC V6",
//       "AMAROK DC V6 CONFORT",
//       "AMAROK DC V6 EXTREME",
//       "AMAROK DC V6 EXTREME",
//       "AMAROK EXTREME V6 3.0L",
//       "AMAROK HIGHLINE 4X2 2.0L",
//       "AMAROK HIGHLINE 4X2 AUT",
//       "AMAROK HIGHLINE 4X4 2.0L",
//       "AMAROK HIGHLINE 4X4 AUT",
//       "AMAROK HIGHLINE P1 V6",
//       "AMAROK HIGHLINE V6 3.0L",
//       "AMAROK SC 2.0L TDI 140 CV",
//       "AMAROK SC 2.0L TDI 140 CV",
//       "AMAROK SC 2.0L TDI 140 CV",
//       "AMAROK SC 2.0L TDI 140 CV",
//       "AMAROK SC 2.0L TDI 140 CV",
//       "AMAROK SC 2.0L TDI 140 CV",
//       "AMAROK SC TDI 140 CV M",
//       "AMAROK SC TDI 140 CV M",
//       "AMAROK TRENDLINE 4X2 2.0L",
//       "AMAROK TRENDLINE 4X4 2.0L",
//       "BEETLE",
//       "BEETLE",
//       "BEETLE 1.8 TURBO",
//       "BEETLE 2.0",
//       "BEETLE 2.0",
//       "BEETLE 2.5 SPORT",
//       "BLACK UP 1.0 MPI",
//       "BLACK UP! 1.0 MPI",
//       "BORA 1.8T",
//       "BORA 2.0",
//       "BORA 2.5",
//       "BORA TDI",
//       "BORA VR6",
//       "CADDY",
//       "CADDY 1.6",
//       "CADDY 1.6 (4510) 102",
//       "CADDY 1.6 (4510) 301",
//       "CADDY 1.6 (4510) 30A",
//       "CADDY 1.6 (4510) 3G1",
//       "CADDY 1.6 (4510) 3GA",
//       "CADDY 1.6 (4510) 3GA",
//       "CADDY 1.6 (4510) 600",
//       "CADDY 1.6 (4510) 601",
//       "CADDY 1.6 (4510) 60A",
//       "CADDY 1.6 500",
//       "CADDY 1.6 501",
//       "CADDY 1.6 5G0",
//       "CADDY 1.6 5G1",
//       "CADDY 1.6 5GA",
//       "CADDY 1.6 MI 102",
//       "CADDY 1.6 MI 991",
//       "CADDY 1.6 MI 992",
//       "CADDY 1.6 MI 99A",
//       "CADDY 1.6L (5016) 102",
//       "CADDY 1.8 (4090) E02",
//       "CADDY 1.9 SD (4512) 102",
//       "CADDY 1.9 SD (4512) 10A",
//       "CADDY 1.9 SD (4512) 301",
//       "CADDY 1.9 SD (4512) 30A",
//       "CADDY 1.9 SD (4512) 601",
//       "CADDY 1.9 SD (4514) 001",
//       "CADDY 1.9 SD (4514) 00A",
//       "CADDY 1.9 SD (4514) 102",
//       "CADDY 1.9 SD (4514) 10A",
//       "CADDY 1.9 SD (4514) 301",
//       "CADDY 1.9 SD (4514) 30A",
//       "CADDY 1.9 SD (4612) 60A",
//       "CADDY 1.9 SD 001",
//       "CADDY 1.9 SD 00A",
//       "CADDY 1.9 SD 101",
//       "CADDY 1.9 SD 102",
//       "CADDY 1.9 SD 102",
//       "CADDY 1.9 SD 10A",
//       "CADDY 1.9 SD 992",
//       "CADDY 1.9 SD 992",
//       "CADDY 1.9 SD 99A",
//       "CADDY 1.9 SD 99A",
//       "CADDY 1.9 SD T02",
//       "CADDY 1.9 SD T0A",
//       "CADDY 1.9 SD VD 102",
//       "CADDY 1.9 SD VD 10A",
//       "CADDY 1.9L (4514) 501",
//       "CADDY 1.9L (4514) 50A",
//       "CADDY 1.9L 500",
//       "CADDY 1.9L 501",
//       "CADDY 1.9L 50A",
//       "CARAVELLE 2.5 TDI",
//       "CONSTELLATION 14.190",
//       "CONSTELLATION 15.190",
//       "CONSTELLATION 17.230",
//       "CONSTELLATION 19.330",
//       "CONSTELLATION 24.280",
//       "CONSTELLATION 25.360",
//       "CONSTELLATION 25.360 V-",
//       "CONSTELLATION 32.360 V-",
//       "CROSS UP! 1.0 MPI",
//       "CROSS UP! 1.0 TSI",
//       "CROSSFOX 1.6",
//       "CROSSFOX 1.6 MSI 16V",
//       "DELIVERY 11.180",
//       "DELIVERY 11.180 V-TRONIC",
//       "DELIVERY 6.160",
//       "DELIVERY 9.170",
//       "DELIVERY 9.170 V-TRONIC",
//       "DERBY 1.8",
//       "DERBY 2.0",
//       "FOX",
//       "FOX 1.6",
//       "FOX 1.6",
//       "FOX 1.6 5P 70A",
//       "FOX 1.6 5P 70C",
//       "FOX 1.6 5P 70D",
//       "FOX 1.6 5P 71A",
//       "FOX 1.6 5P 71C",
//       "FOX 1.6 5P 71D",
//       "FOX 1.6 CONNECT MANUAL",
//       "FOX 1.6 I MOTION",
//       "FOX 1.6 MSI",
//       "FOX 1.6 MSI 16V HIGHLINE",
//       "FOX 1.6 MSI 16V HIGHLINE",
//       "FOX 1.6 MSI 16V HIGHLINE",
//       "FOX 1.6 ROUTE",
//       "FOX 1.6 ROUTE",
//       "FOX 1.6 SPORTLINE",
//       "FOX 1.6L 5D 8160/10A",
//       "FOX 1.6L 5D 8160/10B",
//       "FOX 1.6L 5D 8160/10C",
//       "FOX 1.6L 5D 8160/11A",
//       "FOX 1.9 SDI",
//       "FOX 1.9 SDI 5P 70A",
//       "FOX 1.9 SDI 5P 70C",
//       "FOX 1.9 SDI 5P 71A",
//       "FOX 1.9 SDI 5P 71C",
//       "FOX TRACK 1.6 MSI",
//       "FUSCA 1600",
//       "GOL",
//       "GOL",
//       "GOL 001",
//       "GOL 011",
//       "GOL 1.0",
//       "GOL 1.0",
//       "GOL 1.0",
//       "GOL 1.0 (0250) 001",
//       "GOL 1.0 (0250) 011",
//       "GOL 1.0 MI",
//       "GOL 1.0 MI PLUS 011",
//       "GOL 1.0I",
//       "GOL 1.0L (250) 002",
//       "GOL 1.0L (250) 012",
//       "GOL 1.0L MI PLUS 001",
//       "GOL 1.4 L",
//       "GOL 1.4 L",
//       "GOL 1.6",
//       "GOL 1.6",
//       "GOL 1.6 (0132) 20A",
//       "GOL 1.6 (0132) 20B",
//       "GOL 1.6 (0132) 21A",
//       "GOL 1.6 (0132) 21B",
//       "GOL 1.6 (0182) 203",
//       "GOL 1.6 (0182) 213",
//       "GOL 1.6 (0260) 001",
//       "GOL 1.6 (0260) 011",
//       "GOL 1.6 (0260) 202",
//       "GOL 1.6 (0260) 203",
//       "GOL 1.6 (0260) 20A",
//       "GOL 1.6 (0260) 212",
//       "GOL 1.6 (0260) 213",
//       "GOL 1.6 (0260) 21A",
//       "GOL 1.6 (0270) 002",
//       "GOL 1.6 (0270) 00A",
//       "GOL 1.6 (0270) 00B",
//       "GOL 1.6 (0270) 012",
//       "GOL 1.6 (0270) 01A",
//       "GOL 1.6 (0270) 01B",
//       "GOL 1.6 (0270) 20B",
//       "GOL 1.6 (0270) 21B",
//       "GOL 1.6 001",
//       "GOL 1.6 002",
//       "GOL 1.6 00A",
//       "GOL 1.6 00B",
//       "GOL 1.6 011",
//       "GOL 1.6 012",
//       "GOL 1.6 01A",
//       "GOL 1.6 01B",
//       "GOL 1.6 5P (0132) 002",
//       "GOL 1.6 5P (0132) 00A",
//       "GOL 1.6 5P (0132) 00B",
//       "GOL 1.6 5P (0132) 012",
//       "GOL 1.6 5P (0132) 01A",
//       "GOL 1.6 5P (0132) 01B",
//       "GOL 1.6 5P (0182) 001",
//       "GOL 1.6 5P (0182) 011",
//       "GOL 1.6 5P 001",
//       "GOL 1.6 5P 002",
//       "GOL 1.6 5P 00A",
//       "GOL 1.6 5P 00B",
//       "GOL 1.6 5P 011",
//       "GOL 1.6 5P 012",
//       "GOL 1.6 5P 01A",
//       "GOL 1.6 5P 01B",
//       "GOL 1.6MI",
//       "GOL 1.6MI",
//       "GOL 1.8",
//       "GOL 1.8",
//       "GOL 1.8 (0550) 00A",
//       "GOL 1.8L (0550) 001",
//       "GOL 1.8L (0550) 00B",
//       "GOL 1.8L (0550) 00C",
//       "GOL 1.8L (0550) 011",
//       "GOL 1.8L (0550) 01A",
//       "GOL 1.8L (0550) 01B",
//       "GOL 1.8L (0550) 01C",
//       "GOL 1.9",
//       "GOL 1.9",
//       "GOL 1.9 SD",
//       "GOL 1.9 SD",
//       "GOL 1.9 SD (0211) 203",
//       "GOL 1.9 SD (0211) 213",
//       "GOL 1.9 SD (0212) 20A",
//       "GOL 1.9 SD (0212) 20B",
//       "GOL 1.9 SD (0212) 21A",
//       "GOL 1.9 SD (0212) 21B",
//       "GOL 1.9 SD (0280) 001",
//       "GOL 1.9 SD (0280) 011",
//       "GOL 1.9 SD (0280) 203",
//       "GOL 1.9 SD (0280) 213",
//       "GOL 1.9 SD (0281) 002",
//       "GOL 1.9 SD (0281) 00A",
//       "GOL 1.9 SD (0281) 00B",
//       "GOL 1.9 SD (0281) 012",
//       "GOL 1.9 SD (0281) 01A",
//       "GOL 1.9 SD (0281) 01B",
//       "GOL 1.9 SD (0281) 20A",
//       "GOL 1.9 SD (0281) 20B",
//       "GOL 1.9 SD (0281) 21A",
//       "GOL 1.9 SD (0281) 21B",
//       "GOL 1.9 SD 001",
//       "GOL 1.9 SD 002",
//       "GOL 1.9 SD 00A",
//       "GOL 1.9 SD 00B",
//       "GOL 1.9 SD 00C",
//       "GOL 1.9 SD 011",
//       "GOL 1.9 SD 012",
//       "GOL 1.9 SD 01A",
//       "GOL 1.9 SD 01B",
//       "GOL 1.9 SD 01C",
//       "GOL 1.9 SD 5P (0211) 001",
//       "GOL 1.9 SD 5P (0211) 011",
//       "GOL 1.9 SD 5P (0212) 002",
//       "GOL 1.9 SD 5P (0212) 00A",
//       "GOL 1.9 SD 5P (0212) 00B",
//       "GOL 1.9 SD 5P (0212) 012",
//       "GOL 1.9 SD 5P (0212) 01A",
//       "GOL 1.9 SD 5P (0212) 01B",
//       "GOL 1.9 SD 5P 001",
//       "GOL 1.9 SD 5P 002",
//       "GOL 1.9 SD 5P 00A",
//       "GOL 1.9 SD 5P 00B",
//       "GOL 1.9 SD 5P 011",
//       "GOL 1.9 SD 5P 012",
//       "GOL 1.9 SD 5P 01A",
//       "GOL 1.9 SD 5P 01B",
//       "GOL CL 1.6MI",
//       "GOL COMFORTLINE 1.6 GAS",
//       "GOL COUNTRY 1.4 L",
//       "GOL COUNTRY 1.6",
//       "GOL COUNTRY 1.6 MI",
//       "GOL COUNTRY 1.6 MI",
//       "GOL COUNTRY 1.6MI",
//       "GOL COUNTRY 1.8",
//       "GOL COUNTRY 1.8MI",
//       "GOL COUNTRY 1.9",
//       "GOL COUNTRY 1.9SD",
//       "GOL COUNTRY F 1.6",
//       "GOL COUNTRY F 1.9 SD",
//       "GOL COUNTRY GL 1.8 MI",
//       "GOL CROSSOVER",
//       "GOL DEE JAY 002",
//       "GOL DEE JAY 012",
//       "GOL GL",
//       "GOL GL",
//       "GOL GL 1.6",
//       "GOL GL 1.6 MI",
//       "GOL GL 1.6 MI",
//       "GOL GL 1.6D",
//       "GOL GL 1.6D",
//       "GOL GL 1.8 MI",
//       "GOL GL 1.8 MI",
//       "GOL GL 1.9SD",
//       "GOL GL 1.9SD",
//       "GOL GLD",
//       "GOL GLD 1.9SD",
//       "GOL GLI",
//       "GOL GTI 16V",
//       "GOL GTI 2.0",
//       "GOL GTI 2000",
//       "GOL MI",
//       "GOL PLUS 1.6 MI",
//       "GOL PLUS 1.6 MI",
//       "GOL PLUS 1.6D",
//       "GOL PLUS 1.6D",
//       "GOL PLUS 1.8 MI",
//       "GOL PLUS 1.9 SD",
//       "GOL TREND 1.6",
//       "GOL TREND 1.6",
//       "GOL TREND 1.6 GP",
//       "GOL TREND 1.6 MSI",
//       "GOL TREND 1.6 MSI",
//       "GOL TREND 1.6 SPORTLINE",
//       "GOL TREND 1.6I MOTION",
//       "GOL TREND 1.6I MOTION",
//       "GOL TREND COMFORTLINE",
//       "GOL TREND COMFORTLINE",
//       "GOL TREND COMFORTLINE",
//       "GOL TREND CONNECT 1.6 MSI",
//       "GOL TREND CUP 10 1.6L",
//       "GOL TREND HIGHLINE 1.6",
//       "GOL TREND HIGHLINE 1.6 MSI",
//       "GOL TREND SPORTLINE 1.6",
//       "GOL TREND TRENDLINE 1.6",
//       "GOL TREND TRENDLINE 1.6",
//       "GOL TRENDLINE 1.6 GAS",
//       "GOL TSI 2.0",
//       "GOL/GL/GLI",
//       "GOLF",
//       "GOLF",
//       "GOLF",
//       "GOLF",
//       "GOLF 1.4L TSI BLUEMOTION",
//       "GOLF 1.4L TSI BLUEMOTION",
//       "GOLF 1.6",
//       "GOLF 1.6",
//       "GOLF 1.6 HIGHLINE",
//       "GOLF 1.6L",
//       "GOLF 1.6L",
//       "GOLF 1.9 SDI",
//       "GOLF 1600 GLD",
//       "GOLF 2.0",
//       "GOLF 2.0",
//       "GOLF 2.0L GTI",
//       "GOLF 90 S",
//       "GOLF CABRIO",
//       "GOLF CABRIOLET",
//       "GOLF CABRIOLET",
//       "GOLF CABRIOLET GL",
//       "GOLF CABRIOLET/CL/GL",
//       "GOLF CL",
//       "GOLF CL",
//       "GOLF CL",
//       "GOLF CL 1.3",
//       "GOLF CL TDI",
//       "GOLF COMFORLINE 1.4L TSI",
//       "GOLF COMFORTLINE 1.4L TSI",
//       "GOLF COMFORTLINE 1.4L TSI",
//       "GOLF GL",
//       "GOLF GL",
//       "GOLF GL",
//       "GOLF GL",
//       "GOLF GL",
//       "GOLF GL 1.8",
//       "GOLF GL 1.8 MI",
//       "GOLF GLS",
//       "GOLF GLS",
//       "GOLF GLX",
//       "GOLF GLX 2.0",
//       "GOLF GLX 2.0",
//       "GOLF GLX 2.0MI",
//       "GOLF GT",
//       "GOLF GT SPECIAL",
//       "GOLF GT SPORT 1.4 TSI",
//       "GOLF GTD",
//       "GOLF GTD",
//       "GOLF GTD FULL",
//       "GOLF GTI",
//       "GOLF GTI",
//       "GOLF GTI",
//       "GOLF GTI",
//       "GOLF GTI 2.0",
//       "GOLF GTI 2.0",
//       "GOLF GTI 2.0",
//       "GOLF GTI 2.0 TSI",
//       "GOLF GTI 2.0 TSI DQ",
//       "GOLF GTI DSG",
//       "GOLF GTI VR6",
//       "GOLF GTI/GLX/GLD/GTD",
//       "GOLF HIGHLINE 1.4L TSI DQ",
//       "GOLF HIGHLINE 250TSI DSG",
//       "GOLF TDI",
//       "GOLF TDI",
//       "GOLF TDI",
//       "GOLF TRENDLINE 1.6L MQ",
//       "GOLF TURBO DIESEL SPORT",
//       "GOLF V6 4MOTION",
//       "GOLF V6 4MOTION",
//       "GOLF VARIANT COMFORTLINE",
//       "GOLF VARIANT COMFORTLINE",
//       "GOLF VARIANT HIGHLINE 1.4L",
//       "GOLF VARIANT TRENDLINE",
//       "GOLF VR6",
//       "GOLF VR6",
//       "HIGH UP 1.0 MPI",
//       "HIGH UP! 1.0 MPI",
//       "HIGH UP! 1.0 MPI",
//       "HIGH UP! 1.0 MPI I-MOTION",
//       "JETTA",
//       "JETTA SE",
//       "JETTA SE",
//       "JETTA TSI",
//       "MOVE UP 1.0 MPI",
//       "MOVE UP! 1.0 MPI",
//       "MOVE UP! 1.0 MPI",
//       "MOVE UP! 1.0 MPI I-MOTION",
//       "MULTIVAN 1.9 TDI",
//       "MULTIVAN 2.5 TDI",
//       "MULTIVAN COMFORTLINE 2.0",
//       "NEW BEETLE",
//       "NEW BEETLE",
//       "NEW BEETLE",
//       "NEW BEETLE 1.8 T",
//       "NEW BEETLE 1.8 T CABRIO",
//       "NEW BEETLE 1.8 T SPORT",
//       "NEW BEETLE 2.0L",
//       "NEW BEETLE 2.0L",
//       "NEW BEETLE 2.5 CABRIO",
//       "NEW BEETLE 2.5 SPORT",
//       "NEW BEETLE TDI",
//       "NEW BEETLE TDI",
//       "NIVUS 170 TSI MT",
//       "NIVUS COMFORTLINE 200 TSI",
//       "NIVUS HIGHLINE 200 TSI AT",
//       "NIVUS HIGHLINE HERO 200",
//       "NUEVO SURAN 1.6 MQ 5P",
//       "NUEVO TOUAREG V6 TDI",
//       "PARA TI 1.6",
//       "PARA TI CL",
//       "PARA TI CL",
//       "PARA TI CL",
//       "PARATI CL 1.8",
//       "PARATI CL 1.8",
//       "PASSAT",
//       "PASSAT 1.8",
//       "PASSAT 1.8",
//       "PASSAT 1.8 FSI",
//       "PASSAT 1.8 TSI",
//       "PASSAT 1.8T",
//       "PASSAT 1.8T",
//       "PASSAT 2.0",
//       "PASSAT 2.0",
//       "PASSAT 2.0 FSI",
//       "PASSAT 2.0 FSI",
//       "PASSAT 2.0 T",
//       "PASSAT 2.0 T",
//       "PASSAT 2.0 T FSI",
//       "PASSAT 2.0 T FSI",
//       "PASSAT 2.0 TDI",
//       "PASSAT 2.0 TDI",
//       "PASSAT 2.0 TDI 4 MOTION",
//       "PASSAT 2.0 TDI 4 MOTION",
//       "PASSAT 2.0 TDI BLUEMOTION",
//       "PASSAT 2.0 TDI BLUEMOTION",
//       "PASSAT 2.0 TDI BLUEMOTION",
//       "PASSAT 2.0 TDI BLUEMOTION",
//       "PASSAT 2.0 TSI",
//       "PASSAT 2.0 TSI",
//       "PASSAT 2.0 TSI DSG",
//       "PASSAT CC 1.8",
//       "PASSAT CC 2.0 TDI",
//       "PASSAT CC 2.0 TDI",
//       "PASSAT CC 2.0 TSI",
//       "PASSAT CC 3.6 FSI V6",
//       "PASSAT R-LINE 2.0 TSI DSG",
//       "PASSAT TDI",
//       "PASSAT TDI",
//       "PASSAT TDI",
//       "PASSAT TDI 4 MOTION",
//       "PASSAT TDI 4 MOTION",
//       "PASSAT V6",
//       "PASSAT V6",
//       "PASSAT V6 4 MOTION",
//       "PASSAT V6 4 MOTION",
//       "PASSAT V6 FSI 4 MOTION",
//       "PASSAT V6 TDI",
//       "PASSAT V6 TDI 4 MOTION",
//       "PASSAT V6 TDI 4 MOTION",
//       "PASSAT VARIANT",
//       "PASSAT VARIANT CL",
//       "PASSAT VARIANT CL 1.9 TD",
//       "PASSAT VARIANT GLI",
//       "PASSAT VARIANT TDI",
//       "PASSAT W8 4 MOTION",
//       "PASSAT W8 4 MOTION",
//       "PASSAT/GL/VR6/TDI",
//       "PEPPER UP! 1.0 170TSI 101CV",
//       "PEPPER UP! 1.0 TSI",
//       "PHAETON 4.2 V8 FSI",
//       "POLO",
//       "POLO",
//       "POLO",
//       "POLO 1,9 SD (3040) 302",
//       "POLO 1.4",
//       "POLO 1.6",
//       "POLO 1.6",
//       "POLO 1.6 16V",
//       "POLO 1.6 302",
//       "POLO 1.6 3G1",
//       "POLO 1.6 3G2",
//       "POLO 1.6 3GA",
//       "POLO 1.6 3GB",
//       "POLO 1.6 G3B",
//       "POLO 1.6 MI (3010) 302",
//       "POLO 1.6 MI (3010) 30A",
//       "POLO 1.6 MI (3010) 30B",
//       "POLO 1.6 MI (3010) 312",
//       "POLO 1.6 MI (3010) 31A",
//       "POLO 1.6 MI (3010) 31B",
//       "POLO 1.6 MI (3010) 3G1",
//       "POLO 1.6 MI (3010) 3G2",
//       "POLO 1.6 MI (3010) 3GB",
//       "POLO 1.6 MI (3010) G3A",
//       "POLO 1.6 MI (3010) G3B",
//       "POLO 1.6 MI (3010) PG2",
//       "POLO 1.6 MI 101",
//       "POLO 1.6 MI 102",
//       "POLO 1.6 MI 10A",
//       "POLO 1.6 MI 10B",
//       "POLO 1.6 MI 111",
//       "POLO 1.6 MI 112",
//       "POLO 1.6 MI 11A",
//       "POLO 1.6 MI 11B",
//       "POLO 1.6 MI 3010 3GA",
//       "POLO 1.6 PG3",
//       "POLO 1.6L (3010) PG3.",
//       "POLO 1.8 30B",
//       "POLO 1.8 31B",
//       "POLO 1.8 MI (3020) 30B",
//       "POLO 1.8 MI (3020) 31B",
//       "POLO 1.8 MI (3020) PG2",
//       "POLO 1.8 MI 10A",
//       "POLO 1.8 MI 10B",
//       "POLO 1.8 MI 10C",
//       "POLO 1.8 MI 10D",
//       "POLO 1.8 MI 11A",
//       "POLO 1.8 MI 11B",
//       "POLO 1.8 MI 11C",
//       "POLO 1.8 MI 11D",
//       "POLO 1.8 PG2",
//       "POLO 1.9 SD (3040) 30A",
//       "POLO 1.9 SD (3040) 30B",
//       "POLO 1.9 SD (3040) 312",
//       "POLO 1.9 SD (3040) 31A",
//       "POLO 1.9 SD (3040) 31B",
//       "POLO 1.9 SD 101",
//       "POLO 1.9 SD 102",
//       "POLO 1.9 SD 10A",
//       "POLO 1.9 SD 10B",
//       "POLO 1.9 SD 111",
//       "POLO 1.9 SD 112",
//       "POLO 1.9 SD 11A",
//       "POLO 1.9 SD 11B",
//       "POLO 1.9 SD 302",
//       "POLO 1.9 SD 351",
//       "POLO 1.9 SD 3SA",
//       "POLO 1.9 SD 3SB",
//       "POLO 1.9 TDI (3050) 30A",
//       "POLO 1.9 TDI (3050) 30B",
//       "POLO 1.9 TDI (3050) 31A",
//       "POLO 1.9 TDI (3050) 31B",
//       "POLO 1.9 TDI (3050) 31B",
//       "POLO 1.9 TDI 03G",
//       "POLO 1.9 TDI 10A",
//       "POLO 1.9 TDI 10B",
//       "POLO 1.9 TDI 10C",
//       "POLO 1.9 TDI 10D",
//       "POLO 1.9 TDI 11A",
//       "POLO 1.9 TDI 11B",
//       "POLO 1.9 TDI 11D",
//       "POLO 1.9L TDI 30A",
//       "POLO 1.9L TDI 31A",
//       "POLO CLASSIC",
//       "POLO CLASSIC 1.0 (3001) 01B",
//       "POLO CLASSIC 1.0 (3001) 02B",
//       "POLO CLASSIC 1.0 (3001) 02C",
//       "POLO CLASSIC 1.6",
//       "POLO CLASSIC 1.6",
//       "POLO CLASSIC 1.6 10A",
//       "POLO CLASSIC 1.6 L 31A",
//       "POLO CLASSIC 1.6 MI 003",
//       "POLO CLASSIC 1.6 MI 991",
//       "POLO CLASSIC 1.6 MI 991",
//       "POLO CLASSIC 1.6 MI 992",
//       "POLO CLASSIC 1.6 MI 99C",
//       "POLO CLASSIC 1.6 MI 99C",
//       "POLO CLASSIC 1.6 MI 99D",
//       "POLO CLASSIC 1.6 MI 99D",
//       "POLO CLASSIC 1.6 NAFTA 981",
//       "POLO CLASSIC 1.6 NAFTA 981",
//       "POLO CLASSIC 1.6 NAFTA 98C",
//       "POLO CLASSIC 1.6 NAFTA 98C",
//       "POLO CLASSIC 1.6 NAFTA 98D",
//       "POLO CLASSIC 1.6 NAFTA 98D",
//       "POLO CLASSIC 1.6L 302",
//       "POLO CLASSIC 1.6L 30A",
//       "POLO CLASSIC 1.6L 30A",
//       "POLO CLASSIC 1.6L 30B",
//       "POLO CLASSIC 1.6L 30B",
//       "POLO CLASSIC 1.6L 312",
//       "POLO CLASSIC 1.6L 312",
//       "POLO CLASSIC 1.6L 31A",
//       "POLO CLASSIC 1.6L 31B",
//       "POLO CLASSIC 1.6L 31B",
//       "POLO CLASSIC 1.6L 3G1",
//       "POLO CLASSIC 1.6L 3G2",
//       "POLO CLASSIC 1.6L 3GA",
//       "POLO CLASSIC 1.6L 3GB",
//       "POLO CLASSIC 1.6L G3A",
//       "POLO CLASSIC 1.6L G3A",
//       "POLO CLASSIC 1.6L G3B",
//       "POLO CLASSIC 1.6L PG3",
//       "POLO CLASSIC 1.8 (3035) 101",
//       "POLO CLASSIC 1.8 (3035) 10A",
//       "POLO CLASSIC 1.8 (3035) 10B",
//       "POLO CLASSIC 1.8 (3035) 10E",
//       "POLO CLASSIC 1.8 (3035) 10G",
//       "POLO CLASSIC 1.8 (3035) 10G",
//       "POLO CLASSIC 1.8 (3035) 111",
//       "POLO CLASSIC 1.8 (3035) 11A",
//       "POLO CLASSIC 1.8 (3035) 11B",
//       "POLO CLASSIC 1.8 (3035) 11E",
//       "POLO CLASSIC 1.8 (3035) 11F",
//       "POLO CLASSIC 1.8 (3035) 11G",
//       "POLO CLASSIC 1.8 (3066) 101",
//       "POLO CLASSIC 1.8 (3066) 10A",
//       "POLO CLASSIC 1.8 101",
//       "POLO CLASSIC 1.8 102",
//       "POLO CLASSIC 1.8 10A",
//       "POLO CLASSIC 1.8 10B",
//       "POLO CLASSIC 1.8 11A",
//       "POLO CLASSIC 1.8 31B",
//       "POLO CLASSIC 1.8 M I",
//       "POLO CLASSIC 1.8 MI (2120)",
//       "POLO CLASSIC 1.8 MI (2120)",
//       "POLO CLASSIC 1.8 MI (2120)",
//       "POLO CLASSIC 1.8 MI (2120)",
//       "POLO CLASSIC 1.8 MI 981",
//       "POLO CLASSIC 1.8 MI 98B",
//       "POLO CLASSIC 1.8 MI 98B",
//       "POLO CLASSIC 1.8 MI 99 J",
//       "POLO CLASSIC 1.8 MI 99A",
//       "POLO CLASSIC 1.8 MI 99A",
//       "POLO CLASSIC 1.8 MI 99C",
//       "POLO CLASSIC 1.8 MI 99D",
//       "POLO CLASSIC 1.8 MI 99E",
//       "POLO CLASSIC 1.8 MI 99F",
//       "POLO CLASSIC 1.8 MI 99H",
//       "POLO CLASSIC 1.8 MI 99I",
//       "POLO CLASSIC 1.8 MI 99K",
//       "POLO CLASSIC 1.8 SPORT 99A",
//       "POLO CLASSIC 1.8 SPORT 99A",
//       "POLO CLASSIC 1.8L 30B",
//       "POLO CLASSIC 1.8L 31B",
//       "POLO CLASSIC 1.8L PG2",
//       "POLO CLASSIC 1.9",
//       "POLO CLASSIC 1.9 L TDI 31A",
//       "POLO CLASSIC 1.9 SD",
//       "POLO CLASSIC 1.9 SD 003",
//       "POLO CLASSIC 1.9 SD 302",
//       "POLO CLASSIC 1.9 SD 30A",
//       "POLO CLASSIC 1.9 SD 30B",
//       "POLO CLASSIC 1.9 SD 312",
//       "POLO CLASSIC 1.9 SD 31A",
//       "POLO CLASSIC 1.9 SD 31B",
//       "POLO CLASSIC 1.9 SD 991",
//       "POLO CLASSIC 1.9 SD 991",
//       "POLO CLASSIC 1.9 SD 992",
//       "POLO CLASSIC 1.9 SD 99C",
//       "POLO CLASSIC 1.9 SD 99C",
//       "POLO CLASSIC 1.9 SD 99D",
//       "POLO CLASSIC 1.9 SD 99D",
//       "POLO CLASSIC 1.9 SDI 302",
//       "POLO CLASSIC 1.9 SDI 312",
//       "POLO CLASSIC 1.9 TDI",
//       "POLO CLASSIC 1.9 TDI 99A",
//       "POLO CLASSIC 1.9 TDI 99B",
//       "POLO CLASSIC 1.9L SD 30",
//       "POLO CLASSIC 1.9L SD 3012",
//       "POLO CLASSIC 1.9L SD 31A",
//       "POLO CLASSIC 1.9L SD 31B",
//       "POLO CLASSIC 1.9L SD 3A",
//       "POLO CLASSIC 1.9L TDI 30A",
//       "POLO CLASSIC 1.9L TDI 30B",
//       "POLO CLASSIC 1.9L TDI 31B",
//       "POLO CLASSIC 2.0 (3076) 101",
//       "POLO CLASSIC SD",
//       "POLO CLASSIC SD",
//       "POLO CLASSIC SD 97D",
//       "POLO CLASSIC SD 981",
//       "POLO CLASSIC SD 981",
//       "POLO CLASSIC SD 98C",
//       "POLO CLASSIC SD 98C",
//       "POLO CLASSIC SD 98D",
//       "POLO CLASSIC SD 98D",
//       "POLO COMFORTLINE 1.6 MSI",
//       "POLO COMFORTLINE PLUS 1.6",
//       "POLO GTS AT 1.4 TSI 150CV",
//       "POLO HIGHLINE 1.6 MSI",
//       "POLO HIGHLINE 1.6 MSI",
//       "POLO HIGHLINE AT 1.6 110CV",
//       "POLO MSI 1.6 110CV",
//       "POLO MSI AT 1.6 110CV",
//       "POLO SDI BERLINE",
//       "POLO SERVO",
//       "POLO TREND MSI MT",
//       "POLO TRENDLINE 1.6 MSI",
//       "POLO TRENDLINE 1.6 MSI",
//       "QUANTUM 2000 MI EXCLUSIV",
//       "QUANTUM GLI 2000",
//       "RABBIT",
//       "RABBIT",
//       "RABBIT DIESEL",
//       "ROUTAN 4.0L",
//       "SANTANA 2.0",
//       "SANTANA CLI",
//       "SANTANA GLS",
//       "SAVEIRO",
//       "SAVEIRO 1.6",
//       "SAVEIRO 1.6",
//       "SAVEIRO 1.6",
//       "SAVEIRO 1.6",
//       "SAVEIRO 1.6L GP",
//       "SAVEIRO 1.6L GP",
//       "SAVEIRO 1.6L GP",
//       "SAVEIRO 1.9SD",
//       "SAVEIRO CL",
//       "SAVEIRO CL 1.6D",
//       "SAVEIRO CL 1.6MI",
//       "SAVEIRO CL 1.9SD",
//       "SAVEIRO CLD",
//       "SAVEIRO CLI",
//       "SAVEIRO COMFORTLINE CD",
//       "SAVEIRO CROSS 1.6",
//       "SAVEIRO CROSS 1.6L GP",
//       "SAVEIRO D",
//       "SAVEIRO DC 1.6",
//       "SAVEIRO DC 1.6 CROSS MQ",
//       "SAVEIRO DC 1.6 HIGHLINE",
//       "SAVEIRO EC 1.6",
//       "SAVEIRO GL 1.9 SD",
//       "SAVEIRO HIGHLINE CD MSI",
//       "SAVEIRO OTROS",
//       "SAVEIRO SC 1.6 TRENDLINE",
//       "SAVEIRO TRENDLINE CS MSI",
//       "SCIROCCO 1.4 TSI",
//       "SCIROCCO 1.4 TSI DSG",
//       "SCIROCCO 2.0 TSI",
//       "SCIROCCO 2.0 TSI DSG",
//       "SCIROCCO GTS 2.0 TSI DSG",
//       "SHARAN 1.4 TSI BLUEMOTION",
//       "SHARAN 1.8 T",
//       "SHARAN 1.8 T",
//       "SHARAN 2.0 TSI",
//       "SHARAN 2.8 VR6",
//       "SHARAN 2.8 VR6",
//       "SHARAN CL",
//       "SHARAN TDI",
//       "SHARAN TDI",
//       "SURAN",
//       "SURAN",
//       "SURAN 1.6 16V 5D 055",
//       "SURAN 1.6 16V 5D 159",
//       "SURAN 1.6 16V 5D 188",
//       "SURAN 1.6 16V 5D 229",
//       "SURAN 1.6 16V 5D 250",
//       "SURAN 1.6 16V 5D 348",
//       "SURAN 1.6 16V 5D 414",
//       "SURAN 1.6 16V 5D 428",
//       "SURAN 1.6 16V 5D 442",
//       "SURAN 1.6 16V 5D 641",
//       "SURAN 1.6 16V 5D 769",
//       "SURAN 1.6 16V 5D 988",
//       "SURAN 1.6 16V 5D 993",
//       "SURAN 1.6 16V 5D CROSS 026",
//       "SURAN 1.6 16V 5D CROSS 353",
//       "SURAN 1.6 16V 5D CROSS 572",
//       "SURAN 1.6 16V 5D CROSS 577",
//       "SURAN 1.6 16V 5D CROSS 639",
//       "SURAN 1.6 16V 5D CROSS 772",
//       "SURAN 1.6 16V 5D I-MOTION",
//       "SURAN 1.6 16V 5D I-MOTION",
//       "SURAN 1.6 16V 5D I-MOTION",
//       "SURAN 1.6 16V 5D I-MOTION",
//       "SURAN 1.6 16V 5D I-MOTION",
//       "SURAN 1.6 16V 5D I-MOTION",
//       "SURAN 1.6 16V 5P I-MOTION",
//       "SURAN 1.6 16V 5P I-MOTION",
//       "SURAN 1.6 16V 5P I-MOTION",
//       "SURAN 1.6 16V 5P I-MOTION",
//       "SURAN 1.6 16V 5P I-MOTION",
//       "SURAN 1.6 16V 5P I-MOTION",
//       "SURAN 1.6 5D 057",
//       "SURAN 1.6 5D 060",
//       "SURAN 1.6 5D 156",
//       "SURAN 1.6 5D 175",
//       "SURAN 1.6 5D 212",
//       "SURAN 1.6 5D 263",
//       "SURAN 1.6 5D 591",
//       "SURAN 1.6 5D 618",
//       "SURAN 1.6 5D 682",
//       "SURAN 1.6 5D 768",
//       "SURAN 1.6 5D 780",
//       "SURAN 1.6 5D 823",
//       "SURAN 1.6 5D 860",
//       "SURAN 1.6 5D 911",
//       "SURAN 1.6 5D 912",
//       "SURAN 1.6 5D 921",
//       "SURAN 1.6 5D 943",
//       "SURAN 1.6 5D 970",
//       "SURAN 1.6L 5D 031",
//       "SURAN 1.6L 5D 1200/11A",
//       "SURAN 1.6L 5D 1200/11B",
//       "SURAN 1.6L 5D 1200/11C",
//       "SURAN 1.6L 5D 1200/11D",
//       "SURAN 1.6L 5D 1200/21A",
//       "SURAN 1.6L 5D 1200/21B",
//       "SURAN 1.6L 5D 1200/21C",
//       "SURAN 1.6L 5D 166",
//       "SURAN 1.6L 5D 199",
//       "SURAN 1.6L 5D 586",
//       "SURAN 1.6L 5D 604",
//       "SURAN 1.6L 5D 614",
//       "SURAN 1.6L 5D 618",
//       "SURAN 1.6L 5D 661",
//       "SURAN 1.6L 5D 679",
//       "SURAN 1.6L 5D 700",
//       "SURAN 1.6L 5D 717",
//       "SURAN 1.6L 5D 734",
//       "SURAN 1.6L 5D 755",
//       "SURAN 1.6L 5D 766",
//       "SURAN 1.6L 5D 766",
//       "SURAN 1.6L 5D 768",
//       "SURAN 1.6L 5D 792",
//       "SURAN 1.6L 5D 796",
//       "SURAN 1.6L 5D 807",
//       "SURAN 1.6L 5D 814",
//       "SURAN 1.6L 5D 870",
//       "SURAN 1.6L 5D 891",
//       "SURAN 1.6L 5D 90F",
//       "SURAN 1.6L 5D 923",
//       "SURAN 1.6L 5D I-MOTION",
//       "SURAN 1.6L 5D I-MOTION",
//       "SURAN 1.6L 5D I-MOTION",
//       "SURAN 1.6L 5D I-MOTION",
//       "SURAN 1.6L 5D I-MOTION",
//       "SURAN 1.6L 5D I-MOTION",
//       "SURAN 1.6L 5D I-MOTION",
//       "SURAN 1.6L 5D I-MOTION",
//       "SURAN 1.6L 5D I-MOTION",
//       "SURAN 1.6L 5D I-MOTION 414",
//       "SURAN 1.6L 5D I-MOTION 437",
//       "SURAN 1.6L 5D I-MOTION 766",
//       "SURAN 1.6L 5D I-MOTION 946",
//       "SURAN 1.6L 5D I-MOTION 964",
//       "SURAN 1.6L 5D TRACK 350",
//       "SURAN 1.6L 5D1200/21D",
//       "SURAN 1.6L 5P 766",
//       "SURAN 1.6L 5P 814",
//       "SURAN 1.6L 90T",
//       "SURAN 60A",
//       "SURAN 60B",
//       "SURAN 60D",
//       "SURAN 60D",
//       "SURAN 60E",
//       "SURAN 80A",
//       "SURAN 80B",
//       "SURAN 80D",
//       "SURAN 80E",
//       "SURAN 90A",
//       "SURAN 90B",
//       "SURAN 90D",
//       "SURAN 90E",
//       "SURAN CROSS 1.6L 5 DOOR",
//       "SURAN CROSS 1.6L 5 DOOR",
//       "SURAN CROSS 1.6L 5 DOOR",
//       "SURAN CROSS 1.6L 5",
//       "SURAN CROSS 1.6L 5D",
//       "SURAN SDI",
//       "SURAN SDI 80A",
//       "SURAN SDI 80B",
//       "SURAN SDI 80D",
//       "SURAN SDI 80E",
//       "SURAN SDI 90A",
//       "SURAN SDI 90B",
//       "SURAN SDI 90D",
//       "SURAN SDI 90E",
//       "TAKE UP! 1.0 MPI",
//       "TAKE UP! 1.0 MPI",
//       "TAOS COMFORTLINE 250 TSI",
//       "TAOS HIGHLINE 250 TSI AT",
//       "TAOS HIGHLINE BI TONO 250",
//       "TAOS HIGHLINE HERO 250 TSI",
//       "T-CROSS 170TSI MT",
//       "T-CROSS COMFORTLINE 1.6",
//       "T-CROSS COMFORTLINE 1.6",
//       "T-CROSS COMFORTLINE 200",
//       "T-CROSS HIGHLINE 1.6 MSI",
//       "T-CROSS HIGHLINE 200 TSI AT",
//       "T-CROSS HIGHLINE HERO CAR",
//       "T-CROSS HIGHLINE STYLE",
//       "T-CROSS TRENDLINE 1.6 MSI",
//       "THE BEETLE 1.4 TSI DESIGN",
//       "THE BEETLE 1.4 TSI DESIGN",
//       "THE BEETLE 2.0 TSI SPORT",
//       "THE BEETLE 2.0 TSI SPORT",
//       "THE BEETLE 2.0 TSI SPORT",
//       "THE BEETLE 2.0 TSI SPORT DQ",
//       "THE BEETLE 2.0 TSI SPORT MQ",
//       "THE BEETLE 2.0 TSI SPORT MQ",
//       "TIGUAN",
//       "TIGUAN",
//       "TIGUAN 1.4 TSI",
//       "TIGUAN 1.4 TSI DSG",
//       "TIGUAN 2.0 TDI",
//       "TIGUAN 2.0 TDI 4 MOTION",
//       "TIGUAN 2.0 TSI",
//       "TIGUAN 2.0 TSI",
//       "TIGUAN 2.0 TSI 4 MOTION",
//       "TIGUAN 2.0T",
//       "TIGUAN 250TSI DSG",
//       "TIGUAN ALLSPACE 1.4TSI DSG",
//       "TIGUAN COMFORTLINE 2.0",
//       "TIGUAN COMFORTLINE",
//       "TIGUAN HIGHLINE 2.0 TSI",
//       "TIGUAN HIGHLINE 2.0TDI",
//       "TIGUAN HIGHLINE 350TSI",
//       "TIGUAN LIFE 350TSI DSG 4X4",
//       "TIGUAN S 2.0",
//       "TIGUAN TRENDLINE 1.4TSI",
//       "TOUAREG",
//       "TOUAREG 2.5 R5 TDI",
//       "TOUAREG 3.0 V6 TDI",
//       "TOUAREG 3.0 V6 TDI",
//       "TOUAREG 3.0 V6 TDI",
//       "TOUAREG 3.2 V6",
//       "TOUAREG 3.6 V6",
//       "TOUAREG 3.6 V6 FSI",
//       "TOUAREG 4.2 V8",
//       "TOUAREG 4.2 V8 FSI",
//       "TOUAREG 5.0 V10 TDI",
//       "TOUAREG HYBRID",
//       "TOURAN 1.9 TDI",
//       "TRANSPORTER",
//       "TRANSPORTER",
//       "TRANSPORTER",
//       "TRANSPORTER",
//       "TRANSPORTER 1.9 TDI",
//       "TRANSPORTER 2.0 TDI",
//       "TRANSPORTER T5",
//       "VENTO 1.4TSI BLUEMOTION",
//       "VENTO 1.9TDI",
//       "VENTO 2.0",
//       "VENTO 2.0 TDI",
//       "VENTO 2.5",
//       "VENTO 2.5 STYLE PLUS",
//       "VENTO CONFORTLINE 2.0 AUT",
//       "VENTO HIGHLINE 250TSI AT",
//       "VENTO TRENDLINE ENTRY 2.0",
//       "VIRTUS COMFORTLINE 1.6",
//       "VIRTUS COMFORTLINE 1.6",
//       "VIRTUS COMFORTLINE AT 1.6",
//       "VIRTUS GTS AT 1.4 TSI 150CV",
//       "VIRTUS HIGHLINE 1.6 MSI",
//       "VIRTUS HIGHLINE 1.6 MSI",
//       "VIRTUS HIGHLINE AT 1.6",
//       "VIRTUS MSI 1.6 110CV",
//       "VIRTUS MSI AT 1.6 110CV",
//       "VIRTUS TRENDLINE 1.6 MSI",
//       "VIRTUS TRENDLINE 1.6 MSI",
//       "VOLKSWAGEN VENTO 1.4TSI",
//       "VOLKSWAGEN VENTO 1.9 TDI",
//       "VOLKSWAGEN VENTO 1.9TDI",
//       "VOLKSWAGEN VENTO 2.0",
//       "VOLKSWAGEN VENTO 2.0 FSI",
//       "VOLKSWAGEN VENTO 2.0 TDI",
//       "VOLKSWAGEN VENTO 2.0 TSI",
//       "VOLKSWAGEN VENTO 2.0L",
//       "VOLKSWAGEN VENTO 2.0T FSI",
//       "VOLKSWAGEN VENTO 2.0TDI",
//       "VOLKSWAGEN VENTO 2.5",
//       "VOLKSWAGEN VENTO 2.5",
//       "VOLKSWAGEN VENTO",
//       "VOLKSWAGEN VENTO GLI",
//       "VOLKSWAGEN VENTO",
//       "VOYAGE 1.6",
//       "VOYAGE 1.6 MSI",
//       "VOYAGE 1.6I MOTION",
//       "VOYAGE TRENDLINE 1.6 GAS",
//       "WHITE UP 1.0 MPI",
//       "WHITE UP! 1.0 MPI",
//       "13.190",
//       "GOL TREND 1.6 GP",
//       "GOL TREND 1.6 GP I MOTION",
//       "POLO 1.9 TDI",
//       "TOUAREG 3.0 TDI",
//       "TOUAREG 3.6 FSI",
//       "TOUAREG 3.6 V6 FSI",
//       "TOUAREG 4.2 V8 FSI",
//       "TOUAREG 4.2 V8 FSI",
//       "VOYAGE 1.6 GP",
//       "VOYAGE 1.6 GP I MOTION"
//     ],
//     "VOLVO": [
//       "B 290 R 4X2",
//       "B380R 4X2",
//       "B450R 6X2",
//       "C30 2.0",
//       "C30 2.4I AT",
//       "C30 2.4I MT",
//       "C30 T5 AT",
//       "C30 T5 MT",
//       "C70",
//       "C70",
//       "C70 T5",
//       "FH 12",
//       "FH 12",
//       "FH 400 4X2 R",
//       "FH 400 4X2 R",
//       "FH 400 4X2 T",
//       "FH 400 4X2 T",
//       "FH 400 6X4 R",
//       "FH 400 6X4 R",
//       "FH 400 6X4 T",
//       "FH 400 6X4 T",
//       "FH 420 4X2 R",
//       "FH 420 4X2 R",
//       "FH 420 4X2 T",
//       "FH 420 4X2 T",
//       "FH 420 6X2 R",
//       "FH 420 6X2 T",
//       "FH 420 6X2 T",
//       "FH 420 6X4T",
//       "FH 440 4X2 T",
//       "FH 440 4X2 T",
//       "FH 440 6X4 T",
//       "FH 440 6X4 T",
//       "FH 460 4X2 R",
//       "FH 460 4X2 R",
//       "FH 460 4X2 T",
//       "FH 460 4X2 T",
//       "FH 460 6X2T",
//       "FH 460 6X4 T",
//       "FH 460 6X4 T",
//       "FH 480 4X2 R",
//       "FH 480 4X2 R",
//       "FH 480 4X2 T",
//       "FH 480 4X2 T",
//       "FH 500 4X2R",
//       "FH 500 4X2T",
//       "FH 500 4X2T",
//       "FH 500 6X2R",
//       "FH 500 6X2T",
//       "FH 520 4X2 T",
//       "FH 520 4X2 T",
//       "FH 520 6X4 T",
//       "FH 520 6X4 T",
//       "FH 540 4X2 R",
//       "FH 540 4X2 R",
//       "FH 540 4X2 T",
//       "FH 540 4X2 T",
//       "FH 540 6X2 T",
//       "FH 540 6X2 T",
//       "FH 540 6X4 T",
//       "FH 540 6X4 T",
//       "FH12 340 4X2",
//       "FH12 380 4X2",
//       "FH12 380 4X2",
//       "FH12 380 4X2",
//       "FH12 380 6X2",
//       "FH12 380 6X4",
//       "FH12 380 6X4",
//       "FH12 380/3700",
//       "FH12 380/3700",
//       "FH12 420 4X2",
//       "FH12 420 4X2",
//       "FH12 420 4X2",
//       "FH12 420 6X4",
//       "FH12 420 6X4",
//       "FH12 460 4X2",
//       "FH12 460 4X2",
//       "FH12 460 6X4",
//       "FH12 460 6X4",
//       "FH12-380/4600 4X2",
//       "FH12-420/3700-",
//       "FH400 6X2 T",
//       "FH400 6X2 T",
//       "FH440 4X2R",
//       "FH440 4X2R",
//       "FH440 6X2 T",
//       "FH440 6X2 T",
//       "FH440 6X2 T",
//       "FH520 4X2 R",
//       "FH520 4X2 R",
//       "FM 11 4X2 T",
//       "FM 11 6X2 T",
//       "FM 330 6X2 R",
//       "FM 330 6X2 R",
//       "FM 330 6X2T",
//       "FM 330 6X4 R",
//       "FM 330 6X4 R",
//       "FM 330 8X4 R",
//       "FM 370 4X2 R",
//       "FM 370 4X2 T",
//       "FM 370 4X2 T",
//       "FM 370 4X2 T",
//       "FM 370 6X2 T",
//       "FM 370 6X2 T",
//       "FM 370 6X2R",
//       "FM 370 6X4R",
//       "FM 380 4X2 R",
//       "FM 380 4X2 R",
//       "FM 380 4X2 T",
//       "FM 380 4X2 T",
//       "FM 380 6X2 T",
//       "FM 380 6X2 T",
//       "FM 380 6X4R",
//       "FM 380 6X4T",
//       "FM 400",
//       "FM 400 4X2 R",
//       "FM 400 4X2 R",
//       "FM 400 4X2 T",
//       "FM 400 4X2 T",
//       "FM 400 6X2 T",
//       "FM 400 6X4 R",
//       "FM 400 6X4 R",
//       "FM 400 6X4 R",
//       "FM 400 6X4 R",
//       "FM 400 6X4 T",
//       "FM 400 6X4 T",
//       "FM 410 6X2T",
//       "FM 420 4X2 R",
//       "FM 420 4X2 R",
//       "FM 420 4X2 T",
//       "FM 420 4X2 T",
//       "FM 420 6X2 T",
//       "FM 420 6X2 T",
//       "FM 420 6X4T",
//       "FM 440 6X4 R",
//       "FM 440 6X4 R",
//       "FM 440 6X4 R",
//       "FM 440 6X4 R",
//       "FM 440 6X4 T",
//       "FM 440 6X4 T",
//       "FM 440 8X4 R",
//       "FM 440 8X4 R",
//       "FM 440 8X4 R",
//       "FM 440 8X4 R",
//       "FM 450 HP 6x2 RIGID TAG",
//       "FM 460 6X4 R",
//       "FM 460 6X4 R",
//       "FM 460 6X4 T",
//       "FM 460 8X4 R",
//       "FM 460 8X4 R",
//       "FM 480 6X4 R",
//       "FM 480 6X4 R",
//       "FM 480 8X4 R",
//       "FM 480 8X4 R",
//       "FM 480 8X4 R",
//       "FM 480 8X4 R",
//       "FM12 340 4X2",
//       "FM12 340 4X2",
//       "FM12 420 6X4",
//       "FM12 420 6X4",
//       "FM12 420 6X4",
//       "FM370 6X4R",
//       "FM370 6X4R",
//       "FM370 6X4T",
//       "FM370 6X4T",
//       "FM9 300",
//       "NH12 380 4X2",
//       "NH12 380 4X2",
//       "NH12 420 4X2",
//       "NH12 420 4X2",
//       "NH12 460 4X2",
//       "NH12 460 4X2",
//       "NH12 460 6X4",
//       "NH12 460 6X4",
//       "NH12-380/4300 (4X2T)",
//       "NH12-380/4300",
//       "NH12-380/5600-4X2R",
//       "NH12-420/5600",
//       "NL 10 320",
//       "NL 12 360",
//       "NL10-320/4200 EDC",
//       "NL10-320/4200 EDC",
//       "NL10-320/4200 EDC",
//       "NL10-320/4250 EDC",
//       "NL10-320/4600 EDC",
//       "NL10-320/4600 EDC",
//       "NL10-320/4600 EDC",
//       "NL10-320/5400 EDC",
//       "NL10-320/5400 EDC",
//       "NL10-320/5800 EDC",
//       "NL10-320/5800 EDC",
//       "NL-12",
//       "NL12-360 / 4600 EDC 4X2T",
//       "NL12-360/4200 EDC",
//       "NL12-360/4200 EDC",
//       "NL12-360/4200 EDC",
//       "NL12-360/4250 EDC",
//       "NL12-360/4600 EDC",
//       "NL12-360/4600 EDC",
//       "NL12-360/5400 EDC",
//       "NL12-410/4200 EDC",
//       "NL12-410/4200 EDC",
//       "NL12-410/4200 EDC",
//       "NL12-410/4250 EDC",
//       "NL12-410/4600 EDC",
//       "NL12-410/4600 EDC",
//       "NL12-410/5400",
//       "NL12-410/5400 EDC",
//       "NL12-410/5400 EDC",
//       "S40 1.8",
//       "S40 1.8",
//       "S40 1.9T",
//       "S40 2.0",
//       "S40 2.0T",
//       "S40 2.4",
//       "S40 2.4I AT",
//       "S40 2.4I MT",
//       "S40 T4",
//       "S40 T5",
//       "S40 T5 ELEGANCE",
//       "S40TD",
//       "S60 2.0T",
//       "S60 2.4",
//       "S60 2.4 T",
//       "S60 2.5 T",
//       "S60 D5",
//       "S60 R",
//       "S60 T4",
//       "S60 T5",
//       "S60 T6",
//       "S70 2.5",
//       "S70 2.5 20V",
//       "S70 2.5 D",
//       "S70 2.5T",
//       "S70 T5",
//       "S80 2.4",
//       "S80 2.9",
//       "S80 3.2",
//       "S80 T6",
//       "S80 V8",
//       "S80 X 2.9",
//       "S90 T6 INSCRIPTION AWD",
//       "S90 T6 INSCRIPTION AWD",
//       "V40",
//       "V40 1.8",
//       "V40 1.9D",
//       "V40 2.0",
//       "V40 2.0T",
//       "V40 CROSS COUNTRY T4 AWD",
//       "V40 T4",
//       "V40 T4",
//       "V40 XC CC T4",
//       "V40TD",
//       "V50 2.0",
//       "V50 2.0D",
//       "V50 2.4I",
//       "V50 2.4I AT",
//       "V50 T5",
//       "V60 T6",
//       "V60 T6",
//       "V70 2.0",
//       "V70 2.4",
//       "V70 2.4T",
//       "V70 2.5",
//       "V70 2.5 20V",
//       "V70 2.5T",
//       "V70 3.2",
//       "V70 AWD",
//       "V70 D5",
//       "V70 R",
//       "V70 R AWD",
//       "V70 T5",
//       "V70 XC AWD",
//       "VM 220 4X2 R",
//       "VM 220 4X2 R",
//       "VM 220 6X2 R",
//       "VM 220 6X2 R",
//       "VM 270 4X2 R",
//       "VM 270 4X2 R",
//       "VM 270 6X2 R",
//       "VM 270 6X2 R",
//       "VM 270 6X4 R",
//       "VM 270 6X4 R",
//       "VM 270 8X4 R",
//       "VM 270 8X4 R",
//       "VM 330 4X2 R",
//       "VM 330 4X2 R",
//       "VM 330 4X2 T",
//       "VM 330 4X2 T",
//       "VM 330 6X4 R",
//       "VM 330 6X4 R",
//       "VM 330 8X2 R",
//       "VM 330 8X4 R",
//       "VM 330 8X4 R",
//       "VM17 210 4X2",
//       "VM17 210 4X2",
//       "VM17 240 4X2",
//       "VM17 4X2",
//       "VM210 4X2 R",
//       "VM210 4X2 R",
//       "VM210 6X2 R",
//       "VM210 6X2 R",
//       "VM210 6X4R",
//       "VM23 210 6X2",
//       "VM23 240 6X2",
//       "VM23 6X2 R",
//       "VM260 4X2 R",
//       "VM260 4X2 R",
//       "VM260 6X2 R",
//       "VM260 6X2 R",
//       "VM260 6X4 R",
//       "VM260 6X4 R",
//       "VM310 4X2 T",
//       "VM310 4X2 T",
//       "VM310 6X4 R",
//       "VM310 6X4 R",
//       "VM310 6X4 R",
//       "VM310 6X4 R",
//       "VM330 6X2 R",
//       "VM330 6X2 R",
//       "VNL",
//       "VNL 610",
//       "VNL 610",
//       "VNL 610",
//       "VNL 630",
//       "VNL 64T",
//       "VNL 64T",
//       "VNL 64T",
//       "VNL 64T610 8X4",
//       "VNL 64T670 8X4",
//       "VNL 64T780 8X4",
//       "VNL 670",
//       "VNL 670",
//       "VNL 780",
//       "VNL660",
//       "XC 40 T4",
//       "XC 90 D5",
//       "XC40 T4 AWD",
//       "XC40 T5 AWD",
//       "XC40 T5 AWD R-DESIGN",
//       "XC60 2.0T",
//       "XC60 T5",
//       "XC60 T5 AWD",
//       "XC60 T6",
//       "XC60 T6 INSCRIPTION AWD",
//       "XC70 2.4T",
//       "XC70 2.5T AWD",
//       "XC70 3.2",
//       "XC70 3.2",
//       "XC70 3.2 AWD",
//       "XC70 D5 AWD",
//       "XC70 T6",
//       "XC90",
//       "XC90 2.4",
//       "XC90 2.5",
//       "XC90 2.5T AWD",
//       "XC90 3.2 AWD",
//       "XC90 D5 AWD",
//       "XC90 T5 2.5",
//       "XC90 T6 AWD",
//       "XC90 T6 AWD INSCRIPTION",
//       "XC90 V8 AWD",
//       "FM370 6X4 R",
//       "B270 F 4X2",
//       "FM 440 4X2R",
//       "FM370 6X4 R"
//     ],
//     "WULING": ["LZW1010PL", "LZW1010VHW", "LZW6320H"],
//     "YAMAHA": [
//       "900 ST",
//       "BLASTER - YFS200",
//       "BLASTER YFS2006",
//       "CRYPTON",
//       "CYGNUS RAY ZR",
//       "FASCINO 125 FI",
//       "FAZER 600 FZ6",
//       "FAZER 600 FZ6T",
//       "FAZER FI",
//       "FAZER FZ8 SA",
//       "FJR 1300",
//       "FJR1300A",
//       "FJR1300AS",
//       "FZ 16",
//       "FZ 16",
//       "FZ FI",
//       "FZ FI",
//       "FZ1",
//       "FZ1 N",
//       "FZ1 SA",
//       "FZ16 ST",
//       "FZ1N FAZER",
//       "FZ1S",
//       "FZ25",
//       "FZ25",
//       "FZ6",
//       "FZ6 NAHG",
//       "FZ6-N",
//       "FZ6NHG",
//       "FZ6-S",
//       "FZ6SAHG",
//       "FZ6SHG",
//       "FZ8 NA",
//       "FZ8-N",
//       "FZ8-N",
//       "FZ8-S",
//       "FZ8-S",
//       "FZ-S FI",
//       "FZ-S FI",
//       "FZ-S FI D",
//       "GRIZZLY 350",
//       "GRIZZLY 350 4X4",
//       "GRIZZLY 700 FI AUTO 4X4",
//       "MT 03A",
//       "MT 07",
//       "MT 07 A",
//       "MT 09",
//       "MT 09 ABS",
//       "MT 09 TRACER",
//       "MT 10",
//       "MT01",
//       "MT03",
//       "MT-07 TRACER",
//       "MT-07ST",
//       "MT-09ST",
//       "NMAX CONNECTED",
//       "NM-X",
//       "PW50",
//       "R15",
//       "R6",
//       "RAPTOR 250",
//       "RAPTOR 250R",
//       "RAPTOR 700",
//       "RAPTOR 700R",
//       "RAY ZR 125 FI",
//       "RAY ZR 125 FI",
//       "ROAD STAR 1700",
//       "ROYAL STAR 1300",
//       "ROYAL STAR 1300 MIDNIGHT",
//       "ROYAL STAR 1300 VENTURE",
//       "ROYAL STAR 1300A",
//       "SUPER TENERE",
//       "SZ15RR",
//       "T105 CRYPTON",
//       "T105E CRYPTON",
//       "T110",
//       "T110 CRYPTON",
//       "T110C",
//       "TDM900",
//       "TDM900A",
//       "TENERE ADVENTURE",
//       "TTR 125EV",
//       "TTR 125V",
//       "TTR125",
//       "TTR125E",
//       "TTR125LW",
//       "TTR125LWE",
//       "TTR230",
//       "TTR230T",
//       "TTR230W",
//       "TZ125",
//       "TZR 125",
//       "TZR 125 R",
//       "TZR 125 SP",
//       "V STAR",
//       "V STAR 1300",
//       "V STAR 950",
//       "V STAR CLASSIC",
//       "V-MAX",
//       "V-MAX 1200",
//       "WARRIOR ELECTRIC 350",
//       "WR250F",
//       "WR250R",
//       "WR250X",
//       "WR450F",
//       "XJ6-F DIVERSION",
//       "XJ6-N",
//       "XJ6-S DIVERSION",
//       "XJR 1300",
//       "XP 500",
//       "XSR900",
//       "XT 225",
//       "XT225 4-STROKE",
//       "XT225 G",
//       "XT225D",
//       "XT600A",
//       "XT600E",
//       "XT600ED",
//       "XT660R",
//       "XT660X",
//       "XT660Z",
//       "XTZ 125",
//       "XTZ 125 E",
//       "XTZ 125E",
//       "XTZ 250",
//       "XTZ125E",
//       "XTZ125K",
//       "XTZ150",
//       "XTZ250",
//       "XTZ250",
//       "XTZ250 LANDER",
//       "XTZ250ABS",
//       "XV1900A MIDNIGTH STAR",
//       "XV950 BOLT",
//       "XV950R",
//       "XVA1100 V-STAR CUSTOM",
//       "XVS 65 V-STAR CLASSIC",
//       "XVS11 V-STAR",
//       "XVS11 V-STAR CLASSIC",
//       "XVS1100",
//       "XVS1100 V-STAR CLASSIC",
//       "XVS1100A",
//       "XVS1300A MIDNIGHT STAR",
//       "XVS65 V-STAR",
//       "XVS650",
//       "XVS650 V-STAR CUSTOM",
//       "XVS650A",
//       "XVS950A",
//       "XVS950CUD A",
//       "XVZ1300AT",
//       "YBR 125",
//       "YBR 125 ED",
//       "YBR 125 R",
//       "YBR 125ED",
//       "YBR 250",
//       "YBR125",
//       "YBR125 4-STROKE",
//       "YBR125E",
//       "YBR125ED",
//       "YBR125K",
//       "YBR250",
//       "YBRZ",
//       "YBRZ",
//       "YF 200 BLASTER",
//       "YFM 80F",
//       "YFM125A",
//       "YFM125GBL",
//       "YFM125RAW",
//       "YFM250",
//       "YFM250",
//       "YFM250R",
//       "YFM25BYB",
//       "YFM25BYGR",
//       "YFM25RAL",
//       "YFM25RAW",
//       "YFM25RSP2X",
//       "YFM25RSPA",
//       "YFM25RXGY",
//       "YFM25RXL",
//       "YFM25RZW",
//       "YFM25XLE",
//       "YFM25XLGR",
//       "YFM25XNE",
//       "YFM300",
//       "YFM350 WARRIOR",
//       "YFM350 XF",
//       "YFM350A",
//       "YFM350AS",
//       "YFM350ER",
//       "YFM350FW",
//       "YFM350FW",
//       "YFM350FWA",
//       "YFM350FWB",
//       "YFM350FWHC",
//       "YFM350FWX",
//       "YFM350FWX",
//       "YFM350FXK",
//       "YFM350GW",
//       "YFM350R",
//       "YFM350U",
//       "YFM350X",
//       "YFM350X",
//       "YFM350XE",
//       "YFM350XG",
//       "YFM350XH",
//       "YFM350XJL",
//       "YFM350XJY",
//       "YFM350XKGY",
//       "YFM350XKR",
//       "YFM35FGAGR",
//       "YFM35FGAL",
//       "YFM35FGDGR",
//       "YFM35FGHX",
//       "YFM35FGIAG",
//       "YFM35FGIHY",
//       "YFM35FGIWR",
//       "YFM35FGIYB",
//       "YFM35FGXL",
//       "YFM35FGYL",
//       "YFM35GAL",
//       "YFM35GXGR",
//       "YFM35GYGR",
//       "YFM35RAL",
//       "YFM35RDW",
//       "YFM35RLX",
//       "YFM35RSE2W",
//       "YFM35RWL",
//       "YFM35RXGY",
//       "YFM35RXL",
//       "YFM35RYW",
//       "YFM35RZW",
//       "YFM35XHGR",
//       "YFM35XXGY",
//       "YFM35XYL",
//       "YFM400FWAN",
//       "YFM400FWAN",
//       "YFM450FWA",
//       "YFM450FWAN GRIZZLY",
//       "YFM450FWAN VERSION",
//       "YFM550F",
//       "YFM550FWA GRIZZLY",
//       "YFM5FGYL",
//       "YFM660",
//       "YFM660",
//       "YFM660 GRIZZLY",
//       "YFM660FP",
//       "YFM660FWA",
//       "YFM660FWA",
//       "YFM660R",
//       "YFM660R",
//       "YFM660RN",
//       "YFM700",
//       "YFM700 R",
//       "YFM700FWAD",
//       "YFM700R",
//       "YFM700R",
//       "YFM700R SE",
//       "YFM700RSEL",
//       "YFM700RSES",
//       "YFM70RSEY",
//       "YFM70RSPX RAPTOR",
//       "YFM70RXGY",
//       "YFM70RXL",
//       "YFM7FGBL",
//       "YFM7FGHA",
//       "YFM7FGHY",
//       "YFM7FGHZ",
//       "YFM7FGPAGR",
//       "YFM7FGPAL",
//       "YFM7FGPBGR",
//       "YFM7FGPHA",
//       "YFM7FGPHB",
//       "YFM7FGPHY",
//       "YFM7FGPSED",
//       "YFM7FGPSEY",
//       "YFM7FGPSPA",
//       "YFM7FGPSPX",
//       "YFM7FGPYB",
//       "YFM7FGXL",
//       "YFM7FGYB",
//       "YFM7FGYL",
//       "YFM7FGZL",
//       "YFM7RAL",
//       "YFM7RAW",
//       "YFM7RDW",
//       "YFM7RSE",
//       "YFM7RSEA",
//       "YFM7RSED",
//       "YFM7RSEDB",
//       "YFM7RSEZ",
//       "YFM80",
//       "YFM-80",
//       "YFM-80",
//       "YFM80F",
//       "YFM80G",
//       "YFM80GHL",
//       "YFM80GHX",
//       "YFM80GWL",
//       "YFM80GXGR",
//       "YFM80H",
//       "YFM80-J",
//       "YFM80R",
//       "YFM80RVL",
//       "YFM80RVW",
//       "YFM80RXL",
//       "YFM90R",
//       "YFS 200 F",
//       "YFS 200 G",
//       "YFS200",
//       "YFS200",
//       "YFS200 F-PP",
//       "YFS-200 G",
//       "YFS-200 G",
//       "YFS200 G-PP",
//       "YFS200 G-PP",
//       "YFS200F-GR",
//       "YFS200G-L",
//       "YFS200G-L",
//       "YFS200GN",
//       "YFS200-GR",
//       "YFS200H",
//       "YFS200H-B",
//       "YFS200H-L",
//       "YFS200J-R",
//       "YFS200J-Y",
//       "YFS200K",
//       "YFS200K-L",
//       "YFS200K-R",
//       "YFZ 350 F",
//       "YFZ 450",
//       "YFZ350",
//       "YFZ350",
//       "YFZ350",
//       "YFZ350G",
//       "YFZ350G",
//       "YFZ350H",
//       "YFZ350H-R",
//       "YFZ350H-W",
//       "YFZ350J-B",
//       "YFZ350J-R",
//       "YFZ450",
//       "YFZ450R",
//       "YFZ450R SPECIAL EDITION",
//       "YFZ450RAL",
//       "YFZ450RSEY",
//       "YFZ450RSEZ",
//       "YFZ450RYL",
//       "YFZ450RYW",
//       "YFZ450RZL",
//       "YFZ450SL",
//       "YFZ450SP2X",
//       "YFZ450SPV",
//       "YFZ450SPX",
//       "YFZ450SPX RAPTOR",
//       "YFZ450VL",
//       "YFZ450WGY",
//       "YFZ450WL",
//       "YFZ450X",
//       "YFZ450XBBZ",
//       "YFZ450XGY",
//       "YFZ450XL",
//       "YFZ450XZL",
//       "YFZ450XZW",
//       "YFZ450YL",
//       "YFZ450YW",
//       "YFZ45BB",
//       "YFZ45BW",
//       "YFZ45FGYB",
//       "YFZ45RBL",
//       "YFZ45RBW",
//       "YFZ45RSED",
//       "YP400 MAJESTY",
//       "YS250 FAZER",
//       "YS250 FAZER",
//       "YXR700F RHINO",
//       "YXR700F RHINO",
//       "YZ 125",
//       "YZ 125 G1",
//       "YZ 125G",
//       "YZ 250 G1",
//       "YZ125F1",
//       "YZ250",
//       "YZ250",
//       "YZ250F",
//       "YZ250FX",
//       "YZ250X",
//       "YZ426F",
//       "YZ450F",
//       "YZ450FX",
//       "YZ85",
//       "YZ85LW",
//       "YZF R",
//       "YZF R1M",
//       "YZF R3 GP",
//       "YZF R3A",
//       "YZF250R",
//       "YZF-R1",
//       "YZF-R6",
//       "YFM35FGBGR",
//       "YFM35FGBL",
//       "YFM35FGHB",
//       "YFM35FGIAL",
//       "YFM35XVL",
//       "YFM45FGAL",
//       "YFM45FGHB",
//       "YFM5FGHA",
//       "YFM7FGPSEZ",
//       "YFM7RSEB",
//       "YFZ450XAL",
//       "YFZ45BWE",
//       "YFZ45RDL",
//       "YFZ45RDW",
//       "YFZ45RSEB"
//     ],
//     "YUEJIN": [
//       "HQ1608",
//       "NJ 1041 DER",
//       "NJ1026 DS (CABINA Y DOBLE)",
//       "NJ1026 DS (CABINA Y DOBLE)",
//       "NJ1026 DW (CABINA Y",
//       "NJ1026 DW (CABINA Y",
//       "NJ1030DA",
//       "NJ1030DAS (CABINA DOBLE)",
//       "NJ1035DER",
//       "NJ1041 DE",
//       "NJ1041 DERS",
//       "NJ1061 DER",
//       "NJ1062 DC",
//       "NJ1062 DCW",
//       "NJ1062 DE",
//       "NJ1062 DE",
//       "NJ1062DC (CABINA SIMPLE)",
//       "NJ1062DCW (CABINA Y",
//       "NJ1062DEW",
//       "NJ3061 DE",
//       "NJ3062 DCW",
//       "NJ3062 DEW",
//       "NJ3062DE",
//       "NJ6604D",
//       "NJ6700D"
//     ],
//     "ZANELLA": [
//       "CECCATO R 150",
//       "CECCATO R 150",
//       "CECCATO V 250 I",
//       "CECCATO X",
//       "CICL. N-FIRE 50 PLUS",
//       "CICL. SOL LA PLUS",
//       "CICL. SOL TOP",
//       "CICL. SOL TOP",
//       "CICL.SOL LA PLUS",
//       "CICLOMOTOR FIRE",
//       "CICLOMOTOR FIRE",
//       "CICLOMOTOR FIRE VR",
//       "CICLOMOTOR FIRE VR",
//       "CICLOMOTOR NEW FIRE",
//       "CICLOMOTOR NEW FIRE",
//       "CICLOMOTOR SOL LA",
//       "CICLOMOTOR SOL LA",
//       "CRUISER X",
//       "CRUISER X",
//       "CRUISER X1",
//       "CUATRICICLO EXPLORER 250",
//       "CUSTOM 125",
//       "CUSTOM 150",
//       "DELIBERY",
//       "DELIVERY 100",
//       "DELIVERY 90 4V",
//       "DIRTBIKE",
//       "DUE",
//       "DUE 110 LUXURY",
//       "DUE 110 SPORT",
//       "DUE 125 SPORT",
//       "DUE 3V",
//       "DUE CLASSIC 110",
//       "DUE CLASSIC AUTOMATICA",
//       "DUE GL",
//       "DUE GL C/ALEACION",
//       "E STYLER",
//       "EXPLORER 200 C.C.",
//       "EXPLORER ZCT 110",
//       "FX 110",
//       "FX 110",
//       "FX 110 SERIES",
//       "FX 125",
//       "FX 125",
//       "FX 125 MAD MAX",
//       "FX 150",
//       "FX 150",
//       "FX 150 AUT.",
//       "FX 150 AUTOMATIC",
//       "FX 150 CARGO",
//       "FX 150 MAD MAX",
//       "FX 200",
//       "FX 200 MAD MAX",
//       "FX 200 SERIES",
//       "FX 200 SERIES",
//       "FX 250",
//       "FX 250 KING MAD MAX",
//       "FX 250 MAD MAX",
//       "FX 300 MAD MAX",
//       "FX 300 MAD MAX",
//       "FX 50",
//       "FX 50 SERIES",
//       "FX 70",
//       "FX 70 SERIES",
//       "FX 90",
//       "FX 90",
//       "FX 90 SERIES",
//       "FX110 CARGO",
//       "FX125 SERIES",
//       "FX150 CARGO",
//       "FX150 KART",
//       "FX150 SERIES",
//       "FX200 SPORT SERIES",
//       "FX250",
//       "FX250 KING",
//       "FX250 MAD MAX",
//       "FX250 SPORT SERIES",
//       "FX300",
//       "FX400 SPORT SERIES",
//       "FX50 KART",
//       "GFORCE 200",
//       "GFORCE 200",
//       "GFORCE 250",
//       "GFORCE 250",
//       "GFORCE 300",
//       "GFORCE 700",
//       "GFORCE250",
//       "GFORCE500 4WD",
//       "GT2I",
//       "HJ 110",
//       "HJ110",
//       "HJ125-7",
//       "HOT",
//       "HOT 90 G2",
//       "HOT 90 SWEET G2",
//       "HOT 90 SWEET SERIES",
//       "MADASS",
//       "MADASS",
//       "MOD",
//       "MOTO NEW FIRE 70 FULL",
//       "MOTO NEW FIRE 70 VAV",
//       "MOTO NEW FIRE 70 VAV",
//       "MOTO SOL TOP 70",
//       "MOTO SOL TOP 70",
//       "MOTO SOL TOP 70 VAV",
//       "MOTO SOL TOP 70 VAV",
//       "MOTONETA",
//       "NEW FIRE 100",
//       "NEW FIRE 50 ECONO",
//       "NEW FIRE 50 PLUS",
//       "NEW FIRE 50 SPORT",
//       "NEW FIRE 50CC",
//       "NEW FIRE 50CC 4T",
//       "NEW FIRE 70 CC 4T",
//       "NEW FIRE 70 ECONO",
//       "NEW FIRE 70 FULL",
//       "NEW FIRE 70 PLUS",
//       "NEW FIRE 90",
//       "NEW FIRE 90 4V",
//       "NEW FIRE POWER 70",
//       "PATAGONIA EAGLE 250 II",
//       "PATAGONIAN EAGLE 125",
//       "PATAGONIAN EAGLE 125",
//       "PATAGONIAN EAGLE 150",
//       "PATAGONIAN EAGLE 150",
//       "PATAGONIAN EAGLE 150",
//       "PATAGONIAN EAGLE 250",
//       "PATAGONIAN EAGLE 250",
//       "PATAGONIAN EAGLE 250 II",
//       "PATAGONIAN EAGLE 250 II",
//       "PATAGONIAN EAGLE 350",
//       "PATAGONIAN EAGLE 350",
//       "POCKET",
//       "POCKET",
//       "POCKET",
//       "POCKET AUTOMIX",
//       "POCKET AUTOMIX",
//       "POCKET EK",
//       "POCKET GL",
//       "POCKET GL",
//       "POCKET GL",
//       "POCKET PLUS",
//       "RALLY 50CC",
//       "RAPTOR",
//       "RAPTOR 4 TIEMPOS",
//       "RK6",
//       "RKS",
//       "RKV",
//       "RX 125",
//       "RX 125",
//       "RX 125 SPORT",
//       "RX 150",
//       "RX 150",
//       "RX 150 G3",
//       "RX 150 GLADIATOR",
//       "RX 150 NEXT",
//       "RX 150 R",
//       "RX 150 Z7",
//       "RX 200",
//       "RX 200 G2",
//       "RX 200 MONACO",
//       "RX 200 NAKED",
//       "RX 200 NEXT",
//       "RX 250",
//       "RX 350 NAKED",
//       "RX150",
//       "RX150",
//       "RX200",
//       "RX200",
//       "RX250",
//       "RZ 25",
//       "RZ 25",
//       "RZ 300",
//       "RZ 35 R",
//       "RZ 35 R",
//       "RZ 400",
//       "RZ 650",
//       "RZ 650 MT",
//       "RZ3",
//       "RZ7",
//       "SAPUCAI 125",
//       "SAPUCAI 150",
//       "SAPUCAI 150 F",
//       "SEXY 110",
//       "SEXY 125",
//       "SEXY 125",
//       "SOL",
//       "SOL 100",
//       "SOL 100 AUTOMATICA",
//       "SOL 50 ECONO",
//       "SOL 50 MAX",
//       "SOL 70",
//       "SOL 70 4 TIEMPOS",
//       "SOL 70 4 TIEMPOS",
//       "SOL 90",
//       "SOL 90 4V",
//       "SOL 90 AUTOMATIC",
//       "SOL BUSINESS",
//       "SOL MAX",
//       "SOL MAX 47",
//       "SOL SPEED",
//       "SPEEDCRUISER",
//       "SPEEDLIGHT 200",
//       "STYLER",
//       "STYLER 125 CRUISER",
//       "STYLER 125 EXCLUSIVE",
//       "STYLER 150 CRUISER",
//       "STYLER 150 CRUISER",
//       "STYLER 150 EXCLUSIVE",
//       "STYLER 150 RS",
//       "STYLER 150 RT",
//       "STYLER 250 CRUISER",
//       "STYLER 50",
//       "STYLER 50 CLASSIC",
//       "SWING",
//       "SWING",
//       "SWING",
//       "TRAKTOR",
//       "TRAKTOR 250CC",
//       "TRICARGO",
//       "TRICARGO 4 TIEMPOS",
//       "TRICARGO 90 AUTOMATICO",
//       "TRICARGO MAX 110",
//       "TRICARGO MAX 125",
//       "TX",
//       "VENTO",
//       "VENTO 3V",
//       "VENTO CLASSIC",
//       "Z MAX 200",
//       "Z MAX 200 TRUCK",
//       "Z TRUCK",
//       "Z TRUCK",
//       "Z TRUCK DUO",
//       "ZANELLA KIDS",
//       "ZANELLA SOL 70 ECONO",
//       "ZB 110",
//       "ZB 110 R",
//       "ZB 110 WHITE SHARK",
//       "ZB 110D",
//       "ZB 125 SHARK",
//       "ZB 125R",
//       "ZB 125R XENON",
//       "ZB110",
//       "ZB110",
//       "ZB110 RT",
//       "ZB110 SHARK",
//       "ZB110D",
//       "ZB110D",
//       "ZB125",
//       "Z-CAP",
//       "Z-CAP 50",
//       "Z-CARGA 4 TIEMPOS",
//       "ZCARGA CRAZY 70CC 2T",
//       "ZCT 150",
//       "ZCT110L",
//       "ZCT125",
//       "ZCT150",
//       "ZR 125",
//       "ZR 125 MOTARD",
//       "ZR 150",
//       "ZR 150",
//       "ZR 150 ZT",
//       "ZR 200",
//       "ZR 200 ENDURO",
//       "ZR 250",
//       "ZSC125",
//       "ZTT 200 ENDURO",
//       "ZTT 200 MOTARD",
//       "ZTT 250 ENDURO",
//       "ZTT 250 MOTARD",
//       "ZTT 250 SUPERMOTARD",
//       "ZTT200",
//       "ZTT200 ENDURO",
//       "ZTT200 MOTARD",
//       "ZTT250 SUPERMOTARD"
//     ]
//   }