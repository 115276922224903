import React from "react";
import styles from "./footer.module.css";
import { BsInstagram, BsFacebook, BsWhatsapp } from "react-icons/bs";
import maps_img from "../../img/maps.jpg";

export default function Footer() {
  return (
    <div className={styles.bottomPrimary}>
      <div className={styles.left}>
        <div className={styles.address}>
          <span>Lanín 100 Centenario </span>
          <span>CP. 8309, Neuquén</span>
          <span>+542995974319</span>
        </div>
        <div className={styles.social}>
          <a
            href="https://www.instagram.com/lanin_carwash/?hl=es"
            target="_blank"
            rel="noreferrer"
          >
            <BsInstagram
              className={styles.iconBottom}
              style={{ fontSize: "0.6rem" }}
            />
          </a>
          <a
            href="https://www.facebook.com/CarWashLanin/?locale=es_LA"
            target="_blank"
            rel="noreferrer"
          >
            <BsFacebook
              className={styles.iconBottom}
              style={{ fontSize: "0.6rem" }}
            />
          </a>
          <a href="https://wa.me/542995974319" target="_blank" rel="noreferrer">
            <BsWhatsapp
              className={styles.iconBottom}
              style={{ fontSize: "0.6rem" }}
            />
          </a>
        </div>
        <div className={styles.copyr}>
          <span>LANIN CARWASH ©2023</span>
          <a
            href="https://www.cristianwenz.tech"
            target="_blank"
          >
            <span>HARD-CODE TECHNOLOGIES®</span>
          </a>
        </div> 
      </div>
      <div className={styles.right}>
        <div className={styles.map}>
          <iframe
            width="100%"
            height="90%"
            src="https://www.openstreetmap.org/export/embed.html?bbox=-68.13066244125368%2C-38.83900564491327%2C-68.11422586441041%2C-38.829545106505996&amp;layer=mapnik&amp;marker=-38.834275532897216%2C-68.12244415283203"
          ></iframe>
          <br />
        </div>
      </div>
    </div>
  );
}
